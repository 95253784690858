/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState } from 'react';
import { formatDateTimeRange } from '../../common/formatting.js';
import { DateInput, TimeInput } from '../../common/form';
import Dialog from '../../common/dialog.js';
var moment = require('moment');
var classNames = require('classnames');

const ScheduledCallForm = ({ createLeadScheduledCall }) => {
    const [scheduledForDate, setScheduledForDate] = useState(
        moment().add(1, 'hour').format('YYYY-MM-DD')
    );
    const [scheduledForFromTime, setScheduledForFromTime] = useState(
        moment().add(1, 'hour').format('HH:00')
    );
    const [scheduledForToTime, setScheduledForToTime] = useState(
        moment().add(2, 'hours').format('HH:00')
    );
    const confirmSubmit = () => {
        if (!scheduledForDate || scheduledForDate === '') return;
        if (!scheduledForFromTime || scheduledForFromTime === '') return;
        if (!scheduledForToTime || scheduledForToTime === '') return;

        const scheduledForFromDateTime = moment(`${scheduledForDate}T${scheduledForFromTime}`);
        const scheduledForToDateTime = moment(`${scheduledForDate}T${scheduledForToTime}`);

        const confirmed = window.confirm(
            `You are about to schedule a call-back for ${formatDateTimeRange(scheduledForFromDateTime, scheduledForToDateTime)}. Does that look right?`
        );

        if (!confirmed) return;

        createLeadScheduledCall({ scheduledForFromDateTime, scheduledForToDateTime })
    };

    return (
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                <DateInput
                    value={scheduledForDate}
                    onChange={setScheduledForDate}
                />
                between
                <TimeInput
                    value={scheduledForFromTime}
                    onChange={setScheduledForFromTime}
                />
                and
                <TimeInput
                    value={scheduledForToTime}
                    onChange={setScheduledForToTime}
                />
            </div>
            <div style={{ width: '100%', marginTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
                <button
                    className="medium ui labeled icon green button"
                    style={{ margin: 0, padding: 5 }}
                    onClick={confirmSubmit}
                >
                    <i className="clock outline icon" />
                    Schedule
                </button>
            </div>
        </div>
    );
};

const ScheduleCallButton = ({ submittingScheduleCall, showScheduleCallBackDialog, setShowScheduleCallBackDialog, createLeadScheduledCall }) => {
    return (
        <>
            <button
                className={classNames('ui green button', { loading: false })}
                disabled={submittingScheduleCall}
                onClick={() => setShowScheduleCallBackDialog(true)}
            >
                Schedule Call-Back
            </button>
            {showScheduleCallBackDialog && (
                <Dialog
                    title="Schedule Call-Back"
                    onClose={() => setShowScheduleCallBackDialog(false)}
                >
                    <ScheduledCallForm createLeadScheduledCall={createLeadScheduledCall} />
                </Dialog>
            )}
        </>
    )
}

export default ScheduleCallButton;
