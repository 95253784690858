import React, { useRef, useEffect, useMemo, useState } from 'react';
import useGetInterview from '../../common/useGetInterview.js';
import Spinner from '../spinner.js';
import Center from '../center.js';
import useInterviewEditor from './interviews/useInterviewEditor.js';
import { NodeMenu, EdgeMenu } from './interviews/NodeMenu.js';
import { INTERVIEW_NODE_TYPES } from '../../common/webconstants.js';
import { titleForNode } from './interviews/utils.js';
import { formatDateTimeHumanDateTime } from '../../common/formatting.js';
import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';

cytoscape.use(klay);

const KlayChart = ({ nodes, edges, buildNodeMenu, nodeMenuData, setNodeMenuData }) => {
    const containerRef = useRef();

    useEffect(() => {
        const cy = cytoscape({
            container: containerRef.current,
            style: [
                {
                    selector: 'node',
                    style: {
                        'background-color': '#666',
                        'label': 'data(title)',
                        'shape': 'square',
                        'color': 'black',
                        'font-size': 4,
                        'text-valign': 'center',
                        'text-halign': 'center',
                    }
                  },
                  {
                    selector: 'node.start',
                    style: {
                        'background-color': '#33ff33',
                        'shape': 'round-diamond',
                    },
                  },
                  {
                    selector: 'node.end',
                    style: {
                        'background-color': 'red',
                        'color': 'white',
                        'shape': 'round-diamond',
                    },
                  },
                  {
                    selector: 'node.user-input',
                    style: {
                        'background-color': '#00ccff',
                    },
                  },
                  {
                    selector: 'node.auto-input',
                    style: {
                        'shape': 'roundrectangle',
                        'background-color': '#d9d9d9',
                    },
                  },
                  {
                    selector: 'node.conditional',
                    style: {
                        'background-color': 'yellow',
                        'shape': 'round-octagon',
                    },
                  },
                  {
                    selector: 'edge',
                    style: {
                      'width': 3,
                      'line-color': '#ccc',
                      'target-arrow-color': '#ccc',
                      'target-arrow-shape': 'triangle',
                      'curve-style': 'bezier',
                      'font-size': 4,
                    }
                  },
                  {
                    selector: 'edge[label]',
                    style: {
                      'label': 'data(label)',
                    }
                  },
            ],
            elements: [
                ...nodes.map((ele) => {
                    const classes = [];

                    if (ele.type) {
                        classes.push(ele.type);

                        const isConditionalNode = ele.type === INTERVIEW_NODE_TYPES.CONDITIONAL;
                        const requiresUserInput = [INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION, INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION].includes(ele.type);
                        const isAutoInput = [INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST].includes(ele.type);

                        if (isConditionalNode) {
                            classes.push('conditional');
                        } else if (requiresUserInput) {
                            classes.push('user-input');
                        } else if (isAutoInput) {
                            classes.push('auto-input');
                        }
                    }

                    return {
                        group: 'nodes',
                        data: ele,
                        grabbable: false,
                        classes,
                    };
                }),
                ...edges.map((ele) => {
                    return {
                        group: 'edges',
                        data: ele,
                    };
                }),
            ],
            layout: {
                name: 'klay',
                padding: 100,
                klay: {
                    direction: 'RIGHT',
                    inLayerSpacingFactor: 2,
                    nodePlacement: 'SIMPLE',
                },
              },
        });

        cy.on('click', 'node', function(event) {
            event.stopPropagation();

            const nodeId = this.id();
            const isStartNode = nodeId === 'start';
            const isEndNode = nodeId.startsWith('end-');

            if (isStartNode || isEndNode) return;

            setNodeMenuData({
                id: this.id(),
                x: event.renderedPosition.x,
                y: event.renderedPosition.y,
            });
        });

        cy.on('click', 'edge', function(event) {
            event.stopPropagation();

            setNodeMenuData({
                id: this.id(),
                x: event.renderedPosition.x,
                y: event.renderedPosition.y,
            });
        });

        cy.on('click', function(event) {
            if (!event.isPropagationStopped()) {
                setNodeMenuData(null);
            }
        });

        return () => {
            cy.destroy();
        }
    }, [nodes, edges, setNodeMenuData]);

    return (
        <>
            <div ref={containerRef} style={{ width: '100%', height: '100%' }} />
            {nodeMenuData && (
                <div
                    style={{
                        position: 'absolute',
                        top: nodeMenuData.y,
                        left: nodeMenuData.x,
                    }}
                >
                    {buildNodeMenu(nodeMenuData.id)}
                </div>
            )}
        </>
    );
};

const InterviewEditor = ({ activeInterview, interviewTopic, reloadActiveInterview, loadingInterview }) => {
    const [nodeMenuData, setNodeMenuData] = useState(null);
    const {
        getNodeById,
        startNodeId,
        deleteNode,
        resetToActive,
        insertNodeOnEdge,
        getChoiceQuestionInfo,
        getChoiceQuestionOptionsForQuestion,
        multipleChoiceQuestionNodes,
        singleChoiceQuestionNodes,
        updateConditionalNode,
        publishInterview,
        updateChoiceQuestionOption,
        removeChoiceQuestionOption,
        getMultipleChoiceQuestionNodeById,
        getSingleChoiceQuestionNodeById,
        updateQuestion,
        getConditionalNodeById,
        addChoiceQuestionOption,
        documentTypes,
        addDocumentType,
        getCreateDocumentRequestNodeById,
        getDocumentTypeById,
        addCreateDocumentRequestNode,
        setEndNodeForEdge,
        nodes,
        updateNode,
    } = useInterviewEditor({ activeInterview, reloadActiveInterview });

    const graphData = useMemo(() => {
        const links = [];
        const nodes = [];

        const recordStartNoteTo = (nodeId) => {
            nodes.push({ id: 'start', type: 'start', title: 'Start' });
            links.push({ id: `start>${nodeId}`, source: 'start', target: nodeId });
        };

        const recordEndNodeFrom = ({ nodeId, linkId, endNodeId, label }) => {
            nodes.push({ id: endNodeId, type: 'end', title: 'End' });

            const linkParams = {
                id: linkId,
                source: nodeId,
                target: endNodeId,
            };
            if (label) linkParams.label = label;
            links.push(linkParams);
        };

        const graphNodeIdForNodeId = (nodeId) => {
            return `node-${nodeId}`;
        };

        const recordNode = (nodeId) => {
            const node = getNodeById(nodeId);

            if (nodes.find((nodeData) => nodeData.id === graphNodeIdForNodeId(nodeId))) return;

            const isConditionalNode = node.nodeType === INTERVIEW_NODE_TYPES.CONDITIONAL;
            nodes.push({
                id: graphNodeIdForNodeId(node.id),
                title: titleForNode({
                    node,
                    getSingleChoiceQuestionNodeById,
                    getMultipleChoiceQuestionNodeById,
                    getCreateDocumentRequestNodeById,
                    getDocumentTypeById,
                }),
                type: node.nodeType,
            });

            if (isConditionalNode) {
                const conditionalNode = getConditionalNodeById(node.nodeId);

                if (node.nextInterviewNodeId) {
                    recordNode(node.nextInterviewNodeId);
                    links.push({
                        id: `${graphNodeIdForNodeId(node.id)}|conditionNotMet>${graphNodeIdForNodeId(node.nextInterviewNodeId)}`,
                        source: graphNodeIdForNodeId(node.id),
                        target: graphNodeIdForNodeId(node.nextInterviewNodeId),
                        label: 'False',
                    });
                } else {
                    recordEndNodeFrom({
                        nodeId: graphNodeIdForNodeId(node.id),
                        linkId: `${graphNodeIdForNodeId(node.id)}|conditionNotMet>end`,
                        endNodeId: `end-${node.id}-condition-not-met`,
                        label: 'False',
                    });
                }

                if (conditionalNode.nextInterviewNodeIdConditionMet) {
                    recordNode(conditionalNode.nextInterviewNodeIdConditionMet);
                    links.push({
                        id: `${graphNodeIdForNodeId(node.id)}|conditionMet>${graphNodeIdForNodeId(conditionalNode.nextInterviewNodeIdConditionMet)}`,
                        source: graphNodeIdForNodeId(node.id),
                        target: graphNodeIdForNodeId(conditionalNode.nextInterviewNodeIdConditionMet),
                        label: 'True',
                    });
                } else {
                    recordEndNodeFrom({
                        nodeId: graphNodeIdForNodeId(node.id),
                        linkId: `${graphNodeIdForNodeId(node.id)}|conditionMet>end`,
                        endNodeId: `end-${node.id}-condition-met`,
                        label: 'True',
                    });
                }
            } else {
                if (node.nextInterviewNodeId) {
                    recordNode(node.nextInterviewNodeId);
                    links.push({
                        id: `${graphNodeIdForNodeId(node.id)}>${graphNodeIdForNodeId(node.nextInterviewNodeId)}`,
                        source: graphNodeIdForNodeId(node.id),
                        target: graphNodeIdForNodeId(node.nextInterviewNodeId),
                    });
                } else {
                    recordEndNodeFrom({
                        nodeId: graphNodeIdForNodeId(node.id),
                        linkId: `${graphNodeIdForNodeId(node.id)}>end`,
                        endNodeId: `end-${node.id}`,
                    });
                }
            }
        };

        if (startNodeId) {
            recordStartNoteTo(graphNodeIdForNodeId(startNodeId));
            recordNode(startNodeId);
        } else {
            nodes.push({ id: 'start', type: 'start', title: 'Start' });
            nodes.push({ id: 'end-start', type: 'end', title: 'End' });
            links.push({ id: 'start>end-start', source: 'start', target: 'end-start' });
        }

        return {
            links,
            nodes,
        };
    }, [
        startNodeId,
        getNodeById,
        getConditionalNodeById,
        getCreateDocumentRequestNodeById,
        getDocumentTypeById,
        getMultipleChoiceQuestionNodeById,
        getSingleChoiceQuestionNodeById,
    ]);

    const onClickReset = () => {
        const confirmed = window.confirm(
            'Are you sure you want to discard any changes and reset to the currently published version? This action can not be undone.'
        );

        if (confirmed) {
            setNodeMenuData(null);
            resetToActive();
        }
    };

    const onClickPublish = () => {
        const confirmed = window.confirm(
            'Are you sure you want to publish these changes?'
        );

        if (confirmed) {
            closeMenu();
            publishInterview(interviewTopic);
        }
    };

    const closeMenu = () => setNodeMenuData(null);

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: 5 }}>
                <div>
                    <button
                        onClick={onClickReset}
                        className="ui red button"
                        disabled={loadingInterview}
                    >
                        Reset
                    </button>
                </div>
                <div>
                    <button
                        onClick={onClickPublish}
                        className="ui green button"
                        disabled={loadingInterview}
                    >
                        Publish
                    </button>
                </div>
                <div>
                    Published {formatDateTimeHumanDateTime(activeInterview.publishedAt)}
                </div>
            </div>
            <div
                style={{ width: '100%', height: 500 }}
            >
                <KlayChart
                    edges={graphData.links}
                    nodes={graphData.nodes}
                    nodeMenuData={nodeMenuData}
                    setNodeMenuData={setNodeMenuData}
                    buildNodeMenu={(graphNodeId) => {
                        if (graphNodeId.includes('>')) {
                            return (
                                <EdgeMenu
                                    graphEdgeId={graphNodeId}
                                    insertNodeOnEdge={insertNodeOnEdge}
                                    documentTypes={documentTypes}
                                    addDocumentType={addDocumentType}
                                    onClose={closeMenu}
                                    setEndNodeForEdge={setEndNodeForEdge}
                                    nodes={nodes}
                                    getMultipleChoiceQuestionNodeById={getMultipleChoiceQuestionNodeById}
                                    getSingleChoiceQuestionNodeById={getSingleChoiceQuestionNodeById}
                                    getCreateDocumentRequestNodeById={getCreateDocumentRequestNodeById}
                                    getDocumentTypeById={getDocumentTypeById}
                                />
                            )
                        } else {
                            return (
                                <NodeMenu
                                    graphNodeId={graphNodeId}
                                    getNodeById={getNodeById}
                                    deleteNode={deleteNode}
                                    getChoiceQuestionInfo={getChoiceQuestionInfo}
                                    getChoiceQuestionOptionsForQuestion={getChoiceQuestionOptionsForQuestion}
                                    multipleChoiceQuestionNodes={multipleChoiceQuestionNodes}
                                    singleChoiceQuestionNodes={singleChoiceQuestionNodes}
                                    updateConditionalNode={updateConditionalNode}
                                    updateChoiceQuestionOption={updateChoiceQuestionOption}
                                    removeChoiceQuestionOption={removeChoiceQuestionOption}
                                    getMultipleChoiceQuestionNodeById={getMultipleChoiceQuestionNodeById}
                                    getSingleChoiceQuestionNodeById={getSingleChoiceQuestionNodeById}
                                    updateQuestion={updateQuestion}
                                    getConditionalNodeById={getConditionalNodeById}
                                    addChoiceQuestionOption={addChoiceQuestionOption}
                                    documentTypes={documentTypes}
                                    addDocumentType={addDocumentType}
                                    getCreateDocumentRequestNodeById={getCreateDocumentRequestNodeById}
                                    getDocumentTypeById={getDocumentTypeById}
                                    addCreateDocumentRequestNode={addCreateDocumentRequestNode}
                                    onClose={closeMenu}
                                    updateNode={updateNode}
                                />
                            );
                        }
                    }}
                />
            </div>
        </div>
    );
};

const Interviews = () => {
    const interviewTopic = 'onboarding';
    const {
        interview: activeInterview,
        loading: loadingInterview,
        reload: reloadActiveInterview,
        initialized: interviewInitialized,
    } = useGetInterview({ topic: interviewTopic });

    return (
        <div>
            {(!interviewInitialized || !activeInterview) ? (
                <Center>
                    <Spinner />
                </Center>
            ) : (
                <InterviewEditor
                    activeInterview={activeInterview}
                    loadingInterview={loadingInterview}
                    interviewTopic={interviewTopic}
                    reloadActiveInterview={reloadActiveInterview}
                />
            )}
        </div>
    );
};

export default Interviews;
