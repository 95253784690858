import { useState } from 'react';
import API from './api.js';

const useCreateConversationMessage = ({ conversationId, onMessageCreated }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const createConversationMessage = async ({ text, documentId }) => {
        setErrors([]);
        setLoading(true);

        const creationResult = await API.createConversationMessage(conversationId, { text, documentId });

        if (creationResult.status === 'SUCCESS') {
            if (onMessageCreated) onMessageCreated(creationResult.data);
        } else {
            setErrors(creationResult.errors);
        }

        setLoading(false);
    };

    return {
        errors,
        createConversationMessage,
        loading,
    };
};

export default useCreateConversationMessage;
