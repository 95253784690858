import { useEffect } from 'react';

function usePreventBodyScroll() {
    useEffect(() => {
        document.body.classList.add("overflow-y-hidden");
        return () => { document.body.classList.remove("overflow-y-hidden") }
    }, []);
}

export default usePreventBodyScroll;
