import React from "react";
import SearchBox from '../../components/searchbox.js';

const ConversationFilters = ({ allCaseNumbers, filters, filterSetters, messagesFullTextSearchValue, setMessagesFullTextSearchValue }) => {
    return (
        <div
            style={{
                display: 'flex',
                gap: 10,
                flexWrap: 'wrap',
            }}
        >
            <div>
                <SearchBox
                    allPossibleValues={allCaseNumbers}
                    onCommit={filterSetters.setCaseNumberEq}
                    initialValue={filters.caseNumberEq || ''}
                />
            </div>
            <div>
                <SearchBox
                    allPossibleValues={[]}
                    onCommit={(newValue) => newValue ? setMessagesFullTextSearchValue(newValue) : setMessagesFullTextSearchValue('')}
                    initialValue={messagesFullTextSearchValue}
                    placeholder="Search in messages..."
                    large
                />
            </div>
        </div>
    );
};

export default ConversationFilters;
