/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useState, useEffect, useCallback } from 'react';
import API from '../common/api.js';
import { useLeadField, prepareValue } from './useformhelpers.js';
var moment = require('moment');

const dateTimeToInputValue = (dateTime) => {
    return dateTime.format('YYYY-MM-DDTHH:mm');
};

const localDateTimeToUTC = (inputValue) => {
    return moment(inputValue, 'YYYY-MM-DDTHH:mm').toISOString();
};

const getLocalStorageKey = (leadId, fieldName) => `updateleadform.${leadId}.${fieldName}`;

const useUpdateLeadForm = ({ lead, leadModelDescription, hearingEvents, onUpdate, currentUser }) => {
    const relevantHearingEvent = hearingEvents[0];
    const storedHearingDateTime = (relevantHearingEvent && relevantHearingEvent.startDateTime)
        ? dateTimeToInputValue(relevantHearingEvent.startDateTime)
        : null;

    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [updatingAssignee, setUpdatingAssignee] = useState(false);
    const [updatingDeferredUntil, setUpdatingDeferredUntil] = useState(false);
    const localStorageKeyLocalDocuments = getLocalStorageKey(lead.id, 'localDocuments');
    const {
        value: contingency,
        setValue: setContingency,
        valueChanged: contingencyChanged,
        resetValue: resetContingency,
    } = useLeadField({ lead, fieldName: 'contingency' });
    const {
        value: consultNote,
        setValue: setConsultNote,
        valueChanged: consultNoteChanged,
        resetValue: resetConsultNote,
    } = useLeadField({ lead, fieldName: 'consultNote' });
    const {
        value: consultRecommendation,
        setValue: setConsultRecommendation,
        valueChanged: consultRecommendationChanged,
        resetValue: resetConsultRecommendation,
    } = useLeadField({ lead, fieldName: 'consultRecommendation' });
    const {
        value: phoneNumber,
        setValue: setPhoneNumber,
        valueChanged: phoneNumberChanged,
        resetValue: resetPhoneNumber,
    } = useLeadField({ lead, fieldName: 'phoneNumber' });
    const {
        value: mobileNumber,
        setValue: setMobileNumber,
        valueChanged: mobileNumberChanged,
        resetValue: resetMobileNumber,
    } = useLeadField({ lead, fieldName: 'mobileNumber' });
    const {
        value: benefitsType,
        setValue: setBenefitsType,
        valueChanged: benefitsTypeChanged,
        resetValue: resetBenefitsType,
    } = useLeadField({ lead, fieldName: 'benefitsType' });
    const {
        value: caseType,
        setValue: setCaseType,
        valueChanged: caseTypeChanged,
        resetValue: resetCaseType,
    } = useLeadField({ lead, fieldName: 'caseType' });
    const {
        value: caseStatus,
        setValue: setCaseStatus,
        valueChanged: caseStatusChanged,
        resetValue: resetCaseStatus,
    } = useLeadField({ lead, fieldName: 'caseStatus' });
    const {
        value: receivedEddDeterminationDate,
        setValue: setReceivedEddDeterminationDate,
        valueChanged: receivedEddDeterminationDateChanged,
        resetValue: resetReceivedEddDeterminationDate,
    } = useLeadField({ lead, fieldName: 'receivedEddDeterminationDate' });
    const {
        value: hearingDateTime,
        setValue: setHearingDateTime,
        valueChanged: hearingDateTimeChanged,
        resetValue: resetHearingDateTime,
    } = useLeadField({
        lead,
        fieldName: 'hearingDateTime',
        remoteValue: storedHearingDateTime,
        initialInputValue: window.localStorage.getItem(getLocalStorageKey(lead.id, 'hearingDateTime')) || storedHearingDateTime || '',
    });
    const determineInitialLocalDocuments = () => {
        const storedDocumentsJson = window.localStorage.getItem(localStorageKeyLocalDocuments);

        if (storedDocumentsJson) {
            return JSON.parse(storedDocumentsJson);
        }

        return [];
    };
    const [localDocuments, setLocalDocuments] = useState(determineInitialLocalDocuments);
    useEffect(() => {
        const documentsJson = JSON.stringify(localDocuments);

        if (localDocuments.length > 0) {
            window.localStorage.setItem(localStorageKeyLocalDocuments, documentsJson);
        } else {
            window.localStorage.removeItem(localStorageKeyLocalDocuments);
        }
    }, [localDocuments, localStorageKeyLocalDocuments]);

    const consultRecommendationOptions = [
        { value: '', label: '' },
        ...leadModelDescription.fields.consultRecommendation.options,
    ];
    const contingencyOptions = [
        { value: '', label: '' },
        ...leadModelDescription.fields.contingency.options,
    ];
    const benefitsTypeOptions = [
        { value: '', label: '' },
        ...leadModelDescription.fields.benefitsType.options,
    ];
    const caseTypeOptions = [
        { value: '', label: '' },
        ...leadModelDescription.fields.caseType.options,
    ];
    const caseStatusOptions = [
        { value: '', label: '' },
        ...leadModelDescription.fields.caseStatus.options,
    ];

    const submit = async () => {
        setErrorMessage(null);
        setSubmitting(true);

        const params = {
            contingency: prepareValue(contingency, lead.contingency, { nullify: true }),
            consultNote: prepareValue(consultNote, lead.consultNote, { nullify: true }),
            consultRecommendation: prepareValue(consultRecommendation, lead.consultRecommendation, { nullify: true }),
            phoneNumber: prepareValue(phoneNumber, lead.phoneNumber, { nullify: true }),
            mobileNumber: prepareValue(mobileNumber, lead.mobileNumber, { nullify: true }),
            benefitsType: prepareValue(benefitsType, lead.benefitsType, { nullify: true }),
            caseType: prepareValue(caseType, lead.caseType, { nullify: true }),
            caseStatus: prepareValue(caseStatus, lead.caseStatus, { nullify: true }),
            receivedEddDeterminationDate: prepareValue(receivedEddDeterminationDate, lead.receivedEddDeterminationDate, { nullify: true }),
            caEditableFieldsLastUpdatedAt: lead.caEditableFieldsLastUpdatedAt,
        };

        const consultRecommendationGetsInitialized = lead.consultRecommendation === null && params.consultRecommendation !== null;
        const currentUserIsAttorney = currentUser && currentUser.isAttorney && currentUser.userID;
        const leadNotAssignedYet = !lead.assigneeId;
        if (consultRecommendationGetsInitialized && leadNotAssignedYet && currentUserIsAttorney) {
            params.assigneeId = currentUser.userID;
        }

        const updateLeadResult = await API.updateLead(lead.id, params);
        let updateEventResult = null;

        if (!updateLeadResult || !updateLeadResult.success) {
            setErrorMessage(updateLeadResult.errorMessage);
            setSubmitting(false);

            return;
        }

        if (relevantHearingEvent) {
            const preparedHearingDateTime = prepareValue(hearingDateTime, storedHearingDateTime, { nullify: true });
            const convertedEventParams = {
                startDateTime: preparedHearingDateTime ? localDateTimeToUTC(preparedHearingDateTime) : null,
            };

            updateEventResult = await API.updateEvent(relevantHearingEvent.salesforceEventId, convertedEventParams);

            if (!updateEventResult || !updateEventResult.success) {
                setErrorMessage(`Successfully updated Lead, but failed to update Hearing Date Event: ${updateEventResult.errorMessage}`);

                if (onUpdate) {
                    onUpdate({ leadUpdated: true });
                }
                setSubmitting(false);

                return;
            }
        }

        const documentUploadPromises = localDocuments.map(async (document) => {
            const documentBlob = await fetch(document.base64String).then(res => res.blob());

            const formData = new FormData();
            formData.append('file', documentBlob, document.title);

            return await API.createLeadDocument(lead.id, formData);
        });

        let uploadedDocuments = false;
        if (documentUploadPromises.length > 0) {
            const results = await Promise.all(documentUploadPromises);
            const failedResults = results.filter((result) => !result.success);

            uploadedDocuments = (results.length - failedResults.length) > 0

            if (failedResults.length > 0) {
                const errorMessages = failedResults.map((result) => result.errorMessage);
                const errorMessagesConcat = errorMessages.join(', ');

                setErrorMessage(
                    `Successfully updated Lead and Hearing Date Event, but failed to upload one or more documents: ${errorMessagesConcat}`
                );

                if (onUpdate) {
                    onUpdate({
                        leadUpdated: true,
                        updatedEvent: updateEventResult ? updateEventResult.event : null,
                        uploadedDocuments,
                    });
                }
                setSubmitting(false);

                const documentsFailedToUpload = [];
                documentUploadPromises.forEach((result, index) => {
                    if (!result || !result.success) {
                        documentsFailedToUpload.push(localDocuments[index]);
                    }
                });
                setLocalDocuments(documentsFailedToUpload);

                return;
            }
        }

        if (onUpdate) {
            onUpdate({
                leadUpdated: true,
                updatedEvent: updateEventResult ? updateEventResult.event : null,
                uploadedDocuments,
            });
        }
        setLocalDocuments([]);
        setSubmitting(false);
    };

    const updateAssignee = useCallback(async (assigneeId) => {
        if (assigneeId === lead.assigneeId) return;

        setUpdatingAssignee(true);

        const updateLeadResult = await API.updateLead(lead.id, {
            assigneeId,
            caEditableFieldsLastUpdatedAt: lead.caEditableFieldsLastUpdatedAt,
        });

        if (!updateLeadResult || !updateLeadResult.success) {
            console.error("Updating the Lead's assignee failed", updateLeadResult);
        } else {
            onUpdate({ leadUpdated: true });
        }

        setUpdatingAssignee(false);
    }, [lead, onUpdate]);

    const updateDeferredUntil = useCallback(async (newValue) => {
        setUpdatingDeferredUntil(true);

        const updateLeadResult = await API.updateLead(lead.id, {
            deferredUntil: newValue,
            caEditableFieldsLastUpdatedAt: lead.caEditableFieldsLastUpdatedAt,
        });

        if (!updateLeadResult || !updateLeadResult.success) {
            console.error("Updating the Lead's Deferred Until failed", updateLeadResult);
        } else {
            onUpdate({ leadUpdated: true });
        }

        setUpdatingDeferredUntil(false);
    }, [lead, onUpdate]);

    const addLocalDocument = (document) => {
        setLocalDocuments([
            ...localDocuments,
            { ...document, id: 'id' + (new Date()).getTime() },
        ]);
    };

    const removeLocalDocument = (localDocumentId) => {
        const newDocuments = localDocuments.filter(
            (localDocument) => localDocument.id !== localDocumentId
        );

        setLocalDocuments(newDocuments);
    };

    return {
        submit,
        submitting,
        errorMessage,
        contingency,
        setContingency,
        contingencyChanged,
        resetContingency,
        consultNote,
        setConsultNote,
        consultNoteChanged,
        resetConsultNote,
        consultRecommendation,
        setConsultRecommendation,
        resetConsultRecommendation,
        contingencyOptions,
        consultRecommendationChanged,
        consultRecommendationOptions,
        phoneNumber,
        setPhoneNumber,
        phoneNumberChanged,
        resetPhoneNumber,
        mobileNumber,
        setMobileNumber,
        mobileNumberChanged,
        resetMobileNumber,
        benefitsType,
        setBenefitsType,
        benefitsTypeChanged,
        benefitsTypeOptions,
        resetBenefitsType,
        caseType,
        setCaseType,
        caseTypeChanged,
        resetCaseType,
        caseTypeOptions,
        caseStatus,
        setCaseStatus,
        caseStatusChanged,
        caseStatusOptions,
        resetCaseStatus,
        receivedEddDeterminationDate,
        setReceivedEddDeterminationDate,
        receivedEddDeterminationDateChanged,
        resetReceivedEddDeterminationDate,
        hearingDateTime,
        setHearingDateTime,
        hearingDateTimeChanged,
        resetHearingDateTime,
        localDocuments,
        addLocalDocument,
        removeLocalDocument,
        updateAssignee,
        updatingAssignee,
        updateDeferredUntil,
        updatingDeferredUntil,
    };
}

export default useUpdateLeadForm;
