import React from "react";
import { Link } from "react-router-dom";
import * as Constants from "../common/webconstants.js";
import OnCallButton from '../common/oncallbutton.js';
import { motion } from "framer-motion";
import "./navigation.css";
var classNames = require("classnames");

function RegularMenuItems({ activeTab, count, leadsUrl }) {
    return (
        <>
            <Link to={`/`} className="header item ml-16" style={{ marginLeft: -16 }}>
                <img className="logo" src="/icon.png" alt="logo" />
                <span style={{ marginLeft: 10 }}>Case Access</span>
            </Link>
            <Link to="/status" className={classNames("item", { active: activeTab === Constants.MATTERS_TAB })}>
                Matters
            </Link>
            <Link to="/tasks" className={classNames("item", { active: activeTab === Constants.TASKS_TAB })}>
                Tasks
            </Link>
            <Link
                to={leadsUrl}
                className={classNames("item", { active: [Constants.LEADS_TAB, Constants.LEADS_CR_TAB].includes(activeTab) })}
            >
                Leads
            </Link>
            <div className="inbox-tab-wrapper">
                <Link
                    to="/inbox/messages"
                    className={classNames("item", { active: activeTab === Constants.INBOX_TAB })}
                >
                    Inbox
                </Link>
                {count > 0 && <div className="floating ui red label inbox-unread-count-label">{count}</div>}
            </div>
        </>
    );
}

function LogsMenuItem({ activeTab }) {
    return (
        <div className="ui simple dropdown item">
            Logs
            <i className="dropdown icon"></i>
            <div className="menu">
                <Link to="/emails" className={classNames("item", { active: activeTab === Constants.EMAILS_TAB })}>
                    Emails
                </Link>
                <Link
                    to="/pushnotifications"
                    className={classNames("item", { active: activeTab === Constants.PUSH_NOTIFS_TAB })}
                >
                    Push Notifs
                </Link>
                <Link to="/sms" className={classNames("item", { active: activeTab === Constants.SMS_TAB })}>
                    SMS
                </Link>
                <Link
                    to="/automations"
                    className={classNames("item", { active: activeTab === Constants.AUTOMATIONS_TAB })}
                >
                    Automations
                </Link>
                <Link to="/devices" className={classNames("item", { active: activeTab === Constants.DEVICES_TAB })}>
                    Devices
                </Link>
                <Link
                    to="/idmetracking"
                    className={classNames("item", { active: activeTab === Constants.IDME_TRACKING_TAB })}
                >
                    ID.me
                </Link>
            </div>
        </div>
    );
}

function SettingsMenuItem({ activeTab }) {
    return (
        <Link to="/settings" className={classNames("item", { active: activeTab === Constants.SETTINGS_TAB })}>
            Settings
        </Link>
    );
}

function QueueMenuItem({ activeTab }) {
    return (
        <Link to="/queue" className={classNames("item", { active: activeTab === Constants.QUEUE_TAB })}>
            Queue
        </Link>
    );
}

function BellButton({ red, ...otherProps }) {
    if (red) {
        return (
            <motion.div
                animate={{
                    scale: [1, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1],
                    rotate: [0, 30, -30, 30, -30, 30, -30, 0],
                }}
                transition={{
                    duration: 2,
                    ease: "easeInOut",
                    times: [0, 0.1, 0.2, 0.25, 0.3, 0.35, 0.4, 0.5],
                    repeat: Infinity,
                    repeatDelay: 60,
                    repeatType: "loop"
                }}
                className="ui red icon button"
                {...otherProps}
            >
                <i className="bell icon" />
            </motion.div>
        );
    } else {
        return (
            <button className="ui icon button" {...otherProps}>
                <i className="bell icon" />
            </button>
        );
    }
}

function RightMenuItems({ redBell, onBellButtonPressed, currentUser }) {
    return (
        <div className="right menu" style={{ marginTop: 8, marginBottom: 8 }}>
            {currentUser.isAttorney && (
                <OnCallButton currentUser={currentUser} />
            )}
            <BellButton red={redBell} onClick={onBellButtonPressed} />
            <a href="/login" className="ui borderless item">
                Log Out
            </a>
        </div>
    );
}

export default function Navigation({
    activeTab,
    isAdmin,
    unprocessedInboxMessageCount,
    unprocessedScanCount,
    numberOfUnreadMessages,
    onBellButtonPressed,
    redBell,
    currentUser,
    leadsUrl,
}) {
    let count = unprocessedInboxMessageCount + unprocessedScanCount;
    if (numberOfUnreadMessages !== null) count += numberOfUnreadMessages;

    return (
        <div className="ui borderless menu" style={{ background: "#ECF0F1", flexShrink: 0 }}>
            <div className="ui fluid limitedwidth container" style={{ paddingLeft: 10, paddingRight: 10 }}>
                <RegularMenuItems activeTab={activeTab} count={count} leadsUrl={leadsUrl} />
                {isAdmin && <LogsMenuItem activeTab={activeTab} />}
                {isAdmin && <SettingsMenuItem activeTab={activeTab} />}
                {currentUser.isCRAgent && <QueueMenuItem activeTab={activeTab} />}
                <RightMenuItems
                    redBell={redBell}
                    onBellButtonPressed={onBellButtonPressed}
                    currentUser={currentUser}
                />
            </div>
        </div>
    );
}
