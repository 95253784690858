/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useState } from 'react';
import API from '../common/api.js';
import { formatDateTimeRange } from '../common/formatting.js';
var moment = require('moment');

const useScheduleCallForm = () => {
    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [scheduledForDate, setScheduledForDate] = useState(
        moment().add(1, 'hour').format('YYYY-MM-DD')
    );
    const [scheduledForFromTime, setScheduledForFromTime] = useState(
        moment().add(1, 'hour').format('HH:00')
    );
    const [scheduledForToTime, setScheduledForToTime] = useState(
        moment().add(2, 'hours').format('HH:00')
    );

    const confirmSubmit = () => {
        if (!scheduledForDate || scheduledForDate === '') return;
        if (!scheduledForFromTime || scheduledForFromTime === '') return;
        if (!scheduledForToTime || scheduledForToTime === '') return;

        const scheduledForFromDateTime = moment(`${scheduledForDate}T${scheduledForFromTime}`);
        const scheduledForToDateTime = moment(`${scheduledForDate}T${scheduledForToTime}`);

        const confirmed =  window.confirm(
            `You are about to schedule a call-back for ${formatDateTimeRange(scheduledForFromDateTime, scheduledForToDateTime)}. Does that look right?`
        );

        return {
            confirmed,
            scheduledForFromDateTime,
            scheduledForToDateTime,
        };
    };

    const confirmAndSubmit = async (leadId) => {
        const {
            confirmed,
            scheduledForFromDateTime,
            scheduledForToDateTime,
        } = confirmSubmit();

        if (!confirmed) return;

        setLoading(true);

        await API.createLeadScheduledCall(leadId, {
            scheduledForFrom: scheduledForFromDateTime,
            scheduledForTo: scheduledForToDateTime,
        });

        setLoading(false);
    };

    const confirmAndSubmitWithToken = async (token) => {
        const {
            confirmed,
            scheduledForFromDateTime,
            scheduledForToDateTime,
        } = confirmSubmit();

        if (!confirmed) return;

        setLoading(true);
        setErrorMessages([]);

        const result = await API.createLeadScheduledCallUsingToken(token, {
            scheduledForFrom: scheduledForFromDateTime,
            scheduledForTo: scheduledForToDateTime,
        });

        if (result && result.errorMessages) {
            setErrorMessages(result.errorMessages);
        }

        setLoading(false);

        return result ? result.success : false;
    };

    return {
        scheduledForDate,
        setScheduledForDate,
        scheduledForFromTime,
        setScheduledForFromTime,
        scheduledForToTime,
        setScheduledForToTime,
        errorMessages,
        loading,
        confirmAndSubmit,
        confirmAndSubmitWithToken,
    };
};

export default useScheduleCallForm;
