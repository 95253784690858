import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
var classNames = require('classnames');

function CannotRegisterClaim({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        clientDeposited: null,
        explanation: ""
    });
    
    const saveForm = useCallback(async (matterID) => {
        return await API.saveCannotRegisterClaim(formState.clientDeposited, formState.explanation, username, matterID);
    }, [formState.clientDeposited, formState.explanation, username]);

    function formIsComplete() {
        if (formState.clientDeposited === null) return false;
        if (formState.clientDeposited) {
            return true;
        } else {
            return formState.explanation !== "";
        }
    }

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={!formIsComplete()} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.clientDeposited === true})} onClick={() => { updateFormState({clientDeposited: true}) }}>Client deposited</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.clientDeposited === false})} onClick={() => { updateFormState({clientDeposited: false}) }}>Client is unable to deposit</div>
                </div>
            </div>

            {formState.clientDeposited === true && (
                <>
                    <div className="field">
                        <p>Please confirm the client deposited by clicking the 'Save and Submit' button below.</p>
                    </div>
                </>
            )}

            {formState.clientDeposited === false && (
                <>
                    <p>Please report why the Client is unable to proceed.</p>
                    
                    <div className="field six wide">
                        <label htmlFor="explanation">Explanation:</label>
                        <textarea type="text" name="explanation" value={formState.explanation} onChange={ev => updateFormState({explanation: ev.target.value})} placeholder="Enter explanation..." rows="5"></textarea>
                    </div>
                </>
            )}
        </TaskForm>
    );
}

export default CannotRegisterClaim;
