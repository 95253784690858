import { useState, useEffect, useCallback } from 'react';
import API from '../common/api.js';

const composeFilters = (filters) => {
    const params = {};
    const {
        sortOrder,
        assigneeIdIn,
        crAssigneeIdIn,
        priorityIn,
        issueWithIn,
        benefitsTypeIn,
        opportunityBenefitsTypeIn,
        query,
        isConverted,
        opportunityPresent,
        statusIn,
        opportunityStageIn,
        opportunityStageNotIn,
        consultRecommendationIn,
        createdAtAfter,
        orOperator,
        isOnHold,
        isDeferred,
        isExpectingCallBackASAP,
    } = filters;

    if (sortOrder) {
        params.sort_by = sortOrder;
    }

    if (assigneeIdIn) {
        params.assignee_id_in = assigneeIdIn;
    }

    if (crAssigneeIdIn) {
        params.cr_assignee_id_in = crAssigneeIdIn;
    }

    if (priorityIn) {
        params.priority_in = priorityIn;
    }

    if (issueWithIn) {
        params.issue_with_in = issueWithIn;
    }

    if (benefitsTypeIn) {
        params.benefits_type_in = benefitsTypeIn;
    }

    if (opportunityBenefitsTypeIn) {
        params.opportunity_benefits_type_in = opportunityBenefitsTypeIn;
    }

    if (query) {
        params.q = query;
    }

    if (isConverted != null) {
        params.is_converted = isConverted;
    }

    if (isOnHold != null) {
        params.is_on_hold = isOnHold;
    }

    if (isExpectingCallBackASAP != null) {
        params.is_expecting_call_back_asap = isExpectingCallBackASAP;
    }

    if (isDeferred != null) {
        params.is_deferred = isDeferred;
    }

    if (opportunityPresent != null) {
        params.opportunity_present = opportunityPresent;
    }

    if (statusIn) {
        params.status_in = statusIn;
    }

    if (opportunityStageIn) {
        params.opportunity_stage_in = opportunityStageIn;
    }

    if (opportunityStageNotIn) {
        params.opportunity_stage_not_in = opportunityStageNotIn;
    }

    if (consultRecommendationIn) {
        params.consult_recommendation_in = consultRecommendationIn;
    }

    if (createdAtAfter) {
        params.created_at_after = createdAtAfter.toISOString();
    }

    if (orOperator) {
        params.or = orOperator.map((andConditions) => composeFilters(andConditions));
    }

    return params;
}

export const useLeads = ({
    sortOrder,
    pageNumber,
    pageSize,
    filters,
}) => {
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [moreAvailable, setMoreAvailable] = useState(true);

    const loadData = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        const params = composeFilters(filters);

        if (sortOrder) {
            params.sort_by = sortOrder;
        }

        if (pageNumber && pageSize) {
            params.page = pageNumber
            params.page_size = pageSize
        }

        let leadsResponse = await API.getLeads(params);

        if (leadsResponse && leadsResponse.leads) {
            setLeads(leadsResponse.leads);
            setMoreAvailable(leadsResponse.leads.length === pageSize);
            setInitialized(true);
        } else {
            setErrorMessage('Failed to load leads from server. Please retry.');
        }

        if (leadsResponse && leadsResponse.errorMessages.length > 0) {
            setErrorMessage(leadsResponse.errorMessages[0]);
        }

        setLoading(false);
    }, [
        sortOrder,
        pageNumber,
        pageSize,
        filters,
    ]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        leads,
        loading,
        leadsErrorMessage: errorMessage,
        initialized,
        moreAvailable,
        reload: loadData,
    };
};

export const useLeadsWithLoadMoreFunctionality = ({ batchSize, ...leadsParams}) => {
    const [numberOfLeadsRequested, setNumberOfLeadsRequested] = useState(batchSize);

    const leadsData = useLeads({
        pageNumber: 1,
        pageSize: numberOfLeadsRequested,
        ...leadsParams,
    });

    const loadMore = useCallback(() => {
        if (!leadsData.moreAvailable) return;

        setNumberOfLeadsRequested(numberOfLeadsRequested + batchSize);
    }, [leadsData.moreAvailable, batchSize, numberOfLeadsRequested]);

    return {
        loadMore,
        ...leadsData,
    };
};

export default useLeads;
