import React from 'react';
import useAttorneyStatistics from '../../common/useAttorneyStatistics.js';
import Spinner from '../../components/spinner.js';
import Center from '../../components/center.js';
import {
    TakeItDenyItRatioCard,
    WonLostRatioCard,
    AverageCallLengthCard,
    ResolvedCard,
    TotalOnCallTimeCard,
    AverageOnCallSessionLengthCard,
} from './categorycards.js';

const ErrorBanner = ({ errorMessage }) => {
    return (
        <div
            style={{ background: 'rgb(255, 192, 192)', padding: 10 }}
        >
            {errorMessage}
        </div>
    );
};

const UnexpectedErrorBanner = () => {
    return (
        <ErrorBanner errorMessage="Unexpected error. Please try again or report this problem, if it persists." />
    );
};

const AttorneyStatistics = ({ attorneyUserId }) => {
    const {
        statistics,
        initialized,
        errorMessage,
        loading,
    } = useAttorneyStatistics({ attorneyUserId });

    if (!initialized || loading) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }
    if (errorMessage) {
        return (
            <ErrorBanner errorMessage={errorMessage} />
        );
    }
    if (!statistics) {
        return (
            <UnexpectedErrorBanner />
        );
    }

    return (
        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap', justifyContent: 'center' }}>
            <ResolvedCard statistics={statistics} />
            <TakeItDenyItRatioCard statistics={statistics} />
            <WonLostRatioCard statistics={statistics} />
            <AverageCallLengthCard statistics={statistics} />
            <TotalOnCallTimeCard statistics={statistics} />
            <AverageOnCallSessionLengthCard statistics={statistics} />
        </div>
    );
};

export default AttorneyStatistics;
