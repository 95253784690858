import { useEffect } from 'react';
import PubSub from 'pubsub-js';

function usePubSub({topic, messageHandler}) {
    useEffect(() => {
        const token = PubSub.subscribe(topic, messageHandler);
        return function cleanUp() {
            PubSub.unsubscribe(token);
        }
    }, [topic, messageHandler]);

    return [];
}

export default usePubSub;
