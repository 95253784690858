import React from 'react';
import { FaFile } from 'react-icons/fa';
import { formatDateTimeHumanDateTime } from '../common/formatting.js';

const backgroundColorForTheme = (theme) => {
    switch (theme) {
        case 'outwards':
            return '#36d0ff';
        default:
            return 'rgb(244, 244, 244)';
    }
};

const documentBackgroundColorForTheme = (theme) => {
    switch (theme) {
        case 'outwards':
            return 'rgb(143, 229, 255)';
        default:
            return '#eee';
    }
};

const borderRadiusForTheme = (theme) => {
    switch (theme) {
        case 'inwards':
            return '10px 10px 10px 0';
        case 'outwards':
            return '10px 10px 0 10px';
        default:
            return 10;
    }
};

const widthForTheme = (theme) => {
    switch (theme) {
        case 'inwards':
        case 'outwards':
            return '70%';
        default:
            return null;
    }
};

const marginLeftForTheme = (theme) => {
    switch (theme) {
        case 'inwards':
            return 'none';
        case 'outwards':
            return 'auto';
        default:
            return null;
    }
};

const metaFontColorForTheme = (theme) => {
    switch (theme) {
        case 'inwards':
            return 'rgb(168, 168, 168)';
        case 'outwards':
            return 'rgb(255, 255, 255)';
        default:
            return 'rgb(168, 168, 168)';
    }
};

const highlightColorForTheme = (_theme) => {
    return '#9aff06';
};

const ChatMessage = ({ message, showSender, theme, highlight }) => {
    let text = message.text;

    if (text && highlight) {
        const regEx = new RegExp('(' + highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ')', 'ig');
        text = text.replace(
            regEx,
            `<span style="background-color: ${highlightColorForTheme(theme)}">$1</span>`
        );
    }

    return (
        <div
            style={{
                padding: 7,
                backgroundColor: backgroundColorForTheme(theme),
                borderRadius: borderRadiusForTheme(theme),
                width: widthForTheme(theme),
                marginLeft: marginLeftForTheme(theme),
                color: 'rgb(38, 38, 38)',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                }}
            >
                {text && (
                    <div dangerouslySetInnerHTML={{__html: text }} />
                )}
                {message.documentId && (
                    <a href={`/viewdocument/${message.documentId}`} target="_blank" rel="noopener noreferrer">
                        <div
                            style={{
                                backgroundColor: documentBackgroundColorForTheme(theme),
                                borderRadius: 3,
                                padding: '10px 5px',
                                display: 'flex',
                                gap: 5,
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ fontSize: 14 }}>
                                <FaFile />
                            </div>
                            <div>
                                {message.documentName || `Document ${message.documentId}`}
                            </div>
                        </div>
                    </a>
                )}
            </div>
            {(showSender && message.senderName) && (
                <div
                    style={{
                        color: 'rgb(70, 70, 70)',
                        fontSize: 11,
                    }}
                >
                    {message.senderName}
                </div>
            )}
            <div
                style={{
                    color: metaFontColorForTheme(theme),
                    fontSize: 10,
                }}
            >
                {formatDateTimeHumanDateTime(message.created_at)}
            </div>
        </div>
    );
};

export default ChatMessage;
