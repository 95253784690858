import { useCallback, useState } from 'react';
import API from './api.js';

const useMarkConversationMessageRead = ({ conversationId, onMessageMarkedRead }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const markConversationMessageRead = useCallback(async ({ messageId }) => {
        setErrors([]);
        setLoading(true);

        const creationResult = await API.markConversationMessageRead(conversationId, messageId);

        if (creationResult.status === 'SUCCESS') {
            if (onMessageMarkedRead) onMessageMarkedRead(creationResult.data);
        } else {
            setErrors(creationResult.errors);
        }

        setLoading(false);
    }, [conversationId, onMessageMarkedRead]);

    return {
        errors,
        markConversationMessageRead,
        loading,
    };
};

export default useMarkConversationMessageRead;
