import { useState, useEffect, useCallback } from 'react';
import API from '../common/api.js';

const useLeadCallLogs = ({
    leadId,
    pageNumber,
    pageSize,
    filters,
}) => {
    const [callLogs, setCallLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [moreAvailable, setMoreAvailable] = useState(true);

    const loadData = useCallback(async () => {
        if (!leadId) return;

        setLoading(true);
        setErrorMessage(null);

        const params = {};

        if (filters && 'vonageCallLegIdPslAgent' in filters) {
            params.vonage_call_leg_id_psl_agent = filters.vonageCallLegIdPslAgent;
        }

        if (pageNumber && pageSize) {
            params.page = pageNumber
            params.page_size = pageSize
        }

        let callLogsIfSuccess = await API.getLeadCallLogs(leadId, params);

        if (callLogsIfSuccess) {
            setCallLogs(callLogsIfSuccess);
            setMoreAvailable(callLogsIfSuccess.length === pageSize);
            setInitialized(true);
        } else {
            setErrorMessage('Failed to load leads from server. Please retry.');
        }

        setLoading(false);
    }, [leadId, pageNumber, pageSize, filters]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        callLogs,
        loading,
        leadsErrorMessage: errorMessage,
        initialized,
        moreAvailable,
        reload: loadData,
    };
};

export default useLeadCallLogs;
