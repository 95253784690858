import { INTERVIEW_NODE_TYPES } from '../../../common/webconstants.js';

const limitStringLength = (string) => {
    let limitedString = string;

    if (limitedString.length > 21) {
        limitedString =  limitedString.slice(0, 10) + '…' + limitedString.substr(limitedString.length - 10);
    }

    return limitedString;
};

export const titleForNode = ({ node, getSingleChoiceQuestionNodeById, getMultipleChoiceQuestionNodeById, getCreateDocumentRequestNodeById, getDocumentTypeById }) => {
    if (node.label) return node.label;

    switch (node.nodeType) {
        case INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION:
            const singleChoiceQuestion = getSingleChoiceQuestionNodeById(node.nodeId);
            return limitStringLength(singleChoiceQuestion.title);
        case INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION:
            const multipleChoiceQuestion = getMultipleChoiceQuestionNodeById(node.nodeId);
            return limitStringLength(multipleChoiceQuestion.title);
        case INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST:
            const createDocumentRequestNode = getCreateDocumentRequestNodeById(node.nodeId);
            const documentType = getDocumentTypeById(createDocumentRequestNode.documentTypeId);
            let title = `Request ${documentType.name}`;
            if (title.length > 21) title = documentType.name;
            return limitStringLength(title);
        default:
            return `${node.nodeType} ${node.id}`;
    }
};
