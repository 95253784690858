import { useState } from 'react';
import useDynamicContentForTopic from '../../../common/useDynamicContentForTopic.js';
import API from '../../../common/api.js';

const useDynamicContentEditor = ({ dynamicContentTopicReadableId }) => {
    const {
        dynamicContentBlocks,
        reload: reloadDynamicContentBlocks,
        initialized,
    } = useDynamicContentForTopic({ dynamicContentTopicReadableId });
    const [loading, setLoading] = useState(false);

    const removeDynamicContentBlock = async (blockId) => {
        setLoading(true);

        await API.deleteDynamicContentBlockForTopic(
            dynamicContentTopicReadableId,
            blockId,
        );
        await reloadDynamicContentBlocks();

        setLoading(false);
    };

    const addDynamicContentBlockAtIndex = async (index, blockParams) => {
        setLoading(true);

        await API.addDynamicContentBlockForTopicAtIndex(dynamicContentTopicReadableId, index, blockParams);
        await reloadDynamicContentBlocks();

        setLoading(false);
    };

    const updateDynamicContentBlock = async (blockId, blockParams) => {
        setLoading(true);

        const result = await API.updateDynamicContentBlockForTopic(
            dynamicContentTopicReadableId,
            blockId,
            blockParams
        );

        if (result) {
            await reloadDynamicContentBlocks();
        }

        setLoading(false);

        return result;
    };

    return {
        dynamicContentBlocks,
        removeDynamicContentBlock,
        addDynamicContentBlockAtIndex,
        loading,
        initialized,
        updateDynamicContentBlock,
    };
};

export default useDynamicContentEditor;
