import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentUserContext } from '../common/currentUserContext.js';
import API from '../common/api.js';
import Spinner from '../components/spinner.js';
import Center from '../components/center.js';
import {
    Form,
    LabeledTextInput,
    LabeledPhoneInput,
    LabeledSelectField,
    FormSubmitButton,
} from '../common/form';
import useLeadModelDescription from '../common/useLeadModelDescription.js';

function useExecuteDelayed({ delayInMS }) {
    const timeoutId = useRef(null);

    const executeDelayed = (func) => {
        clear();

        timeoutId.current = setTimeout(func, delayInMS);
    }

    const clear = () => clearTimeout(timeoutId.current);

    return {
        executeDelayed,
        clearExecuteDelayed: clear,
    };
}

function LeadFormLoaded({ leadModelDescription, onLeadCreated, allowSettingConsultationFields }) {
    const currentUser = useContext(CurrentUserContext);
    const benefitsTypeOptions = leadModelDescription.fields.benefitsType.options;
    const caseTypeOptions = leadModelDescription.fields.caseType.options;
    const caseStatusOptions = leadModelDescription.fields.caseStatus.options;
    const contingencyOptions = leadModelDescription.fields.contingency.options;
    const consultRecommendationOptions = [
        { value: '', label: '' },
        ...leadModelDescription.fields.consultRecommendation.options,
    ];

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [benefitsType, setBenefitsType] = useState(benefitsTypeOptions[0].value)
    const [caseType, setCaseType] = useState(caseTypeOptions[0].value)
    const [caseStatus, setCaseStatus] = useState(caseStatusOptions[0].value)
    const [contingency, setContingency] = useState(contingencyOptions[0].value)
    const [consultNote, setConsultNote] = useState('')
    const [consultRecommendation, setConsultRecommendation] = useState(consultRecommendationOptions[0].value)
    const [searchResults, setSearchResults] = useState([])
    const [reasonForDenial, setReasonForDenial] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [loadingSuggestions, setLoadingSuggestions] = useState(false)
    const [placeholderLead, setPlaceholderLead] = useState(null);
    const {
        executeDelayed,
        clearExecuteDelayed,
    } = useExecuteDelayed({ delayInMS: 300 });
    const [loading, setLoading] = useState(false)

    const searchLeads = async (query) => {
        setLoadingSuggestions(true);

        const newSearchResults = await API.searchLeads(query);
        if (newSearchResults) {
            setSearchResults(newSearchResults);
        } else {
            console.error(`Search for "${query}" did not succeed`);
        }

        setLoadingSuggestions(false);
    }

    const clearSearchResults = () => {
        setSearchResults([])
    }

    const clearPlaceholderLead = () => setPlaceholderLead(null);

    const searchLeadsIfAppropriate = (firstName, lastName) => {
        const name = `${firstName} ${lastName}`.trim();

        if (name.length > 1) {
            searchLeads(name)
        } else {
            clearExecuteDelayed();
            clearSearchResults();
        }
    }

    const onChangeLastName = (newLastName) => {
        setLastName(newLastName);
        executeDelayed(() => searchLeadsIfAppropriate(firstName, newLastName));
    }

    const onChangeFirstName = (newFirstName) => {
        setFirstName(newFirstName);
        executeDelayed(() => searchLeadsIfAppropriate(newFirstName, lastName));
    }

    const onClickSearchLead = (lead) => {
        setLastName(lead.lastName);
        setFirstName(lead.firstName);

        if (phoneNumber.length === 0) setPhoneNumber(lead.phoneNumber || '');
        if (mobileNumber.length === 0) setMobileNumber(lead.mobileNumber || '');

        clearSearchResults();
        clearPlaceholderLead();
    }

    const submitLead = async (event) => {
        event.preventDefault();

        setLoading(true);
        setErrorMessage(null);

        const params = {
            firstName,
            lastName,
            phoneNumber,
            mobileNumber,
            benefitsType,
            caseType,
            caseStatus,
            reasonForDenial,
            contingency,
        };

        if (allowSettingConsultationFields) {
            params.consultNote = consultNote;
            params.consultRecommendation = consultRecommendation;
        }

        const currentUserIsAttorney = currentUser && currentUser.isAttorney && currentUser.userID;
        const consultRecommendationIsSet = params.consultRecommendation != null && params.consultRecommendation !== '';
        if (consultRecommendationIsSet && currentUserIsAttorney) {
            params.assigneeId = currentUser.userID;
        }

        const creationResult = await API.createLead(params);

        if (creationResult) {
            if (creationResult.success) {
                if (onLeadCreated) onLeadCreated(creationResult.leadId);
            } else {
                setErrorMessage(creationResult.errorMessage);
            }
        }

        setLoading(false);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form onSubmit={submitLead}>
                {errorMessage && (
                    <div
                        style={{ background: 'rgb(255, 192, 192)', padding: 10, marginBottom: 8 }}
                    >
                        {errorMessage}
                    </div>
                )}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 40 }}>
                    <div style={{ flex: 1, flexBasis: 280 }}>
                        <div style={{ padding: 5, background: 'rgb(242, 242, 242)'}}>
                            <div style={{ display: 'flex', gap: 10}}>
                                <div style={{flex:1}}>
                                    <LabeledTextInput
                                        id="firstName"
                                        label="First name"
                                        value={placeholderLead ? '' : firstName}
                                        onChange={onChangeFirstName}
                                        placeholder={placeholderLead && placeholderLead.firstName}
                                    />
                                </div>
                                <div style={{flex:1}}>
                                    <LabeledTextInput
                                        id="lastName"
                                        label="Last name"
                                        value={placeholderLead ? '' : lastName}
                                        onChange={onChangeLastName}
                                        placeholder={placeholderLead && placeholderLead.lastName}
                                    />
                                </div>
                            </div>
                            {searchResults.length > 0 && (
                                <div style={{ paddingTop: 10 }}>
                                    {searchResults.slice(0, 10).map((lead) => (
                                        <button
                                            key={lead.id}
                                            type="button"
                                            onClick={() => onClickSearchLead(lead)}
                                            onMouseEnter={() => setPlaceholderLead(lead)}
                                            onMouseLeave={() => setPlaceholderLead(null)}
                                            style={{
                                                width: '100%',
                                                borderRadius: 0,
                                                border: 'none',
                                                padding: 10,
                                                borderTop: '1px solid #b5b5b5',
                                                textAlign: 'left',
                                            }}
                                        >
                                            {lead.firstName} {lead.lastName}
                                            {lead.phoneNumber && `, ${lead.phoneNumber}`}
                                        </button>
                                    ))}
                                </div>
                            )}
                            <div style={{ marginTop: 5, textAlign: 'right' }}>
                                {loadingSuggestions && <span>• </span>}
                                <span style={{ fontSize: '75%', fontStyle: 'italic' }}>Auto-Suggest</span>
                            </div>
                        </div>
                        <LabeledPhoneInput
                            id="phoneNumber"
                            label="Phone"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            placeholder={placeholderLead && placeholderLead.phoneNumber}
                        />
                        <LabeledPhoneInput
                            id="mobileNumber"
                            label="Mobile"
                            value={mobileNumber}
                            onChange={setMobileNumber}
                            placeholder={placeholderLead && placeholderLead.mobileNumber}
                        />
                    </div>
                    <div style={{ flex: 1, flexBasis: 280 }}>
                        <LabeledSelectField
                            id="benefitsType"
                            label="Benefits Type"
                            options={benefitsTypeOptions}
                            value={benefitsType}
                            onChange={setBenefitsType}
                        />
                        <LabeledSelectField
                            id="caseType"
                            label="Case Type"
                            options={caseTypeOptions}
                            value={caseType}
                            onChange={setCaseType}
                        />
                        <LabeledSelectField
                            id="caseStatus"
                            label="Case Status"
                            options={caseStatusOptions}
                            value={caseStatus}
                            onChange={setCaseStatus}
                        />
                        <LabeledTextInput
                            id="reasonForDenial"
                            label="Reason for denial"
                            value={reasonForDenial}
                            onChange={setReasonForDenial}
                        />
                    </div>
                    <div style={{ flex: 1, flexBasis: 280 }}>
                        <LabeledSelectField
                            id="contingency"
                            label="Contingency"
                            options={contingencyOptions}
                            value={contingency}
                            onChange={setContingency}
                        />
                        {allowSettingConsultationFields && (
                            <>
                                <LabeledTextInput
                                    id="consultNote"
                                    label="Consultation Notes"
                                    value={consultNote}
                                    onChange={setConsultNote}
                                    lines={5}
                                />
                                <LabeledSelectField
                                    id="consultRecommendation"
                                    label="Recommendation"
                                    options={consultRecommendationOptions}
                                    value={consultRecommendation}
                                    onChange={setConsultRecommendation}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
                    <FormSubmitButton label="Submit" loading={loading} disabled={loading} />
                </div>
            </Form>
        </div>
    )
}

function LeadForm({ onLeadCreated, allowSettingConsultationFields }) {
    const {
        leadModelDescription,
        loadingLeadModelDescription,
        loadingLeadModelDescriptionErrorMessage,
    } = useLeadModelDescription();

    if (loadingLeadModelDescription) {
        return (
            <Center>
                <Spinner />
            </Center>
        )
    } else if (loadingLeadModelDescriptionErrorMessage) {
        return (
            <Center>
                {loadingLeadModelDescriptionErrorMessage}
            </Center>
        )
    }

    return (
        <LeadFormLoaded
            leadModelDescription={leadModelDescription}
            onLeadCreated={onLeadCreated}
            allowSettingConsultationFields={allowSettingConsultationFields}
        />
    )
}

function AddLeadPage({ allowSettingConsultationFields, buildLeadUrl }) {
    const history = useHistory();

    return (
        <div style={{ height: '100%', overflowY: 'scroll', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: 900 }}>
                <h1 className="ui header">Add New Lead</h1>
                <LeadForm
                    onLeadCreated={(leadId) => history.push(buildLeadUrl(leadId))}
                    allowSettingConsultationFields={allowSettingConsultationFields}
                />
            </div>
        </div>
    );
}

export default AddLeadPage;
