import React, { useState } from 'react';
import API from '../common/api.js';
import classNames from 'classnames';
import { useHistory } from "react-router-dom";

function AddTeamPage() {
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [name, setName] = useState("");
    let history = useHistory();
    const valid = name !== "";
    
    async function save() {
        setSaving(true);
        const success = await API.createTeam(name);
        if (success) {
            history.push("/settings");
        } else {
            setErrMsg("ERROR: Saving team failed! Please retry.");
            setSaving(false);
        }
    }

    
    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;
    
    return (
        <div>
            {errmsgEl}
            <h1 className="ui header">Add New Team</h1>
            <div className="ui form segment">
                <div className="field">
                    <label htmlFor="name">Name:</label>
                    <input type="text" name="name" value={name} placeholder="Enter name" onChange={(ev) => setName(ev.target.value)} required/>
                </div>
                
                <button className={classNames("ui primary button", {loading: saving, disabled: !valid})} onClick={save}>Save</button>
            </div>
        </div>
    );
}

export default AddTeamPage;
