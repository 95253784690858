import { useState, useCallback, useEffect } from 'react';
import API from './api.js';

function useClientData(caseNumber, setLoading, setErrMsg, onUpdate) {
    const [clientData, setClientData] = useState(null);

    const loadCase = useCallback(async () => {
        setLoading(true);

        const data = await API.getCaseStatus(caseNumber);
        if (data) {
            const clientData = await API.getContactInfo(data.info.client_id);
            setClientData(clientData);
            onUpdate(clientData);
            setLoading(false);
            
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, [caseNumber, setLoading, setErrMsg, onUpdate]);

    useEffect(() => {
        setClientData(null);

        loadCase();
    }, [caseNumber, loadCase]);

    return [clientData];
}

export default useClientData;
