/* eslint jsx-a11y/anchor-is-valid: 0 */ 
import React, { useState, useEffect, useCallback, useContext } from 'react';
import API from '../common/api.js';
import { Link } from "react-router-dom";
import Assignee from '../components/assignee.js';
import CaseNumber from '../components/casenumber.js';
import TaskCard from '../components/taskcard.js';
import { useDynamicYesNoModal } from '../common/useModal.js';
import { useHistory } from "react-router-dom";
import { orderBy, some, isEqual } from 'lodash';
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';
import useLocalStorage from '../common/useLocalStorage.js';
import {CurrentUserContext} from '../common/currentUserContext.js';
import Modal from '../components/modal.js';
import RecentlyOpenedTasks from '../components/recentlyopenedtasks.js';
import PinnedTasks from '../components/pinnedtasks.js';
import TasksPageTabs from '../components/taskspagetabs.js';
import TasksPageFilterBar from '../components/taskspagefilterbar.js';
import PubSub from 'pubsub-js';
import usePrevious from '../common/usePrevious.js';
import './taskspage.css';
var classNames = require('classnames');


function placeholderRow(msg) {
    return (
        <tr key={msg} className="dim">
            <td className="border-none" colSpan={4}>{msg}</td>
        </tr>
    );
}

function showMoreRow(count, key, onClick) {
    return (
        <tr key={key} >
            <td className="border-none" colSpan={4}><button className="ui compact fluid button" onClick={onClick}>Show {count} more...</button></td>
        </tr>
    );
}

function augmentTasks(data, allUsers, allTeams) {
    return data.map(t => ({
        ...t,
        assignee: allUsers.find(u => u.user_id === t.assignee_id), 
        team: allTeams.find(team => team.team_id === t.team_id)
    }));
}

function sortTasks(data, sortOrder) {
    if (sortOrder === Constants.SORT_HIGHEST_PRIORITY_FIRST) {
        return orderBy(data, ['priority'], ['asc']);
    } else if (sortOrder === Constants.SORT_LOWEST_PRIORITY_FIRST) {
        return orderBy(data, ['priority'], ['desc']);
    } else if (sortOrder === Constants.SORT_OLDEST_TASK_FIRST) {
        return orderBy(data, ['created_at'], ['asc']);
    } else if (sortOrder === Constants.SORT_NEWEST_TASK_FIRST) {
        return orderBy(data, ['created_at'], ['desc']);
    } else {
        return data;
    }
}

function batchByCasenumber(tasks) {
    /* The resulting array contains elements of the form:
        {case_number: "<case number>", tasks: [
            {case_number: "<case number>", title: "...", priority: ..., status: ...},
            {case_number: "<case number>", title: "...", priority: ..., status: ...},
        ]}

        Within a tasks array, the tasks are ordered by priority.
        All tasks with the same case number are grouped together, and the order of the groups is 
        determined by the priority of the tasks within the group.

        Tasks without an associated case number are not batched.
     */
    const result = []; // Preserves the order of the given tasks array
    const tasksForCasenumber = {}; // To avoid n^2 algorithm
    for (const task of tasks) {
        if (task.case_number) {
            // Batch tasks with the same case number
            if (! (task.case_number in tasksForCasenumber)) {
                result.push({case_number: task.case_number}); // Reserve my spot in the results array
                tasksForCasenumber[task.case_number] = [];
            }
            tasksForCasenumber[task.case_number].push(task);

        } else {
            // Don't batch tasks that don't have a case number
            result.push({tasks: [task]});
        }
    }
    return result.map(batch => {
        if (batch.case_number) {
            return {...batch, tasks: tasksForCasenumber[batch.case_number]};
        } else {
            return batch;
        }
    });
}


function TasksPage({allCaseNumbers, allUsers, allTeams, initialExpandedTaskID}) {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useLocalStorage('taskspage.activeTab', Constants.TASKS_TODO_TAB);
    const [caseNumber, setCaseNumber] = useLocalStorage('taskspage.caseNumber', null);
    const [searchText, setSearchText] = useLocalStorage('taskspage.searchText', null);
    const [tasks, setTasks] = useState([]);
    const [sortedTasks, setSortedTasks] = useState([]);
    const prevTasks = usePrevious(tasks);
    const [expandedTask, setExpandedTask] = useState(null);
    const prevInitialExpandedTaskID = usePrevious(initialExpandedTaskID);
    const [assigneeFilter, setAssigneeFilter] = useLocalStorage('taskspage.assigneeFilter', null);
    const [errMsg, setErrMsg] = useState(null);
    const [sortOrder, setSortOrder] = useLocalStorage('taskspage.sortOrder', Constants.SORT_HIGHEST_PRIORITY_FIRST);
    const [maxTodoRows, setMaxTodoRows] = useState(10);
    const [maxDoingRows, setMaxDoingRows] = useState(10);
    const [maxBlockedRows, setMaxBlockedRows] = useState(10);
    const [showFilterBar, setShowFilterBar] = useState(assigneeFilter !== null || caseNumber !== null || searchText !== null);
    const [filterBarHeight, setFilterBarHeight] = useState(0);
    const [rightBarVisible, setRightBarVisible] = useLocalStorage('taskspage.rightBarVisible', true);
    const currentUser = useContext(CurrentUserContext);
    const [openConfirmDeleteTaskModal, confirmDeleteTaskModal] = useDynamicYesNoModal();
    usePubSub({topic: Constants.PUBSUB_TOPIC_TASK, messageHandler: handleServerMessage});
    let history = useHistory();

    const maxDoneRows = 10;

    async function onCaseNumberSearchBoxCommit(caseNumber) {
        if (caseNumber === null || caseNumber.length === 0) {
            setCaseNumber(null);

        } else {
            setCaseNumber(caseNumber);
        }
    }

    async function onTextSearchBoxCommit(text) {
        if (!text) {
            setSearchText(null);

        } else {
            setSearchText(text);
        }
    }

    const loadData = useCallback(async () => {
        setLoading(true);
        setTasks([]);
        setErrMsg(null);
        
        let data = await API.getTasks();
        if (data) {
            setTasks(augmentTasks(data, allUsers, allTeams));
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }
        setLoading(false);
    }, [allUsers, allTeams]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (initialExpandedTaskID && (!expandedTask || !isEqual(tasks, prevTasks) || initialExpandedTaskID !== prevInitialExpandedTaskID)) {
            const taskToExpand = tasks.find(t => t.task_id === parseInt(initialExpandedTaskID));
            if (taskToExpand) {
                setExpandedTask(taskToExpand);

                if (!expandedTask) {    
                    PubSub.publish(Constants.PUBSUB_TOPIC_OPENED_TASK, taskToExpand);
                }
            } else {
                // Probably an old task that was completed a long time ago. Load it explicitly.
                API.getTask(initialExpandedTaskID).then(t => {
                    if (t) {
                        setExpandedTask(t);
                        if (!expandedTask) {    
                            PubSub.publish(Constants.PUBSUB_TOPIC_OPENED_TASK, t);
                        }
                    }
                });
            }
        } else if (!initialExpandedTaskID && expandedTask) {
            setExpandedTask(null);
        }
    }, [tasks, initialExpandedTaskID, prevInitialExpandedTaskID, expandedTask, prevTasks]);

    useEffect(() => {
        let data = tasks;
        const noFilters = (assigneeFilter === null && caseNumber === null && searchText === null);
        if (noFilters) {
            // By default, only show tasks that are assigned to me, a team I'm a member of, or I'm mentioned on, or I'm watching
            data = data.filter(t => {
                if (t.assignee_id === currentUser.userID) return true;
                if (currentUser.teamMemberOf.includes(t.team_id) && t.assignee_id === null) return true;
                if (t.total_mention_count > 0) return true;
                if (t.is_watched_by_current_user > 0) return true;
                return false;
            });
        }
        if (assigneeFilter) {
            const filter = Array.isArray(assigneeFilter) ? assigneeFilter : [assigneeFilter];
            if (filter.length > 0) {
                data = data.filter(t => {
                    return some(filter.map(f => {
                        if (f.is_user) {
                            return t.assignee_id === f.id;
                        } else if (f.is_team) {
                            return (t.team_id === f.id) && (t.assignee_id === null);
                        } else if (f.id === -1) {
                            return (t.team_id === null) && (t.assignee_id === null);
                        } else {
                            return true; // Should never happen
                        }
                    }));
                });
            }
        }
        if (caseNumber !== null && caseNumber.length > 0) {
            data = data.filter(t => t.case_number === caseNumber);
        }
        if (searchText !== null && searchText.length > 0) {
            data = data.filter(t => t.title.toLowerCase().includes(searchText.toLowerCase()));
        }
        setSortedTasks(sortTasks(data, sortOrder));
    }, [tasks, sortOrder, allUsers, allTeams, caseNumber, assigneeFilter, searchText, currentUser.teamMemberOf, currentUser.userID]);

    function handleServerMessage(topic, data) {
        if (topic === Constants.PUBSUB_TOPIC_TASK_ADDED) {
            const newTasks = [...tasks, data];
            setTasks(augmentTasks(newTasks, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_TASK_UPDATED) {
            const newTasks = tasks.map(t => {
                if (t.task_id === data.task_id) {
                    return {...data};
                }
                return {...t};
            });
            setTasks(augmentTasks(newTasks, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_TASK_DELETED) {
            const newTasks = tasks.filter(t => t.task_id !== parseInt(data.task_id));
            setTasks(augmentTasks(newTasks, allUsers, allTeams));
        }
    }

    const setTaskStatus = useCallback((task, status) => {
        // Update state locally for snappy user experience
        const newTasks = tasks.map(t => {
            if (t.task_id === task.task_id) {
                return {...t, status};
            }
            return t;
        });
        setTasks(augmentTasks(newTasks, allUsers, allTeams));

        // Update on the server in the background
        API.updateTask(task.task_id, {status});
    }, [allUsers, allTeams, tasks]);

    const setTaskAssignee = useCallback((task, assignee) => {
        // Update state locally for snappy user experience
        const newTasks = tasks.map(t => {
            if (t.task_id === task.task_id) {
                if (assignee.is_user) {
                    return {...t, assignee_id: assignee.id};
                } else if (assignee.is_team) {
                    return {...t, team_id: assignee.id, assignee_id: null};
                } else {
                    return {...t, team_id: null, assignee_id: null};
                }
            }
            return t;
        });
        setTasks(augmentTasks(newTasks, allUsers, allTeams));

        // Update on the server in the background
        const fieldsToUpdate = assignee.is_user ?
            {assignee_id: assignee.id} : assignee.is_team ?
            {team_id: assignee.id, assignee_id: null} :
            {team_id: null, assignee_id: null};
        API.updateTask(task.task_id, fieldsToUpdate);
    }, [allUsers, allTeams, tasks]);

    async function setTaskDeadline(task, deadline) {
        // Update state locally for snappy user experience
        const newTasks = tasks.map(t => {
            if (t.task_id === task.task_id) {
                return {...t, deadline: deadline};
            }
            return t;
        });
        setTasks(augmentTasks(newTasks, allUsers, allTeams));

        // Update on the server in the background
        const fieldsToUpdate = {deadline};
        const updatedTask = await API.updateTask(task.task_id, fieldsToUpdate);
        if (updatedTask) {
            const newTasks = tasks.map(t => {
                if (t.task_id === updatedTask.task_id) {
                    return updatedTask;
                }
                return t;
            });
            setTasks(augmentTasks(newTasks, allUsers, allTeams));
        }
    }

    const setTaskFormData = useCallback(async (task, formData) => {
        // Update state locally for snappy user experience
        const newTasks = tasks.map(t => {
            if (t.task_id === task.task_id) {
                return {...t, form_data: formData};
            }
            return t;
        });
        setTasks(augmentTasks(newTasks, allUsers, allTeams));

        // Update on the server in the background
        const fieldsToUpdate = {form_data: formData};
        const updatedTask = await API.updateTask(task.task_id, fieldsToUpdate);
        if (updatedTask) {
            const newTasks = tasks.map(t => {
                if (t.task_id === updatedTask.task_id) {
                    return updatedTask;
                }
                return t;
            });
            setTasks(augmentTasks(newTasks, allUsers, allTeams));
        }
    }, [allUsers, allTeams, tasks]);

    const toggleTaskWatched = useCallback(async (task) => {
        // Update state locally for snappy user experience
        const newTasks = tasks.map(t => {
            if (t.task_id === task.task_id) {
                return {...t, is_watched_by_current_user: !task.is_watched_by_current_user};
            }
            return t;
        });
        setTasks(augmentTasks(newTasks, allUsers, allTeams));

        // Update on the server in the background
        if (task.is_watched_by_current_user) {
            API.unwatchTask(task.task_id);
        } else {
            API.watchTask(task.task_id);
        }
    }, [allUsers, allTeams, tasks]);

    const toggleTaskPinned = useCallback(async (task) => {
        // Update state locally for snappy user experience
        const newTasks = tasks.map(t => {
            if (t.task_id === task.task_id) {
                return {...t, is_pinned_by_current_user: !task.is_pinned_by_current_user};
            }
            return t;
        });
        setTasks(augmentTasks(newTasks, allUsers, allTeams));

        // Let the PinnedTasks component update as well. It also takes care of updating the server.
        if (task.is_pinned_by_current_user) {
            PubSub.publish(Constants.PUBSUB_TOPIC_UNPINNED_TASK, task);
        } else {
            PubSub.publish(Constants.PUBSUB_TOPIC_PINNED_TASK, task);
        }
    }, [allUsers, allTeams, tasks]);

    function deleteTask(task) {
        openConfirmDeleteTaskModal({
            title: 'Delete Task?',
            prompt: "Are you sure you want to delete this task? There is no undo!",
            yesAction: () => {
                // Close the task card
                history.push('/tasks');

                // Update state locally for snappy user experience
                const newTasks = tasks.filter(t => t.task_id !== task.task_id);
                setTasks(augmentTasks(newTasks, allUsers, allTeams));

                // Update on the server in the background
                API.deleteTask(task.task_id);
            },
            noAction: () => {}
        });
    }

    function clearFilters() {
        setAssigneeFilter(null);
        setCaseNumber(null);
        setSearchText(null);
        setSortOrder(Constants.SORT_HIGHEST_PRIORITY_FIRST);
    }

    const possibleAssignees = [
        ...(allUsers.map(u => ({value: `user:${u.user_id}`, id: u.user_id, label: u.name, name: u.name, is_user: true, is_team: false}))), 
        ...(allTeams.map(t => ({value: `team:${t.team_id}`, id: t.team_id, label: t.name, name: t.name, is_user: false, is_team: true}))),
        {value: "unassigned", id: -1, label: "Unassigned", is_user: false, is_team: false}
    ];

    function taskRow(task, batchInx, taskInx, batchSize, prefix) {
        let matter = null;
        if (task.case_number) {
            if (taskInx === 0) {
                const cn = classNames("whitespace-no-wrap", {
                    'border-none': (batchInx+taskInx) === 0,
                    'align-middle': (batchSize === 1),
                    'align-baseline': (batchSize > 1)
                });
                matter = (
                    <td className={cn} rowSpan={batchSize}>
                        <a href="#" onClick={() => {setCaseNumber(task.case_number); setShowFilterBar(true);}} className={`ui horizontal icon label`}>
                            <CaseNumber caseNumber={task.case_number}/>
                        </a>
                    </td>
                );
            } else {
                matter = <td style={{display: 'none'}}/>;
            }
        } else {
            matter = <td className={classNames("whitespace-no-wrap", {'border-none': (batchInx+taskInx) === 0})}></td>;
        }

        const assignee = <Assignee task={task} onClick={name => {setAssigneeFilter(possibleAssignees.find(a => a.label === name)); setShowFilterBar(true);}}/>;

        const commentsAnnotations = task.comment_count > 0 && (<><i className="comments icon"/>{task.comment_count} &nbsp;</>);
        const checklistAnnotations = task.checklistitems_count > 0 && (<><i className="check square outline icon"/>{task.completed_checklistitems_count}/{task.checklistitems_count} &nbsp;</>);
        const attachmentAnnotations = task.attachment_count > 0 && (<><i className="paperclip icon"/>{task.attachment_count} &nbsp;</>);
        let mentionAnnotations = null;
        if (task.read_mention_count < task.total_mention_count) {
            mentionAnnotations = <><i className="red bell icon"/><span className="ui red header" style={{fontWeight: 'normal', fontSize: '1em'}}>{task.total_mention_count}</span></>;
        } else if (task.total_mention_count > 0) {
            mentionAnnotations = <><i className="bell outline icon"/>{task.total_mention_count}</>;
        }
        const watchAnnotations = (task.is_watched_by_current_user > 0) && <span>&nbsp;<i className="eye icon"/></span>;
        const annotations = (commentsAnnotations || checklistAnnotations || attachmentAnnotations || mentionAnnotations || watchAnnotations) && (
            <span style={{color: "#999", marginLeft: '2em'}}>
                {commentsAnnotations}
                {checklistAnnotations}
                {attachmentAnnotations}
                {mentionAnnotations}
                {watchAnnotations}
            </span>
        );
        const actionButtons = (
            <>
                <div className="ui mini compact basic icon buttons task-action-buttons">
                    <button className={classNames("ui icon button", {active: task.is_pinned_by_current_user})} data-tooltip={task.is_pinned_by_current_user ? "Unpin task" : "Pin task"} data-position="top center" onClick={() => toggleTaskPinned(task)}>
                        <i className={classNames("thumbtack icon", {green: task.is_pinned_by_current_user})}/>
                    </button>
                    <button className={classNames("ui icon button", {active: task.is_watched_by_current_user})} data-tooltip={task.is_watched_by_current_user ? "Unwatch task" : "Watch task"} data-position="top center" onClick={() => toggleTaskWatched(task)}>
                        <i className={classNames("eye icon", {green: task.is_watched_by_current_user})}/>
                    </button>
                </div>
                <div className="ui mini compact basic icon buttons task-action-buttons" style={{marginLeft: 4}}>
                    <button 
                        className={classNames("ui icon button", {disabled: task.assignee_id === currentUser.userID && task.status === Constants.TASK_STATUS_DOING})} 
                        data-tooltip="I'll take it" 
                        data-position="top center" 
                        onClick={() => {setTaskStatus(task, Constants.TASK_STATUS_DOING); setTaskAssignee(task, {is_user: true, id: currentUser.userID})}}
                    >
                        <i className="add icon"/>
                    </button>
                    <button 
                        className={classNames("ui icon button", {disabled: task.status === Constants.TASK_STATUS_BLOCKED})} 
                        data-tooltip="Blocked" 
                        data-position="top center" 
                        onClick={() => setTaskStatus(task, Constants.TASK_STATUS_BLOCKED)}
                    >
                        <i className="minus circle icon"/>
                    </button>
                    <button 
                        className={classNames("ui icon button", {disabled: task.status === Constants.TASK_STATUS_DONE})} 
                        data-tooltip="Done" 
                        data-position="top center" 
                        onClick={() => setTaskStatus(task, Constants.TASK_STATUS_DONE)}
                    >
                        <i className="check icon"/>
                    </button>
                </div>
            </>
        );

        return (
            <tr key={`${prefix}-${batchInx}-${taskInx}`} className="task-row">
                {matter}
                <td className={classNames("w-full", {'border-none': (batchInx+taskInx) === 0})}><Link to={`/tasks/${task.task_id}`}>{task.title}</Link> {annotations}</td>
                <td className={classNames("whitespace-no-wrap align-top", {'border-none': (batchInx+taskInx) === 0})}>
                    {actionButtons}
                </td>
                <td className={classNames("whitespace-no-wrap", {'border-none': (batchInx+taskInx) === 0})}>
                    <div className={`ui ${Constants.TASK_PRIO_COLORS[task.priority]} horizontal label w-24`}>{Constants.TASK_PRIO_LABELS[task.priority]}</div>
                </td>
                <td className={classNames("whitespace-no-wrap", {'border-none': (batchInx+taskInx) === 0})}>{(task.assignee || task.team) && <a href="#" className="ui grey icon label">{assignee}</a>}</td>
            </tr>
        );
    }

    function taskRowGroup(batch, batchInx, prefix) {
        return batch.tasks.map((task, taskInx) => taskRow(task, batchInx, taskInx, batch.tasks.length, prefix));
    }

    const todoTasks = sortedTasks.filter(task => task.status === Constants.TASK_STATUS_TODO);
    const doingTasks = sortedTasks.filter(task => task.status === Constants.TASK_STATUS_DOING);
    const blockedTasks = sortedTasks.filter(task => task.status === Constants.TASK_STATUS_BLOCKED);
    const doneTasks = sortedTasks.filter(task => task.status === Constants.TASK_STATUS_DONE);

    const todoRows = batchByCasenumber(todoTasks).map((batch, inx) => taskRowGroup(batch, inx, "todo_"));
    const doingRows = batchByCasenumber(doingTasks).map((batch, inx) => taskRowGroup(batch, inx, "doing_"));
    const blockedRows = batchByCasenumber(blockedTasks).map((batch, inx) => taskRowGroup(batch, inx, "blocked_"));
    const doneRows = batchByCasenumber(doneTasks).map((batch, inx) => taskRowGroup(batch, inx, "done_"));

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else {
        return (
            <div style={{height: '100%'}}>
                <TasksPageTabs 
                    activeTab={activeTab} 
                    setActiveTab={setActiveTab} 
                    sortOrder={sortOrder} 
                    setSortOrder={setSortOrder} 
                    numTodo={todoTasks.length} 
                    numDoing={doingTasks.length} 
                    numBlocked={blockedTasks.length} 
                    filterActive={assigneeFilter !== null || caseNumber !== null || searchText !== null} 
                    filterButtonActive={showFilterBar} 
                    onClickFilterButton={() => setShowFilterBar(!showFilterBar)}
                    sidebarButtonActive={rightBarVisible}
                    onClickSidebarButton={() => setRightBarVisible(!rightBarVisible)}
                />
                
                <TasksPageFilterBar 
                    allCaseNumbers={allCaseNumbers} 
                    possibleAssignees={possibleAssignees} 
                    onCaseNumberSearchBoxCommit={onCaseNumberSearchBoxCommit} 
                    caseNumber={caseNumber} 
                    assigneeFilter={assigneeFilter} 
                    setAssigneeFilter={setAssigneeFilter}
                    onTextSearchBoxCommit={onTextSearchBoxCommit}
                    searchText={searchText}
                    sortOrder={sortOrder} 
                    clearFilters={clearFilters} 
                    visible={showFilterBar}
                    onUpdateHeight={h => setFilterBarHeight(h)}/>

                {errmsgEl}
                
                {expandedTask && <Modal><TaskCard task={expandedTask} onCloseClicked={() => {history.push('/tasks')}} setTaskStatus={setTaskStatus} setTaskAssignee={setTaskAssignee} possibleAssignees={possibleAssignees} deleteTask={deleteTask} setTaskDeadline={setTaskDeadline} setTaskFormData={setTaskFormData} toggleTaskWatched={toggleTaskWatched} toggleTaskPinned={toggleTaskPinned}/></Modal>}

                <div style={{display: 'flex', height: '100%'}}>
                    <div style={{flexGrow: 1, overflowY: 'auto', paddingTop: 20, paddingRight: 20, height: `calc(100% - 58px - ${filterBarHeight}px)`}}>
                        <div className={classNames({hidden: activeTab !== Constants.TASKS_TODO_TAB})}>
                            <table className="ui very basic table">
                                <tbody>
                                    {todoRows.length > 0 ? todoRows.slice(0, maxTodoRows) : placeholderRow("No tasks left to do...")}
                                    {todoRows.length > maxTodoRows ? showMoreRow(todoRows.length - maxTodoRows, 'more-todo-rows', () => setMaxTodoRows(Number.MAX_SAFE_INTEGER)) : null}
                                </tbody>
                            </table>
                        </div>
                        <div className={classNames({hidden: activeTab !== Constants.TASKS_DOING_TAB})}>
                            <table className="ui very basic table">
                                <tbody>
                                    {doingRows.length > 0 ? doingRows.slice(0, maxDoingRows) : placeholderRow("No tasks being worked on...")}
                                    {doingRows.length > maxDoingRows ? showMoreRow(doingRows.length - maxDoingRows, 'more-doing-rows', () => setMaxDoingRows(Number.MAX_SAFE_INTEGER)) : null}
                                </tbody>
                            </table>
                        </div>
                        <div className={classNames({hidden: activeTab !== Constants.TASKS_BLOCKED_TAB})}>
                            <table className="ui very basic table">
                                <tbody>
                                    {blockedRows.length > 0 ? blockedRows.slice(0, maxBlockedRows) : placeholderRow("No tasks are currently blocked.")}
                                    {blockedRows.length > maxBlockedRows ? showMoreRow(blockedRows.length - maxBlockedRows, 'more-blocked-rows', () => setMaxBlockedRows(Number.MAX_SAFE_INTEGER)) : null}
                                </tbody>
                            </table>
                        </div>
                        <div className={classNames({hidden: activeTab !== Constants.TASKS_DONE_TAB})}>
                            <table className="ui very basic table">
                                <tbody>
                                    {doneRows.length > 0 ? doneRows.slice(0, maxDoneRows) : placeholderRow("No tasks completed yet...")}
                                    <tr><td colSpan={2}><Link to="/tasks/archive"><div className="ui primary button">View More in Tasks Archive</div></Link></td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto', height: `calc(100% - 58px - ${filterBarHeight}px)`, paddingRight: 10}} className={classNames({hidden: !rightBarVisible})}>
                        <RecentlyOpenedTasks setCaseNumber={setCaseNumber} setShowFilterBar={setShowFilterBar}/>
                        <PinnedTasks setCaseNumber={setCaseNumber} setShowFilterBar={setShowFilterBar}/>
                    </div>
                </div>
                
                {confirmDeleteTaskModal}
            </div>
        );
    }
}

export default TasksPage;
