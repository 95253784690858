export default function generateSelectOptions(possibleAssignees) {
    let users = [];
    let teams = [];
    let others = [];

    for (const a of possibleAssignees) {
        if (a.is_user) {
            users.push(a);
        } else if (a.is_team) {
            teams.push(a);
        } else {
            others.push(a);
        }
    }

    return [
        {
            label: 'Users',
            options: users
        },
        {
            label: 'Teams',
            options: teams,
        },
        {
            label: 'Other',
            options: others
        }
    ];
}
