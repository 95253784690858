import React, { useState, useEffect, useCallback } from 'react';
import SearchBox from '../components/searchbox.js';
import API from '../common/api.js';

function StatusPage({allCaseNumbers, initialMatterID, msg, success, link, linktext}) {
    const [loading, setLoading] = useState(false);
    const [caseNumber, setCaseNumber] = useState(null);
    const [importantEvents, setImportantEvents] = useState([]);
    const [history, setHistory] = useState([]);
    const [stats, setStats] = useState([]);
    const [casePin, setCasePin] = useState(null);
    const [errMsg, setErrMsg] = useState(null);

    async function onSearchBoxCommit(caseNumber) {
        if (caseNumber.length === 0) {
            setImportantEvents([]);
            setHistory([]);
            setStats([]);
            setCaseNumber(null);
            setCasePin(null);

        } else {
            setLoading(true);
            setImportantEvents([]);
            setHistory([]);
            setStats([]);
            setCasePin(null);
            
            const data = await API.getCaseStatus(caseNumber);
            if (data) {
                setImportantEvents(data.important_events);
                setHistory(data.history);
                setStats(data.stats);
                setCasePin(data.case_pin);
                setLoading(false);
                setCaseNumber(caseNumber);
                
            } else {
                setErrMsg("Unable to load data from server");
                setCaseNumber(caseNumber);
                setLoading(false);
            }
        }
    }

    const openInitialCase = useCallback(async (matterID) => {
        const data = await API.getCaseNumber(matterID);
        if (data && data.caseNumber) {
            await onSearchBoxCommit(data.caseNumber);
        }
    }, []);

    useEffect(() => {
        if (initialMatterID) {
            openInitialCase(initialMatterID);
        }
    }, [initialMatterID, openInitialCase]);

    const eventRows = importantEvents.map((event, inx) => 
        <tr key={inx}>
            <td className="four wide">{event.title}</td>
            <td>{event.text}</td>
        </tr>
    );

    const historyRows = history.map((event, inx) => 
        <tr key={inx}>
            <td className="four wide">{event.timestamp}</td>
            <td>{event.title}</td>
        </tr>
    );

    const requestedDocumentsRows = stats.requested_documents && stats.requested_documents.map((event, inx) =>
        <tr key={inx}>
            <td className="four wide">{event.timestamp}</td>
            <td>{event.title}</td>  
        </tr>
    );

    const uploadedDocumentsRows = stats.uploaded_documents && stats.uploaded_documents.map((event, inx) =>
        <tr key={inx}>
            <td className="four wide">{event.timestamp}</td>
            <td>{event.title}</td>  
        </tr>
    );

    const dontHaveDocumentsRows = stats.dont_have_documents && stats.dont_have_documents.map((event, inx) =>
        <tr key={inx}>
            <td className="four wide">{event.timestamp}</td>
            <td>{event.title}</td>  
        </tr>
    );

    const viewedDocumentsRows = stats.document_views && stats.document_views.map((line, inx) =>
        <tr key={inx}>
            <td>{line.name}</td>
            <td>{line.viewcount}</td>  
            <td>{line.first_viewed}</td>
            <td>{line.last_viewed}</td>
        </tr>
    );

    const pinStr = casePin && (<span style={{float: 'right'}}><i className="lock icon"></i>{casePin}</span>);

    const successMsgEl = success === "1" && msg && msg !== "" && <div className="ui success message">{msg}</div>;
    const errorMsgEl = success === "0" && msg && msg !== "" && <div className="ui error message">{msg}</div>;
    const linkMsgEl = link && link !== "" && linktext && linktext !== "" && <div className="ui info icon message"><i className="linkify icon"/> <a href={link}>{linktext}</a></div>;
    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;
    const searchbox = <SearchBox allPossibleValues={allCaseNumbers} onCommit={onSearchBoxCommit} initialValue={caseNumber || ""}/>;

    if (loading) {
        return (
            <div style={{paddingTop: 10}}>
                {searchbox}
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else if (caseNumber === null) {
        return (
            <div style={{paddingTop: 10}}>
                {searchbox}
                <div className="ui info icon message">
                    <i className="lightbulb outline icon"></i>
                    <p className="content">Please enter a case number above.</p>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{paddingTop: 10, paddingBottom: 10, height: '100%', overflowY: 'auto'}}>
                {searchbox}
                {errmsgEl}
                {errorMsgEl}
                {successMsgEl}
                {linkMsgEl}
                <h1 className="ui dividing header">Status for { caseNumber } {pinStr}</h1>
                <h3 className="ui header">Important Events</h3>
                <table className="ui very basic striped table">
                    <tbody>
                        {eventRows}
                    </tbody>
                </table>

                <h3 className="ui header">Case History</h3>
                <table className="ui very basic striped table">
                    <tbody>
                        {historyRows}
                    </tbody>
                </table>

                <div className="ui hidden divider"></div>
                <h2 className="ui dividing header">Client Activity</h2>
                <h3 className="ui header">App stats (synced with Salesforce)</h3>
                <table className="ui very basic table">
                    <tbody>
                        <tr>
                            <td className="four wide">First opened in app</td>
                            <td>{stats.first_opened_in_app}</td>  
                        </tr>
                        <tr>
                            <td className="four wide">Last opened in app</td>
                            <td>{stats.last_opened_in_app}</td>  
                        </tr>
                    </tbody>
                </table>

                <h3 className="ui header mt40">Requested documents</h3>
                <table className="ui very basic table">
                    <tbody>
                        {requestedDocumentsRows}
                    </tbody>
                </table>

                <h3 className="ui header">Uploaded documents</h3>
                <table className="ui very basic table">
                    <tbody>
                        {uploadedDocumentsRows}
                    </tbody>
                </table>

                <h3 className="ui header">Don't Have documents</h3>
                <table className="ui very basic table">
                    <tbody>
                        {dontHaveDocumentsRows}
                    </tbody>
                </table>

                <h3 className="ui header">Viewed documents</h3>
                <table className="ui table">
                    <thead>
                        <tr>
                            <th>Document</th>
                            <th>View Count</th>
                            <th>First Viewed</th>
                            <th>Last Viewed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewedDocumentsRows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default StatusPage;
