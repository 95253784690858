import React, { useState } from "react";
import { createFilter } from "react-select";
import WindowedSelect from "react-windowed-select";
import { sortBy } from "lodash";

function EditableCaseNumber({ allCaseNumbers, initialValue, onChange }) {
    const [value, setValue] = useState({ label: initialValue });

    const styles = {
        container: (styles) => ({ ...styles, zIndex: 100, color: "black", flexGrow: 1 }),
        control: (styles) => ({ ...styles, borderWidth: 0, ":hover": { cursor: "pointer" } }),
        singleValue: (styles) => ({ ...styles, ":hover": { cursor: "pointer", color: "#1e70bf" } }),
        indicatorsContainer: (styles) => ({ ...styles, display: "none" }),
        menuList: (styles) => ({ ...styles, minHeight: 100 }),
        option: (styles) => ({
            ...styles,
            backgroundColor: "white",
            color: "black",
            "&:hover": {
                cursor: "pointer",
                backgroundColor: "#DEEBFF",
            },
        }),
    };

    const options = sortBy(
        allCaseNumbers.map((c) => ({
            ...c,
            label: c.case_number,
        })),
        "label"
    );

    const formatOptionLabel = ({ name, label }, { context }) => {
        if (context === "menu") {
            return (
                <div>
                    <div style={{ marginBottom: "0.25em" }}>{label}</div>
                    <div style={{ color: "#999", fontSize: "0.85em" }}>{name}</div>
                </div>
            );
        }
        // If we get here, the render context is the single value
        if (label) {
            return (
                <span>
                    <i className="folder icon" /> {label}
                </span>
            );
        }
        return <span className="dim">Click to assign to case</span>;
    };

    function onSelectOption(v, {action}) {
        if (action === "select-option") {
            setValue(v);
            onChange(v);
        }
    }

    return (
        <WindowedSelect
            openMenuOnClick={false}
            placeholder="Enter case number..."
            filterOption={createFilter({ ignoreCase: true, matchFrom: "start" })}
            options={options}
            styles={styles}
            value={value}
            onChange={onSelectOption}
            menuPlacement="bottom"
            formatOptionLabel={formatOptionLabel}
        />
    );
}

export default EditableCaseNumber;
