/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useContext, useMemo } from 'react';
import useLeadCallLogs from '../common/useLeadCallLogs.js';
import usePubSub from '../common/usePubSub.js';
import { PUBSUB_TOPIC_LEAD_CALL_LOGS_UPDATED } from '../common/webconstants.js';
import LiveDuration from '../common/liveduration.js';
import { VonageContext } from '../common/vonageContext.js';
import { sanitizeNumber } from '../common/phoneNumberUtils.js';

const WithTooltip = ({ children, tooltip }) => {
    return (
        <div
            data-tooltip={tooltip}
            data-position="bottom center"
        >
            {children}
        </div>
    );
};

export const JoinLeadCallButton = ({ lead, leadUrl, purpose, disabled, tooltip }) => {
    const { joinLeadCall, initialized: vonageInitialized, callInProgress } = useContext(VonageContext);
    const {
        callLogs,
        reload: reloadCallLogs,
    } = useLeadCallLogs({
        leadId: lead.id,
        pageNumber: 1,
        pageSize: 1,
    });
    const latestAttemptedAt = useMemo(() => {
        const latestCallLog = callLogs[0];

        if (!latestCallLog) return null;

        return latestCallLog.attemptedAt;
    }, [callLogs]);

    usePubSub({
        topic: PUBSUB_TOPIC_LEAD_CALL_LOGS_UPDATED,
        messageHandler: (_topic, { leadId }) => {
            if (leadId === lead.id) {
                reloadCallLogs();
            }
        },
    });

    const clickJoinLeadCall = () => {
        joinLeadCall({
            leadId: lead.id,
            leadUrl,
            name: lead.name,
            number: lead.phoneNumber || lead.mobileNumber,
            purpose,
        });
    };

    return (
        <WithTooltip tooltip={tooltip}>
            <button
                className="mini ui green labeled icon button"
                disabled={!vonageInitialized || callInProgress || disabled}
                onClick={clickJoinLeadCall}
                style={{ margin: 0 }}
            >
                <i className="phone square icon"/>
                Join Call
                <span style={{ marginLeft: 2 }}>
                    <LiveDuration startedAt={latestAttemptedAt} />
                </span>
            </button>
        </WithTooltip>
    );
};

export const CallLeadButton = ({ lead, number, purpose, disabled, tooltip, labelCallBack, skipSanitizeNumber }) => {
    const { callLead, initialized: vonageInitialized, callInProgress } = useContext(VonageContext);

    const confirmCall = () => {
        const sanitizedNumber = skipSanitizeNumber ? number : sanitizeNumber(number);
        const confirmedNumber = prompt('Do you want to start a call with', sanitizedNumber);

        if (!confirmedNumber) return;

        callLead({
            leadId: lead.id,
            leadUrl: `/leads/${lead.id}`,
            purpose,
            number: confirmedNumber,
            name: lead.name,
        });
    };

    return (
        <WithTooltip tooltip={tooltip}>
            <button
                className="mini ui grey button"
                disabled={!vonageInitialized || callInProgress || disabled}
                onClick={confirmCall}
                style={{ margin: 0, padding: 7 }}
            >
                <i className="phone square icon" style={{ margin: 0 }} />
                {labelCallBack && ' Call Back'}
            </button>
        </WithTooltip>
    );
};

export const CallLeadOrJoinOngoingCallButton = ({ lead, number, leadUrl, purpose, disabled, tooltip, labelCallBack }) => {
    if (lead.waitingInVonageConversation) {
        return (
            <JoinLeadCallButton
                lead={lead}
                leadUrl={leadUrl}
                purpose={purpose}
                disabled={disabled}
                tooltip={tooltip}
            />
        );
    }

    return (
        <CallLeadButton
            lead={lead}
            number={number}
            purpose={purpose}
            disabled={disabled}
            tooltip={tooltip}
            labelCallBack={labelCallBack}
        />
    );
}
