import React, { useState, useContext, useMemo, useCallback } from "react";
import * as Constants from "../common/webconstants.js";
import API from '../common/api.js';
import useLocalStorage from "../common/useLocalStorage.js";
import { CurrentUserContext } from "../common/currentUserContext.js";
import Dialog from "../common/dialog.js";
import InboxPageFilterBar from "../components/inboxpagefilterbar.js";
import InboxPageTabs from "../components/inboxpagetabs.js";
import SearchBox from '../components/searchbox.js';
import InboxPageMessages from "../components/inboxpagemessages.js";
import InboxPageChats from "../components/inboxpagechats.js";
import useInboxPageMessages from "../common/useInboxPageMessages.js";
import ValidationErrors from '../common/validationerrors.js';
import ScansPageTable from "../components/scanspagetable.js";
import IconDropdown from '../components/icondropdown.js';
import useScans from "../common/useScans.js";
import ConversationFilters from '../components/inboxpage/conversationfilters.js';
import FilterBar from '../components/inboxpage/filterbar.js';
import { Link, useHistory } from "react-router-dom";
import "./inboxpage.css";
var classNames = require('classnames');

const AddConversationDialog = ({ onClose, allCaseNumbers }) => {
    const [caseNumber, setCaseNumber] = useState(null);
    const [topicInput, setTopicInput] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const history = useHistory();

    const createConversation = async () => {
        setValidationErrors([]);

        const creationResponse = await API.createConversationWithMatterOwner({ caseNumber, topic: topicInput });

        if (creationResponse.status === 'SUCCESS') {
            onClose();
            history.push(`/inbox/chats/${creationResponse.data.id}`);
        } else {
            setValidationErrors(creationResponse.errors);
        }
    };

    const submitForm = (event) => {
        event.preventDefault();

        createConversation();
    };

    return (
        <Dialog
            title="Add Conversation"
            onClose={onClose}
            allowOverflow
        >
            <form onSubmit={submitForm}>
                <div>
                    <div>
                        Start a conversation regarding
                    </div>
                    <div style={{ marginTop: 10 }} className="ui input">
                        <SearchBox
                            allPossibleValues={allCaseNumbers}
                            onCommit={setCaseNumber}
                            initialValue=""
                        />
                    </div>
                    <div style={{ maxWidth: 228, marginTop: 10 }} className="ui input">
                        <input
                            value={topicInput}
                            onChange={(event) => setTopicInput(event.currentTarget.value)}
                            placeholder="Topic"
                            className="w-88"
                        />
                    </div>
                    {(validationErrors && validationErrors.length > 0) && (
                        <div style={{ marginTop: 10 }}>
                            <ValidationErrors errors={validationErrors} />
                        </div>
                    )}
                    <div style={{ marginTop: 20 }}>
                        <button type="submit" className="ui primary button">
                            Start Conversation
                        </button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

const useConversationsFilters = ({ localStoragePath }) => {
    const [caseNumberEq, setCaseNumberEq] = useLocalStorage(`${localStoragePath}.filters.caseNumberEq`, null);
    const filters = useMemo(() => ({
        caseNumberEq,
    }), [caseNumberEq]);
    const filterSetters = useMemo(() => ({
        setCaseNumberEq,
    }), [setCaseNumberEq]);

    return {
        filters,
        filterSetters,
        anyFilterActive: caseNumberEq !== null,
    };
};

function InboxPage({
    allCaseNumbers,
    allUsers,
    allTeams,
    initialExpandedMsgID,
    initialExpandedScanID,
    autoAck,
    activeTab,
    conversationId,
    numberOfUnreadMessages,
    archive,
}) {
    const [assigneeFilter, setAssigneeFilter] = useLocalStorage("inboxpage.assigneeFilter", null);
    const [messageTypeFilter, setMessageTypeFilter] = useLocalStorage("inboxpage.messageTypeFilter", null);
    const [caseNumber, setCaseNumber] = useLocalStorage("inboxpage.caseNumber", null);
    const [searchText, setSearchText] = useLocalStorage("inboxpage.searchText", null);
    const [readStatusFilter, setReadStatusFilter] = useLocalStorage("inboxpage.readStatus", Constants.READ_STATUS_ANY);
    const [sortOrder, setSortOrder] = useLocalStorage("inboxpage.sortOrder", Constants.SORT_NEWEST_TASK_FIRST);
    const [displayAddConversationDialog, setDisplayAddConversationDialog] = useState(false);
    const [messagesFullTextSearchValue, setMessagesFullTextSearchValue] = useLocalStorage("inboxpage.messagesFullTextSearchValue", '');
    const {
        filters: conversationsFilters,
        filterSetters: conversationsFilterSetters,
        anyFilterActive: anyConversationsFilterActive,
    } = useConversationsFilters({ localStoragePath: 'inboxpage.conversations' });
    const [showFilterBar, setShowFilterBar] = useState(
        activeTab === Constants.INBOX_CHATS_TAB ? (
            anyConversationsFilterActive || messagesFullTextSearchValue.trim().length > 0
        ) : (
            assigneeFilter !== null ||
                messageTypeFilter !== null ||
                caseNumber !== null ||
                readStatusFilter !== Constants.READ_STATUS_ANY
        )
    );
    const [filterBarHeight, setFilterBarHeight] = useState(0);
    const currentUser = useContext(CurrentUserContext);

    const onFilterBarHeightChange = useCallback((newHeight) => {
        setFilterBarHeight(newHeight);
    }, []);

    const {
        loading: loadingMessages,
        errMsg: errMsgMessages,
        messages,
        setMessageAcknowledged,
        setMessageAssignee,
        toggleWatched: toggleInboxMessageWatched,
    } = useInboxPageMessages({
        allUsers,
        allTeams,
        assigneeFilter,
        messageTypeFilter,
        caseNumber,
        searchText,
        readStatusFilter,
        sortOrder,
        currentUser,
    });

    const {
        loading: loadingScans,
        errMsg: errMsgScans,
        scans,
        setScanAcknowledged,
        setScanAssignee,
        toggleWatched: toggleScanWatched,
    } = useScans({
        allUsers,
        allTeams,
        assigneeFilter,
        caseNumber,
        searchText,
        readStatusFilter,
        sortOrder,
        currentUser,
    });

    async function onCaseNumberSearchBoxCommit(caseNumber) {
        if (caseNumber === null || caseNumber.length === 0) {
            setCaseNumber(null);
        } else {
            setCaseNumber(caseNumber);
        }
    }

    async function onTextSearchBoxCommit(text) {
        if (!text) {
            setSearchText(null);
        } else {
            setSearchText(text);
        }
    }

    function clearFilters() {
        setAssigneeFilter(null);
        setMessageTypeFilter(null);
        setCaseNumber(null);
        setSearchText(null);
        setReadStatusFilter(Constants.READ_STATUS_ANY);
        setSortOrder(Constants.SORT_NEWEST_TASK_FIRST);
    }

    const filterActive =
        assigneeFilter !== null ||
        caseNumber !== null ||
        messageTypeFilter !== null ||
        searchText !== null ||
        readStatusFilter !== Constants.READ_STATUS_ANY;

    const possibleAssignees = [
        ...allUsers.map((u) => ({
            value: `user:${u.user_id}`,
            id: u.user_id,
            label: u.name,
            name: u.name,
            is_user: true,
            is_team: false,
        })),
        ...allTeams.map((t) => ({
            value: `team:${t.team_id}`,
            id: t.team_id,
            label: t.name,
            name: t.name,
            is_user: false,
            is_team: true,
        })),
        { value: "unassigned", id: -1, label: "Unassigned", is_user: false, is_team: false },
    ];

    const allMessageTypes = [
        Constants.MESSAGE_KIND_CALL,
        Constants.MESSAGE_KIND_VOICEMAIL,
        Constants.MESSAGE_KIND_FAX,
        Constants.MESSAGE_KIND_NOTICE,
    ].map((mt) => ({ value: mt, label: Constants.MESSAGE_KIND_LABELS[mt] }));

    const activePage = () => {
        switch (activeTab) {
            case Constants.INBOX_MESSAGES_TAB:
                return (
                    <InboxPageMessages
                        loading={loadingMessages}
                        errMsg={errMsgMessages}
                        messages={messages}
                        setMessageAcknowledged={setMessageAcknowledged}
                        setMessageAssignee={setMessageAssignee}
                        allMessageTypes={allMessageTypes}
                        possibleAssignees={possibleAssignees}
                        initialExpandedMsgID={initialExpandedMsgID}
                        autoAck={autoAck}
                        setAssigneeFilter={(v) => {
                            setAssigneeFilter(v);
                            setShowFilterBar(true);
                        }}
                        setMessageTypeFilter={(v) => {
                            setMessageTypeFilter(v);
                            setShowFilterBar(true);
                        }}
                        toggleWatched={toggleInboxMessageWatched}
                        height={`calc(100% - 58px - ${filterBarHeight}px)`}
                        allCaseNumbers={allCaseNumbers}
                    />
                );
            case Constants.INBOX_CHATS_TAB:
                return (
                    <InboxPageChats
                        conversationId={conversationId}
                        archive={archive}
                        conversationsFilters={conversationsFilters}
                        messagesFullTextSearchValue={messagesFullTextSearchValue}
                        allUsers={allUsers}
                    />
                );
            default:
                return (
                    <ScansPageTable
                        loading={loadingScans}
                        errMsg={errMsgScans}
                        scans={scans}
                        setScanAcknowledged={setScanAcknowledged}
                        setScanAssignee={setScanAssignee}
                        allCaseNumbers={allCaseNumbers}
                        possibleAssignees={possibleAssignees}
                        initialExpandedScanID={initialExpandedScanID}
                        setAssigneeFilter={(v) => {
                            setAssigneeFilter(v);
                            setShowFilterBar(true);
                        }}
                        toggleWatched={toggleScanWatched}
                        height={`calc(100% - 58px - ${filterBarHeight}px)`}
                    />
                );
        }
    };

    const activeActions = () => {
        const sortOptions = [
            {value: Constants.SORT_HIGHEST_PRIORITY_FIRST, title: "Highest priority first"},
            {value: Constants.SORT_LOWEST_PRIORITY_FIRST, title: "Lowest priority first"},
            {value: Constants.SORT_OLDEST_TASK_FIRST, title: "Oldest first"},
            {value: Constants.SORT_NEWEST_TASK_FIRST, title: "Newest first"},
            {value: Constants.SORT_BY_MESSAGE_TYPE, title: "By message type"},
            {value: Constants.SORT_UNREAD_FIRST, title: "Unread first"}
        ];

        switch (activeTab) {
            case Constants.INBOX_MESSAGES_TAB:
                return (
                    <>
                        <Link to="/inbox/messages/archive" className="ui basic icon button" style={{marginRight: 10}}><i className="archive icon"/></Link>
                        <div className="ui basic icon buttons" style={{marginRight: 10}}>
                            <IconDropdown icon="sort amount down" items={sortOptions} value={sortOrder} onChange={(option) => setSortOrder(option)}/>
                            <button className={classNames("ui basic icon button", {active: showFilterBar})} onClick={() => setShowFilterBar(!showFilterBar)}><i className={classNames("filter icon", {green: filterActive})}/></button>
                        </div>
                        <Link to="/callform" className="ui labeled primary icon button"><i className="plus icon"/> Add Message</Link>
                    </>
                );
            case Constants.INBOX_CHATS_TAB:
                return (
                    <div
                        style={{ display: 'flex', gap: 10 }}
                    >
                        <Link
                            to={archive ? '/inbox/chats' : '/inbox/chats/archive'}
                            className="ui basic icon button"
                        >
                            {archive ? (
                                <i className="inbox icon" />
                            ) : (
                                <i className="archive icon" />
                            )}
                        </Link>
                        <button
                            className={classNames("ui basic icon button", { active: showFilterBar })}
                            onClick={() => setShowFilterBar(!showFilterBar)}
                        >
                            <i className={classNames("filter icon", { green: anyConversationsFilterActive })}/>
                        </button>
                        <button
                            onClick={() => setDisplayAddConversationDialog(true)}
                            className="ui labeled primary icon button"
                        >
                            <i className="plus icon"/>
                            Add Conversation
                        </button>
                        {displayAddConversationDialog && (
                            <AddConversationDialog
                                onClose={() => setDisplayAddConversationDialog(false)}
                                allCaseNumbers={allCaseNumbers}
                            />
                        )}
                    </div>
                );
            default:
                return (
                    <>
                        <Link to="/inbox/scans/archive" className="ui basic icon button" style={{marginRight: 10}}><i className="archive icon"/></Link>
                        <div className="ui basic icon buttons" style={{marginRight: 10}}>
                            <IconDropdown icon="sort amount down" items={sortOptions} value={sortOrder} onChange={(option) => setSortOrder(option)}/>
                            <button className={classNames("ui basic icon button", {active: showFilterBar})} onClick={() => setShowFilterBar(!showFilterBar)}><i className={classNames("filter icon", {green: filterActive})}/></button>
                        </div>
                        <Link to="/add_scan" className="ui labeled primary icon button"><i className="plus icon"/> Add Scan</Link>
                    </>
                );
        }
    };

    return (
        <div style={{ height: "100%" }}>
            <InboxPageTabs
                activeTab={activeTab}
                numMessages={messages.filter((msg) => msg.acknowledged === false).length}
                numScans={scans.filter((scn) => scn.acknowledged === false).length}
                numberOfUnreadMessages={numberOfUnreadMessages}
                actions={activeActions()}
                archive={archive}
            />

            {activeTab === Constants.INBOX_CHATS_TAB ? (
                <FilterBar
                    onHeightChange={onFilterBarHeightChange}
                    visible={showFilterBar}
                >
                    <ConversationFilters
                        allCaseNumbers={allCaseNumbers}
                        filters={conversationsFilters}
                        filterSetters={conversationsFilterSetters}
                        messagesFullTextSearchValue={messagesFullTextSearchValue}
                        setMessagesFullTextSearchValue={setMessagesFullTextSearchValue}
                    />
                </FilterBar>
            ) : (
                <InboxPageFilterBar
                    allCaseNumbers={allCaseNumbers}
                    allMessageTypes={allMessageTypes}
                    possibleAssignees={possibleAssignees}
                    onCaseNumberSearchBoxCommit={onCaseNumberSearchBoxCommit}
                    caseNumber={caseNumber}
                    assigneeFilter={assigneeFilter}
                    setAssigneeFilter={setAssigneeFilter}
                    messageTypeFilter={messageTypeFilter}
                    setMessageTypeFilter={setMessageTypeFilter}
                    onTextSearchBoxCommit={onTextSearchBoxCommit}
                    searchText={searchText}
                    readStatusFilter={readStatusFilter}
                    setReadStatusFilter={setReadStatusFilter}
                    sortOrder={sortOrder}
                    clearFilters={clearFilters}
                    visible={showFilterBar}
                    onUpdateHeight={onFilterBarHeightChange}
                    options={{
                        caseNumberFilter: true,
                        messageTypeFilter: activeTab === Constants.INBOX_MESSAGES_TAB,
                        textSearchFilter: true,
                        assigneeFilter: true,
                        readStatusFilter: true,
                    }}
                />
            )}

            {activePage()}
        </div>
    );
}

export default InboxPage;
