import React from 'react';
import './pushnotificationpreview.css';

function PushNotificationPreview({title, detail}) {
    return (
        <div className="pushnotificationpreview brandbackground brandborder">
            <div><img src="/icon.png" alt="icon" className="appicon"/><span className="apptitle">CASE ACCESS</span></div>
            <div className="notificationtitle">{title}</div>
            <div className="notificationbody">{detail}</div>
        </div>
    );
}

export default PushNotificationPreview;
