import React from 'react';

function withLoader(loading, errMsg, contentBuilder) {
    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else if (errMsg) {
        return (
            <div>
                <div className="ui visible error message" style={{marginTop: 20}}>{errMsg}</div>
            </div>
        );
    } else {
        return contentBuilder();
    }
}

export default withLoader;
