/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import LeadsPageArchive from '../components/leadspage/leadspagearchive.js';
import LeadsPageCurrent from '../components/leadspage/leadspagecurrent.js';
import usePinnedLeads from '../common/usePinnedLeads.js';
import useRecentlyViewedLeads from '../common/useRecentlyViewedLeads.js';
import useLeadModelDescription from '../common/useLeadModelDescription.js';
import useOpportunityModelDescription from '../common/useOpportunityModelDescription.js';

function LeadsPage({ expandedLeadID, allUsers, allTeams, archive, leadsUrl: leadsUrlParam }) {
    const leadsUrl = leadsUrlParam || '/leads';
    const leadsArchiveUrl = `${leadsUrl}/archive`;
    const newLeadUrl = '/add_lead';
    const { leadModelDescription } = useLeadModelDescription();
    const { opportunityModelDescription } = useOpportunityModelDescription();
    const {
        pinnedLeads,
        loadingPinnedLeads,
        pinnedLeadsErrorMessage,
        togglePinLead,
        leadIsPinned,
    } = usePinnedLeads()
    const {
        recentlyViewedLeads,
        reportLeadViewed,
    } = useRecentlyViewedLeads();

    if (archive) {
        return (
            <LeadsPageArchive
                expandedLeadID={expandedLeadID}
                allUsers={allUsers}
                allTeams={allTeams}
                leadModelDescription={leadModelDescription}
                opportunityModelDescription={opportunityModelDescription}
                togglePinLead={togglePinLead}
                leadIsPinned={leadIsPinned}
                reportLeadViewed={reportLeadViewed}
                buildLeadUrl={(leadId) => `${leadsArchiveUrl}/${leadId}`}
                leadsUrl={leadsUrl}
                leadsArchiveUrl={leadsArchiveUrl}
            />
        );
    }

    return (
        <LeadsPageCurrent
            expandedLeadID={expandedLeadID}
            allUsers={allUsers}
            allTeams={allTeams}
            leadModelDescription={leadModelDescription}
            opportunityModelDescription={opportunityModelDescription}
            pinnedLeads={pinnedLeads}
            loadingPinnedLeads={loadingPinnedLeads}
            pinnedLeadsErrorMessage={pinnedLeadsErrorMessage}
            togglePinLead={togglePinLead}
            leadIsPinned={leadIsPinned}
            recentlyViewedLeads={recentlyViewedLeads}
            reportLeadViewed={reportLeadViewed}
            buildLeadUrl={(leadId) => `${leadsUrl}/${leadId}`}
            leadsUrl={leadsUrl}
            leadsArchiveUrl={leadsArchiveUrl}
            newLeadUrl={newLeadUrl}
        />
    );
}

export default LeadsPage;
