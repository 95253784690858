import React, { useState, useEffect, useMemo, useContext } from "react";
import usePreventBodyScroll from "../common/usePreventBodyScroll.js";
import useLocalStorage from "../common/useLocalStorage.js";
import { Link } from "react-router-dom";
import MentionText from "../components/mentiontext.js";
import withLoader from "../common/withLoader.js";
import API from "../common/api.js";
import { CurrentUserContext } from '../common/currentUserContext.js';
import { formatHumanDateTime } from "../common/formatting.js";
import * as Constants from "../common/webconstants.js";
import usePubSub from '../common/usePubSub.js';
import useNotifications from '../common/useNotifications.js';
import { motion, AnimatePresence } from "framer-motion";
import "./mentionsbubble.css";

function BasicNotification({ notification, icon, headline, subheadline, subjectUrl, children }) {
    const { archiveNotification, markNotificationRead } = useNotificationUpdate();

    return (
        <AnimatePresence>
            <motion.div className="event" initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <div className="label">
                    <i className={`${icon} icon`} />
                </div>
                <div className="content">
                    <div className="summary">
                        <Link
                            to={subjectUrl}
                            style={{ fontWeight: notification.read ? "normal" : "bold" }}
                            onClick={() => markNotificationRead(notification.id)}
                        >
                            {headline}
                        </Link>
                        <div className="date">
                            {subheadline}
                        </div>
                        {children}
                    </div>
                </div>
                <div className="label">
                    {!notification.archived && <i className="archive dim link icon" onClick={() => archiveNotification(notification.id)} />}
                </div>
            </motion.div>
        </AnimatePresence>
    );
}

function MentionNotification({ notification, subjectUrl, text, teams, users }) {
    const timestamp = formatHumanDateTime(notification.createdAt);
    const casenum = notification.associatedInfo.caseNumber && ` - ${notification.associatedInfo.caseNumber}`;
    const mentionDetails = notification.associatedInfo.text && (
        <div className="ui visible message" style={{ fontWeight: "normal", fontSize: "0.9em" }}>
            <MentionText text={notification.associatedInfo.text} possibleMentionees={[...users, ...teams].map((a) => a.name)} />
        </div>
    );

    return (
        <BasicNotification
            notification={notification}
            icon="comment alternate outline"
            headline={text}
            subheadline={(
                <>
                    {timestamp}
                    {casenum}
                </>
            )}
            subjectUrl={subjectUrl}
        >
            {mentionDetails}
        </BasicNotification>
    );
}

function LeadCommentMentionNotification({ notification, teams, users }) {
    return (
        <MentionNotification
            notification={notification}
            subjectUrl={notification.associatedInfo.leadPath}
            text={notification.description}
            teams={teams}
            users={users}
        />
    );
}

function ScanCommentMentionNotification({ notification, teams, users }) {
    return (
        <MentionNotification
            notification={notification}
            subjectUrl={notification.associatedInfo.scanPath}
            text={notification.description}
            teams={teams}
            users={users}
        />
    );
}

function TaskCommentMentionNotification({ notification, teams, users }) {
    return (
        <MentionNotification
            notification={notification}
            subjectUrl={notification.associatedInfo.taskPath}
            text={notification.description}
            teams={teams}
            users={users}
        />
    );
}

function InboxMessageCommentMentionNotification({ notification, teams, users }) {
    return (
        <MentionNotification
            notification={notification}
            subjectUrl={notification.associatedInfo.inboxMessagePath}
            text={notification.description}
            teams={teams}
            users={users}
        />
    );
}

function LeadScheduledCallDueNotification({ notification }) {
    const timestamp = formatHumanDateTime(notification.createdAt);

    return (
        <BasicNotification
            notification={notification}
            icon="phone"
            headline={notification.description}
            subheadline={timestamp}
            subjectUrl={notification.associatedInfo.leadPath}
        />
    )
}

function AssignedToLeadNotification({ notification }) {
    const timestamp = formatHumanDateTime(notification.createdAt);

    return (
        <BasicNotification
            notification={notification}
            icon="flag"
            headline={notification.description}
            subheadline={timestamp}
            subjectUrl={notification.associatedInfo.leadPath}
        />
    )
}

function AssignedToTaskNotification({ notification }) {
    const timestamp = formatHumanDateTime(notification.createdAt);
    const casenum = notification.associatedInfo.caseNumber && ` - ${notification.associatedInfo.caseNumber}`;

    return (
        <BasicNotification
            notification={notification}
            icon="flag"
            headline={notification.description}
            subheadline={(
                <>
                    {timestamp}
                    {casenum}
                </>
            )}
            subjectUrl={notification.associatedInfo.taskPath}
        />
    )
}

function TeamAssignedToTaskNotification({ notification }) {
    const timestamp = formatHumanDateTime(notification.createdAt);
    const casenum = notification.associatedInfo.caseNumber && ` - ${notification.associatedInfo.caseNumber}`;

    return (
        <BasicNotification
            notification={notification}
            icon="flag"
            headline={notification.description}
            subheadline={(
                <>
                    {timestamp}
                    {casenum}
                </>
            )}
            subjectUrl={notification.associatedInfo.taskPath}
        />
    )
}

function NewCallOnQueueNotification({ notification }) {
    const timestamp = formatHumanDateTime(notification.createdAt);

    return (
        <BasicNotification
            notification={notification}
            icon="angle double right"
            headline={notification.description}
            subheadline={timestamp}
            subjectUrl={notification.associatedInfo.queuePath}
        />
    )
}

function LeadRecommendationChangedNotification({ notification }) {
    const timestamp = formatHumanDateTime(notification.createdAt);

    return (
        <BasicNotification
            notification={notification}
            icon="tag"
            headline={notification.description}
            subheadline={timestamp}
            subjectUrl={notification.associatedInfo.leadPath}
        />
    )
}

function TeamConversationAssigneeUpdatedNotification({ notification }) {
    const timestamp = formatHumanDateTime(notification.createdAt);

    return (
        <BasicNotification
            notification={notification}
            icon="address card outline"
            headline={notification.description}
            subheadline={timestamp}
            subjectUrl={notification.associatedInfo.conversationPath}
        />
    )
}

function NewMessageNotification({ notification }) {
    const timestamp = formatHumanDateTime(notification.createdAt);

    return (
        <BasicNotification
            notification={notification}
            icon="envelope"
            headline={notification.description}
            subheadline={timestamp}
            subjectUrl={notification.associatedInfo.conversationPath}
        />
    )
}

function Notification({ notification, teams, users }) {
    switch (notification.type) {
        case Constants.NOTIFICATION_TYPES.LEAD_COMMENT_MENTION:
            return (
                <LeadCommentMentionNotification
                    notification={notification}
                    teams={teams}
                    users={users}
                />
            );
        case Constants.NOTIFICATION_TYPES.SCAN_COMMENT_MENTION:
            return (
                <ScanCommentMentionNotification
                    notification={notification}
                    teams={teams}
                    users={users}
                />
            );
        case Constants.NOTIFICATION_TYPES.TASK_COMMENT_MENTION:
            return (
                <TaskCommentMentionNotification
                    notification={notification}
                    teams={teams}
                    users={users}
                />
            );
        case Constants.NOTIFICATION_TYPES.INBOX_MESSAGE_COMMENT_MENTION:
            return (
                <InboxMessageCommentMentionNotification
                    notification={notification}
                    teams={teams}
                    users={users}
                />
            );
        case Constants.NOTIFICATION_TYPES.LEAD_SCHEDULED_CALL_DUE:
            return (
                <LeadScheduledCallDueNotification
                    notification={notification}
                />
            );
        case Constants.NOTIFICATION_TYPES.ASSIGNED_TO_LEAD:
            return (
                <AssignedToLeadNotification
                    notification={notification}
                />
            );
        case Constants.NOTIFICATION_TYPES.ASSIGNED_TO_TASK:
            return (
                <AssignedToTaskNotification
                    notification={notification}
                />
            );
        case Constants.NOTIFICATION_TYPES.TEAM_ASSIGNED_TO_TASK:
            return (
                <TeamAssignedToTaskNotification
                    notification={notification}
                />
            );
        case Constants.NOTIFICATION_TYPES.NEW_CALL_ON_QUEUE:
            return (
                <NewCallOnQueueNotification notification={notification} />
            );
        case Constants.NOTIFICATION_TYPES.LEAD_RECOMMENDATION_CHANGED:
            return (
                <LeadRecommendationChangedNotification notification={notification} />
            );
        case Constants.NOTIFICATION_TYPES.TEAM_CONVERSATION_ASSIGNEE_UPDATED:
            return (
                <TeamConversationAssigneeUpdatedNotification notification={notification} />
            );
        case Constants.NOTIFICATION_TYPES.NEW_MESSAGE:
            return (
                <NewMessageNotification notification={notification} />
            );
        default:
            console.log(`Don't know how to display notifications of type ${notification.type}`)
            return null;
    }
}


const cardStyle = {
    float: "right",
    width: 500,
    marginTop: 5,
    maxHeight: "70vh",
    overflowY: "auto",
    zIndex: 2,
};
const headerStyle = {
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 1,
    borderBottom: "1px solid rgba(34,36,38,.1)",
};
const footerStyle = {
    position: "sticky",
    bottom: 0,
    background: "white",
    zIndex: 1,
    borderTop: "1px solid rgba(34,36,38,.1)",
};
const spaceBetweenStyle = { display: "flex", justifyContent: "space-between" };

const useNotificationUpdate = () => {
    const archiveNotification = async (notificationId) => {
        await API.archiveNotification(notificationId);
    };

    const markAllNotificationsRead = async () => {
        await API.markAllNotificationsRead();
    };

    const markNotificationRead = async (notificationId) => {
        await API.markNotificationRead(notificationId);
    };

    return {
        archiveNotification,
        markAllNotificationsRead,
        markNotificationRead,
    };
};

function MentionsBubble({ onCloseClicked, users, teams }) {
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const currentUser = useContext(CurrentUserContext);
    const [showArchive, setShowArchive] = useState(false);
    const [readStatusFilter, setReadStatusFilter] = useLocalStorage(
        "mentionsbubble.readStatus",
        Constants.READ_STATUS_ANY
    );
    const notificationsFilters = useMemo(() => {
        return {
            page: 1,
            pageSize: 500,
            archived: showArchive,
        };
    }, [showArchive]);
    usePreventBodyScroll();
    const {
        notifications,
        reload: reloadNotifications,
        initialized: notificationsInitialized,
        errorMessage: errMsg,
    } = useNotifications(notificationsFilters);
    const { markAllNotificationsRead } = useNotificationUpdate();

    useEffect(() => {
        if (readStatusFilter === Constants.READ_STATUS_ANY) {
            setFilteredNotifications(notifications);
        } else if (readStatusFilter === Constants.READ_STATUS_UNREAD) {
            setFilteredNotifications(
                notifications.filter((notification) => !notification.read)
            );
        } else {
            setFilteredNotifications(
                notifications.filter((notification) => notification.read)
            );
        }
    }, [notifications, readStatusFilter]);

    usePubSub({
        topic: Constants.PUBSUB_TOPIC_NOTIFICATIONS_UPDATED,
        messageHandler: (topic, data = {}) => {
            if (topic === Constants.PUBSUB_TOPIC_NOTIFICATIONS_UPDATED) {
                if (!data.userId || data.userId === currentUser.userID) {
                    reloadNotifications();
                }
            }
        },
    });

    const content = withLoader(!notificationsInitialized, errMsg, () => {
        return (
            <div className="ui feed content">
                {filteredNotifications.length === 0 && 'No notifications'}
                {filteredNotifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        notification={notification}
                        teams={teams}
                        users={users}
                    />
                ))}
            </div>
        );
    });

    const showUnreadOnly = readStatusFilter === Constants.READ_STATUS_UNREAD;
    const readStatusCheckbox = (
        <div
            className={`ui checkbox my-auto ${showUnreadOnly ? "checked" : ""}`}
            onClick={() =>
                setReadStatusFilter(showUnreadOnly ? Constants.READ_STATUS_ANY : Constants.READ_STATUS_UNREAD)
            }
        >
            <input type="checkbox" className="hidden" checked={showUnreadOnly} onChange={() => {}} />
            <label>Unread Only</label>
        </div>
    );

    const headerText = showArchive ? "Notifications Archive" : "Notifications";
    const buttonText = showArchive ? "Show Current" : "Show Archive";
    const showArchiveButton = (
        <button
            className="ui tertiary button"
            style={{ marginTop: "auto", marginBottom: "auto", padding: 0 }}
            onClick={() => setShowArchive(!showArchive)}
        >
            {buttonText}
        </button>
    );
    const markAllAsReadButton = (
        <button
            className="ui tertiary button"
            onClick={markAllNotificationsRead}
            style={{ marginTop: "auto", marginBottom: "auto", padding: 0 }}
        >
            Mark All as Read
        </button>
    );
    const header = (
        <div className="content" style={headerStyle}>
            <div style={spaceBetweenStyle}>
                <h3 style={{ margin: 0 }}>{headerText}</h3>
                {markAllAsReadButton}
            </div>
        </div>
    );
    const footer = (
        <div className="content" style={footerStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {readStatusCheckbox}
                {showArchiveButton}
            </div>
        </div>
    );

    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <div
                className="modal-overlay"
                style={{ zIndex: 1, position: "absolute", right: 0, top: 0, width: "100%" }}
                onClick={onCloseClicked}
            />
            <div className="ui fluid limitedwidth container">
                <div className="ui card" style={cardStyle}>
                    {header}
                    {content}
                    {footer}
                </div>
            </div>
        </div>
    );
}

export default MentionsBubble;
