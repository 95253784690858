/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useState, useEffect, useCallback } from 'react';
import API from '../common/api.js';

const useLead = ({ leadId }) => {
    const [lead, setLead] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const loadData = useCallback(async () => {
        setLoading(true);

        const leadResponse = await API.getLead(leadId);

        if (leadResponse) {
            setLead(leadResponse.lead);
            setLoading(false);
            setErrorMessages(leadResponse.errorMessages);
        }

        setInitialized(true);
    }, [leadId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        lead,
        loading,
        initialized,
        reload: loadData,
        errorMessages,
    };
};

export default useLead;
