/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useContext } from 'react';
import { VonageContext } from '../common/vonageContext.js';
import API from '../common/api.js';

export const RemoveLeadButton = ({lead, reloadLeads, disabled}) => {
    const { initialized: vonageInitialized, callInProgress } = useContext(VonageContext);

    const unenqueueLeadClick = () => {
        if(window.confirm(`Are you sure you want to remove lead ${lead.id}?`) == true) {
            API.unenqueueLead(lead.id).then(() => reloadLeads());
        }
    }
    return (
        <button
            className="ui button mini red"
            disabled={!vonageInitialized || callInProgress || disabled}
            onClick={unenqueueLeadClick}
        >
            Remove
        </button>
    )
}
