/* eslint jsx-a11y/anchor-is-valid: 0 */ 
import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import CaseNumber from '../components/casenumber.js';
import { useQuery, queryCache } from "react-query";
import API from '../common/api.js';
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';
import { uniqBy } from 'lodash';
import {CurrentUserContext} from '../common/currentUserContext.js';


function MRORow({task, setCaseNumber, setShowFilterBar}) {
    const caseNumber = task.case_number && (
        <>
            <br/>
            <a href="#" onClick={() => {setCaseNumber(task.case_number); setShowFilterBar(true);}} className={`ui horizontal icon label`}>
                <CaseNumber caseNumber={task.case_number}/>
            </a>
        </>
    );

    return (
        <tr>
            <td className="border-none">
                <Link to={`/tasks/${task.task_id}`}>{task.title}</Link>
                {caseNumber}
            </td>
        </tr>
    );
}

function PlaceholderRow() {
    return (
        <tr className="dim">
            <td className="border-none">
                None yet...
            </td>
        </tr>
    );
}

function ErrorRow() {
    return (
        <tr className="dim">
            <td className="border-none">
                Failed to load pinned tasks list!
            </td>
        </tr>
    );
}

function LoadingRow() {
    return (
        <tr className="dim">
            <td className="border-none">
                Loading...
            </td>
        </tr>
    );
}


function PinnedTasks({setCaseNumber, setShowFilterBar}) {
    const QUERY_PINNED_TASKS = "pinned-tasks";
    const [tasks, setTasks] = useState([]);
    const { loading, error } = useQuery(QUERY_PINNED_TASKS, API.getPinnedTasks, { onSuccess: (data) => setTasks(data) });
    usePubSub({topic: Constants.PUBSUB_TOPIC_PINNED_TASKS_UPDATED, messageHandler: handleServerMessage});
    usePubSub({topic: Constants.PUBSUB_TOPIC_PINNED_TASK, messageHandler: handlePinnedTask});
    usePubSub({topic: Constants.PUBSUB_TOPIC_UNPINNED_TASK, messageHandler: handleUnpinnedTask});
    const currentUser = useContext(CurrentUserContext);

    function handleServerMessage(topic, data) {
        if (topic === Constants.PUBSUB_TOPIC_PINNED_TASKS_UPDATED) {
            if (data.user_id === currentUser.userID) {
                setTasks(data.tasks);
            }
        }
    }

    function handlePinnedTask(msg, task) {
        const MAX_MRO_ITEMS = 5;
        setTasks(tasks => {
            const mro = uniqBy([task, ...tasks], e => e.task_id).slice(0, MAX_MRO_ITEMS);
            API.setPinnedTasks(mro.map(t => ({id: t.task_id}))).then(() => queryCache.invalidateQueries(QUERY_PINNED_TASKS));
            return mro;
        });
    }

    function handleUnpinnedTask(msg, task) {
        setTasks(tasks => {
            const mro = tasks.filter(t => t.task_id !== task.task_id);
            API.setPinnedTasks(mro.map(t => ({id: t.task_id}))).then(() => queryCache.invalidateQueries(QUERY_PINNED_TASKS));
            return mro;
        });
    }

    const rows = 
        loading ? <LoadingRow/> :
        (tasks && tasks.length > 0) ? tasks.map(task => <MRORow key={task.task_id} task={task} setCaseNumber={setCaseNumber} setShowFilterBar={setShowFilterBar}/>) :
        error ? <ErrorRow/> :
        <PlaceholderRow/>;

    return (
        <div className="dividing rightbar" style={{maxWidth: 250}}>
            <p style={{fontWeight: 'bold', marginTop: '1em', marginBottom: 0, color: 'rgb(95, 109, 131)'}}><i className="thumbtack icon"/> PINNED TASKS</p>
            <table className="ui very basic table" style={{marginBottom: '1em', marginTop: 0}}>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    );
}

export default PinnedTasks;
