import { useState, useCallback, useEffect } from 'react';
import API from './api.js';
import usePrevious from '../common/usePrevious.js';

function useScanData(scanID, setLoading, setErrMsg, onUpdate) {
    const [scan, setScan] = useState(null);
    const prevScanID = usePrevious(scanID);

    const loadScan = useCallback(async () => {
        setLoading(true);

        const data = await API.getScan(scanID);
        if (data) {
            setScan(data);
            onUpdate(data);
            setLoading(false);
            
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, [scanID, setLoading, setErrMsg, onUpdate]);

    useEffect(() => {
        setScan(null);
        if  (scanID) {
            if (scanID !== prevScanID) {
                loadScan();
            }
        }
    }, [scanID, loadScan, prevScanID, setErrMsg]);

    return [scan];
}

export default useScanData;
