import React, { useMemo } from 'react';
import useAllUsers from '../../common/useAllUsers.js';
import useStatistics from '../../common/useStatistics.js';
import AttorneyStatistics from './attorneystatistics.js';
import Spinner from '../spinner.js';
import Center from '../center.js';
import {
    TakeItDenyItRatioCard,
    WonLostRatioCard,
    ResolvedCard,
    IncomingCard,
} from './categorycards.js';
import useLocalStorage from '../../common/useLocalStorage.js';
import { SelectField } from '../../common/form.js';

const ErrorBanner = ({ errorMessage }) => {
    return (
        <div
            style={{ background: 'rgb(255, 192, 192)', padding: 10 }}
        >
            {errorMessage}
        </div>
    );
};

const UnexpectedErrorBanner = () => {
    return (
        <ErrorBanner errorMessage="Unexpected error. Please try again or report this problem, if it persists." />
    );
};

const GeneralSection = () => {
    const {
        statistics,
        initialized,
        errorMessage,
        loading,
    } = useStatistics();

    if (!initialized || loading) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }
    if (errorMessage) {
        return (
            <ErrorBanner errorMessage={errorMessage} />
        );
    }
    if (!statistics) {
        return (
            <UnexpectedErrorBanner />
        );
    }

    return (
        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap', justifyContent: 'center' }}>
            <IncomingCard statistics={statistics} />
            <ResolvedCard statistics={statistics} />
            <TakeItDenyItRatioCard statistics={statistics} />
            <WonLostRatioCard statistics={statistics} />
        </div>
    );
};

const AttorneySection = ({ allUsers, attorneyUserId, setAttorneyUserId }) => {
    const attorneysAsOptions = useMemo(() => {
        return allUsers
            .filter((user) => user.isAttorney)
            .map((user) => ({ value: user.user_id, label: user.name }));
    }, [allUsers]);

    return (
        <>
            <div style={{ marginBottom: 15 }}>
                <SelectField
                    options={attorneysAsOptions}
                    value={attorneyUserId}
                    onChange={(selectedUserId) => setAttorneyUserId(selectedUserId)}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <AttorneyStatistics attorneyUserId={attorneyUserId} />
            </div>
        </>
    );
};

const Section = ({ headline, children }) => {
    const padding = 20;

    return (
        <div style={{ backgroundColor: '#f0f0f0', padding, borderRadius: 10 }}>
            <div style={{ paddingBottom: padding }}>
                <span
                    style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: 'rgb(213, 213, 213)',
                        fontStyle: 'italic',
                        textTransform: 'uppercase',
                    }}
                >
                    {headline}
                </span>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

const AdminDashboard = ({ allUsers }) => {
    const { attorneyUsers } = useAllUsers({ allUsers });
    const [attorneyUserId, setAttorneyUserId] = useLocalStorage('dashboardpage.attorneyUserId', attorneyUsers[0] ? attorneyUsers[0].user_id : null);

    return (
        <div>
            <Section headline="All-Over">
                <GeneralSection />
            </Section>
            <div style={{ marginTop: 20 }}>
                <Section headline="Per Attorney">
                    <AttorneySection
                        allUsers={allUsers}
                        attorneyUserId={attorneyUserId}
                        setAttorneyUserId={setAttorneyUserId}
                    />
                </Section>
            </div>
        </div>
    );
};

export default AdminDashboard;
