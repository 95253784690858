import React, { useState, useEffect, useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import PushNotificationPreview from '../pushnotificationpreview.js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import usePrevious from '../../common/usePrevious.js';
import useClientData from '../../common/useClientData.js';
var classNames = require('classnames');

function VerifyReopen({caseNumber, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        hearingDateSet: null,
        hearingEventTitle: "",
        hearingDate: "",
        hearingStartTime: {value: "10:00", label: "10:00 AM"},
        hearingEndTime: {value: "10:30", label: "10:30 AM"},
        hearingLocation: ""
    });
    const [errMsg, setErrMsg] = useState(null);
    const [loading, setLoading] = useState(true);
    const previousHearingStartTime = usePrevious(formState.hearingStartTime);
    useClientData(caseNumber, setLoading, setErrMsg, useCallback(clientData => {
        updateFormState({hearingEventTitle: `UIAB Hearing/ ${clientData.name}`});
    }, [updateFormState]));
    
    const notifTitle = "Still Waiting on Hearing Date";
	const notifDetail = "The Appeals Office still has not produced a new hearing date for us. We checked with them today and we are still waiting for a new date. If we don't get a hearing date in the next 15 days we will check again.";

    const saveForm = useCallback(async (matterID) => {
        return await API.saveVerifyReopen(formState.hearingDateSet, formState.hearingEventTitle, formState.hearingDate, formState.hearingStartTime.value, formState.hearingEndTime.value, formState.hearingLocation, notifTitle, notifDetail, matterID);
    }, [formState.hearingDateSet, formState.hearingEventTitle, formState.hearingDate, formState.hearingStartTime.value, formState.hearingEndTime.value, formState.hearingLocation, notifTitle, notifDetail]);

    const possibleStartTimes = [
        {value: "07:00", label: "7:00 AM"},
        {value: "07:30", label: "7:30 AM"},
        {value: "08:00", label: "8:00 AM"},
        {value: "08:30", label: "8:30 AM"},
        {value: "09:00", label: "9:00 AM"},
        {value: "09:30", label: "9:30 AM"},
        {value: "10:00", label: "10:00 AM"},
        {value: "10:30", label: "10:30 AM"},
        {value: "11:00", label: "11:00 AM"},
        {value: "11:30", label: "11:30 AM"},
        {value: "12:00", label: "12:00 PM"},
        {value: "12:30", label: "12:30 PM"},
        {value: "13:00", label: "1:00 PM"},
        {value: "13:30", label: "1:30 PM"},
        {value: "14:00", label: "2:00 PM"},
        {value: "14:30", label: "2:30 PM"},
        {value: "15:00", label: "3:00 PM"},
        {value: "15:30", label: "3:30 PM"},
        {value: "16:00", label: "4:00 PM"},
        {value: "16:30", label: "4:30 PM"},
        {value: "17:00", label: "5:00 PM"},
        {value: "17:30", label: "5:30 PM"},
        {value: "18:00", label: "6:00 PM"},
        {value: "18:30", label: "6:30 PM"},
        {value: "19:00", label: "7:00 PM"},
        {value: "19:30", label: "7:30 PM"},
        {value: "20:00", label: "8:00 PM"}
    ];
    const possibleEndTimes = [
        ...possibleStartTimes,
        {value: "20:00", label: "8:00 PM"},
    ].filter(o => formState.hearingStartTime && (formState.hearingStartTime.value < o.value));

    const phstv = previousHearingStartTime ? previousHearingStartTime.value : null;
    useEffect(() => {
        if (previousHearingStartTime && (previousHearingStartTime.value !== formState.hearingStartTime.value)) {
            updateFormState({'hearingEndTime': possibleEndTimes[0]});
        }
    }, [phstv, formState.hearingStartTime.value, possibleEndTimes, previousHearingStartTime, updateFormState])

    function shouldDisableSaveButton() {
        return (formState.hearingDateSet === null) || 
            (formState.hearingDateSet === true && ((formState.hearingDate === "") || (formState.hearingLocation === "")));
    }

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else if (errMsg) {
        return (
            <div>
                <div className="ui error message" style={{marginTop: 20}}>{errMsg}</div>
            </div>
        );
    } else {
        return (
            <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={shouldDisableSaveButton()} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
                <p>Did the CUIAB set a new hearing date?</p>
                <div className="field">
                    <div className="ui buttons">
                        <div className={classNames("ui toggle button", {active: formState.hearingDateSet === true})} onClick={() => updateFormState({hearingDateSet: true})}>Hearing Date Set</div>
                        <div className="or"></div>
                        <div className={classNames("ui toggle button", {active: formState.hearingDateSet === false})} onClick={() => updateFormState({hearingDateSet: false})}>Still Waiting for Hearing Date</div>
                    </div>
                </div>
    
                {formState.hearingDateSet === true && (
                    <>
                        <p>Please enter the details of the hearing for {caseNumber} below. Doing so will add an event in the matter's calendar and send a push notification to the client.</p>
                        <p>A prep meeting will be added to the calendar one business day before the hearing.</p>
                        <div className="field">
                            <label htmlFor="title">Title:</label>
                            <input type="text" name="title" value={formState.hearingEventTitle} disabled required/>
                        </div>
                        <div className="three fields">
                            <div className="field">
                                <label htmlFor="date">Date:</label>
                                <input type="date" name="date" value={formState.hearingDate} onChange={ev => updateFormState({hearingDate: ev.target.value})} required/>
                            </div>
                            <div className="field">
                                <label htmlFor="starttime">Start time:</label>
                                <Dropdown placeholder="Please select" options={possibleStartTimes} onChange={option => updateFormState({hearingStartTime: option})} value={formState.hearingStartTime} required/>
                            </div>
                            <div className="field">
                                <label htmlFor="endtime">End time:</label>
                                <Dropdown placeholder="Please select" options={possibleEndTimes} onChange={option => updateFormState({hearingEndTime: option})} value={formState.hearingEndTime} required/>
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="location">Location:</label>
                            <input type="text" name="location" value={formState.hearingLocation} onChange={ev => updateFormState({hearingLocation: ev.target.value})} required placeholder="Enter location..."/>
                        </div>
                    </>
                )}

                {formState.hearingDateSet === false && (
                    <>
                        <div className="field">
                            <p>Please confirm that the Appeals Office still has not produced a new hearing date for {caseNumber}. Doing so will add a note to this matter and send a push notification to the client:</p>
                            <PushNotificationPreview title={notifTitle} detail={notifDetail}/>
                        </div>
                    </>
                )}
            </TaskForm>
        );
    }
}

export default VerifyReopen;
