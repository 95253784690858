import { useState } from 'react';
import API from './api.js';

const useUpdateTeamConversationAssignee = ({ onAssignmentUpdated }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const updateTeamConversationAssignee = async ({ userId, teamId, conversationId }) => {
        setErrors([]);
        setLoading(true);

        const result = await API.updateTeamConversationAssignee({ teamId, conversationId, userId });

        if (result) {
            if (onAssignmentUpdated) onAssignmentUpdated(userId);
        } else {
            setErrors(['Failed to update assignee']);
        }

        setLoading(false);
    };

    return {
        errors,
        updateTeamConversationAssignee,
        loading,
    };
};

export default useUpdateTeamConversationAssignee;
