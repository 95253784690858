import React, { useState } from 'react';
import SearchBox from '../components/searchbox.js';
import API from '../common/api.js';

function DevicesPage({allCaseNumbers}) {
    const [loading, setLoading] = useState(false);
    const [caseNumber, setCaseNumber] = useState(null);
    const [tokens, setTokens] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [opens, setOpens] = useState([]);
    const [matterID, setMatterID] = useState(null);
    const [notifTitle, setNotifTitle] = useState("");
    const [notifBody, setNotifBody] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    async function onSearchBoxCommit(caseNumber) {
        if (caseNumber.length === 0) {
            setTokens([]);
            setOpens([]);
            setNotifications([]);
            setCaseNumber(null);
            setMatterID(null);
            setErrMsg(null);

        } else {
            setLoading(true);
            setTokens([]);
            setOpens([]);
            setNotifications([]);
            setCaseNumber(null);
            setMatterID(null);
            setErrMsg(null);
            
            const data = await API.getDevices(caseNumber);
            if (data) {
                setTokens(data.tokens);
                setOpens(data.opens);
                setNotifications(data.notifs);
                setCaseNumber(caseNumber);
                setMatterID(data.matter_id);
                setLoading(false);
                setErrMsg(null);
                
            } else {
                setErrMsg("Unable to load data from server");
                setCaseNumber(caseNumber);
                setLoading(false);
            }
        }
    }

    async function onSendNotificationClicked() {
        setIsSending(true);

        await API.sendPushNotification(notifTitle, notifBody, matterID);
        
        const data = await API.getDevices(caseNumber);
        if (data) {
            setTokens(data.tokens);
            setOpens(data.opens);
            setNotifications(data.notifs);
            setCaseNumber(caseNumber);
            setMatterID(data.matter_id);
            setLoading(false);
            setErrMsg(null);
        }
        setIsSending(false);
    }

    const tokenRows = tokens.map((token, inx) => 
        <tr key={inx}>
            <td>{token.token}</td>
        </tr>
    );

    const notificationRows = notifications.map((notif, inx) => 
        <tr key={inx}>
            <td>{notif.timestamp}</td>
            <td>{notif.title}</td>
            <td><div className={`ui ${notif.statuscolor} horizontal label`}>{notif.status}</div></td>
            <td>{notif.recipient}</td>
        </tr>
    );

    const openRows = opens.map((open, inx) => 
        <tr key={inx}>
            <td>{open.timestamp}</td>
            <td>{open.device}</td>
            <td>{open.ip_address}</td>
        </tr>
    );

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;
    const searchbox = <SearchBox allPossibleValues={allCaseNumbers} onCommit={onSearchBoxCommit} initialValue={caseNumber || ""}/>;

    if (loading) {
        return (
            <div style={{paddingTop: 10}}>
                {searchbox}
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else if (caseNumber === null) {
        return (
            <div style={{paddingTop: 10}}>
                {searchbox}
                <div className="ui info icon message">
                    <i className="lightbulb outline icon"></i>
                    <p className="content">Please enter a case number above.</p>
                </div>
            </div>
        );
    } else {
        let sendNotifButtonClasses = "ui primary button";
        if (notifTitle === null || notifTitle.length === 0 || notifBody === null || notifBody.length === 0) {
            sendNotifButtonClasses = `${sendNotifButtonClasses} disabled`;
        }
        if (isSending) {
            sendNotifButtonClasses = `${sendNotifButtonClasses} loading`;
        }
        let sendNotificationForm = null;
        sendNotificationForm = (tokens.length > 0) && (
            <div>
                <div className="ui hidden divider"/>
                <h2 className="ui header">Trigger a push notification for {caseNumber}</h2>
                <div className="ui form segment">
                    <p>Fill out the fields below to send a push notification to all devices that have opened {caseNumber} and have push notifications enabled.</p>
                    <div className="field">
                        <label htmlFor="title">Title:</label>
                        <input type="text" name="title" value={notifTitle} placeholder="Enter title" onChange={(ev) => setNotifTitle(ev.target.value)}/>
                    </div>
                    <div className="field">
                        <label htmlFor="body">Body:</label>
                        <textarea type="text" name="body" value={notifBody} rows="4" placeholder="Enter body text" onChange={(ev) => setNotifBody(ev.target.value)}></textarea>
                    </div>
                    <button className={sendNotifButtonClasses} onClick={onSendNotificationClicked}>Send Push Notification to {tokens.length} Devices</button>
                </div> 
            </div>
        );

        return (
            <div style={{paddingTop: 10, height: '100%', overflowY: 'auto', paddingRight: 10, paddingBottom: 10}}>
                {searchbox}
                {errmsgEl}
                <h1 className="ui header">Devices that enabled push notifications for {caseNumber}</h1>
                <table className="ui very basic striped table">
                    <thead>
                        <tr><th>Device</th></tr>
                    </thead>
                    <tbody>
                        {tokenRows}
                    </tbody>
                </table>

                <div className="ui hidden divider"/>
                <h2 className="ui header">The following push notifications were sent for {caseNumber}</h2>
                <table className="ui very basic striped table">
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Recipient</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notificationRows}
                    </tbody>
                </table>

                <div className="ui hidden divider"/>
                <h2 className="ui header">History of when {caseNumber} was opened in the app</h2>
                <table className="ui very basic striped table">
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Device Type</th>
                            <th>IP Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {openRows}
                    </tbody>
                </table>

                {sendNotificationForm}
            </div>
        );
    }
}

export default DevicesPage;
