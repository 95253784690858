import React, { useState, useEffect, useMemo } from 'react';
import useConversation from '../../common/useConversation.js';
import useConversationMessages from '../../common/useConversationMessages.js';
import useMarkConversationMessageRead from '../../common/useMarkConversationMessageRead.js';
import ChatMessage from '../../common/chatmessage.js';
import usePubSub from '../../common/usePubSub.js';
import useUpdateTeamConversationAssignee from '../../common/useUpdateTeamConversationAssignee.js';
import { PUBSUB_TOPIC_CONVERSATIONS_MESSAGES_ADDED } from '../../common/webconstants.js';
import Spinner from '../../components/spinner.js';
import Center from '../../components/center.js';
import { decorateConversation, decorateMessage } from './decorators.js';
import ConversationTitle from './conversationtitle.js';
import ConversationAvatar from './conversationavatar.js';
import LoadMoreButton from './loadmorebutton.js';
import { FaChevronLeft } from 'react-icons/fa';
import useArchiveConversation from '../../common/useArchiveConversation.js';
import Select from 'react-select';
import SendMessageSection from './conversationpage/sendmessagesection.js';

const MessageStreamMessage = ({ message }) => {
    return (
        <div
            style={{
                fontStyle: 'italic',
                textAlign: 'center',
                fontSize: 13,
                color: '#757575',
            }}
        >
            {message}
        </div>
    );
};

const MessageStreamUnreadBar = () => {
    const color = 'rgb(128, 128, 128)';

    const stripe = (
        <div
            style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    borderBottom: '1px solid rgb(180, 180, 180)',
                    flex: 1,
                }}
            />
        </div>
    );

    return (
        <div style={{ display: 'flex', marginTop: 15, marginBottom: 15 }}>
            {stripe}
            <div style={{ marginLeft: 10, marginRight: 10, color, fontStyle: 'italic', fontSize: 13 }}>
                Unread messages
            </div>
            {stripe}
        </div>
    );
};

const Conversation = ({
    conversation,
    messages,
    onMessageSent,
    moreMessagesAvailable,
    loadMoreMessages,
    loadingMessages,
}) => {
    const messageStreamElements = useMemo(() => {
        const elements = [];

        const latestReadMessageCreatedAt = conversation.participantSelf.latestReadMessageCreatedAt;
        let unreadBarIncluded = false;

        for (let i = messages.length - 1; i >= 0; i--) {
            const message = messages[i];

            if (!unreadBarIncluded && (!latestReadMessageCreatedAt || message.created_at > latestReadMessageCreatedAt)) {
                elements.unshift(
                    <MessageStreamUnreadBar key="unread-bar" />
                );
                unreadBarIncluded = true;
            }

            elements.unshift(
                <ChatMessage
                    key={message.id}
                    message={message}
                    showSender={message.isOwnMessage && conversation.participantSelf.isTeam}
                    theme={message.isOwnMessage ? 'outwards' : 'inwards'}
                />
            );
        }

        return elements;
    }, [conversation.participantSelf, messages]);

    return (
        <>
            <div style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column-reverse', gap: 6, overflow: 'scroll' }}>
                {messageStreamElements.map((messageStreamElement) => messageStreamElement)}
                <div style={{ margin: 10 }}>
                    {(moreMessagesAvailable || loadingMessages) ? (
                        <LoadMoreButton
                            onClick={loadMoreMessages}
                            disabled={loadingMessages}
                        />
                    ) : (
                        <MessageStreamMessage message={messages.length === 0 ? 'No messages yet' : 'No older messages'} />
                    )}
                </div>
            </div>
            <div style={{ marginTop: 10 }}>
                <SendMessageSection conversation={conversation} onMessageSent={onMessageSent} />
            </div>
        </>
    );
};

const TeamConversationAssigneeSelect = ({ conversationId, allUsers, teamParticipant, onAssignmentUpdated }) => {
    const assigneeOptions = useMemo(() => {
        return allUsers
            .filter((user) => teamParticipant.participantId === 1 ? user.isCMAgent : true)
            .map((user) => ({ value: user.user_id, label: user.name }));
    }, [allUsers, teamParticipant]);
    const {
        updateTeamConversationAssignee,
        loading: updatingAssignee,
    } = useUpdateTeamConversationAssignee({
        onAssignmentUpdated,
    })
    const selectedAssignee = useMemo(() => {
        if (!teamParticipant.assignedUserId) return null;

        return {
            id: teamParticipant.assignedUserId,
            label: teamParticipant.assignedUserName,
        }
    }, [teamParticipant]);
    const updateAssignee = (userId) => {
        updateTeamConversationAssignee({
            teamId: teamParticipant.participantId,
            conversationId,
            userId,
        });
    };

    return (
        <Select
            options={assigneeOptions}
            styles={{
                container: styles => ({...styles, zIndex: 100, color: 'black', flexGrow: 1}),
                control: styles => ({ ...styles, borderWidth: 0, ':hover': {cursor: 'pointer'} }),
                singleValue: styles => ({ ...styles, ':hover': {cursor: 'pointer', color: '#1e70bf'} }),
            }}
            value={selectedAssignee}
            onChange={(assigneeOption) => updateAssignee(assigneeOption === null ? null : parseInt(assigneeOption.value))}
            menuPlacement="bottom"
            isDisabled={updatingAssignee}
            isClearable
        />
    );
};

const ConversationPage = ({ conversationId, redirectToConversations, currentUser, allUsers }) => {
    const messagesBatchSize = 10;
    const {
        conversation,
        initialized: conversationInitialized,
        updateLastReadMessageCreatedAt,
        updateArchived,
        reload: reloadConversation,
    } = useConversation({ conversationId });
    const [numberOfMessages, setNumberOfMessages] = useState(messagesBatchSize);
    const decoratedConversation = useMemo(() => {
        if (!conversation) return null;

        return decorateConversation(conversation, { currentUser });
    }, [conversation, currentUser]);
    const { archiveConversation } = useArchiveConversation({
        onConversationArchived: () => updateArchived(true),
    });
    const {
        messages,
        initialized: messagesInitialized,
        loading: loadingMessages,
        addToCachedMessages,
        reload: reloadMessages,
    } = useConversationMessages({
        conversationId: conversationId,
        page: 1,
        pageSize: numberOfMessages,
    });
    const { markConversationMessageRead } = useMarkConversationMessageRead({ conversationId })
    const decoratedMessages = useMemo(() => {
        return messages
            .map((message) => decorateMessage(message, { currentUser }));
    }, [messages, currentUser]);
    const moreMessagesAvailable = messages.length === numberOfMessages;
    const loadMoreMessages = () => setNumberOfMessages(numberOfMessages + messagesBatchSize);

    useEffect(() => {
        if (!decoratedConversation) return;

        const latestMessage = messages[0];

        if (!latestMessage) return;

        if (!decoratedConversation.participantSelf.latestReadMessageCreatedAt || decoratedConversation.participantSelf.latestReadMessageCreatedAt < latestMessage.created_at) {
            markConversationMessageRead({ messageId: latestMessage.id });
        }
    }, [messages, markConversationMessageRead, decoratedConversation]);

    usePubSub({
        topic: PUBSUB_TOPIC_CONVERSATIONS_MESSAGES_ADDED,
        messageHandler: (_topic, data) => {
            const wasAddedToTheCurrentConversation = data.conversationId === conversationId;

            if (wasAddedToTheCurrentConversation) return reloadMessages();
        },
    });

    const processArchiveConversation = () => {
        const confirmed = window.confirm('Are you sure you want to archive this conversation?');

        if (!confirmed) return;

        archiveConversation(conversation.id);
    };

    return (
        <div
            style={{
                backgroundColor: '#fbfbfb',
                borderRadius: 3,
                padding: 7,
                marginTop: 20,
                height: '100%',
            }}
        >

            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <button
                        onClick={redirectToConversations}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: 20,
                            color: 'rgb(87, 87, 87)',
                        }}
                    >
                        <FaChevronLeft />
                    </button>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap', flex: 1 }}>
                        {decoratedConversation && (
                            <>
                                <div style={{ display: 'flex', gap: 10, flex: 1, minWidth: 200, flexWrap: 'wrap' }}>
                                    <ConversationAvatar conversation={decoratedConversation} />
                                    <ConversationTitle conversation={decoratedConversation} />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 5,
                                    }}
                                >
                                    {decoratedConversation.participantSelf.participantType === 'Team' && (
                                        <div
                                            style={{
                                                width: 200,
                                                zIndex: 1,
                                            }}
                                        >
                                            <TeamConversationAssigneeSelect
                                                conversationId={conversationId}
                                                allUsers={allUsers}
                                                onAssignmentUpdated={reloadConversation}
                                                teamParticipant={decoratedConversation.participantSelf}
                                            />
                                        </div>
                                    )}
                                    <button
                                        className="ui basic icon button"
                                        onClick={processArchiveConversation}
                                        disabled={decoratedConversation.archived}
                                    >
                                        <i className="archive icon"/>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {(conversationInitialized && messagesInitialized) ? (
                    <>
                        {decoratedConversation ? (
                            <Conversation
                                conversation={decoratedConversation}
                                messages={decoratedMessages}
                                onMessageSent={(newMessage) => {
                                    updateLastReadMessageCreatedAt({
                                        participantType: decoratedConversation.participantSelf.participant_type,
                                        participantId: decoratedConversation.participantSelf.participant_id,
                                        createdAt: newMessage.created_at,
                                    })
                                    addToCachedMessages(newMessage);

                                    if (decoratedConversation.participantSelf.isTeam && !decoratedConversation.participantSelf.assignedUserId) {
                                        reloadConversation();
                                    }
                                }}
                                moreMessagesAvailable={moreMessagesAvailable}
                                loadMoreMessages={loadMoreMessages}
                                loadingMessages={loadingMessages}
                            />
                        ) : (
                            <div>
                                Failed to load conversation
                            </div>
                        )}
                    </>
                ) : (
                    <Center>
                        <Spinner />
                    </Center>
                )}
            </div>
        </div>
    );
};

export default ConversationPage;
