import React, { useContext } from 'react';
import { CurrentUserContext } from '../common/currentUserContext.js';
import AdminDashboard from '../components/dashboardpage/admindashboard.js';
import AttorneyDashboard from '../components/dashboardpage/attorneydashboard';

const DashboardPage = ({ allUsers }) => {
    const currentUser = useContext(CurrentUserContext);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
            <div style={{ padding: 20, width: 1140, overflowY: 'scroll' }}>
                <h1>Dashboard</h1>
                <div style={{ marginTop: 40 }}>
                    {currentUser.isAdmin ? (
                        <AdminDashboard allUsers={allUsers} />
                    ) : (
                        <AttorneyDashboard />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
