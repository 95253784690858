import React, { useState, useEffect, useCallback } from 'react';
import SearchBox from '../components/searchbox.js';
import API from '../common/api.js';

function PushNotificationsPage({allCaseNumbers}) {
    const [loading, setLoading] = useState(true);
    const [notifs, setNotifs] = useState([]);
    const [oldest, setOldest] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [errMsg, setErrMsg] = useState(null);
    const [initialSearchValue, setInitialSearchValue] = useState("");

    const loadInitialData = useCallback(async () => {
        const data = await API.getPushNotifications();
        if (data) {
            setNotifs(data.notifs);
            setOldest(data.oldest);
            setHasMore(true);
            setLoading(false);
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadInitialData();
    }, [loadInitialData]);

    async function onLoadMoreClicked() {
        setLoading(true);
        const data = await API.getPushNotificationsOlderThan(oldest);
        if (data) {
            if (data.notifs.length === 0) {
                setLoading(false);
                setHasMore(false);
            } else {
                const newNotifs = notifs.concat(data.notifs);
                setNotifs(newNotifs);
                setOldest(data.oldest);
                setLoading(false);
            }
        } else {
            setErrMsg("Failed to load more messages from server");
            setLoading(false);
        }
    }

    async function onSearchBoxCommit(caseNumber) {
        if (caseNumber.length === 0) {
            setInitialSearchValue("");
            await loadInitialData();
        } else {
            setLoading(true);
            setHasMore(false);
            setNotifs([]);
            setInitialSearchValue(caseNumber);
            const data = await API.getPushNotificationsForCase(caseNumber);
            if (data) {
                setNotifs(data.notifs);
                setOldest(data.oldest);
                setLoading(false);
                setHasMore(false);
            } else {
                setErrMsg("Unable to load data from server");
                setLoading(false);
            }
        }
    }

    const rows = notifs.map((notif, inx) => 
        <tr key={inx}>
            <td>{notif.timestamp}</td>
            <td className="hoverparent">{notif.case_number} <button className="ui basic mini icon button showwhenparenthovered" onClick={() => onSearchBoxCommit(notif.case_number)}><i className="search icon"></i></button></td>
            <td>{notif.title}</td>
            <td><div className={`ui ${notif.statuscolor} horizontal label`}>{notif.status}</div></td>
            <td>{notif.recipient}</td>
        </tr>
    );

    let btnclass = "ui black bottom attached button";
    if (loading) {
        btnclass = `${btnclass} loading disabled`;
    }
    if (!hasMore) {
        btnclass = `${btnclass} disabled`;
    }
    const loadMoreButton = <div className={btnclass} tabIndex="0" onClick={onLoadMoreClicked}>{hasMore ? "Load more" : "That's all folks!"}</div>;

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    return (
        <div style={{paddingTop: 10, height: '100%', overflowY: 'auto', paddingRight: 10, paddingBottom: 10}}>
            <SearchBox allPossibleValues={allCaseNumbers} onCommit={onSearchBoxCommit} initialValue={initialSearchValue}/>
            <table className="ui very basic striped table">
                <thead>
                    <tr>
                        <th>Timestamp</th>
                        <th>Matter</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Recipient</th>
                    </tr>
                </thead>
                {errmsgEl}
                <tbody>
                    {rows}
                </tbody>
            </table>
            {loadMoreButton}
        </div>
    );
}

export default PushNotificationsPage;
