/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useRef } from 'react';
import useInterval from '../common/useInterval.js';
import { durationAsMinutesString } from '../common/formatting.js';
var moment = require('moment');

const LiveDuration = ({ startedAt }) => {
    const containerRef = useRef(null);

    useInterval(() => {
        if (!containerRef.current || !startedAt) return;

        containerRef.current.innerHTML = durationAsMinutesString(startedAt, moment());
    }, 100);

    if (!startedAt) return null;

    return (
        <span ref={containerRef} />
    );
};

export default LiveDuration;
