import React, { useRef, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';

const FilterBar = ({ onHeightChange, children, visible }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && visible) {
            onHeightChange(ref.current.offsetHeight);
        } else {
            onHeightChange(0);
        }
    }, [visible, onHeightChange]);

    return (
        <AnimateHeight
            duration={250}
            height={visible ? "auto" : 0}
            onAnimationEnd={({ newHeight }) => () => onHeightChange(newHeight)}
        >
            <div className="filter-bar" ref={ref}>
                {children}
            </div>
        </AnimateHeight>
    );
};

export default FilterBar;
