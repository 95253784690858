import { useState, useCallback, useEffect } from 'react';
import API from './api.js';

function useMatterInfo(caseNumber, options) {
    const [matterInfo, setMatterInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState(null);

    const {includingClientData} = options || {};

    const loadCase = useCallback(async () => {
        setLoading(true);

        const data = await API.getCaseStatus(caseNumber);
        if (data) {
            setMatterInfo(data.info);
            if (includingClientData) {
                const clientData = await API.getContactInfo(data.info.client_id);
                setMatterInfo({...data.info, client: clientData});
            }
            setLoading(false);
            
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, [caseNumber, includingClientData]);

    useEffect(() => {
        setMatterInfo(null);

        loadCase();
    }, [caseNumber, loadCase]);

    return [matterInfo, loading, errMsg];
}

export default useMatterInfo;
