import React, { useState, useEffect, useCallback } from 'react';
import API from '../../common/api.js';
import { useDynamicYesNoModal } from '../../common/useModal.js';
import useDefaultTextMessageTemplates from '../../common/useDefaultTextMessageTemplates.js';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { formatPhoneNumberFromString } from '../../common/formatting.js';
import Dialog from '../../common/dialog.js';
import UserPicture from '../settingspage/userpicture.js';
import UserPictureForm from '../settingspage/userpictureform.js';

function General() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [faxNumbers, setFaxNumbers] = useState([]);
    const [voicemailExtensions, setVoicemailExtensions] = useState([]);
    const [allowSendingEmails, setAllowSendingEmails] = useState(false);
    const [allowSendingPushNotifications, setAllowSendingPushNotifications] = useState(false);
    const [allowSendingSMS, setAllowSendingSMS] = useState(false);
    const [allowSendingScheduleCallRequestSMS, setAllowSendingScheduleCallRequestSMS] = useState(false);
    const [sendSlackMessageForEachNotification, setSendSlackMessageForEachNotification] = useState(false);
    const [successMsg, setSuccessMsg] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [deferLeadAfterFailedCallsNumberOfCalls, setDeferLeadAfterFailedCallsNumberOfCalls] = useState('');
    const [deferLeadAfterFailedCallsDeferForDays, setDeferLeadAfterFailedCallsDeferForDays] = useState('');
    const [deferLeadAfterFailedCallsActive, setDeferLeadAfterFailedCallsActive] = useState(false);
    const [enquireCallToVoicemailActive, setEnquireCallToVoicemailActive] = useState(false);
    const [enquireVMForCallsBelowSeconds, setEnquireVMForCallsBelowSeconds] = useState('');
    const [openConfirmDeleteUserModal, confirmDeleteUserModal] = useDynamicYesNoModal();
    const [openConfirmDeleteTeamModal, confirmDeleteTeamModal] = useDynamicYesNoModal();
    const [openConfirmDeleteFaxNumberModal, confirmDeleteFaxNumberModal] = useDynamicYesNoModal();
    const [openConfirmDeleteVoicemailExtensionModal, confirmDeleteVoicemailExtensionModal] = useDynamicYesNoModal();
    const { textMessageTemplates, reload: reloadTextMessageTemplates } = useDefaultTextMessageTemplates();
    const [editUserPictureDialogOpenFor, setEditUserPictureDialogOpenFor] = useState(null);
    let history = useHistory();

    const loadData = useCallback(async () => {
        const [usersData, settingsData, teamsData, faxNumbersData, voicemailExtensionsData] = await Promise.all([API.getUsers(), API.getSettings(), API.getTeams(), API.getFaxNumbers(), API.getVoicemailExtensions()]);
        if (usersData) {
            setUsers(usersData);
        } else {
            setErrMsg("Unable to load user data from server");
        }
        if (settingsData) {
            setAllowSendingEmails(settingsData.allow_sending_emails);
            setAllowSendingPushNotifications(settingsData.allow_push_notifications);
            setAllowSendingSMS(settingsData.allow_sending_sms);
            setAllowSendingScheduleCallRequestSMS(settingsData.allow_lead_schedule_call_requests);
            setSendSlackMessageForEachNotification(settingsData.send_slack_message_for_each_notification);

            if (Number.isInteger(settingsData.defer_lead_after_failed_calls_number_of_calls)) {
                setDeferLeadAfterFailedCallsNumberOfCalls(settingsData.defer_lead_after_failed_calls_number_of_calls.toString());
            } else {
                setDeferLeadAfterFailedCallsNumberOfCalls('');
            }

            if (Number.isInteger(settingsData.defer_lead_after_failed_calls_defer_for_days)) {
                setDeferLeadAfterFailedCallsDeferForDays(settingsData.defer_lead_after_failed_calls_defer_for_days.toString());
            } else {
                setDeferLeadAfterFailedCallsDeferForDays('');
            }

            if (Number.isInteger(settingsData.enquire_vm_for_calls_below_seconds)) {
                setEnquireVMForCallsBelowSeconds(settingsData.enquire_vm_for_calls_below_seconds.toString());
            } else {
                setEnquireVMForCallsBelowSeconds('');
            }

            setDeferLeadAfterFailedCallsActive(
                Number.isInteger(settingsData.defer_lead_after_failed_calls_number_of_calls) && Number.isInteger(settingsData.defer_lead_after_failed_calls_defer_for_days)
            );
            setEnquireCallToVoicemailActive(
                Number.isInteger(settingsData.enquire_vm_for_calls_below_seconds)
            );
        } else {
            setErrMsg("Unable to load settings data from server");
        }
        if (teamsData) {
            setTeams(teamsData);
        } else {
            setErrMsg("Unable to load team data from server");
        }
        if (faxNumbersData) {
            setFaxNumbers(faxNumbersData);
        } else {
            setErrMsg("Unable to load fax number data from server");
        }
        if (voicemailExtensionsData) {
            setVoicemailExtensions(voicemailExtensionsData);
        } else {
            setErrMsg("Unable to load voicemail extensions data from server");
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    async function saveSettings() {
        setSaving(true);

        const params = {
            allowSendingEmails,
            allowSendingPushNotifications,
            allowSendingSMS,
            allowLeadScheduleCallRequests: allowSendingScheduleCallRequestSMS,
            sendSlackMessageForEachNotification,
            deferLeadAfterFailedCallsNumberOfCalls: '',
            deferLeadAfterFailedCallsDeferForDays: '',
            enquireVMForCallsBelowSeconds: '',
        };

        if (deferLeadAfterFailedCallsActive && parseInt(deferLeadAfterFailedCallsNumberOfCalls) && parseInt(deferLeadAfterFailedCallsDeferForDays)) {
            params.deferLeadAfterFailedCallsNumberOfCalls = deferLeadAfterFailedCallsNumberOfCalls;
            params.deferLeadAfterFailedCallsDeferForDays = deferLeadAfterFailedCallsDeferForDays;
        }

        if (enquireCallToVoicemailActive && parseInt(enquireVMForCallsBelowSeconds)) {
            params.enquireVMForCallsBelowSeconds = enquireVMForCallsBelowSeconds;
        }

        const success = await API.saveSettings(params);
        if (success) {
            setSuccessMsg("Saved!");
            setTimeout(() => {setSuccessMsg(null)}, 2500);
            setDeferLeadAfterFailedCallsActive(
                params.deferLeadAfterFailedCallsNumberOfCalls !== '' && params.deferLeadAfterFailedCallsDeferForDays !== ''
            );
            setEnquireCallToVoicemailActive(params.enquireVMForCallsBelowSeconds !== '')
        } else {
            setErrMsg("ERROR: Saving settings failed! Please retry.");
            setTimeout(() => {setErrMsg(null)}, 2500);
        }
        setSaving(false);
    }

    function deleteUser(user) {
        openConfirmDeleteUserModal({
            title: "Delete User?",
            prompt: `Are you sure you want to delete user ${user.name} (${user.email})?`,
            yesAction: async () => {
                const success = await API.deleteUser(user.user_id);
                if (success) {
                    setSuccessMsg("User deleted!");
                    loadData();
                } else {
                    setErrMsg("ERROR: failed to delete user. Please retry.");
                }
            },
            noAction: () => {}
        });
    }

    function addUser() {
        history.push("/add_user");
    }

    function editUser(user) {
        history.push(`/edit_user/${user.user_id}`);
    }

    function deleteTeam(team) {
        openConfirmDeleteTeamModal({
            title: "Delete Team?",
            prompt: `Are you sure you want to delete team ${team.name}?`,
            yesAction: async () => {
                const success = await API.deleteTeam(team.team_id);
                if (success) {
                    setSuccessMsg("Team deleted!");
                    loadData();
                } else {
                    setErrMsg("ERROR: failed to delete team. Please retry.");
                }
            },
            noAction: () => {}
        });
    }

    function addTeam() {
        history.push("/add_team");
    }

    function editTeam(team) {
        history.push(`/edit_team/${team.team_id}`);
    }

    function deleteFaxNumber(fn) {
        openConfirmDeleteFaxNumberModal({
            title: "Delete Fax Number?",
            prompt: `Are you sure you want to delete fax number ${formatPhoneNumberFromString(fn.phonenumber)}?`,
            yesAction: async () => {
                const success = await API.deleteFaxNumber(fn.fax_number_id);
                if (success) {
                    setSuccessMsg("Fax number deleted!");
                    loadData();
                } else {
                    setErrMsg("ERROR: failed to delete fax number. Please retry.");
                }
            },
            noAction: () => {}
        });
    }

    function addFaxNumber() {
        history.push("/add_fax");
    }

    function editFaxNumber(fn) {
        history.push(`/edit_fax/${fn.fax_number_id}`);
    }

    function deleteVoicemailExtension(fn) {
        console.log(fn)
        openConfirmDeleteVoicemailExtensionModal({
            title: "Delete Voicemail Extension?",
            prompt: `Are you sure you want to delete voicemail extension ${fn.extension}?`,
            yesAction: async () => {
                const success = await API.deleteVoicemailExtension(fn.extension_id);
                if (success) {
                    setSuccessMsg("Voicemail extension deleted!");
                    loadData();
                } else {
                    setErrMsg("ERROR: failed to delete voicemail extension. Please retry.");
                }
            },
            noAction: () => {}
        });
    }

    function addVoicemailExtension() {
        history.push("/add_voicemail_extension");
    }

    function addTextMessageTemplate() {
        history.push("/add_text_message_template");
    }

    function editVoicemailExtension(fn) {
        history.push(`/edit_voicemail_extension/${fn.extension_id}`);
    }

    const reloadUsers = async () => {
        const usersData = await API.getUsers();

        setUsers(usersData);
    };

    const userRows = users && users.map((user, inx) =>
        <tr key={inx}>
            <td>
                <button
                    style={{
                        margin: 0,
                        padding: 0,
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => setEditUserPictureDialogOpenFor(user)}
                >
                    <UserPicture pictureSrc={user.picture_url} name={user.name} size={50} />
                </button>
            </td>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>
                { user.teams.map((team, teamInx) => <div key={teamInx} className="ui horizontal label"><i className="users icon"/> {team}</div>) }
            </td>
            <td>
                { user.rights.map((right, rightInx) => <div key={rightInx} className="ui orange horizontal label">{right}</div>) }
            </td>
            <td>
                { (user.user_id > 1) && (
                    <div className="ui right floated icon buttons">
                        <button className="ui basic button" onClick={() => editUser(user)}>
                            <i className="edit icon"/>
                        </button>
                        <button className="ui basic button" onClick={() => deleteUser(user)}>
                            <i className="trash icon"/>
                        </button>
                    </div>
                ) }
            </td>
        </tr>
    );

    const teamRows = teams && teams.map((team, inx) =>
        <tr key={inx}>
            <td>{team.name}</td>
            <td>
                {
                    <div className="ui right floated icon buttons">
                        <button className="ui basic button" onClick={() => editTeam(team)}>
                            <i className="edit icon"/>
                        </button>
                        <button className="ui basic button" onClick={() => deleteTeam(team)}>
                            <i className="trash icon"/>
                        </button>
                    </div>
                }
            </td>
        </tr>
    );

    const faxNumberRows =
        faxNumbers &&
        faxNumbers.map((fn, inx) => {
            const user = users?.find((u) => u.user_id === fn.user_id);
            const team = teams?.find((t) => t.team_id === fn.team_id);

            return (
                <tr key={fn.phonenumber}>
                    <td>{formatPhoneNumberFromString(fn.phonenumber)}</td>
                    <td>
                        {user && (
                            <div className="ui horizontal label">
                                <i className="user icon" /> {user.name}
                            </div>
                        )}
                    </td>
                    <td>
                        {team && (
                            <div className="ui horizontal label">
                                <i className="users icon" /> {team.name}
                            </div>
                        )}
                    </td>
                    <td>
                        <div className="ui right floated icon buttons">
                            <button className="ui basic button" onClick={() => editFaxNumber(fn)}>
                                <i className="edit icon" />
                            </button>
                            <button className="ui basic button" onClick={() => deleteFaxNumber(fn)}>
                                <i className="trash icon" />
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });

    const voicemailExtensionRows =
        voicemailExtensions &&
        voicemailExtensions.map((fn, inx) => {
            const user = users?.find((u) => u.user_id === fn.user_id);
            const team = teams?.find((t) => t.team_id === fn.team_id);

            return (
                <tr key={fn.extension}>
                    <td>{fn.extension}</td>
                    <td>
                        {user && (
                            <div className="ui horizontal label">
                                <i className="user icon" /> {user.name}
                            </div>
                        )}
                    </td>
                    <td>
                        {team && (
                            <div className="ui horizontal label">
                                <i className="users icon" /> {team.name}
                            </div>
                        )}
                    </td>
                    <td>
                        <div className="ui right floated icon buttons">
                            <button className="ui basic button" onClick={() => editVoicemailExtension(fn)}>
                                <i className="edit icon" />
                            </button>
                            <button className="ui basic button" onClick={() => deleteVoicemailExtension(fn)}>
                                <i className="trash icon" />
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });

    const textMessageTemplatesRows = textMessageTemplates.map((textMessageTemplate) => {
        return (
            <tr key={textMessageTemplate.id}>
                <td>
                    {textMessageTemplate.message}
                </td>
                <td>
                    <div className="ui right floated icon buttons">
                        <button
                            className="ui basic button"
                            onClick={() => API.deleteDefaultTextMessageTemplate(textMessageTemplate.id).then(reloadTextMessageTemplates)}
                        >
                            <i className="trash icon"/>
                        </button>
                    </div>
                </td>
            </tr>
        );
    });

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;
    const successMsgEl = successMsg ? <div className="ui success message">{successMsg}</div> : null;

    if (loading) {
        return (
            <div style={{paddingTop: 10}}>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else {
        return (
            <div style={{paddingTop: 10, paddingBottom: 70, paddingRight: 10, height: '100%', overflowY: 'auto'}}>
                {errmsgEl}
                {successMsgEl}

                <h1 className="ui header">Teams</h1>
                <table className="ui striped table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>
                                <button className="ui right floated small primary basic button" onClick={addTeam}>
                                    <i className="users icon"></i> Add Team
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {teamRows}
                    </tbody>
                </table>

                <h1 className="ui header" style={{marginTop: 50}}>Users</h1>
                {editUserPictureDialogOpenFor && (
                    <Dialog
                        title="Edit user picture"
                        onClose={() => setEditUserPictureDialogOpenFor(null)}
                    >
                        <UserPictureForm
                            user={editUserPictureDialogOpenFor}
                            onPictureUpdated={() => {
                                setEditUserPictureDialogOpenFor(null);
                                reloadUsers();
                            }}
                        />
                    </Dialog>
                )}
                <table className="ui striped table">
                    <thead>
                        <tr>
                            <th>Picture</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Teams</th>
                            <th>Rights</th>
                            <th>
                                <button className="ui right floated small primary basic button" onClick={addUser}>
                                    <i className="user icon"></i> Add User
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {userRows}
                    </tbody>
                </table>

                <h1 className="ui header" style={{marginTop: 50}}>Fax Numbers</h1>
                <table className="ui striped table">
                    <thead>
                        <tr>
                            <th>Phone Number</th>
                            <th>Assign to user</th>
                            <th>Assign to team</th>
                            <th>
                                <button className="ui right floated small primary basic button" onClick={addFaxNumber}>
                                    <i className="user icon"></i> Add Fax Number
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {faxNumberRows}
                    </tbody>
                </table>

                <h1 className="ui header" style={{marginTop: 50}}>Voicemail Extensions</h1>
                <table className="ui striped table">
                    <thead>
                        <tr>
                            <th>Extension</th>
                            <th>Assign to user</th>
                            <th>Assign to team</th>
                            <th>
                                <button className="ui right floated small primary basic button" onClick={addVoicemailExtension}>
                                    <i className="user icon"></i> Add Voicemail Extension
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {voicemailExtensionRows}
                    </tbody>
                </table>

                <h1 className="ui header" style={{marginTop: 50}}>Text Message Templates</h1>
                <table className="ui striped table">
                    <thead>
                        <tr>
                            <th>Message</th>
                            <th>
                                <button className="ui right floated small primary basic button" onClick={addTextMessageTemplate}>
                                    <i className="plus icon"></i> Add Template
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {textMessageTemplatesRows}
                    </tbody>
                </table>

                <h1 className="ui header" style={{marginTop: 50}}>Server Settings</h1>
                <div className="ui form segment">
                    <div className="ui yellow message">
                        <div className="ui grid">
                            <div className="one wide column">
                                <i className="icon huge exclamation triangle"></i>
                            </div>
                            <div className="fifteen wide column" style={{paddingLeft: 30}}>
                                Caution: when you disable sending emails, push notifications or SMS messages, the server will immediately stop sending these out. They <i>will</i> still be queued however, and when you enable the setting again, the server will send out all queued mails, sms messages and notifications. <b>This is probably NOT what you want.</b> Please have a developer take a look at the queue, and clear out the unwanted entries before enabling the setting again.
                            </div>
                        </div>
                    </div>

                    <div className="inline field">
                        <div className={`ui toggle ${allowSendingEmails ? "checked" : ""} checkbox`} onClick={() => setAllowSendingEmails(!allowSendingEmails)}>
                            <input type="checkbox" tabIndex="0" className="hidden" {...{checked: allowSendingEmails}} onChange={() => {}}/>
                            <label>Allow server to send emails</label>
                        </div>
                    </div>
                    <div className="inline field">
                        <div className={`ui toggle ${allowSendingPushNotifications ? "checked" : ""} checkbox`} onClick={() => setAllowSendingPushNotifications(!allowSendingPushNotifications)}>
                            <input type="checkbox" tabIndex="0" className="hidden" {...{checked: allowSendingPushNotifications}} onChange={() => {}}/>
                            <label>Allow server to send push notifications</label>
                        </div>
                    </div>
                    <div className="inline field">
                        <div className={`ui toggle ${allowSendingSMS ? "checked" : ""} checkbox`} onClick={() => setAllowSendingSMS(!allowSendingSMS)}>
                            <input type="checkbox" tabIndex="0" className="hidden" {...{checked: allowSendingSMS}} onChange={() => {}}/>
                            <label>Allow server to send SMS messages</label>
                        </div>
                    </div>
                    <div className="inline field">
                        <div className={`ui toggle ${allowSendingScheduleCallRequestSMS ? "checked" : ""} checkbox`} onClick={() => setAllowSendingScheduleCallRequestSMS(!allowSendingScheduleCallRequestSMS)}>
                            <input type="checkbox" tabIndex="0" className="hidden" {...{checked: allowSendingScheduleCallRequestSMS}} onChange={() => {}}/>
                            <label>Allow Attorneys to send SMS requesting Leads to schedule a call</label>
                        </div>
                    </div>
                    <div className="inline field">
                        <div className={`ui toggle ${sendSlackMessageForEachNotification ? "checked" : ""} checkbox`} onClick={() => setSendSlackMessageForEachNotification(!sendSlackMessageForEachNotification)}>
                            <input type="checkbox" tabIndex="0" className="hidden" {...{checked: sendSlackMessageForEachNotification}} onChange={() => {}}/>
                            <label>Send Slack message for each Case Access notification</label>
                        </div>
                    </div>
                    <div className="inline field">
                        <div
                            className={`ui toggle ${deferLeadAfterFailedCallsActive ? "checked" : ""} checkbox`}
                            style={{ margin: 0 }}
                            onClick={() => setDeferLeadAfterFailedCallsActive(!deferLeadAfterFailedCallsActive)}
                        >
                            <input type="checkbox" tabIndex="0" className="hidden" {...{checked: deferLeadAfterFailedCallsActive}} onChange={() => {}}/>
                            <label />
                        </div>
                        Defer Lead for
                        <input
                            type="number"
                            min="1"
                            step="1"
                            required
                            name="deferLeadAfterFailedCallsDeferForDays"
                            disabled={!deferLeadAfterFailedCallsActive}
                            value={deferLeadAfterFailedCallsDeferForDays}
                            onChange={(event) => setDeferLeadAfterFailedCallsDeferForDays(event.target.value)}
                            style={{ width: 75, margin: 0, marginLeft: 7, marginRight: 7 }}
                        />
                        day(s) after
                        <input
                            type="number"
                            min="1"
                            step="1"
                            required
                            name="deferLeadAfterFailedCallsNumberOfCalls"
                            disabled={!deferLeadAfterFailedCallsActive}
                            value={deferLeadAfterFailedCallsNumberOfCalls}
                            onChange={(event) => setDeferLeadAfterFailedCallsNumberOfCalls(event.target.value)}
                            style={{ width: 75, margin: 0, marginLeft: 7, marginRight: 7 }}
                        />
                        calls in a row went unanswered within one day.
                    </div>
                    <div className="inline field">
                        <div
                            className={`ui toggle ${enquireCallToVoicemailActive ? "checked" : ""} checkbox`}
                            style={{ margin: 0 }}
                            onClick={() => setEnquireCallToVoicemailActive(!enquireCallToVoicemailActive)}
                        >
                            <input type="checkbox" tabIndex="0" className="hidden" {...{checked: enquireCallToVoicemailActive}} onChange={() => {}}/>
                            <label />
                        </div>
                        Enquire whether call went to voicemail if call was shorter than
                        <input
                            type="number"
                            min="1"
                            step="1"
                            required
                            name="enquireVMForCallsBelowSeconds"
                            disabled={!enquireCallToVoicemailActive}
                            value={enquireVMForCallsBelowSeconds}
                            onChange={(event) => setEnquireVMForCallsBelowSeconds(event.target.value)}
                            style={{ width: 75, margin: 0, marginLeft: 7, marginRight: 7 }}
                        />
                        second(s)
                    </div>

                    <button className={classNames("ui primary button", {loading: saving})} onClick={saveSettings}>Save Settings</button>
                </div>

                {confirmDeleteUserModal}
                {confirmDeleteTeamModal}
                {confirmDeleteFaxNumberModal}
                {confirmDeleteVoicemailExtensionModal}
            </div>
        );
    }
}

export default General;
