import React, { useMemo, useRef, useState } from 'react';
import useDynamicContentTopics from '../../common/useDynamicContentTopics.js';
import API from '../../common/api.js';
import Dialog from '../../common/dialog.js';
import Spinner from '../spinner.js';
import Center from '../center.js';
import useLocalStorage from '../../common/useLocalStorage.js';
import useDynamicContentEditor from './dynamiccontent/useDynamicContentEditor.js';
import { DYNAMIC_CONTENT_BLOCK_TYPES } from '../../common/webconstants.js';
import { FaPlus, FaTrash, FaPenSquare } from 'react-icons/fa';

const DynamicContentBlockFormHeadline = ({ children }) => {
    return (
        <h3>{children}</h3>
    );
};

const DynamicContentBlockContainer = ({ children, onClickRemove, onClickEdit }) => {
    return (
        <div
            style={{
                backgroundColor: 'rgb(224, 255, 251)',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 5,
                overflow: 'hidden',
            }}
        >
            <div style={{ flex: 1 }}>
                {children}
            </div>
            {onClickEdit && (
                <div>
                    <button
                        onClick={onClickEdit}
                        style={{
                            height: '100%',
                            cursor: 'pointer',
                            padding: 15,
                            backgroundColor: 'rgb(180, 241, 255)',
                            border: 'none',
                        }}
                    >
                        <FaPenSquare />
                    </button>
                </div>
            )}
            <div>
                <button
                    onClick={onClickRemove}
                    style={{
                        height: '100%',
                        cursor: 'pointer',
                        padding: 15,
                        backgroundColor: 'rgb(186, 255, 246)',
                        border: 'none',
                    }}
                >
                    <FaTrash />
                </button>
            </div>
        </div>
    );
};

const PdfDynamicContentBlock = ({ dynamicContentBlock, onClickRemove }) => {
    const pdfDynamicContentBlock = dynamicContentBlock.pdfDynamicContentBlock;

    return (
        <DynamicContentBlockContainer onClickRemove={onClickRemove}>
            <div
                style={{ padding: 10 }}
            >
                <a href={pdfDynamicContentBlock.url} target="_blank" rel="noopener noreferrer">
                    {pdfDynamicContentBlock.fileName}
                </a>
            </div>
        </DynamicContentBlockContainer>
    );
};

const VideoDynamicContentBlock = ({ dynamicContentBlock, onClickRemove }) => {
    const videoDynamicContentBlock = dynamicContentBlock.videoDynamicContentBlock;

    return (
        <DynamicContentBlockContainer onClickRemove={onClickRemove}>
            <div>
                <video width="100%" controls>
                    <source
                        src={videoDynamicContentBlock.url}
                        type="video/mp4"
                    />
                    Your browser does not support HTML5 video.
                </video>
            </div>
            <div
                style={{
                    fontSize: 12,
                    color: 'rgb(19, 19, 19)',
                    padding: 5,
                    paddingTop: 0,
                }}
            >
                {videoDynamicContentBlock.fileName}
            </div>
        </DynamicContentBlockContainer>
    );
};

const EditTextDynamicContentBlockForm = ({ dynamicContentBlock, updateDynamicContentBlock, onBlockUpdated, dynamicContentBlocksEditorLoading }) => {
    const textDynamicContentBlock = dynamicContentBlock.textDynamicContentBlock;
    const [text, setText] = useState(textDynamicContentBlock.text);

    const onClickSubmit = async () => {
        const trimmedText = text.trim();

        if (trimmedText === '') return;

        if (await updateDynamicContentBlock(dynamicContentBlock.id, { text: trimmedText })) {
            onBlockUpdated();
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 13,
            }}
        >
            <div>
                <DynamicContentBlockFormHeadline>
                    Edit Text
                </DynamicContentBlockFormHeadline>
            </div>
            <div>
                <textarea
                    value={text}
                    onChange={(event) => setText(event.currentTarget.value)}
                    style={{ width: '100%' }}
                />
            </div>
            <div>
                <button
                    onClick={onClickSubmit}
                    disabled={text.trim() === '' || dynamicContentBlocksEditorLoading}
                    className="ui green button"
                >
                    Update Text
                </button>
            </div>
        </div>
    );
};

const TextDynamicContentBlock = ({ dynamicContentBlock, onClickRemove, updateDynamicContentBlock, dynamicContentBlocksEditorLoading }) => {
    const [showEditForm, setShowEditForm] = useState(false);
    const textDynamicContentBlock = dynamicContentBlock.textDynamicContentBlock;

    return (
        <>
            {showEditForm && (
                <Dialog
                    onClose={() => setShowEditForm(false)}
                    title="Edit Text"
                >
                    <EditTextDynamicContentBlockForm
                        dynamicContentBlock={dynamicContentBlock}
                        updateDynamicContentBlock={updateDynamicContentBlock}
                        onBlockUpdated={() => setShowEditForm(false)}
                        dynamicContentBlocksEditorLoading={dynamicContentBlocksEditorLoading}
                    />
                </Dialog>
            )}
            <DynamicContentBlockContainer
                onClickRemove={onClickRemove}
                onClickEdit={() => setShowEditForm(true)}
            >
                <div
                    style={{ padding: 10, fontStyle: 'italic', color: '#242424' }}
                >
                    {textDynamicContentBlock.text}
                </div>
            </DynamicContentBlockContainer>
        </>
    );
};

const DynamicContentBlock = ({ dynamicContentBlock, onClickRemove, updateDynamicContentBlock, dynamicContentBlocksEditorLoading }) => {
    switch (dynamicContentBlock.dynamicContentBlockType) {
        case DYNAMIC_CONTENT_BLOCK_TYPES.PDF:
            return (
                <PdfDynamicContentBlock
                    dynamicContentBlock={dynamicContentBlock}
                    onClickRemove={onClickRemove}
                />
            );
        case DYNAMIC_CONTENT_BLOCK_TYPES.VIDEO:
            return (
                <VideoDynamicContentBlock
                    dynamicContentBlock={dynamicContentBlock}
                    onClickRemove={onClickRemove}
                />
            );
        case DYNAMIC_CONTENT_BLOCK_TYPES.TEXT:
            return (
                <TextDynamicContentBlock
                    dynamicContentBlock={dynamicContentBlock}
                    onClickRemove={onClickRemove}
                    updateDynamicContentBlock={updateDynamicContentBlock}
                    dynamicContentBlocksEditorLoading={dynamicContentBlocksEditorLoading}
                />
            );
        default:
            console.error('Unknown dynamic content block type', dynamicContentBlock.dynamicContentBlockType);
            return null;
    }
};

const PdfDynamicContentBlockForm = ({ addDynamicContentBlock, loading }) => {
    const fileInput = useRef();
    const [selectedFile, setSelectedFile] = useState(null);
    const onClickSubmit = () => {
        if (!selectedFile) return;

        addDynamicContentBlock({
            type: DYNAMIC_CONTENT_BLOCK_TYPES.PDF,
            fileUri: selectedFile,
        });
    };

    const onFileChange = (event) => {
        setSelectedFile(null);

        if (!event.currentTarget.files || !event.currentTarget.files[0]) return;

        const newFile = event.currentTarget.files[0];

        setSelectedFile(newFile);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 13,
            }}
        >
            <div>
                <DynamicContentBlockFormHeadline>
                    Select PDF file
                </DynamicContentBlockFormHeadline>
            </div>
            <div>
                <input
                    type="file"
                    accept="application/pdf"
                    onChange={onFileChange}
                    ref={fileInput}
                />
            </div>
            <div>
                <button
                    onClick={onClickSubmit}
                    disabled={!selectedFile || loading}
                    className="ui green button"
                >
                    Add PDF
                </button>
            </div>
        </div>
    );
};

const VideoDynamicContentBlockForm = ({ addDynamicContentBlock, loading }) => {
    const fileInput = useRef();
    const [selectedFile, setSelectedFile] = useState(null);

    const onClickSubmit = () => {
        if (!selectedFile) return;

        addDynamicContentBlock({
            type: DYNAMIC_CONTENT_BLOCK_TYPES.VIDEO,
            fileUri: selectedFile,
        });
    };

    const onFileChange = (event) => {
        setSelectedFile(null);

        if (!event.currentTarget.files || !event.currentTarget.files[0]) return;

        const newFile = event.currentTarget.files[0];

        setSelectedFile(newFile);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 13,
            }}
        >
            <div>
                <DynamicContentBlockFormHeadline>
                    Select Video file
                </DynamicContentBlockFormHeadline>
            </div>
            <div>
                <input
                    type="file"
                    accept="video/mp4"
                    onChange={onFileChange}
                    ref={fileInput}
                />
            </div>
            <div>
                <button
                    onClick={onClickSubmit}
                    disabled={!selectedFile || loading}
                    className="ui green button"
                >
                    Add Video
                </button>
            </div>
        </div>
    );
};

const TextDynamicContentBlockForm = ({ addDynamicContentBlock, loading }) => {
    const [text, setText] = useState('');

    const onClickSubmit = () => {
        const trimmedText = text.trim();

        if (trimmedText === '') return;

        addDynamicContentBlock({
            type: DYNAMIC_CONTENT_BLOCK_TYPES.TEXT,
            text: trimmedText,
        });
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 13,
            }}
        >
            <div>
                <DynamicContentBlockFormHeadline>
                    Add text
                </DynamicContentBlockFormHeadline>
            </div>
            <div>
                <textarea
                    value={text}
                    onChange={(event) => setText(event.currentTarget.value)}
                    style={{ width: '100%' }}
                />
            </div>
            <div>
                <button
                    onClick={onClickSubmit}
                    disabled={text.trim() === '' || loading}
                    className="ui green button"
                >
                    Add Text Node
                </button>
            </div>
        </div>
    );
};

const SpecifiedDynamicContentBlockForm = ({ type, addDynamicContentBlock, loading }) => {
    switch (type) {
        case DYNAMIC_CONTENT_BLOCK_TYPES.PDF:
            return <PdfDynamicContentBlockForm addDynamicContentBlock={addDynamicContentBlock} loading={loading} />
        case DYNAMIC_CONTENT_BLOCK_TYPES.VIDEO:
            return <VideoDynamicContentBlockForm addDynamicContentBlock={addDynamicContentBlock} loading={loading} />
        case DYNAMIC_CONTENT_BLOCK_TYPES.TEXT:
            return <TextDynamicContentBlockForm addDynamicContentBlock={addDynamicContentBlock} loading={loading} />
        default:
            console.error('SpecifiedDynamicContentBlockForm: unknown type', type);
            return null;
    }
};

const DynamicContentBlockForm = ({ addDynamicContentBlock, loading }) => {
    const [type, setType] = useState(null);

    if (type) {
        return (
            <SpecifiedDynamicContentBlockForm
                type={type}
                addDynamicContentBlock={addDynamicContentBlock}
                loading={loading}
            />
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 13,
            }}
        >
            <div>
                <DynamicContentBlockFormHeadline>
                    Add content of type
                </DynamicContentBlockFormHeadline>
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: 5,
                }}
            >
                <button
                    onClick={() => setType(DYNAMIC_CONTENT_BLOCK_TYPES.PDF)}
                    className="ui button"
                >
                    PDF
                </button>
                <button
                    onClick={() => setType(DYNAMIC_CONTENT_BLOCK_TYPES.VIDEO)}
                    className="ui button"
                >
                    Video
                </button>
                <button
                    onClick={() => setType(DYNAMIC_CONTENT_BLOCK_TYPES.TEXT)}
                    className="ui button"
                >
                    Text
                </button>
            </div>
        </div>
    );
};

const AddContentBlockButton = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            style={{
                cursor: 'pointer',
                backgroundColor: 'rgb(232, 232, 232)',
                border: '1px solid #eaeaea',
                margin: 0,
                padding: 5,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 3,
                color: '#2b2b2b',
            }}
        >
            <FaPlus />
        </button>
    );
};

const DynamicContentTopicEditor = ({
    dynamicContentTopicReadableId,
}) => {
    const gap = 10;
    const [showDynamicContentBlockFormForIndex, setShowDynamicContentBlockFormForIndex] = useState(null);
    const {
        dynamicContentBlocks,
        removeDynamicContentBlock,
        addDynamicContentBlockAtIndex,
        loading: dynamicContentBlocksEditorLoading,
        updateDynamicContentBlock,
    } = useDynamicContentEditor({
        dynamicContentTopicReadableId,
    });

    if (dynamicContentBlocksEditorLoading || !dynamicContentBlocks) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }

    return (
        <div>
            {showDynamicContentBlockFormForIndex !== null && (
                <Dialog
                    onClose={() => setShowDynamicContentBlockFormForIndex(null)}
                    title={`Add content item at position ${showDynamicContentBlockFormForIndex + 1}`}
                >
                    <DynamicContentBlockForm
                        loading={dynamicContentBlocksEditorLoading}
                        addDynamicContentBlock={async (contentBlockParams) => {
                            await addDynamicContentBlockAtIndex(
                                showDynamicContentBlockFormForIndex,
                                contentBlockParams,
                            );

                            setShowDynamicContentBlockFormForIndex(null);
                        }}
                    />
                </Dialog>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap,
                    maxWidth: 500,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <AddContentBlockButton onClick={() => setShowDynamicContentBlockFormForIndex(0)} />
                </div>
                {dynamicContentBlocks.map((dynamicContentBlock, index) => (
                    <div
                        key={dynamicContentBlock.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap,
                            alignItems: 'center',
                        }}
                    >
                        <DynamicContentBlock
                            dynamicContentBlock={dynamicContentBlock}
                            onClickRemove={async () => {
                                const confirmed = window.confirm('Do you really want to remove this content? This action can not be undone.');

                                if (!confirmed) return;

                                await removeDynamicContentBlock(dynamicContentBlock.id);
                            }}
                            updateDynamicContentBlock={updateDynamicContentBlock}
                            dynamicContentBlocksEditorLoading={dynamicContentBlocksEditorLoading}
                        />
                        <div>
                            <AddContentBlockButton onClick={() => setShowDynamicContentBlockFormForIndex(index + 1)} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const NewTopicForm = ({ dynamicContentTopics, onTopicAdded }) => {
    const [readableId, setReadableId] = useState('');
    const existingReadableIds = useMemo(() => {
        return dynamicContentTopics
            .map((dynamicContentTopic) => dynamicContentTopic.readableId);
    }, [dynamicContentTopics]);
    const readableIdIsBlank = readableId === '';
    const isNotUnique = existingReadableIds.includes(readableId);
    const containsInvalidCharacters = readableIdIsBlank ? false : !(/^[0-9a-zA-Z_-]+$/.test(readableId));
    const [submitting, setSubmitting] = useState(false);

    const onClickSubmit = async () => {
        setSubmitting(true);

        const result = await API.addDynamicContentTopic({ readableId });

        setSubmitting(false);

        if (result) {
            onTopicAdded(readableId);
        }
    };

    return (
        <div>
            <DynamicContentBlockFormHeadline>
                Choose a name
            </DynamicContentBlockFormHeadline>
            <div>
                <ul>
                    <li>Allowed characters: a-z A-Z 0-9 _ -</li>
                    <li>Must be unique</li>
                </ul>
            </div>
            <div>
                <div className="ui input">
                    <input
                        value={readableId}
                        onChange={(event) => setReadableId(event.currentTarget.value)}
                    />
                </div>
            </div>
            {isNotUnique && (
                <div>
                    This name already exists
                </div>
            )}
            {containsInvalidCharacters && (
                <div>
                    Allowed characters: a-z A-Z 0-9 _ -
                </div>
            )}
            <div style={{ marginTop: 20 }}>
                <button
                    className="ui green button"
                    onClick={onClickSubmit}
                    disabled={submitting || readableIdIsBlank || isNotUnique || containsInvalidCharacters}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

const DynamicContentEditor = ({ dynamicContentTopics, reloadDynamicContentTopics }) => {
    const [showNewTopicForm, setShowNewTopicForm] = useState(false);
    const [dynamicContentTopic, setDynamicContentTopic] = useLocalStorage(
        'dynamiccontenteditor.topic',
        dynamicContentTopics[0] ? dynamicContentTopics[0].readableId : ''
    );
    const topicSelected = dynamicContentTopic !== '';
    const sortedDynamicContentTopics = useMemo(() => {
        return dynamicContentTopics.sort((a, b) => {
            if (a.readableId < b.readableId) {
                return -1;
            }
            if (a.readableId > b.readableId) {
                return 1;
            }

            return 0;
        });
    }, [dynamicContentTopics]);

    return (
        <div>
            {showNewTopicForm && (
                <Dialog
                    onClose={() => setShowNewTopicForm(false)}
                    title="Add Topic"
                >
                    <NewTopicForm
                        dynamicContentTopics={dynamicContentTopics}
                        onTopicAdded={(readableId) => {
                            reloadDynamicContentTopics();
                            setDynamicContentTopic(readableId);
                            setShowNewTopicForm(false);
                        }}
                    />
                </Dialog>
            )}
            <div style={{ display: 'flex', gap: 5 }}>
                <div
                    style={{ fontWeight: 'bold' }}
                >
                    Topic:
                </div>
                <div>
                    <select
                        value={dynamicContentTopic}
                        onChange={(event) => setDynamicContentTopic(event.currentTarget.value)}
                    >
                        <option value=""></option>
                        {sortedDynamicContentTopics.map((dynamicContentTopic) => (
                            <option
                                key={dynamicContentTopic.readableId}
                                value={dynamicContentTopic.readableId}
                            >
                                {dynamicContentTopic.readableId}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <button
                        className="ui button mini"
                        onClick={() => setShowNewTopicForm(true)}
                    >
                        Add Topic
                    </button>
                </div>
            </div>
            {topicSelected && (
                <div style={{ marginTop: 20 }}>
                    <DynamicContentTopicEditor
                        dynamicContentTopicReadableId={dynamicContentTopic}
                    />
                </div>
            )}
        </div>
    );
};

const DynamicContent = () => {
    const {
        dynamicContentTopics,
        initialized: dynamicContentTopicsInitialized,
        reload: reloadDynamicContentTopics,
    } = useDynamicContentTopics();

    return (
        <div>
            {(!dynamicContentTopicsInitialized || !dynamicContentTopics) ? (
                <Center>
                    <Spinner />
                </Center>
            ) : (
                <DynamicContentEditor
                    dynamicContentTopics={dynamicContentTopics}
                    reloadDynamicContentTopics={reloadDynamicContentTopics}
                />
            )}
        </div>
    );
};

export default DynamicContent;
