/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import * as Constants from "../common/webconstants.js";
import { Link } from "react-router-dom";
var classNames = require("classnames");

function InboxArchivePageTabs({
    activeTab,
    filterActive,
    filterButtonActive,
    onClickFilterButton,
    inboxLink,
}) {
    function tab(tabID, title, uri) {
        return (
            <Link to={uri} className={classNames("item", { active: activeTab === tabID })}>
                {title}
            </Link>
        );
    }

    const filterButton = (
        <button
            className={classNames("ui basic icon button", { active: filterButtonActive })}
            onClick={onClickFilterButton}
        >
            <i className={classNames("filter icon", { green: filterActive })} />
        </button>
    );

    return (
        <div
            className="ui secondary pointing menu"
            style={{ paddingBottom: 8, paddingTop: 10, marginBottom: 0, background: "white" }}
        >
            {tab(Constants.INBOX_MESSAGES_TAB, `Messages`, "/inbox/messages/archive")}
            {tab(Constants.INBOX_CHATS_TAB, `Chats`, "/inbox/chats/archive")}
            {tab(Constants.INBOX_SCANS_TAB, `Scans`, "/inbox/scans/archive")}

            <div className="right menu">
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Link to={inboxLink} className="ui basic icon button" style={{ marginRight: 10 }}>
                        <i className="inbox icon" />
                    </Link>
                    {filterButton}
                </div>
            </div>
        </div>
    );
}

export default InboxArchivePageTabs;
