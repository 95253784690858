import React from 'react';

const ConversationTopic = ({ topic }) => {
    return (
        <div
            style={{
                backgroundColor: '#ffff31',
                borderRadius: 3,
                padding: '3px 7px',
                fontSize: 12,
                color: '#1e1e1e',
            }}
        >
            {topic}
        </div>
    );
};

export default ConversationTopic;
