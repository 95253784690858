import { useState, useCallback, useEffect } from 'react';
import API from './api.js';
import usePrevious from '../common/usePrevious.js';

function useTaskData(taskID, setLoading, setErrMsg, onUpdate) {
    const [task, setTask] = useState(null);
    const prevTaskID = usePrevious(taskID);

    const loadTask = useCallback(async () => {
        setLoading(true);

        const data = await API.getTask(taskID);
        if (data) {
            setTask(data);
            onUpdate(data);
            setLoading(false);
            
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, [taskID, setLoading, setErrMsg, onUpdate]);

    useEffect(() => {
        setTask(null);
        if  (taskID) {
            if (taskID !== prevTaskID) {
                loadTask();
            }
        }
    }, [taskID, loadTask, prevTaskID]);

    return [task];
}

export default useTaskData;
