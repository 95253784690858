import React, { useState, useEffect, useCallback } from "react";
import MentionEditor from "../components/mentioneditor.js";
import MentionText from "../components/mentiontext.js";
import { formatHumanDateTime } from "../common/formatting.js";
var classNames = require("classnames");

function useComments(entityID, entity, api, possibleAssignees, readOnly, currentUser) {
    const [showCommentsSection, setShowCommentsSection] = useState(false);
    const [comments, setComments] = useState([]);
    const [loadingComments, setLoadingComments] = useState(false);
    const [savingComment, setSavingComment] = useState(false);
    const [newCommentEditorState, setNewCommentEditorState] = useState(null);
    const [errMsg, setErrMsg] = useState(null);

    const loadComments = useCallback(async () => {
        if (entityID) {
            setLoadingComments(true);
            let data = await api.getComments(entityID);
            if (data) {
                setComments(data);
                setShowCommentsSection(data.length > 0);
            } else {
                setErrMsg("Unable to load comments from server");
                setComments([]);
                setShowCommentsSection(false);
            }
            setLoadingComments(false);
        }
    }, [entityID, api]);

    useEffect(() => {
        setShowCommentsSection(false);
        loadComments();
        api.markMentionsRead(entityID);
    }, [entityID, loadComments, api]);

    async function saveComment() {
        setSavingComment(true);
        const newCommentText = newCommentEditorState.getText();
        const mentionees = newCommentEditorState.getMentionees();
        const comment = await api.createComment(entityID, newCommentText);
        if (comment !== null) {
            loadComments();
            setNewCommentEditorState(null);
            for (let i = 0; i < mentionees.length; ++i) {
                const mentionee = mentionees[i];
                const assignee = mentionee.is_user ? mentionee.id : null;
                const team = mentionee.is_team ? mentionee.id : null;
                api.createMentionMessage(
                    entity,
                    newCommentText,
                    assignee,
                    team,
                    currentUser.username,
                    comment.comment_id
                );
            }
        } else {
            setErrMsg("Failed to save comment");
        }
        setSavingComment(false);
    }

    const commentTextStyle = { backgroundColor: "white", padding: 8, border: "1px solid #CCC" };
    const contentSectionStyle = {
        backgroundColor: "#F8F8F8",
        marginTop: "3em",
        marginBottom: "2em",
        borderTop: "none",
    };
    const insetStyle = { paddingLeft: "3.3em" };

    const commentEls =
        comments &&
        comments.map((comment, inx) => {
            const timestamp = formatHumanDateTime(comment.created_at);
            return (
                <div className="comment" key={inx}>
                    <span className="avatar">
                        <i className="big user icon" />
                    </span>
                    <div className="content">
                        <span className="author">{comment.name}</span>
                        <div className="metadata">
                            <span className="date">{timestamp}</span>
                        </div>
                        <div className="text" style={commentTextStyle}>
                            <MentionText
                                text={comment.text}
                                possibleMentionees={possibleAssignees.filter((a) => a.id !== -1).map((a) => a.name)}
                            />
                        </div>
                    </div>
                </div>
            );
        });

    const commentsSpinner = (
        <div>
            <div className="ui active inline loader" style={{ marginTop: 20 }}></div>
        </div>
    );

    const commentReplyForm = (
        <form className="ui reply form segment">
            <div className="field">
                <MentionEditor
                    placeholder="Write a comment..."
                    value={newCommentEditorState}
                    onChange={(s) => setNewCommentEditorState(s)}
                    readOnly={savingComment}
                    mentionables={possibleAssignees.filter((a) => a.id !== -1)}
                />
            </div>
            <div
                className={classNames("ui primary labeled icon button", {
                    loading: savingComment,
                    hidden: newCommentEditorState === null || newCommentEditorState.getText().trim().length === 0
                })}
                onClick={saveComment}
            >
                <i className="icon edit"></i> Save Comment
            </div>
        </form>
    );

    const commentsSection = (
        <div className="content" style={contentSectionStyle}>
            <h3 className="ui header">
                <i className="comments icon" /> Comments
            </h3>
            <div className="ui comments" style={insetStyle}>
                {readOnly || commentReplyForm}
                {loadingComments ? commentsSpinner : commentEls}
            </div>
        </div>
    );

    let commentCounter = null;
    if (loadingComments) {
        commentCounter = "Loading...";
    } else if (errMsg) {
        commentCounter = "Error";
    } else if (comments) {
        if (comments.length === 0) {
            if (readOnly) {
                commentCounter = (
                    <>
                        <i className="comment icon" /> 0 Comments
                    </>
                );
            } else {
                commentCounter = (
                    <>
                        <i className="comment icon" /> Add Comment
                    </>
                );
            }
        } else if (comments.length === 1) {
            commentCounter = (
                <>
                    <i className="comment icon" /> 1 Comment
                </>
            );
        } else {
            commentCounter = (
                <>
                    <i className="comment icon" /> {comments.length} Comments
                </>
            );
        }
    }

    const toggleCommentsButton = (
        <button
            className={classNames("ui fluid labeled icon toggle button", { active: showCommentsSection })}
            style={{ marginBottom: "0.5em", textAlign: "left" }}
            onClick={() => setShowCommentsSection(!showCommentsSection)}
        >
            {commentCounter}
        </button>
    );

    return { comments, setComments, commentsSection: showCommentsSection && commentsSection, toggleCommentsButton };
}

export default useComments;
