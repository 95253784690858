import React, { useState } from 'react';
import API from '../common/api.js';
import { useHistory } from 'react-router-dom';
import {
    Form,
    LabeledTextInput,
    FormSubmitButton,
} from '../common/form';

function AddTextMessageTemplatePage() {
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const history = useHistory();

    const saveTemplate = async (event) => {
        event.preventDefault();

        setErrorMessages([]);
        setIsSubmitting(true);

        const creationResult = await API.createDefaultTextMessageTemplate({ message });

        if (creationResult) {
            const { textMessageTemplateId, errorMessages } = creationResult;

            setErrorMessages(errorMessages);

            if (textMessageTemplateId) {
                history.push('/settings');
                setMessage('');
            }
        }

        setIsSubmitting(false);
    };

    return (
        <>
            <h1 className="ui header">Add New Text Message Template</h1>
            <Form onSubmit={saveTemplate}>
                {errorMessages.map((errorMessage, index) => (
                    <div
                        key={index}
                        style={{ background: 'rgb(255, 192, 192)', padding: 10, marginBottom: 8 }}
                    >
                        {errorMessage}
                    </div>
                ))}
                <LabeledTextInput
                    id="message"
                    label="Message"
                    value={message}
                    onChange={setMessage}
                    lines={5}
                />
                <FormSubmitButton label="Submit" loading={isSubmitting} disabled={isSubmitting} />
            </Form>
        </>
    );
}

export default AddTextMessageTemplatePage;
