import React, { useState, useCallback, useEffect } from "react";
import API from "../common/api.js";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Select from "react-select";

function isValidExtension(extension) {
    const ext = parseInt(extension);
    return ext >= 1 && ext <= 999;
}

function AddVoicemailExtensionPage() {
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [extension, setExtension] = useState("");
    const [user, setUser] = useState(null);
    const [team, setTeam] = useState(null);
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    let history = useHistory();

    const valid = extension && isValidExtension(extension) && (user !== null || team !== null);

    const loadData = useCallback(async () => {
        const [usersData, teamsData] = await Promise.all([API.getUsers(), API.getTeams()]);

        if (teamsData) {
            setTeams(teamsData);
        } else {
            setErrMsg("Unable to load team data from server");
        }

        if (usersData) {
            setUsers(usersData);
        } else {
            setErrMsg("Unable to load user data from server");
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    async function save() {
        setSaving(true);
        const success = await API.createVoicemailExtension(extension, user?.value, team?.value);
        if (success) {
            history.push("/settings");
        } else {
            setErrMsg("ERROR: Saving voicemail extension failed! Please retry.");
            setSaving(false);
        }
    }

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    const possibleUsers = users && users.map((u) => ({ value: u.user_id, label: u.name }));
    const possibleTeams = teams && teams.map((t) => ({ value: t.team_id, label: t.name }));

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{ marginTop: 20 }}></div>
            </div>
        );
    } else {
        return (
            <div>
                {errmsgEl}
                <h1 className="ui header">Add New Voicemail Extension</h1>
                <div className="ui form segment">
                    <div className="field">
                        <label htmlFor="name">Extension (3 digits):</label>
                        <div className="w-88">
                            <input
                                type="text"
                                name="extension"
                                value={extension}
                                placeholder="Enter 3-digit extension, e.g. 101"
                                onChange={(ev) => {
                                    if (ev.target.value === "" || isValidExtension(ev.target.value)) {
                                        setExtension(ev.target.value);
                                    }
                                }}
                                required
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="email">Assign to user:</label>
                        <Select
                            options={possibleUsers}
                            placeholder="Select a user"
                            className="w-88"
                            value={user}
                            onChange={(option) => setUser(option)}
                            isClearable={true}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Assign to team:</label>
                        <Select
                            options={possibleTeams}
                            placeholder="Select a team"
                            className="w-88"
                            value={team}
                            onChange={(option) => setTeam(option)}
                            isClearable={true}
                        />
                    </div>

                    <button
                        className={classNames("ui primary button", { loading: saving, disabled: !valid })}
                        onClick={save}
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    }
}

export default AddVoicemailExtensionPage;
