import React, { useState } from 'react';
import './datepickerdropdown.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatHumandDate } from "../common/formatting.js";
var classNames = require('classnames');

function DatePickerDropdown({prefix, onChange, value, nullText, compact}) {
    const [showingMenu, setShowingMenu] = useState(false);
    const date = value ? new Date(value * 1000) : new Date();

    function setDate(d) {
        const newValue = d ? d.getTime() / 1000 : null;
        onChange(newValue);
        setShowingMenu(false);
    }
  
    const backdropStyle = {
        position: 'fixed', 
        left: 0, 
        top: 0, 
        right: 0, 
        bottom: 0, 
        background: 'transparent',
        zIndex: 1
    };

    const humanDate = formatHumandDate(value);

    const text = value ?
        `${prefix || ""}${humanDate}` :
        nullText;

    const clearButton = value && (<i className="close link icon" onClick={() => setDate(null)} style={{paddingLeft: 10, marginRight: 10}}/>);

    const dropdownStyle = {
        display: 'flex', 
        paddingLeft: 10, 
        paddingRight: 3, 
        paddingTop: 1, 
        paddingBottom: 3 
    };
    if (!compact) {
        dropdownStyle['height'] = 40;
        dropdownStyle['lineHeight'] = '38px';
    }

    return (
        <>
            <div className={classNames({hidden: !showingMenu})} style={backdropStyle} onClick={() => setShowingMenu(false)}/>
            <div style={{position: 'relative', minWidth: 180}}>
                <span style={dropdownStyle} className="date-picker-dropdown">
                    <span onClick={() => setShowingMenu(!showingMenu)}>{text}</span> <span style={{marginLeft: 'auto', color: "#CCC"}}>{clearButton} | <i className="chevron down link icon" onClick={() => setShowingMenu(!showingMenu)}/></span>
                </span>
                <div className={classNames("ui vertical text menu", {hidden: !showingMenu})} style={{position: 'absolute', left: 0, top: 30, zIndex: 2}}>
                    <DatePicker
                        selected={date}
                        onChange={d => setDate(d)}
                        inline
                    />
                </div>
            </div>
        </>
    );
}

export default DatePickerDropdown;
