import React, { useState } from 'react';
var classNames = require('classnames');

export const DROPDOWN_POSITION_LEFT = 'left';
export const DROPDOWN_POSITION_RIGHT = 'right';

function IconDropdown({icon, items, onChange, value, position}) {
    const [showingMenu, setShowingMenu] = useState(false);

    const backdropStyle = {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: 'transparent'
    };

    function itemClicked(item) {
        onChange(item.value);
        setShowingMenu(false);
    }

    const itemEls = items.map((item, inx) => {
        return (
            <span className={classNames("item", {active: value === item.value})} style={{margin: 0, padding: '1em'}} key={inx} onClick={() => itemClicked(item)}>
                {item.title}
            </span>
        );
    })

    const dropdownStyle = { position: 'absolute', top: 30, zIndex: 1 };
    switch (position) {
        case DROPDOWN_POSITION_LEFT:
            dropdownStyle.right = 0;
            break;
        default:
            dropdownStyle.left = 0;
    }

    return (
        <>
            <div className={classNames({hidden: !showingMenu})} style={backdropStyle} onClick={() => setShowingMenu(false)}/>
            <div style={{position: 'relative'}}>
                <button className="ui basic icon button" onClick={() => setShowingMenu(!showingMenu)}>
                    <i className={`${icon} icon`}/>
                </button>
                <div className={classNames("ui vertical text menu", {hidden: !showingMenu})} style={dropdownStyle}>
                    <div className="ui segment" style={{padding: 0}}>
                        {itemEls}
                    </div>
                </div>
            </div>
        </>
    );
}

export default IconDropdown;
