import useEventSource from "./useEventSource.js";
import PubSub from 'pubsub-js';

function useConnectPubSubSSE() {
    useEventSource({ endpoint: "/api/subscribe/sse", messageHandler: handleEventSourceMessage });

    function handleEventSourceMessage(e) {
        console.log("Incoming SSE event:");
        const data = JSON.parse(e.data);
        console.log(data)
        PubSub.publish(data.event, data.data);
    }

    return [];
}

export default useConnectPubSubSSE;
