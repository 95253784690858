import React from 'react';
import { get } from 'lodash';

function Assignee({task, onClick}) {
    const username = get(task, "assignee.name", null);
    const teamname = get(task, "team.name", null);
    const assigneeIcon = username ? "user icon" : "users icon";
    const displayName = username || teamname;
    if (displayName) {
        return (
            <span onClick={() => onClick(displayName)}><i className={assigneeIcon}/> {displayName}</span>
        );
    }
    return null;
}

export default Assignee;
