import { useState, useEffect, useCallback } from 'react';
import API from './api.js';

const useConversationMessages = ({ conversationId, page, pageSize }) => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const loadData = useCallback(async () => {
        setLoading(true);

        const response = await API.getConversationMessages(conversationId, { page, pageSize });

        setMessages(response || []);

        setInitialized(true);
        setLoading(false);
    }, [conversationId, page, pageSize]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const addToCachedMessages = (newMessage) => {
        setMessages([newMessage, ...messages]);
    };

    return {
        messages,
        loading,
        initialized,
        reload: loadData,
        addToCachedMessages,
    };
};

export default useConversationMessages;
