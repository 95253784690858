/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Constants from '../../common/webconstants.js';
import Spinner from '../spinner.js';
import Center from '../center.js';
import LeadModal from '../leadmodal.js';
import { JoinLeadCallButton } from '../leadcallbutton.js';
import LeadsPageLayout from "../leadspagelayout.js";
import useLocalStorage from '../../common/useLocalStorage.js';
import useAllUsers from '../../common/useAllUsers.js';
import Table from '../../common/table.js';
import usePubSub from '../../common/usePubSub.js';
import { useLeadsWithLoadMoreFunctionality } from '../../common/useLeads.js';
import { formatDateTimeHumanDateTime } from '../../common/formatting.js';
import LeadPriorityLabel from '../../common/leadprioritylabel.js';
import IconDropdown, { DROPDOWN_POSITION_LEFT } from '../icondropdown.js';
import FilterButton from './filterbutton.js';
import FilterBar from './filterbar.js';
var classNames = require('classnames');
var moment = require('moment');

const BATCH_SIZE = 20;

const StageLabel = ({ lead }) => {
    switch (lead.opportunityStage) {
        case 'Closed Won':
            return (
                <span style={{ color: 'green' }}>
                    Signed On
                </span>
            );
        case 'Closed Lost':
            return (
                <span style={{ color: 'red' }}>
                    Did Not Sign
                </span>
            );
        case 'Proposal':
        case 'Negotiation':
        case 'Follow Up':
        case 'Signup':
            return lead.opportunityStage;
        default:
            if (lead.status === 'Unqualified') {
                return lead.status;
            }

            return null;
    }
};

const LeadRow = ({ lead, leadIsPinned, togglePinLead, allUsers, leadUrl }) => {
    const isPinned = leadIsPinned(lead.id);
    const { getUserName } = useAllUsers({ allUsers });

    return (
        <tr key={lead.id} className="task-row" style={{ height: 50 }}>
            <td className={classNames({'border-none': false})} style={{ width: 175 }}>
                <Link to={leadUrl}>{lead.name}</Link>
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.benefitsTypeLabel || lead.benefitsType || '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.caseType ? lead.caseType : '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {getUserName(lead.assigneeId) || '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.consultRecommendation || '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                <StageLabel lead={lead} />
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.consultFieldsUpdatedAt ? formatDateTimeHumanDateTime(lead.consultFieldsUpdatedAt) : '-'}
            </td>
            <td
                className={classNames({'border-none': false})}
                style={{ width: 160 }}
            >
                {lead.waitingInVonageConversation && (
                    <JoinLeadCallButton
                        lead={lead}
                        leadUrl={leadUrl}
                        purpose={Constants.CALL_PURPOSES.CONSULTATION}
                    />
                )}
                <div className="ui mini compact basic icon buttons task-action-buttons">
                    <button
                        className={classNames("ui icon button", {active: isPinned})}
                        data-tooltip={isPinned ? "Unpin lead" : "Pin lead"}
                        data-position="top center"
                        onClick={() => togglePinLead(lead)}
                    >
                        <i className={classNames("thumbtack icon", {green: isPinned})}/>
                    </button>
                </div>
            </td>
            <td className={classNames("whitespace-no-wrap", {'border-none': false})}>
                <LeadPriorityLabel lead={lead} />
            </td>
        </tr>
    );
};

const LeadsArchiveTable = ({
    leads,
    leadIsPinned,
    togglePinLead,
    loadMoreLeads,
    moreLeadsAvailable,
    loadingLeads,
    allUsers,
    buildLeadUrl,
}) => {
    return (
        <div className={classNames({ hidden: false })}>
            <Table
                dim={loadingLeads}
                head={
                    <>
                        <th>Name of Lead</th>
                        <th>Benefits Type</th>
                        <th>Case Type</th>
                        <th>Assigned Attorney</th>
                        <th>Recommendation</th>
                        <th>Stage</th>
                        <th>Date Archived</th>
                        <th>Actions</th>
                        <th>Priority</th>
                    </>
                }
            >
                {leads.length === 0 && (
                    <tr className="dim">
                        <td className="border-none" colSpan={4}>No leads...</td>
                    </tr>
                )}
                {leads.map((lead) => (
                    <LeadRow
                        key={lead.id}
                        lead={lead}
                        leadIsPinned={leadIsPinned}
                        togglePinLead={togglePinLead}
                        allUsers={allUsers}
                        leadUrl={buildLeadUrl(lead.id)}
                    />
                ))}
                {moreLeadsAvailable && (
                    <tr>
                        <td className="border-none" colSpan={9}>
                            <button
                                className="ui compact fluid button"
                                onClick={loadMoreLeads}
                                disabled={loadingLeads}
                            >
                                Load more...
                            </button>
                        </td>
                    </tr>
                )}
            </Table>
        </div>
    );
};

const ActionButtons = ({
    sortOrder,
    setSortOrder,
    showFilterBar,
    setShowFilterBar,
    anyFiltersApplied,
    leadsUrl,
}) => {
    const sortOptions = [
        { value: Constants.SORT_BY_PRIORITY_ASC, title: "Highest priority first" },
        { value: Constants.SORT_BY_PRIORITY_DESC, title: "Lowest priority first" },
        { value: Constants.SORT_BY_CONSULT_FIELDS_UDPATED_AT_DESC, title: "Consult fields recently updated" },
    ];

    return (
        <>
            <a className="ui basic icon button" style={{ marginRight: 10 }} href={leadsUrl}>
                <i className={"inbox icon"} />
            </a>
            <div className="ui basic icon buttons" style={{marginRight: 10}}>
                <IconDropdown
                    icon="sort amount down"
                    items={sortOptions}
                    value={sortOrder}
                    onChange={(option) => setSortOrder(option)}
                    position={DROPDOWN_POSITION_LEFT}
                />
                <FilterButton
                    onClick={() => setShowFilterBar(!showFilterBar)}
                    active={showFilterBar}
                    pressed={anyFiltersApplied}
                />
            </div>
            <Link to="/add_lead" className="ui labeled primary icon button">
                <i className="plus icon" />
                Add Lead
            </Link>
        </>
    );
};

const LeadsPageArchiveLayout = ({
    allUsers,
    allTeams,
    leads,
    leadsErrorMessage,
    loadingLeads,
    expandedLeadID,
    leadModelDescription,
    opportunityModelDescription,
    queryFilter,
    setQueryFilter,
    reportLeadViewed,
    selectedAssigneeFilterOptions,
    setSelectedAssigneeFilterOptions,
    selectedPriorityFilterOptions,
    setSelectedPriorityFilterOptions,
    selectedBenefitsTypeFilterOptions,
    setSelectedBenefitsTypeFilterOptions,
    togglePinLead,
    leadIsPinned,
    anyFiltersApplied,
    sortOrder,
    setSortOrder,
    leadsInitialized,
    loadMoreLeads,
    moreLeadsAvailable,
    buildLeadUrl,
    leadsUrl,
    leadsArchiveUrl,
}) => {
    const [showFilterBar, setShowFilterBar] = useState(anyFiltersApplied);
    const history = useHistory();

    return (
        <LeadsPageLayout
            headline="Leads Archive"
            showFilterBar={showFilterBar}
            actionButtons={
                <ActionButtons
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    showFilterBar={showFilterBar}
                    setShowFilterBar={setShowFilterBar}
                    anyFiltersApplied={anyFiltersApplied}
                    leadsUrl={leadsUrl}
                />
            }
            filterBar={
                <FilterBar
                    allUsers={allUsers}
                    query={queryFilter}
                    setQuery={setQueryFilter}
                    leadModelDescription={leadModelDescription}
                    selectedAssigneeOptions={selectedAssigneeFilterOptions}
                    setSelectedAssigneeOptions={setSelectedAssigneeFilterOptions}
                    selectedPriorityOptions={selectedPriorityFilterOptions}
                    setSelectedPriorityOptions={setSelectedPriorityFilterOptions}
                    selectedBenefitsTypeOptions={selectedBenefitsTypeFilterOptions}
                    setSelectedBenefitsTypeOptions={setSelectedBenefitsTypeFilterOptions}
                    anyFiltersApplied={anyFiltersApplied}
                />
            }
        >
            {leadsErrorMessage && <div className="ui error message">{leadsErrorMessage}</div>}
            {expandedLeadID && (
                <LeadModal
                    id={expandedLeadID}
                    onClose={() => { history.push(leadsArchiveUrl) }}
                    isPinned={leadIsPinned(expandedLeadID)}
                    togglePinLead={togglePinLead}
                    allUsers={allUsers}
                    allTeams={allTeams}
                    reportLeadViewed={reportLeadViewed}
                    leadModelDescription={leadModelDescription}
                    opportunityModelDescription={opportunityModelDescription}
                    leadUrl={buildLeadUrl(expandedLeadID)}
                />
            )}
            {!leadsInitialized ? (
                <Center>
                    <Spinner />
                </Center>
            ) : (
                <LeadsArchiveTable
                    leads={leads}
                    leadIsPinned={leadIsPinned}
                    togglePinLead={togglePinLead}
                    loadMoreLeads={loadMoreLeads}
                    moreLeadsAvailable={moreLeadsAvailable}
                    loadingLeads={loadingLeads}
                    allUsers={allUsers}
                    buildLeadUrl={buildLeadUrl}
                />
            )}
        </LeadsPageLayout>
    );
};

const LeadsPageArchive = ({
    expandedLeadID,
    allUsers,
    allTeams,
    leadModelDescription,
    opportunityModelDescription,
    togglePinLead,
    leadIsPinned,
    reportLeadViewed,
    buildLeadUrl,
    leadsUrl,
    leadsArchiveUrl,
}) => {
    const [sortOrder, setSortOrder] = useLocalStorage('leadspage.archive.sortOrder', Constants.SORT_BY_CONSULT_FIELDS_UDPATED_AT_DESC);
    const [selectedAssigneeFilterOptions, setSelectedAssigneeFilterOptions] = useLocalStorage('leadspage.archive.selectedAssigneeFilterOptions', []);
    const [selectedPriorityFilterOptions, setSelectedPriorityFilterOptions] = useLocalStorage('leadspage.archive.selectedPriorityFilterOptions', []);
    const [selectedBenefitsTypeFilterOptions, setSelectedBenefitsTypeFilterOptions] = useLocalStorage('leadspage.archive.selectedBenefitsTypeFilterOptions', []);
    const [queryFilter, setQueryFilter] = useLocalStorage('leadspage.archive.queryFilter', '');
    const assigneeIdInFilter = useMemo(() => {
        return selectedAssigneeFilterOptions
            .map((selectedAssigneeOption) => selectedAssigneeOption.value);
    }, [selectedAssigneeFilterOptions]);
    const priorityInFilter = useMemo(() => {
        return selectedPriorityFilterOptions
            .map((selectedPriorityFilterOption) => selectedPriorityFilterOption.value);
    }, [selectedPriorityFilterOptions]);
    const benefitsTypeInFilter = useMemo(() => {
        return selectedBenefitsTypeFilterOptions
            .map((selectedBenefitsTypeFilterOption) => selectedBenefitsTypeFilterOption.value);
    }, [selectedBenefitsTypeFilterOptions]);
    const filters = useMemo(() => ({
        query: queryFilter,
        assigneeIdIn: assigneeIdInFilter,
        priorityIn: priorityInFilter,
        benefitsTypeIn: benefitsTypeInFilter,
        createdAtAfter: moment().add(-14, 'days'),
        orOperator: [
            { isConverted: true },
            { consultRecommendationIn: ['Deny It'] },
        ],
    }), [queryFilter, assigneeIdInFilter, priorityInFilter, benefitsTypeInFilter]);
    const {
        leads,
        loading: loadingLeads,
        leadsErrorMessage,
        initialized: leadsInitialized,
        loadMore: loadMoreLeads,
        moreAvailable: moreLeadsAvailable,
        reload: reloadLeads,
    } = useLeadsWithLoadMoreFunctionality({
        batchSize: BATCH_SIZE,
        sortOrder,
        filters,
    });
    const anyFiltersApplied = queryFilter.length > 0
        || assigneeIdInFilter.length > 0
        || priorityInFilter.length > 0
        || benefitsTypeInFilter.length > 0;

    usePubSub({
        topic: Constants.PUBSUB_TOPIC_LEAD,
        messageHandler: (topic, _data) => {
            if (
                topic === Constants.PUBSUB_TOPIC_LEAD_WAITING_IN_CONVERSATION_UPDATED ||
                    topic === Constants.PUBSUB_TOPIC_LEAD_UPDATED
            ) {
                reloadLeads();
            }
        },
    });

    return (
        <LeadsPageArchiveLayout
            allUsers={allUsers}
            allTeams={allTeams}
            leads={leads}
            leadsErrorMessage={leadsErrorMessage}
            loadingLeads={loadingLeads}
            expandedLeadID={expandedLeadID}
            leadModelDescription={leadModelDescription}
            opportunityModelDescription={opportunityModelDescription}
            leadsInitialized={leadsInitialized}
            loadMoreLeads={loadMoreLeads}
            moreLeadsAvailable={moreLeadsAvailable}
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
            reportLeadViewed={reportLeadViewed}
            selectedAssigneeFilterOptions={selectedAssigneeFilterOptions}
            setSelectedAssigneeFilterOptions={setSelectedAssigneeFilterOptions}
            selectedPriorityFilterOptions={selectedPriorityFilterOptions}
            setSelectedPriorityFilterOptions={setSelectedPriorityFilterOptions}
            selectedBenefitsTypeFilterOptions={selectedBenefitsTypeFilterOptions}
            setSelectedBenefitsTypeFilterOptions={setSelectedBenefitsTypeFilterOptions}
            togglePinLead={togglePinLead}
            leadIsPinned={leadIsPinned}
            anyFiltersApplied={anyFiltersApplied}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            buildLeadUrl={buildLeadUrl}
            leadsArchiveUrl={leadsArchiveUrl}
            leadsUrl={leadsUrl}
        />
    );
}

export default LeadsPageArchive;
