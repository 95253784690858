/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useRef, useEffect } from "react";
import SearchBox from "../components/searchbox.js";
import * as Constants from "../common/webconstants.js";
import Select from "react-select";
import AnimateHeight from "react-animate-height";
import generateSelectOptions from "../common/generateSelectOptions.js";

function TasksPageFilterBar({
    allCaseNumbers,
    possibleAssignees,
    onCaseNumberSearchBoxCommit,
    caseNumber,
    assigneeFilter,
    setAssigneeFilter,
    onTextSearchBoxCommit,
    searchText,
    sortOrder,
    clearFilters,
    visible,
    onUpdateHeight,
}) {
    const ref = useRef(null);
    const caseNumberSearchbox = (
        <SearchBox
            allPossibleValues={allCaseNumbers}
            onCommit={onCaseNumberSearchBoxCommit}
            initialValue={caseNumber || ""}
        />
    );
    const assigneeSelect = (
        <Select
            options={generateSelectOptions(possibleAssignees)}
            placeholder="Filter by assignee"
            className="min-w-64"
            value={assigneeFilter}
            onChange={(option) => {
                if (option && option.length === 0) option = null;
                setAssigneeFilter(option);
            }}
            isClearable={true}
            closeMenuOnSelect={false}
            isMulti
        />
    );
    const textSearchbox = (
        <SearchBox
            allPossibleValues={[]}
            onCommit={onTextSearchBoxCommit}
            initialValue={searchText || ""}
            placeholder="Filter on text..."
            large
        />
    );

    const clearFilterButton = (assigneeFilter !== null ||
        caseNumber !== null ||
        searchText !== null ||
        sortOrder !== Constants.SORT_HIGHEST_PRIORITY_FIRST) && (
        <div>
            <button className="ui tertiary button" onClick={clearFilters}>
                Reset filters
            </button>
        </div>
    );

    useEffect(() => {
        if (ref.current && visible) {
            onUpdateHeight(ref.current.offsetHeight);
        }
    });

    return (
        <AnimateHeight
            duration={250}
            height={visible ? "auto" : 0}
            onAnimationEnd={({ newHeight }) => () => onUpdateHeight(newHeight)}
        >
            <div className="filter-bar" ref={ref}>
                {caseNumberSearchbox}
                {assigneeSelect}
                {textSearchbox}
                {clearFilterButton}
            </div>
        </AnimateHeight>
    );
}

export default TasksPageFilterBar;
