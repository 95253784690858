/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useCallback } from 'react';
import API from '../../common/api.js';
import * as Constants from '../../common/webconstants.js';
import { formatDateTimeRange } from '../../common/formatting.js';
import { labelForLeadScheduledCallStatusValue } from '../../common/scheduledCallsUtils.js';
import { SelectField } from '../../common/form';
import BannerLayout from './bannerlayout.js';

const ScheduledCallResolveForm = ({ lead, activeLeadScheduledCall }) => {
    const [selectedResolveValue, setSelectedResolveValue] = useState(null);
    const [submittingResolve, setSubmittingResolve] = useState(false);

    const submitResolve = useCallback(async (selectedResolveValue) => {
        setSelectedResolveValue(selectedResolveValue);

        if (selectedResolveValue === null) return;

        setSubmittingResolve(true);

        const confirmed = window.confirm(`Do you want to mark this scheduled call "${labelForLeadScheduledCallStatusValue(selectedResolveValue)}"?`);

        if (confirmed) {
            await API.resolveLeadScheduledCall(
                lead.id,
                activeLeadScheduledCall.id,
                { status: selectedResolveValue }
            );
        } else {
            setSelectedResolveValue(null);
        }

        setSubmittingResolve(false);
    }, [activeLeadScheduledCall.id, lead.id]);

    return (
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
            <div>{formatDateTimeRange(activeLeadScheduledCall.scheduledForFrom, activeLeadScheduledCall.scheduledForTo)}</div>
            <SelectField
                value={selectedResolveValue}
                onChange={submitResolve}
                options={Object.keys(Constants.LEAD_SCHEDULED_CALLS_STATUSES).map((statusKey) => Constants.LEAD_SCHEDULED_CALLS_STATUSES[statusKey])}
                readOnly={submittingResolve}
                placeholder="Resolve"
            />
        </div>
    )
};

const ScheduledCallResolveBanner = ({ lead, activeLeadScheduledCall }) => {
    return (
        <BannerLayout
            backgroundColor="#ffff7c"
            headline="Call-Back Scheduled"
            headlineColor="rgb(106, 105, 36)"
        >
            <ScheduledCallResolveForm lead={lead} activeLeadScheduledCall={activeLeadScheduledCall} />
        </BannerLayout>
    );
};

export default ScheduledCallResolveBanner;
