import React, { useState, useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import EDDCredentialsMessage from '../eddcredentialsmessage.js';
import useMatterInfo from '../../common/useMatterInfo.js';
import withLoader from '../../common/withLoader.js';
import {get} from 'lodash';
import Enum from 'es6-enum';
import { formatHumanDateTime } from "../../common/formatting.js";

var moment = require('moment');
require("../../common/moment-timezone-with-data.js");
var classNames = require('classnames');

function ObstructionReviewDefault({caseNumber, matterInfo, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        obstructionResolved: null,
        noteSubject: "",
        noteDetail: ""
    });
    
    let historyTitle = "Obstruction Reviewed and Still Unresolved";
    let historyDetail = "We reviewed the obstruction in your case and as of now we have been unable to resolve it. Do not be despaired! We manage to resolve obstructions almost every time. We will revisit in 15 more days."

    const saveForm = useCallback(async (matterID) => {
        return await API.saveObstructionReview(formState.obstructionResolved, historyTitle, historyDetail, formState.noteSubject, formState.noteDetail, matterID);
    }, [formState.obstructionResolved, historyTitle, historyDetail, formState.noteSubject, formState.noteDetail]);

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={formState.obstructionResolved === null} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <p>Please check whether the obstruction been resolved in the mean time.</p>
            <EDDCredentialsMessage matterInfo={matterInfo}/>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.obstructionResolved === true})} onClick={() => updateFormState({obstructionResolved: true})}>Yes, it's resolved</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.obstructionResolved === false})} onClick={() => updateFormState({obstructionResolved: false})}>No, it's still obstructed</div>
                </div>
            </div>

            {formState.obstructionResolved === true && (
                <>
                    <div className="field">
                        <p>Press the Save button below to confirm your choice. The system will automatically clear the Obstruction status in Clio.</p>
                    </div>
                </>
            )}

            {formState.obstructionResolved === false && (
                <>
                    <div className="field">
                        <p>When you press the Save button below, the following entry will be added to this matter's history (this will be visible to the client):</p>
                        <div className="ui segment brandbackground brandborder">
                            <b>{historyTitle}</b>
                            <p>{historyDetail}</p>
                        </div>
                    </div>
                    <div className="field">
                        In addition, you can add a note to this matter as well (not visible to the client):
                    </div>
                    <div className="field">
                        <label htmlFor="note_subject">Subject:</label>
                        <input type="text" name="note_subject" value={formState.noteSubject} onChange={ev => updateFormState({noteSubject: ev.target.value})} placeholder="Enter subject..."/>
                    </div>
                    <div className="field">
                        <label htmlFor="note_detail">Note:</label>
                        <textarea type="text" name="note_detail" value={formState.noteDetail} onChange={ev => updateFormState({noteDetail: ev.target.value})} rows="10" placeholder="Enter your notes..."></textarea>
                    </div>
                </>
            )}
        </TaskForm>
    );
}

const GENERATION_STATUS = Enum("NONE", "BUSY", "SUCCESS", "FAILED");

function ObstructionReview60({caseNumber, matterInfo, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        obstructionResolved: null,
        lastGenerationTimestamp: null
    });
    const [generationStatus, setGenerationStatus] = useState(GENERATION_STATUS.NONE);
    
    const saveForm = useCallback(async (matterID) => {
        if (formState.obstructionResolved === false) {
            const historyTitle = "We Re-Filed Your Appeal";
            const historyDetail = "It has been 60 days since we filed the appeal and we have not been able to confirm the EDD processed it. We re-filed the appeal with a conspicuous cover page to get the EDD’s attention that we are intending to appeal your matter. Please allow 30-45 more days for the obstructed appeal to be processed. If we do not hear back after such time, we will escalate this matter further.";
            const noteSubject = "Generated Obstructed Appeal";
            const noteDetail = `${username} generated an obstructed appeal after being unable to confirm appeal 60 days after.`;
            return await API.saveObstructionReview(formState.obstructionResolved, historyTitle, historyDetail, noteSubject, noteDetail, matterID);
        } else {
            return await API.saveObstructionReview(formState.obstructionResolved, "", "", "", "", matterID);
        }
    }, [formState.obstructionResolved, username]);

    const generateDocument = useCallback(async () => {
        setGenerationStatus(GENERATION_STATUS.BUSY);
        const postData = {
            "matter_id": matterInfo.matter_id,
            "case_number": caseNumber,
            "practice_area": matterInfo.practice_area,
            "client_name": get(matterInfo, "client.name", ""),
            "client_street": get(matterInfo, "client.street", ""),
            "client_city": get(matterInfo, "client.city", ""),
            "client_postal_code": get(matterInfo, "client.postal_code", ""),
            "client_phone_number": get(matterInfo, "client.phone", ""),
            "client_state": get(matterInfo, "client.province", ""),
            "edd_determination_date": get(matterInfo, "custom_fields.notice_of_determination_date.value", "")
        }
        const zapURL = "https://hooks.zapier.com/hooks/catch/348024/ols1k3o/";
        const response = await API.postZap(zapURL, postData);

        if (response.status === "success") {
            updateFormState({lastGenerationTimestamp: moment.utc().unix()});
            setGenerationStatus(GENERATION_STATUS.SUCCESS);
        } else {
            alert("Something went wrong triggering zapier, no document was generated!");
            setGenerationStatus(GENERATION_STATUS.FAILED);
        }
    }, [updateFormState, matterInfo, caseNumber]);

    const humanGenerationDate = formatHumanDateTime(formState.lastGenerationTimestamp);
    const generationStatusText = 
        generationStatus === GENERATION_STATUS.FAILED ? "Document generation failed!!" :
        generationStatus === GENERATION_STATUS.SUCCESS ? `Triggered document generator ${humanGenerationDate}` :
        "";

    const disableSaveButton = 
        (formState.obstructionResolved === null) ||
        (formState.obstructionResolved === false && generationStatus !== GENERATION_STATUS.SUCCESS);

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={disableSaveButton} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <p>Please check whether the obstruction been resolved in the mean time.</p>
            <EDDCredentialsMessage matterInfo={matterInfo}/>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.obstructionResolved === true})} onClick={() => updateFormState({obstructionResolved: true})}>Yes, it's resolved</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.obstructionResolved === false})} onClick={() => updateFormState({obstructionResolved: false})}>No, it's still obstructed</div>
                </div>
            </div>

            {formState.obstructionResolved === true && (
                <>
                    <div className="field">
                        <p>Press the Save button below to confirm your choice. The system will automatically clear the Obstruction status in Clio.</p>
                    </div>
                </>
            )}

            {formState.obstructionResolved === false && (
                <>
                    <div className="field">
                        <p>Press the button below to generate the obstructed appeal document. Once it's generated, it will be emailed to case_management@pershingsquarelaw.com</p>
                        <p>In addition, a note will be added to the matter in Clio.</p>
                        <p><b>IMPORTANT</b>: don't forget to press the Save button below as well!</p>
                        <div>
                            <div className={classNames("ui primary button", {loading: generationStatus === GENERATION_STATUS.BUSY})} onClick={generateDocument}>Generate Obstructed Appeal</div>
                            <span className="dim">{generationStatusText}</span>
                        </div>
                    </div>
                </>
            )}
        </TaskForm>
    );
}



function ObstructionReview({caseNumber, username, extraData, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [matterInfo, loading, errMsg] = useMatterInfo(caseNumber, {includingClientData: true});

    const params = {caseNumber, matterInfo, username, initialFormState, markTaskDone, completedAt, markTaskDoing};

    return withLoader(loading, errMsg, () => (
        (extraData.days_stale) === 60 ? 
        <ObstructionReview60 {...params}/> :
        <ObstructionReviewDefault {...params}/>
    ));
}

export default ObstructionReview;
