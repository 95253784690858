/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import { LEAD_PRIO_LABELS, LEAD_PRIO_COLORS } from '../common/webconstants.js';

function leadPriorityLabel(lead) {
    return LEAD_PRIO_LABELS[lead.priority || '0'];
}

function leadPriorityColor(lead) {
    if (!lead.priority) return 'grey';

    return LEAD_PRIO_COLORS[lead.priority];
}

function LeadPriorityLabel({ lead }) {
    return (
        <div className={`ui ${leadPriorityColor(lead)} horizontal label w-24`}>
            {leadPriorityLabel(lead)}
        </div>
    )
}

export default LeadPriorityLabel;
