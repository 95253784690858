import React, { useState, useRef, useCallback, useContext } from "react";
import API from "../common/api.js";
import SearchBox from "../components/searchbox.js";
import withLoader from "../common/withLoader.js";
import useInboxMessageData from "../common/useInboxMessageData.js";
import useScanData from "../common/useScanData.js";
import PDFViewer from "../components/pdfviewer.js";
import { useMessageModal } from "../common/useModal.js";
import AudioPlayer from "../components/audioplayer.js";
import { useHistory } from "react-router-dom";
import * as Constants from "../common/webconstants.js";
import { CurrentUserContext } from "../common/currentUserContext.js";
var classNames = require("classnames");

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const DOC_TYPE_UNKNOWN = -1;
const DOC_TYPE_PDF = 1;
const DOC_TYPE_MP3 = 2;

function UploadDocumentPage({ allCaseNumbers, initializeFromMessageID, initializeFromScanID }) {
    const [loading, setLoading] = useState(false);
    const [documentType, setDocumentType] = useState(DOC_TYPE_UNKNOWN);
    const [documentName, setDocumentName] = useState("");
    const [shareWithClient, setShareWithClient] = useState(false);
    const [uri, setURI] = useState(null);
    const [notes, setNotes] = useState(null);
    const [busy, setBusy] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [caseNumber, setCaseNumber] = useState(null);
    const formEl = useRef(null);
    const [conversionStatus, setConversionStatus] = useState(null);
    const currentUser = useContext(CurrentUserContext);
    const [returnURI, setReturnURI] = useState("/inbox");
    let history = useHistory();
    const [openUploadSuccessModal, uploadSuccessModal] = useMessageModal({
        title: "Upload complete",
        msg: "The file was uploaded to Clio sucessfully!",
        action: () => {
            history.push(returnURI);
        },
    });

    useInboxMessageData(
        initializeFromMessageID,
        setLoading,
        setErrMsg,
        useCallback(
            (message) => {
                setDocumentName(message.subject);
                setCaseNumber(message.case_number);
                if (message.uri) {
                    setURI(message.uri);

                    if (message.uri.toLowerCase().endsWith(".pdf")) {
                        setDocumentType(DOC_TYPE_PDF);
                    } else if (message.uri.toLowerCase().endsWith(".mp3")) {
                        setDocumentType(DOC_TYPE_MP3);
                    } else {
                        setErrMsg("Error: unsupported file type, must be PDF or mp3");
                    }

                    setShareWithClient(false);
                    setNotes(null);
                    setConversionStatus(null);
                    if (message.acknowledged) {
                        setReturnURI("/inbox/messages/archive");
                    } else {
                        setReturnURI("/inbox/messages");
                    }
                } else {
                    setErrMsg("Error: this message does not have a file attachment to upload");
                }
            },
            [setDocumentName, setCaseNumber, setURI, setDocumentType, setShareWithClient, setNotes, setConversionStatus]
        )
    );

    useScanData(
        initializeFromScanID,
        setLoading,
        setErrMsg,
        useCallback(
            (scan) => {
                setDocumentName(scan.filename);
                setCaseNumber(scan.case_number);
                if (scan.uri) {
                    setURI(scan.uri);

                    if (scan.uri.toLowerCase().endsWith(".pdf")) {
                        setDocumentType(DOC_TYPE_PDF);
                    } else {
                        setErrMsg("Error: unsupported file type, must be PDF.");
                    }

                    setShareWithClient(false);
                    setNotes(null);
                    setConversionStatus(null);
                    if (scan.acknowledged) {
                        setReturnURI("/inbox/scans/archive");
                    } else {
                        setReturnURI("/inbox/scans");
                    }
                } else {
                    setErrMsg("Error: this scan does not have a file attachment to upload");
                }
            },
            [setDocumentName, setURI, setDocumentType, setShareWithClient, setNotes, setConversionStatus]
        )
    );

    async function upload() {
        if (formEl.current.reportValidity()) {
            setBusy(true);
            const matterIDResponse = await API.getMatterID(caseNumber);
            if (matterIDResponse) {
                if (documentType === DOC_TYPE_PDF) {
                    const documentID = await API.createDocument(
                        matterIDResponse.matterID,
                        documentName,
                        shareWithClient,
                        "application/pdf"
                    );
                    const success = await API.uploadPdfByUrl(documentID, uri);
                    if (success) {
                        if (notes && notes.length > 0) {
                            await API.uploadNotes(documentID, notes);
                        }
                        const conversionStarted = await API.convertToPDF(documentID); // In our case, not really a conversion since it's already a PDF. But this kicks off storing on Clio servers.
                        if (conversionStarted) {
                            while (true) {
                                const conversion = await API.getConversionStatus(documentID);
                                if (conversion === null || conversion === "failed") {
                                    setErrMsg("Failed to store file. Please retry.");
                                    break;
                                } else if (conversion.status === "done") {
                                    if (initializeFromScanID) {
                                        await API.addScanActionHistoryItem(
                                            Constants.SCAN_ACTION_HISTORY_UPLOADED_TO_CLIO,
                                            initializeFromScanID,
                                            currentUser.username,
                                            `Uploaded to Clio case ${caseNumber}`
                                        );
                                        await API.updateScan(initializeFromScanID, { case_number: caseNumber });
                                    }
                                    if (initializeFromMessageID) {
                                        await API.updateInboxMessage(initializeFromMessageID, {
                                            case_number: caseNumber,
                                        });
                                    }
                                    openUploadSuccessModal();
                                    break;
                                } else {
                                    setConversionStatus(conversion.status);
                                }
                                await timeout(500); // ms
                            }
                        } else {
                            setErrMsg("Failed to store file. Please retry.");
                        }
                    } else {
                        setErrMsg("Failed to upload file. Please retry.");
                    }
                } else if (documentType === DOC_TYPE_MP3) {
                    const documentID = await API.createDocument(
                        matterIDResponse.matterID,
                        documentName,
                        false,
                        "audio/mpeg"
                    );
                    let success = await API.uploadMp3ByUrl(documentID, uri);
                    if (success) {
                        success = await API.storeOnClio(documentID);
                        if (success) {
                            while (true) {
                                const conversion = await API.getStoreOnClioStatus(documentID);
                                if (conversion === null) {
                                    setErrMsg("Failed to store file. Please retry.");
                                    break;
                                } else if (conversion.status === "done") {
                                    openUploadSuccessModal();
                                    break;
                                } else {
                                    setConversionStatus(conversion.status);
                                }
                                await timeout(500); // ms
                            }
                        } else {
                            setErrMsg("Failed to store file. Please retry.");
                        }
                    } else {
                        setErrMsg("Failed to upload file. Please retry.");
                    }
                }
            } else {
                setErrMsg("Failed to retrieve matter ID. Please retry.");
            }
            setBusy(false);
        }
    }

    const errMsgEl = errMsg !== null && (
        <div className="ui visible error message">
            <p>{errMsg}</p>
        </div>
    );

    const caseNumberSearchbox = (
        <SearchBox
            allPossibleValues={allCaseNumbers}
            onCommit={(caseNumber) => setCaseNumber(caseNumber)}
            initialValue={caseNumber || ""}
            placeholder="Enter case number"
        />
    );

    const fieldsComplete = documentName !== "" && caseNumber !== null && uri !== null;

    const pdfFields = (
        <>
            <div className="field ten wide">
                <label>Document Name</label>
                <input
                    type="text"
                    placeholder="Enter document name..."
                    value={documentName}
                    onChange={(ev) => setDocumentName(ev.target.value)}
                    required
                />
            </div>
            <div className="field ten wide">
                <label>Document Preview</label>
                <PDFViewer uri={uri} nocontrols />
            </div>
            <div className="field ten wide">
                <label>Type any notes you want to add below</label>
                <textarea
                    placeholder="Enter notes (optional)..."
                    lines={6}
                    onChange={(ev) => setNotes(ev.target.value)}
                ></textarea>
            </div>
            <div className="field">
                <label>Share settings</label>
                <div
                    className={`ui toggle ${shareWithClient ? "checked" : ""} checkbox`}
                    onClick={() => setShareWithClient(!shareWithClient)}
                >
                    <input
                        type="checkbox"
                        tabIndex="0"
                        className="hidden"
                        {...{ checked: shareWithClient }}
                        onChange={() => {}}
                    />
                    <label>Share document with client</label>
                </div>
            </div>
        </>
    );

    const mp3Fields = (
        <>
            <div className="field ten wide">
                <label>Audio Clip Name</label>
                <input
                    type="text"
                    placeholder="Enter audio clip name..."
                    value={documentName}
                    onChange={(ev) => setDocumentName(ev.target.value)}
                    required
                />
            </div>
            <div className="field ten wide">
                <label>Listen To Clip</label>
                <AudioPlayer uri={uri} />
            </div>
        </>
    );

    return withLoader(loading, errMsg, () => (
        <div
            className="ui container"
            style={{ height: "100%", overflowY: "auto", paddingRight: 10, paddingBottom: 10 }}
        >
            <div className="ui hidden divider"></div>
            <div className="ui raised segment">
                <h1 className="ui header">Upload Document To Clio</h1>
                <div className="content">
                    <div className="description">
                        <form className="ui form" ref={formEl}>
                            <div className="field six wide">
                                <label htmlFor="casenumber">Case Number</label>
                                {caseNumberSearchbox}
                            </div>
                            {documentType === DOC_TYPE_PDF && pdfFields}
                            {documentType === DOC_TYPE_MP3 && mp3Fields}
                            <div
                                className={classNames("ui positive labeled icon button", {
                                    loading: busy,
                                    disabled: !fieldsComplete,
                                })}
                                style={{ marginTop: "1em" }}
                                onClick={upload}
                            >
                                <i className="check icon" /> Upload
                            </div>
                            {busy && <p>{conversionStatus}</p>}
                            {errMsgEl}
                        </form>
                    </div>
                </div>
            </div>
            <div className="ui hidden divider"></div>
            {uploadSuccessModal}
        </div>
    ));
}

export default UploadDocumentPage;
