import { useState, useEffect, useCallback } from 'react';
import API from './api.js';

const useConversation = ({ conversationId }) => {
    const [conversation, setConversation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const loadData = useCallback(async () => {
        setLoading(true);

        const response = await API.getConversation(conversationId);

        setConversation(response || null);

        setInitialized(true);
        setLoading(false);
    }, [conversationId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const updateLastReadMessageCreatedAt = useCallback(({ participantType, participantId, createdAt }) => {
        if (!conversation) return;

        setConversation({
            ...conversation,
            participants: conversation.participants.map((participant) => {
                if (participant.participant_type === participantType && participant.participant_id === participantId) {
                    participant.latestReadMessageCreatedAt = createdAt;

                    return {
                        ...participant,
                        latestReadMessageCreatedAt: createdAt,
                    };
                } else {
                    return { ...participant };
                }
            }),
        });
    }, [conversation]);

    const updateArchived = useCallback((archived) => {
        if (!conversation) return;

        setConversation({
            ...conversation,
            archived,
        });
    }, [conversation]);

    return {
        conversation,
        loading,
        initialized,
        reload: loadData,
        updateLastReadMessageCreatedAt,
        updateArchived,
    };
};

export default useConversation;
