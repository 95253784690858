/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useMemo } from 'react';
import SearchBox from "../searchbox.js";
import Select from 'react-select';
import { LEAD_PRIO_LABELS } from '../../common/webconstants.js';

const FilterBar = ({
    query,
    setQuery,
    leadModelDescription,
    selectedAssigneeOptions,
    setSelectedAssigneeOptions,
    selectedPriorityOptions,
    setSelectedPriorityOptions,
    selectedBenefitsTypeOptions,
    setSelectedBenefitsTypeOptions,
    allUsers,
    anyFiltersApplied,
}) => {
    const assigneeOptions = useMemo(() => {
        return allUsers
            .filter((user) => user.isAttorney)
            .map((user) => ({ value: user.user_id, label: user.name }));
    }, [allUsers]);

    const priorityOptions = useMemo(() => {
        const originalOrder = Object
            .entries(LEAD_PRIO_LABELS)
            .map(([priorityValue, priorityLabel]) => ({
                label: priorityLabel,
                value: priorityValue,
            }));

        const presentableOrder = [...originalOrder.splice(1), originalOrder[0]];

        return presentableOrder;
    }, []);

    const clearFilters = () => {
        setQuery('')
        setSelectedAssigneeOptions([])
        setSelectedPriorityOptions([])
        setSelectedBenefitsTypeOptions([])
    };

    return (
        <>
            <SearchBox
                allPossibleValues={[]}
                onCommit={(serachboxsubmit) => setQuery(serachboxsubmit || '')}
                initialValue={query}
                placeholder="Filter by name or phone..."
                large
            />
            <Select
                options={leadModelDescription ? leadModelDescription.fields.benefitsType.options : []}
                placeholder="Filter by Benefits Type"
                className="min-w-64"
                value={selectedBenefitsTypeOptions}
                onChange={(selectedOptions) => setSelectedBenefitsTypeOptions(selectedOptions || [])}
                isClearable
                closeMenuOnSelect={false}
                isMulti
                isDisabled={!leadModelDescription}
            />
            <Select
                options={priorityOptions}
                placeholder="Filter by priority"
                className="min-w-64"
                value={selectedPriorityOptions}
                onChange={(selectedOptions) => setSelectedPriorityOptions(selectedOptions || [])}
                isClearable
                closeMenuOnSelect={false}
                isMulti
            />
            <Select
                options={assigneeOptions}
                placeholder="Filter by assignee"
                className="min-w-64"
                value={selectedAssigneeOptions}
                onChange={(selectedOptions) => setSelectedAssigneeOptions(selectedOptions || [])}
                isClearable
                closeMenuOnSelect={false}
                isMulti
            />
            {anyFiltersApplied && (
                <div>
                    <button className="ui tertiary button" onClick={clearFilters}>
                        Reset filters
                    </button>
                </div>
            )}
        </>
    );
};

export default FilterBar;
