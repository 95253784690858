import React from 'react';
import TasksArchivePage from '../pages/tasksarchivepage.js';

/*
    This rather silly component is a workaround for a bug on Chrome: 
    calling setters from a useEventSource message handler doesn't work on 
    components that are rendered by components that are directly rendered
    by a <Route/>, but it does work for subcomponents.

    So the purpose of this component is simply to have an additional component 
    layer.

    It's not required in Firefox or Safari, but for some reason I don't understand
    it is required in Chrome (at least, it was in version 81.0.4044.122)
 */
function TasksArchivePageWrapper(props) {
    return <TasksArchivePage {...props}/>;
}

export default TasksArchivePageWrapper;
