/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useState } from 'react';
import API from '../common/api.js';
import { useLeadField, prepareValue } from './useformhelpers.js';

const useUpdateOpportunityForm = ({ lead, opportunityModelDescription, onUpdate }) => {
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const {
        value: contingency,
        setValue: setContingency,
        valueChanged: contingencyChanged,
        resetValue: resetContingency,
    } = useLeadField({ lead, fieldName: 'opportunityContingency' });
    const {
        value: consultNote,
        setValue: setConsultNote,
        valueChanged: consultNoteChanged,
        resetValue: resetConsultNote,
    } = useLeadField({ lead, fieldName: 'opportunityConsultNote' });
    const {
        value: consultRecommendation,
        setValue: setConsultRecommendation,
        valueChanged: consultRecommendationChanged,
        resetValue: resetConsultRecommendation,
    } = useLeadField({ lead, fieldName: 'opportunityConsultRecommendation' });
    const {
        value: benefitsType,
        setValue: setBenefitsType,
        valueChanged: benefitsTypeChanged,
        resetValue: resetBenefitsType,
    } = useLeadField({ lead, fieldName: 'opportunityBenefitsType' });
    const {
        value: caseType,
        setValue: setCaseType,
        valueChanged: caseTypeChanged,
        resetValue: resetCaseType,
    } = useLeadField({ lead, fieldName: 'opportunityCaseType' });
    const {
        value: caseStatus,
        setValue: setCaseStatus,
        valueChanged: caseStatusChanged,
        resetValue: resetCaseStatus,
    } = useLeadField({ lead, fieldName: 'opportunityCaseStatus' });
    const {
        value: receivedEddDeterminationDate,
        setValue: setReceivedEddDeterminationDate,
        valueChanged: receivedEddDeterminationDateChanged,
        resetValue: resetReceivedEddDeterminationDate,
    } = useLeadField({ lead, fieldName: 'opportunityReceivedEddDeterminationDate' });

    const consultRecommendationOptions = [
        { value: '', label: '' },
        ...opportunityModelDescription.fields.consultRecommendation.options,
    ];
    const contingencyOptions = [
        { value: '', label: '' },
        ...opportunityModelDescription.fields.contingency.options,
    ];
    const benefitsTypeOptions = [
        { value: '', label: '' },
        ...opportunityModelDescription.fields.benefitsType.options,
    ];
    const caseTypeOptions = [
        { value: '', label: '' },
        ...opportunityModelDescription.fields.caseType.options,
    ];
    const caseStatusOptions = [
        { value: '', label: '' },
        ...opportunityModelDescription.fields.caseStatus.options,
    ];

    const submit = async () => {
        setErrorMessage(null);
        setSubmitting(true);

        const params = {
            contingency: prepareValue(contingency, lead.opportunityContingency, { nullify: true }),
            consultNote: prepareValue(consultNote, lead.opportunityConsultNote, { nullify: true }),
            consultRecommendation: prepareValue(consultRecommendation, lead.opportunityConsultRecommendation, { nullify: true }),
            benefitsType: prepareValue(benefitsType, lead.opportunityBenefitsType, { nullify: true }),
            caseType: prepareValue(caseType, lead.opportunityCaseType, { nullify: true }),
            caseStatus: prepareValue(caseStatus, lead.opportunityCaseStatus, { nullify: true }),
            receivedEddDeterminationDate: prepareValue(receivedEddDeterminationDate, lead.opportunityReceivedEddDeterminationDate, { nullify: true }),
            updatedAt: lead.opportunityUpdatedAt,
        };

        const updateLeadResult = await API.updateOpportunity(lead.id, params);

        if (!updateLeadResult || !updateLeadResult.success) {
            setErrorMessage(updateLeadResult.errorMessage);
            setSubmitting(false);

            return;
        }

        if (onUpdate) {
            onUpdate({
                leadUpdated: true,
            });
        }

        setSubmitting(false);
    };

    return {
        submit,
        submitting,
        errorMessage,

        contingency,
        setContingency,
        contingencyChanged,
        resetContingency,
        consultNote,
        setConsultNote,
        consultNoteChanged,
        resetConsultNote,
        consultRecommendation,
        setConsultRecommendation,
        consultRecommendationChanged,
        resetConsultRecommendation,
        benefitsType,
        setBenefitsType,
        benefitsTypeChanged,
        resetBenefitsType,
        caseType,
        setCaseType,
        caseTypeChanged,
        resetCaseType,
        caseStatus,
        setCaseStatus,
        caseStatusChanged,
        resetCaseStatus,
        receivedEddDeterminationDate,
        setReceivedEddDeterminationDate,
        receivedEddDeterminationDateChanged,
        resetReceivedEddDeterminationDate,

        contingencyOptions,
        caseStatusOptions,
        caseTypeOptions,
        benefitsTypeOptions,
        consultRecommendationOptions,
    };
}

export default useUpdateOpportunityForm;
