import { useCallback, useState, useEffect } from "react";
import API from "../common/api.js";

const useSettings = () => {
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const loadData = useCallback(async () => {
        setLoading(true);

        const settingsResponse = await API.getSettings();

        setSettings(settingsResponse);

        setLoading(false);
        setInitialized(true);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        settings,
        loading,
        initialized,
        reload: loadData,
    };
};

export default useSettings;
