/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useEffect, useState } from 'react';
import API from '../common/api.js';

function useOpportunityModelDescription() {
    const [loading, setLoading] = useState(true);
    const [opportunityModelDescription, setOpportunityModelDescription] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const loadOpportunityModelDescription = async () => {
        const opportunityModelDescription = await API.getOpportunityModelDescription();

        if (opportunityModelDescription) {
            setOpportunityModelDescription(opportunityModelDescription);
        } else {
            setErrorMessage(
                'Failed to query required information from API. Try reloading the page or report this issue, if it persists.'
            );
        }

        setLoading(false);
    }

    useEffect(() => {
        loadOpportunityModelDescription();
    }, []);

    return {
        opportunityModelDescription,
        loadingLeadModelDescription: loading,
        loadingLeadModelDescriptionErrorMessage: errorMessage,
    };
};

export default useOpportunityModelDescription;
