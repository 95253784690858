import React, { useState, useCallback, useEffect, useContext } from "react";
import API from "../common/api.js";
import classNames from "classnames";
import * as Constants from "../common/webconstants.js";
import SearchBox from "../components/searchbox.js";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useMessageModal, useDynamicYesNoModal } from "../common/useModal.js";
import usePrevious from "../common/usePrevious.js";
import { CurrentUserContext } from "../common/currentUserContext.js";
import { useHistory } from "react-router-dom";

function RequiredFieldIndicator() {
    return <span style={{ color: "red" }}>*</span>;
}

function CallFormPage({ users, teams, allCaseNumbers, authkey }) {
    const initialPriority = {
        value: Constants.MESSAGE_PRIO_NORMAL,
        label: Constants.MESSAGE_PRIO_LABELS[Constants.MESSAGE_PRIO_NORMAL],
    };
    const [loadingClientData, setLoadingClientData] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState(initialPriority);
    const [caseNumber, setCaseNumber] = useState(null);
    const [assignee, setAssignee] = useState(null);
    const [nameOfCaller, setNameOfCaller] = useState("");
    const [callbackNumber, setCallbackNumber] = useState("");
    const [openSuccessAlert, successAlert] = useMessageModal({
        title: "Message Saved!",
        msg: "The message has been saved.",
        action: () => {
            window.location.reload();
        },
    });
    const previousCaseNumber = usePrevious(caseNumber);
    const currentUser = useContext(CurrentUserContext);
    let history = useHistory();

    useEffect(() => {
        if (currentUser === null) {
            const team = teams.find((t) => t.name === Constants.TEAM_CASE_MANAGEMENT);
            if (team) {
                setAssignee({ value: `team:${team.team_id}`, label: team.name });
            }
        }
    }, [currentUser, teams]);

    const valid = subject !== "" && nameOfCaller !== "" && assignee !== null;

    const loadClient = useCallback(async () => {
        setLoadingClientData(true);

        const data = await API.getCaseStatus(caseNumber, { authkey });
        if (data) {
            const client = await API.getContactInfo(data.info.client_id, { authkey });
            if (nameOfCaller === "") {
                setNameOfCaller(client.name);
            }
            if (callbackNumber === "") {
                setCallbackNumber(client.phone);
            }
            setLoadingClientData(false);
        } else {
            setLoadingClientData(false);
        }
    }, [caseNumber, setLoadingClientData, nameOfCaller, callbackNumber, authkey]);

    useEffect(() => {
        if (caseNumber !== previousCaseNumber && caseNumber !== null && caseNumber !== "") {
            loadClient();
        }
    }, [caseNumber, previousCaseNumber, loadClient]);

    const [openConfirmationModal, confirmationModal] = useDynamicYesNoModal();

    async function doSave(onSuccess) {
        setSaving(true);
        setErrMsg(null);
        const [assignee_kind, assignee_id] = assignee ? assignee.value.split(":") : [null, null];
        const authorName = currentUser ? currentUser.username : "Ruby Receptionist";
        const success = await API.createCallMessage(
            subject,
            description,
            priority.value,
            caseNumber,
            assignee_kind === "user" ? assignee_id : null,
            assignee_kind === "team" ? assignee_id : null,
            nameOfCaller,
            callbackNumber,
            authorName,
            { authkey }
        );
        if (success) {
            if (onSuccess) {
                onSuccess();
            } else {
                openSuccessAlert();
            }
        } else {
            setErrMsg("ERROR: Saving message failed! Please retry.");
            setSaving(false);
        }
    }

    function save(ev, onSuccess) {
        if ((caseNumber === null) && (currentUser !== null)) { // This check does not apply to Ruby Receptionist messages
            openConfirmationModal({
                title: "Are You Sure?",
                prompt:
                    "You didn't fill out the Case Number field. Are you sure you want to create a message without an associated case?",
                yesAction: () => doSave(onSuccess),
                noAction: () => {}
            });
        } else {
            doSave(onSuccess);
        }
    }

    function saveAndClose(ev) {
        save(ev, () => {
            history.push("/inbox/messages");
        });
    }

    const errmsgEl = errMsg ? <div className="ui visible error message">{errMsg}</div> : null;
    const possiblePriorities = [Constants.MESSAGE_PRIO_NORMAL, Constants.MESSAGE_PRIO_URGENT].map((p) => ({
        value: p,
        label: Constants.MESSAGE_PRIO_LABELS[p],
    }));
    const possibleAssignees = users
        .map((u) => ({ value: `user:${u.user_id}`, label: u.name }))
        .concat(teams.map((t) => ({ value: `team:${t.team_id}`, label: t.name })));
    const prioritySelect = (
        <Dropdown
            options={possiblePriorities}
            placeholder="Select a priority"
            onChange={(option) => setPriority(option)}
            value={priority}
        />
    );
    const assigneeSelect = (
        <Dropdown
            options={possibleAssignees}
            placeholder="Assign to a person or a team"
            onChange={(option) => setAssignee(option)}
            value={assignee}
        />
    );
    const caseNumberSearchbox = (
        <SearchBox
            allPossibleValues={allCaseNumbers}
            onCommit={(caseNumber) => setCaseNumber(caseNumber)}
            initialValue=""
            placeholder="Enter case number"
        />
    );
    const clientDataDisclosureIndicator = loadingClientData ? <div className="ui mini active inline loader" /> : null;
    const infoMessage = currentUser ? null : (
        <div className="ui visible warning icon message">
            <i class="icon info circle"></i>
            All messages taken with this form should be sent to Case Management, unless the message is for a specific
            person, in which case it should be assigned to that person, using the "Who is this call for?" field.
        </div>
    );

    return (
        <div style={{ height: "100%", overflowY: "auto", paddingRight: 10, paddingBottom: 10 }}>
            <h1 className="ui header">Take A Message</h1>
            {infoMessage}
            <div className="ui form segment" style={{ background: "#fafafa" }}>
                <div className="field six wide">
                    <label htmlFor="casenumber">Case Number</label>
                    {caseNumberSearchbox}
                </div>
                <div className="field six wide">
                    <label htmlFor="name">
                        Name of Caller <RequiredFieldIndicator /> {clientDataDisclosureIndicator}
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={nameOfCaller}
                        placeholder="Required"
                        onChange={(ev) => setNameOfCaller(ev.target.value)}
                        required
                    />
                </div>
                <div className="field six wide">
                    <label htmlFor="callbacknumber">Call Back Number {clientDataDisclosureIndicator}</label>
                    <input
                        type="text"
                        name="callbacknumber"
                        value={callbackNumber}
                        placeholder="Optional"
                        onChange={(ev) => setCallbackNumber(ev.target.value)}
                    />
                </div>
                <div className="field">
                    <label htmlFor="subject">
                        Subject <RequiredFieldIndicator />
                    </label>
                    <input
                        type="text"
                        name="subject"
                        value={subject}
                        placeholder="Required"
                        onChange={(ev) => setSubject(ev.target.value)}
                        required
                    />
                </div>
                <div className="field">
                    <label htmlFor="description">Description</label>
                    <textarea
                        name="description"
                        placeholder="Optional"
                        rows="8"
                        value={description}
                        onChange={(ev) => setDescription(ev.target.value)}
                    ></textarea>
                </div>
                <div className="field six wide">
                    <label htmlFor="priority">Priority</label>
                    {prioritySelect}
                </div>
                <div className="field six wide">
                    <label htmlFor="assignee">
                        Who Is This Call For? <RequiredFieldIndicator />
                    </label>
                    {assigneeSelect}
                </div>

                <button
                    className={classNames("ui primary button", { loading: saving, disabled: !valid })}
                    onClick={save}
                >
                    Save and Add
                </button>
                {currentUser && (
                    <button
                        className={classNames("ui primary button", { loading: saving, disabled: !valid })}
                        onClick={saveAndClose}
                    >
                        Save and Close
                    </button>
                )}
                {errmsgEl}
            </div>
            {successAlert}
            {confirmationModal}
        </div>
    );
}

export default CallFormPage;
