import React from 'react';
import PlaceholderAvatar from '../../common/placeholderavatar.js';

const Avatar = ({ url }) => {
    return (
        <img
            src={url}
            alt="Avatar"
            style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            }}
        />
    );
};

const ConversationAvatar = ({ conversation }) => {
    const firstOtherParticipant = conversation.participantsOtherThanSelf[0];

    return (
        <div
            style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                overflow: 'hidden',
            }}
        >
            {firstOtherParticipant.avatarUrl ? (
                <Avatar url={firstOtherParticipant.avatarUrl} />
            ) : (
                <PlaceholderAvatar name={firstOtherParticipant && firstOtherParticipant.name} />
            )}
        </div>
    );
};

export default ConversationAvatar;
