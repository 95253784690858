import React, { useState, useEffect } from 'react';
var classNames = require('classnames');

function SearchBox({onCommit, allPossibleValues, initialValue, placeholder, large}) {
    const [completions, setCompletions] = useState([]);
    const [searchValue, setSearchValue] = useState(initialValue || "");

    useEffect(() => {
        setSearchValue(initialValue);
    }, [initialValue]);

    function onCompletionItemClicked(val) {
        setCompletions([]);
        setSearchValue(val);
        onCommit(val);
    }

    function onSearchValueChanged(ev) {
        const val = ev.target.value;
        const completions = val.length === 0 ? [] : allPossibleValues.filter((item) => item.case_number.toLowerCase().startsWith(val.toLowerCase()));
        setSearchValue(val);
        setCompletions(completions);
    }

    function handleKeyPress(ev) {
        if (ev.key === 'Enter') {
            if (searchValue === "") {
                onCommit("");
            }
            if (allPossibleValues.length === 0) {
                onCommit(searchValue);
            }
        }
    }

    function handleBlur() {
        if (allPossibleValues.length === 0) {
            onCommit(searchValue);
        }
    }

    let completionsMenu = null;
    if (completions.length > 0) {
        const completionEls = completions.map((val) => 
            <div className="item" data-value={val.case_number} key={val.case_number} onClick={() => onCompletionItemClicked(val.case_number)}>
                <div style={{marginBottom: '0.25em'}}>{val.case_number}</div>
                <div style={{color: '#999', fontSize: '0.85em'}}>{val.name}</div>
            </div>
        );
        completionsMenu = (
            <div className="ui vertical menu" style={{maxHeight: "15rem", overflowY: "scroll", position: "absolute", top: "22px", zIndex: 10}}>
                {completionEls}
            </div>
        );
    }

    const icon = (searchValue && searchValue.length > 0 && completions.length === 0) ?
        <i className="close link icon" onClick={() => {onCommit(null); setSearchValue("")}}/> :
        <i className="search icon"/>;

    return (
        <div style={{position: "relative"}}>
            <div className="ui icon input">
                {icon}
                <input className={classNames({"w-88": large})} type="text" placeholder={placeholder || "Filter on case number..."} value={searchValue} onChange={onSearchValueChanged} onKeyPress={handleKeyPress} onBlur={handleBlur}/>
            </div>
            {completionsMenu}
        </div>
    );
}

export default SearchBox;
