import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import PushNotificationPreview from '../pushnotificationpreview.js';
import EDDCredentialsMessage from '../eddcredentialsmessage.js';
import useMatterInfo from '../../common/useMatterInfo.js';
import withLoader from '../../common/withLoader.js';
var classNames = require('classnames');

function VerifyAppeal({caseNumber, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [matterInfo, loading, errMsg] = useMatterInfo(caseNumber);
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        appealVerified: null,
        noteSubject: "",
        noteDetail: ""
    });
    
    const notifTitle = formState.appealVerified ? "The EDD Received the Appeal" : "Still Waiting on Appeal to Be Processed";
    const notifDetail = formState.appealVerified ? 
        "We verified with the EDD that they received the appeal in your matter. At this point, the EDD will send the appeal to the Unemployment Insurance Appeals Board to issue a hearing date. It usually takes a couple of weeks to get the hearing date." :
        "An appeal was filed 30 days ago and we still could not verify the EDD processed it. We will wait 15 more days for EDD to process the appeal. If we still cannot verify it, we will initiate an Obstructed Case Procedure. This will allow us to push the EDD to act and minimize any delay.";

    const saveForm = useCallback(async (matterID) => {
        return await API.saveVerifyAppeal(formState.appealVerified, notifTitle, notifDetail, formState.noteSubject, formState.noteDetail, matterID);
    }, [formState.appealVerified, notifTitle, notifDetail, formState.noteSubject, formState.noteDetail]);

    

    return withLoader(loading, errMsg, () => (
        <TaskForm caseNumber={caseNumber} saveButtonText="Add The Above To This Matter And Notify The Client" disableSaveButton={formState.appealVerified === null} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <p>Please verify whether the appeal was processed.</p>
            <EDDCredentialsMessage matterInfo={matterInfo}/>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.appealVerified === true})} onClick={() => updateFormState({appealVerified: true})}>Appeal Processed</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.appealVerified === false})} onClick={() => updateFormState({appealVerified: false})}>Could Not Verify</div>
                </div>
            </div>

            {formState.appealVerified !== null && (
                <>
                    <div className="field">
                        <p>When you press the Add button below, the following push notification will be sent to the client:</p>
                        <PushNotificationPreview title={notifTitle} detail={notifDetail}/>
                    </div>
                    <div className="field">
                        In addition, you can add a note to this matter as well (not visible to the client):
                    </div>
                    <div className="field">
                        <label htmlFor="note_subject">Subject:</label>
                        <input type="text" name="note_subject" value={formState.noteSubject} onChange={ev => updateFormState({noteSubject: ev.target.value})} placeholder="Enter subject..."/>
                    </div>
                    <div className="field">
                        <label htmlFor="note_detail">Note:</label>
                        <textarea type="text" name="note_detail" value={formState.noteDetail} onChange={ev => updateFormState({noteDetail: ev.target.value})} rows="10" placeholder="Enter your notes..."></textarea>
                    </div>
                </>
            )}
        </TaskForm>
    ));
}

export default VerifyAppeal;
