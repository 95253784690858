import React, { useState, useEffect, useCallback } from "react";
import API from "../common/api.js";
import { formatPhoneNumberFromString } from "../common/formatting.js";
import * as Constants from "../common/webconstants.js";
import { get, sortBy } from "lodash";
import { Link } from "react-router-dom";

var classNames = require("classnames");
var moment = require("moment");
require("../common/moment-timezone-with-data.js");

function AccordionItem({ title, children, startExpanded }) {
    const [expanded, setExpanded] = useState(startExpanded);

    function toggle() {
        setExpanded(!expanded);
    }

    return (
        <>
            <div className="row p-0" onClick={toggle}>
                <div
                    className={classNames("sixteen wide column title", { active: expanded })}
                    style={{ fontWeight: "bold" }}
                >
                    <i className="dropdown icon" /> {title}
                </div>
            </div>
            <div className="row p-0">
                <div
                    className={classNames("sixteen wide column content", { active: expanded, hidden: !expanded })}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                    {children}
                </div>
            </div>
        </>
    );
}

function MatterQuickView({ caseNumber }) {
    const [loading, setLoading] = useState(true);
    const [caseInfo, setCaseInfo] = useState(null);
    const [importantEvents, setImportantEvents] = useState([]);
    const [history, setHistory] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [client, setClient] = useState(null);
    const [errMsg, setErrMsg] = useState(null);

    const loadCase = useCallback(async () => {
        setLoading(true);

        const data = await API.getCaseStatus(caseNumber);
        if (data) {
            const [documents, clientData, tasks] = await Promise.all([
                API.getInboxDocuments(data.matter_id),
                API.getContactInfo(data.info.client_id),
                API.getTasksForCase(caseNumber),
            ]);
            const pendingTasks = tasks.filter((task) =>
                [Constants.TASK_STATUS_TODO, Constants.TASK_STATUS_DOING, Constants.TASK_STATUS_BLOCKED].includes(
                    task.status
                )
            );
            setCaseInfo(data.info);
            setImportantEvents(data.important_events);
            setHistory(data.history);
            setDocuments(documents);
            setTasks(sortBy(pendingTasks, "status"));
            setClient(clientData);
            setLoading(false);
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, [caseNumber]);

    useEffect(() => {
        setCaseInfo(null);
        setImportantEvents(null);
        setHistory([]);
        setDocuments([]);
        setClient(null);

        loadCase();
    }, [caseNumber, loadCase]);

    const accordionStyle = {
        paddingLeft: 10,
        marginTop: 0,
        marginBottom: 0,
    };

    const iconBulletStyle = { width: "1.8em", display: "inline-block" };

    const events =
        importantEvents &&
        importantEvents.map((event, inx) => {
            return (
                <tr key={inx}>
                    <td>
                        <span style={iconBulletStyle}>
                            <i className="calendar alternate outline icon" />
                        </span>
                        {event.title}:
                    </td>
                    <td>{event.text}</td>
                </tr>
            );
        });

    const historyItems =
        history &&
        history.map((item, inx) => {
            return (
                <tr key={inx}>
                    <td>{item.timestamp}</td>
                    <td>{item.title}</td>
                </tr>
            );
        });

    const documentItems =
        documents &&
        documents.map((doc, inx) => {
            return (
                <p key={inx}>
                    <a href={`/viewdocument/${doc.document_id}`} target="_blank" rel="noopener noreferrer">
                        {doc.title}
                    </a>
                </p>
            );
        });

    const iconForTask = (task) => {
        return task.status === Constants.TASK_STATUS_TODO
            ? "circle outline"
            : task.status === Constants.TASK_STATUS_DOING
            ? "play circle outline"
            : task.status === Constants.TASK_STATUS_BLOCKED
            ? "minus circle"
            : task.status === Constants.TASK_STATUS_DONE
            ? "check circle outline"
            : "question circle outline";
    };

    const taskItems =
        tasks &&
        tasks.map((task, inx) => {
            return (
                <div className="item" key={task.task_id}>
                    <span style={iconBulletStyle}>
                        <i className={`${iconForTask(task)} icon`} />
                    </span>
                    <Link to={`/tasks/${task.task_id}`} key={inx}>
                        {task.title}
                    </Link>
                </div>
            );
        });

    const isIDMeVerified = get(caseInfo, "custom_fields.id_me_verified.value");
    const clientInfo = client && (
        <>
            <div>
                <b>
                    <span style={iconBulletStyle}>
                        <i className="fitted user icon" />
                    </span>
                    {client.name}
                </b>
            </div>
            <div>
                <span style={iconBulletStyle}>
                    <i className="phone icon" />
                </span>
                {client.phone ? formatPhoneNumberFromString(client.phone, "US") : ""}
            </div>
            <div>
                <span style={iconBulletStyle}>
                    <i className="mail icon" />
                </span>
                <a href={`mailto:${client.email}`}>{client.email}</a>
            </div>
            <div>
                <span style={iconBulletStyle}>
                    <i className={classNames("shield alternate icon", {red: !isIDMeVerified})} />
                </span>
                {isIDMeVerified ? "ID.me verified" : <span style={{color: "#912D2B"}}>Not ID.me verified yet</span>}
            </div>
        </>
    );

    const contingencyDetails = get(caseInfo, "has_contingency") && (
        <>
            <div className="item">
                <span style={iconBulletStyle} />
                Debit card:{" "}
                {get(
                    Constants.DEBIT_CARD_VERIFICATION_LABELS,
                    get(caseInfo, "custom_fields.debit_card_verification.value"),
                    "Unknown"
                )}
            </div>
            <div className="item">
                <span style={iconBulletStyle} />
                EDD credentials:{" "}
                {get(Constants.EDD_CREDENTIALS_LABELS, get(caseInfo, "custom_fields.edd_credentials.value"), "Unknown")}
            </div>
        </>
    );

    const caseInfoEl = caseInfo && (
        <div className="ui list">
            <div className="item">
                <b>
                    <span style={iconBulletStyle}>
                        <i className="suitcase icon" />
                    </span>
                    {caseInfo.practice_area}
                </b>
            </div>
            <div className="item">
                <span style={iconBulletStyle}>
                    <i className="folder open outline icon" />
                </span>
                {caseInfo.case_type}
            </div>
            <div className="item">
                <span style={iconBulletStyle}>
                    <i className="dolly icon" />
                </span>
                {caseInfo.status}
            </div>
            <div className="item">
                <span style={iconBulletStyle}>
                    <i className="money bill alternate outline icon" />
                </span>
                Contingency: {caseInfo.has_contingency ? "Yes" : "No"}
            </div>
            {contingencyDetails}
        </div>
    );

    const keyDates = caseInfo && (
        <div className="ui accordion grid" style={accordionStyle}>
            <AccordionItem title="Key Dates" startExpanded={true}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <span style={iconBulletStyle}>
                                    <i className="calendar alternate outline icon" />
                                </span>
                                Opened:
                            </td>
                            <td>{caseInfo.open_date && moment(caseInfo.open_date).format("MMM D, YYYY")}</td>
                        </tr>
                        <tr>
                            <td>
                                <span style={iconBulletStyle}>
                                    <i className="calendar alternate outline icon" />
                                </span>
                                Appeal filed:
                            </td>
                            <td>{caseInfo.appeal_date && moment(caseInfo.appeal_date).format("MMM D, YYYY")}</td>
                        </tr>
                        {events}
                        <tr>
                            <td>
                                <span style={iconBulletStyle}>
                                    <i className="calendar alternate outline icon" />
                                </span>
                                Closed:
                            </td>
                            <td>{caseInfo.close_date && moment(caseInfo.close_date).format("MMM D, YYYY")}</td>
                        </tr>
                    </tbody>
                </table>
            </AccordionItem>
        </div>
    );

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{ marginTop: 20 }}></div>
            </div>
        );
    } else if (errMsg) {
        return <div>{errMsg}</div>;
    } else {
        return (
            <div>
                <div className="ui vertical segment" style={{ paddingTop: 0, paddingBottom: "0.8em" }}>
                    <a
                        href={`https://app.clio.com/nc/#/matters/${caseInfo.matter_id}`}
                        className="ui basic primary small compact fluid icon button"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {caseNumber} in Clio <i className="fitted external alternate icon" />
                    </a>
                </div>

                <div className="ui vertical segment">{clientInfo}</div>

                <div className="ui vertical segment">{caseInfoEl}</div>

                <div className="ui vertical segment">{keyDates}</div>

                <div className="ui vertical segment">
                    <div className="ui accordion grid" style={accordionStyle}>
                        <AccordionItem title="History" startExpanded={false}>
                            <table>
                                <tbody>{historyItems}</tbody>
                            </table>
                        </AccordionItem>
                    </div>
                </div>

                <div className="ui vertical segment">
                    <div className="ui accordion grid" style={accordionStyle}>
                        <AccordionItem title="Documents" startExpanded={false}>
                            {documentItems}
                        </AccordionItem>
                    </div>
                </div>

                <div className="ui vertical segment">
                    <div className="ui accordion grid" style={accordionStyle}>
                        <AccordionItem title="Pending Tasks" startExpanded={false}>
                            <div className="ui list">{taskItems}</div>
                        </AccordionItem>
                    </div>
                </div>
            </div>
        );
    }
}

export default MatterQuickView;
