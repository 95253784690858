import React, { useMemo } from 'react';

const PlaceholderAvatar = ({ name }) => {
    const firstLetters = useMemo(() => {
        if (!name) return null;

        const pieces = name.split(' ')
            .map((piece) => piece.trim())
            .filter((piece) => piece.length > 0);

        if (pieces.length === 0) return null;

        if (pieces.length === 1 ) {
            return pieces[0].charAt(0);
        }

        return `${pieces[0].charAt(0)}${pieces[pieces.length - 1].charAt(0)}`;
    }, [name]);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#ddf',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
            }}
        >
            {firstLetters && (
                <span style={{ textTransform: 'capitalize', fontSize: 23, color: '#617aff' }}>
                    {firstLetters}
                </span>
            )}
        </div>
    )
};

export default PlaceholderAvatar;
