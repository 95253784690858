import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import API from './api.js';
import { CALL_PURPOSES, CALL_TYPES } from './webconstants.js';
import { VonageContext } from './vonageContext.js';
import NexmoClient from 'nexmo-client';
import useLeadCallLogs from '../common/useLeadCallLogs.js';
import usePubSub from '../common/usePubSub.js';
import Dialog from '../common/dialog.js';
import useSettings from '../common/useSettings.js';
import LiveDuration from '../common/liveduration.js';
import { PUBSUB_TOPIC_LEAD_CALL_LOGS_UPDATED } from '../common/webconstants.js';

const WindowButton = ({ icon, onClick }) => {
    return (
        <button
            onClick={onClick}
            style={{ cursor: 'pointer', border: 'none', borderRadius: 3, backgroundColor: 'rgb(247, 247, 247)' }}
        >
            <i
                className={`${icon} icon`}
                style={{ margin: 0 }}
            />
        </button>
    );
};

const VoicemailForm = ({ contactName, callLog, onAnswered }) => {
    const markCallAsVoicemail = async () => {
        onAnswered();
        await API.editCallLog(callLog.id, { wentToVoicemail: true });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <div>
                Did your call with {contactName} go to voicemail?
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
                <button
                    className="tiny ui green button"
                    style={{ margin: 0 }}
                    onClick={markCallAsVoicemail}
                >
                    Yes
                </button>
                <button
                    className="tiny ui grey button"
                    style={{ margin: 0 }}
                    onClick={onAnswered}
                >
                    No
                </button>
            </div>
        </div>
    );
};

const VonageContextProvider = ({ children }) => {
    const [vonageToken, setVonageToken] = useState(null);
    const [vonageApp, setVonageApp] = useState(null);
    const [callInProgress, setCallInProgress] = useState(false);
    const [callId, setCallId] = useState(null);
    const [contactName, setContactName] = useState(null);
    const [contactNumber, setContactNumber] = useState(null);
    const [contactUrl, setContactUrl] = useState(null);
    const [loadingHangup, setLoadingHangup] = useState(false);
    const [minimized, setMinimized] = useState(true);
    const [leadId, setLeadId] = useState(null);
    const [showVoicemailDialogOnOccasion, setShowVoicemailDialogOnOccasion] = useState(false);
    const [showVoicemailDialog, setShowVoicemailDialog] = useState(false);
    const [loadingMoveToQueue, setLoadingMoveToQueue] = useState(false);
    const [onHold, setOnHold] = useState(false);
    const hangUpRef = useRef(null);
    const { settings } = useSettings();
    const callLogsFilters = useMemo(() => ({ vonageCallLegIdPslAgent: callId }), [callId]);
    const {
        callLogs,
        reload: reloadCallLogs,
    } = useLeadCallLogs({
        leadId,
        pageNumber: 1,
        pageSize: 1,
        filters: callLogsFilters,
    });
    const callLog = useMemo(() => callLogs[0], [callLogs]);
    const enquireVmForCallsBelowSeconds = useMemo(() => {
        if (!settings) {
            return 60;
        }

        return settings.enquire_vm_for_calls_below_seconds;
    }, [settings]);

    useEffect(() => {
        if (!callLog) return;

        setCallInProgress(['attempted', 'started', 'ringing', 'answered'].includes(callLog.status));

        if (showVoicemailDialogOnOccasion && callLog.status === 'completed' && callLog.answeredAt && callLog.completedAt) {
            const callLengthInSeconds = callLog.completedAt.diff(callLog.answeredAt, 'seconds');
            if (enquireVmForCallsBelowSeconds && callLengthInSeconds <= enquireVmForCallsBelowSeconds) {
                setShowVoicemailDialog(true);
            }

            setShowVoicemailDialogOnOccasion(false);
        }
    }, [callLog])

    usePubSub({
        topic: PUBSUB_TOPIC_LEAD_CALL_LOGS_UPDATED,
        messageHandler: (_topic, { leadId: leadIdEventRefersTo }) => {
            if (leadId === leadIdEventRefersTo) {
                reloadCallLogs();
            }
        },
    });

    useEffect(() => {
        const requestAndSetVonageToken = async () => {
            const receivedToken = await API.getVonageToken();
            setVonageToken(receivedToken);
        }

        requestAndSetVonageToken();
    }, []);

    useEffect(() => {
        if (!vonageToken) return

        const buildNexmoClient = async () => {
            const vonageClient = new NexmoClient({ debug: false });
            const app = await vonageClient.createSession(vonageToken);

            app.on('member:call', (_member, call) => {
                hangUpRef.current = async () => {
                    setLoadingHangup(true);

                    try {
                        call.hangUp();
                    } catch (error) {
                        console.error('call.hangUp()', error);
                    }

                    setLoadingHangup(false);
                };
            });

            app.on('call:status:changed',(call) => {
                setCallInProgress([call.CALL_STATUS.STARTED, call.CALL_STATUS.RINGING, call.CALL_STATUS.ANSWERED].includes(call.status));
                setCallId(call.id);
            });

            setVonageApp(app);
        };

        buildNexmoClient();
    }, [vonageToken]);

    const putCallOnHold = async () => {
        await API.holdVonageCall(callId);
        setOnHold(true);
    };

    const hangUp = async () => {
        setShowVoicemailDialogOnOccasion(true);

        if (hangUpRef.current) {
            hangUpRef.current();
        };

        if (callId) {
            try {
                await API.hangupVonageCall(callId);
            } catch (error) {
                console.error('API.hangupVonageCall(callId)', error);
            }
        }
    };

    const returnCallOnHold = async () => {
        await API.returnVonageCall(callId);
        setOnHold(false);
    };

    const callLead = ({
        leadId,
        leadUrl,
        purpose,
        number,
        name,
    }) => {
        setLoadingMoveToQueue(false);
        setShowVoicemailDialogOnOccasion(false);
        setContactName(name);
        setContactUrl(leadUrl);
        setContactNumber(number);
        setLeadId(leadId);

        const params = {
            type: CALL_TYPES.CONTACT_LEAD,
            lead_id: leadId,
            purpose,
            phone_number: number,
        };
        vonageApp.callServer(JSON.stringify(params));
    };

    const joinLeadCall = ({ leadId, leadUrl, name, number, purpose }) => {
        setLoadingMoveToQueue(false);
        setShowVoicemailDialogOnOccasion(false);
        setContactName(name);
        setContactUrl(leadUrl);
        setContactNumber(number);
        setLeadId(leadId);

        const params = {
            type: CALL_TYPES.JOIN_WAITING_LEAD,
            lead_id: leadId,
            purpose,
        };

      vonageApp.callServer(JSON.stringify(params));
    };

    const moveToQueue = async () => {
        setLoadingMoveToQueue(true);

        const success = await API.moveCallToQueue({
            callLegId: callId,
            purpose: CALL_PURPOSES.POST_CONSULTATION,
        });

        if (!success) {
            setLoadingMoveToQueue(false);
        }
    };

    return (
        <VonageContext.Provider
            value={{
                initialized: !!vonageApp,
                callLead,
                joinLeadCall,
                callInProgress,
            }}
        >
            {children}
            {callInProgress && (
                <CallPanel
                    contactName={contactName}
                    contactNumber={contactNumber}
                    contactUrl={contactUrl}
                    moveToQueue={moveToQueue}
                    loadingHangup={loadingHangup}
                    loadingMoveToQueue={loadingMoveToQueue}
                    minimized={minimized}
                    setMinimized={setMinimized}
                    onHold={onHold}
                    putCallOnHold={putCallOnHold}
                    returnCallOnHold={returnCallOnHold}
                    callLog={callLog}
                    hangUp={hangUp}
                />
            )}
            {showVoicemailDialog && (
                <Dialog title="Voicemail">
                    <VoicemailForm
                        contactName={contactName}
                        callLog={callLog}
                        onAnswered={() => setShowVoicemailDialog(false)}
                    />
                </Dialog>
            )}
        </VonageContext.Provider>
    );
};

const HoldButton = ({ onHold, toggleHold, disabled, size }) => {
    return (
        <button
            className={`ui ${onHold ? 'orange' : 'blue'} labeled icon ${size} button`}
            onClick={toggleHold}
            disabled={disabled}
            style={{ margin: 0 }}
        >
            <i className={`${onHold ? 'play' : 'pause'} icon`} />
            {onHold ? 'Return to call' : 'Hold'}
        </button>
    );
};

const EndCallButtons = ({ moveToQueue, disabled, onHold, size, hangUp }) => {
    return (
        <div className={`ui ${size} buttons`}>
            <button
                className={`ui green labeled icon ${size} button`}
                onClick={moveToQueue}
                disabled={disabled || onHold}
            >
                <i className="forward icon"/>
                Move to queue
            </button>
            <div className="or" />
            <button
                className={`ui red right labeled icon ${size} button`}
                disabled={disabled || onHold}
                onClick={hangUp}
            >
                Hang up
                <i className="stop icon"/>
            </button>
        </div>
    );
};

const ContactName = ({ contactName, contactUrl }) => {
    const displayedContactName = contactName || 'Unknown';

    return (
        <div style={{ fontWeight: 'bold' }}>
            {contactUrl ? (
                <>
                    {contactUrl.charAt(0) === '/' ? (
                        <Link to={contactUrl}>{displayedContactName}</Link>
                    ) : (
                        <a
                            href={contactUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Go to Lead-page"
                        >
                            {displayedContactName}
                        </a>
                    )}
                </>
            ) : (
                <>{displayedContactName}</>
            )}
        </div>
    );
};

const CallStatus = ({ callLog }) => {
    if (!callLog) return null;
    if (!callLog.answeredAt) return '...';

    return (
        <LiveDuration startedAt={callLog.answeredAt} />
    );
};

const CallPanel = ({
    contactName,
    contactNumber,
    contactUrl,
    moveToQueue,
    loadingHangup,
    loadingMoveToQueue,
    minimized,
    setMinimized,
    onHold,
    putCallOnHold,
    returnCallOnHold,
    hangUp,
    callLog,
}) => {
    const disabled = loadingHangup || loadingMoveToQueue;
    const toggleMinimized = () => setMinimized(!minimized);
    const toggleHold = () => {
        if (onHold) {
            returnCallOnHold();
        } else {
            putCallOnHold();
        }
    };

    if (minimized) {
        return (
            <CallPanelMini
                contactName={contactName}
                contactNumber={contactNumber}
                contactUrl={contactUrl}
                moveToQueue={moveToQueue}
                toggleMinimized={toggleMinimized}
                disabled={disabled}
                onHold={onHold}
                toggleHold={toggleHold}
                hangUp={hangUp}
                callLog={callLog}
            />
        );
    } else {
        return (
            <CallPanelMaxi
                contactName={contactName}
                contactNumber={contactNumber}
                contactUrl={contactUrl}
                moveToQueue={moveToQueue}
                toggleMinimized={toggleMinimized}
                disabled={disabled}
                onHold={onHold}
                toggleHold={toggleHold}
                hangUp={hangUp}
                callLog={callLog}
            />
        );
    }
};

const CallPanelMaxi = ({
    contactName,
    contactNumber,
    contactUrl,
    moveToQueue,
    toggleMinimized,
    disabled,
    onHold,
    toggleHold,
    hangUp,
    callLog,
}) => {
    return (
        <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 450,
            zIndex: 99,
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid rgb(211, 211, 211)',
            borderBottom: 'none',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            boxShadow: 'rgb(151, 151, 151) 0px 0px 5px',
        }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <WindowButton
                    icon="window minimize"
                    onClick={toggleMinimized}
                />
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 12, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ fontSize: '200%' }}>
                        <i className="phone icon" style={{ color: '#262626' }} />
                    </div>
                    <div style={{ fontSize: '100%' }}>
                        <CallStatus callLog={callLog} />
                    </div>
                </div>
                <div style={{ fontSize: '200%' }}>
                    <ContactName contactName={contactName} contactUrl={contactUrl} />
                    {contactNumber && (
                        <div style={{ marginTop: 10, fontSize: '60%', color: '#2f2f2f', fontStyle: 'italic' }}>
                            {contactNumber}
                        </div>
                    )}
                </div>
            </div>
            <div style={{ marginTop: 20 }}>
                <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                    <HoldButton
                        onHold={onHold}
                        toggleHold={toggleHold}
                        disabled={disabled}
                        size="small"
                    />
                </div>
                <EndCallButtons
                    moveToQueue={moveToQueue}
                    disabled={disabled}
                    onHold={onHold}
                    hangUp={hangUp}
                />
            </div>
        </div>
    )
};

const CallPanelMini = ({
    contactName,
    contactNumber,
    contactUrl,
    moveToQueue,
    toggleMinimized,
    disabled,
    onHold,
    toggleHold,
    hangUp,
    callLog,
}) => {
    return (
        <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 1000,
            zIndex: 99,
            backgroundColor: 'white',
            padding: 2,
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            border: '1px solid rgb(211, 211, 211)',
            borderBottom: 'none',
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            boxShadow: 'rgba(151, 151, 151, 0.1) 0px 0px 2px',
        }}>
            <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', fontSize: 15, gap: 2 }}>
                <div>
                    <i className="phone icon" style={{ color: '#262626' }} />
                </div>
                <div style={{ marginLeft: 2, fontSize: '80%', color: 'rgb(32, 32, 32)' }}>
                    <CallStatus callLog={callLog} />
                </div>
                <div style={{ marginLeft: 8 }}>
                    <ContactName contactName={contactName} contactUrl={contactUrl} />
                </div>
                {contactNumber && (
                    <div style={{ marginLeft: 8, fontSize: '70%', color: '#2f2f2f', fontStyle: 'italic' }}>
                        {contactNumber}
                    </div>
                )}
                <div style={{ marginLeft: 8 }}>
                    <HoldButton
                        onHold={onHold}
                        toggleHold={toggleHold}
                        disabled={disabled}
                        size="mini"
                    />
                </div>
                <div style={{ marginLeft: 8 }}>
                    <EndCallButtons
                        moveToQueue={moveToQueue}
                        disabled={disabled}
                        onHold={onHold}
                        size="mini"
                        hangUp={hangUp}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <WindowButton
                    icon="window maximize"
                    onClick={toggleMinimized}
                />
            </div>
        </div>
    )
};

export default VonageContextProvider;
