import { useState, useEffect } from 'react';

function useEventSource({endpoint, messageHandler}) {
    const [eventSource, setEventSource] = useState(null);

    if (eventSource) {
        eventSource.onmessage = messageHandler;
    }

    useEffect(() => {
        if (typeof window.EventSource === "function") { // Not supported on IE11
            const es = new EventSource(endpoint);
            setEventSource(es);
            return function cleanUp() {
                es.close();
                setEventSource(null);
            }
        }
    }, [endpoint]);

    return [];
}

export default useEventSource;
