import React, { useState, useContext } from "react";
import * as Constants from "../common/webconstants.js";
import useLocalStorage from "../common/useLocalStorage.js";
import { CurrentUserContext } from "../common/currentUserContext.js";
import InboxArchivePageFilterBar from "../components/inboxarchivepagefilterbar.js";
import "./inboxpage.css";
import useInboxArchivePageMessages from "../common/useInboxArchivePageMessages.js";
import InboxArchivePageTabs from "../components/inboxarchivepagetabs.js";
import InboxArchivePageMessages from "../components/inboxarchivepagemessages.js";
import useScansArchive from "../common/useScansArchive.js";
import InboxArchivePageScans from "../components/inboxarchivepagescans.js";

function InboxArchivePage({ allCaseNumbers, allUsers, allTeams, initialExpandedMsgID, initialExpandedScanID, activeTab }) {
    const [assigneeFilter, setAssigneeFilter] = useLocalStorage("inboxarchivepage.assigneeFilter", null);
    const [messageTypeFilter, setMessageTypeFilter] = useLocalStorage("inboxarchivepage.messageTypeFilter", null);
    const [caseNumber, setCaseNumber] = useLocalStorage("inboxarchivepage.caseNumber", null);
    const [searchText, setSearchText] = useLocalStorage("inboxarchivepage.searchText", null);
    const [showFilterBar, setShowFilterBar] = useState(
        assigneeFilter !== null || messageTypeFilter !== null || caseNumber !== null
    );
    const [filterBarHeight, setFilterBarHeight] = useState(0);
    const currentUser = useContext(CurrentUserContext);
    const {
        loading: loadingMessages,
        errMsg: errMsgMessages,
        paging: pagingMessages,
        hasMore: hasMoreMessages,
        loadMore: loadMoreMessages,
        messages,
    } = useInboxArchivePageMessages({
        allUsers,
        allTeams,
        assigneeFilter,
        messageTypeFilter,
        caseNumber,
        searchText,
        currentUser,
    });
    const {
        loading: loadingScans,
        errMsg: errMsgScans,
        paging: pagingScans,
        hasMore: hasMoreScans,
        loadMore: loadMoreScans,
        scans
    } = useScansArchive({
        allUsers,
        allTeams,
        assigneeFilter,
        caseNumber,
        searchText,
        currentUser,
    });

    async function onCaseNumberSearchBoxCommit(caseNumber) {
        if (caseNumber === null || caseNumber.length === 0) {
            setCaseNumber(null);
        } else {
            setCaseNumber(caseNumber);
        }
    }

    async function onTextSearchBoxCommit(text) {
        if (!text) {
            setSearchText(null);
        } else {
            setSearchText(text);
        }
    }

    function clearFilters() {
        setAssigneeFilter(null);
        setMessageTypeFilter(null);
        setCaseNumber(null);
        setSearchText(null);
    }

    const possibleAssignees = [
        ...allUsers.map((u) => ({
            value: `user:${u.user_id}`,
            id: u.user_id,
            label: u.name,
            name: u.name,
            is_user: true,
            is_team: false,
        })),
        ...allTeams.map((t) => ({
            value: `team:${t.team_id}`,
            id: t.team_id,
            label: t.name,
            name: t.name,
            is_user: false,
            is_team: true,
        })),
        { value: "unassigned", id: -1, label: "Unassigned", is_user: false, is_team: false },
    ];

    const allMessageTypes = [
        Constants.MESSAGE_KIND_CALL,
        Constants.MESSAGE_KIND_VOICEMAIL,
        Constants.MESSAGE_KIND_FAX,
        Constants.MESSAGE_KIND_NOTICE,
    ].map((mt) => ({ value: mt, label: Constants.MESSAGE_KIND_LABELS[mt] }));

    const filterActive = assigneeFilter !== null || caseNumber !== null || messageTypeFilter !== null;

    return (
        <div style={{ height: "100%" }}>
            <InboxArchivePageTabs
                activeTab={activeTab}
                filterActive={filterActive}
                filterButtonActive={showFilterBar}
                onClickFilterButton={() => setShowFilterBar(!showFilterBar)}
                inboxLink={activeTab === Constants.INBOX_MESSAGES_TAB ? "/inbox/messages" : "/inbox/scans"}
            />

            <InboxArchivePageFilterBar
                allCaseNumbers={allCaseNumbers}
                allMessageTypes={allMessageTypes}
                possibleAssignees={possibleAssignees}
                onCaseNumberSearchBoxCommit={onCaseNumberSearchBoxCommit}
                caseNumber={caseNumber}
                assigneeFilter={assigneeFilter}
                setAssigneeFilter={setAssigneeFilter}
                messageTypeFilter={messageTypeFilter}
                setMessageTypeFilter={setMessageTypeFilter}
                onTextSearchBoxCommit={onTextSearchBoxCommit}
                searchText={searchText}
                clearFilters={clearFilters}
                visible={showFilterBar}
                onUpdateHeight={(h) => setFilterBarHeight(h)}
                options={{
                    caseNumberFilter: true,
                    messageTypeFilter: activeTab === Constants.INBOX_MESSAGES_TAB,
                    textSearchFilter: true,
                    assigneeFilter: true
                }}
            />

            {activeTab === Constants.INBOX_MESSAGES_TAB ? (
                <InboxArchivePageMessages
                    loading={loadingMessages}
                    paging={pagingMessages}
                    hasMore={hasMoreMessages}
                    loadMore={loadMoreMessages}
                    errMsg={errMsgMessages}
                    messages={messages}
                    allMessageTypes={allMessageTypes}
                    possibleAssignees={possibleAssignees}
                    initialExpandedMsgID={initialExpandedMsgID}
                    setAssigneeFilter={(v) => {
                        setAssigneeFilter(v);
                        setShowFilterBar(true);
                    }}
                    setMessageTypeFilter={(v) => {
                        setMessageTypeFilter(v);
                        setShowFilterBar(true);
                    }}
                    height={`calc(100% - 58px - ${filterBarHeight}px)`}
                    allCaseNumbers={allCaseNumbers}
                />
            ) : (
                <InboxArchivePageScans
                    loading={loadingScans}
                    paging={pagingScans}
                    hasMore={hasMoreScans}
                    loadMore={loadMoreScans}
                    errMsg={errMsgScans}
                    scans={scans}
                    possibleAssignees={possibleAssignees}
                    initialExpandedScanID={initialExpandedScanID}
                    setAssigneeFilter={(v) => {
                        setAssigneeFilter(v);
                        setShowFilterBar(true);
                    }}
                    height={`calc(100% - 58px - ${filterBarHeight}px)`}
                    allCaseNumbers={allCaseNumbers}
                />
            )}
        </div>
    );
}

export default InboxArchivePage;
