import { useState, useEffect, useCallback } from 'react';
import API from '../common/api.js';

const useDefaultTextMessageTemplates = () => {
    const [textMessageTemplates, setTextMessageTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [initialized, setInitialized] = useState(false);

    const loadData = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        let templatesIfSuccess = await API.getDefaultTextMessageTemplates();

        if (templatesIfSuccess) {
            setTextMessageTemplates(templatesIfSuccess);
            setInitialized(true);
        } else {
            setErrorMessage('Failed to load leads from server. Please retry.');
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        textMessageTemplates,
        loading,
        errorMessage,
        initialized,
        reload: loadData,
    };
};

export default useDefaultTextMessageTemplates;
