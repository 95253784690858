/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { CurrentUserContext } from '../common/currentUserContext.js';
import { VonageContext } from '../common/vonageContext.js';
import API from '../common/api.js';
import useComments from '../common/useComments.js';
import useLeadHistoricalEvents from '../common/useLeadHistoricalEvents.js';
import ModalWithBackdrop from '../components/modalwithbackdrop.js';
import ModalMainCard from '../components/modalmaincard.js';
import Card from '../components/card.js';
import Spinner from './spinner.js';
import { CallLeadOrJoinOngoingCallButton } from './leadcallbutton.js';
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';
import Center from './center.js';
import { formatDateTimeHumanDateTime } from '../common/formatting.js';
import LeadPriorityLabel from '../common/leadprioritylabel.js';
import useUpdateLeadForm from '../common/useUpdateLeadForm.js';
import useUpdateOpportunityForm from '../common/useUpdateOpportunityForm.js';
import useSettings from '../common/useSettings.js';
import useLead from '../common/useLead.js';
import useActiveLeadScheduledCall from '../common/useActiveLeadScheduledCall.js';
import HistoricalEvents from './leadmodal/historicalevents.js'
import ScheduleCallButton from './leadmodal/schedulecallbutton.js';
import ScheduledCallResolveBanner from './leadmodal/scheduledcallresolvebanner.js';
import DeferredBanner from './leadmodal/deferredbanner.js';
import AttachmentsSection from './leadmodal_attachmentssection.js';
import { sanitizeNumber } from '../common/phoneNumberUtils.js';
import Select from 'react-select';
import {
    PhoneInput,
    SelectField,
    DateTimeInput,
    DateInput,
    TextInput,
} from '../common/form';
var moment = require('moment');
var classNames = require('classnames');

const DiscardButton = ({ discard, fieldName }) => {
    const confirmDiscard = () => {
        const isConfirmed = window.confirm(
            `Do you want to discard your changes to "${fieldName}"? This action can not be undone.`
        );

        if (isConfirmed) discard();
    };

    return (
        <button
            onClick={confirmDiscard}
            style={{
                border: 'none',
                background: 'none',
                fontSize: '70%',
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'rgb(65, 79, 4)',
            }}
        >
            Discard
        </button>
    )
};

const Label = ({ children }) => {
    return (
        <span
            style={{
                color: 'rgb(64, 64, 64)',
                fontSize: '90%',
            }}
        >
            {children}
        </span>
    );
};

const Row = ({ label, children, discardChanges, actions }) => {
    return (
        <div className="flex" style={{ gap: 10, marginBottom: 5, flexWrap: 'wrap' }}>
            <div
                className="flex"
                style={{
                    width: 150,
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                }}
            >
                <Label>
                    {label}
                </Label>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                {children}
                <div>
                    {discardChanges && (
                        <DiscardButton discard={discardChanges} fieldName={label} />
                    )}
                </div>
                {actions && (
                    <div>
                        {actions}
                    </div>
                )}
            </div>
        </div>
    );
};

const RowRow = ({ label, children, discardChanges }) => {
    return (
        <div className="flex" style={{ marginBottom: 5 }}>
            <div style={{ marginRight: 10 }}>
                <Label>
                    {label}
                </Label>
            </div>
            <div>
                {children}
            </div>
            {discardChanges && (
                <DiscardButton discard={discardChanges} fieldName={label} />
            )}
        </div>
    );
};

const RowColumn = ({ label, children, discardChanges }) => {
    return (
        <div style={{ marginBottom: 5 }}>
            <div>
                <Label>
                    {label}
                </Label>
                {discardChanges && (
                    <DiscardButton discard={discardChanges} fieldName={label} />
                )}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

const leadCommentsApi = {
    getComments: API.getLeadComments,
    markMentionsRead: API.markLeadMentionsRead,
    createComment: API.createLeadComment,
    createMentionMessage: API.createLeadMentionMessage,
};

const CallButton = ({ lead, number, leadUrl, currentUser }) => {
    const isOnCall = useMemo(() => {
        if (!currentUser) return false;

        return currentUser.onCallStatus.status === Constants.ON_CALL_STATUS_ON_CALL;
    }, [currentUser]);

    return (
        <CallLeadOrJoinOngoingCallButton
            lead={lead}
            number={number}
            leadUrl={leadUrl}
            purpose={Constants.CALL_PURPOSES.CONSULTATION}
            disabled={!isOnCall}
            tooltip={isOnCall ? null : 'Please set yourself On Call to make calls'}
        />
    );
};

const LeadForm = ({
    lead,
    loadingLeadErrorMessages,
    isPinned,
    togglePinLead,
    commentsSection,
    toggleCommentsButton,
    hearingEvents,
    attachments,
    loadingAttachments,
    onClose,
    reportLeadViewed,
    allUsers,
    currentUser,
    historicalEvents,
    loadingLead,
    leadUrl,
    opportunityModelDescription,
    activeLeadScheduledCall,

    contingency,
    setContingency,
    contingencyOptions,
    contingencyChanged,
    resetContingency,
    phoneNumber,
    setPhoneNumber,
    phoneNumberChanged,
    submitLead,
    submittingLead,
    submittingLeadErrorMessage,
    mobileNumber,
    setMobileNumber,
    mobileNumberChanged,
    benefitsType,
    setBenefitsType,
    benefitsTypeChanged,
    benefitsTypeOptions,
    caseType,
    setCaseType,
    caseTypeChanged,
    caseTypeOptions,
    caseStatus,
    setCaseStatus,
    caseStatusChanged,
    caseStatusOptions,
    receivedEddDeterminationDate,
    setReceivedEddDeterminationDate,
    receivedEddDeterminationDateChanged,
    hearingDateTime,
    setHearingDateTime,
    hearingDateTimeChanged,
    consultNote,
    setConsultNote,
    consultNoteChanged,
    consultRecommendation,
    setConsultRecommendation,
    consultRecommendationOptions,
    consultRecommendationChanged,
    resetConsultRecommendation,
    resetPhoneNumber,
    resetMobileNumber,
    resetBenefitsType,
    resetCaseType,
    resetCaseStatus,
    resetHearingDateTime,
    resetReceivedEddDeterminationDate,
    resetConsultNote,
    localDocuments,
    addLocalDocument,
    removeLocalDocument,
    updateAssignee,
    updatingAssignee,
    updateDeferredUntil,
    updatingDeferredUntil,
}) => {
    const [showAttachmentsSection, setShowAttachmentsSection] = useState(false);
    const inputTheme = (valueIsEdited) => valueIsEdited ? 'inline-warn' : 'inline';
    const [assigneeUser, setAssigneeUser] = useState(null);
    const { callInProgress } = useContext(VonageContext);
    const [scheduleCallBackRequestSent, setScheduleCallBackRequestSent] = useState(false);
    const { settings } = useSettings();
    const [showScheduleCallBackDialog, setShowScheduleCallBackDialog] = useState(false);
    const [submittingScheduleCall, setSubmittingScheduleCall] = useState(false);
    const createLeadScheduledCall = async ({ scheduledForFromDateTime, scheduledForToDateTime }) => {
        setSubmittingScheduleCall(true);

        await API.createLeadScheduledCall(lead.id, {
            scheduledForFrom: scheduledForFromDateTime,
            scheduledForTo: scheduledForToDateTime,
        });

        setSubmittingScheduleCall(false);
        setShowScheduleCallBackDialog(false);
    };

    const requestScheduleCallBack = async () => {
        setScheduleCallBackRequestSent(true);

        const confirmedNumber = prompt(
            'Do you want to send an SMS, asking the client to schedule a call-back, to the following number',
            sanitizeNumber(mobileNumber)
        );

        const success = await API.createLeadScheduleCallRequest(lead.id, { number: confirmedNumber });

        setScheduleCallBackRequestSent(success);
    };

    const readOnly = useMemo(
        () => loadingLead || submittingLead,
        [loadingLead, submittingLead]
    )

    const numberOfAttachments = useMemo(() => {
        let numberOfAttachments = localDocuments ? localDocuments.length : 0;

        if (attachments) numberOfAttachments += attachments.length;

        return numberOfAttachments;
    }, [localDocuments, attachments]);

    let attachmentCounter = null;
    if (loadingAttachments) {
        attachmentCounter = "Loading...";
    } else {
        if (numberOfAttachments === 0) {
            attachmentCounter = <><i className="paperclip icon"/> Add Attachment</>;
        } else if (numberOfAttachments === 1) {
            attachmentCounter = <><i className="paperclip icon"/> 1 Attachment</>
        } else {
            attachmentCounter = <><i className="paperclip icon"/> {numberOfAttachments} Attachments</>
        }
    }

    const confirmSubmitLead = () => {
        const result = window.confirm('Are you sure you want to submit this data to Salesforce? This action can not be undone.');

        if (result) submitLead();
    }

    const errorMessages = useMemo(() => {
        const submitErrorMessages = submittingLeadErrorMessage ? [submittingLeadErrorMessage] : [];
        return [...loadingLeadErrorMessages, ...submitErrorMessages]
    }, [loadingLeadErrorMessages, submittingLeadErrorMessage])

    const assigneeOptions = useMemo(() => {
        return allUsers
            .filter((user) => user.isAttorney)
            .map((user) => ({ value: user.user_id, label: user.name }));
    }, [allUsers]);
    const selectedAssignee = useMemo(() => {
        if (!lead.assigneeId) return null;

        return assigneeUser
            ? { id: assigneeUser.user_id, label: assigneeUser.name }
            : { id: lead.assigneeId, label: lead.assigneeId }
    }, [lead.assigneeId, assigneeUser]);
    const isDeferred = useMemo(() => {
        if (!lead.deferredUntil) return false;
        return moment().isBefore(lead.deferredUntil);
    }, [lead.deferredUntil]);

    useEffect(() => {
        if (numberOfAttachments > 0) setShowAttachmentsSection(true);
    }, [numberOfAttachments]);

    useEffect(() => {
        reportLeadViewed(lead);
    }, [lead, reportLeadViewed]);

    useEffect(() => {
        setAssigneeUser(
            allUsers.find((user) => user.user_id === lead.assigneeId)
        );
    }, [lead.assigneeId, allUsers]);

    return (
        <ModalMainCard
            title={`Lead: ${lead.name}`}
            header={
                <>
                    <div style={{ opacity: 0.7, flex: 1 }}>
                        <a
                            href={`https://pershing1234.lightning.force.com/lightning/r/Lead/${lead.salesforceLeadId}/view`}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            <i className="linkify icon" />
                            {lead.salesforceLeadId}
                        </a>
                    </div>
                    <div className="flex">
                        <LeadPriorityLabel lead={lead} />
                    </div>
                </>
            }
            footer={
                <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 20 }}>
                    <div style={{ flex: 1, display: 'flex', gap: 20, alignItems: 'center', flexWrap: 'wrap' }}>
                        <div
                            style={{
                                width: 300,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                            data-tooltip="Who is this task assigned to? Click to re-assign"
                            data-position="right center"
                        >
                            <i className="fitted user icon mr-4" style={{lineHeight: '1.1em'}}/>
                            <Select
                                options={assigneeOptions}
                                styles={{
                                    container: styles => ({...styles, zIndex: 100, color: 'black', flexGrow: 1}),
                                    control: styles => ({ ...styles, borderWidth: 0, ':hover': {cursor: 'pointer'} }),
                                    singleValue: styles => ({ ...styles, ':hover': {cursor: 'pointer', color: '#1e70bf'} }),
                                }}
                                value={selectedAssignee}
                                onChange={(assigneeOption) => updateAssignee(assigneeOption === null ? null : parseInt(assigneeOption.value))}
                                menuPlacement="top"
                                isDisabled={!updateAssignee || updatingAssignee || readOnly}
                                isClearable
                            />
                        </div>
                    </div>
                    {!activeLeadScheduledCall && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ScheduleCallButton
                                submittingScheduleCall={submittingScheduleCall}
                                showScheduleCallBackDialog={showScheduleCallBackDialog}
                                setShowScheduleCallBackDialog={setShowScheduleCallBackDialog}
                                createLeadScheduledCall={createLeadScheduledCall}
                            />
                        </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {callInProgress && (
                            <div
                                style={{
                                    fontStyle: 'italic',
                                    color: 'rgb(92, 92, 92)',
                                    fontSize: '95%',
                                    marginRight: 5,
                                }}
                            >
                                Please complete your call before updating any leads.
                            </div>
                        )}
                        <div>
                            <button
                                className={classNames('ui primary button', { loading: (readOnly && !lead.isConverted) })}
                                disabled={!submitLead || readOnly || callInProgress}
                                onClick={confirmSubmitLead}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            }
            onClickClose={onClose}
        >
            <div>
                {isDeferred && <DeferredBanner lead={lead} updateDeferredUntil={updateDeferredUntil} updatingDeferredUntil={updatingDeferredUntil} />}
                {activeLeadScheduledCall && <ScheduledCallResolveBanner lead={lead} activeLeadScheduledCall={activeLeadScheduledCall} />}
                <div className="flex" style={{ flexWrap: 'wrap' }}>
                    <div style={{ flex: 1, marginTop: (errorMessages && errorMessages.length > 0) ? 8 : '3em', marginBottom: '2em', paddingRight: 14, flexBasis: 420 }}>
                        <div>
                            {errorMessages && errorMessages.map((errorMessage) => (
                                <div
                                    key={errorMessage}
                                    style={{ background: 'rgb(255, 192, 192)', padding: 10, marginBottom: 8 }}
                                >
                                    {errorMessage}
                                </div>
                            ))}
                            <div className="flex" style={{ flexWrap: 'wrap' }}>
                                <div style={{ flex: 12, marginBottom: 30, flexBasis: 420, maxWidth: 500 }}>
                                    <div style={{ marginBottom: 20 }}>
                                        <Row label={Constants.LEAD_FIELDS_LABELS.status}>
                                            {lead.status}
                                        </Row>
                                        <Row label={Constants.LEAD_FIELDS_LABELS.reasonForDenial}>
                                            {lead.reasonForDenial}
                                        </Row>
                                    </div>
                                    <div style={{ marginBottom: 20 }}>
                                        <Row
                                            label={Constants.LEAD_FIELDS_LABELS.phoneNumber}
                                            discardChanges={phoneNumberChanged ? resetPhoneNumber : null}
                                            actions={
                                                <>
                                                    {phoneNumber && (
                                                        <div style={{ marginLeft: 3 }}>
                                                            <CallButton
                                                                lead={lead}
                                                                number={phoneNumber}
                                                                leadUrl={leadUrl}
                                                                currentUser={currentUser}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        >
                                            <PhoneInput
                                                value={phoneNumber}
                                                onChange={setPhoneNumber}
                                                theme={inputTheme(phoneNumberChanged)}
                                                readOnly={!setPhoneNumber || readOnly}
                                            />
                                        </Row>
                                        <Row
                                            label={Constants.LEAD_FIELDS_LABELS.mobileNumber}
                                            discardChanges={mobileNumberChanged ? resetMobileNumber : null}
                                            actions={
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 3, marginLeft: 3 }}>
                                                    {mobileNumber && (
                                                        <CallButton
                                                            lead={lead}
                                                            number={mobileNumber}
                                                            leadUrl={leadUrl}
                                                            currentUser={currentUser}
                                                        />
                                                    )}
                                                    {(mobileNumber && settings && settings.allow_lead_schedule_call_requests) && (
                                                        <button
                                                            className="mini ui blue button"
                                                            onClick={requestScheduleCallBack}
                                                            style={{ margin: 0, padding: 7 }}
                                                            disabled={scheduleCallBackRequestSent}
                                                        >
                                                            <i className="comment icon" style={{ margin: 0 }} />
                                                        </button>
                                                    )}
                                                </div>
                                            }
                                        >
                                            <PhoneInput
                                                value={mobileNumber}
                                                onChange={setMobileNumber}
                                                theme={inputTheme(mobileNumberChanged)}
                                                readOnly={!setMobileNumber || readOnly}
                                            />
                                        </Row>
                                    </div>
                                    <div>
                                        <Row label={Constants.LEAD_FIELDS_LABELS.benefitsType} discardChanges={benefitsTypeChanged ? resetBenefitsType : null}>
                                            <SelectField
                                                value={benefitsType}
                                                onChange={setBenefitsType}
                                                options={benefitsTypeOptions}
                                                theme={inputTheme(benefitsTypeChanged)}
                                                readOnly={!setBenefitsType || readOnly}
                                            />
                                        </Row>
                                        <Row label={Constants.LEAD_FIELDS_LABELS.caseType} discardChanges={caseTypeChanged ? resetCaseType : null}>
                                            <SelectField
                                                value={caseType}
                                                onChange={setCaseType}
                                                options={caseTypeOptions}
                                                theme={inputTheme(caseTypeChanged)}
                                                readOnly={!setCaseType || readOnly}
                                            />
                                        </Row>
                                        <Row label={Constants.LEAD_FIELDS_LABELS.caseStatus} discardChanges={caseStatusChanged ? resetCaseStatus : null}>
                                            <SelectField
                                                value={caseStatus}
                                                onChange={setCaseStatus}
                                                options={caseStatusOptions}
                                                theme={inputTheme(caseStatusChanged)}
                                                readOnly={!setCaseStatus || readOnly}
                                            />
                                        </Row>
                                        {hearingEvents.length > 0 ? (
                                            <Row label={Constants.LEAD_FIELDS_LABELS.hearingDateTime} discardChanges={hearingDateTimeChanged ? resetHearingDateTime : null}>
                                                <DateTimeInput
                                                    value={hearingDateTime}
                                                    onChange={setHearingDateTime}
                                                    theme={inputTheme(hearingDateTimeChanged)}
                                                    readOnly={!setHearingDateTime || readOnly}
                                                />
                                                <span style={{ fontSize: '80%', paddingBottom: 3, marginLeft: 3 }}>
                                                    {moment.tz.guess()}
                                                </span>
                                            </Row>
                                        ) : (
                                            <>
                                                {lead.determinationReceived === 'Yes' ? (
                                                    <>
                                                        <Row
                                                            label={Constants.LEAD_FIELDS_LABELS.receivedEddDeterminationDate}
                                                            discardChanges={receivedEddDeterminationDateChanged ? resetReceivedEddDeterminationDate : null}
                                                        >
                                                            <DateInput
                                                                value={receivedEddDeterminationDate}
                                                                onChange={setReceivedEddDeterminationDate}
                                                                theme={inputTheme(receivedEddDeterminationDateChanged)}
                                                                readOnly={!setReceivedEddDeterminationDate || readOnly}
                                                            />
                                                        </Row>
                                                        <Row label={Constants.LEAD_FIELDS_LABELS.appealDeadline}>
                                                            <DateInput
                                                                value={lead.appealDeadline || ''}
                                                                theme={inputTheme(false)}
                                                                readOnly
                                                            />
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <Row label="Notice of Determination Received">
                                                        No
                                                    </Row>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div style={{ flex: 10, paddingLeft: 14, flexBasis: 250 }}>
                                    <RowRow label={Constants.LEAD_FIELDS_LABELS.contingency} discardChanges={contingencyChanged ? resetContingency : null}>
                                        <SelectField
                                            value={contingency}
                                            onChange={setContingency}
                                            options={contingencyOptions}
                                            theme={inputTheme(contingencyChanged)}
                                            readOnly={!setContingency || readOnly}
                                        />
                                    </RowRow>
                                    <RowColumn label={Constants.LEAD_FIELDS_LABELS.consultNote} discardChanges={consultNoteChanged ? resetConsultNote : null}>
                                        <TextInput
                                            value={consultNote}
                                            onChange={setConsultNote}
                                            theme={inputTheme(consultNoteChanged)}
                                            lines={12}
                                            stretchWide
                                            readOnly={!setConsultNote || readOnly}
                                        />
                                    </RowColumn>
                                    <RowRow label={Constants.LEAD_FIELDS_LABELS.consultRecommendation} discardChanges={consultRecommendationChanged ? resetConsultRecommendation : null}>
                                        <SelectField
                                            value={consultRecommendation}
                                            onChange={setConsultRecommendation}
                                            options={consultRecommendationOptions}
                                            theme={inputTheme(consultRecommendationChanged)}
                                            readOnly={!setConsultRecommendation || readOnly}
                                        />
                                    </RowRow>
                                </div>
                            </div>
                            <div>
                                {showAttachmentsSection && (
                                    <AttachmentsSection
                                        attachments={attachments}
                                        loadingAttachments={loadingAttachments}
                                        localAttachments={localDocuments}
                                        addLocalAttachment={addLocalDocument}
                                        removeLocalAttachment={removeLocalDocument}
                                    />
                                )}
                            </div>
                            <div>
                                {commentsSection}
                            </div>
                        </div>
                    </div>
                    <div style={{paddingLeft: '1em', paddingRight: '1em', marginTop: '4em', width: 275, flexGrow: 0, flexShrink: 0}}>
                        <p style={{fontWeight: 'bold', color: '#5F6D83'}}>
                            SECTIONS
                        </p>
                        {toggleCommentsButton}
                        <button
                            className={classNames("ui fluid labeled icon toggle button", { active: showAttachmentsSection })}
                            style={{ marginBottom: '0.5em', textAlign: 'left' }}
                            onClick={() => setShowAttachmentsSection(!showAttachmentsSection)}
                            disabled={loadingAttachments}
                        >
                            {attachmentCounter}
                        </button>
                        <button
                            className={classNames("ui fluid labeled icon toggle button", { active: isPinned })}
                            style={{marginBottom: '0.5em', textAlign: 'left'}}
                            onClick={() => togglePinLead(lead)}
                        >
                            <i className="thumbtack icon"/>Pin Lead
                        </button>

                        <p style={{fontWeight: 'bold', color: '#5F6D83', marginTop: '2em'}}>
                            ABOUT LEAD
                        </p>
                        <div
                            style={{marginRight: '2em', marginBottom: '0.25em'}}
                            data-tooltip="Created at"
                            data-position="left center"
                        >
                            <i className="calendar plus outline icon"/> {lead.createdAt ? formatDateTimeHumanDateTime(lead.createdAt) : 'N/A'}
                        </div>
                        <div
                            style={{marginRight: '2em', marginBottom: '0.25em'}}
                            data-tooltip="Qualified at"
                            data-position="left center"
                        >
                            <i className="certificate icon"/> {lead.qualifiedAt ? formatDateTimeHumanDateTime(lead.qualifiedAt) : 'N/A'}
                        </div>
                        <div
                            style={{marginRight: '2em', marginBottom: '0.25em'}}
                            data-tooltip="Consultation fields updated at"
                            data-position="left center"
                        >
                            <i className="save icon"/> {lead.consultFieldsUpdatedAt ? formatDateTimeHumanDateTime(lead.consultFieldsUpdatedAt) : 'N/A'}
                        </div>

                        <p style={{fontWeight: 'bold', color: '#5F6D83', marginTop: '2em'}}>
                            HISTORY
                        </p>
                        <HistoricalEvents
                            historicalEvents={historicalEvents}
                            allUsers={allUsers}
                            opportunityModelDescription={opportunityModelDescription}
                        />
                    </div>
                </div>
            </div>
        </ModalMainCard>
    );
};

function OpportunityFormModal({
    lead,
    loadingLeadErrorMessages,
    isPinned,
    togglePinLead,
    leadModelDescription,
    opportunityModelDescription,
    commentsSection,
    toggleCommentsButton,
    hearingEvents,
    attachments,
    loadingAttachments,
    activeLeadScheduledCall,
    onClose,
    onUpdate,
    reportLeadViewed,
    allUsers,
    currentUser,
    historicalEvents,
    loadingLead,
    leadUrl,
}) {
    const {
        updateAssignee,
        updatingAssignee,
        updateDeferredUntil,
        updatingDeferredUntil,
    } = useUpdateLeadForm({
        lead,
        hearingEvents,
        leadModelDescription,
        onUpdate,
        currentUser,
    });

    const {
        contingency,
        setContingency,
        contingencyChanged,
        resetContingency,
        consultNote,
        setConsultNote,
        consultNoteChanged,
        resetConsultNote,
        consultRecommendation,
        setConsultRecommendation,
        consultRecommendationChanged,
        resetConsultRecommendation,
        benefitsType,
        setBenefitsType,
        benefitsTypeChanged,
        resetBenefitsType,
        caseType,
        setCaseType,
        caseTypeChanged,
        resetCaseType,
        caseStatus,
        setCaseStatus,
        caseStatusChanged,
        resetCaseStatus,
        receivedEddDeterminationDate,
        setReceivedEddDeterminationDate,
        receivedEddDeterminationDateChanged,
        resetReceivedEddDeterminationDate,

        contingencyOptions,
        benefitsTypeOptions,
        caseTypeOptions,
        caseStatusOptions,
        consultRecommendationOptions,

        submit: submitOpportunity,
        submitting: submittingOpportunity,
        errorMessage: submittingOpportunityErrorMessage,
    } = useUpdateOpportunityForm({
        lead,
        opportunityModelDescription,
        onUpdate,
    });

    return (
        <LeadForm
            lead={lead}
            loadingLeadErrorMessages={loadingLeadErrorMessages}
            isPinned={isPinned}
            togglePinLead={togglePinLead}
            commentsSection={commentsSection}
            toggleCommentsButton={toggleCommentsButton}
            hearingEvents={hearingEvents}
            attachments={attachments}
            loadingAttachments={loadingAttachments}
            onClose={onClose}
            reportLeadViewed={reportLeadViewed}
            allUsers={allUsers}
            currentUser={currentUser}
            historicalEvents={historicalEvents}
            loadingLead={loadingLead}
            leadUrl={leadUrl}
            opportunityModelDescription={opportunityModelDescription}
            activeLeadScheduledCall={activeLeadScheduledCall}

            contingencyOptions={contingencyOptions}
            benefitsTypeOptions={benefitsTypeOptions}
            caseTypeOptions={caseTypeOptions}
            caseStatusOptions={caseStatusOptions}
            consultRecommendationOptions={consultRecommendationOptions}

            phoneNumber={lead.phoneNumber || ''}
            mobileNumber={lead.mobileNumber || ''}

            contingency={contingency}
            setContingency={setContingency}
            contingencyChanged={contingencyChanged}
            resetContingency={resetContingency}
            consultNote={consultNote}
            setConsultNote={setConsultNote}
            consultNoteChanged={consultNoteChanged}
            resetConsultNote={resetConsultNote}
            consultRecommendation={consultRecommendation}
            setConsultRecommendation={setConsultRecommendation}
            consultRecommendationChanged={consultRecommendationChanged}
            resetConsultRecommendation={resetConsultRecommendation}
            benefitsType={benefitsType}
            setBenefitsType={setBenefitsType}
            benefitsTypeChanged={benefitsTypeChanged}
            resetBenefitsType={resetBenefitsType}
            caseType={caseType}
            setCaseType={setCaseType}
            caseTypeChanged={caseTypeChanged}
            resetCaseType={resetCaseType}
            caseStatus={caseStatus}
            setCaseStatus={setCaseStatus}
            caseStatusChanged={caseStatusChanged}
            resetCaseStatus={resetCaseStatus}
            receivedEddDeterminationDate={receivedEddDeterminationDate}
            setReceivedEddDeterminationDate={setReceivedEddDeterminationDate}
            receivedEddDeterminationDateChanged={receivedEddDeterminationDateChanged}
            resetReceivedEddDeterminationDate={resetReceivedEddDeterminationDate}

            submitLead={submitOpportunity}
            submittingLead={submittingOpportunity}
            submittingLeadErrorMessage={submittingOpportunityErrorMessage}

            updateAssignee={updateAssignee}
            updatingAssignee={updatingAssignee}
            updateDeferredUntil={updateDeferredUntil}
            updatingDeferredUntil={updatingDeferredUntil}
        />
    );
};

function LeadFormModal({
    lead,
    loadingLeadErrorMessages,
    isPinned,
    togglePinLead,
    leadModelDescription,
    opportunityModelDescription,
    commentsSection,
    toggleCommentsButton,
    hearingEvents,
    attachments,
    loadingAttachments,
    activeLeadScheduledCall,
    onClose,
    onUpdate,
    reportLeadViewed,
    allUsers,
    currentUser,
    historicalEvents,
    loadingLead,
    leadUrl,
}) {
    const {
        contingency,
        setContingency,
        contingencyOptions,
        contingencyChanged,
        resetContingency,
        phoneNumber,
        setPhoneNumber,
        phoneNumberChanged,
        submit: submitLead,
        submitting: submittingLead,
        errorMessage: submittingLeadErrorMessage,
        mobileNumber,
        setMobileNumber,
        mobileNumberChanged,
        benefitsType,
        setBenefitsType,
        benefitsTypeChanged,
        benefitsTypeOptions,
        caseType,
        setCaseType,
        caseTypeChanged,
        caseTypeOptions,
        caseStatus,
        setCaseStatus,
        caseStatusChanged,
        caseStatusOptions,
        receivedEddDeterminationDate,
        setReceivedEddDeterminationDate,
        receivedEddDeterminationDateChanged,
        hearingDateTime,
        setHearingDateTime,
        hearingDateTimeChanged,
        consultNote,
        setConsultNote,
        consultNoteChanged,
        consultRecommendation,
        setConsultRecommendation,
        consultRecommendationOptions,
        consultRecommendationChanged,
        resetConsultRecommendation,
        resetPhoneNumber,
        resetMobileNumber,
        resetBenefitsType,
        resetCaseType,
        resetCaseStatus,
        resetHearingDateTime,
        resetReceivedEddDeterminationDate,
        resetConsultNote,
        localDocuments,
        addLocalDocument,
        removeLocalDocument,
        updateAssignee,
        updatingAssignee,
        updateDeferredUntil,
        updatingDeferredUntil,
    } = useUpdateLeadForm({
        lead,
        hearingEvents,
        leadModelDescription,
        onUpdate,
        currentUser,
    });

    return (
        <LeadForm
            lead={lead}
            loadingLeadErrorMessages={loadingLeadErrorMessages}
            isPinned={isPinned}
            togglePinLead={togglePinLead}
            commentsSection={commentsSection}
            toggleCommentsButton={toggleCommentsButton}
            hearingEvents={hearingEvents}
            attachments={attachments}
            loadingAttachments={loadingAttachments}
            onClose={onClose}
            reportLeadViewed={reportLeadViewed}
            allUsers={allUsers}
            currentUser={currentUser}
            historicalEvents={historicalEvents}
            loadingLead={loadingLead}
            leadUrl={leadUrl}
            opportunityModelDescription={opportunityModelDescription}
            activeLeadScheduledCall={activeLeadScheduledCall}

            contingency={contingency}
            setContingency={setContingency}
            contingencyOptions={contingencyOptions}
            contingencyChanged={contingencyChanged}
            resetContingency={resetContingency}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumberChanged={phoneNumberChanged}
            submitLead={submitLead}
            submittingLead={submittingLead}
            submittingLeadErrorMessage={submittingLeadErrorMessage}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            mobileNumberChanged={mobileNumberChanged}
            benefitsType={benefitsType}
            setBenefitsType={setBenefitsType}
            benefitsTypeChanged={benefitsTypeChanged}
            benefitsTypeOptions={benefitsTypeOptions}
            caseType={caseType}
            setCaseType={setCaseType}
            caseTypeChanged={caseTypeChanged}
            caseTypeOptions={caseTypeOptions}
            caseStatus={caseStatus}
            setCaseStatus={setCaseStatus}
            caseStatusChanged={caseStatusChanged}
            caseStatusOptions={caseStatusOptions}
            receivedEddDeterminationDate={receivedEddDeterminationDate}
            setReceivedEddDeterminationDate={setReceivedEddDeterminationDate}
            receivedEddDeterminationDateChanged={receivedEddDeterminationDateChanged}
            hearingDateTime={hearingDateTime}
            setHearingDateTime={setHearingDateTime}
            hearingDateTimeChanged={hearingDateTimeChanged}
            consultNote={consultNote}
            setConsultNote={setConsultNote}
            consultNoteChanged={consultNoteChanged}
            consultRecommendation={consultRecommendation}
            setConsultRecommendation={setConsultRecommendation}
            consultRecommendationOptions={consultRecommendationOptions}
            consultRecommendationChanged={consultRecommendationChanged}
            resetConsultRecommendation={resetConsultRecommendation}
            resetPhoneNumber={resetPhoneNumber}
            resetMobileNumber={resetMobileNumber}
            resetBenefitsType={resetBenefitsType}
            resetCaseType={resetCaseType}
            resetCaseStatus={resetCaseStatus}
            resetHearingDateTime={resetHearingDateTime}
            resetReceivedEddDeterminationDate={resetReceivedEddDeterminationDate}
            resetConsultNote={resetConsultNote}
            localDocuments={localDocuments}
            addLocalDocument={addLocalDocument}
            removeLocalDocument={removeLocalDocument}
            updateAssignee={updateAssignee}
            updatingAssignee={updatingAssignee}
            updateDeferredUntil={updateDeferredUntil}
            updatingDeferredUntil={updatingDeferredUntil}
        />
    );
};

function LeadModalLoaded({
    lead,
    loadingLeadErrorMessages,
    isPinned,
    togglePinLead,
    leadModelDescription,
    opportunityModelDescription,
    commentsSection,
    toggleCommentsButton,
    hearingEvents,
    attachments,
    loadingAttachments,
    onClose,
    onUpdate,
    reportLeadViewed,
    allUsers,
    currentUser,
    historicalEvents,
    loadingLead,
    activeLeadScheduledCall,
    leadUrl,
}) {
    if (lead.isConverted) {
        return (
            <OpportunityFormModal
                lead={lead}
                loadingLeadErrorMessages={loadingLeadErrorMessages}
                isPinned={isPinned}
                togglePinLead={togglePinLead}
                leadModelDescription={leadModelDescription}
                opportunityModelDescription={opportunityModelDescription}
                commentsSection={commentsSection}
                toggleCommentsButton={toggleCommentsButton}
                hearingEvents={hearingEvents}
                activeLeadScheduledCall={activeLeadScheduledCall}
                attachments={attachments}
                loadingAttachments={loadingAttachments}
                onClose={onClose}
                onUpdate={onUpdate}
                reportLeadViewed={reportLeadViewed}
                allUsers={allUsers}
                currentUser={currentUser}
                historicalEvents={historicalEvents}
                loadingLead={loadingLead}
                leadUrl={leadUrl}
            />
        )
    }

    return (
        <LeadFormModal
            lead={lead}
            loadingLeadErrorMessages={loadingLeadErrorMessages}
            isPinned={isPinned}
            togglePinLead={togglePinLead}
            leadModelDescription={leadModelDescription}
            opportunityModelDescription={opportunityModelDescription}
            commentsSection={commentsSection}
            toggleCommentsButton={toggleCommentsButton}
            hearingEvents={hearingEvents}
            activeLeadScheduledCall={activeLeadScheduledCall}
            attachments={attachments}
            loadingAttachments={loadingAttachments}
            onClose={onClose}
            onUpdate={onUpdate}
            reportLeadViewed={reportLeadViewed}
            allUsers={allUsers}
            currentUser={currentUser}
            historicalEvents={historicalEvents}
            loadingLead={loadingLead}
            leadUrl={leadUrl}
        />
    );
};

function LeadModal({ id, onClose, isPinned, togglePinLead, allUsers, allTeams, reportLeadViewed, leadModelDescription, opportunityModelDescription, leadUrl }) {
    const possibleAssignees = [
        ...(allUsers.map(u => ({value: `user:${u.user_id}`, id: u.user_id, label: u.name, name: u.name, is_user: true, is_team: false}))),
        ...(allTeams.map(t => ({value: `team:${t.team_id}`, id: t.team_id, label: t.name, name: t.name, is_user: false, is_team: true}))),
        {value: "unassigned", id: -1, label: "Unassigned", is_user: false, is_team: false}
    ];

    const {
        lead,
        reload: reloadLead,
        errorMessages: loadingLeadErrorMessages,
        initialized: leadInitialized,
        loading: loadingLead,
    } = useLead({ leadId: id });
    const [hearingEvents, setHearingEvents] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [loadingAttachments, setLoadingAttachments] = useState(true);
    const currentUser = useContext(CurrentUserContext);
    const {
        commentsSection,
        toggleCommentsButton,
    } = useComments(id, lead, leadCommentsApi, possibleAssignees, false, currentUser);
    const {
        historicalEvents,
        initialized: historicalEventsInitialized,
        reload: reloadHistoricalEvents,
    } = useLeadHistoricalEvents({ leadId: id });
    const {
        activeLeadScheduledCall,
        reload: reloadActiveLeadScheduledCall,
        initialized: activeLeadScheduledCallInitialized,
    } = useActiveLeadScheduledCall({ leadId: id });

    const handleIncomingEvent = useCallback((topic, data) => {
        if (
            [
                Constants.PUBSUB_TOPIC_LEAD_CALL_LOGS_UPDATED,
                Constants.PUBSUB_TOPIC_LEAD_CONSULT_RECOMMENDATION_UPDATES_UPDATED,
                Constants.PUBSUB_TOPIC_LEAD_TEXT_MESSAGES_UPDATED,
                Constants.PUBSUB_TOPIC_LEAD_UPDATED,
                Constants.PUBSUB_TOPIC_LEAD_SCHEDULED_CALLS_UPDATED,
                Constants.PUBSUB_TOPIC_LEAD_SCHEDULE_CALL_BACK_REQUESTS_UPDATED,
            ].includes(topic)
        ) {
            if (data.leadId === id) {
                reloadHistoricalEvents();
            }
        }

        if (topic === Constants.PUBSUB_TOPIC_LEAD_SCHEDULED_CALLS_UPDATED) {
            if (data.leadId === id) {
                reloadActiveLeadScheduledCall();
            }
        }

        if ([Constants.PUBSUB_TOPIC_LEAD_UPDATED, Constants.PUBSUB_TOPIC_LEAD_WAITING_IN_CONVERSATION_UPDATED].includes(topic)) {
            if (data.leadId === id) {
                reloadLead();
            }
        }
    }, [id, reloadActiveLeadScheduledCall, reloadHistoricalEvents, reloadLead]);
    usePubSub({
        topic: Constants.PUBSUB_TOPIC_LEAD,
        messageHandler: handleIncomingEvent,
    });

    useEffect(() => {
        API.getLeadHearingEvents(id).then(hearingEvents => {
            if (!hearingEvents) return console.log('API.getLeadHearingEvents(id) did not return events');

            setHearingEvents(hearingEvents);
        });
    }, [id]);

    const loadAttachments = useCallback(async () => {
        setLoadingAttachments(true);

        const leadDocuments = await API.getLeadDocuments(id);

        if (leadDocuments) {
            setAttachments(leadDocuments);
        } else {
            console.log('API.getLeadDocuments(id) did not return succeed');
        }

        setLoadingAttachments(false);
    }, [id]);

    useEffect(() => {
        loadAttachments();
    }, [loadAttachments]);

    return (
        <ModalWithBackdrop onClickBackdrop={onClose}>
            {(leadInitialized && leadModelDescription && opportunityModelDescription && hearingEvents && historicalEventsInitialized && activeLeadScheduledCallInitialized) ? (
                <>
                    {lead ? (
                        <LeadModalLoaded
                            lead={lead}
                            isPinned={isPinned}
                            togglePinLead={togglePinLead}
                            leadModelDescription={leadModelDescription}
                            opportunityModelDescription={opportunityModelDescription}
                            commentsSection={commentsSection}
                            toggleCommentsButton={toggleCommentsButton}
                            hearingEvents={hearingEvents}
                            attachments={attachments}
                            loadingAttachments={loadingAttachments}
                            onClose={onClose}
                            reportLeadViewed={reportLeadViewed}
                            allUsers={allUsers}
                            currentUser={currentUser}
                            historicalEvents={historicalEvents}
                            loadingLeadErrorMessages={loadingLeadErrorMessages}
                            loadingLead={loadingLead}
                            leadUrl={leadUrl}
                            activeLeadScheduledCall={activeLeadScheduledCall}
                            onUpdate={({ leadUpdated, updatedEvent, uploadedDocuments }) => {
                                if (leadUpdated) {
                                    reloadLead();
                                }

                                if (updatedEvent) {
                                    const indexOfUpdatedEvent = hearingEvents.findIndex((apiEvent) => apiEvent.salesforceLeadId === updatedEvent.salesforceLeadId);

                                    if (indexOfUpdatedEvent === -1) {
                                        setHearingEvents([updatedEvent]);
                                    } else {
                                        const newEvents = [...hearingEvents];
                                        newEvents[indexOfUpdatedEvent] = updatedEvent;

                                        setHearingEvents(newEvents);
                                    }
                                }

                                if (uploadedDocuments) {
                                    loadAttachments();
                                }
                            }}
                        />
                    ) : (
                        <Card>
                            <Center>
                                <div style={{ padding: 20 }}>
                                    <h1>Failed to load Lead</h1>
                                    <ul>
                                        <li>Has this Lead been deleted?</li>
                                        <li>You can also try to reload the page in case this was only a temporary networking issue.</li>
                                    </ul>
                                </div>
                            </Center>
                        </Card>
                    )}
                </>
            ) : (
                <Card>
                    <Center>
                        <div style={{ margin: 40 }}>
                            <Spinner />
                        </div>
                    </Center>
                </Card>
            )}
        </ModalWithBackdrop>
    )
}

export default LeadModal;
