import React from 'react';
import copyToClipboard from 'copy-to-clipboard';


function CopyToClipboardIconButton({ value }) {
    return (
        <div
            className="mini ui compact basic icon button"
            style={{ verticalAlign: "middle" }}
            onClick={() => copyToClipboard(value)}
        >
            <i className="copy outline icon"></i>
        </div>
    );
}


export default CopyToClipboardIconButton;
