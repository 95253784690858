import React, { useState } from 'react';
import useMessagesSearch from '../../common/useMessagesSearch.js';
import LoadMoreButton from './loadmorebutton.js';
import Spinner from '../../components/spinner.js';
import Center from '../../components/center.js';
import ChatMessage from '../../common/chatmessage.js';
import ConversationTopic from '../../common/conversationtopic';

const MessageSearchMatch = ({ match, query, onClick }) => {
    return (
        <div
            style={{
                padding: 10,
                borderBottom: '1px solid rgb(227, 227, 227)',

            }}
        >
            <div
                onClick={onClick}
                style={{
                    cursor: 'pointer',
                }}
            >
                <div
                    style={{
                        backgroundColor: 'rgb(251, 251, 251)',
                        display: 'flex',
                        padding: 5,
                        gap: 10,
                        flexWrap: 'wrap',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 5,
                            flex: 1,
                            minWidth: 200,
                        }}
                    >
                        <div
                            style={{
                                color: '#313131',
                                fontSize: 11,
                                fontWeight: 'bold',
                            }}
                        >
                            {match.regardingCaseNumber}
                        </div>
                        {match.topic && (
                            <ConversationTopic topic={match.topic} />
                        )}
                    </div>
                    <div style={{ flex: 3, minWidth: 200 }}>
                        <ChatMessage
                            message={match}
                            showSender
                            highlight={query}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MessagesSearchPage = ({ redirectToConversation, conversationsFilters, messagesFullTextSearchValue }) => {
    const resultsBatchSize = 10;
    const [numberOfResults, setNumberOfResults] = useState(resultsBatchSize);
    const {
        matches,
        searchInProgress,
    } = useMessagesSearch({
        page: 1,
        pageSize: numberOfResults,
        conversationsFilters,
        query: messagesFullTextSearchValue,
    });
    const moreMatchesAvailable = matches.length === numberOfResults;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, marginTop: 10, overflowY: 'scroll', height: '100%' }}>
            {!searchInProgress ? (
                <>
                    {matches.length === 0 ? (
                        'Your search did not yield any matches'
                    ) : (
                        <>
                            {matches.map((match) => (
                                <MessageSearchMatch
                                    key={match.id}
                                    match={match}
                                    query={messagesFullTextSearchValue}
                                    onClick={() => redirectToConversation(match.conversationId)}
                                />
                            ))}
                        </>
                    )}
                    {moreMatchesAvailable && (
                        <LoadMoreButton
                            onClick={() => setNumberOfResults(numberOfResults + resultsBatchSize)}
                            disabled={searchInProgress}
                        />
                    )}
                </>
            ) : (
                <Center>
                    <Spinner />
                </Center>
            )}
        </div>
    );
};

export default MessagesSearchPage;
