/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useRef, useEffect } from "react";
import SearchBox from "../components/searchbox.js";
import * as Constants from "../common/webconstants.js";
import Select from "react-select";
import AnimateHeight from "react-animate-height";
import generateSelectOptions from "../common/generateSelectOptions.js";

function InboxPageFilterBar({
    allCaseNumbers,
    allMessageTypes,
    possibleAssignees,
    onCaseNumberSearchBoxCommit,
    caseNumber,
    assigneeFilter,
    setAssigneeFilter,
    messageTypeFilter,
    setMessageTypeFilter,
    onTextSearchBoxCommit,
    searchText,
    readStatusFilter,
    setReadStatusFilter,
    sortOrder,
    clearFilters,
    visible,
    onUpdateHeight,
    options,
}) {
    const ref = useRef(null);
    const showClearFilterButton =
        assigneeFilter !== null ||
        messageTypeFilter !== null ||
        caseNumber !== null ||
        searchText ||
        readStatusFilter !== Constants.READ_STATUS_ANY ||
        sortOrder !== Constants.SORT_NEWEST_TASK_FIRST;
    const clearFilterButton = showClearFilterButton && (
        <div>
            <button className="ui tertiary button" onClick={clearFilters}>
                Reset filters
            </button>
        </div>
    );

    const caseNumberSearchbox = (
        <SearchBox
            allPossibleValues={allCaseNumbers}
            onCommit={onCaseNumberSearchBoxCommit}
            initialValue={caseNumber || ""}
        />
    );
    const assigneeSelect = (
        <Select
            options={generateSelectOptions(possibleAssignees)}
            placeholder="Filter by assignee"
            className="min-w-64"
            value={assigneeFilter}
            onChange={(option) => {
                if (option && option.length === 0) option = null;
                setAssigneeFilter(option);
            }}
            isClearable={true}
            closeMenuOnSelect={false}
            isMulti
        />
    );

    const messageTypeSelect = (
        <Select
            options={allMessageTypes}
            placeholder="Filter by type"
            className="w-64"
            value={messageTypeFilter}
            onChange={(option) => setMessageTypeFilter(option)}
            isClearable={true}
        />
    );

    const textSearchbox = (
        <SearchBox
            allPossibleValues={[]}
            onCommit={onTextSearchBoxCommit}
            initialValue={searchText || ""}
            placeholder="Filter on text..."
            large
        />
    );

    const showUnreadOnly = readStatusFilter === Constants.READ_STATUS_UNREAD;
    const readStatusCheckbox = (
        <div
            className={`ui checkbox my-auto ${showUnreadOnly ? "checked" : ""}`}
            onClick={() =>
                setReadStatusFilter(showUnreadOnly ? Constants.READ_STATUS_ANY : Constants.READ_STATUS_UNREAD)
            }
        >
            <input type="checkbox" className="hidden" checked={showUnreadOnly} onChange={() => {}} />
            <label>Unread Only</label>
        </div>
    );

    useEffect(() => {
        if (ref.current && visible) {
            onUpdateHeight(ref.current.offsetHeight);
        }
    });

    return (
        <AnimateHeight
            duration={250}
            height={visible ? "auto" : 0}
            onAnimationEnd={({ newHeight }) => () => onUpdateHeight(newHeight)}
        >
            <div className="filter-bar" ref={ref}>
                {options.caseNumberFilter && caseNumberSearchbox}
                {options.messageTypeFilter && messageTypeSelect}
                {options.assigneeFilter && assigneeSelect}
                {options.textSearchFilter && textSearchbox}
                {options.readStatusFilter && readStatusCheckbox}
                {clearFilterButton}
            </div>
        </AnimateHeight>
    );
}

export default InboxPageFilterBar;
