import React, { useState, useEffect, useCallback, useContext } from 'react';
import API from '../common/api.js';
import { Link } from "react-router-dom";
import Assignee from '../components/assignee.js';
import CaseNumber from '../components/casenumber.js';
import TaskCard from '../components/taskcard.js';
import { useDynamicYesNoModal } from '../common/useModal.js';
import { useHistory } from "react-router-dom";
import { some, sortBy } from 'lodash';
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';
import useLocalStorage from '../common/useLocalStorage.js';
import {CurrentUserContext} from '../common/currentUserContext.js';
import TasksArchivePageFilterBar from '../components/tasksarchivepagefilterbar.js';
var classNames = require('classnames');
var moment = require('moment');
require("../common/moment-timezone-with-data.js");


function placeholderRow(msg) {
    return (
        <tr key={msg} className="dim">
            <td className="border-none" colSpan={4}>{msg}</td>
        </tr>
    );
}

function LoadMoreRow({paging, onClick}) {
    return (
        <tr>
            <td className="border-none" colSpan={4}><button className={classNames("ui compact fluid button", {loading: paging})} onClick={onClick}>Load more...</button></td>
        </tr>
    );
}

function augmentTasks(data, allUsers, allTeams) {
    return data.map(t => ({
        ...t,
        assignee: allUsers.find(u => u.user_id === t.assignee_id), 
        team: allTeams.find(team => team.team_id === t.team_id)
    }));
}


function TasksArchivePage({allCaseNumbers, allUsers, allTeams, initialExpandedTaskID}) {
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(false);
    const [caseNumber, setCaseNumber] = useLocalStorage('tasksarchivepage.caseNumber', null);
    const [searchText, setSearchText] = useLocalStorage('tasksarchivepage.searchText', null);
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [expandedTask, setExpandedTask] = useState(null);
    const [assigneeFilter, setAssigneeFilter] = useLocalStorage('tasksarchivepage.assigneeFilter', null);
    const [formTypeFilter, setFormTypeFilter] = useLocalStorage('tasksarchivepage.formType', null);
    const [errMsg, setErrMsg] = useState(null);
    const [showFilterBar, setShowFilterBar] = useState(assigneeFilter !== null || caseNumber !== null || formTypeFilter !== null);
    const currentUser = useContext(CurrentUserContext);
    const [openConfirmDeleteTaskModal, confirmDeleteTaskModal] = useDynamicYesNoModal();
    usePubSub({topic: Constants.PUBSUB_TOPIC_TASK, messageHandler: handleServerMessage});
    let history = useHistory();

    async function onCaseNumberSearchBoxCommit(caseNumber) {
        if (caseNumber === null || caseNumber.length === 0) {
            setCaseNumber(null);

        } else {
            setCaseNumber(caseNumber);
        }
    }

    async function onTextSearchBoxCommit(text) {
        if (!text) {
            setSearchText(null);

        } else {
            setSearchText(text);
        }
    }

    const loadData = useCallback(async () => {
        setLoading(true);
        setTasks([]);
        setErrMsg(null);
        
        let data = null;
        if (caseNumber && caseNumber.length > 0) {
            data = await API.getTasksArchiveForCase(caseNumber);
            setHasMore(false);
        } else {
            data = await API.getTasksArchive();
            setHasMore(true);
        }

        if (data) {
            setTasks(augmentTasks(data, allUsers, allTeams));
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }
        setLoading(false);
    }, [allUsers, allTeams, caseNumber]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const loadMore = useCallback(async () => {
        setPaging(true);
        setErrMsg(null);

        let data = null;
        data = await API.getTasksArchive(tasks.length);
        setHasMore(true);
        if (data) {
            if (data.length > 0) {
                setTasks(augmentTasks(tasks.concat(data), allUsers, allTeams));
            } else {
                setHasMore(false);
            }
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }

        setPaging(false);
    }, [allUsers, allTeams, tasks]);

    useEffect(() => {
        if (initialExpandedTaskID) {
            const taskToExpand = tasks.find(t => t.task_id === parseInt(initialExpandedTaskID));
            if (taskToExpand) {
                setExpandedTask(taskToExpand);
                window.scrollTo(0,0);
            }
        } else {
            setExpandedTask(null);
        }
    }, [tasks, initialExpandedTaskID]);

    useEffect(() => {
        let data = tasks;
        if (assigneeFilter) {
            const filter = Array.isArray(assigneeFilter) ? assigneeFilter : [assigneeFilter];
            if (filter.length > 0) {
                data = data.filter(t => {
                    return some(filter.map(f => {
                        if (f.is_user) {
                            return t.assignee_id === f.id;
                        } else if (f.is_team) {
                            return (t.team_id === f.id) && (t.assignee_id === null);
                        } else if (f.id === -1) {
                            return (t.team_id === null) && (t.assignee_id === null);
                        } else {
                            return true; // Should never happen
                        }
                    }));
                });
            }
        }
        if (caseNumber !== null && caseNumber.length > 0) {
            data = data.filter(t => t.case_number === caseNumber); // Not really needed anymore, but doesn't hurt
        }
        if (formTypeFilter) {
            const filter = Array.isArray(formTypeFilter) ? formTypeFilter : [formTypeFilter];
            if (filter.length > 0) {
                data = data.filter(t => {
                    return some(filter.map(f => {
                        if (parseInt(f.value) === -1) {
                            return t.form_type === null;
                        } else {
                            return parseInt(t.form_type) === parseInt(f.value);
                        }
                    }));
                });
            }
        }
        if (searchText !== null && searchText.length > 0) {
            data = data.filter(t => t.title.toLowerCase().includes(searchText.toLowerCase()));
        }
        setFilteredTasks(data);
    }, [tasks, allUsers, allTeams, caseNumber, assigneeFilter, formTypeFilter, searchText, currentUser.teamMemberOf, currentUser.userID]);

    function handleServerMessage(topic, data) {
        if (topic === Constants.PUBSUB_TOPIC_TASK_ADDED) {
            const newTasks = [...tasks, data];
            setTasks(augmentTasks(newTasks, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_TASK_UPDATED) {
            const newTasks = tasks.map(t => {
                if (t.task_id === data.task_id) {
                    return {...data};
                }
                return {...t};
            });
            setTasks(augmentTasks(newTasks, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_TASK_DELETED) {
            const newTasks = tasks.filter(t => t.task_id !== parseInt(data.task_id));
            setTasks(augmentTasks(newTasks, allUsers, allTeams));
        }
    }

    function deleteTask(task) {
        openConfirmDeleteTaskModal({
            title: 'Delete Task?',
            prompt: "Are you sure you want to delete this task? There is no undo!",
            yesAction: () => {
                // Close the task card
                history.push('/tasks');

                // Update state locally for snappy user experience
                const newTasks = tasks.filter(t => t.task_id !== task.task_id);
                setTasks(augmentTasks(newTasks, allUsers, allTeams));

                // Update on the server in the background
                API.deleteTask(task.task_id);
            },
            noAction: () => {}
        });
    }

    function clearFilters() {
        setAssigneeFilter(null);
        setCaseNumber(null);
        setFormTypeFilter(null);
        setSearchText(null);
    }

    const possibleAssignees = [
        ...(allUsers.map(u => ({value: `user:${u.user_id}`, id: u.user_id, label: u.name, name: u.name, is_user: true, is_team: false}))), 
        ...(allTeams.map(t => ({value: `team:${t.team_id}`, id: t.team_id, label: t.name, name: t.name, is_user: false, is_team: true}))),
        {value: "unassigned", id: -1, label: "Unassigned", is_user: false, is_team: false}
    ];

    function taskRow(task, inx, prefix) {
        const matter = (
            // eslint-disable-next-line
            <a href="#" onClick={() => {setCaseNumber(task.case_number); setShowFilterBar(true);}} className={`ui horizontal icon label`}>
                <CaseNumber caseNumber={task.case_number}/>
            </a>
        );
        // eslint-disable-next-line
        const assignee = <Assignee task={task} onClick={name => {setAssigneeFilter(possibleAssignees.find(a => a.label === name)); setShowFilterBar(true);}}/>;

        const commentsAnnotations = task.comment_count > 0 && (<><i className="comments icon"/>{task.comment_count} &nbsp;</>);
        const checklistAnnotations = task.checklistitems_count > 0 && (<><i className="check square outline icon"/>{task.completed_checklistitems_count}/{task.checklistitems_count} &nbsp;</>);
        const attachmentAnnotations = task.attachment_count > 0 && (<><i className="paperclip icon"/>{task.attachment_count} &nbsp;</>);
        let mentionAnnotations = null;
        if (task.read_mention_count < task.total_mention_count) {
            mentionAnnotations = <><i className="red bell icon"/><span className="ui red header" style={{fontWeight: 'normal', fontSize: '1em'}}>{task.total_mention_count}</span></>;
        } else if (task.total_mention_count > 0) {
            mentionAnnotations = <><i className="bell outline icon"/>{task.total_mention_count}</>;
        }
        const watchAnnotations = (task.is_watched_by_current_user > 0) && <i className="eye icon"/>;
        const annotations = (commentsAnnotations || checklistAnnotations || attachmentAnnotations || mentionAnnotations || watchAnnotations) && (
            <span style={{color: "#999", marginLeft: '2em'}}>
                {commentsAnnotations}
                {checklistAnnotations}
                {attachmentAnnotations}
                {mentionAnnotations}
                {watchAnnotations}
            </span>
        );
        return (
            <tr key={`${prefix}${inx}`}>
                <td className={classNames("whitespace-no-wrap", {'border-none': inx === 0})}>
                    {task.case_number && matter}
                </td>
                <td className={classNames("w-full", {'border-none': inx === 0})}><Link to={`/tasks/archive/${task.task_id}`}>{task.title}</Link> {annotations}</td>
                <td className={classNames("whitespace-no-wrap", {'border-none': inx === 0})}>
                    <div className={`ui ${Constants.TASK_PRIO_COLORS[task.priority]} horizontal label w-24`}>{Constants.TASK_PRIO_LABELS[task.priority]}</div>
                </td>
                {/* eslint-disable-next-line */}
                <td className={classNames("whitespace-no-wrap", {'border-none': inx === 0})}>{(task.assignee || task.team) && <a href="#" className="ui grey icon label">{assignee}</a>}</td>
            </tr>
        );
    }

    let doneRows = [];
    let currentMonth = null;
    for (let inx = 0; inx < filteredTasks.length; ++inx) {
        const task = filteredTasks[inx];
        const row = taskRow(task, inx, "done_");
        const month = moment(task.completed_at * 1000).format("MMMM, YYYY");
        if (month !== currentMonth) {
            doneRows.push(<tr key={inx}><td style={{paddingTop: '3em'}} colSpan={4}><h3>{month}</h3></td></tr>);
            currentMonth = month;
        }
        doneRows.push(row);
    }

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    let possibleFormTypes = Object.keys(Constants.FORM_TYPE_LABELS).map(formType => ({value: formType, label: Constants.FORM_TYPE_LABELS[formType]}));
    possibleFormTypes.push({value: -1, label: 'Manually Created Task'});
    possibleFormTypes = sortBy(possibleFormTypes, ['label']);

    const filterActive = assigneeFilter !== null || caseNumber !== null || formTypeFilter !== null;
    const filterButton = (
        <button className={classNames("ui basic icon button", {active: showFilterBar})} onClick={() => setShowFilterBar(!showFilterBar)}><i className={classNames("filter icon", {green: filterActive})}/></button>
    );

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else {
        return (
            <div style={{height: '100%'}}>
                {expandedTask && <div style={{position: 'fixed', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(50,50,50,.75)', zIndex: 1}} onClick={() => history.push('/tasks/archive')}/>}
                <div style={{position: 'relative', borderBottom: '1px solid #CCC', marginTop: 10}}>
                    <h1 className="ui header">Tasks Archive</h1>
                    <div style={{position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'row'}}>
                        <Link to="/tasks" className="ui basic icon button" style={{marginRight: 10}}><i className="inbox icon"/></Link>
                        {filterButton}
                    </div>
                </div>
                <TasksArchivePageFilterBar 
                    allCaseNumbers={allCaseNumbers} 
                    possibleAssignees={possibleAssignees} 
                    possibleFormTypes={possibleFormTypes}
                    onCaseNumberSearchBoxCommit={onCaseNumberSearchBoxCommit} 
                    caseNumber={caseNumber} 
                    assigneeFilter={assigneeFilter} 
                    setAssigneeFilter={setAssigneeFilter}
                    formTypeFilter={formTypeFilter}
                    setFormTypeFilter={setFormTypeFilter}
                    onTextSearchBoxCommit={onTextSearchBoxCommit}
                    searchText={searchText}
                    clearFilters={clearFilters} 
                    visible={showFilterBar}
                />
                {errmsgEl}

                {expandedTask && <TaskCard task={expandedTask} onCloseClicked={() => {history.push('/tasks/archive')}} possibleAssignees={possibleAssignees} deleteTask={deleteTask} readOnly/>}

                <div style={{height: 'calc(100% - 56px)', overflowY: 'auto', paddingRight: 10}}>
                    <div className="ui visible info message" style={{zIndex: -1, marginTop: 20}}>This page shows the 100 most recently completed tasks, in reverse chronological order. You can load the next batch of 100 tasks at the bottom of the page.<br/><br/>If you filter on case number, it shows <i>all</i> completed tasks for that case.</div>
                    
                    <table className="ui very basic table" style={{marginBottom: '4em'}}>
                        <tbody>
                            {doneRows.length > 0 ? doneRows : placeholderRow("No tasks completed yet...")}
                            {hasMore && <LoadMoreRow paging={paging} onClick={loadMore}/>}
                        </tbody>
                    </table>
                </div>

                {confirmDeleteTaskModal}
            </div>
        );
    }
}

export default TasksArchivePage;
