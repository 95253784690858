import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import { get } from 'lodash';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';


function VerifyDebitCard({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        flag1: null,
        flag2: null,
        notes1: "",
        notes2: ""
    });
    
    const flag1 = get(formState, "flag1.value", null);
    const flag2 = get(formState, "flag2.value", null);
    const saveForm = useCallback(async (matterID) => {
        return await API.saveVerifyDebitCard(flag1, formState.notes1, flag2, formState.notes2, username, matterID);
    }, [flag1, formState.notes1, flag2, formState.notes2, username]);

    const yesNoOptions = ["Yes", "No"];

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={formState.flag1 === null || formState.flag2 === null} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <div className="field">
                <p>Please verify the EDD Debit Card and indicate what happened below. Press 'Save and Submit' to complete the procedure.</p>
            </div>
            <div className="field six wide">
                <label htmlFor="flag1">Were you able to verify the card?</label>
                <Dropdown placeholder="Please select" options={yesNoOptions} onChange={option => updateFormState({flag1: option})} value={formState.flag1} required/>
            </div>
            <div className="field six wide">
                <label htmlFor="notes1">If not, describe what happened:</label>
                <input type="text" name="notes1" value={formState.notes1} onChange={ev => updateFormState({notes1: ev.target.value})} placeholder="Enter notes..."/>
            </div>
            <div className="field six wide">
                <label htmlFor="flag2">Were you able to verify the card has no DD?</label>
                <Dropdown placeholder="Please select" options={yesNoOptions} onChange={option => updateFormState({flag2: option})} value={formState.flag2} required/>
            </div>
            <div className="field six wide">
                <label htmlFor="notes2">If not, describe what happened:</label>
                <input type="text" name="notes2" value={formState.notes2} onChange={ev => updateFormState({notes2: ev.target.value})} placeholder="Enter notes..."/>
            </div>
        </TaskForm>
    );
}

export default VerifyDebitCard;
