import React, { useContext } from 'react';
import ConversationTopic from '../../common/conversationtopic';
import { CurrentUserContext } from '../../common/currentUserContext.js';

const teamBadgeBackgroundColor = ({ teamParticipant, currentUser }) => {
    if (!teamParticipant.assignedUserId) return 'rgb(255, 201, 184)';
    if (currentUser.userID === teamParticipant.assignedUserId) return 'rgb(121, 255, 110)';

    return 'rgb(227, 227, 227)';
};

const ConversationTitle = ({ conversation }) => {
    const currentUser = useContext(CurrentUserContext);
    const firstOtherParticipant = conversation.participantsOtherThanSelf[0];
    const primaryLines = [];

    if (firstOtherParticipant.name) {
        primaryLines.push(firstOtherParticipant.name);
    }

    if (firstOtherParticipant.case_number) {
        primaryLines.push(firstOtherParticipant.case_number);
    }

    if (primaryLines.length === 0 && conversation.regardingMatterId) {
        primaryLines.push(`Matter ${conversation.regardingMatterId}`);
    }

    if (primaryLines.length === 0) {
        primaryLines.push(`Conversation ${conversation.id}`);
    }

    return (
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
            <div>
                {conversation.participantSelf.isTeam && (
                    <div
                        style={{
                            backgroundColor: teamBadgeBackgroundColor({
                                teamParticipant: conversation.participantSelf,
                                currentUser,
                            }),
                            borderRadius: 3,
                            padding: '1px 5px',
                            fontSize: 12,
                            color: '#1e1e1e',
                            marginBottom: 2,
                        }}
                    >
                        <i className="users icon" />
                        {conversation.participantSelf.assignedUserId
                            ? `${conversation.participantSelf.name}: ${conversation.participantSelf.assignedUserName}`
                            : conversation.participantSelf.name}
                    </div>
                )}
                <div style={{ fontWeight: 'bold' }}>
                    {primaryLines[0]}
                </div>
                {primaryLines.slice(1).map((text) => (
                    <div key={text}>
                        {text}
                    </div>
                ))}
            </div>
            <div>
                {conversation.topic && (
                    <ConversationTopic topic={conversation.topic} />
                )}
            </div>
        </div>
    );
};

export default ConversationTitle;
