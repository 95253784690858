/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../../common/webconstants.js';
import Spinner from '../spinner.js';
import Center from '../center.js';
import LeadModal from '../leadmodal.js';
import { JoinLeadCallButton } from '../leadcallbutton.js';
import LeadsPageLayout from "../leadspagelayout.js";
import useLocalStorage from '../../common/useLocalStorage.js';
import Table from '../../common/table.js';
import { useHistory } from 'react-router-dom';
import { useLeadsWithLoadMoreFunctionality, useLeads } from '../../common/useLeads.js';
import { formatDateTimeHumanDateTime, formatDateTimeRange } from '../../common/formatting.js';
import LeadPriorityLabel from '../../common/leadprioritylabel.js';
import usePubSub from '../../common/usePubSub.js';
import IconDropdown, { DROPDOWN_POSITION_LEFT } from '../icondropdown.js';
import FilterButton from './filterbutton.js';
import FilterBar from './filterbar.js';
var moment = require('moment');
var classNames = require('classnames');

const BATCH_SIZE = 20;

const LeadRow = ({ lead, leadIsPinned, togglePinLead, leadUrl }) => {
    const isPinned = leadIsPinned(lead.id);
    const isDeferred = lead.deferredUntil && moment().isBefore(lead.deferredUntil);
    const hasScheduledCallComingUp = lead.nextScheduledCallScheduledForFrom && lead.nextScheduledCallScheduledForTo;
    const isWithinScheduledCallWindow = hasScheduledCallComingUp && moment().isAfter(lead.nextScheduledCallScheduledForFrom) && moment().isBefore(lead.nextScheduledCallScheduledForTo);
    const backgroundColor = isWithinScheduledCallWindow ? 'rgb(230, 255, 216)' : (isDeferred ? '#FFFF99' : null);

    return (
        <tr key={lead.id} className="task-row" style={{ height: 50, backgroundColor }}>
            <td className={classNames({'border-none': false})} style={{ width: 175 }}>
                <div>
                    <Link to={leadUrl}>{lead.name}</Link>
                </div>
                {hasScheduledCallComingUp && (
                    <div style={{ fontSize: '80%', color: '#5c5c5c' }}>
                        <i className="clock outline icon" />
                        {formatDateTimeRange(lead.nextScheduledCallScheduledForFrom, lead.nextScheduledCallScheduledForTo)}
                    </div>
                )}
                {(isDeferred && !hasScheduledCallComingUp) && (
                    <div style={{ fontSize: '80%', color: '#5c5c5c' }}>
                        Deferred until
                        {' '}
                        {formatDateTimeHumanDateTime(lead.deferredUntil)}
                    </div>
                )}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.benefitsTypeLabel || lead.benefitsType || '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.caseType ? lead.caseType : '-'}
            </td>
            <td className={classNames({'border-none': false})}>
                {lead.caseDescription ? lead.caseDescription : '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.qualifiedAt ? formatDateTimeHumanDateTime(lead.qualifiedAt) : '-'}
            </td>
            <td
                className={classNames({'border-none': false})}
                style={{ width: 160 }}
            >
                {lead.waitingInVonageConversation && (
                    <JoinLeadCallButton
                        lead={lead}
                        leadUrl={leadUrl}
                        purpose={Constants.CALL_PURPOSES.CONSULTATION}
                    />
                )}
                <div className="ui mini compact basic icon buttons task-action-buttons">
                    <button
                        className={classNames("ui icon button", {active: isPinned})}
                        data-tooltip={isPinned ? "Unpin lead" : "Pin lead"}
                        data-position="top center"
                        onClick={() => togglePinLead(lead)}
                    >
                        <i className={classNames("thumbtack icon", {green: isPinned})}/>
                    </button>
                </div>
            </td>
            <td className={classNames("whitespace-no-wrap", {'border-none': false})}>
                <LeadPriorityLabel lead={lead} />
            </td>
        </tr>
    );
};

const LeadsTable = ({
    leads,
    leadIsPinned,
    togglePinLead,
    loadMoreLeads,
    moreLeadsAvailable,
    loadingLeads,
    buildLeadUrl,
}) => {
    return (
        <div className={classNames({ hidden: false })}>
            <Table
                dim={loadingLeads}
                head={
                    <>
                        <th>Name of Lead</th>
                        <th>Benefits Type</th>
                        <th>Case Type</th>
                        <th>Case Description</th>
                        <th>Qualified At</th>
                        <th>Actions</th>
                        <th>Priority</th>
                    </>
                }
            >
                {leads.length === 0 && (
                    <tr className="dim">
                        <td className="border-none" colSpan={4}>No leads...</td>
                    </tr>
                )}
                {leads.map((lead) => (
                    <LeadRow
                        key={lead.id}
                        lead={lead}
                        leadIsPinned={leadIsPinned}
                        togglePinLead={togglePinLead}
                        leadUrl={buildLeadUrl(lead.id)}
                    />
                ))}
                {moreLeadsAvailable && (
                    <tr>
                        <td className="border-none" colSpan={7}>
                            <button
                                className="ui compact fluid button"
                                onClick={loadMoreLeads}
                                disabled={loadingLeads}
                            >
                                Load more...
                            </button>
                        </td>
                    </tr>
                )}
            </Table>
        </div>
    );
};

const SideBar = ({
    pinnedLeads,
    loadingPinnedLeads,
    pinnedLeadsErrorMessage,
    recentlyViewedLeads,
    buildLeadUrl,
}) => {
    return (
        <>
            <p style={{ fontWeight: 'bold', marginTop: '1em', marginBottom: 0, color: 'rgb(95, 109, 131)' }}>
                <i className="thumbtack icon"/> PINNED LEADS
            </p>
            <table className="ui very basic table" style={{marginBottom: '1em', marginTop: 0}}>
                <tbody>
                    {loadingPinnedLeads && (
                        <tr className="dim">
                            <td className="border-none">
                                Loading...
                            </td>
                        </tr>
                    )}
                    {pinnedLeadsErrorMessage && (
                        <tr className="dim">
                            <td className="border-none">
                                {pinnedLeadsErrorMessage}
                            </td>
                        </tr>
                    )}
                    {!loadingPinnedLeads && pinnedLeads.length === 0 && (
                        <tr className="dim">
                            <td className="border-none">
                                None yet...
                            </td>
                        </tr>
                    )}
                    {!loadingPinnedLeads && pinnedLeads.length > 0 && pinnedLeads.map((lead) => (
                        <tr key={lead.id}>
                            <td className="border-none">
                                <Link to={buildLeadUrl(lead.id)}>{lead.name}</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <p style={{ fontWeight: 'bold', marginTop: '1em', marginBottom: 0, color: 'rgb(95, 109, 131)' }}>
                <i className="history icon"/> RECENTLY VIEWED
            </p>
            <table className="ui very basic table" style={{marginBottom: '1em', marginTop: 0}}>
                <tbody>
                    {recentlyViewedLeads.length === 0 && (
                        <tr className="dim">
                            <td className="border-none">
                                None yet...
                            </td>
                        </tr>
                    )}
                    {recentlyViewedLeads.length > 0 && recentlyViewedLeads.map((recentlyViewedLead) => (
                        <tr key={recentlyViewedLead.id}>
                            <td className="border-none">
                                <Link to={buildLeadUrl(recentlyViewedLead.id)}>{recentlyViewedLead.name}</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const ActionButtons = ({
    rightBarVisible,
    setRightBarVisible,
    sortOrder,
    setSortOrder,
    showFilterBar,
    setShowFilterBar,
    anyFiltersApplied,
    leadsArchiveUrl,
    newLeadUrl,
    isDeferredFilter,
    setSelectedIsDeferredFilterOption,
    numberOfDeferredLeads,
}) => {
    const sortOptions = [
        { value: Constants.SORT_BY_PRIORITY_ASC, title: "Highest priority first" },
        { value: Constants.SORT_BY_PRIORITY_DESC, title: "Lowest priority first" },
        { value: Constants.SORT_BY_CONSULT_FIELDS_UDPATED_AT_DESC, title: "Consult fields recently updated" },
    ];

    return (
        <>
            <a className="ui basic icon button" style={{ marginRight: 10 }} href={leadsArchiveUrl}>
                <i className="archive icon" />
            </a>
            <button className="ui basic icon button" onClick={() => setRightBarVisible(!rightBarVisible)}>
                <i className={classNames("icon", {columns: !rightBarVisible, 'window maximize outline': rightBarVisible})}/>
            </button>
            <div className="ui basic icon buttons" style={{marginRight: 10}}>
                <IconDropdown
                    icon="sort amount down"
                    items={sortOptions}
                    value={sortOrder}
                    onChange={(option) => setSortOrder(option)}
                    position={DROPDOWN_POSITION_LEFT}
                />
                <FilterButton
                    onClick={() => setShowFilterBar(!showFilterBar)}
                    active={showFilterBar}
                    pressed={anyFiltersApplied}
                />
                <button
                    className={classNames("ui basic icon button", { active: isDeferredFilter === null })}
                    onClick={() => setSelectedIsDeferredFilterOption(isDeferredFilter === null ? ({ label: 'No', value: false }) : null)}
                    style={{ position: 'relative' }}
                >
                    <i className={classNames("clock outline icon", { green: isDeferredFilter === null })}/>
                    {numberOfDeferredLeads > 0 && (
                        <div
                            style={{
                                backgroundColor: 'red',
                                borderRadius: 999,
                                position: 'absolute',
                                top: 3,
                                right: 3,
                                width: 14,
                                height: 14,
                                fontSize: 8,
                                fontWeight: 'bold',
                                color: 'white',
                                boxShadow: '0px 0px 2px rgba(0,0,0,.2)',
                            }}
                        >
                            {numberOfDeferredLeads}
                        </div>
                    )}
                </button>
            </div>
            <Link to={newLeadUrl} className="ui labeled primary icon button">
                <i className="plus icon" />
                Add Lead
            </Link>
        </>
    );
};

const LeadsPageCurrent = ({
    expandedLeadID,
    allUsers,
    allTeams,
    leadModelDescription,
    opportunityModelDescription,
    pinnedLeads,
    loadingPinnedLeads,
    pinnedLeadsErrorMessage,
    togglePinLead,
    leadIsPinned,
    recentlyViewedLeads,
    reportLeadViewed,
    tabs,
    buildLeadUrl,
    leadsArchiveUrl,
    leadsUrl,
    newLeadUrl,
}) => {
    const [sortOrder, setSortOrder] = useLocalStorage('leadspage.sortOrder', Constants.SORT_BY_PRIORITY_ASC);
    const [rightBarVisible, setRightBarVisible] = useLocalStorage('leadspage.rightBarVisible', true);
    const [selectedAssigneeFilterOptions, setSelectedAssigneeFilterOptions] = useLocalStorage('leadspage.selectedAssigneeFilterOptions', []);
    const [selectedPriorityFilterOptions, setSelectedPriorityFilterOptions] = useLocalStorage('leadspage.selectedPriorityFilterOptions', []);
    const [selectedBenefitsTypeFilterOptions, setSelectedBenefitsTypeFilterOptions] = useLocalStorage('leadspage.selectedBenefitsTypeFilterOptions', []);
    const [selectedIsDeferredFilterOption, setSelectedIsDeferredFilterOption] = useLocalStorage('leadspage.archive.selectedIsDeferredFilterOption', { label: 'No', value: false });
    const [queryFilter, setQueryFilter] = useLocalStorage('leadspage.queryFilter', '');
    const assigneeIdInFilter = useMemo(() => {
        return selectedAssigneeFilterOptions
            .map((selectedAssigneeOption) => selectedAssigneeOption.value);
    }, [selectedAssigneeFilterOptions]);
    const priorityInFilter = useMemo(() => {
        return selectedPriorityFilterOptions
            .map((selectedPriorityFilterOption) => selectedPriorityFilterOption.value);
    }, [selectedPriorityFilterOptions]);
    const benefitsTypeInFilter = useMemo(() => {
        return selectedBenefitsTypeFilterOptions
            .map((selectedBenefitsTypeFilterOption) => selectedBenefitsTypeFilterOption.value);
    }, [selectedBenefitsTypeFilterOptions]);
    const isDeferredFilter = useMemo(() => {
        if (!selectedIsDeferredFilterOption) return null;

        return selectedIsDeferredFilterOption.value;
    }, [selectedIsDeferredFilterOption]);
    const filters = useMemo(() => ({
        query: queryFilter,
        assigneeIdIn: assigneeIdInFilter,
        priorityIn: priorityInFilter,
        benefitsTypeIn: benefitsTypeInFilter,
        statusIn: ['Consultation'],
        isConverted: false,
        isDeferred: isDeferredFilter,
    }), [queryFilter, assigneeIdInFilter, priorityInFilter, benefitsTypeInFilter, isDeferredFilter]);
    const {
        leads,
        loading: loadingLeads,
        leadsErrorMessage,
        initialized: leadsInitialized,
        loadMore: loadMoreLeads,
        moreAvailable: moreLeadsAvailable,
        reload: reloadLeads,
    } = useLeadsWithLoadMoreFunctionality({
        batchSize: BATCH_SIZE,
        sortOrder,
        filters,
    });
    const anyFiltersApplied = queryFilter.length > 0
        || assigneeIdInFilter.length > 0
        || priorityInFilter.length > 0
        || benefitsTypeInFilter.length > 0;
    const [showFilterBar, setShowFilterBar] = useState(anyFiltersApplied);
    const history = useHistory();

    const filtersDeferred = useMemo(() => ({
        statusIn: ['Consultation'],
        isConverted: false,
        isDeferred: true,
    }), []);
    const { leads: deferredLeads, reload: reloadDeferredLeads } = useLeads({ filters: filtersDeferred });
    const numberOfDeferredLeads = deferredLeads.length;

    usePubSub({
        topic: Constants.PUBSUB_TOPIC_LEAD,
        messageHandler: (topic, _data) => {
            if (
                topic === Constants.PUBSUB_TOPIC_LEAD_WAITING_IN_CONVERSATION_UPDATED ||
                    topic === Constants.PUBSUB_TOPIC_LEAD_UPDATED
            ) {
                reloadLeads();
                reloadDeferredLeads();
            }
        },
    });

    return (
        <LeadsPageLayout
            showFilterBar={showFilterBar}
            sideBarVisible={rightBarVisible}
            tabs={tabs}
            actionButtons={
                <ActionButtons
                    rightBarVisible={rightBarVisible}
                    setRightBarVisible={setRightBarVisible}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    showFilterBar={showFilterBar}
                    setShowFilterBar={setShowFilterBar}
                    anyFiltersApplied={anyFiltersApplied}
                    leadsArchiveUrl={leadsArchiveUrl}
                    newLeadUrl={newLeadUrl}
                    isDeferredFilter={isDeferredFilter}
                    setSelectedIsDeferredFilterOption={setSelectedIsDeferredFilterOption}
                    numberOfDeferredLeads={numberOfDeferredLeads}
                />
            }
            filterBar={
                <FilterBar
                    allUsers={allUsers}
                    query={queryFilter}
                    setQuery={setQueryFilter}
                    leadModelDescription={leadModelDescription}
                    selectedAssigneeOptions={selectedAssigneeFilterOptions}
                    setSelectedAssigneeOptions={setSelectedAssigneeFilterOptions}
                    selectedPriorityOptions={selectedPriorityFilterOptions}
                    setSelectedPriorityOptions={setSelectedPriorityFilterOptions}
                    selectedBenefitsTypeOptions={selectedBenefitsTypeFilterOptions}
                    setSelectedBenefitsTypeOptions={setSelectedBenefitsTypeFilterOptions}
                    anyFiltersApplied={anyFiltersApplied}
                />
            }
            sideBar={
                <SideBar
                    pinnedLeads={pinnedLeads}
                    loadingPinnedLeads={loadingPinnedLeads}
                    pinnedLeadsErrorMessage={pinnedLeadsErrorMessage}
                    recentlyViewedLeads={recentlyViewedLeads}
                    buildLeadUrl={buildLeadUrl}
                />
            }
        >
            {leadsErrorMessage && <div className="ui error message">{leadsErrorMessage}</div>}
            {expandedLeadID && (
                <LeadModal
                    id={expandedLeadID}
                    onClose={() => { history.push(leadsUrl) }}
                    isPinned={leadIsPinned(expandedLeadID)}
                    togglePinLead={togglePinLead}
                    allUsers={allUsers}
                    allTeams={allTeams}
                    reportLeadViewed={reportLeadViewed}
                    leadModelDescription={leadModelDescription}
                    opportunityModelDescription={opportunityModelDescription}
                    leadUrl={buildLeadUrl(expandedLeadID)}
                />
            )}
            {!leadsInitialized ? (
                <Center>
                    <Spinner />
                </Center>
            ) : (
                <LeadsTable
                    leads={leads}
                    leadIsPinned={leadIsPinned}
                    togglePinLead={togglePinLead}
                    loadMoreLeads={loadMoreLeads}
                    moreLeadsAvailable={moreLeadsAvailable}
                    loadingLeads={loadingLeads}
                    buildLeadUrl={buildLeadUrl}
                />
            )}
        </LeadsPageLayout>
    );
}

export default LeadsPageCurrent;
