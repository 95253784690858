export const decorateConversation = (conversation, { currentUser }) => {
    const participantsOtherThanSelf = conversation
        .participants
        .filter((participant) => !(participant.isUser && participant.participant_id === currentUser.userID));
    const participantSelf = conversation
        .participants
        .find((participant) => participant.isUser && participant.participant_id === currentUser.userID)
        || conversation
            .participants
            .find((participant) => participant.isTeam && (currentUser.teamMemberOf || []).includes(participant.participant_id));

    return {
        ...conversation,
        participantsOtherThanSelf,
        participantSelf,
    };
};

export const decorateMessage = (message, { currentUser }) => {
    const isOwnMessage = message.sender_type === 'User' && message.sender_id === currentUser.userID;

    return {
        ...message,
        isOwnMessage,
    };
};
