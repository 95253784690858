import React, { useContext, useEffect } from "react";
import Select from "react-select";
import "./taskcard.css";
import { Link } from "react-router-dom";
import PDFViewer from "../components/pdfviewer.js";
import { ToastContext } from "../common/toastContext.js";
import API from "../common/api.js";
import usePreventBodyScroll from "../common/usePreventBodyScroll.js";
import generateSelectOptions from "../common/generateSelectOptions.js";
import { useQuery } from "react-query";
import useComments from "../common/useComments.js";
import { CurrentUserContext } from "../common/currentUserContext.js";
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';
import { get } from "lodash";
import MatterQuickView from "../components/matterquickview.js";
import EditableCaseNumber from "../components/editablecasenumber.js";
import { formatHumanDateTime } from "../common/formatting.js";
var classNames = require("classnames");

const scanCommentsApi = {
    getComments: API.getScanComments,
    markMentionsRead: API.markScanMentionsRead,
    createComment: API.createScanComment,
    createMentionMessage: API.createScanMentionMessage,
};

function ScanCard({
    scan,
    onCloseClicked,
    setScanAssignee,
    setScanAcknowledged,
    possibleAssignees,
    allCaseNumbers,
    toggleWatched,
    readOnly,
    leftSiblingLink,
    rightSiblingLink,
}) {
    const toastIF = useContext(ToastContext);
    const currentUser = useContext(CurrentUserContext);
    usePreventBodyScroll();
    usePubSub({topic: Constants.PUBSUB_TOPIC_INBOX_SCAN, messageHandler: handleServerMessage});
    const { isLoading: loadingActionHistory, error: errorActionHistory, data: actionHistoryItems } = useQuery(
        ["scan-action-history", { scanID: scan.scan_id }],
        API.getScanActionHistory
    );

    const scanID = scan && scan.scan_id;
    const { comments, setComments, commentsSection, toggleCommentsButton } = useComments(
        scanID,
        scan,
        scanCommentsApi,
        possibleAssignees,
        readOnly,
        currentUser
    );

    async function markScanProcessed() {
        setScanAcknowledged(scan);
        toastIF.addToast(`Processed: ${scan.filename}`);
        await API.addScanActionHistoryItem(
            Constants.SCAN_ACTION_HISTORY_PROCESSED,
            scanID,
            currentUser.username,
            "Marked as Processed"
        );
        onCloseClicked();
    }

    useEffect(() => {
        API.markScanRead(scan.scan_id);
    }, [scan.scan_id]);

    function handleServerMessage(topic, data) {
        if (parseInt(get(data, "scan_id")) === scanID) {
            if (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_COMMENT_ADDED) {
                const newComments = [data, ...comments];
                setComments(newComments);
            }
        }
    }

    async function handleChangeCaseNumber(v) {
        await API.updateScan(scanID, { case_number: v.case_number });
    }

    if (scan === null) return null;

    const headerStyle = {
        backgroundColor: "white",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        flexGrow: 0,
        flexDirection: "column",
        zIndex: 1,
    };
    const footerStyle = { ...headerStyle, flexDirection: "row", minHeight: 44 };
    const closeButtonStyle = { position: "absolute", right: 10, top: 10, padding: 7 };
    const headerFieldsStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingRight: 30,
        alignItems: "center",
    };
    const titleFieldStyle = { flexGrow: 1 };
    const contentSectionParentStyle = {
        backgroundColor: "#F8F8F8",
        paddingTop: 0,
        paddingBottom: 0,
        borderTop: "none",
        maxHeight: "calc(100vh - 200px)",
        overflowY: "auto",
    };
    const contentSectionStyle = {
        backgroundColor: "#F8F8F8",
        paddingTop: "2em",
        paddingBottom: "2em",
        borderTop: "none",
    };
    const stretcherStyle = { ...contentSectionStyle, flexGrow: 1000, height: 0, border: "none", padding: 0 };
    const insetStyle = { paddingLeft: "3.3em" };
    const modalContentStyle = {
        display: "flex",
        zIndex: 2,
        minHeight: "60vh",
        maxHeight: "80vh",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        maxWidth: 1500,
    };

    const previewSection = (
        <div className="content" style={contentSectionStyle}>
            <h3 className="ui header">
                <i className="eye icon" /> Preview
            </h3>
            <div className="taskcard-description" style={insetStyle}>
                <PDFViewer uri={scan.uri} />
            </div>
        </div>
    );

    const historySectionContents = loadingActionHistory ? (
        <div className="ui active inline loader" style={{ marginTop: 20 }}></div>
    ) : errorActionHistory ? (
        <div className="ui error message">Error retrieving action history</div>
    ) : actionHistoryItems.length === 0 ? (
        <p className="dim">None yet...</p>
    ) : (
        <table className="ui very basic compact table" style={{ marginTop: 0 }}>
            <tbody>
                {actionHistoryItems.map((item, inx) => {
                    const humanCreationDate = formatHumanDateTime(item.created_at);
                    const icon =
                        item.kind === Constants.SCAN_ACTION_HISTORY_UPLOADED_TO_CLIO
                            ? "upload icon"
                            : item.kind === Constants.SCAN_ACTION_HISTORY_CONVERTED_TO_TASK
                            ? "tasks icon"
                            : "check icon";
                    return (
                        <tr key={`history_item_${inx}`}>
                            <td
                                className="collapsing top aligned"
                                style={{ paddingRight: 0, paddingTop: 0, border: "none" }}
                            >
                                <i className={icon} />
                            </td>
                            <td style={{ paddingLeft: 4, paddingTop: 0, border: "none" }}>
                                {item.description}{" "}
                                {item.uri && (
                                    <Link to={item.uri}>
                                        <i className="linkify icon" />
                                    </Link>
                                )}
                                <br />
                                <span className="scans-scan-timestamp">
                                    {humanCreationDate} by {item.author_name}
                                </span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );

    const assignee = scan.assignee_id
        ? possibleAssignees.find((a) => a.is_user && a.id === scan.assignee_id)
        : possibleAssignees.find((a) => a.is_team && a.id === scan.team_id);
    const assigneeSelectStyles = {
        container: (styles) => ({ ...styles, zIndex: 100, color: "black", flexGrow: 1 }),
        control: (styles) => ({ ...styles, borderWidth: 0, ":hover": { cursor: "pointer" } }),
        singleValue: (styles) => ({ ...styles, ":hover": { cursor: "pointer", color: "#1e70bf" } }),
        indicatorsContainer: (styles) => ({ ...styles, display: "none" }),
    };
    const assigneeIcon = scan.assignee_id ? "user icon" : "users icon";
    const assigneeSelect = readOnly ? (
        <div
            style={{
                width: 300,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
        >
            <i className={`fitted ${assigneeIcon} mr-4`} style={{ lineHeight: "1.1em", paddingRight: 10 }} />
            {assignee.label}
        </div>
    ) : (
        <div
            style={{
                width: 300,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
            data-tooltip="Who is this scan assigned to? Click to re-assign"
            data-position="right center"
        >
            <i className={`fitted ${assigneeIcon} mr-4`} style={{ lineHeight: "1.1em" }} />
            <Select
                options={generateSelectOptions(possibleAssignees)}
                styles={assigneeSelectStyles}
                value={assignee}
                onChange={(option) => setScanAssignee(scan, option)}
                menuPlacement="top"
            />
        </div>
    );

    const quickView = scan.case_number && scan.case_number.length > 0 && (
        <div className="modal-rail">
            <div className="ui segment" style={{ height: "100%", overflowY: "scroll" }}>
                <MatterQuickView caseNumber={scan.case_number} />
            </div>
        </div>
    );

    const stretcher = <div className="content" style={stretcherStyle}></div>;

    const humanCreationDate = formatHumanDateTime(scan.created_at);

    const header = (
        <div className="content" style={headerStyle}>
            <div style={{ ...headerFieldsStyle, marginBottom: "0.5em" }}>
                <div style={closeButtonStyle}>
                    <div className="ui compact mini icon buttons" style={{ marginRight: 20 }}>
                        <Link
                            to={leftSiblingLink || ""}
                            className={classNames("ui button", { disabled: leftSiblingLink === null })}
                        >
                            <i className="chevron left icon" />
                        </Link>
                        <Link
                            to={rightSiblingLink || ""}
                            className={classNames("ui button", { disabled: rightSiblingLink === null })}
                        >
                            <i className="chevron right icon" />
                        </Link>
                    </div>
                    <span onClick={onCloseClicked}>
                        <i className="fitted black close link icon" />
                    </span>
                </div>
                <div style={headerFieldsStyle}>
                    <div style={titleFieldStyle}>
                        <h2 className="mr-4">
                            <i className="file pdf icon" /> {scan.filename}
                        </h2>
                    </div>
                </div>
            </div>
            <div style={{ ...headerFieldsStyle, paddingRight: 0 }}>
                <div>
                    <div style={{ marginRight: "2em", minWidth: 300 }}>
                        <EditableCaseNumber
                            initialValue={scan.case_number}
                            allCaseNumbers={allCaseNumbers}
                            onChange={handleChangeCaseNumber}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const actionButtons = (
        <div>
            <a
                href={scan.uri}
                target="_blank"
                rel="noopener noreferrer"
                className="ui basic primary fluid labeled icon button ui"
                style={{ marginBottom: "0.5em", textAlign: "left" }}
            >
                <i className="external alternate icon" /> Open In New Window
            </a>
            <Link
                to={`/upload?scanID=${scan.scan_id}`}
                className="ui basic primary fluid labeled icon button"
                style={{ marginBottom: "0.5em", textAlign: "left" }}
            >
                <i className="upload icon" /> Upload To Clio
            </Link>
        </div>
    );

    const toggleWatchButton = readOnly || (
        <button className={classNames("ui fluid labeled icon toggle button", {active: scan.is_watched_by_current_user})} style={{marginBottom: '0.5em', textAlign: 'left'}} onClick={() => toggleWatched(scan)}><i className="eye icon"/>Watch Scan</button>
    );

    const content = (
        <div className="content" style={contentSectionParentStyle}>
            <div className="flex">
                <div className="flex-grow">
                    {previewSection}
                    {stretcher}
                    {commentsSection}
                </div>
                <div
                    style={{
                        paddingLeft: "1em",
                        paddingRight: "1em",
                        marginTop: "2em",
                        width: 250,
                        flexGrow: 0,
                        flexShrink: 0,
                    }}
                >
                    <p style={{ fontWeight: "bold", color: "#5F6D83" }}>SECTIONS</p>
                    {toggleCommentsButton}
                    {toggleWatchButton}

                    <p style={{ fontWeight: "bold", color: "#5F6D83", marginTop: "2em" }}>ABOUT SCAN</p>
                    <div
                        style={{ marginRight: "2em", marginBottom: "0.25em" }}
                        data-tooltip="Created at"
                        data-position="left center"
                    >
                        <i className="calendar plus outline icon" /> {humanCreationDate}
                    </div>

                    <p style={{ fontWeight: "bold", color: "#5F6D83", marginTop: "2em" }}>ACTIONS</p>
                    {actionButtons}

                    <p style={{ fontWeight: "bold", color: "#5F6D83", marginTop: "2em" }}>ACTION HISTORY</p>
                    {historySectionContents}
                </div>
            </div>
        </div>
    );

    const footer = (
        <div className="content" style={footerStyle}>
            <div style={{ flexGrow: 1 }}>{assigneeSelect}</div>
            <div>
                <Link to={`/add_task?scanID=${scan.scan_id}`} className="ui basic primary labeled icon button">
                    <i className="tasks icon" /> Convert To Task
                </Link>
                {scan.acknowledged || (
                    <button className="ui primary labeled icon button" onClick={markScanProcessed}>
                        <i className="check icon" style={{ marginLeft: "auto" }} /> Processed
                    </button>
                )}
            </div>
        </div>
    );

    const scanDetails = (
        <div key={scan.scan_id} className="ui raised fluid card">
            {header}
            {content}
            {footer}
        </div>
    );

    return (
        <>
            <div className="modal-overlay" style={{ zIndex: 1 }} onClick={onCloseClicked} />
            <div style={modalContentStyle}>
                {scanDetails}
                {quickView}
            </div>
        </>
    );
}

export default ScanCard;
