import { useState, useCallback } from 'react';

function useStateObject(initialValue) {
    const [state, setState] = useState(initialValue);

    const updateState = useCallback(s => {
        setState(state => {
            const newState = {...state, ...s};
            return newState;
        });
    }, [setState]);

    return [state, updateState];
}

export default useStateObject;
