import React, { useState } from "react";
import * as Constants from "./webconstants.js";
import API from './api.js';
var classNames = require("classnames");

function OnCallButton({ currentUser }) {
    const [updatingOnCallSince, setUpdatingOnCallSince] = useState();

    const setOnCallStatus = async (onCallStatus) => {
        setUpdatingOnCallSince(true);

        await API.setOnCallStatus(onCallStatus);

        setUpdatingOnCallSince(false);
    }

    const setOnCall = () => {
        setOnCallStatus({ status: Constants.ON_CALL_STATUS_ON_CALL });
    }

    const setOffCall = () => {
        setOnCallStatus({ status: Constants.ON_CALL_STATUS_OFF_CALL });
    }

    if (!currentUser) return;

    return (
        <button
            className={
                classNames(
                    'ui labeled icon button',
                    {
                        disabled: updatingOnCallSince,
                        red: currentUser.onCallStatus.status === Constants.ON_CALL_STATUS_OFF_CALL,
                        green: currentUser.onCallStatus.status === Constants.ON_CALL_STATUS_ON_CALL,
                    }
                )
            }
            onClick={currentUser.onCallStatus.status === Constants.ON_CALL_STATUS_OFF_CALL ? setOnCall : setOffCall}
            data-tooltip={currentUser.onCallStatus.status === Constants.ON_CALL_STATUS_OFF_CALL ? 'Go On Call' : 'Go Off Call'}
            data-position="bottom center"
            style={{ marginRight: 10 }}
        >
            <i className="phone square icon" />
            {currentUser.onCallStatus.status === Constants.ON_CALL_STATUS_OFF_CALL ? 'Off Call' : 'On Call'}
        </button>
    );
}

export default OnCallButton;
