import { useState } from 'react';
import API from './api';

const DOCUMENT_FILE_TYPES = {
    PDF: 'application/pdf',
    MPEG: 'audio/mpeg',
};

const PDF_CONVERSION_STATUSES = {
    DONE: 'done',
    FAILED: 'failed',
};

const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const useDocumentUpload = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const addError = (error) => setErrors([...errors, error]);
    const createTemporaryDocument = async ({ matterId, name, fileType, shareWithClient=false }) => {
        if (!name || name.trim().length === 0) {
            addError({
                code: 'DOCUMENT_NAME_BLANK',
                description: 'Please provide a name for the document',
            });

            return;
        }

        const temporaryDocumentId = await API.createDocument(matterId, name, shareWithClient, fileType);

        if (!temporaryDocumentId) {
            addError({
                code: 'FAILED_TO_CREATE_DOCUMENT',
                description: 'Failed to create a new document',
            });

            return;
        }

        return temporaryDocumentId;
    };
    const createTemporaryPdfDocument = ({ matterId, name, shareWithClient }) => {
        return createTemporaryDocument({ matterId, name, shareWithClient, fileType: DOCUMENT_FILE_TYPES.PDF })
    };
    const addFileToTemporaryDocument = async (temporaryDocumentId, { fileUri }) => {
        const result = await API.addFileToTemporaryDocument(temporaryDocumentId, { fileUri });

        if (!result) {
            addError({
                code: 'FAILED_TO_ADD_FILE',
                description: `Failed to add file to (temporary) document ${temporaryDocumentId}`,
            });
        }

        return result;
    };
    const addNotesToTemporaryDocument = async (temporaryDocumentId, { notes }) => {
        const result = await API.uploadNotes(temporaryDocumentId, notes);

        if (!result) {
            addError({
                code: 'FAILED_TO_ADD_NOTES',
                description: `Failed to add notes to (temporary) document ${temporaryDocumentId}`,
            });
        }

        return result;
    };
    const startTemporaryDocumentSubmission = async (temporaryDocumentId) => {
        const result = await API.convertToPDF(temporaryDocumentId);

        if (!result) {
            addError({
                code: 'FAILED_TO_START_CONVERSION',
                description: `Failed to start the conversion process for (temporary) document ${temporaryDocumentId}`,
            });
        }

        return result;
    };
    const getTemporaryDocumentSubmissionStatus = (temporaryDocumentId) => API.getConversionStatus(temporaryDocumentId);
    const awaitTemporaryDocumentSubmission = async (temporaryDocumentId) => {
        while (true) {
            const conversion = await getTemporaryDocumentSubmissionStatus(temporaryDocumentId);
            if (conversion === null || conversion === PDF_CONVERSION_STATUSES.FAILED) {
                addError({
                    code: 'CONVERSION_FAILED',
                    description: `Failed to convert document ${temporaryDocumentId} to PDF`,
                });

                return null;
            } else if (conversion.status === PDF_CONVERSION_STATUSES.DONE) {
                return conversion.clioDocumentID;
            }

            await timeout(500);
        }
    };

    const submitTemporaryDocument = async ({ temporaryDocumentId }) => {
        setErrors([]);
        setLoading(true);

        const upload = async () => {
            const conversionStarted = await startTemporaryDocumentSubmission(temporaryDocumentId);
            if (!conversionStarted) return;

            const documentId = await awaitTemporaryDocumentSubmission(temporaryDocumentId);
            if (!documentId) return;

            return documentId;
        };

        const documentId = await upload();

        setLoading(false);

        return documentId;
    };

    const submitPdfDocument = async ({ matterId, documentName, shareWithClient, fileUri, notes }) => {
        setErrors([]);
        setLoading(true);

        const upload = async () => {
            const temporaryDocumentId = await createTemporaryPdfDocument({ matterId, name: documentName, shareWithClient });
            if (!temporaryDocumentId) return;

            const fileAdded = await addFileToTemporaryDocument(temporaryDocumentId, { fileUri });
            if (!fileAdded) return;

            if (notes) {
                const notesAdded = await addNotesToTemporaryDocument(temporaryDocumentId, { notes });
                if (!notesAdded) return;
            }

            const conversionStarted = await startTemporaryDocumentSubmission(temporaryDocumentId);
            if (!conversionStarted) return;

            const documentId = await awaitTemporaryDocumentSubmission(temporaryDocumentId);
            if (!documentId) return;

            return documentId;
        };

        const documentId = await upload();

        setLoading(false);

        return documentId;
    };

    return {
        createTemporaryPdfDocument,
        addFileToTemporaryDocument,
        submitTemporaryDocument,
        addNotesToTemporaryDocument,
        submitPdfDocument,
        errors,
        loading,
    }
};

export default useDocumentUpload;
