import React from 'react';
import { durationInSecondsAsMinutesString, durationInSecondsAsHoursString } from '../../common/formatting.js';

const ValueRow = ({ value }) => {
    return (
        <div
            style={{
                fontSize: 18,
                fontWeight: 'bold',
                textAlign: 'right',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginTop: 5,
                color: '#3e3e3e',
            }}
        >
            {value}
        </div>
    );
};

const LabelRow = ({ label }) => {
    return (
        <div
            style={{
                fontSize: 12,
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginTop: 5,
            }}
        >
            {label}
        </div>
    );
};

const RatioValue = ({ numberA, numberB }) => {
    if (!numberA || !numberB || numberB === 0) {
        return 'N/A';
    }

    const ratio = numberA / numberB;

    let color = null;
    if (ratio > 0.5) {
        color = '#00ae00';
    } else if (ratio < 0.5) {
        color = 'red';
    }

    return <span style={{ color }}>{Math.round(ratio * 100)}%</span>;
};

const BiRatioValue = ({ numberA, numberB }) => {
    return (
        <RatioValue
            numberA={numberA}
            numberB={numberA + numberB}
        />
    )
};

const CategoryCard = ({ headline, statistics }) => {
    const borderRadius = 5;
    const borderColor = '#c1c1c1';

    return (
        <div
            style={{
                width: 250,
                backgroundColor: '#d5d5d5',
                borderRadius,
                boxShadow: 'rgb(198, 198, 198) 1px 1px 5px',
                border: '1px solid',
                borderColor,
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    backgroundColor: borderColor,
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                    padding: 15,
                }}
            >
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: 22,
                        fontWeight: 'bold',
                        color: 'rgb(45, 45, 45)',
                    }}
                >
                    {headline}
                </span>
            </div>
            <div style={{ display: 'flex', gap: 5, paddingTop: 8, paddingBottom: 15 }}>
                <div style={{ flex: 2, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <ValueRow value={statistics.day} />
                    <ValueRow value={statistics.week} />
                    <ValueRow value={statistics.month} />
                    <ValueRow value={statistics.quarter} />
                    <ValueRow value={statistics.year} />
                </div>
                <div style={{ flex: 3, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <LabelRow label="today" />
                    <LabelRow label="this week" />
                    <LabelRow label="this month" />
                    <LabelRow label="this quarter" />
                    <LabelRow label="this year" />
                </div>
            </div>
        </div>
    );
};


export const TakeItDenyItRatioCard = ({ statistics }) => {
    const takeItVsDenyItValue = (key) => {
        return (
            <RatioValue
                numberA={statistics.numberTakeIt[key]}
                numberB={statistics.numberResolved[key]}
            />
        );
    };

    return (
        <CategoryCard
            headline="Take It Ratio"
            statistics={{
                day: takeItVsDenyItValue('day'),
                week: takeItVsDenyItValue('week'),
                month: takeItVsDenyItValue('month'),
                quarter: takeItVsDenyItValue('quarter'),
                year: takeItVsDenyItValue('year'),
            }}
        />
    );
};

export const WonLostRatioCard = ({ statistics }) => {
    const wonVsLostValue = (key) => {
        return (
            <BiRatioValue
                numberA={statistics.numberClosedWon[key]}
                numberB={statistics.numberClosedLost[key]}
            />
        );
    };

    return (
        <CategoryCard
            headline="Won/Lost"
            statistics={{
                day: wonVsLostValue('day'),
                week: wonVsLostValue('week'),
                month: wonVsLostValue('month'),
                quarter: wonVsLostValue('quarter'),
                year: wonVsLostValue('year'),
            }}
        />
    );
};

export const AverageCallLengthCard = ({ statistics }) => {
    const averageCallLengthValue = (key) => {
        const value = statistics.averageCallLengthInSeconds[key];

        if (value === null) return 'N/A';

        return `${durationInSecondsAsMinutesString(value)} m`;
    }

    return (
        <CategoryCard
            headline="AVG Call Duration"
            statistics={{
                day: averageCallLengthValue('day'),
                week: averageCallLengthValue('week'),
                month: averageCallLengthValue('month'),
                quarter: averageCallLengthValue('quarter'),
                year: averageCallLengthValue('year'),
            }}
        />
    );
};

export const ResolvedCard = ({ statistics }) => {
    return (
        <CategoryCard
            headline="Resolved"
            statistics={statistics.numberResolved}
        />
    );
};

export const IncomingCard = ({ statistics }) => {
    return (
        <CategoryCard
            headline="Incoming"
            statistics={statistics.numberIncoming}
        />
    );
};

export const TotalOnCallTimeCard = ({ statistics }) => {
    const formattedValueForKey = (key) => {
        const value = statistics.totalOnCallTimeInSeconds[key];

        return `${durationInSecondsAsHoursString(value)} h`;
    }

    return (
        <CategoryCard
            headline="On Call Total"
            statistics={{
                day: formattedValueForKey('day'),
                week: formattedValueForKey('week'),
                month: formattedValueForKey('month'),
                quarter: formattedValueForKey('quarter'),
                year: formattedValueForKey('year'),
            }}
        />
    );
};

export const AverageOnCallSessionLengthCard = ({ statistics }) => {
    const formattedValueForKey = (key) => {
        const value = statistics.averageOnCallSessionLengthInSeconds[key];

        if (value === null) return 'N/A';

        return `${durationInSecondsAsHoursString(value)} h`;
    }

    return (
        <CategoryCard
            headline="On Call Session AVG"
            statistics={{
                day: formattedValueForKey('day'),
                week: formattedValueForKey('week'),
                month: formattedValueForKey('month'),
                quarter: formattedValueForKey('quarter'),
                year: formattedValueForKey('year'),
            }}
        />
    );
};
