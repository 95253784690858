/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';

function ModalWithBackdrop({ children, onClickBackdrop }) {
    return (
        <div
            onClick={onClickBackdrop}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(50,50,50,0.75)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 99,
            }}
        >
            <div
                onClick={(event) => event.stopPropagation()}
                style={{
                    display: 'flex',
                    width: '90vw',
                    maxWidth: 1200,
                    maxHeight: '90vh',
                    justifyContent: 'center',
                }}
            >
                {children}
            </div>
        </div>
    );
}

export default ModalWithBackdrop;
