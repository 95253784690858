import React from 'react';
const matchAll = require('string.prototype.matchall');
matchAll.shim();


function buildPieces(text, possibleMentionees) {
    const pieces = [];
    const re = new RegExp(`(${possibleMentionees.join("|")})`, 'gi');
    const matches = text.matchAll(re);
    let pos = 0;
    for (let match of matches) {
        const index = match.index;
        if (pos !== index) {
            pieces.push({highlight: false, text: text.substr(pos, index - pos)});
        }
        pieces.push({highlight: true, text: match[1]});
        pos = index + match[1].length;
    }
    if (pos < text.length) {
        pieces.push({highlight: false, text: text.substr(pos)});
    }
    return pieces;
}

function MentionText({text, possibleMentionees}) {
    const pieces = buildPieces(text, possibleMentionees);

    return (
        <div>
            {pieces.map((item, inx) => {
                if (item.highlight) {
                    return <span key={inx} style={{color: '#0079bf'}}>@{item.text}</span>;
                } else {
                    return item.text;
                }
            })}
        </div>
    );
}

export default MentionText;
