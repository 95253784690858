/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import Modal from '../components/modal.js';

const Dialog = ({ children, onClose, title, allowOverflow }) => {
    return (
        <Modal>
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(49, 49, 49, 0.54)',
                    // backgroundColor: 'rgba(64, 64, 64, 0.46)',
                    // rgba(0, 0, 0, 0.19)
                }}
            >
                <div
                    style={{
                        backgroundColor: 'rgb(236, 236, 236)',
                        width: 400,
                        borderRadius: 5,
                        border: '1px solid rgb(200, 200, 200)',
                        boxShadow: 'rgb(160, 160, 160) 2px -2px 20px',
                        overflow: allowOverflow ? null : 'hidden',
                    }}
                >
                    <div
                        style={{
                            padding: 5,
                            display: 'flex',
                            // justifyContent: 'flex-end',
                            backgroundColor: 'rgb(200, 200, 200)',
                            // borderTopLeftRadius: 5,
                            // borderTopRightRadius: 5,
                        }}
                    >
                        <div style={{ flex: 1, fontSize: '90%', fontWeight: 'bold', color: '#333' }}>
                            {title}
                        </div>
                        {onClose && (
                            <div onClick={onClose}>
                                <i className="black close link icon" />
                            </div>
                        )}
                    </div>
                    <div style={{ padding: 10 }}>
                        {children}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Dialog;
