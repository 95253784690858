/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Spinner from './spinner.js';
import Center from './center.js';
import API from '../common/api.js';

function iconClassNameForFileType(type) {
    switch (type) {
        case 'PNG':
            return 'file image outline icon';
        default:
            return 'file alternate outline icon';
    }
}

const DiscardButton = ({ discard, fieldName }) => {
    const confirmDiscard = () => {
        const isConfirmed = window.confirm(
            `Do you want to discard your changes to "${fieldName}"? This action can not be undone.`
        );

        if (isConfirmed) discard();
    };

    return (
        <button
            onClick={confirmDiscard}
            style={{
                border: 'none',
                background: 'none',
                fontSize: '70%',
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'rgb(65, 79, 4)',
            }}
            disabled={!confirmDiscard}
        >
            Discard
        </button>
    )
};

function AttachmentImg({ attachment, collapsed }) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageBlob, setImageBlob] = useState(null);

    const loadImageBlob = useCallback(async () => {
        const imageBlob = await API.getLeadDocument(attachment.salesforceContentDocumentId);

        setImageBlob(imageBlob);
        setImageLoaded(true);
    }, [attachment.salesforceContentDocumentId]);

    useEffect(() => {
        if (collapsed) return;

        setImageLoaded(false);

        loadImageBlob();
    }, [attachment, collapsed, loadImageBlob]);

    if (collapsed) return null;

    if (!imageLoaded) {
        return (
            <div style={{ marginTop: 30, marginBottom: 20 }}>
                <Center>
                    <Spinner />
                </Center>
            </div>
        );
    }

    return (
        <div style={{ marginTop: 10 }}>
            <img
                src={imageBlob}
                width="100%"
                alt={attachment.title}
            />
        </div>
    );
}

function LeadAttachment({ attachment, isLocal, discard }) {
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        setCollapsed(true);
    }, [attachment]);

    return (
        <div style={{ background: isLocal ? 'rgb(254, 255, 204)' : 'rgb(240, 240, 240)', padding: 10, borderRadius: 3 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className={`large ${iconClassNameForFileType(attachment.fileType)}`}/>
                <button
                    className="tiny ui right labeled icon button"
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <i className={`${collapsed ? 'plus' : 'minus'} square icon`} />
                    {attachment.title}
                </button>
                {isLocal && (
                    <DiscardButton discard={discard} fieldName={attachment.title} />
                )}
            </div>
            {isLocal ? (
                <>
                    {!collapsed && (
                        <div style={{ marginTop: 10 }}>
                            <img
                                src={attachment.base64String}
                                width="100%"
                                alt={attachment.title}
                            />
                        </div>
                    )}
                </>
            ) : (
                <AttachmentImg attachment={attachment} collapsed={collapsed} />
            )}
        </div>
    )
}

const UploadForm = ({ addLocalAttachment }) => {
    const inputRef = useRef();

    const onAttachmentSelected = (event) => {
        const file = event.target.files[0];

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener('load', function () {
            addLocalAttachment({
                title: file.name,
                base64String: this.result,
            });
        });

        inputRef.current.value = "";
    };

    return (
        <div className="ui form" style={{paddingLeft: '3.3em'}}>
            <div className="field">
                <label>Add Attachment</label>
                <input
                    type="file"
                    ref={inputRef}
                    onChange={onAttachmentSelected}
                    style={{width: '50%', marginLeft: 25}}
                    disabled={!addLocalAttachment}
                />
            </div>
        </div>
    )
};

function AttachmentsSection({ attachments, loadingAttachments, localAttachments, addLocalAttachment, removeLocalAttachment }) {
    return (
        <div
            className="content"
            style={{ backgroundColor: '#F8F8F8', marginTop: '3em', marginBottom: '2em', borderTop: 'none' }}
        >
            <h3 className="ui header">
                <i className="paperclip icon"/> Attachments
            </h3>
            <div style={{ paddingLeft: '3.3em' }}>
                {attachments && (
                    <ul style={{listStyle: 'none', paddingLeft: 0}}>
                        {attachments.map((attachment, inx) => (
                            <li key={inx} className="mb-2">
                                <LeadAttachment attachment={attachment} />
                            </li>
                        ))}
                    </ul>
                )}
                {localAttachments && (
                    <ul style={{listStyle: 'none', paddingLeft: 0}}>
                        {localAttachments.map((attachment, inx) => (
                            <li key={inx} className="mb-2">
                                <LeadAttachment
                                    attachment={attachment}
                                    isLocal
                                    discard={removeLocalAttachment ? () => removeLocalAttachment(attachment.id) : null}
                                />
                            </li>
                        ))}
                    </ul>
                )}
                {loadingAttachments && (
                    <Center>
                        <Spinner />
                    </Center>
                )}
            </div>
            <div style={{ marginTop: 10, paddingLeft: '3.3em' }}>
                <UploadForm addLocalAttachment={addLocalAttachment} />
            </div>
        </div>
    );
}

export default AttachmentsSection;
