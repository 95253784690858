export const MATTERS_TAB = "Matters";
export const TASKS_TAB = "Tasks";
export const LEADS_TAB = "Leads";
export const LEADS_CR_TAB = "Leads (CR)";
export const EMAILS_TAB = "Emails";
export const PUSH_NOTIFS_TAB = "Push Notifs";
export const SMS_TAB = "SMS";
export const AUTOMATIONS_TAB = "Automations";
export const DEVICES_TAB = "Devices";
export const SETTINGS_TAB = "Settings";
export const QUEUE_TAB = "Queue";
export const IDME_TRACKING_TAB = "ID.me tracking";
export const INBOX_TAB = "Inbox";
export const SCANS_TAB = "Scans";
export const TASKS_TODO_TAB = "To Do";
export const TASKS_DOING_TAB = "Doing";
export const TASKS_BLOCKED_TAB = "Blocked";
export const TASKS_DONE_TAB = "Done";
export const INBOX_MESSAGES_TAB = "Messages";
export const INBOX_SCANS_TAB = "Scans"
export const INBOX_CHATS_TAB = "Chats"
export const LEADS_CR_DENIED_TAB = "Denied";
export const LEADS_CR_OPPORTUNITIES_TAB = "Opportunities";
export const LEADS_CR_CONSULTATIONS_TAB = "Consultations";
export const LEADS_CR_QUEUED_TAB = "Queued";
export const DASHBOARD_TAB = "Dashboard";
export const SETTINGS_GENERAL_TAB = "Settings/General";
export const SETTINGS_INTERVIEWS_TAB = "Settings/Interviews";
export const SETTINGS_DYNAMIC_CONTENT_TAB = "Settings/DynamicContent";

export const CLIENT_PHONE_HEARING_NA = 2681584;
export const CLIENT_PHONE_HEARING_REQUESTED = 2681599;
export const CLIENT_PHONE_HEARING_APPROVED = 2681629;
export const CLIENT_PHONE_HEARING_DENIED = 2681614;

export const EDD_CREDENTIALS_VERIFIED = 2650489;
export const EDD_CREDENTIALS_UNVERIFIED = 2650504;
export const EDD_CREDENTIALS_PASSWORD_CHANGED = 2650519;
export const EDD_CREDENTIALS_NEED_TO_REGISTER_CLAIM = 2650534;
export const EDD_CREDENTIALS_CANNOT_REGISTER_CLAIM = 2650549;
export const EDD_CREDENTIALS_VERIFIED_REOPEN_REQUIRED = 2650564;
export const EDD_CREDENTIALS_VERIFIED_CERT_REQUIRED = 2650579;
export const EDD_CREDENTIALS_VERIFIED_FOR_HEARING = 2665849;
export const EDD_CREDENTIALS_TRUST_DEPOSIT = 2665864;
export const EDD_CREDENTIALS_NA = 2927014;
export const EDD_CREDENTIALS_ADDRESS_CHANGED = 2684434;
export const EDD_CREDENTIALS_LABELS = {
    2650489: "Verified",
    2650504: "Unverified",
    2650519: "Password Changed",
    2650534: "Need To Register Claim",
    2650549: "Cannot Register Claim",
    2650564: "Verified Reopen Required",
    2650579: "Verified Cert Required",
    2665849: "Verified For Hearing",
    2665864: "Trust Deposit",
    2927014: "N/A",
    2684434: "Address Changed"
};

export const CONTINGENCY_YES = 2135134;
export const CONTINGENCY_NO = 2135149;

export const CONVERSATION_PARTICPANT_TYPE_MATTER_OWNER = 'MatterOwner'
export const CONVERSATION_PARTICPANT_TYPE_USER = 'User'

export const DEBIT_CARD_VERIFICATION_ORDER_NEW_CARD = 2626294;
export const DEBIT_CARD_VERIFICATION_NEW_CARD_ORDERED = 2626309;
export const DEBIT_CARD_VERIFICATION_VERIFIED = 2626279;
export const DEBIT_CARD_VERIFICATION_UNVERIFIED = 2626264;
export const DEBIT_CARD_VERIFICATION_NEW_CARD_RECEIVED = 2626324;
export const DEBIT_CARD_VERIFICATION_NO_CARD = 2626339;
export const DEBIT_CARD_VERIFICATION_CHECKS = 2628859;
export const DEBIT_CARD_VERIFICATION_CANCEL_DIRECT_DEPOSIT = 2646154;
export const DEBIT_CARD_VERIFICATION_VERIFIED_FOR_HEARING = 2646184;
export const DEBIT_CARD_VERIFICATION_CARD_SHIPPED_BY_BOA = 2684419;
export const DEBIT_CARD_VERIFICATION_NA = 2684449;
export const DEBIT_CARD_VERIFICATION_LABELS = {
    2626294: "Order new card",
    2626309: "New card ordered",
    2626279: "Verified",
    2626264: "Unverified",
    2626324: "New Card Received",
    2626339: "No Card",
    2628859: "Checks",
    2646154: "Cancel Direct Deposit",
    2646184: "Verified for Hearing",
    2684419: "Card Shipped by BOA",
    2684449: "N/A"
};

export const TASK_STATUS_TODO = 1;
export const TASK_STATUS_DOING = 2;
export const TASK_STATUS_DONE = 3;
export const TASK_STATUS_BLOCKED = 4;
export const TASK_STATUS_LABELS = {
    1: "To Do",
    2: "Doing",
    3: "Done",
    4: "Blocked"
};


export const TASK_PRIO_OVERDUE = 1;
export const TASK_PRIO_CRITICAL = 2;
export const TASK_PRIO_HIGH = 3;
export const TASK_PRIO_NORMAL = 4;
export const TASK_PRIO_LOW = 5;
export const TASK_PRIO_LABELS = {
    1: "Overdue",
    2: "Critical",
    3: "High",
    4: "Normal",
    5: "Low"
};
export const TASK_PRIO_COLORS = {
    1: "purple",
    2: "red",
    3: "orange",
    4: "yellow",
    5: "teal"
};
export const LEAD_PRIO_LABELS = {
    0: "Other",
    1: "Urgent",
    2: "High",
    3: "Important",
    4: "Standard",
    5: "Low",
};
export const LEAD_PRIO_COLORS = {
    1: "purple",
    2: "red",
    3: "orange",
    4: "yellow",
    5: "teal"
};

export const SORT_HIGHEST_PRIORITY_FIRST = 1;
export const SORT_LOWEST_PRIORITY_FIRST = 2;
export const SORT_OLDEST_TASK_FIRST = 3;
export const SORT_NEWEST_TASK_FIRST = 4;
export const SORT_BY_MESSAGE_TYPE = 5;
export const SORT_UNREAD_FIRST = 6;
export const SORT_BY_PRIORITY_ASC = 'priorityasc';
export const SORT_BY_PRIORITY_DESC = 'prioritydesc';
export const SORT_BY_CONSULT_FIELDS_UDPATED_AT_ASC = 'consultfieldsupdatedatasc';
export const SORT_BY_CONSULT_FIELDS_UDPATED_AT_DESC = 'consultfieldsupdatedatdesc';

export const CALL_TYPES = {
    CONTACT_LEAD: 'contact-lead',
    JOIN_WAITING_LEAD: 'join-waiting-lead',
};

export const CALL_PURPOSES = {
    CONSULTATION: 'consultation',
    POST_CONSULTATION: 'post-consultation',
};

export const LEAD_EVENT_TYPES = {
    CONSULT_RECOMMENDATION_UPDATE: 'ConsultRecommendationUpdate',
    CALL_LOG: 'CallLog',
    TEXT_MESSAGE: 'TextMessage',
    OPPORTUNITY_UPDATE: 'OpportunityUpdate',
    CALL_SCHEDULED: 'CallScheduled',
    SCHEDULED_CALL_RESOLVED: 'ScheduledCallResolved',
    SCHEDULE_CALL_REQUESTED: 'ScheduleCallRequested',
};

export const LEAD_SCHEDULED_CALLS_STATUSES = {
    DONE: { label: 'Done', value: 'done' },
    UNANSWERED: { label: 'Unanswered', value: 'unanswered' },
    CANCELED: { label: 'Canceled', value: 'canceled' },
}

export const DYNAMIC_CONTENT_BLOCK_TYPES = {
    VIDEO: 'VideoDynamicContentBlocks',
    PDF: 'PdfDynamicContentBlocks',
    TEXT: 'TextDynamicContentBlocks',
};

export const FORM_POST_HEARING_REPORT = 1;
export const FORM_VERIFY_APPEAL = 2;
export const FORM_OBSTRUCTION_REVIEW = 3;
export const FORM_LIMITATIONS_REMINDER = 4;
export const FORM_REOPEN_REMINDER = 5;
export const FORM_REOPEN_VERIFICATION = 6;
export const FORM_PREP_MEETING = 7;
export const FORM_VERIFY_CONTINUATION = 8;
export const FORM_PHONE_HEARING_REQUEST = 9;
export const FORM_ORDER_DEBIT_CARD = 10;
export const FORM_VERIFY_DEBIT_CARD = 11;
export const FORM_NON_APPEAL_REPORT = 12;
export const FORM_CLAIM_REOPEN_REQUIRED = 13;
export const FORM_CLAIM_CERTIFICATION_REQUIRED = 14;
export const FORM_REGISTER_CLAIM = 15;
export const FORM_CANNOT_REGISTER_CLAIM = 16;
export const FORM_EDD_PASSWORD_CHANGE = 17;
export const FORM_CALL_CLIENT_FOR_ID_ME_VERIFICATION = 18;

export const FORM_TYPE_LABELS = {
    1: "Post Hearing Report",
    2: "Verify Appeal",
    3: "Obstruction Review",
    4: "Limitations Reminder",
    5: "Re-open Reminder",
    6: "Re-open Verification",
    7: "Prep Meeting",
    8: "Verify Continuation",
    9: "Phone Hearing Request",
    10: "Order Debit Card",
    11: "Verify Debit Card",
    12: "Non-Appeal Report",
    13: "Claim Re-open Required",
    14: "Claim Certification Required",
    15: "Register Claim",
    16: "Cannot Register Claim",
    17: "EDD Password Change"
};


export const MESSAGE_PRIO_URGENT = 3;
export const MESSAGE_PRIO_NORMAL = 4;
export const MESSAGE_PRIO_LABELS = {
    3: "Urgent",
    4: "Normal"
};
export const MESSAGE_PRIO_COLORS = {
    3: "red",
    4: "yellow"
};
export const MESSAGE_KIND_CALL = 1;
export const MESSAGE_KIND_VOICEMAIL = 2;
export const MESSAGE_KIND_FAX = 3;
export const MESSAGE_KIND_NOTICE = 4;
export const MESSAGE_KIND_TASK_MENTION = 5;
export const MESSAGE_KIND_INBOX_MESSAGE_MENTION = 6;
export const MESSAGE_KIND_SCAN_MENTION = 7;

export const MENTIONEE_KIND_USER = 1;
export const MENTIONEE_KIND_TEAM = 2;

export const POW_KIND_TASK = 1;
export const POW_KIND_MESSAGE = 2;
export const POW_KIND_SCAN = 3;
export const POW_KIND_LEAD = 4;

export const MESSAGE_KIND_LABELS = {
    1: "Call",
    2: "Voicemail",
    3: "Fax",
    4: "Notice",
    5: "Mention"
};
export const MESSAGE_KIND_ICONS = {
    1: "edit outline",
    2: "file audio outline",
    3: "fax",
    4: "bell outline",
    5: "comment alternate outline"
}

export const READ_STATUS_UNREAD = 1;
export const READ_STATUS_READ = 2;
export const READ_STATUS_ANY = 3;

export const SCAN_ACTION_HISTORY_UPLOADED_TO_CLIO = 1;
export const SCAN_ACTION_HISTORY_CONVERTED_TO_TASK = 2;
export const SCAN_ACTION_HISTORY_PROCESSED = 3;


export const TEAM_ATTORNEYS = "Attorneys";
export const TEAM_CASE_MANAGEMENT = "Case Management";

export const LEAD_FIELDS_LABELS = {
    status: 'Status',
    reasonForDenial: 'Reason For Denial',
    phoneNumber: 'Phone',
    mobileNumber: 'Mobile',
    benefitsType: 'Benefits Type',
    caseType: 'Case Type',
    caseStatus: 'Case Status',
    hearingDateTime: 'Hearing Date',
    receivedEddDeterminationDate: 'Notice of Determination received on',
    appealDeadline: 'Appeal Deadline',
    contingency: 'Contingency',
    consultNote: 'Consultation Notes',
    consultRecommendation: 'Recommendation',
    assigneeId: 'Assignee',
};

export const NOTIFICATION_TYPES = {
    LEAD_SCHEDULED_CALL_DUE: 'LeadScheduledCallDue',
    LEAD_COMMENT_MENTION: 'LeadCommentMention',
    SCAN_COMMENT_MENTION: 'ScanCommentMention',
    TASK_COMMENT_MENTION: 'TaskCommentMention',
    INBOX_MESSAGE_COMMENT_MENTION: 'InboxMessageCommentMention',
    ASSIGNED_TO_LEAD: 'AssignedToLead',
    ASSIGNED_TO_TASK:  'AssignedToTask',
    TEAM_ASSIGNED_TO_TASK: 'TeamAssignedToTask',
    NEW_CALL_ON_QUEUE: 'NewCallOnQueue',
    LEAD_RECOMMENDATION_CHANGED: 'LeadRecommendationChanged',
    TEAM_CONVERSATION_ASSIGNEE_UPDATED: 'TeamConversationAssigneeUpdated',
    NEW_MESSAGE: 'NewMessage',
}

export const INTERVIEW_NODE_TYPES = {
    SINGLE_CHOICE_QUESTION: 'SingleChoiceQuestion',
    MULTIPLE_CHOICE_QUESTION: 'MultipleChoiceQuestion',
    CREATE_DOCUMENT_REQUEST: 'CreateDocumentRequest',
    CONDITIONAL: 'Conditional',
};

export const CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS = {
    AND: 'AND',
    OR: 'OR',
};

// On-call statuses
export const ON_CALL_STATUS_ON_CALL = 'oncall';
export const ON_CALL_STATUS_OFF_CALL = 'offcall';
export const ON_CALL_STATUS_TEMPORARILY_OFF_CALL = 'temporarilyoffcall';


// Following PubSub topics are local within the client
export const PUBSUB_TOPIC_OPENED_TASK = "Opened Task";
export const PUBSUB_TOPIC_PINNED_TASK = "Pinned Task";
export const PUBSUB_TOPIC_UNPINNED_TASK = "Unpinned Task";

// Following PubSub topics are being pushed by the server, via the SSE channel
export const PUBSUB_ALL_SERVER_TOPICS = "server";

export const PUBSUB_TOPIC_INBOX = "server.inbox";
export const PUBSUB_TOPIC_INBOX_MESSAGE = "server.inbox.message";
export const PUBSUB_TOPIC_INBOX_MESSAGE_ADDED = "server.inbox.message.added";
export const PUBSUB_TOPIC_INBOX_MESSAGE_UPDATED = "server.inbox.message.updated";
export const PUBSUB_TOPIC_INBOX_MESSAGE_DELETED = "server.inbox.message.deleted";
export const PUBSUB_TOPIC_INBOX_MESSAGE_COMMENT_ADDED = "server.inbox.message.comment.added";

export const PUBSUB_TOPIC_INBOX_SCAN = "server.inbox.scan";
export const PUBSUB_TOPIC_INBOX_SCAN_ADDED = "server.inbox.scan.added";
export const PUBSUB_TOPIC_INBOX_SCAN_UPDATED = "server.inbox.scan.updated";
export const PUBSUB_TOPIC_INBOX_SCAN_DELETED = "server.inbox.scan.deleted";
export const PUBSUB_TOPIC_INBOX_SCAN_COMMENT_ADDED = "server.inbox.scan.comment.added";

export const PUBSUB_TOPIC_TASK = "server.task";
export const PUBSUB_TOPIC_TASK_ADDED = "server.task.added";
export const PUBSUB_TOPIC_TASK_UPDATED = "server.task.updated";
export const PUBSUB_TOPIC_TASK_DELETED = "server.task.deleted";
export const PUBSUB_TOPIC_TASK_ATTACHMENT_ADDED = "server.task.attachment.added";
export const PUBSUB_TOPIC_TASK_ATTACHMENT_DELETED = "server.task.attachment.deleted";
export const PUBSUB_TOPIC_TASK_CHECKLIST_ADDED = "server.task.checklist.added";
export const PUBSUB_TOPIC_TASK_CHECKLIST_DELETED = "server.task.checklist.deleted";
export const PUBSUB_TOPIC_TASK_CHECKLIST_ITEM_ADDED = "server.task.checklist.item.added";
export const PUBSUB_TOPIC_TASK_CHECKLIST_ITEM_UPDATED = "server.task.checklist.item.updated";
export const PUBSUB_TOPIC_TASK_CHECKLIST_ITEM_DELETED = "server.task.checklist.item.deleted";
export const PUBSUB_TOPIC_TASK_COMMENT_ADDED = "server.task.comment.added";

export const PUBSUB_TOPIC_LEAD = "server.lead";
export const PUBSUB_TOPIC_LEAD_CALL_LOGS_UPDATED = "server.lead.call_logs.updated";
export const PUBSUB_TOPIC_LEAD_SCHEDULED_CALLS_UPDATED = "server.lead.scheduled_calls.updated";
export const PUBSUB_TOPIC_LEAD_WAITING_IN_CONVERSATION_UPDATED = "server.lead.waiting_in_vonage_conversation.updated";
export const PUBSUB_TOPIC_LEAD_CONSULT_RECOMMENDATION_UPDATES_UPDATED = "server.lead.consult_recommendation_updates.updated";
export const PUBSUB_TOPIC_LEAD_TEXT_MESSAGES_UPDATED = "server.lead.text_messages.updated";
export const PUBSUB_TOPIC_LEAD_SCHEDULE_CALL_BACK_REQUESTS_UPDATED = "server.lead.schedule_call_back_requests.updated";
export const PUBSUB_TOPIC_LEAD_UPDATED = "server.lead.updated";

export const PUBSUB_TOPIC_CONVERSATIONS_MESSAGES_ADDED = "server.conversations.messages.added";
export const PUBSUB_TOPIC_CONVERSATIONS_MESSAGES_READ = "server.conversations.messages.read";

export const PUBSUB_TOPIC_ONLINE = "server.online";
export const PUBSUB_TOPIC_PINNED_TASKS_UPDATED = "server.pinned_tasks.updated";
export const PUBSUB_TOPIC_RECENTLY_OPENED_TASKS_UPDATED = "server.recently_opened_tasks.updated";
export const PUBSUB_TOPIC_MENTIONS = "server.mentions";
export const PUBSUB_TOPIC_MENTIONS_UPDATED = "server.mentions.updated";

export const PUBSUB_TOPIC_NOTIFICATIONS = "server.notifications";
export const PUBSUB_TOPIC_NOTIFICATIONS_UPDATED = "server.notifications.updated";

export const PUBSUB_TOPIC_ON_CALL_STATUS_UPDATED = "server.on_call_status.updated";
