import React, { useContext } from 'react';
import * as Constants from '../../common/webconstants.js';
import {CurrentUserContext} from '../../common/currentUserContext.js';
import PostHearingReport from '../../components/taskforms/posthearingreport.js';
import VerifyAppeal from '../../components/taskforms/verifyappeal.js';
import ObstructionReview from '../../components/taskforms/obstructionreview.js';
import LimitationsReminder from '../../components/taskforms/limitationsreminder.js';
import ReopenReminder from '../../components/taskforms/reopenreminder.js';
import VerifyReopen from '../../components/taskforms/verifyreopen.js';
import PrepMeeting from '../../components/taskforms/prepmeeting.js';
import VerifyContinuation from '../../components/taskforms/verifycontinuation.js';
import PhoneHearingRequest from '../../components/taskforms/phonehearingrequest.js';
import OrderDebitCard from '../../components/taskforms/orderdebitcard.js';
import VerifyDebitCard from '../../components/taskforms/verifydebitcard.js';
import NonAppealReport from '../../components/taskforms/nonappealreport.js';
import ClaimReopenRequired from '../../components/taskforms/claimreopenrequired.js';
import ClaimCertificationRequired from '../../components/taskforms/claimcertificationrequired.js';
import RegisterClaim from '../../components/taskforms/registerclaim.js';
import CannotRegisterClaim from '../../components/taskforms/cannotregisterclaim.js';
import PasswordChange from '../../components/taskforms/passwordchange.js';


function UnknownFormType({formType}) {
    return (
        <div className="ui error message">Error: Unknown form type {formType}!</div>
    );
}

function FormFactory({formType, caseNumber, extraData, initialFormState, completedAt, markTaskDone, markTaskDoing}) {
    const currentUser = useContext(CurrentUserContext);
    const commonProps = {caseNumber, initialFormState, completedAt, markTaskDone, markTaskDoing, username: currentUser.username, extraData: (JSON.parse(extraData) || {})};

    switch(formType) {
        case Constants.FORM_POST_HEARING_REPORT:
            return <PostHearingReport initialAttorneyName={currentUser.username} {...commonProps}/>;
        case Constants.FORM_VERIFY_APPEAL:
            return <VerifyAppeal {...commonProps}/>;
        case Constants.FORM_OBSTRUCTION_REVIEW:
            return <ObstructionReview {...commonProps}/>;
        case Constants.FORM_LIMITATIONS_REMINDER:
            return <LimitationsReminder {...commonProps}/>;
        case Constants.FORM_REOPEN_REMINDER:
            return <ReopenReminder {...commonProps}/>;
        case Constants.FORM_REOPEN_VERIFICATION:
            return <VerifyReopen {...commonProps}/>;
        case Constants.FORM_PREP_MEETING:
            return <PrepMeeting {...commonProps}/>;
        case Constants.FORM_VERIFY_CONTINUATION:
            return <VerifyContinuation {...commonProps}/>;
        case Constants.FORM_PHONE_HEARING_REQUEST:
            return <PhoneHearingRequest {...commonProps}/>;
        case Constants.FORM_ORDER_DEBIT_CARD:
            return <OrderDebitCard {...commonProps}/>;
        case Constants.FORM_VERIFY_DEBIT_CARD:
            return <VerifyDebitCard {...commonProps}/>;
        case Constants.FORM_NON_APPEAL_REPORT:
            return <NonAppealReport {...commonProps}/>;
        case Constants.FORM_CLAIM_REOPEN_REQUIRED:
            return <ClaimReopenRequired {...commonProps}/>;
        case Constants.FORM_CLAIM_CERTIFICATION_REQUIRED:
            return <ClaimCertificationRequired {...commonProps}/>;
        case Constants.FORM_REGISTER_CLAIM:
            return <RegisterClaim {...commonProps}/>;
        case Constants.FORM_CANNOT_REGISTER_CLAIM:
            return <CannotRegisterClaim {...commonProps}/>;
        case Constants.FORM_EDD_PASSWORD_CHANGE:
            return <PasswordChange {...commonProps}/>;
        case Constants.FORM_CALL_CLIENT_FOR_ID_ME_VERIFICATION:
            return <div>Call client.</div>; // Not really a form, but needed to identify the task type for automations
        default:
            return <UnknownFormType formType={formType}/>
    }
}

export default FormFactory;
