import React, { useState, useEffect, useCallback } from 'react';
import SearchBox from '../components/searchbox.js';
import API from '../common/api.js';

function IDMeTrackingPage({allCaseNumbers}) {
    const [loading, setLoading] = useState(true);
    const [tracks, setTracks] = useState([]);
    const [oldest, setOldest] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [errMsg, setErrMsg] = useState(null);
    const [initialSearchValue, setInitialSearchValue] = useState("");

    const loadInitialData = useCallback(async () => {
        const data = await API.getIDMeTracking();
        if (data) {
            setTracks(data.tracks);
            setOldest(data.oldest);
            setHasMore(true);
            setLoading(false);
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadInitialData();
    }, [loadInitialData]);

    async function onLoadMoreClicked() {
        setLoading(true);
        const data = await API.getIDMeTrackingOlderThan(oldest);
        if (data) {
            if (data.tracks.length === 0) {
                setLoading(false);
                setHasMore(false);
            } else {
                const newTracks = tracks.concat(data.tracks);
                setTracks(newTracks);
                setOldest(data.oldest);
                setLoading(false);
            }
        } else {
            setErrMsg("Failed to load more messages from server");
            setLoading(false);
        }
    }

    async function onSearchBoxCommit(caseNumber) {
        if (caseNumber.length === 0) {
            setInitialSearchValue("");
            await loadInitialData();
        } else {
            setLoading(true);
            setHasMore(false);
            setTracks([]);
            setInitialSearchValue(caseNumber);
            const data = await API.getIDMeTrackingForCase(caseNumber);
            if (data) {
                setTracks(data.tracks);
                setOldest(data.oldest);
                setLoading(false);
                setHasMore(false);
            } else {
                setErrMsg("Unable to load data from server");
                setLoading(false);
            }
        }
    }

    const rows = tracks.map((track, inx) => 
        <tr key={inx}>
            <td>{track.timestamp}</td>
            <td className="hoverparent">{track.case_number} <button className="ui basic mini icon button showwhenparenthovered" onClick={() => onSearchBoxCommit(track.case_number)}><i className="search icon"></i></button></td>
            <td>{track.what}</td>
            <td>{track.who}</td>
        </tr>
    );

    let btnclass = "ui black bottom attached button";
    if (loading) {
        btnclass = `${btnclass} loading disabled`;
    }
    if (!hasMore) {
        btnclass = `${btnclass} disabled`;
    }
    const loadMoreButton = <div className={btnclass} tabIndex="0" onClick={onLoadMoreClicked}>{hasMore ? "Load more" : "That's all folks!"}</div>;

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    return (
        <div style={{paddingTop: 10, height: '100%', overflowY: 'auto', paddingRight: 10, paddingBottom: 10}}>
            <SearchBox allPossibleValues={allCaseNumbers} onCommit={onSearchBoxCommit} initialValue={initialSearchValue}/>
            <table className="ui very basic striped table">
                <thead>
                    <tr>
                        <th>Timestamp</th>
                        <th>Matter</th>
                        <th>What</th>
                        <th>Who</th>
                    </tr>
                </thead>
                {errmsgEl}
                <tbody>
                    {rows}
                </tbody>
            </table>
            {loadMoreButton}
        </div>
    );
}

export default IDMeTrackingPage;
