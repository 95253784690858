import React, { useState, useCallback, useEffect } from 'react';
import API from '../common/api.js';
import classNames from 'classnames';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import PhonenumberInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from 'react-phone-number-input'
import "react-phone-number-input/style.css";

function EditFaxPage({faxNumberID}) {
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [phonenumber, setPhonenumber] = useState("");
    const [user, setUser] = useState(null);
    const [team, setTeam] = useState(null);
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    let history = useHistory();
    const valid = phonenumber && isValidPhoneNumber(`${phonenumber}`) && (user !== null || team !== null);

    const loadData = useCallback(async () => {
        const [usersData, teamsData, faxNumberData] = await Promise.all([API.getUsers(), API.getTeams(), API.getFaxNumber(faxNumberID)]);

        if (teamsData) {
            setTeams(teamsData);
        } else {
            setErrMsg("Unable to load team data from server");
        }

        if (usersData) {
            setUsers(usersData);
        } else {
            setErrMsg("Unable to load user data from server");
        }

        if (faxNumberData) {
            setPhonenumber(faxNumberData.phonenumber);
            const u = usersData.find(u => u.user_id === faxNumberData.user_id);
            if (u) {
                setUser({value: u.user_id, label: u.name});
            }
            const t = teamsData.find(t => t.team_id === faxNumberData.team_id);
            if (t) {
                setTeam({value: t.team_id, label: t.name});
            }
        } else {
            setErrMsg("Unable to load fax number data from server");
        }

        setLoading(false);
    }, [faxNumberID]);

    useEffect(() => {
        loadData();
    }, [loadData]);
    
    async function save() {
        setSaving(true);
        const success = await API.editFaxNumber(faxNumberID, phonenumber, user?.value, team?.value);
        if (success) {
            history.push("/settings");
        } else {
            setErrMsg("ERROR: Saving fax number failed! Please retry.");
            setSaving(false);
        }
    }

    
    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;
    const possibleUsers = users && users.map((u) => ({ value: u.user_id, label: u.name }));
    const possibleTeams = teams && teams.map((t) => ({ value: t.team_id, label: t.name }));
    
    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else {
        return (
            <div>
                {errmsgEl}
                <h1 className="ui header">Edit Fax Number</h1>
                <div className="ui form segment">
                    <div className="field">
                        <label htmlFor="name">Phone number:</label>
                        <div className="w-88">
                            <PhonenumberInput country="US" value={phonenumber} onChange={setPhonenumber} />
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="email">Assign to user:</label>
                        <Select
                            options={possibleUsers}
                            placeholder="Select a user"
                            className="w-88"
                            value={user}
                            onChange={(option) => setUser(option)}
                            isClearable={true}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Assign to team:</label>
                        <Select
                            options={possibleTeams}
                            placeholder="Select a team"
                            className="w-88"
                            value={team}
                            onChange={(option) => setTeam(option)}
                            isClearable={true}
                        />
                    </div>
                    
                    <button className={classNames("ui primary button", {loading: saving, disabled: !valid})} onClick={save}>Save</button>
                    <button className="ui black basic button" onClick={() => history.push("/settings")}>Cancel</button>
                </div>
            </div>
        );
    }
}

export default EditFaxPage;
