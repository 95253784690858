import { useCallback, useState } from 'react';
import API from './api.js';

const useArchiveConversation = ({ onConversationArchived } = {}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const archiveConversation = useCallback(async (conversationId) => {
        setErrors([]);
        setLoading(true);

        const creationResult = await API.archiveConversation(conversationId);

        if (creationResult) {
            if (creationResult.status === 'SUCCESS') {
                if (onConversationArchived) onConversationArchived();
            } else {
                setErrors(creationResult.errors);
            }
        }

        setLoading(false);
    }, [onConversationArchived]);

    return {
        errors,
        archiveConversation,
        loading,
    };
};

export default useArchiveConversation;
