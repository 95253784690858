import { useMemo, useCallback } from "react";
import { findIndex } from "lodash";

function useLeftRightSiblingLink(collection, currentItem, idField, pathPrefix) {
    const composeUrl = useCallback((inx) => `${pathPrefix}${collection[inx][idField]}`, [
        pathPrefix,
        collection,
        idField,
    ]);

    const { leftSiblingLink, rightSiblingLink } = useMemo(() => {
        if (currentItem === null || collection === null) {
            return { leftSiblingLink: null, rightSiblingLink: null };
        } else {
            const inx = findIndex(collection, (i) => i[idField] === currentItem[idField]);
            const leftSiblingLink = inx > 0 ? composeUrl(inx - 1) : null;
            const rightSiblingLink = inx < collection.length - 1 ? composeUrl(inx + 1) : null;
            return { leftSiblingLink, rightSiblingLink };
        }
    }, [collection, currentItem, idField, composeUrl]);

    return [leftSiblingLink, rightSiblingLink];
}

export default useLeftRightSiblingLink;
