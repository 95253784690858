import React, { useCallback } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';

function PostHearingReport({caseNumber, initialAttorneyName, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        attorneyName: initialAttorneyName,
        notes: "",
        status: "Pending",
    });
    
    const saveForm = useCallback(async (matterID) => {
        return await API.savePostHearingReport(formState.attorneyName, formState.notes, formState.status, matterID);
    }, [formState.attorneyName, formState.notes, formState.status]);

    const possibleStatus = ["Open", "Closed", "Pending"];
    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save Post Hearing Report and Update Case Status" saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <p>Please provide a detailed report of what happened in the hearing. Unless you state otherwise, the system will set the status to ‘Pending’ after submission.</p>
            <div className="field">
                <label htmlFor="attorneyname">Name of attorney who did the hearing:</label>
                <input type="text" name="attorneyname" value={formState.attorneyName} onChange={ev => updateFormState({attorneyName: ev.target.value})} placeholder="Enter name..." required/>
            </div>
            <div className="field">
                <label htmlFor="detail">Notes:</label>
                <textarea type="text" name="detail" value={formState.notes} onChange={ev => updateFormState({notes: ev.target.value})} placeholder="Enter notes..." rows="10" required></textarea>
            </div>
            <div className="field">
                <label htmlFor="status">Update the case status to:</label>
                <Dropdown options={possibleStatus} onChange={option => updateFormState({status: option.value})} value={formState.status}/>
            </div>
        </TaskForm>
    );
}

export default PostHearingReport;
