import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Assignee from "../components/assignee.js";
import ScanCard from "../components/scancard.js";
import { useHistory } from "react-router-dom";
import Modal from "../components/modal.js";
import useLeftRightSiblingLink from "../common/useLeftRightSiblingLink.js";
import "../pages/scanspage.css";
import { formatHumanDateTime } from "../common/formatting.js";
var classNames = require("classnames");

function placeholderRow(scan) {
    return (
        <tr key={scan} className="dim">
            <td className="border-none" colSpan={4}>
                {scan}
            </td>
        </tr>
    );
}

function LoadMoreRow({ paging, onClick }) {
    return (
        <tr>
            <td className="border-none" colSpan={4}>
                <button className={classNames("ui compact fluid button", { loading: paging })} onClick={onClick}>
                    Load more...
                </button>
            </td>
        </tr>
    );
}

function InboxArchivePageScans({
    loading,
    paging,
    hasMore,
    loadMore,
    errMsg,
    scans,
    possibleAssignees,
    initialExpandedScanID,
    setAssigneeFilter,
    height,
    allCaseNumbers,
}) {
    const [expandedScan, setExpandedScan] = useState(null);
    let history = useHistory();

    useEffect(() => {
        if (initialExpandedScanID) {
            const scanToExpand = scans.find((scan) => scan.scan_id === initialExpandedScanID);
            if (scanToExpand) {
                setExpandedScan(scanToExpand);
            }
        } else {
            setExpandedScan(null);
        }
    }, [scans, initialExpandedScanID]);

    const [leftSiblingLink, rightSiblingLink] = useLeftRightSiblingLink(
        scans,
        expandedScan,
        "scan_id",
        "/inbox/scans/archive/"
    );

    function scansRow(scn, inx, prefix) {
        // eslint-disable-next-line
        const assignee = (
            <Assignee
                task={scn}
                onClick={(name) => {
                    setAssigneeFilter(possibleAssignees.find((a) => a.label === name));
                }}
            />
        );
        const timestamp = formatHumanDateTime(scn.created_at);
        const casenum = scn.case_number && ` - ${scn.case_number}`;
        const actionButtons = (
            <div className="ui small basic icon buttons scans-scan-action-buttons">
                <Link
                    to={`/add_task?scanID=${scn.scan_id}`}
                    className="ui button"
                    data-tooltip="Convert to task"
                    data-position="top center"
                >
                    <i className="tasks icon" />
                </Link>
                <Link
                    to={`/upload?scanID=${scn.scan_id}`}
                    className="ui button"
                    data-tooltip="Upload to Clio"
                    data-position="top center"
                >
                    <i className="upload icon" />
                </Link>
            </div>
        );
        return (
            <tr key={`${prefix}${inx}`} className="scans-scan-row">
                <td
                    className={classNames("w-full align-top", {
                        "border-none": inx === 0,
                        "font-bold": !scn.was_read && !scn.acknowledged,
                    })}
                >
                    <Link to={`/inbox/scans/archive/${scn.scan_id}`}>{scn.filename}</Link>
                    <br />
                    <span className="scans-scan-timestamp">
                        {timestamp}
                        {casenum}
                    </span>
                </td>
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {actionButtons}
                </td>
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {(scn.assignee || scn.team) && (
                        /* eslint-disable-next-line */
                        <a href="#" className="ui grey icon label">
                            {assignee}
                        </a>
                    )}
                </td>
            </tr>
        );
    }

    const scnRows = scans.map((scn, inx) => scansRow(scn, inx, "scan_"));

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{ marginTop: 20 }}></div>
            </div>
        );
    } else {
        return (
            <>
                {errmsgEl}

                {expandedScan && (
                    <Modal>
                        <ScanCard
                            scan={expandedScan}
                            onCloseClicked={() => {
                                history.push("/inbox/scans/archive");
                            }}
                            possibleAssignees={possibleAssignees}
                            allCaseNumbers={allCaseNumbers}
                            leftSiblingLink={leftSiblingLink}
                            rightSiblingLink={rightSiblingLink}
                            readOnly
                        />
                    </Modal>
                )}

                <div
                    style={{
                        flexFlow: 1,
                        overflowY: "auto",
                        paddingTop: 20,
                        paddingRight: 20,
                        height: height,
                    }}
                >
                    <div className="ui visible info message" style={{ zIndex: -1, marginTop: 20 }}>
                        This page shows the 100 most recently processed scans, in reverse chronological order. You can
                        load the next batch of 100 scans at the bottom of the page.
                    </div>

                    <table className="ui very basic table">
                        <tbody>
                            {scnRows.length > 0 ? scnRows : placeholderRow("No scans processed yet...")}
                            {hasMore && <LoadMoreRow paging={paging} onClick={loadMore} />}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default InboxArchivePageScans;
