import { useState, useEffect, useCallback } from 'react';
import API from './api.js';

const useMessagesSearch = ({ page, pageSize, conversationsFilters, query }) => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadData = useCallback(async () => {
        if (!query || query.trim().length === 0) return;

        setLoading(true);

        const selectedConversationsFilters = {};
        if (conversationsFilters.caseNumberEq) {
            selectedConversationsFilters.case_number_eq = conversationsFilters.caseNumberEq;
        }
        const response = await API.searchMessages({
            query,
            pagination: { page, pageSize },
            conversationsFilters: selectedConversationsFilters,
        });

        setMatches(response || []);

        setLoading(false);
    }, [page, pageSize, conversationsFilters, query]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        matches,
        searchInProgress: loading,
        reload: loadData,
    };
};

export default useMessagesSearch;
