import React from 'react';
import {get} from 'lodash';
import useHover from '../common/useHover.js';
import CopyToClipboardIconButton from "../components/copytoclipboardiconbutton.js";


function EDDCredentialsMessage({matterInfo}) {
    const [hoverRef, isHovered] = useHover();
    const eddLoginEmail = get(matterInfo, 'custom_fields.edd_login_email.value');
    const eddPassword = get(matterInfo, 'custom_fields.edd_password.value');

    const passwordSpan = isHovered ?
        <span>{eddPassword}</span> :
        <span>{"•".repeat(eddPassword?.length)}</span>;

    const portalLink = <a className="ui mini compact basic button" href="https://portal.edd.ca.gov/WebApp/Login?resource_url=https%3A%2F%2Fportal.edd.ca.gov%2FWebApp%2FHome" target="_blank" rel="noopener noreferrer">Open EDD Portal &nbsp; <i className="fitted external alternate icon"/></a>;

    if (eddLoginEmail && eddPassword) {
        return (
            <div className="ui visible info message" ref={hoverRef}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <b>EDD Login Credentials:</b> {eddLoginEmail} <CopyToClipboardIconButton value={eddLoginEmail}/> &nbsp; &mdash; &nbsp; {passwordSpan} <CopyToClipboardIconButton value={eddPassword}/>
                    </div>
                    <div>
                        {portalLink}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="ui visible warning message">
                <p><b>EDD Login Credentials Unknown!</b> Please contact client.</p>
            </div>
        );
    }
}

export default EDDCredentialsMessage;
