import { useState, useEffect, useCallback } from 'react';
import API from '../common/api.js';

const useAttorneyStatistics = ({ attorneyUserId }) => {
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [initialized, setInitialized] = useState(false);

    const loadData = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        const response = await API.getAttorneyStatistics(attorneyUserId);

        if (response) {
            setStatistics(response);
            setInitialized(true);
        } else {
            setErrorMessage(
                'Failed to load the statistics for this Attorney. Please try again or report this problem, if it persists.'
            );
        }

        setLoading(false);
    }, [attorneyUserId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        statistics,
        loading,
        errorMessage,
        initialized,
        reload: loadData,
    };
};

export default useAttorneyStatistics;
