import React from 'react';

function CaseNumber({caseNumber, style}) {
    if (!caseNumber) return null;
    if (caseNumber.length === 0) return null;

    return (
        <span style={style}>
            <i className="folder icon"/> {caseNumber}
        </span>
    );
}

export default CaseNumber;