/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useCallback } from 'react';
import useLocalStorage from '../common/useLocalStorage.js';

const useRecentlyViewedLeads = () => {
    const [recentlyViewedLeads, setRecentlyViewedLeads] = useLocalStorage('leadspage.recentlyViewedLeads', []);
    const reportLeadViewed = useCallback((lead) => {
        if (recentlyViewedLeads.length > 0 && recentlyViewedLeads[0].id === lead.id) return;

        let newRecentlyViewedLeads = [...recentlyViewedLeads];

        // Ensure that, if this `lead` has an appearance in this list already, it is being removed.
        newRecentlyViewedLeads = newRecentlyViewedLeads.filter((recentlyViewedLead) => recentlyViewedLead.id !== lead.id);

        // Add the `lead` to the beginning of the list.
        newRecentlyViewedLeads.unshift({ id: lead.id, name: lead.name });

        // Ensure the list doesn't contain any more than 5 entries.
        newRecentlyViewedLeads = newRecentlyViewedLeads.slice(0, 5);

        setRecentlyViewedLeads(newRecentlyViewedLeads);
    }, [recentlyViewedLeads, setRecentlyViewedLeads]);

    return {
        recentlyViewedLeads,
        reportLeadViewed,
    };
};

export default useRecentlyViewedLeads;
