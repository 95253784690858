import React, { Component } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';

function makeMentionEditorState(editorState) {
    return {
        editorState,
        getMentionees: () => {
            const rawEditorContent = convertToRaw(editorState.getCurrentContent());
            const entityMap = rawEditorContent.entityMap;
            const mentionedUsers = Object.values(entityMap).map(entity => entity.data.mention);
            return mentionedUsers;
        },
        getText: () => editorState.getCurrentContent().getPlainText()
    }
}


export default class MentionEditor extends Component {

  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin();

    if (props.value === null) {
        props.onChange(makeMentionEditorState(EditorState.createEmpty()));
    }
  }

  state = {
    suggestions: this.props.mentionables,
  };

  componentDidUpdate(prevProps, prevState) {
      if (this.props.value === null && prevProps.value !== null) {
        this.props.onChange(makeMentionEditorState(EditorState.createEmpty()));
      }
  }

  onChange = (editorState) => {
      this.props.onChange(makeMentionEditorState(editorState));
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.props.mentionables),
    });
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    if (this.props.value === null) return null;

    return (
      <div onClick={this.focus}>
        <Editor
          editorState={this.props.value.editorState}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          plugins={plugins}
          ref={(element) => { this.editor = element; }}
          readOnly={this.props.readOnly}
        />
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
        />
      </div>
    );
  }
}
