/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useRef } from 'react';
import AnimateHeight from "react-animate-height";
var classNames = require('classnames');

const LeadsPageLayout = ({
    tabs,
    showFilterBar,
    sideBarVisible,
    children,
    actionButtons,
    filterBar,
    sideBar,
    headline,
}) => {
    const filterBarRef = useRef(null);

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div>
                {headline && (
                    <h1 className="ui header" style={{ marginTop: 10 }}>
                        {headline}
                    </h1>
                )}
                <div
                    className="ui secondary pointing menu"
                    style={{ paddingBottom: 8, paddingTop: 10, marginBottom: 0, background: 'white' }}
                >
                    {tabs}
                    <div className="right menu">
                        {actionButtons}
                    </div>
                </div>
            </div>
            <AnimateHeight
                duration={250}
                height={showFilterBar ? "auto" : 0}
            >
                <div className="filter-bar" ref={filterBarRef}>
                    {filterBar}
                </div>
            </AnimateHeight>
            <div style={{ flex: 1, display: 'flex', overflowY: 'hidden' }}>
                <div
                    style={{
                        flex: 1,
                        flexGrow: 1,
                        overflowY: 'scroll',
                        paddingTop: 20,
                        paddingRight: 20,
                        height: '100%',
                    }}
                >
                    {children}
                </div>
                {sideBar && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'scroll',
                            height: '100%',
                            paddingRight: 10,
                            width: 200,
                        }}
                        className={classNames({hidden: !sideBarVisible})}
                    >
                        <div className="dividing rightbar" style={{ maxWidth: 250 }}>
                            {sideBar}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LeadsPageLayout;
