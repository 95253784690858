/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import Card from './card';

function ModalMainCard({ title, header, footer, onClickClose, children }) {
    const closeButtonStyle = {
        position: 'absolute',
        right: 10,
        top: 10,
        padding: 7,
    };
    const headerFieldsStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingRight: 30,
        alignItems: 'center',
    };
    const titleFieldStyle = {
        flexGrow: 1,
    };

    return (
        <Card
            header={
                <>
                    <div style={{...headerFieldsStyle, marginBottom: '0.5em'}}>
                        <span style={closeButtonStyle} onClick={onClickClose}><i className="fitted black close link icon"/></span>
                        <div style={headerFieldsStyle}>
                            <div style={titleFieldStyle}>
                                <h2 className="mr-4">{title}</h2>
                            </div>
                        </div>
                    </div>
                    {header && (
                        <div style={{...headerFieldsStyle, paddingRight: 0}}>
                            {header}
                        </div>
                    )}
                </>
            }
            footer={footer}
            stretchWide
        >
            {children}
        </Card>
    )
}

export default ModalMainCard;
