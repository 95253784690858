/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useMemo } from 'react';
import LeadHistoricalEventRow from './leadhistoricaleventsrow.js';
import { LEAD_EVENT_TYPES } from '../../common/webconstants.js';

const HistoricalEvents = ({ historicalEvents, allUsers, opportunityModelDescription }) => {
    const groupedHistoricalEvents = useMemo(() => {
        const callLogEvents = [];
        const eventsSections = [];

        historicalEvents.forEach((historicalEvent) => {
            if (historicalEvent.type === LEAD_EVENT_TYPES.CALL_LOG) {
                if (callLogEvents.length === 0) {
                    eventsSections.push(callLogEvents);
                }

                callLogEvents.push(historicalEvent);
            } else {
                eventsSections.push([historicalEvent]);
            }
        });

        return eventsSections;
    }, [historicalEvents]);

    if (historicalEvents.length === 0) {
        return (
            <span style={{ fontStyle: 'italic', color: '#959595', fontSize: '95%' }}>No recorded events yet</span>
        );
    }

    return groupedHistoricalEvents.map((historicalEventGroup) => (
        <LeadHistoricalEventRow
            key={historicalEventGroup[0].id}
            historicalEvents={historicalEventGroup}
            allUsers={allUsers}
            opportunityModelDescription={opportunityModelDescription}
        />
    ))
};

export default HistoricalEvents;
