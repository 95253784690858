import React from 'react';
import PlaceholderAvatar from '../../common/placeholderavatar.js';

const UserPicture = ({ pictureSrc, name, size }) => {
    return (
        <div
            style={{
                width: size,
                height: size,
                borderRadius: size,
                overflow: 'hidden',
            }}
        >
            {pictureSrc ? (
                <img
                    src={pictureSrc}
                    alt="User"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            ) : (
                <PlaceholderAvatar name={name} />
            )}
        </div>
    );
};

export default UserPicture;
