/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';

const BannerLayout = ({ backgroundColor, headline, headlineColor, children }) => {
    return (
        <div
            style={{
                backgroundColor,
                padding: 2,
                borderRadius: 3,
            }}
        >
            <div style={{ display: 'flex', gap: 10, alignItems: 'center', flexWrap: 'wrap', fontSize: '90%' }}>
                <div>
                    <span
                        style={{
                            color: headlineColor,
                            fontWeight: 'bold',
                            fontSize: '102%',
                        }}
                    >
                        {headline}
                    </span>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default BannerLayout;
