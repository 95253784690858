import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import API from '../../common/api.js';
import { isEqual } from 'lodash';
import {WorkflowButtonParentContext} from '../../common/workflowButtonParentContext.js';
var classNames = require('classnames');


function useAutoMarkTaskDoing({formState, markTaskDoing}) {
    const [referenceFormState] = useState(formState);
    const [markedDoing, setMarkedDoing] = useState(false);

    useEffect(() => {
        if (markedDoing) return;
        if (! isEqual(referenceFormState, formState)) {
            markTaskDoing();
            setMarkedDoing(true);
        }
    }, [referenceFormState, formState, markTaskDoing, markedDoing]);
}

function SaveAndSubmitButton({disabled, onClick, busy}) {
    const parentEl = useContext(WorkflowButtonParentContext);
    const button = busy ? 
        <div style={{display: 'flex'}}>Saving &nbsp; <div className="ui tiny active centered inline loader"/></div> :
        <div className={classNames("ui compact primary button", {disabled})} onClick={onClick} data-tooltip="Save the form data to the server and mark this task as done" data-position="top right">Save and Submit</div>;
    if (parentEl) {
        return ReactDOM.createPortal(button, parentEl);
    } else {
        return null;
    }
}


function TaskForm({caseNumber, disableSaveButton, saveForm, markTaskDone, markTaskDoing, formState, children, extraInfo}) {
    const [saving, setSaving] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const formEl = useRef(null);
    useAutoMarkTaskDoing({formState, markTaskDoing});

    const handleSubmit = useCallback(async () => {
        setErrMsg(null);

        if (formEl.current) {
            if (formEl.current.reportValidity()) {
                setSaving(true);

                const matterIDResponse = await API.getMatterID(caseNumber);
                if (matterIDResponse) {
                    const {success, msg} = await saveForm(matterIDResponse.matterID);
                    if (success) {
                        markTaskDone(msg, JSON.stringify(formState));
                        return true;
                    } else {
                        setErrMsg(msg);
                    }
                }

                setSaving(false);
            }
        }
        return false;
    }, [caseNumber, formState, markTaskDone, saveForm]);

    return (
        <form className="ui form" ref={formEl}>
            {children}
            {errMsg && <div className="ui visible error message">{errMsg}</div>}
            <SaveAndSubmitButton disabled={disableSaveButton} onClick={handleSubmit} busy={saving}/>
            {extraInfo}
        </form>
    );
}



export default TaskForm;
