import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
var classNames = require('classnames');

function PasswordChange({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        passwordVerified: null,
        newPassword: "",
        notes: ""
    });
    
    const saveForm = useCallback(async (matterID) => {
        return await API.savePasswordChange(formState.passwordVerified, formState.newPassword, formState.notes, username, matterID);
    }, [formState.passwordVerified, formState.newPassword, formState.notes, username]);

    function formIsComplete() {
        if (formState.passwordVerified === null) return false;
        if (formState.passwordVerified) {
            return true;
        } else {
            return formState.notes !== "";
        }
    }

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={!formIsComplete()} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.passwordVerified === true})} onClick={() => { updateFormState({passwordVerified: true}) }}>Password Verified</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.passwordVerified === false})} onClick={() => { updateFormState({passwordVerified: false}) }}>Client Not Cooperating</div>
                </div>
            </div>

            {formState.passwordVerified === true && (
                <>
                    <p>If you have not already done so, please update the password in the matter by entering it here, then click 'Save and Submit'.<br/>
                    If you've already updated the password in Clio, just leave the New Password field blank, but clicking 'Save and Submit' is still required.</p>
                    
                    <div className="field six wide">
                        <label htmlFor="newpwd">New Password:</label>
                        <input type="text" name="newpwd" value={formState.newPassword} onChange={ev => updateFormState({newPassword: ev.target.value})} placeholder="Enter new password..."/>
                    </div>
                </>
            )}

            {formState.passwordVerified === false && (
                <>
                    <p>Please describe what efforts were made to obtain password.</p>
                    
                    <div className="field six wide">
                        <label htmlFor="notes">Describe efforts:</label>
                        <textarea type="text" name="notes" value={formState.notes} onChange={ev => updateFormState({notes: ev.target.value})} placeholder="Enter notes..." rows="5"></textarea>
                    </div>
                </>
            )}
        </TaskForm>
    );
}

export default PasswordChange;
