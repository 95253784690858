import React, { useState, useEffect } from "react";
import * as Constants from "../common/webconstants.js";
import { Link } from "react-router-dom";
import Assignee from "../components/assignee.js";
import MessageCard from "../components/messagecard.js";
import { useHistory } from "react-router-dom";
import Modal from "../components/modal.js";
import "../pages/inboxpage.css";
import { formatHumanDateTime } from "../common/formatting.js";
var classNames = require("classnames");


function placeholderRow(msg) {
    return (
        <tr key={msg} className="dim">
            <td className="border-none" colSpan={4}>
                {msg}
            </td>
        </tr>
    );
}

function LoadMoreRow({ paging, onClick }) {
    return (
        <tr>
            <td className="border-none" colSpan={4}>
                <button className={classNames("ui compact fluid button", { loading: paging })} onClick={onClick}>
                    Load more...
                </button>
            </td>
        </tr>
    );
}


function InboxArchivePageMessages({ loading, paging, hasMore, loadMore, errMsg, messages, allMessageTypes, possibleAssignees, initialExpandedMsgID, setAssigneeFilter, setMessageTypeFilter, height, allCaseNumbers }) {
    const [expandedMessage, setExpandedMessage] = useState(null);
    let history = useHistory();

    useEffect(() => {
        if (initialExpandedMsgID) {
            const msgToExpand = messages.find((msg) => msg.msg_id === initialExpandedMsgID);
            if (msgToExpand) {
                setExpandedMessage(msgToExpand);
            }
        } else {
            setExpandedMessage(null);
        }
    }, [messages, initialExpandedMsgID]);

    function inboxRow(msg, inx, prefix) {
        const msgKind = (
            <span
                className="ui horizontal icon label"
                style={{ width: "100%", textAlign: "left", cursor: "pointer" }}
                onClick={() => {
                    setMessageTypeFilter(allMessageTypes.find((m) => m.value === msg.kind));
                }}
            >
                <i className={`${Constants.MESSAGE_KIND_ICONS[msg.kind]} icon`} style={{ width: 12 }} />{" "}
                {Constants.MESSAGE_KIND_LABELS[msg.kind]}
            </span>
        );
        const priorityLabel = (
            <div
                className={`ui ${Constants.MESSAGE_PRIO_COLORS[msg.priority]} horizontal label`}
                style={{ width: "100%", marginTop: 4 }}
            >
                {Constants.MESSAGE_PRIO_LABELS[msg.priority]}
            </div>
        );
        // eslint-disable-next-line
        const assignee = (
            <Assignee
                task={msg}
                onClick={(name) => {
                    setAssigneeFilter(possibleAssignees.find((a) => a.label === name));
                }}
            />
        );
        const timestamp = formatHumanDateTime(msg.created_at);
        const casenum = msg.case_number && ` - ${msg.case_number}`;
        const actionButtons = (
            <div className="ui small basic icon buttons inbox-message-action-buttons">
                {msg.acknowledged && (
                    <Link
                        to={`/rework_inbox_message?messageID=${msg.msg_id}`}
                        className="ui button"
                        data-tooltip="Rework message"
                        data-position="left center"
                    >
                        <i className="recycle icon" />
                    </Link>
                )}
                <Link
                    to={`/add_task?messageID=${msg.msg_id}`}
                    className="ui button"
                    data-tooltip="Convert to task"
                    data-position="right center"
                >
                    <i className="tasks icon" />
                </Link>
            </div>
        );
        return (
            <tr key={`${prefix}${inx}`} className="inbox-message-row">
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {msgKind}
                    <br />
                    {msg.priority === Constants.MESSAGE_PRIO_URGENT && priorityLabel}
                </td>
                <td className={classNames("w-full align-top", { "border-none": inx === 0 })}>
                    <Link to={`/inbox/messages/archive/${msg.msg_id}`}>{msg.subject}</Link>
                    <br />
                    <span className="inbox-message-timestamp">
                        {timestamp}
                        {casenum}
                    </span>
                </td>
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {actionButtons}
                </td>
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {(msg.assignee || msg.team) && (
                        /* eslint-disable-next-line */
                        <a href="#" className="ui grey icon label">
                            {assignee}
                        </a>
                    )}
                </td>
            </tr>
        );
    }

    const msgRows = messages.map((msg, inx) => inboxRow(msg, inx, "done_"));

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{ marginTop: 20 }}></div>
            </div>
        );
    } else {
        return (
            <>
                {errmsgEl}

                {expandedMessage && (
                    <Modal>
                        <MessageCard
                            message={expandedMessage}
                            onCloseClicked={() => {
                                history.push("/inbox/messages/archive");
                            }}
                            possibleAssignees={possibleAssignees}
                            allCaseNumbers={allCaseNumbers}
                            readOnly
                        />
                    </Modal>
                )}

                <div style={{
                    flexFlow: 1,
                    overflowY: 'auto',
                    paddingTop: 20,
                    paddingRight: 20,
                    height: height
                }}>
                    <div className="ui visible info message" style={{ zIndex: -1, marginTop: 20 }}>
                        This page shows the 100 most recently processed messages, in reverse chronological order. You
                        can load the next batch of 100 messages at the bottom of the page.
                        <br />
                        <br />
                        If you filter on case number, it shows <i>all</i> processed messages for that case.
                    </div>

                    <table className="ui very basic table">
                        <tbody>
                            {msgRows.length > 0 ? msgRows : placeholderRow("No messages processed yet...")}
                            {hasMore && <LoadMoreRow paging={paging} onClick={loadMore} />}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default InboxArchivePageMessages;
