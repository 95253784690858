/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';

function Spinner() {
    return (
        <div className="ui active inline loader" style={{ zIndex: 1 }} />
    )
}

export default Spinner;
