import React, { useState, useCallback, useEffect, useContext } from 'react';
import API from '../common/api.js';
import classNames from 'classnames';
import * as Constants from '../common/webconstants.js';
import SearchBox from '../components/searchbox.js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import usePrevious from '../common/usePrevious.js';
import {CurrentUserContext} from '../common/currentUserContext.js';
import { useHistory } from "react-router-dom";
import useInboxMessageData from '../common/useInboxMessageData.js';
import withLoader from '../common/withLoader.js';


function RequiredFieldIndicator() {
    return (
        <span style={{color: 'red'}}>*</span>
    );
}

function ReworkInboxMessagePage({users, teams, allCaseNumbers, initializeFromMessageID}) {
    const initialPriority = {value: Constants.MESSAGE_PRIO_NORMAL, label: Constants.MESSAGE_PRIO_LABELS[Constants.MESSAGE_PRIO_NORMAL]};
    const [loading, setLoading] = useState(false);
    const [loadingClientData, setLoadingClientData] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState(initialPriority);
    const [caseNumber, setCaseNumber] = useState(null);
    const [assignee, setAssignee] = useState(null);
    const [nameOfCaller, setNameOfCaller] = useState("");
    const [callbackNumber, setCallbackNumber] = useState("");
    const [kind, setKind] = useState(null);
    const [senderNumber, setSenderNumber] = useState("");
    const [taskID, setTaskID] = useState(null);
    const [uri, setURI] = useState(null);
    const previousCaseNumber = usePrevious(caseNumber);
    const currentUser = useContext(CurrentUserContext);
    let history = useHistory();

    const possiblePriorities = [
        Constants.MESSAGE_PRIO_NORMAL, Constants.MESSAGE_PRIO_URGENT
    ].map(p => ({value: p, label: Constants.MESSAGE_PRIO_LABELS[p]}));

    useInboxMessageData(initializeFromMessageID, setLoading, setErrMsg, useCallback(message => {
        setSubject(message.subject);
        setDescription(`Please rework [this message](/inbox/messages/archive/${initializeFromMessageID}).\n\n${message.description || ""}`);
        const priority = possiblePriorities.find(p => p.value === message.priority);
        if (priority) {
            setPriority(priority);
        }
        setCaseNumber(message.case_number);
        setNameOfCaller(message.name_of_caller);
        setCallbackNumber(message.callback_number);
        setKind(message.kind);
        setSenderNumber(message.senderNumber);
        setTaskID(message.task_id);
        setURI(message.uri);
        const user = users.find(u => u.user_id === message.assignee_id);
        if (user) {
            setAssignee({value: `user:${user.user_id}`, label: user.name});
        }
        const tm = teams.find(t => t.team_id === message.team_id);
        if (tm) {
            setAssignee({value: `team:${tm.team_id}`, label: tm.name});
        }
    }, [setSubject, setDescription, setPriority, setCaseNumber, setAssignee, setKind, setSenderNumber, setTaskID, setURI, teams, users, initializeFromMessageID, possiblePriorities]));

    useEffect(() => {
        if (currentUser === null) {
            const team = teams.find(t => t.name === Constants.TEAM_CASE_MANAGEMENT);
            if (team) {
                setAssignee({value: `team:${team.team_id}`, label: team.name});
            }
        }
    }, [currentUser, teams]);
    
    const valid = (subject !== "") && (nameOfCaller !== "") && (assignee !== null);

    const loadClient = useCallback(async () => {
        setLoadingClientData(true);

        const data = await API.getCaseStatus(caseNumber);
        if (data) {
            const client = await API.getContactInfo(data.info.client_id);
            if (nameOfCaller === "") {
                setNameOfCaller(client.name);
            }
            if (callbackNumber === "") {
                setCallbackNumber(client.phone);
            }
            setLoadingClientData(false);
            
        } else {
            setLoadingClientData(false);
        }
    }, [caseNumber, setLoadingClientData, nameOfCaller, callbackNumber]);

    useEffect(() => {
        if (caseNumber !== previousCaseNumber && caseNumber !== null && caseNumber !== "") {
            loadClient();
        }
    }, [caseNumber, previousCaseNumber, loadClient]);

    async function save(ev) {
        setSaving(true);
        setErrMsg(null);
        const [assignee_kind, assignee_id] = assignee ? assignee.value.split(":") : [null, null];
        const authorName = currentUser ? currentUser.username : "Unknown";
        let newMessage = await API.createInboxMessage(kind, {
            subject, 
            description, 
            priority: priority.value, 
            caseNumber, 
            assignee_id: (assignee_kind === 'user' ? assignee_id : null), 
            team_id: (assignee_kind === 'team' ? assignee_id : null), 
            name_of_caller: nameOfCaller, 
            callback_number: callbackNumber, 
            sender_number: senderNumber,
            uri,
            task_id: taskID,
            author_name: authorName
        });
        if (newMessage) {
            history.push(`/inbox/messages/${newMessage.msg_id}`);
        } else {
            setErrMsg("ERROR: Saving message failed! Please retry.");
            setSaving(false);
        }
    }

    const errmsgEl = errMsg ? <div className="ui visible error message">{errMsg}</div> : null;
    
    const possibleAssignees = users.map(u => ({value: `user:${u.user_id}`, label: u.name})).concat(teams.map(t => ({value: `team:${t.team_id}`, label: t.name})));
    const prioritySelect = <Dropdown options={possiblePriorities} placeholder="Select a priority" onChange={option => setPriority(option)} value={priority}/>;
    const assigneeSelect = <Dropdown options={possibleAssignees} placeholder="Assign to a person or a team" onChange={option => setAssignee(option)} value={assignee}/>;
    const caseNumberSearchbox = <SearchBox allPossibleValues={allCaseNumbers} onCommit={caseNumber => setCaseNumber(caseNumber)} initialValue={caseNumber || ""} placeholder="Enter case number"/>;
    const clientDataDisclosureIndicator =
        loadingClientData ? <div className="ui mini active inline loader"/> : null;
    
    return withLoader(loading, errMsg, () => (
        <div style={{height: '100%', overflowY: 'auto', paddingRight: 10, paddingBottom: 10}}>
            <h1 className="ui header">Rework Message</h1>
            <div className="ui form segment" style={{background: '#fafafa'}}>
                <div className="field six wide">
                    <label>Message Type</label>
                    {kind !== null && Constants.MESSAGE_KIND_LABELS[kind]}
                </div>
                <div className="field six wide">
                    <label htmlFor="casenumber">Case Number</label>
                    {caseNumberSearchbox}
                </div>
                {(kind === Constants.MESSAGE_KIND_CALL || kind === Constants.MESSAGE_KIND_VOICEMAIL) && (
                    <>
                        <div className="field six wide">
                            <label htmlFor="name">Name of Caller <RequiredFieldIndicator/> {clientDataDisclosureIndicator}</label>
                            <input type="text" name="name" value={nameOfCaller} placeholder="Required" onChange={(ev) => setNameOfCaller(ev.target.value)} required/>
                        </div>
                        <div className="field six wide">
                            <label htmlFor="callbacknumber">Call Back Number {clientDataDisclosureIndicator}</label>
                            <input type="text" name="callbacknumber" value={callbackNumber} placeholder="Optional" onChange={(ev) => setCallbackNumber(ev.target.value)}/>
                        </div>
                    </>
                )}
                {(kind === Constants.MESSAGE_KIND_FAX) && (
                    <div className="field six wide">
                        <label htmlFor="name">Sender Number <RequiredFieldIndicator/> </label>
                        <input type="text" name="name" value={senderNumber} placeholder="Required" onChange={(ev) => setSenderNumber(ev.target.value)} required/>
                    </div>
                )}
                <div className="field">
                    <label htmlFor="subject">Subject <RequiredFieldIndicator/></label>
                    <input type="text" name="subject" value={subject} placeholder="Required" onChange={(ev) => setSubject(ev.target.value)} required/>
                </div>
                <div className="field">
                    <label htmlFor="description">Description</label>
                    <textarea name="description" placeholder="Optional" rows="8" value={description} onChange={ev => setDescription(ev.target.value)}></textarea>
                </div>
                <div className="field six wide">
                    <label htmlFor="priority">Priority</label>
                    {prioritySelect}
                </div>
                <div className="field six wide">
                    <label htmlFor="assignee">Who Is This Message For? <RequiredFieldIndicator/></label>
                    {assigneeSelect}
                </div>
                

                <button className={classNames("ui primary button", {loading: saving, disabled: !valid})} onClick={save}>Save and Close</button>
                {errmsgEl}
            </div>
        </div>
    ));
}

export default ReworkInboxMessagePage;
