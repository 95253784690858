import { useState, useEffect, useCallback } from "react";
import API from "../common/api.js";
import { some } from "lodash";
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';

function augmentScans(data, allUsers, allTeams) {
    return data.map((t) => ({
        ...t,
        assignee: allUsers.find((u) => u.user_id === t.assignee_id),
        team: allTeams.find((team) => team.team_id === t.team_id),
    }));
}

function useScansArchive({ allUsers, allTeams, assigneeFilter, caseNumber, searchText, currentUser }) {
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(false);
    const [scans, setScans] = useState([]);
    const [filteredScans, setFilteredScans] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    usePubSub({topic: Constants.PUBSUB_TOPIC_INBOX_SCAN, messageHandler: handleServerMessage});

    const loadData = useCallback(async () => {
        setScans([]);
        setLoading(true);
        setErrMsg(null);

        let data = await API.getScansArchive();
        setHasMore(true);

        if (data) {
            setScans(augmentScans(data, allUsers, allTeams));
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }

        setLoading(false);
    }, [allUsers, allTeams]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const loadMore = useCallback(async () => {
        setPaging(true);
        setErrMsg(null);

        let data = null;
        data = await API.getScansArchive(scans.length);
        setHasMore(true);
        if (data) {
            if (data.length > 0) {
                setScans(augmentScans(scans.concat(data), allUsers, allTeams));
            } else {
                setHasMore(false);
            }
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }

        setPaging(false);
    }, [allUsers, allTeams, scans]);

    useEffect(() => {
        let data = scans;
        if (assigneeFilter) {
            const filter = Array.isArray(assigneeFilter) ? assigneeFilter : [assigneeFilter];
            if (filter.length > 0) {
                data = data.filter((scan) => {
                    return some(
                        filter.map((f) => {
                            if (f.is_user) {
                                return scan.assignee_id === parseInt(f.id);
                            } else if (f.is_team) {
                                return scan.team_id === parseInt(f.id) && scan.assignee_id === null;
                            } else if (f.id === -1) {
                                return scan.team_id === null && scan.assignee_id === null;
                            } else {
                                return true; // Should never happen
                            }
                        })
                    );
                });
            }
        }
        if (caseNumber !== null && caseNumber.length > 0) {
            data = data.filter((scan) => scan.case_number === caseNumber);
        }
        if (searchText !== null && searchText.length > 0) {
            data = data.filter((scan) => scan.filename.toLowerCase().includes(searchText.toLowerCase()));
        }
        setFilteredScans(data);
    }, [scans, allUsers, allTeams, assigneeFilter, caseNumber, searchText, currentUser.teamMemberOf, currentUser.userID]);

    function handleServerMessage(topic, data) {
        if (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_ADDED) {
            const newScans = [...scans, { ...data.data, was_read: false, read_status_user_id: currentUser.userID }];
            setScans(augmentScans(newScans, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_UPDATED) {
            const newScans = scans.map((scan) => {
                if (scan.scan_id === data.data.scan_id) {
                    if (data.data.read_status_user_id === currentUser.userID) {
                        return { ...data.data };
                    } else {
                        return { ...data.data, was_read: scan.was_read, read_status_user_id: scan.read_status_user_id };
                    }
                }
                return { ...scan };
            });
            setScans(augmentScans(newScans, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_DELETED) {
            const newScans = scans.filter((scan) => scan.scan_id !== parseInt(data.data.scan_id));
            setScans(augmentScans(newScans, allUsers, allTeams));
        }
    }

    return { loading, paging, hasMore, loadMore, errMsg, scans: filteredScans };
}

export default useScansArchive;
