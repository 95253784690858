import { parsePhoneNumberFromString } from 'libphonenumber-js';
var moment = require('moment');
require("../common/moment-timezone-with-data.js");

export function formatPhoneNumberFromString(number, country) {
    try {
        return parsePhoneNumberFromString(number, country).formatNational();
    } catch (e) {
        return number;
    }
}

export function formatHumanDateTime(epoch) {
    return epoch && moment(epoch * 1000).calendar(null, {
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow at] LT',
        nextWeek: 'MM/DD/YYYY [at] LT',
        lastDay: '[Yesterday at] LT',
        lastWeek: '[Last] dddd [at] LT',
        sameElse: 'MM/DD/YYYY [at] LT'
    });
}

export function formatHumandDate(epoch) {
    return epoch && moment(epoch * 1000).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'MM/DD/YYYY',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'MM/DD/YYYY'
    });
}

export function formatDateTimeHumanDateTime(dateTime) {
    return dateTime.calendar(null, {
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow at] LT',
        nextWeek: 'MM/DD/YYYY [at] LT',
        lastDay: '[Yesterday at] LT',
        lastWeek: '[Last] dddd [at] LT',
        sameElse: 'MM/DD/YYYY [at] LT'
    });
}

export function formatDateTimeRange(dateTimeA, dateTimeB) {
    const date = dateTimeA.calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'MM/DD/YYYY',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'MM/DD/YYYY',
    });
    const timeA = dateTimeA.calendar(null, {
        sameDay: 'LT',
        nextDay: 'LT',
        nextWeek: 'LT',
        lastDay: 'LT',
        lastWeek: 'LT',
        sameElse: 'LT',
    });
    const timeB = dateTimeB.calendar(null, {
        sameDay: 'LT',
        nextDay: 'LT',
        nextWeek: 'LT',
        lastDay: 'LT',
        lastWeek: 'LT',
        sameElse: 'LT',
    });

    return `${date} between ${timeA} and ${timeB}`;
}

export function parseIso8601(iso8601DateTime) {
    return moment(iso8601DateTime);
}

export function parseTimestamp(timestamp) {
    if (!timestamp) return null;

    return moment(timestamp * 1000);
}

export function formatShortDate(epoch) {
    return epoch && moment(epoch * 1000).format('MM/DD/YYYY');
}

export const durationAsMinutesString = (fromDateTime, toDateTime) => {
    const durationInSeconds = Math.round(toDateTime.diff(fromDateTime) / 1000);

    return durationInSecondsAsMinutesString(durationInSeconds);
};

export const durationInSecondsAsMinutesString = (durationInSeconds) => {
    return `${Math.floor(durationInSeconds / 60)}:${(durationInSeconds % 60).toString().padStart(2, '0')}`;
};

export const durationInSecondsAsHoursString = (durationInSeconds) => {
    const fullHours = Math.floor(durationInSeconds / 3600);
    const restInSeconds = durationInSeconds % 3600;
    const restInMinutes = Math.round(restInSeconds / 60);

    return `${fullHours}:${restInMinutes.toString().padStart(2, '0')}`;
};
