import React, {useState, useEffect, useCallback} from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';


function PrepMeeting({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        appealVerified: null,
        noteDetail: ""
    });
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState(null);

    const loadCase = useCallback(async () => {
        setLoading(true);

        const data = await API.getCaseStatus(caseNumber);
        if (data) {
            const noteData = await API.getCaseNotes(data.matter_id);
            setNotes(noteData.filter(n => !/^\[.*\]/.test(n.subject)));
            setLoading(false);
            
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, [caseNumber]);

    useEffect(() => {
        loadCase();
    }, [loadCase]);
    
    
    const saveForm = useCallback(async (matterID) => {
        return await API.savePrepMeeting(username, formState.noteDetail, matterID);
    }, [username, formState.noteDetail]);

    const noteRows = notes.map(note => {
        return (
            <tr>
                <td className="four wide top aligned">{note.subject}<br/><span className="dim">{note.notedate}</span></td>
                <td className="twelve wide top aligned"><span style={{whiteSpace: 'pre-wrap'}}>{note.detail}</span></td>  
            </tr>
        );
    });

    const rows = errMsg ?
        <tr><td><div className="ui error message">{errMsg}</div></td></tr> :
        loading ? <tr><td><div className="ui active inline loader"></div></td></tr> :
        noteRows;

    const extraInfo = (
        <>
            <h4 className="ui header">Case Notes</h4>
            <table class="ui very basic table">
                <tbody>
                    {rows}
                </tbody>
            </table>
        </>
    );

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={formState.noteDetail === ""} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt} extraInfo={extraInfo}>
            <p>Please enter your notes from the prep call below:</p>
            <>
                <div className="field">
                    <label htmlFor="note_detail">Notes:</label>
                    <textarea type="text" name="note_detail" value={formState.noteDetail} onChange={ev => updateFormState({noteDetail: ev.target.value})} rows="10" placeholder="Enter your notes..."></textarea>
                </div>
            </>
        </TaskForm>
    );
}

export default PrepMeeting;
