/* eslint jsx-a11y/anchor-is-valid: 0 */ 
import React from 'react';
import * as Constants from '../common/webconstants.js';
import { Link } from "react-router-dom";
import IconDropdown from '../components/icondropdown.js';
var classNames = require('classnames');

function TasksPageTabs({activeTab, setActiveTab, sortOrder, setSortOrder, numTodo, numDoing, numBlocked, filterActive, filterButtonActive, onClickFilterButton, sidebarButtonActive, onClickSidebarButton}) {
    function tab(tabID, title) {
        return (
            <a className={classNames("item", {active: activeTab === tabID})} onClick={() => setActiveTab(tabID)}>
                {title}
            </a>
        );
    }

    const filterButton = (
        <button className={classNames("ui basic icon button", {active: filterButtonActive})} onClick={onClickFilterButton}><i className={classNames("filter icon", {green: filterActive})}/></button>
    );

    const sortOptions = [
        {value: Constants.SORT_HIGHEST_PRIORITY_FIRST, title: "Highest priority first"},
        {value: Constants.SORT_LOWEST_PRIORITY_FIRST, title: "Lowest priority first"},
        {value: Constants.SORT_OLDEST_TASK_FIRST, title: "Oldest task first"},
        {value: Constants.SORT_NEWEST_TASK_FIRST, title: "Newest task first"},
    ];
    const sortButton = (
        <IconDropdown icon="sort amount down" items={sortOptions} value={sortOrder} onChange={(option) => setSortOrder(option)}/>
    );

    const sidebarButton = (
        <button className="ui basic icon button" onClick={onClickSidebarButton}>
            <i className={classNames("icon", {columns: !sidebarButtonActive, 'window maximize outline': sidebarButtonActive})}/>
        </button>
    );

    return (
        <div className="ui secondary pointing menu" style={{paddingBottom: 8, paddingTop: 10, marginBottom: 0, background: 'white'}}>
            {tab(Constants.TASKS_TODO_TAB, `To Do (${numTodo})`)}
            {tab(Constants.TASKS_DOING_TAB, `Doing (${numDoing})`)}
            {tab(Constants.TASKS_BLOCKED_TAB, `Blocked (${numBlocked})`)}
            {tab(Constants.TASKS_DONE_TAB, "Done")}
            
            <div className="right menu">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Link to="/tasks/archive" className="ui basic icon button" style={{marginRight: 10}}><i className="archive icon"/></Link>
                    {sidebarButton}
                    <div className="ui basic icon buttons" style={{marginRight: 10}}>
                        {sortButton}
                        {filterButton}
                    </div>
                    <Link to="/add_task" className="ui labeled primary icon button"><i className="plus icon"/> Add Task</Link>
                </div>
            </div>
        </div>
    );
}


export default TasksPageTabs;
