import { useCallback, useEffect, useState } from 'react';
import API from './api.js';

const useGetInterview = ({ topic }) => {
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [errors, setErrors] = useState([]);
    const [interview, setInterview] = useState(null);

    const loadInterview = useCallback(async () => {
        setErrors([]);
        setLoading(true);

        const result = await API.getInterviewForTopic({ topic });

        if (result) {
            setInterview(result);
            setInitialized(true);
        } else {
            setErrors(['Failed to load interview']);
        }

        setLoading(false);

        return result;
    }, [topic]);

    useEffect(() => {
        loadInterview();
    }, [loadInterview]);

    return {
        interview,
        reload: loadInterview,
        errors,
        loading,
        initialized,
    };
};

export default useGetInterview;
