import React, { useState, useCallback, useEffect } from 'react';
import API from '../common/api.js';
import classNames from 'classnames';
import { useHistory } from "react-router-dom";

function validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function EditUserPage({userID}) {
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [memberships, setMemberships] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    let history = useHistory();
    const valid = name !== "" && email !== "" && validateEmail(email);

    const loadData = useCallback(async () => {
        const [teamsData, userData] = await Promise.all([API.getTeams(), API.getUser(userID)]);
        if (teamsData) {
            setTeams(teamsData);
        } else {
            setErrMsg("Unable to load team data from server");
        }
        if (userData) {
            setName(userData.name);
            setEmail(userData.email);
            setIsAdmin(userData.rights.includes("admin"));
            setMemberships(userData.teams);
        }
        setLoading(false);
    }, [userID]);

    useEffect(() => {
        loadData();
    }, [loadData]);
    
    async function save() {
        setSaving(true);
        const success = await API.editUser(userID, name, email, isAdmin, memberships);
        if (success) {
            history.push("/settings");
        } else {
            setErrMsg("ERROR: Saving user failed! Please retry.");
            setSaving(false);
        }
    }

    function belongsToTeam(team) {
        return memberships.includes(team);
    }

    function toggleTeam(team) {
        if (belongsToTeam(team)) {
            setMemberships(memberships.filter(t => t !== team));
        } else {
            setMemberships([...memberships, team]);
        }
    }
    
    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    const teamCheckboxes = teams && teams.map((team, inx) => {
        return (
            <div className="inline field" key={inx}>
                <div className={`ui checkbox ${belongsToTeam(team.name) ? "checked" : ""}`} onClick={() => toggleTeam(team.name)}>
                    <input type="checkbox" {...{checked: belongsToTeam(team.name)}} onChange={() => {}}/>
                    <label>{team.name}</label>
                </div>
            </div>
        );
    });

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else {
        return (
            <div>
                {errmsgEl}
                <h1 className="ui header">Add New User</h1>
                <div className="ui form segment">
                    <div className="field">
                        <label htmlFor="name">Name:</label>
                        <input type="text" name="name" value={name} placeholder="Enter name" onChange={(ev) => setName(ev.target.value)} required/>
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email:</label>
                        <input type="email" name="email" value={email} placeholder="Enter email" onChange={(ev) => setEmail(ev.target.value)} required/>
                    </div>
                    <div className="inline field">
                        <div className={`ui toggle ${isAdmin ? "checked" : ""} checkbox`} onClick={() => setIsAdmin(!isAdmin)}>
                            <input type="checkbox" tabIndex="0" className="hidden" {...{checked: isAdmin}} onChange={() => {}}/>
                            <label>Give this user administrator rights</label>
                        </div>
                    </div>
                    <div className="grouped fields">
                        <label>Add to teams:</label>
                        {teamCheckboxes}
                    </div>

                    <button className={classNames("ui primary button", {loading: saving, disabled: !valid})} onClick={save}>Save</button>
                    <button className="ui black basic button" onClick={() => history.push("/settings")}>Cancel</button>
                </div>
            </div>
        );
    }
}

export default EditUserPage;
