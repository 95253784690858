import React, { useState, useCallback, useContext } from "react";
import API from "../common/api.js";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import * as Constants from "../common/webconstants.js";
import SearchBox from "../components/searchbox.js";
import Select from "react-select";
import DatePickerDropdown from "../components/datepickerdropdown.js";
import useInboxMessageData from "../common/useInboxMessageData.js";
import useScanData from "../common/useScanData.js";
import useTaskData from "../common/useTaskData.js";
import withLoader from "../common/withLoader.js";
import { CurrentUserContext } from "../common/currentUserContext.js";

function AddTaskPage({
    users,
    teams,
    allCaseNumbers,
    initializeFromMessageID,
    initializeFromTaskID,
    initializeFromScanID,
}) {
    const [errMsg, setErrMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState({
        value: Constants.TASK_PRIO_NORMAL,
        label: Constants.TASK_PRIO_LABELS[Constants.TASK_PRIO_NORMAL],
    });
    const [deadline, setDeadline] = useState(null);
    const [caseNumber, setCaseNumber] = useState(null);
    const [assignee, setAssignee] = useState(null);
    const [team, setTeam] = useState(null);
    const [formType, setFormType] = useState(null);
    const [isWatched, setIsWatched] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const currentUser = useContext(CurrentUserContext);
    let history = useHistory();

    useInboxMessageData(
        initializeFromMessageID,
        setLoading,
        setErrMsg,
        useCallback(
            (message) => {
                setTitle(message.subject);
                setDescription(
                    `(Converted from [this message](/inbox/messages/archive/${initializeFromMessageID})).\n\n${
                        message.description || ""
                    }`
                );
                setCaseNumber(message.case_number);
                const user = users.find((u) => u.user_id === message.assignee_id);
                if (user) {
                    setAssignee({ value: user.user_id, label: user.name });
                }
                const tm = teams.find((t) => t.team_id === message.team_id);
                if (tm) {
                    setTeam({ value: tm.team_id, label: tm.name });
                }
                if (message.uri) {
                    setAttachment({ filename: message.uri.split("/").pop(), msg_id: message.msg_id });
                }
            },
            [
                setTitle,
                setDescription,
                setCaseNumber,
                setAssignee,
                setTeam,
                setAttachment,
                initializeFromMessageID,
                teams,
                users,
            ]
        )
    );

    useTaskData(
        initializeFromTaskID,
        setLoading,
        setErrMsg,
        useCallback(
            (task) => {
                setTitle(task.title);
                setDescription(
                    `Please rework [this task](/tasks/archive/${initializeFromTaskID}).\n\n${task.description}`
                );
                setCaseNumber(task.case_number);
                const user = users.find((u) => u.user_id === task.assignee_id);
                if (user) {
                    setAssignee({ value: user.user_id, label: user.name });
                }
                const tm = teams.find((t) => t.team_id === task.team_id);
                if (tm) {
                    setTeam({ value: tm.team_id, label: tm.name });
                }
                setFormType(task.form_type);
            },
            [
                setTitle,
                setDescription,
                setCaseNumber,
                setAssignee,
                setTeam,
                setFormType,
                initializeFromTaskID,
                teams,
                users,
            ]
        )
    );

    useScanData(
        initializeFromScanID,
        setLoading,
        setErrMsg,
        useCallback(
            (scan) => {
                setDescription(`(Converted from [this scan](/inbox/scans/${initializeFromScanID})).`);
                setAttachment({ ...scan });
            },
            [setAttachment, initializeFromScanID]
        )
    );

    const valid = title !== "" && (assignee !== null || team !== null);
    const returnUrlAfterCancel = initializeFromMessageID
        ? `/inbox/messages/${initializeFromMessageID}`
        : initializeFromTaskID
        ? `/tasks/archive/${initializeFromTaskID}`
        : initializeFromScanID
        ? `/inbox/scans/${initializeFromScanID}`
        : "/tasks";
    const returnUrlAfterSave = initializeFromMessageID
        ? `/inbox/messages?ack=${initializeFromMessageID}`
        : initializeFromTaskID
        ? `/tasks/archive`
        : initializeFromScanID
        ? `/inbox/scans/${initializeFromScanID}`
        : "/tasks";

    async function save() {
        setSaving(true);
        const task = await API.createTask(
            title,
            description,
            priority.value,
            deadline,
            caseNumber,
            assignee && assignee.value,
            team && team.value,
            formType
        );
        if (task) {
            if (isWatched) {
                await API.watchTask(task.task_id);
            }
            if (initializeFromScanID && attachment) {
                await Promise.all([
                    API.copyTaskAttachmentFromScan(task.task_id, attachment.scan_id),
                    API.addScanActionHistoryItem(
                        Constants.SCAN_ACTION_HISTORY_CONVERTED_TO_TASK,
                        initializeFromScanID,
                        currentUser.username,
                        `Created task "${title}"`,
                        `/tasks/${task.task_id}`
                    ),
                ]);
            }
            if (initializeFromMessageID && attachment) {
                await API.copyTaskAttachmentFromInboxMessage(task.task_id, attachment.msg_id);
            }
            history.push(returnUrlAfterSave);
        } else {
            setErrMsg("ERROR: Saving task failed! Please retry.");
            setSaving(false);
        }
    }

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;
    const possiblePriorities = [
        Constants.TASK_PRIO_LOW,
        Constants.TASK_PRIO_NORMAL,
        Constants.TASK_PRIO_HIGH,
        Constants.TASK_PRIO_CRITICAL,
        Constants.TASK_PRIO_OVERDUE,
    ].map((p) => ({ value: p, label: Constants.TASK_PRIO_LABELS[p] }));
    const prioritySelect = (
        <Select
            options={possiblePriorities}
            placeholder="Select a priority"
            onChange={(option) => setPriority(option)}
            value={priority}
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
        />
    );
    const assigneeSelect = (
        <Select
            options={users.map((u) => ({ value: u.user_id, label: u.name }))}
            placeholder="Assign to a user"
            value={assignee}
            onChange={(option) => {
                if (option && option.length === 0) option = null;
                setAssignee(option);
            }}
            isClearable={true}
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
        />
    );
    const teamSelect = (
        <Select
            options={teams.map((t) => ({ value: t.team_id, label: t.name }))}
            placeholder="Assign to a team"
            onChange={(option) => setTeam(option)}
            value={team}
            isClearable={true}
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
        />
    );
    const caseNumberSearchbox = (
        <SearchBox
            allPossibleValues={allCaseNumbers}
            onCommit={(caseNumber) => setCaseNumber(caseNumber)}
            initialValue={caseNumber || ""}
            placeholder="Enter case number..."
        />
    );
    const formDescription = formType && (
        <div className="ui visible info message">
            Note that a blank {Constants.FORM_TYPE_LABELS[formType]} form will be attached to this task automatically.
        </div>
    );
    const attachmentIndicator = attachment && (
        <div className="ui label field">
            <i className={`large paperclip icon`} /> {attachment.filename}
        </div>
    );

    const explainWhyNotValid = "A task needs a title, and must be assigned to either a person or a team (or both).";

    return withLoader(loading, errMsg, () => (
        <div style={{ height: "100%", overflowY: "auto", paddingRight: 10, paddingBottom: 10 }}>
            {errmsgEl}
            <h1 className="ui header">Add New Task</h1>
            <div className="ui form segment">
                <div className="field">
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={title}
                        placeholder="Enter title"
                        onChange={(ev) => setTitle(ev.target.value)}
                        required
                    />
                </div>
                <div className="field">
                    <label htmlFor="description">Description:</label>
                    <textarea
                        name="description"
                        placeholder="Write a description..."
                        rows="8"
                        value={description || ""}
                        onChange={(ev) => setDescription(ev.target.value)}
                    ></textarea>
                    {formDescription}
                </div>
                {attachmentIndicator}
                <div className="field">
                    <label htmlFor="priority">Priority:</label>
                    {prioritySelect}
                </div>
                <div className="field">
                    <label htmlFor="deadline">Due Date:</label>
                    <DatePickerDropdown
                        value={deadline}
                        onChange={(d) => setDeadline(d)}
                        prefix=""
                        nullText="Set due date"
                    />
                </div>
                <div className="field">
                    <label htmlFor="assignee">Assignee:</label>
                    {assigneeSelect}
                </div>
                <div className="field">
                    <label htmlFor="team">Team:</label>
                    {teamSelect}
                </div>
                <div className="field">
                    <label htmlFor="casenumber">Case Number:</label>
                    {caseNumberSearchbox}
                </div>
                <div className="inline field">
                    <div
                        className={`ui toggle ${isWatched ? "checked" : ""} checkbox`}
                        onClick={() => setIsWatched(!isWatched)}
                    >
                        <input
                            type="checkbox"
                            tabIndex="0"
                            className="hidden"
                            {...{ checked: isWatched }}
                            onChange={() => {}}
                        />
                        <label>Watch this task</label>
                    </div>
                </div>

                <div>
                    <button
                        className={classNames("ui primary button", { loading: saving, disabled: !valid })}
                        onClick={save}
                    >
                        Save
                    </button>
                    <button className="ui black basic button" onClick={() => history.push(returnUrlAfterCancel)}>
                        Cancel
                    </button>
                    {valid || (
                        <span className="dim">
                            &nbsp;
                            <i className="exclamation triangle icon" /> {explainWhyNotValid}
                        </span>
                    )}
                </div>
            </div>
        </div>
    ));
}

export default AddTaskPage;
