import React, { useState, useMemo, useRef } from 'react';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
var classNames = require('classnames');

function Pagination({currPage, numPages, prevPage, nextPage}) {
    if (numPages) {
        return (
            <div style={{flexDirection: 'row', display: 'flex', alignItems: 'baseline', marginTop: 8}}>
                <button className={classNames("ui compact icon button", {disabled: (currPage ===  1)})} onClick={prevPage}><i className="chevron left icon"/></button>
                <p style={{marginLeft: 20, marginRight: 20}}>Page {currPage} of {numPages}</p>
                <button className={classNames("ui compact icon button", {disabled: (currPage ===  numPages)})} onClick={nextPage}><i className="chevron right icon"/></button>
            </div>
        )
    }
    return null;
}


function PDFViewer({uri, nocontrols}) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [minHeight, setMinHeight] = useState(1);
    const stableURI = useMemo(() => uri, [uri]);
    const documentWrapper = useRef(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function onPageRendered() {
        setMinHeight(documentWrapper.current.clientHeight);
    }

    function prevPage() {
        if (pageNumber < 2) return;
        setPageNumber(pageNumber - 1);
    }

    function nextPage() {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    return (
        <>
            <div ref={documentWrapper} style={{minHeight}}>
                <Document file={stableURI} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} onRenderSuccess={onPageRendered} height={500}/>
                </Document>
            </div>
            {nocontrols || <Pagination currPage={pageNumber} numPages={numPages} prevPage={prevPage} nextPage={nextPage}/>}
        </>
    );
}

export default PDFViewer;
