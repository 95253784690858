import React from 'react';

function LoginPage() {
    return (
        <div style={{backgroundColor: '#DADADA'}}>
            <div className="ui middle aligned center aligned grid" style={{height: '100vh'}}>
                <div className="column" style={{maxWidth: 450}}>
                    <form className="ui large form segment" style={{padding: 40}} action={`/auth/microsoft_graph?origin=${encodeURI(window.location.pathname)}`} method="post">
                        <h2 className="ui image header">
                            <img src="/icon.png" className="image" alt="logo"/>
                            <div className="content">Log in to your account</div>
                        </h2>
                        
                        <button className="ui primary basic fluid large submit icon button" style={{lineHeight: '1.3em'}} type="submit">
                            <i className="large microsoft icon middle aligned"></i>
                            Log in with Microsoft Account
                        </button>
                        
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
