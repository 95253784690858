import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as Constants from '../../common/webconstants.js';
import { get } from 'lodash';
var classNames = require('classnames');

function RegisterClaim({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        registeredClaim: null,
        credentials: null
    });
    
    const credentials = get(formState, "credentials.value", null);
    const saveForm = useCallback(async (matterID) => {
        return await API.saveRegisterClaim(formState.registeredClaim, credentials, username, matterID);
    }, [formState.registeredClaim, credentials, username]);

    function formIsComplete() {
        if (formState.registeredClaim === null) return false;
        if (formState.registeredClaim) {
            return (formState.credentials !== null);
        } else {
            return true;
        }
    }

    const possibleCredentialsOptions = [
        {value: Constants.EDD_CREDENTIALS_VERIFIED, label: "Verified"},
        {value: Constants.EDD_CREDENTIALS_VERIFIED_REOPEN_REQUIRED, label: "Verified, but requires a re-open"},
        {value: Constants.EDD_CREDENTIALS_VERIFIED_CERT_REQUIRED, label: "Verified, but requires certification"},
    ];

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={!formIsComplete()} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.registeredClaim === true})} onClick={() => { updateFormState({registeredClaim: true}) }}>I registered the claim</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.registeredClaim === false})} onClick={() => { updateFormState({registeredClaim: false}) }}>I cannot register the claim</div>
                </div>
            </div>

            {formState.registeredClaim === true && (
                <>
                    <p>If you were able to register the claim, what is the status of the EDD Online Account?</p>
                    <div className="field six wide">
                        <label htmlFor="credentials">EDD Online Account Status</label>
                        <Dropdown placeholder="Please select" options={possibleCredentialsOptions} onChange={option => updateFormState({credentials: option})} value={formState.credentials}/>
                    </div>
                </>
            )}

            {formState.registeredClaim === false && (
                <>
                    <div className="field">
                        <p>Please confirm you're unable to register the claim by clicking the 'Save and Submit' button below.</p>
                    </div>
                </>
            )}
        </TaskForm>
    );
}

export default RegisterClaim;
