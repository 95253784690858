import { useState, useEffect, useCallback } from "react";
import API from "../common/api.js";
import { orderBy, some } from "lodash";
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';


function augmentScans(data, allUsers, allTeams) {
    return data.map((t) => ({
        ...t,
        assignee: allUsers.find((u) => u.user_id === t.assignee_id),
        team: allTeams.find((team) => team.team_id === t.team_id),
    }));
}

function sortScans(data, sortOrder) {
    if (sortOrder === Constants.SORT_OLDEST_TASK_FIRST) {
        return orderBy(data, ["created_at"], ["asc"]);
    } else if (sortOrder === Constants.SORT_NEWEST_TASK_FIRST) {
        return orderBy(data, ["created_at"], ["desc"]);
    } else if (sortOrder === Constants.SORT_UNREAD_FIRST) {
        return orderBy(data, ["was_read", "created_at"], ["desc", "desc"]);
    } else {
        return data;
    }
}

function useScans({ allUsers, allTeams, assigneeFilter, caseNumber, searchText, readStatusFilter, sortOrder, currentUser }) {
    const [loading, setLoading] = useState(true);
    const [scans, setScans] = useState([]);
    const [sortedScans, setSortedScans] = useState([]);
    const [errMsg, setErrMsg] = useState(null);
    usePubSub({topic: Constants.PUBSUB_TOPIC_INBOX_SCAN, messageHandler: handleServerMessage});

    const loadData = useCallback(async () => {
        setScans([]);
        setLoading(true);
        setErrMsg(null);

        let data = await API.getScans();
        if (data) {
            setScans(augmentScans(data, allUsers, allTeams));
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }
        setLoading(false);
    }, [allUsers, allTeams]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        let data = scans;
        const noFilters =
            assigneeFilter === null && caseNumber === null && searchText === null;
        if (noFilters) {
            // By default, only show scans that are assigned to me, or a team I'm a member of, or I'm mentioned on, or I'm watching
            data = data.filter((scn) => {
                if (scn.assignee_id === currentUser.userID) return true;
                if (currentUser.teamMemberOf.includes(scn.team_id) && scn.assignee_id === null) return true;
                if (scn.total_mention_count > 0) return true;
                if (scn.is_watched_by_current_user > 0) return true;
                return false;
            });
        }
        if (assigneeFilter) {
            const filter = Array.isArray(assigneeFilter) ? assigneeFilter : [assigneeFilter];
            if (filter.length > 0) {
                data = data.filter((scn) => {
                    return some(
                        filter.map((f) => {
                            if (f.is_user) {
                                return scn.assignee_id === parseInt(f.id);
                            } else if (f.is_team) {
                                return scn.team_id === parseInt(f.id) && scn.assignee_id === null;
                            } else if (f.id === -1) {
                                return scn.team_id === null && scn.assignee_id === null;
                            } else {
                                return true; // Should never happen
                            }
                        })
                    );
                });
            }
        }
        if (caseNumber !== null && caseNumber.length > 0) {
            data = data.filter((scn) => scn.case_number === caseNumber);
        }
        if (searchText !== null && searchText.length > 0) {
            data = data.filter((scn) => scn.filename.toLowerCase().includes(searchText.toLowerCase()));
        }
        if (readStatusFilter === Constants.READ_STATUS_UNREAD) {
            data = data.filter(scn => !scn.was_read);
        }
        setSortedScans(sortScans(data, sortOrder));
    }, [scans, sortOrder, assigneeFilter, caseNumber, searchText, readStatusFilter, currentUser.teamMemberOf, currentUser.userID]);

    function handleServerMessage(topic, data) {
        if (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_ADDED) {
            const newScans = [
                ...scans,
                { ...data, was_read: false, read_status_user_id: currentUser.userID },
            ];
            setScans(augmentScans(newScans, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_UPDATED) {
            const newScans = scans.map((scn) => {
                if (scn.scan_id === data.scan_id) {
                    if (data.read_status_user_id === currentUser.userID) {
                        return { ...data };
                    } else {
                        return { ...data, was_read: scn.was_read, read_status_user_id: scn.read_status_user_id };
                    }
                }
                return { ...scn };
            });
            setScans(augmentScans(newScans, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_DELETED) {
            const newScans = scans.filter((scn) => scn.scan_id !== parseInt(data.scan_id));
            setScans(augmentScans(newScans, allUsers, allTeams));
        }
    }

    const setScanAcknowledged = useCallback(
        (scn) => {
            // Update state locally for snappy user experience
            const newScans = scans.map((m) => {
                if (m.scan_id === scn.scan_id) {
                    return { ...m, acknowledged: true, was_read: true };
                }
                return m;
            });
            setScans(augmentScans(newScans, allUsers, allTeams));

            // Update on the server in the background
            API.updateScan(scn.scan_id, { acknowledged: true });
            API.markScanRead(scn.scan_id);
        },
        [scans, allTeams, allUsers]
    );

    function setScanAssignee(scn, assignee) {
        // Update state locally for snappy user experience
        const newScans = scans.map((m) => {
            if (m.scan_id === scn.scan_id) {
                if (assignee.is_user) {
                    return { ...m, assignee_id: assignee.id };
                } else if (assignee.is_team) {
                    return { ...m, team_id: assignee.id, assignee_id: null };
                } else {
                    return { ...m, team_id: null, assignee_id: null };
                }
            }
            return m;
        });
        setScans(augmentScans(newScans, allUsers, allTeams));

        // Update on the server in the background
        const fieldsToUpdate = assignee.is_user
            ? { assignee_id: assignee.id }
            : assignee.is_team
            ? { team_id: assignee.id, assignee_id: null }
            : { team_id: null, assignee_id: null };
        API.updateScan(scn.scan_id, fieldsToUpdate);
    }

    const toggleWatched = useCallback(async (scan) => {
        // Update state locally for snappy user experience
        const newScans = scans.map(s => {
            if (s.scan_id === scan.scan_id) {
                return {...s, is_watched_by_current_user: !s.is_watched_by_current_user};
            }
            return s;
        });
        setScans(augmentScans(newScans, allUsers, allTeams));

        // Update on the server in the background
        if (scan.is_watched_by_current_user) {
            API.unwatchScan(scan.scan_id);
        } else {
            API.watchScan(scan.scan_id);
        }
    }, [allUsers, allTeams, scans]);

    return {loading, errMsg, scans: sortedScans, setScanAcknowledged, setScanAssignee, toggleWatched};
}

export default useScans;
