import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import PushNotificationPreview from '../pushnotificationpreview.js';
var classNames = require('classnames');

function OrderDebitCard({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        cardOrdered: null,
        notes: ""
    });
    
    const saveForm = useCallback(async (matterID) => {
        return await API.saveOrderDebitCard(formState.cardOrdered, formState.notes, username, matterID);
    }, [formState.cardOrdered, formState.notes, username]);

    const notifTitle = "Case on Hold - Cannot Order New Debit Card";
    const notifDetail = "You were previously issued an EDD Debit Card, so we require that you cooperate with us to order a new card. If we do not have a working card on file, we cannot be paid our fee. Therefore, we may have to close your case unless this issue is resolved. Please contact our office immediately at (213) 377-5796 ext. 3.";

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Confirm" disableSaveButton={formState.cardOrdered === null} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <p>Were you able to order a new debit card?</p>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.cardOrdered === true})} onClick={() => updateFormState({cardOrdered: true})}>Card Ordered</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.cardOrdered === false})} onClick={() => updateFormState({cardOrdered: false})}>Client Does Not Cooperate</div>
                </div>
            </div>

            {formState.cardOrdered === true && (
                <>
                    <p>Please confirm that you were able to order a new debit card by clicking the "Confirm" button below.</p>
                    <div className="field">
                        <label htmlFor="detail">Optional Notes:</label>
                        <textarea type="text" name="detail" value={formState.notes} onChange={ev => updateFormState({notes: ev.target.value})} placeholder="Enter optional notes..." rows="10"></textarea>
                    </div>
                </>
            )}

            {formState.cardOrdered === false && (
                <>
                    <div className="field">
                        <p>Please confirm that you are unable to order a new card order for {caseNumber} because the client does not cooperate.</p>
                        <p>Once you do, the server will automatically take the following actions:</p>
                        <ul>
                            <li>Case Hold field will be set to 'Cannot Order New Card'</li>
                            <li>Status field will be set to 'Pending'</li>
                            <li>A push notification will be sent to the client requesting to call our office</li>
                        </ul>
                        <PushNotificationPreview title={notifTitle} detail={notifDetail}/>
                    </div>
                </>
            )}
        </TaskForm>
    );
}

export default OrderDebitCard;
