import { parseIso8601, parseTimestamp } from '../common/formatting.js';
import { LEAD_EVENT_TYPES, DYNAMIC_CONTENT_BLOCK_TYPES } from './webconstants.js';

var qs = require('qs');

const parseLead = (apiLead) => {
    const lead = { ...apiLead };

    if (apiLead.qualifiedAt) {
        lead.qualifiedAt = parseIso8601(apiLead.qualifiedAt);
    }

    if (apiLead.createdAt) {
        lead.createdAt = parseIso8601(apiLead.createdAt);
    }

    if (apiLead.consultFieldsUpdatedAt) {
        lead.consultFieldsUpdatedAt = parseIso8601(apiLead.consultFieldsUpdatedAt);
    }

    if (apiLead.deferredUntil) {
        lead.deferredUntil = parseIso8601(apiLead.deferredUntil);
    }

    if (apiLead.nextScheduledCallScheduledForFrom) {
        lead.nextScheduledCallScheduledForFrom = parseIso8601(apiLead.nextScheduledCallScheduledForFrom);
    }

    if (apiLead.nextScheduledCallScheduledForTo) {
        lead.nextScheduledCallScheduledForTo = parseIso8601(apiLead.nextScheduledCallScheduledForTo);
    }

    return lead;
}

const parseCallLog = (apiCallLog) => {
    const callLog = { ...apiCallLog };

    if (apiCallLog.attemptedAt) {
        callLog.attemptedAt = parseTimestamp(apiCallLog.attemptedAt);
    } else {
        callLog.attemptedAt = null;
    }

    if (apiCallLog.answeredAt) {
        callLog.answeredAt = parseTimestamp(apiCallLog.answeredAt);
    } else {
        callLog.answeredAt = null;
    }

    if (apiCallLog.completedAt) {
        callLog.completedAt = parseTimestamp(apiCallLog.completedAt);
    } else {
        callLog.completedAt = null;
    }

    return callLog;
}

const parseLeadRecommendationUpdate = (apiLeadRecommendationUpdate) => {
    const leadRecommendationUpdate = { ...apiLeadRecommendationUpdate };

    if (apiLeadRecommendationUpdate.createdAt) {
        leadRecommendationUpdate.createdAt = parseTimestamp(apiLeadRecommendationUpdate.createdAt);
    } else {
        leadRecommendationUpdate.createdAt = null;
    }

    return leadRecommendationUpdate;
};

const parseTextMessage = (apiTextMessage) => {
    const textMessage = { ...apiTextMessage };

    if (apiTextMessage.createdAt) {
        textMessage.createdAt = parseTimestamp(apiTextMessage.createdAt);
    } else {
        textMessage.createdAt = null;
    }

    return textMessage;
};

const parseLeadScheduledCall = (apiLeadScheduledCall) => {
    const leadScheduledCall = { ...apiLeadScheduledCall };

    if (apiLeadScheduledCall.createdAt) {
        leadScheduledCall.createdAt = parseTimestamp(apiLeadScheduledCall.createdAt);
    } else {
        leadScheduledCall.createdAt = null;
    }

    if (apiLeadScheduledCall.scheduledForFrom) {
        leadScheduledCall.scheduledForFrom = parseTimestamp(apiLeadScheduledCall.scheduledForFrom);
    } else {
        leadScheduledCall.scheduledForFrom = null;
    }

    if (apiLeadScheduledCall.scheduledForTo) {
        leadScheduledCall.scheduledForTo = parseTimestamp(apiLeadScheduledCall.scheduledForTo);
    } else {
        leadScheduledCall.scheduledForTo = null;
    }

    return leadScheduledCall;
};

const parseFieldsUpdate = (apiFieldsUpdate) => {
    const fieldsUpdate = { ...apiFieldsUpdate };

    if (apiFieldsUpdate.createdAt) {
        fieldsUpdate.createdAt = parseTimestamp(apiFieldsUpdate.createdAt);
    } else {
        fieldsUpdate.createdAt = null;
    }

    return fieldsUpdate;
};

const parseLeadScheduleCallRequest = (apiLeadScheduleCallRequest) => {
    const leadScheduleCallRequest = { ...apiLeadScheduleCallRequest };

    if (apiLeadScheduleCallRequest.createdAt) {
        leadScheduleCallRequest.createdAt = parseTimestamp(apiLeadScheduleCallRequest.createdAt);
    } else {
        leadScheduleCallRequest.createdAt = null;
    }

    return leadScheduleCallRequest;
};

const parseLeadHistoricalEvent = (apiLeadEvent) => {
    switch (apiLeadEvent.type) {
        case LEAD_EVENT_TYPES.CONSULT_RECOMMENDATION_UPDATE:
            return parseLeadRecommendationUpdate(apiLeadEvent);
        case LEAD_EVENT_TYPES.CALL_LOG:
            return parseCallLog(apiLeadEvent);
        case LEAD_EVENT_TYPES.TEXT_MESSAGE:
            return parseTextMessage(apiLeadEvent);
        case LEAD_EVENT_TYPES.OPPORTUNITY_UPDATE:
            return parseFieldsUpdate(apiLeadEvent);
        case LEAD_EVENT_TYPES.CALL_SCHEDULED:
            return parseLeadScheduledCall(apiLeadEvent);
        case LEAD_EVENT_TYPES.SCHEDULED_CALL_RESOLVED:
            return parseLeadScheduledCall(apiLeadEvent);
        case LEAD_EVENT_TYPES.SCHEDULE_CALL_REQUESTED:
            return parseLeadScheduleCallRequest(apiLeadEvent);
        default:
            console.error(`No parser configured for Lead Event type ${apiLeadEvent.type}`);
            return apiLeadEvent;
    }
};

const parseEvent = (apiEvent) => {
    const event = { ...apiEvent };

    if (apiEvent.startDateTime) {
        event.startDateTime = parseIso8601(apiEvent.startDateTime);
    }

    if (apiEvent.endDateTime) {
        event.endDateTime = parseIso8601(apiEvent.endDateTime);
    }

    return event;
}

const parseConversation = (apiConversation) => {
    const conversation = {
        ...apiConversation,
        latestMessageText: apiConversation.latest_message_text,
        latestMessageDocumentId: apiConversation.latest_message_document_id,
        regardingMatterId: apiConversation.regarding_matter_id,
        participants: apiConversation.participants.map((participant) => parseConversationParticipant(participant)),
    };

    if (apiConversation.created_at) {
        conversation.createdAt = parseTimestamp(apiConversation.created_at);
    }

    if (apiConversation.latest_activity_at) {
        conversation.latestActivityAt = parseTimestamp(apiConversation.latest_activity_at);
    }

    return conversation;
};

const parseConversationParticipant = (apiParticipant) => {
    const participant = {
        ...apiParticipant,
        isUser: apiParticipant.participant_type === 'User',
        isTeam: apiParticipant.participant_type === 'Team',
        isMatterOwner: apiParticipant.participant_type === 'MatterOwner',
        avatarUrl: apiParticipant.avatar_url,
        participantId: apiParticipant.participant_id,
        participantType: apiParticipant.participant_type,
    };

    if ('unread_messages_exist' in participant) {
        participant.unreadMessagesExist = apiParticipant.unread_messages_exist;
        delete participant['unread_messages_exist'];
    }

    if ('assigned_user_id' in participant) {
        participant.assignedUserId = apiParticipant.assigned_user_id;
        delete participant['assigned_user_id'];
    }

    if ('assigned_user_name' in participant) {
        participant.assignedUserName = apiParticipant.assigned_user_name;
        delete participant['assigned_user_name'];
    }

    if (apiParticipant.latest_read_message_created_at) {
        participant.latestReadMessageCreatedAt = parseTimestamp(apiParticipant.latest_read_message_created_at);
        delete participant['latest_read_message_created_at'];
    }

    return participant;
};

const parseMessage = (apiMessage) => {
    const message = {
        ...apiMessage,
        conversationId: apiMessage.conversation_id,
    };

    if (apiMessage.created_at) {
        message.created_at = parseTimestamp(apiMessage.created_at);
    }

    if (apiMessage.document_id) {
        message.documentId = apiMessage.document_id;
    }

    if (apiMessage.document_name) {
        message.documentName = apiMessage.document_name;
        delete message['document_name'];
    }

    if ('sender_name' in apiMessage) {
        message.senderName = apiMessage.sender_name;
        delete message['sender_name'];
    }

    return message;
};

const parseMessagesSearchMatch = (apiMessagesSearchMatch) => {
    const searchMatch = {
        ...apiMessagesSearchMatch,
        ...parseMessage(apiMessagesSearchMatch),
    };

    if ('regarding_matter_id' in searchMatch) {
        searchMatch.regardingMatterId = searchMatch.regarding_matter_id;
        delete searchMatch['regarding_matter_id'];
    }

    if ('regarding_case_number' in searchMatch) {
        searchMatch.regardingCaseNumber = searchMatch.regarding_case_number;
        delete searchMatch['regarding_case_number'];
    }

    return searchMatch;
};

const parseNode = (apiNode) => {
    return {
        id: apiNode.id,
        nodeType: apiNode.node_type,
        nodeId: apiNode.node_id,
        nextInterviewNodeId: apiNode.next_interview_node_id,
        label: apiNode.label,
    };
};

const parseQuestionNode = (apiQuestionNode) => {
    return {
        id: apiQuestionNode.id,
        title: apiQuestionNode.title,
    };
};

const parseConditionalNode = (apiConditionalNode) => {
    return {
        id: apiConditionalNode.id,
        nextInterviewNodeIdConditionMet: apiConditionalNode.next_interview_node_id_condition_met,
        condition: {
            type: apiConditionalNode.condition.type,
            operator: apiConditionalNode.condition.operator,
            cqoIds: apiConditionalNode.condition.cqo_ids,
        },
    };
};

const parseCreateDocumentRequestNode = (apiDocumentRequestNode) => {
    return {
        id: apiDocumentRequestNode.id,
        documentTypeId: apiDocumentRequestNode.document_type_id,
    };
};

const parseChoiceQuestionOption = (apiChoiceQuestionOption) => {
    return {
        id: apiChoiceQuestionOption.id,
        title: apiChoiceQuestionOption.title,
        orderNumber: apiChoiceQuestionOption.order_number,
        choiceQuestionType: apiChoiceQuestionOption.choice_question_type,
        choiceQuestionId: apiChoiceQuestionOption.choice_question_id,
    };
};

const parseDocumentType = (apiDocumentType) => {
    return apiDocumentType;
};

const parseInterview = (apiInterview) => {
    const interview = {
        startNodeId: apiInterview.start_node_id,
        publishedAt: parseTimestamp(apiInterview.published_at),
        nodes: apiInterview.nodes.map(parseNode),
        singleChoiceQuestionNodes: apiInterview.single_choice_question_nodes.map(parseQuestionNode),
        multipleChoiceQuestionNodes: apiInterview.multiple_choice_question_nodes.map(parseQuestionNode),
        conditionalNodes: apiInterview.conditional_nodes.map(parseConditionalNode),
        createDocumentRequestNodes: apiInterview.create_document_request_nodes.map(parseCreateDocumentRequestNode),
        choiceQuestionOptions: apiInterview.choice_question_options.map(parseChoiceQuestionOption),
        documentTypes: apiInterview.document_types.map(parseDocumentType),
    };

    return interview;
};

const parseDynamicContentTopic = (apiDynamicContentTopic) => {
    return {
        readableId: apiDynamicContentTopic.readable_id,
    };
};

const parseVideoDynamicContentBlock = (apiVideoDynamicContentBlock) => {
    return {
        url: apiVideoDynamicContentBlock.url,
        fileName: apiVideoDynamicContentBlock.file_name,
        fileType: apiVideoDynamicContentBlock.file_type,
    };
};

const parsePdfDynamicContentBlock = (apiPdfDynamicContentBlock) => {
    return {
        url: apiPdfDynamicContentBlock.url,
        fileName: apiPdfDynamicContentBlock.file_name,
    };
};

const parseDynamicContentBlock = (apiDynamicContentBlock) => {
    const dynamicContentBlock = {
        id: apiDynamicContentBlock.id,
        dynamicContentBlockType: apiDynamicContentBlock.dynamic_content_block_type,
    };

    if ('text_dynamic_content_block' in apiDynamicContentBlock) {
        dynamicContentBlock.textDynamicContentBlock = apiDynamicContentBlock.text_dynamic_content_block;
    }

    if ('video_dynamic_content_block' in apiDynamicContentBlock) {
        dynamicContentBlock.videoDynamicContentBlock = parseVideoDynamicContentBlock(apiDynamicContentBlock.video_dynamic_content_block);
    }

    if ('pdf_dynamic_content_block' in apiDynamicContentBlock) {
        dynamicContentBlock.pdfDynamicContentBlock = parsePdfDynamicContentBlock(apiDynamicContentBlock.pdf_dynamic_content_block);
    }

    return dynamicContentBlock;
};

export default class API {
    static async init() {
    }

    static async me() {
        const response = await fetch(`/api/me`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Unable to fetch user info");
            return null;
        }
    }

    static async setOnCallStatus(onCallStatus) {
        const response = await fetch('/api/me/on_call_status', {
            method: 'PUT',
            body: JSON.stringify(onCallStatus),
        });

        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log('Failed to update on-call-status');
            return null;
        }
    }

    static async getAllCaseNumbers(queryParams = {}) {
        const query = qs.stringify(queryParams);
        const response = await fetch(`/api/all_case_numbers?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to fetch all case numbers`);
            return [];
        }
    }

    static async getCaseNumber(matterID) {
        const query = qs.stringify({"matter_id": matterID});
        const response = await fetch(`/api/case_number?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unknown matter ID: ${matterID}`)
            return null;
        }
    }

    static async getMatterID(caseNumber) {
        const query = qs.stringify({"case_number": caseNumber});
        const response = await fetch(`/api/matter_id?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unknown case number: ${caseNumber}`)
            return null;
        }
    }

    static async getCaseStatus(caseNumber, queryParams = {}) {
        const query = qs.stringify({...queryParams, case_number: caseNumber});
        const response = await fetch(`/api/status?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getEmails() {
        const response = await fetch("/api/emails");
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getEmailsOlderThan(oldest) {
        const response = await fetch(`/api/emails?older_than=${oldest}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getEmailsForCase(caseNumber) {
        const response = await fetch(`/api/emails?case_number=${encodeURI(caseNumber)}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getPushNotifications() {
        const response = await fetch("/api/pushnotifications");
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getPushNotificationsOlderThan(oldest) {
        const response = await fetch(`/api/pushnotifications?older_than=${oldest}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getPushNotificationsForCase(caseNumber) {
        const response = await fetch(`/api/pushnotifications?case_number=${encodeURI(caseNumber)}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getSMSs() {
        const response = await fetch("/api/sms");
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getSMSsOlderThan(oldest) {
        const response = await fetch(`/api/sms?older_than=${oldest}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getSMSsForCase(caseNumber) {
        const response = await fetch(`/api/sms?case_number=${encodeURI(caseNumber)}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getAutomations() {
        const response = await fetch("/api/automations");
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getAutomationsOlderThan(oldest) {
        const response = await fetch(`/api/automations?older_than=${oldest}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getAutomationsForCase(caseNumber) {
        const response = await fetch(`/api/automations?case_number=${encodeURI(caseNumber)}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getIDMeTracking() {
        const response = await fetch("/api/idmetracking");
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getIDMeTrackingOlderThan(oldest) {
        const response = await fetch(`/api/idmetracking?older_than=${oldest}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getIDMeTrackingForCase(caseNumber) {
        const response = await fetch(`/api/idmetracking?case_number=${encodeURI(caseNumber)}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async sendPushNotification(notifTitle, notifBody, matterID) {
        const formData  = new FormData();
        formData.append('notiftitle', notifTitle);
        formData.append('notifbody', notifBody);
        formData.append('matter_id', matterID);

        const response = await fetch(`/api/devices`, {
            method: 'POST',
            body: formData
        });
        return (response.status === 200);
    }

    static async getDevices(caseNumber) {
        const response = await fetch(`/api/devices?case_number=${encodeURI(caseNumber)}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getUsers(queryParams = {}) {
        const query = qs.stringify(queryParams);
        const response = await fetch(`/api/users?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getSettings() {
        const response = await fetch("/api/settings");
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async saveSettings({
        allowSendingEmails,
        allowSendingPushNotifications,
        allowSendingSMS,
        sendSlackMessageForEachNotification,
        deferLeadAfterFailedCallsNumberOfCalls,
        deferLeadAfterFailedCallsDeferForDays,
        allowLeadScheduleCallRequests,
        enquireVMForCallsBelowSeconds,
    }) {
        const formData  = new FormData();
        formData.append("allow_sending_emails", allowSendingEmails);
        formData.append("allow_push_notifications", allowSendingPushNotifications);
        formData.append("allow_sending_sms", allowSendingSMS);
        formData.append("send_slack_message_for_each_notification", sendSlackMessageForEachNotification);
        formData.append("defer_lead_after_failed_calls_number_of_calls", deferLeadAfterFailedCallsNumberOfCalls);
        formData.append("defer_lead_after_failed_calls_defer_for_days", deferLeadAfterFailedCallsDeferForDays);
        formData.append("allow_lead_schedule_call_requests", allowLeadScheduleCallRequests);
        formData.append("enquire_vm_for_calls_below_seconds", enquireVMForCallsBelowSeconds);

        const response = await fetch("/api/settings", {
            method: 'POST',
            body: formData
        });
        return (response.status === 200);
    }

    static async getDynamicContentTopics() {
        const response = await fetch('/api/dynamic_content_topics');
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json.map(parseDynamicContentTopic);
        } else {
            return null;
        }
    }

    static async addDynamicContentTopic({ readableId }) {
        const response = await fetch(
            '/api/dynamic_content_topics',
            {
                method: 'POST',
                body: JSON.stringify({ readable_id: readableId }),
                headers: { 'Content-Type': 'application/json' },
            },
        );

        return response.status === 201;
    }

    static async getDynamicContentForTopic({ dynamicContentTopicReadableId }) {
        const response = await fetch(`/api/dynamic_content_topics/${dynamicContentTopicReadableId}/dynamic_content`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json.map(parseDynamicContentBlock);
        } else {
            return null;
        }
    }

    static async addDynamicContentBlockForTopicAtIndex(dynamicContentTopicReadableId, index, blockParams) {
        const data = new FormData();
        data.append('index', index);
        data.append('dynamic_content_block_type', blockParams.type);

        if (blockParams.type === DYNAMIC_CONTENT_BLOCK_TYPES.TEXT) {
            data.append('text', blockParams.text);
        } else {
            data.append('file', blockParams.fileUri);
        }

        const response = await fetch(
            `/api/dynamic_content_topics/${dynamicContentTopicReadableId}/dynamic_content_blocks`,
            {
                method: 'POST',
                body: data,
            },
        );

        return response.status === 200;
    }

    static async deleteDynamicContentBlockForTopic(dynamicContentTopicReadableId, dynamicContentBlockId) {
        const response = await fetch(
            `/api/dynamic_content_topics/${dynamicContentTopicReadableId}/dynamic_content_blocks/${dynamicContentBlockId}`,
            {
                method: 'DELETE',
            },
        );

        return response.status === 200;
    }

    static async updateDynamicContentBlockForTopic(dynamicContentTopicReadableId, dynamicContentBlockId, { text }) {
        const data = new FormData();
        data.append('text', text);

        const response = await fetch(
            `/api/dynamic_content_topics/${dynamicContentTopicReadableId}/dynamic_content_blocks/${dynamicContentBlockId}`,
            {
                method: 'PATCH',
                body: data,
            },
        );

        return response.status === 200;
    }

    static async deleteUser(user_id) {
        const response = await fetch(`/api/user/${user_id}`, {
            method: 'DELETE'
        });
        return (response.status === 200);
    }

    static async createUser(name, email, isAdmin, memberships) {
        const formData  = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("is_admin", isAdmin);
        formData.append("memberships", memberships);

        const response = await fetch("/api/user", {
            method: 'POST',
            body: formData
        });
        return (response.status === 200);
    }

    static async getUser(userID) {
        const response = await fetch(`/api/user/${userID}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async editUser(userID, name, email, isAdmin, memberships) {
        const formData  = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("is_admin", isAdmin);
        formData.append("memberships", memberships);

        const response = await fetch(`/api/user/${userID}`, {
            method: 'PUT',
            body: formData
        });
        return (response.status === 200);
    }

    static async getTeams(queryParams = {}) {
        const query = qs.stringify(queryParams);
        const response = await fetch(`/api/teams?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async deleteTeam(team_id) {
        const response = await fetch(`/api/teams/${team_id}`, {
            method: 'DELETE'
        });
        return (response.status === 200);
    }

    static async createTeam(name) {
        const formData  = new FormData();
        formData.append("name", name);

        const response = await fetch("/api/teams", {
            method: 'POST',
            body: formData
        });
        return (response.status === 200);
    }

    static async getTeam(teamID) {
        const response = await fetch(`/api/teams/${teamID}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async editTeam(teamID, name) {
        const formData  = new FormData();
        formData.append("name", name);

        const response = await fetch(`/api/teams/${teamID}`, {
            method: 'PUT',
            body: formData
        });
        return (response.status === 200);
    }

    static async getFaxNumbers(queryParams = {}) {
        const query = qs.stringify(queryParams);
        const response = await fetch(`/api/faxnumbers?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async deleteFaxNumber(fax_number_id) {
        const response = await fetch(`/api/faxnumbers/${fax_number_id}`, {
            method: 'DELETE'
        });
        return (response.status === 200);
    }

    static async createFaxNumber(phonenumber, user_id, team_id) {
        const formData  = new FormData();
        formData.append("phonenumber", phonenumber);
        formData.append("user_id", user_id ?? -1);
        formData.append("team_id", team_id ?? -1);

        const response = await fetch("/api/faxnumbers", {
            method: 'POST',
            body: formData
        });
        return (response.status === 200);
    }

    static async getFaxNumber(faxNumberID) {
        const response = await fetch(`/api/faxnumbers/${faxNumberID}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async editFaxNumber(faxNumberID, phonenumber, user_id, team_id) {
        const formData  = new FormData();
        formData.append("phonenumber", phonenumber);
        formData.append("user_id", user_id ?? -1);
        formData.append("team_id", team_id ?? -1);

        const response = await fetch(`/api/faxnumbers/${faxNumberID}`, {
            method: 'PUT',
            body: formData
        });
        return (response.status === 200);
    }

    static async getVoicemailExtensions(queryParams = {}) {
        const query = qs.stringify(queryParams);
        const response = await fetch(`/api/voicemailextensions?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async deleteVoicemailExtension(extension_id) {
        const response = await fetch(`/api/voicemailextensions/${extension_id}`, {
            method: 'DELETE'
        });
        return (response.status === 200);
    }

    static async getConversation(conversationId) {
        const response = await fetch(`/api/conversations/${conversationId}`);

        if (response.status === 200) {
            const apiConversation = await response.json();

            return parseConversation(apiConversation);
        } else {
            return null;
        }
    }

    static async getConversationMessages(conversationId, { page, pageSize }) {
        const response = await fetch(`/api/conversations/${conversationId}/messages?page=${page}&page_size=${pageSize}`);

        if (response.status === 200) {
            const apiMessages = await response.json();

            return apiMessages.map(parseMessage);
        } else {
            return null;
        }
    }

    static async createConversationMessage(conversationId, { text, documentId }) {
        const response = await fetch(`/api/conversations/${conversationId}/messages`, {
            method: 'POST',
            body: JSON.stringify({ text, document_id: documentId }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200 || response.status === 201) {
            const responseBody = await response.json();

            if (responseBody.data && responseBody.data.id) {
                responseBody.data = parseMessage(responseBody.data);
            }

            return responseBody;
        } else {
            return null;
        }
    }

    static async markConversationMessageRead(conversationId, messageId) {
        const response = await fetch(`/api/conversations/${conversationId}/messages/${messageId}/read`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200 || response.status === 201) {
            const responseBody = await response.json();

            return responseBody;
        } else {
            return null;
        }
    }

    static async getConversations({ page, pageSize, filters }) {
        const queryString = qs.stringify({
            page,
            page_size: pageSize,
            ...filters
        });
        const response = await fetch(`/api/conversations?${queryString}`);

        if (response.status === 200) {
            const apiConversations = await response.json();

            return apiConversations.map(parseConversation);
        } else {
            return null;
        }
    }

    static async createConversationWithMatterOwner({ caseNumber, topic }) {
        const response = await fetch('/api/conversations_with_matter_owner', {
            method: 'POST',
            body: JSON.stringify({ case_number: caseNumber, topic }),
            headers: { 'Content-Type': 'application/json' }
        });

        if (response.status === 200 || response.status === 201) {
            const responseBody = await response.json();

            return responseBody;
        } else {
            return null;
        }
    }

    static async archiveConversation(conversationId) {
        const response = await fetch(`/api/conversations/${conversationId}/archived`, {
            method: 'POST',
        });

        if (response.status === 200 || response.status === 201) {
            const responseBody = await response.json();

            return responseBody;
        } else {
            return null;
        }
    }

    static async searchMessages({ query, pagination, conversationsFilters }) {
        const queryString = qs.stringify({
            q: query,
            page: pagination.page,
            page_size: pagination.pageSize,
            conversations_filters: conversationsFilters,
        });
        const response = await fetch(`/api/conversations/messages/search?${queryString}`);

        if (response.status === 200) {
            const apiMessageSearchResult = await response.json();

            return apiMessageSearchResult.map(parseMessagesSearchMatch);
        } else {
            return null;
        }
    }

    static async getNumberOfUnreadMessages() {
        const response = await fetch('/api/conversations/number_unread');

        if (response.status === 200) {
            const responseBody = await response.json();

            return responseBody.number_unread;
        } else {
            return null;
        }
    }

    static async createVoicemailExtension(extension, user_id, team_id) {
        const formData  = new FormData();
        formData.append("extension", extension);
        formData.append("user_id", user_id ?? -1);
        formData.append("team_id", team_id ?? -1);

        const response = await fetch("/api/voicemailextensions", {
            method: 'POST',
            body: formData
        });
        return (response.status === 200);
    }

    static async getVoicemailExtension(extensionID) {
        const response = await fetch(`/api/voicemailextensions/${extensionID}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async editVoicemailExtension(extensionID, extension, user_id, team_id) {
        const formData  = new FormData();
        formData.append("extension", extension);
        formData.append("user_id", user_id ?? -1);
        formData.append("team_id", team_id ?? -1);

        const response = await fetch(`/api/voicemailextensions/${extensionID}`, {
            method: 'PUT',
            body: formData
        });
        return (response.status === 200);
    }

    static async getTasksForCase(caseNumber) {
        const query = qs.stringify({"case_number": caseNumber});
        const response = await fetch(`/api/tasks?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getTasks() {
        const response = await fetch(`/api/tasks`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getLeads(params = {}) {
        const query = qs.stringify(params);
        const response = await fetch(`/api/leads?${query}`);

        if (response.status === 200) {
            const apiLeadsResponse = await response.json();

            if (apiLeadsResponse.leads) {
                apiLeadsResponse.leads = apiLeadsResponse.leads.map(parseLead)
            }

            return apiLeadsResponse;
        } else {
            return null;
        }
    }

    static async getLead(leadID) {
        const response = await fetch(`/api/leads/${leadID}`);
        if (response.status === 200) {
            const leadResponse = await response.json();

            if (leadResponse.lead) {
                leadResponse.lead = parseLead(leadResponse.lead)
            }

            return leadResponse;
        } else {
            return null;
        }
    }

    static async getLeadDocuments(leadID) {
        const response = await fetch(`/api/leads/${leadID}/documents`);
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async getLeadCallLogs(leadID, params) {
        const query = qs.stringify(params);
        const response = await fetch(`/api/leads/${leadID}/call_logs?${query}`);

        if (response.status === 200) {
            const apiCallLogs = await response.json();
            return apiCallLogs.map((apiCallLog) => parseCallLog(apiCallLog));
        } else {
            return null;
        }
    }

    static async getLeadHistoricalEvents(leadID) {
        const response = await fetch(`/api/leads/${leadID}/historical_events`);

        if (response.status === 200) {
            const apiLeadEvents = await response.json();
            return apiLeadEvents.map((apiLeadEvent) => parseLeadHistoricalEvent(apiLeadEvent));
        } else {
            return null;
        }
    }

    static async getSuggestedPhoneNumberForLead(leadId) {
        const response = await fetch(`/api/leads/${leadId}/suggested_phone_number`);

        if (response.status === 200) {
            const responseBody = await response.json();
            return responseBody;
        } else {
            return null;
        }
    }

    static async createLeadDocument(leadID, formData) {
        const response = await fetch(`/api/leads/${leadID}/documents`, {
            method: 'POST',
            body: formData,
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Error occurred while uploading file");
            console.log(response);
            return null;
        }
    }

    static async getActiveLeadScheduledCall(leadID) {
        const response = await fetch(`/api/leads/${leadID}/scheduled_calls/active`);

        if (response.status === 200) {
            const leadScheduledCallResponse = await response.json();
            const apiLeadScheduledCallIfExists = leadScheduledCallResponse.leadScheduledCall;

            return apiLeadScheduledCallIfExists ? parseLeadScheduledCall(apiLeadScheduledCallIfExists) : null ;
        } else {
            return null;
        }
    }

    static async createLeadScheduledCall(leadID, { scheduledForFrom, scheduledForTo }) {
        const response = await fetch(`/api/leads/${leadID}/scheduled_calls`, {
            method: 'POST',
            body: JSON.stringify({
                scheduled_for_from: scheduledForFrom.toISOString(),
                scheduled_for_to: scheduledForTo.toISOString(),
            }),
        });
        if (response.status === 201) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async createLeadScheduledCallUsingToken(token, { scheduledForFrom, scheduledForTo }) {
        const response = await fetch(`/api/scheduled_calls`, {
            method: 'POST',
            body: JSON.stringify({
                token,
                scheduled_for_from: scheduledForFrom.toISOString(),
                scheduled_for_to: scheduledForTo.toISOString(),
            }),
        });
        if (response.status === 200 || response.status === 201) {
            const responseBody = await response.json();
            return responseBody;
        } else {
            return null;
        }
    }

    static async createLeadScheduleCallRequest(leadId, { number }) {
        const response = await fetch(`/api/leads/${leadId}/schedule_call_requests`, {
            method: 'POST',
            body: JSON.stringify({
                number,
            }),
        });

        return response.status === 201;
    }

    static async unenqueueLead(leadId) {
        const response = await fetch(`/api/leads/${leadId}/unenqueue`, {
            method: 'POST'
        });

        return response.status === 200;
    }

    static async resolveLeadScheduledCall(leadID, leadScheduledCallId, { status }) {
        const response = await fetch(`/api/leads/${leadID}/scheduled_calls/${leadScheduledCallId}/resolve`, {
            method: 'PATCH',
            body: JSON.stringify({ status }),
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async createLead(leadParams) {
        const response = await fetch('/api/leads', {
            method: 'POST',
            body: JSON.stringify(leadParams),
            headers: {'Content-Type': 'application/json'}
        });

        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getPinnedLeads() {
        const response = await fetch(`/api/pinned_leads`);
        if (response.status === 200) {
            const apiLeadsResponse = await response.json();

            if (apiLeadsResponse.leads) {
                apiLeadsResponse.leads = apiLeadsResponse.leads.map(parseLead)
            }

            return apiLeadsResponse;
        } else {
            return null;
        }
    }

    static async getLeadModelDescription() {
        const response = await fetch(`/api/leads/describe`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getOpportunityModelDescription() {
        const response = await fetch(`/api/opportunities/describe`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getLeadDocument(salesforceLeadDocumentId) {
        const response = await fetch(`/api/salesforce_content_documents/${salesforceLeadDocumentId}/body`);
        if (response.status === 200) {
            const imageBlob = await response.blob();
            const imageObjectURL = URL.createObjectURL(imageBlob);
            return imageObjectURL;
        } else {
            return null;
        }
    }

    static async getVonageToken() {
        const response = await fetch('/api/vonage/auth_tokens', { method: 'POST' });

        if (response.status === 200) {
            const responseBody = await response.json();

            return responseBody.auth_token;
        } else {
            return null;
        }
    }

    static async hangupVonageCall(callLegId) {
        const response = await fetch('/api/vonage/hang_up', {
            method: 'POST',
            body: JSON.stringify({ call_leg_id: callLegId }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    static async holdVonageCall(callLegId) {
        const response = await fetch('/api/vonage/hold', {
            method: 'POST',
            body: JSON.stringify({ call_leg_id: callLegId }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    static async returnVonageCall(callLegId) {
        const response = await fetch('/api/vonage/hold', {
            method: 'DELETE',
            body: JSON.stringify({ call_leg_id: callLegId }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    static async moveCallToQueue({ callLegId, purpose }) {
        const response = await fetch('/api/vonage/move_to_queue', {
            method: 'POST',
            body: JSON.stringify({ call_leg_id: callLegId, purpose }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200) {
            return true;
        } else {
            return null;
        }
    }

    static async getDefaultTextMessageTemplates() {
        const response = await fetch('/api/default_text_message_templates');

        if (response.status === 200) {
            const responseBody = await response.json();
            return responseBody.textMessageTemplates;
        } else {
            return null;
        }
    }

    static async createDefaultTextMessageTemplate(params) {
        const response = await fetch('/api/default_text_message_templates', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {'Content-Type': 'application/json'}
        });

        if (response.status === 201) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async deleteDefaultTextMessageTemplate(textMessageTemplateId) {
        const response = await fetch(`/api/default_text_message_templates/${textMessageTemplateId}`, {
            method: 'DELETE',
        });

        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getTextMessageTemplates() {
        const response = await fetch('/api/text_message_templates');

        if (response.status === 200) {
            const responseBody = await response.json();
            return responseBody.textMessageTemplates;
        } else {
            return null;
        }
    }

    static async createTextMessageTemplate(params) {
        const response = await fetch('/api/text_message_templates', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {'Content-Type': 'application/json'}
        });

        if (response.status === 201) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async deleteTextMessageTemplate(textMessageTemplateId) {
        const response = await fetch(`/api/text_message_templates/${textMessageTemplateId}`, {
            method: 'DELETE',
        });

        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async editTextMessageTemplate(textMessageTemplateId, textMessageTemplateParams) {
        const response = await fetch(`/api/text_message_templates/${textMessageTemplateId}`, {
            method: 'PATCH',
            body: JSON.stringify(textMessageTemplateParams),
        });

        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getInterviewForTopic({ topic }) {
        const response = await fetch(`/api/interview_topics/${topic}/interview`);

        if (response.status === 200) {
            const responseBody = await response.json();

            return parseInterview(responseBody);
        } else {
            return null;
        }
    }

    static async publishInterviewForTopic({ topic, interview }) {
        const response = await fetch(`/api/interview_topics/${topic}/interview`, {
            method: 'PUT',
            body: JSON.stringify(interview),
        });

        return response.status === 200;
    }

    static async editCallLog(callLogId, params) {
        const response = await fetch(`/api/call_logs/${callLogId}`, {
            method: 'PATCH',
            body: JSON.stringify(params),
        });

        return response.status === 200;
    }

    static async createLeadTextMessage(leadId, params) {
        const response = await fetch(`/api/leads/${leadId}/text_messages`, {
            method: 'POST',
            body: JSON.stringify(params),
        });

        return response.status === 201;
    }

    static async updateTeamConversationAssignee({ teamId, conversationId, userId }) {
        const response = await fetch(`/api/teams/${teamId}/conversations/${conversationId}/assignee`, {
            method: 'PUT',
            body: JSON.stringify({ user_id: userId }),
        });

        return response.status === 200;
    }

    static async replaceUserPicture({ userId, picture }) {
        const response = await fetch(`/api/users/${userId}/picture`, {
            method: 'PUT',
            body: JSON.stringify(picture),
        });

        return response.status === 200;
    }

    static async deleteUserPicture({ userId }) {
        const response = await fetch(`/api/users/${userId}/picture`, {
            method: 'DELETE',
        });

        return response.status === 200;
    }

    static async getAttorneyStatistics(attorneyUserId) {
        const response = await fetch(`/api/statistics/attorney/${attorneyUserId}`);

        if (response.status === 200) {
            return await response.json();
        }

        return null;
    }

    static async getStatistics() {
        const response = await fetch('/api/statistics');

        if (response.status === 200) {
            return await response.json();
        }

        return null;
    }

    static async searchLeads(query) {
        const response = await fetch(`/api/leads/search?q=${encodeURI(query)}`);
        if (response.status === 200) {
            const leadsResponse = await response.json();
            return leadsResponse.leads.map(parseLead);
        } else {
            return null;
        }
    }

    static async pinLead(leadId) {
        const response = await fetch(
            `/api/leads/${leadId}/pin`,
            { method: 'POST' }
        );

        return response.status === 200
    }

    static async unpinLead(leadId) {
        const response = await fetch(
            `/api/leads/${leadId}/pin`,
            { method: 'DELETE' }
        );

        return response.status === 200
    }

    static async updateLead(leadID, params) {
        const response = await fetch(`/api/leads/${leadID}`, {
            method: 'PATCH',
            body: JSON.stringify(params),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async updateOpportunity(leadID, params) {
        const response = await fetch(`/api/opportunities/${leadID}`, {
            method: 'PATCH',
            body: JSON.stringify(params),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async updateEvent(salesforceEventID, params) {
        const response = await fetch(`/api/events/${salesforceEventID}`, {
            method: 'PATCH',
            body: JSON.stringify(params),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            const response_json = await response.json();

            if (!response_json.success) {
                return response_json;
            }

            return {
                ...response_json,
                event: parseEvent(response_json.event),
            };
        } else {
            return null;
        }
    }

    static async getLeadHearingEvents(leadId) {
        const response = await fetch(`/api/leads/${leadId}/hearing_events`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json.map((apiEvent) => parseEvent(apiEvent));
        } else {
            return null;
        }
    }

    static async getTasksArchive(offset=0) {
        const query = qs.stringify({"offset": offset});
        const response = await fetch(`/api/tasks/archive?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getTasksArchiveForCase(caseNumber) {
        const query = qs.stringify({"case_number": caseNumber});
        const response = await fetch(`/api/tasks/archive?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getTask(taskID) {
        const response = await fetch(`/api/tasks/${taskID}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async createTask(title, description, priority, deadline, caseNumber, assignee, team, formType) {
        const postData = {
            title, description, priority, deadline,
            case_number: caseNumber,
            assignee_id: assignee,
            team_id: team,
            form_type: formType
        };

        const response = await fetch("/api/tasks", {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async updateTask(taskID, fieldsToUpdate) {
        const response = await fetch(`/api/tasks/${taskID}`, {
            method: 'PATCH',
            body: JSON.stringify(fieldsToUpdate),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async deleteTask(taskID) {
        const response = await fetch(`/api/tasks/${taskID}`, {
            method: 'DELETE'
        });
        return (response.status === 200);
    }

    static async getInboxDocuments(matterID) {
        const query = qs.stringify({"matter_id": matterID});
        const response = await fetch(`/inbox_documents?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to fetch inbox documents for matter_id ${matterID}`)
            return [];
        }
    }

    static async getCaseNotes(matterID) {
        const query = qs.stringify({"matter_id": matterID});
        const response = await fetch(`/notes?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to notes for matter_id ${matterID}`)
            return [];
        }
    }

    static async getContactInfo(contactID, queryParams = {}) {
        const query = qs.stringify(queryParams);
        const response = await fetch (`/api/contact/${contactID}?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to get contact info for contact_id ${contactID}`)
            return null;
        }
    }

    static async getTaskComments(taskID) {
        const response = await fetch (`/api/tasks/${taskID}/comments`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to get comments for task ${taskID}`)
            return [];
        }
    }

    static async createTaskComment(taskID, text) {
        const postData = {
            text
        };

        const response = await fetch(`/api/tasks/${taskID}/comments`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Unable to create task comment")
            return null;
        }
    }

    static async getLeadComments(leadID) {
        const response = await fetch (`/api/leads/${leadID}/comments`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to get comments for lead ${leadID}`)
            return [];
        }
    }

    static async createLeadComment(leadID, text) {
        const postData = {
            text
        };

        const response = await fetch(`/api/leads/${leadID}/comments`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Unable to create task comment")
            return null;
        }
    }

    static async markLeadMentionsRead(leadID) {
        const response = await fetch(`/api/leadmentions/${leadID}/markread`, {
            method: 'PUT'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while marking mentions as read");
            console.log(response);
            return false;
        }
    }

    static async createLeadMentionMessage(lead, message, assignee, team, authorName, commentID) {
        const subject = `${authorName} mentioned you on Lead "${lead.name}"`;
        const postData = {
            lead_id: lead.id,
            subject,
            description: message,
            assignee_id: assignee,
            team_id: team,
            author_name: authorName,
            comment_id: commentID
        };

        const response = await fetch(`/api/leadmentions`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        return (response.status === 200);
    }

    static async getTaskChecklists(taskID) {
        const response = await fetch (`/api/tasks/${taskID}/checklists`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to get checklists for task ${taskID}`)
            return [];
        }
    }

    static async getTaskChecklistItems(taskID, listID) {
        const response = await fetch (`/api/tasks/${taskID}/checklists/${listID}/items`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to get checklist items for list ${listID}`)
            return [];
        }
    }

    static async updateTaskChecklistItem(taskID, listID, itemID, fieldsToUpdate) {
        const response = await fetch(`/api/tasks/${taskID}/checklists/${listID}/items/${itemID}`, {
            method: 'PATCH',
            body: JSON.stringify(fieldsToUpdate),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async createTaskChecklistItem(taskID, listID, title) {
        const postData = {
            title
        };

        const response = await fetch(`/api/tasks/${taskID}/checklists/${listID}/items`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async createTaskChecklist(taskID, title) {
        const postData = {
            title
        };

        const response = await fetch(`/api/tasks/${taskID}/checklists`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async deleteTaskChecklistItem(taskID, listID, itemID) {
        const response = await fetch(`/api/tasks/${taskID}/checklists/${listID}/items/${itemID}`, {
            method: 'DELETE'
        });
        return (response.status === 200);
    }

    static async getTaskAttachments(taskID) {
        const response = await fetch (`/api/tasks/${taskID}/attachments`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to get attachments for task ${taskID}`)
            return [];
        }
    }

    static async uploadTaskAttachment(taskID, file) {
        var data = new FormData();
        data.append('file', file);

        const response = await fetch(`/api/tasks/${taskID}/attachments`, {
            method: 'POST',
            body: data
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Error occurred while uploading file");
            console.log(response);
            return null;
        }
    }

    static async copyTaskAttachmentFromScan(taskID, scanID) {
        const response = await fetch(`/api/tasks/${taskID}/attachments/fromscan/${scanID}`, {
            method: 'POST'
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Error occurred while copying scan to task attachment");
            console.log(response);
            return null;
        }
    }

    static async copyTaskAttachmentFromInboxMessage(taskID, msgID) {
        const response = await fetch(`/api/tasks/${taskID}/attachments/frominboxmessage/${msgID}`, {
            method: 'POST'
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Error occurred while copying inbox message attachment to task attachment");
            console.log(response);
            return null;
        }
    }

    static async deleteTaskAttachment(taskID, attachmentID) {
        const response = await fetch(`/api/tasks/${taskID}/attachments/${attachmentID}`, {
            method: 'DELETE'
        });
        return (response.status === 200);
    }

    static async createCallMessage(subject, description, priority, caseNumber, assignee, team, nameOfCaller, callbackNumber, authorName, queryParams={}) {
        const postData = {
            subject, description, priority,
            case_number: caseNumber,
            assignee_id: assignee,
            team_id: team,
            name_of_caller: nameOfCaller,
            callback_number: callbackNumber,
            author_name: authorName
        };

        const query = qs.stringify(queryParams);
        const response = await fetch(`/api/callmessages?${query}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        return (response.status === 200);
    }

    static async createTaskMentionMessage(task, message, assignee, team, authorName, commentID) {
        const subject = `${authorName} mentioned you on Task "${task.title}"`;
        const postData = {
            task_id: task.task_id,
            subject,
            description: message,
            case_number: task.case_number,
            assignee_id: assignee,
            team_id: team,
            author_name: authorName,
            comment_id: commentID
        };

        const response = await fetch(`/api/taskmentions`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        return (response.status === 200);
    }

    static async createInboxMessageMentionMessage(msg, description, assignee, team, authorName, commentID) {
        const subject = `${authorName} mentioned you on Message "${msg.subject}"`;
        const postData = {
            msg_id: msg.msg_id,
            subject,
            description,
            case_number: msg.case_number,
            assignee_id: assignee,
            team_id: team,
            author_name: authorName,
            comment_id: commentID
        };

        const response = await fetch(`/api/inboxmessagementions`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        return (response.status === 200);
    }

    static async createScanMentionMessage(scan, message, assignee, team, authorName, commentID) {
        const subject = `${authorName} mentioned you on Scan "${scan.filename}"`;
        const postData = {
            scan_id: scan.scan_id,
            subject,
            description: message,
            case_number: scan.case_number,
            assignee_id: assignee,
            team_id: team,
            author_name: authorName,
            comment_id: commentID
        };

        const response = await fetch(`/api/scanmentions`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        return (response.status === 200);
    }

    static async createInboxMessage(kind, fields) {
        const postData = {
            kind,
            ...fields
        };

        const response = await fetch(`/api/inbox`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getInboxMessages() {
        const response = await fetch(`/api/inbox`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async updateInboxMessage(msgID, fieldsToUpdate) {
        const response = await fetch(`/api/inbox/${msgID}`, {
            method: 'PATCH',
            body: JSON.stringify(fieldsToUpdate),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async getInboxMessage(msgID) {
        const response = await fetch(`/api/inbox/${msgID}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getUnprocessedInboxMessageCount(userID) {
        const query = qs.stringify({user_id: userID});
        const response = await fetch(`/api/inbox/unacknowledgedcount?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return {count: 0};
        }
    }

    static async markInboxMessageRead(msgID) {
        const response = await fetch(`/api/inbox/${msgID}/markread`, {
            method: 'PUT'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while marking message as read");
            console.log(response);
            return false;
        }
    }

    static async getInboxMessagesArchive(offset=0) {
        const query = qs.stringify({"offset": offset});
        const response = await fetch(`/api/inbox/archive?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getInboxMessagesArchiveForCase(caseNumber) {
        const query = qs.stringify({"case_number": caseNumber});
        const response = await fetch(`/api/inbox/archive?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getInboxMessageComments(msgID) {
        const response = await fetch (`/api/inbox/${msgID}/comments`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to get comments for message ${msgID}`)
            return [];
        }
    }

    static async createInboxMessageComment(msgID, text) {
        const postData = {
            text
        };

        const response = await fetch(`/api/inbox/${msgID}/comments`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Unable to create inbox message comment")
            return null;
        }
    }

    static async markInboxMessageMentionsRead(msgID) {
        const response = await fetch(`/api/inboxmessagementions/${msgID}/markread`, {
            method: 'PUT'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while marking mentions as read");
            console.log(response);
            return false;
        }
    }

    static async getInboxMessageMentions() {
        const response = await fetch(`/api/inboxmessagementions`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async watchInboxMessage(msgID) {
        const response = await fetch(`/api/inbox/${msgID}/watch`, {
            method: 'POST'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while watching message");
            console.log(response);
            return false;
        }
    }

    static async unwatchInboxMessage(msgID) {
        const response = await fetch(`/api/inbox/${msgID}/watch`, {
            method: 'DELETE'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while unwatching message");
            console.log(response);
            return false;
        }
    }

    // Creates a new document with the given name for the given matter on the server.
    // Returns the document ID on success, or null on failure.
    static async createDocument(matterID, documentName, shareWithClient, fileType) {
        const query = qs.stringify({"matter_id": matterID, "docname": documentName, "share_with_client": shareWithClient, file_type: fileType});
        const response = await fetch(`/documents?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json.document_id;
        } else {
            console.log("Error occurred when trying to create document");
            console.log(response);
            return null;
        }
    }

    // Uploads a PDF to the given document
    // Returns true on success, false on failure
    static async uploadPdfByUrl(documentID, uri) {
        const query = qs.stringify({uri, file_type: 'application/pdf'});

        const response = await fetch(`/documents/${documentID}?${query}`, {
            method: 'PUT'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while uploading PDF file");
            console.log(response);
            return false;
        }
    }

    static async addFileToTemporaryDocument(temporaryDocumentId, { fileUri }) {
        const data = new FormData();
        data.append('file', fileUri);

        const response = await fetch(
            `/documents/${temporaryDocumentId}`,
            {
                method: 'PUT',
                body: data,
            },
        );

        return response.status === 200;
    }

    // Upload notes to the given document
    // Returns true on success, false on failure
    static async uploadNotes(documentID, notes) {
        var data = new FormData();
        data.append('notes', notes);

        console.log("PUTting the notes to the server");
        const response = await fetch(`/documents/${documentID}`, {
            method: 'PUT',
            body: data
        });
        if (response.status === 200) {
            console.log("Uploaded notes");
            return true;
        } else {
            console.log("Error occurred while uploading notes");
            console.log(response);
            return false;
        }
    }

    // Kicks off the PDF conversion process
    // Return true on success, false on failure.
    static async convertToPDF(documentID) {
        const response = await fetch(`/documents/${documentID}/conversion`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            console.log("Conversion to PDF initiated");
            return true;
        } else {
            console.log("Error occurred when trying to initiate PDF conversion process");
            console.log(response);
            return false;
        }
    }

    // Polls the PDF conversion status of the given document
    // Returns "converting", "storing" or "done" for valid documentIDs.
    // Returns null for unknown documentIDs.
    static async getConversionStatus(documentID) {
        const response = await fetch(`/documents/${documentID}/conversion`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            const response_json = await response.json();
            console.log("Poll conversion status result:")
            console.log(response_json)
            return {status: response_json.status, clioDocumentID: response_json.clio_document_id};
        } else {
            console.log("Error occurred when trying to poll status of PDF conversion process");
            console.log(response);
            return null;
        }
    }

    // Uploads an mp3 to the given document
    // Returns true on success, false on failure
    static async uploadMp3ByUrl(documentID, uri) {
        const query = qs.stringify({uri, file_type: 'audio/mpeg'});

        const response = await fetch(`/documents/${documentID}?${query}`, {
            method: 'PUT'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while uploading MP3 file");
            console.log(response);
            return false;
        }
    }

    // Kicks off the Clio upload process
    // Return true on success, false on failure.
    static async storeOnClio(documentID) {
        const response = await fetch(`/documents/${documentID}/store`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            console.log("Store on Clio initiated");
            return true;
        } else {
            console.log("Error occurred when trying to initiate store on Clio process");
            console.log(response);
            return false;
        }
    }

    // Polls the Clio storage status of the given document
    // Returns "storing" or "done" for valid documentIDs.
    // Returns null for unknown documentIDs.
    static async getStoreOnClioStatus(documentID) {
        const response = await fetch(`/documents/${documentID}/store`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            const response_json = await response.json();
            console.log("Poll store status result:")
            console.log(response_json)
            return {status: response_json.status, clioDocumentID: response_json.clio_document_id};
        } else {
            console.log("Error occurred when trying to poll status of Clio storage process");
            console.log(response);
            return null;
        }
    }

    static async markTaskMentionsRead(taskID) {
        const response = await fetch(`/api/taskmentions/${taskID}/markread`, {
            method: 'PUT'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while marking mentions as read");
            console.log(response);
            return false;
        }
    }

    static async getNotifications(params = {}) {
        const query = qs.stringify(params);
        const response = await fetch(`/api/notifications?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async archiveNotification(notificationId) {
        const response = await fetch(`/api/notifications/${notificationId}/archive`, {
            method: 'PATCH',
        });

        return response.status === 200;
    }

    static async markAllNotificationsRead() {
        const response = await fetch(`/api/notifications/own/read`, {
            method: 'PATCH'
        });

        return response.status === 200;
    }

    static async markNotificationRead(notificationId) {
        const response = await fetch(`/api/notifications/${notificationId}/read`, {
            method: 'PATCH'
        });

        return response.status === 200;
    }

    static async getMentions() {
        const response = await fetch(`/api/mentions`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getMentionsArchive() {
        const response = await fetch(`/api/mentions/archive`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async markAllMentionsRead() {
        const response = await fetch(`/api/mentions/markread`, {
            method: 'POST'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while marking all mentions read");
            console.log(response);
            return false;
        }
    }

    static async archiveMention(mentionID) {
        const response = await fetch(`/api/mentions/${mentionID}`, {
            method: 'DELETE'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while archiving mention");
            console.log(response);
            return false;
        }
    }

    static async watchTask(taskID) {
        const response = await fetch(`/api/tasks/${taskID}/watch`, {
            method: 'POST'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while watching task");
            console.log(response);
            return false;
        }
    }

    static async unwatchTask(taskID) {
        const response = await fetch(`/api/tasks/${taskID}/watch`, {
            method: 'DELETE'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while unwatching task");
            console.log(response);
            return false;
        }
    }

    static async getRecentlyOpenedTasks() {
        const response = await fetch(`/api/recentlyopenedtasks`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return [];
        }
    }

    static async setRecentlyOpenedTasks(tasks) {
        const postData = {tasks};
        const response = await fetch(`/api/recentlyopenedtasks`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while setting recently opened tasks");
            console.log(response);
            return false;
        }
    }

    static async getPinnedTasks() {
        const response = await fetch(`/api/pinnedtasks`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return [];
        }
    }

    static async setPinnedTasks(tasks) {
        const postData = {tasks};
        const response = await fetch(`/api/pinnedtasks`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while setting pinned tasks");
            console.log(response);
            return false;
        }
    }

    static async getScans() {
        const response = await fetch(`/api/scans`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async updateScan(scanID, fieldsToUpdate) {
        const response = await fetch(`/api/scans/${scanID}`, {
            method: 'PATCH',
            body: JSON.stringify(fieldsToUpdate),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return null;
        }
    }

    static async getScan(scanID) {
        const response = await fetch(`/api/scans/${scanID}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async getUnprocessedScanCount(userID) {
        const query = qs.stringify({user_id: userID});
        const response = await fetch(`/api/scans/unacknowledgedcount?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return {count: 0};
        }
    }

    static async markScanRead(scanID) {
        const response = await fetch(`/api/scans/${scanID}/markread`, {
            method: 'PUT'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while marking scan as read");
            console.log(response);
            return false;
        }
    }

    static async getScansArchive(offset=0) {
        const query = qs.stringify({"offset": offset});
        const response = await fetch(`/api/scans/archive?${query}`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async addScanActionHistoryItem(kind, scanID, authorName, description, uri) {
        const postData = {kind, scan_id: scanID, author_name: authorName, description, uri};
        const response = await fetch(`/api/scans/${scanID}/actionhistory`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while add action history item");
            console.log(response);
            return false;
        }
    }

    static async getScanActionHistory(key, {scanID}) {
        const response = await fetch(`/api/scans/${scanID}/actionhistory`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return [];
        }
    }

    static async createScan(file, assigneeID, teamID) {
        var data = new FormData();
        data.append('file', file);
        if (assigneeID) {
            data.append('assignee_id', assigneeID);
        }
        if (teamID) {
            data.append('team_id', teamID);
        }

        const response = await fetch(`/api/scans`, {
            method: 'POST',
            body: data
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Error occurred while uploading file");
            console.log(response);
            return null;
        }
    }

    static async getScanComments(scanID) {
        const response = await fetch (`/api/scans/${scanID}/comments`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to get comments for scan ${scanID}`)
            return [];
        }
    }

    static async createScanComment(scanID, text) {
        const postData = {
            text
        };

        const response = await fetch(`/api/scans/${scanID}/comments`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Unable to create scan comment")
            return null;
        }
    }

    static async markScanMentionsRead(scanID) {
        const response = await fetch(`/api/scanmentions/${scanID}/markread`, {
            method: 'PUT'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while marking mentions as read");
            console.log(response);
            return false;
        }
    }

    static async getScanMentions() {
        const response = await fetch(`/api/scanmentions`);
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            return null;
        }
    }

    static async watchScan(scanID) {
        const response = await fetch(`/api/scans/${scanID}/watch`, {
            method: 'POST'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while watching scan");
            console.log(response);
            return false;
        }
    }

    static async unwatchScan(scanID) {
        const response = await fetch(`/api/scans/${scanID}/watch`, {
            method: 'DELETE'
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred while unwatching scan");
            console.log(response);
            return false;
        }
    }


    //
    // Form stuff
    //

    static async savePostHearingReport(attorneyName, notes, status, matterID) {
        const formData  = new FormData();
        formData.append('attorneyname', attorneyName);
        formData.append('detail', notes);
        formData.append('status', status);
        const response = await fetch(`/api/posthearingreport/${matterID}`, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveVerifyAppeal(appealVerified, notifTitle, notifDetail, noteSubject, noteDetail, matterID) {
        const formData  = new FormData();
        formData.append('title', notifTitle);
        formData.append('detail', notifDetail);
        formData.append('note_subject', noteSubject);
        formData.append('note_detail', noteDetail);

        const endpoint = appealVerified ? `/api/appealprocessed/${matterID}` : `/api/couldnotverifyappeal/${matterID}`;
        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveObstructionReview(obstructionResolved, historyTitle, historyDetail, noteSubject, noteDetail, matterID) {
        let formData  = null;
        let endpoint = null;

        if (obstructionResolved) {
            endpoint = `/api/clearobstructed/${matterID}`;
        } else {
            formData  = new FormData();
            formData.append('title', historyTitle);
            formData.append('detail', historyDetail);
            formData.append('note_subject', noteSubject);
            formData.append('note_detail', noteDetail);
            endpoint = `/api/stillobstructed/${matterID}`;
        }
        console.log(`POSTING TO ENDPOINT ${endpoint}`)
        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        console.log({response})
        if (response.status === 200) {
            const result =  await response.json();
            console.log({result})
            return result;
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async postZap(zapURL, postData) {
        const response = await fetch(zapURL, {
            method: 'POST',
            body: JSON.stringify(postData)
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log("Unable to trigger zap")
            return null;
        }
    }

    static async saveLimitationsReminder(appealFiledNotMuted, requesterName, reason, matterID) {
        if (appealFiledNotMuted === false) {
            const formData  = new FormData();
            formData.append('requestername', requesterName);
            formData.append('reason', reason);
            const endpoint = `/api/mutelimitationsalerts/${matterID}`;
            const response = await fetch(endpoint, {
                method: 'POST',
                body: formData
            });
            if (response.status === 200) {
                return await response.json();
            } else {
                return {success: false, msg: "An error occurred while saving to the server. Please retry."};
            }

        } else {
            return {success: true, msg: "Thank you for confirming."};
        }
    }

    static async saveReopenReminder(submitted, submitterName, reason, detail, matterID) {
        let formData  = new FormData();
        let endpoint = null;

        if (submitted) {
            endpoint = `/api/submittedreopen/${matterID}`;
            formData.append('name', submitterName);
            formData.append('reason', reason);
        } else {
            endpoint = `/api/noreopennecessary/${matterID}`;
            formData.append('name', submitterName);
            formData.append('detail', detail);
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveVerifyReopen(hearingDateSet, hearingEventTitle, hearingDate, hearingStartTime, hearingEndTime, hearingLocation, notifTitle, notifDetail, matterID) {
        const formData = new FormData();
        let endpoint = null;

        if (hearingDateSet) {
            formData.append('title', hearingEventTitle);
            formData.append('date', hearingDate);
            formData.append('starttime', hearingStartTime);
            formData.append('endtime', hearingEndTime);
            formData.append('location', hearingLocation);
            endpoint = `/api/hearingdateset/${matterID}`;
        } else {
            formData.append('notiftitle', notifTitle);
            formData.append('notifdetail', notifDetail);
            endpoint = `/api/stillwaitingforhearingdate/${matterID}`;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async savePrepMeeting(attorneyName, noteDetail, matterID) {
        const endpoint = `/api/prepmeeting/${matterID}`;
        const formData  = new FormData();
        formData.append('attorneyname', attorneyName);
        formData.append('detail', noteDetail);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async savePhoneHearingRequest(action, matterID) {
        const endpoint = `/api/phonehearingrequest/${matterID}`;
        const formData  = new FormData();
        formData.append('action', action);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveOrderDebitCard(cardOrdered, notes, username, matterID) {
        let endpoint = null;
        let formData  = null;

        if (cardOrdered) {
            formData = new FormData();
            formData.append('name', username);
            formData.append('notes', notes);
            endpoint = `/api/ordernewcard_done/${matterID}`;
        } else {
            endpoint = `/api/ordernewcard_doesnotcooperate/${matterID}`;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveVerifyDebitCard(flag1, notes1, flag2, notes2, username, matterID) {
        const endpoint = `/api/verifydebitcard/${matterID}`;
        const formData  = new FormData();
        formData.append('name', username);
        formData.append('flag1', flag1);
        formData.append('notes1', notes1);
        formData.append('flag2', flag2);
        formData.append('notes2', notes2);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveNonAppealReport(share, notes, caseType, username, matterID) {
        const endpoint = `/api/nonappealreport/${matterID}`;
        const formData  = new FormData();
        formData.append('name', username);
        formData.append('share', share);
        formData.append('notes', notes);
        formData.append('case_type', caseType);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveClaimReopenRequired(canReopen, canReopenReason, withClient, withClientReason, explained, explainedReason, workAndEarningsReported, workAndEarnings, backdatingNecessary, furtherCertificationsAvailable, notes, username, matterID) {
        const endpoint = `/api/claimreopenrequired/${matterID}`;
        const formData  = new FormData();
        formData.append('name', username);
        formData.append('notes', notes);
        formData.append('can_reopen', canReopen);
        formData.append('can_reopen_reason', canReopenReason);
        formData.append('with_client', withClient);
        formData.append('with_client_reason', withClientReason);
        formData.append('explained', explained);
        formData.append('explained_reason', explainedReason);
        formData.append('work_and_earnings_reported', workAndEarningsReported);
        formData.append('work_and_earnings', workAndEarnings);
        formData.append('backdating_necessary', backdatingNecessary);
        formData.append('further_certifications_available', furtherCertificationsAvailable);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveClaimCertificationRequired(withClient, withClientReason, explained, explainedReason, workAndEarningsReported, workAndEarnings, backdatingNecessary, notes, username, matterID) {
        const endpoint = `/api/claimcertificationrequired/${matterID}`;
        const formData  = new FormData();
        formData.append('name', username);
        formData.append('notes', notes);
        formData.append('with_client', withClient);
        formData.append('with_client_reason', withClientReason);
        formData.append('explained', explained);
        formData.append('explained_reason', explainedReason);
        formData.append('work_and_earnings_reported', workAndEarningsReported);
        formData.append('work_and_earnings', workAndEarnings);
        formData.append('backdating_necessary', backdatingNecessary);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveRegisterClaim(registeredClaim, credentials, username, matterID) {
        let endpoint = null;
        const formData  = new FormData();
        formData.append('name', username);

        if (registeredClaim) {
            endpoint = `/api/claimregistered/${matterID}`;
            formData.append('credentials', credentials);
        } else {
            endpoint = `/api/cannotregisterclaim/${matterID}`;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async saveCannotRegisterClaim(clientDeposited, explanation, username, matterID) {
        let endpoint = null;
        const formData  = new FormData();
        formData.append('name', username);

        if (clientDeposited) {
            endpoint = `/api/clientdeposited/${matterID}`;
        } else {
            endpoint = `/api/clientcannotdeposit/${matterID}`;
            formData.append('explanation', explanation);
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }

    static async savePasswordChange(passwordVerified, newPassword, notes, username, matterID) {
        let endpoint = null;
        const formData  = new FormData();
        formData.append('name', username);

        if (passwordVerified) {
            endpoint = `/api/passwordverified/${matterID}`;
            formData.append('newpwd', newPassword);
        } else {
            endpoint = `/api/passwordchanged_doesnotcooperate/${matterID}`;
            formData.append('notes', notes);
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            return {success: false, msg: "An error occurred while saving to the server. Please retry."};
        }
    }
}
