import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as Constants from '../../common/webconstants.js';
import useMatterInfo from '../../common/useMatterInfo.js';
import { get } from 'lodash';

function getRequestStatus(matterInfo) {
    if (matterInfo === null) return "";
    if (matterInfo.phone_hearing === Constants.CLIENT_PHONE_HEARING_REQUESTED) return "requested";
    if (matterInfo.phone_hearing === Constants.CLIENT_PHONE_HEARING_APPROVED) return "approved";
    if (matterInfo.phone_hearing === Constants.CLIENT_PHONE_HEARING_DENIED) return "denied";
    if (matterInfo.phone_hearing === Constants.CLIENT_PHONE_HEARING_NA) return "N/A";
    return "";
}

function PhoneHearingRequest({caseNumber, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [matterInfo, loading, errMsg] = useMatterInfo(caseNumber);
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        newRequestStatus: null
    });
    
    const newRequestStatus = get(formState, "newRequestStatus.value", null);
    const saveForm = useCallback(async (matterID) => {
        return await API.savePhoneHearingRequest(newRequestStatus, matterID);
    }, [newRequestStatus]);

    const possibleStatus = [
        {label: "Approved", value: "approve"}, 
        {label: "Denied", value: "deny"}
    ];

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else if (errMsg) {
        return (
            <div>
                <div className="ui visible error message" style={{marginTop: 20}}>{errMsg}</div>
            </div>
        );
    } else {
        return (
            <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={formState.requestStatus === ""} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
                <div className="field">
                    <p>This request is currently marked as: {getRequestStatus(matterInfo)}</p>
                </div>
                <div className="field w-64">
                    <label htmlFor="action">Update the request status to:</label>
                    <Dropdown placeholder="Please select" options={possibleStatus} onChange={option => updateFormState({newRequestStatus: option})} value={formState.newRequestStatus}/>
                </div>
            </TaskForm>
        );
    }
}

export default PhoneHearingRequest;
