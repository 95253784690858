/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';

function Center({children}) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            {children}
        </div>
    )
}

export default Center;
