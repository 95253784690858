import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import useMatterInfo from '../../common/useMatterInfo.js';
import withLoader from '../../common/withLoader.js';
import EDDCredentialsMessage from '../eddcredentialsmessage.js';


function triState(v) {
    if (v === null) return null;
    if (v.value === "Yes") return true;
    if (v.value === "No") return false;
    return null;
}

function ClaimCertificationRequired({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [matterInfo, loading, errMsg] = useMatterInfo(caseNumber);
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        withClient: null,
        withClientReason: "",
        explained: null,
        explainedReason: "",
        workAndEarningsReported: null,
        workAndEarnings: "",
        backdatingNecessary: null,
        notes: ""
    });

    const withClient = triState(formState.withClient);
    const explained = triState(formState.explained);
    const workAndEarningsReported = triState(formState.workAndEarningsReported);
    const backdatingNecessary = triState(formState.backdatingNecessary);
    
    const saveForm = useCallback(async (matterID) => {
        return await API.saveClaimCertificationRequired(
                withClient, formState.withClientReason, 
                explained, formState.explainedReason,
                workAndEarningsReported, formState.workAndEarnings,
                backdatingNecessary,
                formState.notes, username, matterID);
    }, [withClient, formState.withClientReason, explained, formState.explainedReason, workAndEarningsReported, formState.workAndEarnings, backdatingNecessary, formState.notes, username]);

    const yesNoOptions = ["Yes", "No"];
        
    const withClientDropdown = (
        <div className="field eight wide">
            <label htmlFor="with_client">1. Are you helping the client certify for benefits with the client on the phone, with the client's understanding, and at the client's request for assistance?</label>
            <Dropdown placeholder="Please select" options={yesNoOptions} onChange={option => updateFormState({withClient: option})} value={formState.withClient} required/>
        </div>
    );

    const withClientTextfield = (
        <div className="field eight wide">
            <label htmlFor="with_client_reason">Why not?</label>
            <input type="text" name="with_client_reason" value={formState.withClientReason} onChange={ev => updateFormState({withClientReason: ev.target.value})} placeholder="Explain why not" required/>
        </div>
    );

    const explainedDropdown = (
        <div className="field eight wide">
            <label for="explained">2. Did you read and explain all written content to the client?</label>
            <Dropdown placeholder="Please select" options={yesNoOptions} onChange={option => updateFormState({explained: option})} value={formState.explained} required/>
        </div>
    );

    const explainedTextfield = (
        <div className="field eight wide">
            <label htmlFor="explained_reason">Why not?</label>
            <input type="text" name="explained_reason" value={formState.explainedReason} placeholder="Explain why not" onChange={ev => updateFormState({explainedReason: ev.target.value})} required/>
        </div>
    );

    const workAndEarningsReportedDropdown = (
        <div className="field eight wide">
            <label htmlFor="work_and_earnings_reported">3. Did the client report any work and earnings?</label>
            <Dropdown placeholder="Please select" options={yesNoOptions} onChange={option => updateFormState({workAndEarningsReported: option})} value={formState.workAndEarningsReported} required/>
        </div>
    );

    const workAndEarningsTextfield = (
        <div className="field eight wide">
            <label htmlFor="work_and_earnings">Client report</label>
            <textarea type="text" name="work_and_earnings" rows="4" value={formState.workAndEarnings} placeholder="Enter client report" onChange={ev => updateFormState({workAndEarnings: ev.target.value})} required></textarea>
        </div>
    );

    const backdatingNecessaryDropdown = (
        <div className="field eight wide">
            <label htmlFor="backdating_necessary">4. Is it necessary to backdate certifications?</label>
            <Dropdown placeholder="Please select" options={yesNoOptions} onChange={option => updateFormState({backdatingNecessary: option})} value={formState.backdatingNecessary} required/>
        </div>
    );

    const warningBox = (
        <div className="ui red message">
            <div className="ui grid">
                <div className="one wide column">
                    <i className="icon huge exclamation triangle"/>
                </div>
                <div className="fifteen wide column" style={{paddingLeft: 30}}>
                    Never submit a certification until you made sure the client completely understands the submission and that you do so with the client's consent and understanding.
                </div>
            </div>
        </div>
    );

    const notesTextfield = (
        <div className="field eight wide">
            <label htmlFor="notes">Optional Notes</label>
            <textarea type="text" name="notes" rows="4" value={formState.notes} placeholder="Enter notes..." onChange={ev => updateFormState({notes: ev.target.value})}></textarea>
        </div>
    );

    function formComplete() {
        if (withClient === false && formState.withClientReason !== "") return true;
        if (explained === false && formState.explainedReason !== "") return true;
        if (workAndEarningsReported && formState.workAndEarnings === "") return false;
        if (withClient && explained && workAndEarningsReported !== null && backdatingNecessary !== null) return true;
        return false;
    }

    return withLoader(loading, errMsg, () => (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={!formComplete()} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <p>Please re-open the EDD online account. Note: <b>NEVER</b> complete this step without the Client on the phone.</p>
            <EDDCredentialsMessage matterInfo={matterInfo}/>
            
            {withClientDropdown}
            {withClient === false && withClientTextfield}
            {withClient === false && warningBox}
            
            {withClient && explainedDropdown}
            {withClient && explained === false && explainedTextfield}
            {withClient && explained === false && warningBox}

            {withClient && explained && workAndEarningsReportedDropdown}
            {withClient && explained && workAndEarningsReported === true && workAndEarningsTextfield}

            {withClient && explained && workAndEarningsReported !== null && backdatingNecessaryDropdown}

            <div className="ui divider"/>
            {notesTextfield}
        </TaskForm>
    ));
}

export default ClaimCertificationRequired;
