/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';

function Card({ header, footer, children, stretchWide }) {
    const containerStyle = {};
    if (stretchWide) containerStyle.width = '100%';
    const headerStyle = {
        backgroundColor: 'white',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        flexGrow: 0,
        flexDirection: 'column',
    };
    const footerStyle = {
        ...headerStyle,
        flexDirection: 'row',
    };
    const contentMainStyle = {
        backgroundColor: '#F8F8F8',
        paddingTop: 0,
        paddingBottom: 0,
        borderTop: 'none',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
    };

    return (
        <div className="ui raised card" style={containerStyle}>
            {header && (
                <div className="content" style={headerStyle}>
                    {header}
                </div>
            )}
            <div className="content" style={contentMainStyle}>
                {children}
            </div>
            {footer && (
                <div className="content" style={footerStyle}>
                    {footer}
                </div>
            )}
        </div>
    )
}

export default Card
