/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import SearchBox from "../components/searchbox.js";
import Select from "react-select";
import AnimateHeight from "react-animate-height";
import generateSelectOptions from "../common/generateSelectOptions.js";

function TasksArchivePageFilterBar({
    allCaseNumbers,
    possibleAssignees,
    possibleFormTypes,
    onCaseNumberSearchBoxCommit,
    caseNumber,
    assigneeFilter,
    setAssigneeFilter,
    formTypeFilter,
    setFormTypeFilter,
    onTextSearchBoxCommit,
    searchText,
    clearFilters,
    visible,
}) {
    const caseNumberSearchbox = (
        <SearchBox
            allPossibleValues={allCaseNumbers}
            onCommit={onCaseNumberSearchBoxCommit}
            initialValue={caseNumber || ""}
        />
    );
    const assigneeSelect = (
        <Select
            options={generateSelectOptions(possibleAssignees)}
            placeholder="Filter by assignee"
            className="min-w-64"
            value={assigneeFilter}
            onChange={(option) => {
                if (option && option.length === 0) option = null;
                setAssigneeFilter(option);
            }}
            isClearable={true}
            closeMenuOnSelect={false}
            isMulti
        />
    );
    const formTypeSelect = (
        <Select
            options={possibleFormTypes}
            placeholder="Filter by type"
            className="min-w-64"
            value={formTypeFilter}
            onChange={(option) => setFormTypeFilter(option)}
            isClearable={true}
            closeMenuOnSelect={false}
            isMulti
        />
    );
    const textSearchbox = (
        <SearchBox
            allPossibleValues={[]}
            onCommit={onTextSearchBoxCommit}
            initialValue={searchText || ""}
            placeholder="Filter on text..."
            large
        />
    );

    const clearFilterButton = (assigneeFilter !== null ||
        caseNumber !== null ||
        formTypeFilter !== null ||
        searchText !== null) && (
        <div>
            <button className="ui tertiary button" onClick={clearFilters}>
                Reset filters
            </button>
        </div>
    );

    return (
        <AnimateHeight duration={250} height={visible ? "auto" : 0}>
            <div className="filter-bar">
                {caseNumberSearchbox}
                {assigneeSelect}
                {formTypeSelect}
                {textSearchbox}
                {clearFilterButton}
            </div>
        </AnimateHeight>
    );
}

export default TasksArchivePageFilterBar;
