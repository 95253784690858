import { useState, useCallback, useEffect } from 'react';
import API from './api.js';
import usePrevious from '../common/usePrevious.js';

function useInboxMessageData(messageID, setLoading, setErrMsg, onUpdate) {
    const [message, setMessage] = useState(null);
    const prevMessageID = usePrevious(messageID);

    const loadMessage = useCallback(async () => {
        setLoading(true);

        const data = await API.getInboxMessage(messageID);
        if (data) {
            setMessage(data);
            onUpdate(data);
            setLoading(false);
            
        } else {
            setErrMsg("Unable to load data from server");
            setLoading(false);
        }
    }, [messageID, setLoading, setErrMsg, onUpdate]);

    useEffect(() => {
        setMessage(null);
        if (messageID) {
            if (messageID !== prevMessageID) {
                loadMessage();
            }
        }
    }, [messageID, loadMessage, prevMessageID, setErrMsg]);

    return [message];
}

export default useInboxMessageData;
