import React, { useMemo } from 'react';
import * as Constants from '../common/webconstants.js';

const DiskIndicator = ({ color }) => {
    const size = 7;
    const background = useMemo(() => {
        switch (color) {
            case 'green':
                return '#00c800';
            case 'red':
                return '#dd1c12';
            case 'yellow':
                return '#ddda11';
            default:
                return 'gray';
        }
    }, [color]);

    return (
        <div
            style={{
                width: size,
                height: size,
                borderRadius: size,
                background,
            }}
        />
    );
};

const colorForUser = (user) => {
    switch (user.onCallStatus.status) {
        case Constants.ON_CALL_STATUS_ON_CALL:
            return 'green';
        case Constants.ON_CALL_STATUS_TEMPORARILY_OFF_CALL:
            return 'yellow';
        default:
            return 'red';
    }
}

const userPriority = (user) => {
    switch (user.onCallStatus.status) {
        case Constants.ON_CALL_STATUS_ON_CALL:
            return 1;
        case Constants.ON_CALL_STATUS_TEMPORARILY_OFF_CALL:
            return 0;
        default:
            return -1;
    }
}

const tooltip = (user) => {
    switch (user.onCallStatus.status) {
        case Constants.ON_CALL_STATUS_ON_CALL:
            return `${user.name} is online and ready to take calls.`;
        case Constants.ON_CALL_STATUS_TEMPORARILY_OFF_CALL:
            return `${user.name} is temporarily offline.`;
        default:
            return `${user.name} is not available right now.`;
    }
}

function QueuePage({ allUsers }) {
    const attorneyUsers = useMemo(() => {
        return allUsers
            .filter((user) => user.isAttorney)
            .map((user) => {
                return {
                    ...user,
                    priority: userPriority(user),
                };
            })
            .sort(((userA, userB) => userB.priority - userA.priority));
    }, [allUsers]);

    return (
        <div style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 10, height: '100%', overflowY: 'auto' }}>
            <h1 className="ui header">Attorneys</h1>
            {attorneyUsers.map((attorneyUser) => (
                <div
                    key={attorneyUser.user_id}
                    style={{
                        marginBottom: 8,
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                        }}
                        data-tooltip={tooltip(attorneyUser)}
                        data-position="right center"
                    >
                        <div>{attorneyUser.name}</div>
                        <DiskIndicator color={colorForUser(attorneyUser)} />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default QueuePage;
