import React, { useContext } from 'react';
import { CurrentUserContext } from '../../common/currentUserContext.js';
import AttorneyStatistics from './attorneystatistics.js';

const AttorneyDashboard = () => {
    const currentUser = useContext(CurrentUserContext);

    return (
        <AttorneyStatistics attorneyUserId={currentUser.userID} />
    );
};

export default AttorneyDashboard;
