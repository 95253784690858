import React, { useState, useCallback, useEffect } from 'react';
import API from '../common/api.js';
import classNames from 'classnames';
import { useHistory } from "react-router-dom";

function EditTeamPage({teamID}) {
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    let history = useHistory();
    const valid = name !== "";

    const loadData = useCallback(async () => {
        const teamData = await API.getTeam(teamID);
        if (teamData) {
            setName(teamData.name);
        } else {
            setErrMsg("Unable to load data from server");
        }
        setLoading(false);
    }, [teamID]);

    useEffect(() => {
        loadData();
    }, [loadData]);
    
    async function save() {
        setSaving(true);
        const success = await API.editTeam(teamID, name);
        if (success) {
            history.push("/settings");
        } else {
            setErrMsg("ERROR: Saving team failed! Please retry.");
            setSaving(false);
        }
    }

    
    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;
    
    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else {
        return (
            <div>
                {errmsgEl}
                <h1 className="ui header">Edit Team</h1>
                <div className="ui form segment">
                    <div className="field">
                        <label htmlFor="name">Name:</label>
                        <input type="text" name="name" value={name} placeholder="Enter name" onChange={(ev) => setName(ev.target.value)} required/>
                    </div>
                    
                    <button className={classNames("ui primary button", {loading: saving, disabled: !valid})} onClick={save}>Save</button>
                    <button className="ui black basic button" onClick={() => history.push("/settings")}>Cancel</button>
                </div>
            </div>
        );
    }
}

export default EditTeamPage;
