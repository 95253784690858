import React, { useContext, useEffect } from "react";
import EditableCaseNumber from "../components/editablecasenumber.js";
import Select from "react-select";
import "./taskcard.css";
import MatterQuickView from "../components/matterquickview.js";
import { Link } from "react-router-dom";
import PDFViewer from "../components/pdfviewer.js";
import AudioPlayer from "../components/audioplayer.js";
import { ToastContext } from "../common/toastContext.js";
import { formatPhoneNumberFromString } from "../common/formatting.js";
import API from "../common/api.js";
import usePreventBodyScroll from "../common/usePreventBodyScroll.js";
import generateSelectOptions from "../common/generateSelectOptions.js";
import { useHistory } from "react-router-dom";
import useComments from "../common/useComments.js";
import { CurrentUserContext } from "../common/currentUserContext.js";
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';
import { get } from "lodash";
import { formatHumanDateTime } from "../common/formatting.js";
const ReactMarkdown = require("react-markdown");
var classNames = require("classnames");

const messageCommentsApi = {
    getComments: API.getInboxMessageComments,
    markMentionsRead: API.markInboxMessageMentionsRead,
    createComment: API.createInboxMessageComment,
    createMentionMessage: API.createInboxMessageMentionMessage,
};

function MessageCard({
    message,
    onCloseClicked,
    setMessageAssignee,
    setMessageAcknowledged,
    possibleAssignees,
    allCaseNumbers,
    toggleWatched,
    readOnly,
    leftSiblingLink,
    rightSiblingLink,
}) {
    const toastIF = useContext(ToastContext);
    const currentUser = useContext(CurrentUserContext);
    usePreventBodyScroll();
    let history = useHistory();
    usePubSub({topic: Constants.PUBSUB_TOPIC_INBOX_MESSAGE, messageHandler: handleServerMessage});

    const msgID = message && message.msg_id;
    const { comments, setComments, commentsSection, toggleCommentsButton } = useComments(
        msgID,
        message,
        messageCommentsApi,
        possibleAssignees,
        readOnly,
        currentUser
    );

    function markMessageProcessed(msg) {
        setMessageAcknowledged(message);
        toastIF.addToast(msg);
        onCloseClicked();
    }

    useEffect(() => {
        API.markInboxMessageRead(message.msg_id);
    }, [message.msg_id]);

    function reworkMessage() {
        history.push(`/rework_inbox_message?messageID=${message.msg_id}`);
    }

    function handleServerMessage(topic, data) {
        if (parseInt(get(data, "msg_id")) === msgID) {
            if (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_COMMENT_ADDED) {
                const newComments = [data, ...comments];
                setComments(newComments);
            }
        }
    }

    async function handleChangeCaseNumber(v) {
        await API.updateInboxMessage(msgID, { case_number: v.case_number });
    }

    if (message === null) return null;

    const headerStyle = {
        backgroundColor: "white",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        flexGrow: 0,
        flexDirection: "column",
    };
    const footerStyle = { ...headerStyle, flexDirection: "row", minHeight: 44 };
    const closeButtonStyle = { position: "absolute", right: 10, top: 10, padding: 7 };
    const headerFieldsStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingRight: 30,
        alignItems: "center",
    };
    const titleFieldStyle = { flexGrow: 1 };
    const contentSectionParentStyle = {
        backgroundColor: "#F8F8F8",
        paddingTop: 0,
        paddingBottom: 0,
        borderTop: "none",
        maxHeight: "calc(100vh - 200px)",
        overflowY: "auto",
    };
    const contentSectionStyle = {
        backgroundColor: "#F8F8F8",
        paddingTop: "2em",
        paddingBottom: "2em",
        borderTop: "none",
    };
    const stretcherStyle = { ...contentSectionStyle, flexGrow: 1000, height: 0, border: "none", padding: 0 };
    const insetStyle = { paddingLeft: "3.3em" };
    const modalContentStyle = {
        display: "flex",
        zIndex: 2,
        minHeight: "60vh",
        maxHeight: "80vh",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        maxWidth: 1500,
    };

    const faxDetails = message.kind === Constants.MESSAGE_KIND_FAX && (
        <>
            <p>
                Fax received from{" "}
                {message.sender_number ? formatPhoneNumberFromString(message.sender_number, "US") : "an unknown number"}
            </p>
            <PDFViewer uri={message.uri} />
        </>
    );
    const voicemailDetails = message.kind === Constants.MESSAGE_KIND_VOICEMAIL && (
        <>
            <p>Name of caller: {message.name_of_caller}</p>
            <p>
                Callback number:{" "}
                {message.callback_number ? formatPhoneNumberFromString(message.callback_number, "US") : "Unknown"}
            </p>
            <AudioPlayer uri={message.uri} />
        </>
    );
    const callDetails = message.kind === Constants.MESSAGE_KIND_CALL && (
        <>
            <p>Name of caller: {message.name_of_caller}</p>
            <p>
                Callback number:{" "}
                {message.callback_number ? formatPhoneNumberFromString(message.callback_number, "US") : "Unknown"}
            </p>
        </>
    );

    let descriptionSection = null;
    if (message.description || faxDetails || voicemailDetails || callDetails) {
        descriptionSection = (
            <div className="content" style={contentSectionStyle}>
                <h3 className="ui header">
                    <i className="align left icon" /> Description
                </h3>
                <div className="taskcard-description" style={insetStyle}>
                    <ReactMarkdown source={message.description} />
                    {faxDetails}
                    {voicemailDetails}
                    {callDetails}
                </div>
            </div>
        );
    }

    let actionButtons = null;
    if (message.kind === Constants.MESSAGE_KIND_FAX) {
        actionButtons = (
            <div>
                <a
                    href={message.uri}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ui basic primary fluid labeled icon button ui"
                    style={{ marginBottom: "0.5em", textAlign: "left" }}
                >
                    <i className="external alternate icon" /> Open In New Window
                </a>
                <Link
                    to={`/upload?messageID=${message.msg_id}`}
                    className="ui basic primary fluid labeled icon button"
                    style={{ marginBottom: "0.5em", textAlign: "left" }}
                >
                    <i className="upload icon" /> Upload To Clio
                </Link>
            </div>
        );
    } else if (message.kind === Constants.MESSAGE_KIND_VOICEMAIL) {
        actionButtons = (
            <div>
                <Link
                    to={`/upload?messageID=${message.msg_id}`}
                    className="ui basic primary fluid labeled icon button"
                    style={{ marginBottom: "0.5em", textAlign: "left" }}
                >
                    <i className="upload icon" /> Upload To Clio
                </Link>
            </div>
        );
    }

    const assignee = message.assignee_id
        ? possibleAssignees.find((a) => a.is_user && a.id === message.assignee_id)
        : possibleAssignees.find((a) => a.is_team && a.id === message.team_id);
    const assigneeSelectStyles = {
        container: (styles) => ({ ...styles, zIndex: 100, color: "black", flexGrow: 1 }),
        control: (styles) => ({ ...styles, borderWidth: 0, ":hover": { cursor: "pointer" } }),
        singleValue: (styles) => ({ ...styles, ":hover": { cursor: "pointer", color: "#1e70bf" } }),
        indicatorsContainer: (styles) => ({ ...styles, display: "none" }),
    };
    const assigneeIcon = message.assignee_id ? "user icon" : "users icon";
    const assigneeSelect = readOnly ? (
        <div
            style={{
                width: 300,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
        >
            <i className={`fitted ${assigneeIcon} mr-4`} style={{ lineHeight: "1.1em", paddingRight: 10 }} />
            {assignee.label}
        </div>
    ) : (
        <div
            style={{
                width: 300,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
            data-tooltip="Who is this message assigned to? Click to re-assign"
            data-position="right center"
        >
            <i className={`fitted ${assigneeIcon} mr-4`} style={{ lineHeight: "1.1em" }} />
            <Select
                options={generateSelectOptions(possibleAssignees)}
                styles={assigneeSelectStyles}
                value={assignee}
                onChange={(option) => setMessageAssignee(message, option)}
                menuPlacement="top"
            />
        </div>
    );

    const quickView = message.case_number && message.case_number.length > 0 && (
        <div className="modal-rail">
            <div className="ui segment" style={{ height: "100%", overflowY: "scroll" }}>
                <MatterQuickView caseNumber={message.case_number} />
            </div>
        </div>
    );

    const stretcher = <div className="content" style={stretcherStyle}></div>;

    const humanCreationDate = formatHumanDateTime(message.created_at);
    const humanAcknowledgedAt = formatHumanDateTime(message.acknowledged_at);

    const toggleWatchButton = readOnly || (
        <button className={classNames("ui fluid labeled icon toggle button", {active: message.is_watched_by_current_user})} style={{marginBottom: '0.5em', textAlign: 'left'}} onClick={() => toggleWatched(message)}><i className="eye icon"/>Watch Message</button>
    );

    const messageDetails = (
        <div key={message.msg_id} className="ui raised fluid card">
            <div className="content" style={headerStyle}>
                <div style={{ ...headerFieldsStyle, marginBottom: "0.5em" }}>
                    <span style={closeButtonStyle}>
                        <div className="ui compact mini icon buttons" style={{ marginRight: 20 }}>
                            <Link
                                to={leftSiblingLink || ""}
                                className={classNames("ui button", { disabled: leftSiblingLink === null })}
                            >
                                <i className="chevron left icon" />
                            </Link>
                            <Link
                                to={rightSiblingLink || ""}
                                className={classNames("ui button", { disabled: rightSiblingLink === null })}
                            >
                                <i className="chevron right icon" />
                            </Link>
                        </div>
                        <span onClick={onCloseClicked}>
                            <i className="fitted black close link icon" />
                        </span>
                    </span>
                    <div style={headerFieldsStyle}>
                        <div style={titleFieldStyle}>
                            <h2 className="mr-4">
                                <i className={`${Constants.MESSAGE_KIND_ICONS[message.kind]} icon`} /> {message.subject}
                            </h2>
                        </div>
                    </div>
                </div>
                <div style={{ ...headerFieldsStyle, paddingRight: 0 }}>
                    <div style={{ marginRight: "2em", width: 300 }}>
                        <EditableCaseNumber
                            initialValue={message.case_number}
                            allCaseNumbers={allCaseNumbers}
                            onChange={handleChangeCaseNumber}
                        />
                    </div>
                    <div className="flex">
                        <div className={`ui ${Constants.MESSAGE_PRIO_COLORS[message.priority]} horizonal label`}>
                            {Constants.MESSAGE_PRIO_LABELS[message.priority]}
                        </div>
                    </div>
                </div>
            </div>
            <div className="content" style={contentSectionParentStyle}>
                <div className="flex">
                    <div className="flex-grow">
                        {descriptionSection}
                        {stretcher}
                        {commentsSection}
                    </div>
                    <div
                        style={{
                            paddingLeft: "1em",
                            paddingRight: "1em",
                            marginTop: "4em",
                            width: 250,
                            flexGrow: 0,
                            flexShrink: 0,
                        }}
                    >
                        <p style={{ fontWeight: "bold", color: "#5F6D83" }}>SECTIONS</p>
                        {toggleCommentsButton}
                        {toggleWatchButton}

                        <p style={{ fontWeight: "bold", color: "#5F6D83", marginTop: "2em" }}>ABOUT MESSAGE</p>
                        {message.author_name && (
                            <div
                                style={{ marginRight: "2em", marginBottom: "0.25em" }}
                                data-tooltip="Who created this message?"
                                data-position="left center"
                            >
                                <i className="address card outline icon" /> {message.author_name}
                            </div>
                        )}
                        <div
                            style={{ marginRight: "2em", marginBottom: "0.25em" }}
                            data-tooltip="Created at"
                            data-position="left center"
                        >
                            <i className="calendar plus outline icon" /> {humanCreationDate}
                        </div>
                        {message.acknowledged_at && (
                            <div style={{ marginRight: "2em" }} data-tooltip="Processed at" data-position="left center">
                                <i className="calendar check outline icon" /> {humanAcknowledgedAt} by{" "}
                                {message.acknowledged_by}
                            </div>
                        )}

                        {actionButtons && (
                            <p style={{ fontWeight: "bold", color: "#5F6D83", marginTop: "2em" }}>ACTIONS</p>
                        )}
                        {actionButtons}
                    </div>
                </div>
            </div>
            <div className="content" style={footerStyle}>
                <div style={{ flexGrow: 1 }}>{assigneeSelect}</div>
                <div>
                    <Link to={`/add_task?messageID=${message.msg_id}`} className="ui basic primary labeled icon button">
                        <i className="tasks icon" /> Convert To Task
                    </Link>
                    {message.acknowledged || (
                        <button
                            className="ui primary labeled icon button"
                            onClick={() => markMessageProcessed(`Processed: ${message.subject}`)}
                        >
                            <i className="check icon" style={{ marginLeft: "auto" }} /> Processed
                        </button>
                    )}
                    {message.acknowledged && (
                        <button className="ui primary labeled icon button" onClick={reworkMessage}>
                            <i className="recycle icon" style={{ marginLeft: "auto" }} /> Rework Message
                        </button>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="modal-overlay" style={{ zIndex: 1 }} onClick={onCloseClicked} />
            <div style={modalContentStyle}>
                {messageDetails}
                {quickView}
            </div>
        </>
    );
}

export default MessageCard;
