/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import * as Constants from '../common/webconstants.js';
import { Link } from "react-router-dom";
var classNames = require('classnames');

function InboxPageTabs({
    activeTab,
    numMessages,
    numScans,
    numberOfUnreadMessages,
    actions,
    archive,
}) {
    function tab(tabID, title, uri) {
        return (
            <Link to={uri} className={classNames("item", {active: activeTab === tabID})}>
                {title}
            </Link>
        );
    }

    return (
        <div className="ui secondary pointing menu" style={{paddingBottom: 8, paddingTop: 10, marginBottom: 0, background: 'white'}}>
            {tab(Constants.INBOX_MESSAGES_TAB, `Messages (${numMessages})`, `/inbox/messages${archive ? '/archive' : ''}`)}
            {tab(Constants.INBOX_CHATS_TAB, `Chats${numberOfUnreadMessages === null ? '' : ` (${numberOfUnreadMessages})`}`, "/inbox/chats")}
            {tab(Constants.INBOX_SCANS_TAB, `Scans (${numScans})`, `/inbox/scans${archive ? '/archive' : ''}`)}

            <div className="right menu">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {actions}
                </div>
            </div>
        </div>
    );
}

export default InboxPageTabs;
