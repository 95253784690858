import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentUserContext } from '../common/currentUserContext.js';
import ConversationPage from './inboxpagechats/conversationpage.js';
import ConversationsPage from './inboxpagechats/conversationspage.js';
import MessagesSearchPage from './inboxpagechats/messagessearchpage.js';

const InboxPageChats = ({ conversationId, archive, conversationsFilters, messagesFullTextSearchValue, allUsers }) => {
    const currentUser = useContext(CurrentUserContext);
    const history = useHistory();
    const redirectToConversations = () => {
        history.push(archive ? '/inbox/chats/archive' : '/inbox/chats');
    };
    const redirectToConversation = (conversationId) => {
        history.push(`/inbox/chats${archive ? '/archive' : ''}/${conversationId}`);
    };
    const mergedConversationFilters = useMemo(() => {
        return {
            ...conversationsFilters,
            archived: archive ? true : false,
        };
    }, [conversationsFilters, archive]);

    return (
        <div
            style={{
                height: '70vh',
            }}
        >
            {conversationId ? (
                <ConversationPage
                    conversationId={conversationId}
                    redirectToConversations={redirectToConversations}
                    currentUser={currentUser}
                    archive={archive}
                    allUsers={allUsers}
                />
            ) : (
                <>
                    {messagesFullTextSearchValue.trim().length > 0 ? (
                        <MessagesSearchPage
                            redirectToConversation={redirectToConversation}
                            conversationsFilters={mergedConversationFilters}
                            messagesFullTextSearchValue={messagesFullTextSearchValue}
                        />
                    ) : (
                        <ConversationsPage
                            redirectToConversation={redirectToConversation}
                            currentUser={currentUser}
                            conversationsFilters={mergedConversationFilters}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default InboxPageChats;
