import { useMemo, useCallback } from 'react';
import useLocalStorage from '../../../common/useLocalStorage.js';
import { INTERVIEW_NODE_TYPES, CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS } from '../../../common/webconstants.js';
import API from '../../../common/api.js';

const useInterviewEditor = ({ activeInterview, reloadActiveInterview }) => {
    const [interview, setInterview] = useLocalStorage('intervieweditor.interview', activeInterview);
    const nodes = interview.nodes;
    const setNodes = useCallback((newNodes) => setInterview({ ...interview, nodes: newNodes }), [interview, setInterview]);
    const startNodeId = interview.startNodeId;
    const choiceQuestionOptions = interview.choiceQuestionOptions;
    const setChoiceQuestionOptions = useCallback((newChoiceQuestionOptions) => setInterview({ ...interview, choiceQuestionOptions: newChoiceQuestionOptions }), [interview, setInterview]);
    const multipleChoiceQuestionNodes = interview.multipleChoiceQuestionNodes;
    const setMultipleChoiceQuestionNodes = useCallback((newMultipleChoiceQuestionNodes) => setInterview({ ...interview, multipleChoiceQuestionNodes: newMultipleChoiceQuestionNodes }), [interview, setInterview]);
    const singleChoiceQuestionNodes = interview.singleChoiceQuestionNodes;
    const setSingleChoiceQuestionNodes = useCallback((newNodes) => setInterview({ ...interview, singleChoiceQuestionNodes: newNodes }), [interview, setInterview]);
    const conditionalNodes = interview.conditionalNodes;
    const setConditionalNodes = useCallback((newNodes) => setInterview({ ...interview, conditionalNodes: newNodes }), [interview, setInterview]);
    const createDocumentRequestNodes = interview.createDocumentRequestNodes;
    const documentTypes = interview.documentTypes;
    const setDocumentTypes = useCallback((newDocumentTypes) => setInterview({ ...interview, documentTypes: newDocumentTypes }), [interview, setInterview]);
    const nodesById = useMemo(() => {
        const byId = {};

        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            byId[node.id] = { node, index: i };
        }

        return byId;
    }, [nodes]);
    const getNodeById = useCallback((id) => {
        if (!id) console.error("getNodeById: id can't be null");

        const cachedNode = nodesById[id];

        if (!cachedNode) console.error("getNodeById: can't find node with id", id);

        return cachedNode.node;
    }, [nodesById]);
    const choiceQuestionOptionsById = useMemo(() => {
        const byId = {};

        for (let i = 0; i < choiceQuestionOptions.length; i++) {
            const choiceQuestionOption = choiceQuestionOptions[i];
            byId[choiceQuestionOption.id] = { choiceQuestionOption, index: i };
        }

        return byId;
    }, [choiceQuestionOptions]);
    const getChoiceQuestionOptionById = useCallback((id) => {
        return choiceQuestionOptionsById[id].choiceQuestionOption;
    }, [choiceQuestionOptionsById]);
    const conditionalNodesById = useMemo(() => {
        const byId = {};

        for (let i = 0; i < conditionalNodes.length; i++) {
            const conditionalNode = conditionalNodes[i];
            byId[conditionalNode.id] = { conditionalNode, index: i };
        }

        return byId;
    }, [conditionalNodes]);
    const getConditionalNodeById = useCallback((id) => conditionalNodesById[id].conditionalNode, [conditionalNodesById]);
    const singleChoiceQuestionNodesById = useMemo(() => {
        const byId = {};

        for (let i = 0; i < singleChoiceQuestionNodes.length; i++) {
            const question = singleChoiceQuestionNodes[i];
            byId[question.id] = { question, index: i };
        }

        return byId;
    }, [singleChoiceQuestionNodes]);
    const getSingleChoiceQuestionNodeById = useCallback((id) => {
        if (!id) console.error("getSingleChoiceQuestionNodeById: id can't be null");

        const cachedNode = singleChoiceQuestionNodesById[id];

        if (!cachedNode) console.error("getSingleChoiceQuestionNodeById: can't find node with id", id);

        return singleChoiceQuestionNodesById[id].question;
    }, [singleChoiceQuestionNodesById]);
    const createDocumentRequestNodesById = useMemo(() => {
        const byId = {};

        for (let i = 0; i < createDocumentRequestNodes.length; i++) {
            const createDocumentRequestNode = createDocumentRequestNodes[i];
            byId[createDocumentRequestNode.id] = { createDocumentRequestNode, index: i };
        }

        return byId;
    }, [createDocumentRequestNodes]);
    const getCreateDocumentRequestNodeById = useCallback((id) => {
        return createDocumentRequestNodesById[id].createDocumentRequestNode;
    }, [createDocumentRequestNodesById]);
    const documentTypesById = useMemo(() => {
        const byId = {};

        for (let i = 0; i < documentTypes.length; i++) {
            const documentType = documentTypes[i];
            byId[documentType.id] = { documentType, index: i };
        }

        return byId;
    }, [documentTypes]);
    const getDocumentTypeById = useCallback((id) => {
        return documentTypesById[id].documentType;
    }, [documentTypesById]);
    const multipleChoiceQuestionNodesById = useMemo(() => {
        const byId = {};

        for (let i = 0; i < multipleChoiceQuestionNodes.length; i++) {
            const question = multipleChoiceQuestionNodes[i];
            byId[question.id] = { question, index: i };
        }

        return byId;
    }, [multipleChoiceQuestionNodes]);
    const getMultipleChoiceQuestionNodeById = useCallback((id) => {
        return multipleChoiceQuestionNodesById[id].question;
    }, [multipleChoiceQuestionNodesById]);
    const deleteNode = (nodeId, { keepConditionMetPath, onDeletionComplete } = {}) => {
        const node = getNodeById(nodeId);
        let removedCqoIds = null;
        let newSingleChoiceQuestionNodes = interview.singleChoiceQuestionNodes;
        let newMultipleChoiceQuestionNodes = interview.multipleChoiceQuestionNodes;

        let replacementNodeId = node.nextInterviewNodeId;
        if (node.nodeType === INTERVIEW_NODE_TYPES.CONDITIONAL && keepConditionMetPath) {
            const conditionalNode = getConditionalNodeById(node.nodeId);
            replacementNodeId = conditionalNode.nextInterviewNodeIdConditionMet;
        }

        if (node.nodeType === INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION) {
            removedCqoIds = choiceQuestionOptions
                .filter(
                    (option) => (option.choiceQuestionType === node.nodeType
                        && option.choiceQuestionId === node.nodeId)
                )
                .map((option) => option.id)
            newSingleChoiceQuestionNodes = singleChoiceQuestionNodes.filter((scqNode) => scqNode.id !== node.nodeId);
        }

        if (node.nodeType === INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION) {
            removedCqoIds = choiceQuestionOptions
                .filter(
                    (option) => (option.choiceQuestionType === node.nodeType
                        && option.choiceQuestionId === node.nodeId)
                )
                .map((option) => option.id)
            newMultipleChoiceQuestionNodes = multipleChoiceQuestionNodes.filter((mcqNode) => mcqNode.id !== node.nodeId);
        }

        const newNodes = [];
        let superfluousConditionalNodeId = null;
        for (let i = 0; i < nodes.length; i++) {
            const node = { ...nodes[i] };

            if (node.id === nodeId) {
                if (node.nodeType === INTERVIEW_NODE_TYPES.CONDITIONAL) {
                    superfluousConditionalNodeId = node.nodeId;
                }

                continue;
            }

            if (node.nextInterviewNodeId === nodeId) {
                node.nextInterviewNodeId = replacementNodeId;
            }

            newNodes.push(node);
        }

        let newConditionalNodes = conditionalNodes;
        if (removedCqoIds) {
            newConditionalNodes = conditionalNodesWoReferencesToChoiceQuestionOptions(removedCqoIds);
        }
        const tempConditionalNodes = [];
        for (let i = 0; i < newConditionalNodes.length; i++) {
            const conditionalNode = { ...newConditionalNodes[i] };

            if (superfluousConditionalNodeId && superfluousConditionalNodeId === conditionalNode.id) {
                continue;
            }

            if (conditionalNode.nextInterviewNodeIdConditionMet === nodeId) {
                conditionalNode.nextInterviewNodeIdConditionMet = replacementNodeId;
            }

            tempConditionalNodes.push(conditionalNode);
        }
        newConditionalNodes = tempConditionalNodes;

        setInterview({
            ...interview,
            startNodeId: startNodeId === nodeId ? replacementNodeId : startNodeId,
            nodes: newNodes,
            conditionalNodes: newConditionalNodes,
            singleChoiceQuestionNodes: newSingleChoiceQuestionNodes,
            multipleChoiceQuestionNodes: newMultipleChoiceQuestionNodes,
        });

        if (onDeletionComplete) onDeletionComplete();
    };
    const insertNodeOnEdge = ({ targetNodeId, sourceNodeId, sourceNodeIdField }, { nodeType, params, onNodeInserted }) => {
        const highestExistingId = nodes.length === 0 ? 0 : Math.max(...nodes.map((node) => node.id));
        const newNode = {
            id: highestExistingId + 1,
            nodeType,
            nextInterviewNodeId: targetNodeId,
        };
        let newConditionalNode = null;
        let newSingleChoiceNode = null;
        let newMultipleChoiceNode = null;
        let newCreateDocumentRequestNode = null;

        if (nodeType === INTERVIEW_NODE_TYPES.CONDITIONAL) {
            newConditionalNode = addConditionalNode();
            newNode.nodeId = newConditionalNode.id;
        }

        if (nodeType === INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION) {
            newSingleChoiceNode = addSingleChoiceQuestionNode(params);
            newNode.nodeId = newSingleChoiceNode.id;
        }

        if (nodeType === INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION) {
            newMultipleChoiceNode = addMultipleChoiceQuestionNode(params);
            newNode.nodeId = newMultipleChoiceNode.id;
        }

        if (nodeType === INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST) {
            newCreateDocumentRequestNode = addCreateDocumentRequestNode(params);
            newNode.nodeId = newCreateDocumentRequestNode.id;
        }

        if (!newNode.nodeId) {
            console.error("Don't know how to insert nodes of type", nodeType);
            return;
        }

        const newConditionalNodes = [...conditionalNodes];
        const newSingleChoiceNodes = [...singleChoiceQuestionNodes];
        const newMultipleChoiceNodes = [...multipleChoiceQuestionNodes];
        const newCreateDocumentRequestNodes = [...createDocumentRequestNodes];
        const newNodes = [...nodes];
        let newStartNodeId = startNodeId;

        if (sourceNodeId) {
            const { node: sourceNode, index: sourceNodeIndex } = nodesById[sourceNodeId];


            if (sourceNode.nodeType === INTERVIEW_NODE_TYPES.CONDITIONAL && sourceNodeIdField === 'conditionMet') {
                const { index: conditionalNodeIndex } = conditionalNodesById[sourceNode.nodeId];

                newConditionalNodes[conditionalNodeIndex] = {
                    ...newConditionalNodes[conditionalNodeIndex],
                    nextInterviewNodeIdConditionMet: newNode.id,
                };
            } else {
                newNodes[sourceNodeIndex] = {
                    ...newNodes[sourceNodeIndex],
                    nextInterviewNodeId: newNode.id,
                };
            }
        } else {
            newStartNodeId = newNode.id;
        }

        newNodes.push(newNode);
        if (newConditionalNode) newConditionalNodes.push(newConditionalNode);
        if (newSingleChoiceNode) newSingleChoiceNodes.push(newSingleChoiceNode);
        if (newMultipleChoiceNode) newMultipleChoiceNodes.push(newMultipleChoiceNode);
        if (newCreateDocumentRequestNode) newCreateDocumentRequestNodes.push(newCreateDocumentRequestNode);

        setInterview({
            ...interview,
            startNodeId: newStartNodeId,
            nodes: newNodes,
            conditionalNodes: newConditionalNodes,
            singleChoiceQuestionNodes: newSingleChoiceNodes,
            multipleChoiceQuestionNodes: newMultipleChoiceNodes,
            createDocumentRequestNodes: newCreateDocumentRequestNodes,
        });

        if (onNodeInserted) onNodeInserted();
    };
    const setEndNodeForEdge = ({ sourceNodeId, sourceNodeIdField, targetNodeId, onEdgeSet }) => {
        const { node, index } = nodesById[sourceNodeId];

        if (node.nodeType === INTERVIEW_NODE_TYPES.CONDITIONAL && sourceNodeIdField === 'conditionMet') {
            const { index: conditionalNodeIndex } = conditionalNodesById[node.nodeId];
            const newConditionalNodes = [...conditionalNodes];
            newConditionalNodes[conditionalNodeIndex] = {
                ...newConditionalNodes[conditionalNodeIndex],
                nextInterviewNodeIdConditionMet: targetNodeId,
            };
            setConditionalNodes(newConditionalNodes);
        } else {
            const newNodes = [...nodes];
            newNodes[index] = {
                ...newNodes[index],
                nextInterviewNodeId: targetNodeId,
            };
            setNodes(newNodes);
        }

        if (onEdgeSet) onEdgeSet();
    };
    const resetToActive = useCallback(() => {
        setInterview(activeInterview);
    }, [activeInterview, setInterview]);
    const getQuestion = useCallback(({ type, id }) => {
        switch (type) {
            case 'SingleChoiceQuestion':
                return getSingleChoiceQuestionNodeById(id);
            case 'MultipleChoiceQuestion':
                return getMultipleChoiceQuestionNodeById(id);
            default:
                console.error('getQuestion: question not found', type, id);
        }
    }, [getMultipleChoiceQuestionNodeById, getSingleChoiceQuestionNodeById]);
    const getChoiceQuestionInfo = useCallback((optionId) => {
        const option = getChoiceQuestionOptionById(optionId);

        const question = getQuestion({
            type: option.choiceQuestionType,
            id: option.choiceQuestionId,
        });

        return {
            option,
            question,
        };
    }, [getChoiceQuestionOptionById, getQuestion]);
    const getChoiceQuestionOptionsForQuestion = useCallback(({ questionType, questionId }) => {
        return choiceQuestionOptions
            .filter(
                (option) => (option.choiceQuestionType === questionType
                    && option.choiceQuestionId === questionId)
            )
            .sort((a, b) => {
                return a.orderNumber - b.orderNumber;
            });
    }, [choiceQuestionOptions]);
    const newParamsChange = (existingParams, newParams) => {
        const keys = Object.keys(newParams);
        let anyChanges = false;

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (existingParams[key] !== newParams[key]) {
                anyChanges = true;
                break;
            }
        }

        return anyChanges;
    };
    const publishInterview = useCallback(async (topic) => {
        const publicationSuccessful = await API.publishInterviewForTopic({ topic, interview });

        if (publicationSuccessful) {
            const newActiveInterview = await reloadActiveInterview();
            setInterview(newActiveInterview);
        }

        return publicationSuccessful;
    }, [interview, reloadActiveInterview, setInterview]);
    const updateNode = useCallback((nodeId, params) => {
        const { node, index } = nodesById[nodeId];

        if ('label' in params) {
            if (!params.label) {
                params.label = null;
            } else {
                const trimmedLabel = params.label.trim();
                const sanitizedLabel = trimmedLabel === '' ? null : trimmedLabel;
                params.label = sanitizedLabel;
            }
        }

        if (!newParamsChange(node, params)) return;

        const newNodes = [...nodes];
        newNodes[index] = {
            ...node,
            ...params,
        };

        setNodes(newNodes);
    }, [nodes, setNodes, nodesById]);
    const updateChoiceQuestionOption = useCallback((choiceQuestionOptionId, params) => {
        const { choiceQuestionOption, index } = choiceQuestionOptionsById[choiceQuestionOptionId];

        if (!newParamsChange(choiceQuestionOption, params)) return;

        const newChoiceQuestionOptions = [...choiceQuestionOptions];
        newChoiceQuestionOptions[index] = {
            ...choiceQuestionOption,
            ...params,
        };

        setChoiceQuestionOptions(newChoiceQuestionOptions);
    }, [choiceQuestionOptions, choiceQuestionOptionsById, setChoiceQuestionOptions]);
    const updateSingleChoiceQuestion = useCallback((questionId, params) => {
        const { question, index } = singleChoiceQuestionNodesById[questionId];

        if (!newParamsChange(question, params)) return;

        const newSingleChoiceQuestions = [...singleChoiceQuestionNodes];
        newSingleChoiceQuestions[index] = {
            ...question,
            ...params,
        };

        setSingleChoiceQuestionNodes(newSingleChoiceQuestions);
    }, [setSingleChoiceQuestionNodes, singleChoiceQuestionNodes, singleChoiceQuestionNodesById]);
    const updateMultipleChoiceQuestion = useCallback((questionId, params) => {
        const { question, index } = multipleChoiceQuestionNodesById[questionId];

        if (!newParamsChange(question, params)) return;

        const newMultipleChoiceQuestions = [...multipleChoiceQuestionNodes];
        newMultipleChoiceQuestions[index] = {
            ...question,
            ...params,
        };

        setMultipleChoiceQuestionNodes(newMultipleChoiceQuestions);
    }, [multipleChoiceQuestionNodes, multipleChoiceQuestionNodesById, setMultipleChoiceQuestionNodes]);
    const updateQuestion = useCallback(({ questionType, questionId }, params) => {
        switch (questionType) {
            case 'SingleChoiceQuestion':
                updateSingleChoiceQuestion(questionId, params);
                break;
            case 'MultipleChoiceQuestion':
                updateMultipleChoiceQuestion(questionId, params);
                break;
            default:
                console.error('updateQuestion: question not found', questionType, questionId);
        }
    }, [updateMultipleChoiceQuestion, updateSingleChoiceQuestion]);
    const updateConditionalNode = useCallback((conditionalNodeId, conditionParams) => {
        const { conditionalNode, index } = conditionalNodesById[conditionalNodeId];

        if (!newParamsChange(conditionalNode.condition, conditionParams)) return;

        const newConditionalNodes = [...conditionalNodes];
        newConditionalNodes[index] = {
            ...conditionalNode,
            condition: {
                ...conditionalNode.condition,
                ...conditionParams,
            },
        };

        setConditionalNodes(newConditionalNodes);
    }, [conditionalNodes, conditionalNodesById, setConditionalNodes]);
    const conditionalNodesWoReferencesToChoiceQuestionOptions = useCallback((choiceQuestionOptionIds) => {
        const newConditionalNodes = [];

        for (let i = 0; i < conditionalNodes.length; i++) {
            const conditionalNode = conditionalNodes[i];

            newConditionalNodes.push({
                ...conditionalNode,
                condition: {
                    ...conditionalNode.condition,
                    cqoIds: [...conditionalNode
                        .condition
                        .cqoIds
                        .filter((cqoId) => !choiceQuestionOptionIds.includes(cqoId))],
                },
            });
        }

        return newConditionalNodes;
    }, [conditionalNodes]);
    const removeChoiceQuestionOption = useCallback((choiceQuestionOptionId) => {
        const { index } = choiceQuestionOptionsById[choiceQuestionOptionId];
        const newChoiceQuestionOptions = [...choiceQuestionOptions];
        newChoiceQuestionOptions.splice(index, 1);

        setInterview({
            ...interview,
            choiceQuestionOptions: newChoiceQuestionOptions,
            conditionalNodes: conditionalNodesWoReferencesToChoiceQuestionOptions([choiceQuestionOptionId]),
        });
    }, [
        interview,
        setInterview,
        choiceQuestionOptions,
        choiceQuestionOptionsById,
        conditionalNodesWoReferencesToChoiceQuestionOptions,
    ]);
    const addChoiceQuestionOption = useCallback(({ choiceQuestionType, choiceQuestionId, title }) => {
        const optionsForTheSameQuestion = getChoiceQuestionOptionsForQuestion({
            questionType: choiceQuestionType,
            questionId: choiceQuestionId,
        });
        const highestOrderNumberForQuestion = optionsForTheSameQuestion.length === 0 ? 0 : Math.max(...optionsForTheSameQuestion.map((option) => option.orderNumber));
        const highestId = choiceQuestionOptions.length === 0 ? 0 : Math.max(...choiceQuestionOptions.map((option) => option.id));
        const newChoiceQuestionOption = {
            id: highestId + 1,
            choiceQuestionType,
            choiceQuestionId,
            orderNumber: highestOrderNumberForQuestion + 1,
            title,
        };
        setChoiceQuestionOptions([...choiceQuestionOptions, newChoiceQuestionOption]);
    }, [choiceQuestionOptions, getChoiceQuestionOptionsForQuestion, setChoiceQuestionOptions]);
    const addDocumentType = useCallback(({ name }) => {
        const highestId = documentTypes.length === 0 ? 0 : Math.max(...documentTypes.map((type) => type.id));
        const newDocumentType = {
            id: highestId + 1,
            name,
        };

        setDocumentTypes([...documentTypes, newDocumentType]);
    }, [documentTypes, setDocumentTypes]);
    const addCreateDocumentRequestNode = useCallback(({ documentTypeId }) => {
        const highestId = createDocumentRequestNodes.length === 0 ? 0 : Math.max(...createDocumentRequestNodes.map((type) => type.id));
        return {
            id: highestId + 1,
            documentTypeId,
        };
    }, [createDocumentRequestNodes]);
    const addConditionalNode = useCallback(() => {
        const highestId = conditionalNodes.length === 0 ? 0 : Math.max(...conditionalNodes.map((node) => node.id));
        const newNode = {
            id: highestId + 1,
            nextInterviewNodeIdConditionMet: null,
            condition: {
                type: 'CQO_SELECTED',
                cqoIds: [],
                operator: CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS.AND,
            },
        };

        return newNode;
    }, [conditionalNodes]);
    const addSingleChoiceQuestionNode = useCallback(({ title }) => {
        const highestId = singleChoiceQuestionNodes.length === 0 ? 0 : Math.max(...singleChoiceQuestionNodes.map((node) => node.id));
        return {
            id: highestId + 1,
            title,
        };
    }, [singleChoiceQuestionNodes]);
    const addMultipleChoiceQuestionNode = useCallback(({ title }) => {
        const highestId = multipleChoiceQuestionNodes.length === 0 ? 0 : Math.max(...multipleChoiceQuestionNodes.map((node) => node.id));
        return {
            id: highestId + 1,
            title,
        };
    }, [multipleChoiceQuestionNodes]);

    return {
        startNodeId,
        getNodeById,
        deleteNode,
        resetToActive,
        insertNodeOnEdge,
        getChoiceQuestionInfo,
        getChoiceQuestionOptionById,
        getChoiceQuestionOptionsForQuestion,
        multipleChoiceQuestionNodes,
        singleChoiceQuestionNodes,
        updateConditionalNode,
        publishInterview,
        updateChoiceQuestionOption,
        removeChoiceQuestionOption,
        getMultipleChoiceQuestionNodeById,
        getSingleChoiceQuestionNodeById,
        updateQuestion,
        getConditionalNodeById,
        addChoiceQuestionOption,
        documentTypes,
        addDocumentType,
        getCreateDocumentRequestNodeById,
        getDocumentTypeById,
        addCreateDocumentRequestNode,
        setEndNodeForEdge,
        nodes,
        updateNode,
    };
};

export default useInterviewEditor;
