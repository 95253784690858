import { useState, useEffect, useCallback } from "react";
import API from "../common/api.js";
import { some } from "lodash";
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';


function augmentMessages(data, allUsers, allTeams) {
    return data.map((t) => ({
        ...t,
        assignee: allUsers.find((u) => u.user_id === t.assignee_id),
        team: allTeams.find((team) => team.team_id === t.team_id),
    }));
}

function useInboxArchivePageMessages({ allUsers, allTeams, assigneeFilter, messageTypeFilter, caseNumber, searchText, currentUser }) {
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(false);
    const [messages, setMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    usePubSub({topic: Constants.PUBSUB_TOPIC_INBOX_MESSAGE, messageHandler: handleServerMessage});

    const loadData = useCallback(async () => {
        setMessages([]);
        setLoading(true);
        setErrMsg(null);

        let data = null;
        if (caseNumber && caseNumber.length > 0) {
            data = await API.getInboxMessagesArchiveForCase(caseNumber);
            setHasMore(false);
        } else {
            data = await API.getInboxMessagesArchive();
            setHasMore(true);
        }

        if (data) {
            setMessages(augmentMessages(data, allUsers, allTeams));
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }

        setLoading(false);
    }, [allUsers, allTeams, caseNumber]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const loadMore = useCallback(async () => {
        setPaging(true);
        setErrMsg(null);

        let data = null;
        data = await API.getInboxMessagesArchive(messages.length);
        setHasMore(true);
        if (data) {
            if (data.length > 0) {
                setMessages(augmentMessages(messages.concat(data), allUsers, allTeams));
            } else {
                setHasMore(false);
            }
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }

        setPaging(false);
    }, [allUsers, allTeams, messages]);

    useEffect(() => {
        let data = messages;
        if (assigneeFilter) {
            const filter = Array.isArray(assigneeFilter) ? assigneeFilter : [assigneeFilter];
            if (filter.length > 0) {
                data = data.filter((msg) => {
                    return some(
                        filter.map((f) => {
                            if (f.is_user) {
                                return msg.assignee_id === parseInt(f.id);
                            } else if (f.is_team) {
                                return msg.team_id === parseInt(f.id) && msg.assignee_id === null;
                            } else if (f.id === -1) {
                                return msg.team_id === null && msg.assignee_id === null;
                            } else {
                                return true; // Should never happen
                            }
                        })
                    );
                });
            }
        }
        if (messageTypeFilter) {
            data = data.filter((msg) => msg.kind === parseInt(messageTypeFilter.value));
        }
        if (caseNumber !== null && caseNumber.length > 0) {
            data = data.filter((msg) => msg.case_number === caseNumber); // Not really needed anymore, but doesn't hurt
        }
        if (searchText !== null && searchText.length > 0) {
            data = data.filter((msg) => msg.subject.toLowerCase().includes(searchText.toLowerCase()));
        }
        setFilteredMessages(data);
    }, [
        messages,
        allUsers,
        allTeams,
        assigneeFilter,
        messageTypeFilter,
        caseNumber,
        searchText,
        currentUser.teamMemberOf,
        currentUser.userID,
    ]);

    function handleServerMessage(topic, data) {
        if (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_ADDED) {
            const newMessages = [
                ...messages,
                { ...data, was_read: false, read_status_user_id: currentUser.userID },
            ];
            setMessages(augmentMessages(newMessages, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_UPDATED) {
            const newMessages = messages.map((msg) => {
                if (msg.msg_id === data.msg_id) {
                    if (data.read_status_user_id === currentUser.userID) {
                        return { ...data };
                    } else {
                        return { ...data, was_read: msg.was_read, read_status_user_id: msg.read_status_user_id };
                    }
                }
                return { ...msg };
            });
            setMessages(augmentMessages(newMessages, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_DELETED) {
            const newMessages = messages.filter((msg) => msg.msg_id !== parseInt(data.msg_id));
            setMessages(augmentMessages(newMessages, allUsers, allTeams));
        }
    }

    return { loading, errMsg, paging, hasMore, messages: filteredMessages, loadMore };
}

export default useInboxArchivePageMessages;
