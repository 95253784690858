import React, { useState, useMemo } from 'react';
import useCreateConversationMessage from '../../../common/useCreateConversationMessage.js';
import ValidationErrors from '../../../common/validationerrors.js';
import Dialog from '../../../common/dialog.js';
import Spinner from '../../../components/spinner.js';
import { FaPaperPlane, FaPaperclip } from 'react-icons/fa';
import useDocumentUpload from '../../../common/useDocumentUpload.js';

const DocumentUploadForm = ({ onTemporaryDocumentSubmitted, onClickClose }) => {
    const [pdfFile, setPdfFile] = useState(null);
    const [documentName, setDocumentName] = useState('');
    const [notes, setNotes] = useState('');
    const informationComplete = pdfFile && documentName.trim().length > 0;

    const onFileChange = async (event) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            setPdfFile(event.currentTarget.files[0]);
        } else {
            setPdfFile(null);
        }
    };

    const submitTemporaryDocument = () => {
        if (!informationComplete) return;

        const temporaryDocument = {
            name: documentName.trim(),
            file: pdfFile,
        };

        const trimmedNotes = notes.trim();
        if (trimmedNotes.length > 0) {
            temporaryDocument.notes = trimmedNotes;
        }

        onTemporaryDocumentSubmitted(temporaryDocument);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 25,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15,
                }}
            >
                <div>
                    <div className="ui input" style={{ width: '100%' }}>
                        <input
                            type="text"
                            placeholder="Document name"
                            value={documentName}
                            onChange={(event) => setDocumentName(event.currentTarget.value)}
                            required
                        />
                    </div>
                </div>
                <div>
                    <input
                        type="file"
                        onChange={onFileChange}
                        accept="application/pdf"
                    />
                </div>
                <div>
                    <div className="ui input" style={{ width: '100%' }}>
                        <input
                            value={notes}
                            placeholder="Additional notes (optional)"
                            onChange={(event) => setNotes(event.currentTarget.value)}
                        />
                    </div>
                </div>
            </div>
            <div>
                <button
                    type="button"
                    disabled={!informationComplete}
                    className="ui primary button"
                    onClick={submitTemporaryDocument}
                >
                    Attach document
                </button>
                <button
                    type="button"
                    className="ui button"
                    onClick={onClickClose}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

const TemporaryDocument = ({ temporaryDocument, onTemporaryDocumentDiscarded, onClickClose }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 25,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                }}
            >
                <div
                    style={{
                        fontSize: '110%',
                        fontWeight: 'bold',
                    }}
                >
                    {temporaryDocument.name}
                </div>
                <div>
                    {temporaryDocument.file.name}
                </div>
                <div
                    style={{
                        fontSize: '95%',
                        color: '#797979',
                    }}
                >
                    {temporaryDocument.notes ? (
                        <>
                            {temporaryDocument.notes}
                        </>
                    ) : (
                        <span
                            style={{
                                fontStyle: 'italic',
                            }}
                        >
                            No notes
                        </span>
                    )}
                </div>
            </div>
            <div>
                <button
                    type="button"
                    className="ui button red"
                    onClick={onTemporaryDocumentDiscarded}
                >
                    Discard
                </button>
                <button
                    type="button"
                    className="ui button"
                    onClick={onClickClose}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

const DocumentAttachmentManager = ({ temporaryDocument, onClickClose, onTemporaryDocumentSubmitted, onTemporaryDocumentDiscarded }) => {
    if (temporaryDocument) {
        return (
            <TemporaryDocument
                temporaryDocument={temporaryDocument}
                onTemporaryDocumentDiscarded={onTemporaryDocumentDiscarded}
                onClickClose={onClickClose}
            />
        );
    }

    return (
        <DocumentUploadForm
            onTemporaryDocumentSubmitted={onTemporaryDocumentSubmitted}
            onClickClose={onClickClose}
        />
    );
};

const SendMessageSection = ({ conversation, onMessageSent }) => {
    const [text, setText] = useState('');
    const [temporaryDocument, setTemporaryDocument] = useState(null);
    const {
        submitPdfDocument,
        errors: documentUploadErrors,
        loading: submittingDocument,
    } = useDocumentUpload();
    const [documentUploadDialogOpen, setDocumentUploadDialogOpen] = useState(false);
    const borderRadius = 5;
    const {
        createConversationMessage,
        loading: submittingMessage,
        errors: messageErrors,
    } = useCreateConversationMessage({
        conversationId: conversation.id,
        onMessageCreated: (newMessage) => {
            setText('');
            setTemporaryDocument(null);
            if (onMessageSent) onMessageSent(newMessage);
        },
    });
    const submitMessageForm = async (event) => {
        event.preventDefault();

        let documentId = null;

        if (temporaryDocument) {
            documentId = await submitPdfDocument({
                matterId: conversation.regardingMatterId,
                documentName: temporaryDocument.name,
                shareWithClient: true,
                fileUri: temporaryDocument.file,
                notes: temporaryDocument.notes,
            });

            if (!documentId) {
                return;
            }
        }

        const messageParams = {};
        const trimmedText = text.trim();

        if (trimmedText.length > 0) {
            messageParams.text = trimmedText;
        }

        if (documentId) {
            messageParams.documentId = documentId;
        }

        if (Object.keys(messageParams).length > 0) {
            await createConversationMessage(messageParams);
        }
    };
    const errors = useMemo(() => {
        return [...messageErrors, ...documentUploadErrors];
    }, [messageErrors, documentUploadErrors]);
    const loading = submittingMessage || submittingDocument;

    return (
        <form onSubmit={submitMessageForm}>
            {documentUploadDialogOpen && (
                <Dialog
                    onClose={() => setDocumentUploadDialogOpen(false)}
                    title="Attach document"
                >
                    <DocumentAttachmentManager
                        temporaryDocument={temporaryDocument}
                        onTemporaryDocumentSubmitted={(temporaryDocument) => {
                            setTemporaryDocument(temporaryDocument);
                            setDocumentUploadDialogOpen(false);
                        }}
                        onTemporaryDocumentDiscarded={() => {
                            setTemporaryDocument(null);
                        }}
                        onClickClose={() => {
                            setDocumentUploadDialogOpen(false);
                        }}
                    />
                </Dialog>
            )}
            {errors.length > 0 && (
                <div style={{ marginTop: 5, marginBottom: 5 }}>
                    <ValidationErrors errors={errors} />
                </div>
            )}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <textarea
                        value={text}
                        onChange={(event) => setText(event.currentTarget.value)}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            padding: 10,
                            borderTopLeftRadius: borderRadius,
                            borderBottomLeftRadius: borderRadius,
                        }}
                        placeholder="Compose new message..."
                        disabled={loading}
                    />
                </div>
                <div>
                    <button
                        disabled={loading || !conversation.regardingMatterId}
                        title="Attach"
                        style={{
                            height: '100%',
                            border: 'none',
                            cursor: 'pointer',
                            padding: 13,
                            fontSize: 17,
                            backgroundColor: 'rgb(236, 236, 236)',
                            maxWidth: 70,
                            wordWrap: 'break-word',
                        }}
                        onClick={() => setDocumentUploadDialogOpen(true)}
                        type="button"
                    >
                        {temporaryDocument ? (
                            <div>
                                <div>
                                    <FaPaperclip />
                                </div>
                                <div
                                    style={{
                                        fontSize: 10,
                                    }}
                                >
                                    {temporaryDocument.name}
                                </div>
                            </div>
                        ) : (
                            <FaPaperclip />
                        )}
                    </button>
                </div>
                <div>
                    <button
                        type="submit"
                        disabled={loading}
                        title="Send message"
                        style={{
                            height: '100%',
                            borderRadius: 0,
                            borderTopRightRadius: borderRadius,
                            borderBottomRightRadius: borderRadius,
                            border: 'none',
                            cursor: 'pointer',
                            paddingLeft: 13,
                            paddingRight: 13,
                            fontSize: 17,
                            backgroundColor: 'rgb(54, 208, 255)',
                        }}
                    >
                        {loading ? (
                            <Spinner />
                        ) : (
                            <FaPaperPlane />
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default SendMessageSection;
