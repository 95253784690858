/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import BannerLayout from './bannerlayout.js';
import { formatDateTimeHumanDateTime } from '../../common/formatting.js';

const DeferredBanner = ({ lead, updateDeferredUntil, updatingDeferredUntil }) => {
    return (
        <BannerLayout
            backgroundColor="rgb(191, 191, 191)"
            headline="Deferred"
            headlineColor="rgb(245, 245, 245)"
        >
            <div style={{ display: 'flex', gap: 10 }}>
                <div>
                    until {formatDateTimeHumanDateTime(lead.deferredUntil)}
                </div>
                <div>
                    <button
                        className="mini ui grey button"
                        style={{ margin: 0, padding: 5 }}
                        onClick={() => updateDeferredUntil && !updatingDeferredUntil && updateDeferredUntil(null)}
                    >
                        Repeal
                    </button>
                </div>
            </div>
        </BannerLayout>
    );
};

export default DeferredBanner;
