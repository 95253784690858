/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useEffect, useState } from 'react';
import API from '../common/api.js';

function useLeadModelDescription() {
    const [loading, setLoading] = useState(true);
    const [leadModelDescription, setLeadModelDescription] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const loadLeadModelDescription = async () => {
        const leadModelDescription = await API.getLeadModelDescription();

        if (leadModelDescription) {
            setLeadModelDescription(leadModelDescription)
        } else {
            setErrorMessage(
                'Failed to query required information from API. Try reloading the page or report this issue, if it persists.'
            )
        }

        setLoading(false);
    }

    useEffect(() => {
        loadLeadModelDescription();
    }, []);

    return {
        leadModelDescription,
        loadingLeadModelDescription: loading,
        loadingLeadModelDescriptionErrorMessage: errorMessage,
    }
}

export default useLeadModelDescription;
