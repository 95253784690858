import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Assignee from "../components/assignee.js";
import ScanCard from "../components/scancard.js";
import { useHistory } from "react-router-dom";
import Modal from "../components/modal.js";
import useLeftRightSiblingLink from '../common/useLeftRightSiblingLink.js';
import "../pages/scanspage.css";
import { formatHumanDateTime } from "../common/formatting.js";
import API from "../common/api.js";
var classNames = require("classnames");


function placeholderRow(scn) {
    return (
        <tr key={scn} className="dim">
            <td className="border-none" colSpan={3}>
                {scn}
            </td>
        </tr>
    );
}

function ScansPageTable({
    loading,
    errMsg,
    scans,
    setScanAcknowledged,
    setScanAssignee,
    allCaseNumbers,
    possibleAssignees,
    initialExpandedScanID,
    setAssigneeFilter,
    toggleWatched,
    height,
}) {
    const [expandedScan, setExpandedScan] = useState(null);
    let history = useHistory();

    useEffect(() => {
        if (initialExpandedScanID) {
            const scnToExpand = scans.find((scn) => scn.scan_id === initialExpandedScanID);
            if (scnToExpand) {
                setExpandedScan(scnToExpand);
            } else {
                // That scan was probably archived, and we got here through a link. So it won't be in the scans list.
                // Load it explicitly to ensure we don't have broken links.
                API.getScan(initialExpandedScanID).then(scn => {
                    if (scn) {
                        setExpandedScan(scn);
                    } else {
                        setExpandedScan(null);            
                    }
                });
            }
        } else {
            setExpandedScan(null);
        }
    }, [scans, initialExpandedScanID]);

    const [leftSiblingLink, rightSiblingLink] = useLeftRightSiblingLink(scans, expandedScan, 'scan_id', '/inbox/scans/');

    function scansRow(scn, inx, prefix) {
        // eslint-disable-next-line
        const assignee = (
            <Assignee
                task={scn}
                onClick={(name) => {
                    setAssigneeFilter(possibleAssignees.find((a) => a.label === name));
                }}
            />
        );
        const timestamp = formatHumanDateTime(scn.created_at);
        const casenum = scn.case_number && ` - ${scn.case_number}`;
        const commentsAnnotations = scn.comment_count > 0 && (<><i className="comments icon"/>{scn.comment_count} &nbsp;</>);
        let mentionAnnotations = null;
        if (scn.read_mention_count < scn.total_mention_count) {
            mentionAnnotations = <><i className="red bell icon"/><span className="ui red header" style={{fontWeight: 'normal', fontSize: '1em'}}>{scn.total_mention_count}</span></>;
        } else if (scn.total_mention_count > 0) {
            mentionAnnotations = <><i className="bell outline icon"/>{scn.total_mention_count}</>;
        }
        const watchAnnotations = (scn.is_watched_by_current_user > 0) && <span>&nbsp;<i className="eye icon"/></span>;
        const annotations = (commentsAnnotations || mentionAnnotations || watchAnnotations) && (
            <span style={{color: "#999", marginLeft: '2em'}}>
                {commentsAnnotations}
                {mentionAnnotations}
                {watchAnnotations}
            </span>
        );
        const actionButtons = (
            <div className="ui small basic icon buttons scans-scan-action-buttons">
                <button className={classNames("ui icon button", {active: scn.is_watched_by_current_user})} data-tooltip={scn.is_watched_by_current_user ? "Unwatch scan" : "Watch scan"} data-position="top center" onClick={() => toggleWatched(scn)}>
                    <i className={classNames("eye icon", {green: scn.is_watched_by_current_user})}/>
                </button>
                {scn.acknowledged || (
                    <button
                        className="ui button"
                        data-tooltip="Mark scans as processed"
                        data-position="top center"
                        onClick={() => setScanAcknowledged(scn)}
                    >
                        <i className="check icon" />
                    </button>
                )}
                <Link
                    to={`/add_task?scanID=${scn.scan_id}`}
                    className="ui button"
                    data-tooltip="Convert to task"
                    data-position="top center"
                >
                    <i className="tasks icon" />
                </Link>
                <Link
                    to={`/upload?scanID=${scn.scan_id}`}
                    className="ui button"
                    data-tooltip="Upload to Clio"
                    data-position="top center"
                >
                    <i className="upload icon" />
                </Link>
            </div>
        );
        return (
            <tr key={`${prefix}${inx}`} className="scans-scan-row">
                <td
                    className={classNames("w-full align-top", {
                        "border-none": inx === 0,
                        "font-bold": !scn.was_read && !scn.acknowledged,
                    })}
                >
                    <Link to={`/inbox/scans/${scn.scan_id}`}>{scn.filename}</Link> {annotations}
                    <br />
                    <span className="scans-scan-timestamp">
                        {timestamp}
                        {casenum}
                    </span>
                </td>
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {actionButtons}
                </td>
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {(scn.assignee || scn.team) && (
                        /* eslint-disable-next-line */
                        <a href="#" className="ui grey icon label">
                            {assignee}
                        </a>
                    )}
                </td>
            </tr>
        );
    }

    const scnRows = scans.filter((scn) => scn.acknowledged === false).map((scn, inx) => scansRow(scn, inx, "scan_"));

    const errMsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    if (loading) {
        return (
            <div style={{ paddingTop: 10, height: "100%" }}>
                <div className="ui active inline loader" style={{ marginTop: 20 }}></div>
            </div>
        );
    } else {
        return (
            <>
                {errMsgEl}

                {expandedScan && (
                    <Modal>
                        <ScanCard
                            scan={expandedScan}
                            onCloseClicked={() => {
                                history.push("/inbox/scans");
                            }}
                            setScanAssignee={setScanAssignee}
                            setScanAcknowledged={setScanAcknowledged}
                            possibleAssignees={possibleAssignees}
                            allCaseNumbers={allCaseNumbers}
                            toggleWatched={toggleWatched}
                            leftSiblingLink={leftSiblingLink}
                            rightSiblingLink={rightSiblingLink}
                        />
                    </Modal>
                )}
                <div
                    style={{
                        flexGrow: 1,
                        overflowY: "auto",
                        paddingTop: 20,
                        paddingRight: 20,
                        height: height,
                    }}
                >
                    <table className="ui very basic table">
                        <tbody>{scnRows.length > 0 ? scnRows : placeholderRow("No scans left to process...")}</tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default ScansPageTable;
