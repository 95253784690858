/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useCallback, useMemo } from 'react';

const useAllUsers = ({ allUsers }) => {
    const usersById = useMemo(() => {
        const users = {};

        allUsers.forEach((user) => users[user.user_id] = user);

        return users;
    }, [allUsers]);

    const getUserName = useCallback((userId) => {
        if (!userId) return null;

        const user = usersById[userId];

        if (!user) {
            return `User ${userId}`;
        }

        return user.name;
    }, [usersById]);

    const attorneyUsers = useMemo(() => allUsers.filter((user) => user.isAttorney), [allUsers]);

    return {
        getUserById: (userId) => usersById[userId],
        getUserName,
        attorneyUsers,
    };
};

export default useAllUsers;
