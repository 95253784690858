import React from 'react';
import General from '../components/settingspage/general.js';
import Interviews from '../components/settingspage/interviews.js';
import DynamicContent from '../components/settingspage/dynamiccontent.js';
import {
    SETTINGS_GENERAL_TAB,
    SETTINGS_INTERVIEWS_TAB,
    SETTINGS_DYNAMIC_CONTENT_TAB,
} from "../common/webconstants.js";
import classNames from 'classnames';
import { Link } from "react-router-dom";

const page = (tabName) => {
    switch (tabName) {
        case SETTINGS_INTERVIEWS_TAB:
            return <Interviews />;
        case SETTINGS_DYNAMIC_CONTENT_TAB:
            return <DynamicContent />;
        default:
            return <General />;
    }
};

const TabBar = ({ tabs }) => {
    return (
        <div className="ui tabular menu">
            {tabs.map((tab) => (
                <Link
                    key={tab.path}
                    to={tab.path}
                    className={classNames('item', { active: tab.active })}
                >
                    {tab.title}
                </Link>
            ))}
        </div>
    );
};

const SettingsPage = ({ tabName }) => {
    return (
        <>
            <TabBar
                tabs={[
                    {
                        path: '/settings',
                        active: tabName === SETTINGS_GENERAL_TAB,
                        title: 'General',
                    },
                    {
                        path: '/settings/interviews',
                        active: tabName === SETTINGS_INTERVIEWS_TAB,
                        title: 'Questionnaire',
                    },
                    {
                        path: '/settings/dynamic_content',
                        active: tabName === SETTINGS_DYNAMIC_CONTENT_TAB,
                        title: 'Dynamic Content',
                    },
                ]}
            />
            {page(tabName)}
        </>
    );
};

export default SettingsPage;
