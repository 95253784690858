import './common/polyfills.js';
import React, { useState, useCallback, useEffect } from 'react';
import StatusPage from './pages/statuspage.js';
import TasksPage from './components/taskspagewrapper.js';
import LeadsPage from './components/leadspagewrapper.js';
import LeadsPageCustomerRelations from './components/leadspagecustomerrelationswrapper';
import AddTaskPage from './pages/addtaskpage.js';
import AddLeadPage from './pages/addleadpage.js';
import TasksArchivePage from './components/tasksarchivepagewrapper.js';
import LoginPage from './pages/loginpage.js';
import EmailsPage from './pages/emailspage.js';
import PushNotificationsPage from './pages/pushnotificationspage.js';
import SMSPage from './pages/smspage.js';
import AutomationsPage from './pages/automationspage.js';
import IDMeTrackingPage from './pages/idmetrackingpage.js';
import DevicesPage from './pages/devicespage.js';
import SettingsPage from './pages/settingspage.js';
import DashboardPage from './pages/dashboardpage.js';
import RequestCallBackPage from './pages/requestcallbackpage.js';
import QueuePage from './pages/queuepage.js';
import AddUserPage from './pages/adduserpage.js';
import EditUserPage from './pages/edituserpage.js';
import AddTeamPage from './pages/addteampage.js';
import EditTeamPage from './pages/editteampage.js';
import AddFaxPage from './pages/addfaxpage.js';
import EditFaxPage from './pages/editfaxpage.js';
import AddVoicemailExtensionPage from './pages/addvoicemailextensionpage.js';
import AddTextMessageTemplatePage from './pages/addtextmessagetemplatepage.js';
import EditVoicemailExtensionPage from './pages/editvoicemailextensionpage.js';
import CallFormPage from './pages/callformpage.js';
import ReworkInboxMessagePage from './pages/reworkinboxmessagepage.js';
import InboxPage from './pages/inboxpage.js';
import InboxArchivePage from './pages/inboxarchivepage.js';
import UploadDocumentPage from './pages/uploaddocumentpage.js';
import AddScanPage from './pages/addscanpage.js';
import MentionsBubble from './components/mentionsbubble.js';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Navigation from './components/navigation.js';
import API from './common/api.js';
import * as Constants from './common/webconstants.js';
import {CurrentUserContext} from './common/currentUserContext.js';
import VonageContextProvider from './common/vonageContextProvider.js';
import {ToastContext} from './common/toastContext.js';
import usePubSub from './common/usePubSub.js';
import Modal from "./components/modal.js";
import Toasts from "./components/toasts.js";
import useConnectPubSubSSE from "./common/useConnectPubSubSSE.js";
import useNumberOfUnreadMessages from "./common/useNumberOfUnreadMessages.js";

var qs = require('qs');

function App() {
  const [activeTab, setActiveTab] = useState("");
  const [allCaseNumbers, setAllCaseNumbers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [unprocessedInboxMessageCount, setUnprocessedInboxMessageCount] = useState(0);
  const [unprocessedScanCount, setUnprocessedScanCount] = useState(0);
  const [expandMentionsBubble, setExpandMentionsBubble] = useState(false);
  const [haveUnreadMentions, setHaveUnreadMentions] = useState(false);
  const [toastIF, setToastIF] = useState(null);
  usePubSub({topic: Constants.PUBSUB_ALL_SERVER_TOPICS, messageHandler: handleServerMessage});
  useConnectPubSubSSE();

  const isLoggedIn = currentUser && currentUser.isLoggedIn;
  const isAdmin = currentUser && currentUser.isAdmin;

  const {
    numberOfUnreadMessages,
    reload: reloadNumberOfUnreadMessages,
  } = useNumberOfUnreadMessages();

  // TODO:
  // - 404 page

  const bootstrap = useCallback(async () => {
    const user = await API.me();
    if (user && user.isLoggedIn) {
      setCurrentUser(user);
      const [caseNumberData, userData, teamData] = await Promise.all([API.getAllCaseNumbers(), API.getUsers(), API.getTeams()]);
      if (caseNumberData) {
        setAllCaseNumbers(caseNumberData);
      }
      if (userData) {
        setAllUsers(userData);
      }
      if (teamData) {
        setAllTeams(teamData);
      }
    } else if (window.location.pathname === "/callform") {
      const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const {authkey} = urlParams;
      const [caseNumberData, userData, teamData] = await Promise.all([API.getAllCaseNumbers({authkey}), API.getUsers({authkey}), API.getTeams({authkey})]);
      if (caseNumberData) {
        setAllCaseNumbers(caseNumberData);
      }
      if (userData) {
        setAllUsers(userData);
      }
      if (teamData) {
        setAllTeams(teamData);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    bootstrap();
  }, [bootstrap]);


  //
  // Listen for incoming inbox messages and update the unread counter in the navigation.
  // Also flash a toast to make user aware.
  //
  const refreshUnprocessedInboxMessageCount = useCallback(async () => {
    if (currentUser) {
      const countResponse = await API.getUnprocessedInboxMessageCount(currentUser.userID);
      if (countResponse) {
        setUnprocessedInboxMessageCount(countResponse.regularMessageCount);
        setHaveUnreadMentions(countResponse.mentionCount > 0);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    refreshUnprocessedInboxMessageCount();
  }, [refreshUnprocessedInboxMessageCount]);

  //
  // Listen for incoming scans and update the unread counter in the navigation.
  // Also flash a toast to make user aware.
  //
  const refreshUnprocessedScanCount = useCallback(async () => {
    if (currentUser) {
      const countResponse = await API.getUnprocessedScanCount(currentUser.userID);
      if (countResponse) {
        setUnprocessedScanCount(countResponse.count);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    refreshUnprocessedScanCount();
  }, [refreshUnprocessedScanCount]);


  function handleServerMessage(topic, data) {
    if ((topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_ADDED) || (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_UPDATED) || (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_DELETED || (topic === Constants.PUBSUB_TOPIC_NOTIFICATIONS_UPDATED))) {
      refreshUnprocessedInboxMessageCount();
    }
    if (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_ADDED) {
      if (currentUser) {
        if (data.assignee_id === currentUser.userID || (data.team_members && data.team_members.includes(currentUser.userID))) {
          toastIF.addToast(`New message: ${data.subject}`);
        }
      }
    }
    if ((topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_ADDED) || (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_UPDATED) || (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_DELETED)) {
      refreshUnprocessedScanCount();
    }
    if (topic === Constants.PUBSUB_TOPIC_INBOX_SCAN_ADDED) {
      if (currentUser) {
        if (data.assignee_id === currentUser.userID || (data.team_members && data.team_members.includes(currentUser.userID))) {
          toastIF.addToast(`New scan: ${data.filename}`);
        }
      }
    }
    if (topic === Constants.PUBSUB_TOPIC_ON_CALL_STATUS_UPDATED) {
      const updatedUsers = allUsers.map((user) => {
        if (user.user_id === data.user_id) {
          return { ...user, onCallStatus: data.onCallStatus };
        } else {
          return user;
        }
      });

      if (currentUser.userID === data.user_id) {
        setCurrentUser({
          ...currentUser,
          onCallStatus: data.onCallStatus,
        });
      }

      setAllUsers(updatedUsers);
    }
    if (topic === Constants.PUBSUB_TOPIC_CONVERSATIONS_MESSAGES_ADDED) {
      if (currentUser) {
        const addedBySelf = data.senderType === Constants.CONVERSATION_PARTICPANT_TYPE_USER
          && data.senderId === currentUser.userID;

        if (addedBySelf) return;

        const currentUserParticipatesInConversation = data.conversationParticipants.find((participant) => {
          return participant.participantType === Constants.CONVERSATION_PARTICPANT_TYPE_USER
            && participant.participantId === currentUser.userID;
        });

        if (!currentUserParticipatesInConversation) return;
      }

      reloadNumberOfUnreadMessages();
    }
    if (topic === Constants.PUBSUB_TOPIC_CONVERSATIONS_MESSAGES_READ) {
      if (currentUser) {
        const messagesWereReadBySelf = data.participantType === Constants.CONVERSATION_PARTICPANT_TYPE_USER
          && data.participantId === currentUser.userID;

        if (!messagesWereReadBySelf) return;
      }

      reloadNumberOfUnreadMessages();
    }
  }

  function onBellButtonPressed() {
    setExpandMentionsBubble(flag => !flag);
  }

  function onCloseMentionsBubbleClicked() {
    setExpandMentionsBubble(false);
  }



  // FIXME: this causes TaskCard to redraw while it's open
  // useInterval(() => {
  //   bootstrap();
  // }, 60*1000);

  const adminRoutes = [
    <Route key={1} exact={true} path="/emails" render={() => {
      setTimeout(() => setActiveTab(Constants.EMAILS_TAB), 1);
      return <EmailsPage allCaseNumbers={allCaseNumbers}/>;
    }} />,
    <Route key={2} exact={true} path="/pushnotifications" render={() => {
      setTimeout(() => setActiveTab(Constants.PUSH_NOTIFS_TAB), 1);
      return <PushNotificationsPage allCaseNumbers={allCaseNumbers}/>;
    }} />,
    <Route key={3} exact={true} path="/sms" render={() => {
      setTimeout(() => setActiveTab(Constants.SMS_TAB), 1);
      return <SMSPage allCaseNumbers={allCaseNumbers}/>;
    }} />,
    <Route key={4} exact={true} path="/automations" render={() => {
      setTimeout(() => setActiveTab(Constants.AUTOMATIONS_TAB), 1);
      return <AutomationsPage allCaseNumbers={allCaseNumbers}/>;
    }} />,
    <Route key={4} exact={true} path="/idmetracking" render={() => {
      setTimeout(() => setActiveTab(Constants.IDME_TRACKING_TAB), 1);
      return <IDMeTrackingPage allCaseNumbers={allCaseNumbers}/>;
    }} />,
    <Route key={5} exact={true} path="/devices" render={() => {
      setTimeout(() => setActiveTab(Constants.DEVICES_TAB), 1);
      return <DevicesPage allCaseNumbers={allCaseNumbers}/>;
    }} />,
    <Route key={6} exact={true} path="/settings" render={() => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <SettingsPage tabName={Constants.SETTINGS_GENERAL_TAB} />;
    }} />,
    <Route key={16} exact={true} path="/settings/interviews" render={() => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <SettingsPage tabName={Constants.SETTINGS_INTERVIEWS_TAB} />;
    }} />,
    <Route key={17} exact={true} path="/settings/dynamic_content" render={() => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <SettingsPage tabName={Constants.SETTINGS_DYNAMIC_CONTENT_TAB} />;
    }} />,
    <Route key={7} exact={true} path="/add_user" render={() => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <AddUserPage/>;
    }} />,
    <Route key={8} exact={true} path="/edit_user/:userID" render={({match}) => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <EditUserPage userID={match.params.userID}/>;
    }} />,
    <Route key={9} exact={true} path="/add_team" render={() => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <AddTeamPage/>;
    }} />,
    <Route key={10} exact={true} path="/edit_team/:teamID" render={({match}) => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <EditTeamPage teamID={match.params.teamID}/>;
    }} />,
    <Route key={11} exact={true} path="/add_fax" render={() => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <AddFaxPage/>;
    }} />,
    <Route key={12} exact={true} path="/edit_fax/:faxNumberID" render={({match}) => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <EditFaxPage faxNumberID={match.params.faxNumberID}/>;
    }} />,
    <Route key={13} exact={true} path="/add_voicemail_extension" render={() => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <AddVoicemailExtensionPage/>;
    }} />,
    <Route key={14} exact={true} path="/edit_voicemail_extension/:extensionID" render={({match}) => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <EditVoicemailExtensionPage extensionID={match.params.extensionID}/>;
    }} />,
    <Route key={15} exact={true} path="/add_text_message_template" render={() => {
      setTimeout(() => setActiveTab(Constants.SETTINGS_TAB), 1);
      return <AddTextMessageTemplatePage/>;
    }} />,
  ];

  if (loading) {
    return (
      <div className="ui container">
        <div className="ui hidden divider"></div>
            <div className="ui active inverted dimmer" style={{marginTop: 20}}>
              <div className="ui large text loader">Loading</div>
            </div>
        <div className="ui hidden divider"></div>
      </div>
    );

  } else if (window.location.pathname === '/rcb') {
    const { re } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    return (
      <>
        <div className="ui borderless menu" style={{background: "#ECF0F1"}}>
          <div className="ui container">
            <div className="header item ml-16" style={{marginLeft: -16}}>
              <img className="logo" src="/icon.png" alt="logo"/>
              <span style={{marginLeft: 10}}>Pershing Square Law Firm, PC</span>
            </div>
          </div>
        </div>
        <div className="ui container">
          <div className="ui hidden divider"></div>
          <RequestCallBackPage token={re} />
          <div className="ui hidden divider"></div>
        </div>
      </>
    );
  } else if (!isLoggedIn) {
    if (window.location.pathname === "/callform" && allTeams.length > 0) {
      const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const {authkey} = urlParams;
      return (
        <Router>
          <div className="ui borderless menu" style={{background: "#ECF0F1"}}>
            <div className="ui container">
              <div className="header item ml-16" style={{marginLeft: -16}}>
                <img className="logo" src="/icon.png" alt="logo"/>
                <span style={{marginLeft: 10}}>Pershing Square Law Firm, PC</span>
              </div>
            </div>
          </div>
          <div className="ui container">
            <div className="ui hidden divider"></div>
            <CallFormPage allCaseNumbers={allCaseNumbers} users={allUsers} teams={allTeams} authkey={authkey}/>
            <div className="ui hidden divider"></div>
          </div>
        </Router>
      );
    } else {
      return <LoginPage/>;
    }

  } else {
    const leadsUrl = (currentUser.isCRAgent || currentUser.isAdmin) ? '/leads_cr' : '/leads';

    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
        <Router>
          <Navigation
            activeTab={activeTab}
            isAdmin={isAdmin}
            unprocessedInboxMessageCount={unprocessedInboxMessageCount}
            unprocessedScanCount={unprocessedScanCount}
            numberOfUnreadMessages={numberOfUnreadMessages}
            onBellButtonPressed={onBellButtonPressed}
            redBell={haveUnreadMentions}
            currentUser={currentUser}
            leadsUrl={leadsUrl}
          />
          <CurrentUserContext.Provider value={currentUser}>
            <VonageContextProvider>
              <ToastContext.Provider value={toastIF}>
                <div style={{paddingLeft: 10, paddingRight: 10, overflow: 'hidden', height: '100%'}}>
                  <div className="ui fluid limitedwidth container" style={{height: '100%'}}>
                    <Switch>
                      <Route exact={true} path="/" render={() => {
                        setTimeout(() => setActiveTab(Constants.DASHBOARD_TAB), 1);
                        return <DashboardPage allUsers={allUsers} />;
                      }} />
                      <Route exact={true} path="/status" render={() => {
                        setTimeout(() => setActiveTab(Constants.MATTERS_TAB), 1);
                        return <StatusPage allCaseNumbers={allCaseNumbers}/>;
                      }} />
                      <Route exact={true} path="/status/:matterID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.MATTERS_TAB), 1);
                        const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                        const {msg, success, link, linktext} = urlParams;
                        return <StatusPage allCaseNumbers={allCaseNumbers} initialMatterID={match.params.matterID} msg={msg} success={success} link={link} linktext={linktext}/>;
                      }} />
                      <Route exact={true} path="/tasks" render={() => {
                        setTimeout(() => setActiveTab(Constants.TASKS_TAB), 1);
                        return <TasksPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams}/>;
                      }} />
                      <Route exact={true} path="/tasks/archive" render={() => {
                        setTimeout(() => setActiveTab(Constants.TASKS_TAB), 1);
                        return <TasksArchivePage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams}/>;
                      }} />
                      <Route exact={true} path="/tasks/archive/:taskID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.TASKS_TAB), 1);
                        return <TasksArchivePage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} initialExpandedTaskID={match.params.taskID}/>;
                      }} />
                      <Route exact={true} path="/tasks/:taskID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.TASKS_TAB), 1);
                        return <TasksPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} initialExpandedTaskID={match.params.taskID}/>;
                      }} />
                      <Route exact={true} path="/add_task" render={() => {
                        setTimeout(() => setActiveTab(Constants.TASKS_TAB), 1);
                        const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                        const {messageID, taskID, scanID} = urlParams;
                        return <AddTaskPage allCaseNumbers={allCaseNumbers} users={allUsers} teams={allTeams} initializeFromMessageID={messageID} initializeFromTaskID={taskID} initializeFromScanID={scanID}/>;
                      }} />
                      <Route exact={true} path="/leads/archive" render={() => {
                        setTimeout(() => setActiveTab(Constants.LEADS_TAB), 1);
                        return <LeadsPage allUsers={allUsers} allTeams={allTeams} archive />;
                      }} />
                      <Route exact={true} path="/leads/archive/:leadID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.LEADS_TAB), 1);
                        return <LeadsPage expandedLeadID={parseInt(match.params.leadID)} allUsers={allUsers} allTeams={allTeams} archive />;
                      }} />
                      <Route exact={true} path="/leads" render={() => {
                        setTimeout(() => setActiveTab(Constants.LEADS_TAB), 1);
                        return <LeadsPage allUsers={allUsers} allTeams={allTeams} />;
                      }} />
                      <Route exact={true} path="/add_lead_cr" render={() => {
                        setTimeout(() => setActiveTab(Constants.LEADS_TAB), 1);
                        return <AddLeadPage buildLeadUrl={(leadId) => `/leads_cr/${leadId}`} />;
                      }} />
                      <Route exact={true} path="/add_lead" render={() => {
                        setTimeout(() => setActiveTab(Constants.LEADS_TAB), 1);
                        return <AddLeadPage buildLeadUrl={(leadId) => `/leads/${leadId}`} allowSettingConsultationFields />;
                      }} />
                      <Route exact={true} path="/leads/:leadID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.LEADS_TAB), 1);
                        return <LeadsPage expandedLeadID={parseInt(match.params.leadID)} allUsers={allUsers} allTeams={allTeams} />;
                      }} />
                      <Route exact={true} path="/leads_cr/archive" render={() => {
                        setTimeout(() => setActiveTab(Constants.LEADS_TAB), 1);
                        return <LeadsPage allUsers={allUsers} allTeams={allTeams} archive leadsUrl="/leads_cr"/>;
                      }} />
                      <Route exact={true} path="/leads_cr/archive/:leadID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.LEADS_TAB), 1);
                        return <LeadsPage expandedLeadID={parseInt(match.params.leadID)} allUsers={allUsers} allTeams={allTeams} archive leadsUrl="/leads_cr" />;
                      }} />
                      <Route exact={true} path="/leads_cr" render={() => {
                        setTimeout(() => setActiveTab(Constants.LEADS_CR_TAB), 1);
                        const { tab } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                        return <LeadsPageCustomerRelations allUsers={allUsers} initialTab={tab} />;
                      }} />
                      <Route exact={true} path="/leads_cr/:leadID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.LEADS_CR_TAB), 1);
                        return <LeadsPageCustomerRelations expandedLeadID={parseInt(match.params.leadID)} allUsers={allUsers} allTeams={allTeams} />;
                      }} />
                      <Route exact={true} path="/callform" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <CallFormPage allCaseNumbers={allCaseNumbers} users={allUsers} teams={allTeams}/>;
                      }} />
                      <Route exact={true} path="/rework_inbox_message" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                        const {messageID} = urlParams;
                        return <ReworkInboxMessagePage allCaseNumbers={allCaseNumbers} users={allUsers} teams={allTeams} initializeFromMessageID={messageID}/>;
                      }} />
                      <Route exact={true} path="/inbox/messages" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                        const {ack} = urlParams;
                        return <InboxPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} autoAck={parseInt(ack)} activeTab={Constants.INBOX_MESSAGES_TAB} numberOfUnreadMessages={numberOfUnreadMessages}/>;
                      }} />
                      <Route
                          exact={true}
                          path="/inbox"
                          render={({ location }) => (
                              <Redirect
                                  to={{
                                      pathname: "/inbox/messages",
                                      state: { from: location },
                                  }}
                              />
                          )}
                      />
                      <Route exact={true} path="/inbox/messages/archive" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxArchivePage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} activeTab={Constants.INBOX_MESSAGES_TAB}/>;
                      }} />
                      <Route exact={true} path="/inbox/messages/archive/:msgID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxArchivePage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} initialExpandedMsgID={parseInt(match.params.msgID)} activeTab={Constants.INBOX_MESSAGES_TAB}/>;
                      }} />
                      <Route exact={true} path="/inbox/messages/:msgID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} initialExpandedMsgID={parseInt(match.params.msgID)} activeTab={Constants.INBOX_MESSAGES_TAB} numberOfUnreadMessages={numberOfUnreadMessages}/>;
                      }} />
                      <Route exact={true} path="/upload" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.MATTERS_TAB), 1);
                        const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                        const {messageID, scanID} = urlParams;
                        return <UploadDocumentPage allCaseNumbers={allCaseNumbers} initializeFromMessageID={parseInt(messageID)} initializeFromScanID={parseInt(scanID)}/>;
                      }}/>
                      <Route exact={true} path="/inbox/chats/archive" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} activeTab={Constants.INBOX_CHATS_TAB} numberOfUnreadMessages={numberOfUnreadMessages} archive />;
                      }} />
                      <Route exact={true} path="/inbox/chats/archive/:coversationId" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} activeTab={Constants.INBOX_CHATS_TAB} numberOfUnreadMessages={numberOfUnreadMessages} conversationId={parseInt(match.params.coversationId)} archive />;
                      }} />
                      <Route exact={true} path="/inbox/chats" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} activeTab={Constants.INBOX_CHATS_TAB} numberOfUnreadMessages={numberOfUnreadMessages}/>;
                      }} />
                      <Route exact={true} path="/inbox/chats/:conversationId" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} activeTab={Constants.INBOX_CHATS_TAB} numberOfUnreadMessages={numberOfUnreadMessages} conversationId={parseInt(match.params.conversationId)}/>;
                      }} />
                      <Route exact={true} path="/inbox/scans" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} activeTab={Constants.INBOX_SCANS_TAB} numberOfUnreadMessages={numberOfUnreadMessages}/>;
                      }} />
                      <Route
                          exact={true}
                          path="/scans"
                          render={({ location }) => (
                              <Redirect
                                  to={{
                                      pathname: "/inbox/scans",
                                      state: { from: location },
                                  }}
                              />
                          )}
                      />
                      <Route exact={true} path="/inbox/scans/archive" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxArchivePage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} activeTab={Constants.INBOX_SCANS_TAB}/>;
                      }} />
                      <Route exact={true} path="/inbox/scans/archive/:scanID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxArchivePage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} initialExpandedScanID={parseInt(match.params.scanID)} activeTab={Constants.INBOX_SCANS_TAB}/>;
                      }} />
                      <Route exact={true} path="/inbox/scans/:scanID" render={({match}) => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <InboxPage allCaseNumbers={allCaseNumbers} allUsers={allUsers} allTeams={allTeams} initialExpandedScanID={parseInt(match.params.scanID)} activeTab={Constants.INBOX_SCANS_TAB} numberOfUnreadMessages={numberOfUnreadMessages}/>;
                      }} />
                      <Route exact={true} path="/add_scan" render={() => {
                        setTimeout(() => setActiveTab(Constants.INBOX_TAB), 1);
                        return <AddScanPage users={allUsers} teams={allTeams}/>;
                      }} />
                      <Route exact={true} path="/queue" render={() => {
                        setTimeout(() => setActiveTab(Constants.QUEUE_TAB), 1);
                        return <QueuePage currentUser={currentUser} allUsers={allUsers} />;
                      }} />,

                      {isAdmin && adminRoutes}
                      <Route path="/" render={() => {
                        return <div>This URL does not exist...</div>;
                      }} />
                    </Switch>
                  </div>
                  {expandMentionsBubble && <Modal><MentionsBubble onCloseClicked={onCloseMentionsBubbleClicked} users={allUsers} teams={allTeams}/></Modal>}
                </div>
              </ToastContext.Provider>
            </VonageContextProvider>
          </CurrentUserContext.Provider>
        </Router>
        <Toasts onMount={toastIF => setToastIF(toastIF)}/>
      </div>
    );
  }
}

export default App;
