import React, { useState } from "react";
import API from "../common/api.js";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import withLoader from "../common/withLoader.js";
import CopyToClipboardIconButton from "../components/copytoclipboardiconbutton.js";


function AddScanPage({ users, teams }) {
    const [errMsg, setErrMsg] = useState(null);
    const [saving, setSaving] = useState(false);
    const [assignee, setAssignee] = useState(null);
    const [team, setTeam] = useState(null);
    const [newAttachment, setNewAttachment] = useState(null);
    let history = useHistory();

    const valid = newAttachment !== "" && newAttachment !== null && (assignee !== null || team !== null);
    const returnUrl = "/scans";
    const loading = false;

    async function save() {
        setSaving(true);
        setErrMsg("");
        const scan = await API.createScan(newAttachment, assignee && assignee.value, team && team.value);
        if (scan) {
            setSaving(false);
            history.push(returnUrl);
        } else {
            setErrMsg("ERROR: Saving scan failed! Please retry.");
            setSaving(false);
        }
    }

    const attachmentUploadForm = <input type="file" onChange={(val) => setNewAttachment(val.target.files[0])} />;

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    const assigneeSelect = (
        <Select
            options={users.map((u) => ({ value: u.user_id, label: u.name }))}
            placeholder="Assign to a user"
            value={assignee}
            onChange={(option) => {
                if (option && option.length === 0) option = null;
                setAssignee(option);
            }}
            isClearable={true}
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
        />
    );
    const teamSelect = (
        <Select
            options={teams.map((t) => ({ value: t.team_id, label: t.name }))}
            placeholder="Assign to a team"
            onChange={(option) => setTeam(option)}
            value={team}
            isClearable={true}
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
        />
    );

    function removePunctuation(str) {
        return str.replace(/[^a-zA-Z0-9]/g, "");
    }

    const teamRows = teams.map((t) => {
        const email = `scan+${removePunctuation(t.name).toLowerCase()}@mail.psl-app.com`;
        return (
            <tr key={`team_${t.team_id}`}>
                <td>
                    {email} <CopyToClipboardIconButton value={email} />
                </td>
                <td>
                    <i className="users icon" /> {t.name} Team
                </td>
            </tr>
        );
    });

    const userRows = users.map((u) => {
        const email = `scan+${removePunctuation(u.name).toLowerCase()}@mail.psl-app.com`;
        return (
            <tr key={`user_${u.user_id}`}>
                <td>
                    {email} <CopyToClipboardIconButton value={email} />
                </td>
                <td>
                    <i className="user icon" /> {u.name}
                </td>
            </tr>
        );
    });

    const emailAddressesTable = (
        <table className="ui striped padded table" style={{ width: "auto" }}>
            <thead>
                <tr>
                    <th>Email Address</th>
                    <th>Assigned To</th>
                </tr>
            </thead>
            <tbody>
                {teamRows}
                {userRows}
            </tbody>
        </table>
    );

    const infoMsgEl = (
        <div className="ui icon info message">
            <i className="info icon" style={{marginBottom: 'auto'}}/>
            <div className="content">
                <div className="header">Adding Scans Via Email</div>
                <p>
                    You can also add a scan by sending an email with one or more PDF files attached to one of the
                    following email addresses:
                </p>
                {emailAddressesTable}
            </div>
        </div>
    );

    return withLoader(loading, errMsg, () => (
        <div style={{ height: "100%", overflowY: "auto", paddingRight: 10, paddingBottom: 10 }}>
            {errmsgEl}
            <h1 className="ui header">Add New Scan</h1>
            <div className="ui form segment">
                <div className="field">
                    <label htmlFor="document">PDF file:</label>
                    {attachmentUploadForm}
                </div>
                <div className="field">
                    <label htmlFor="assignee">Assignee:</label>
                    {assigneeSelect}
                </div>
                <div className="field">
                    <label htmlFor="team">Team:</label>
                    {teamSelect}
                </div>

                <button
                    className={classNames("ui primary button", { loading: saving, disabled: !valid })}
                    onClick={save}
                >
                    Upload
                </button>
                <button className="ui black basic button" onClick={() => history.push(returnUrl)}>
                    Cancel
                </button>
            </div>
            {infoMsgEl}
        </div>
    ));
}

export default AddScanPage;
