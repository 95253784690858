/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';

const Table = ({ children, head, dim }) => {
    return (
        <table
            className="ui very basic table"
            style={{
                backgroundColor: dim && 'rgba(11,11,11,.04)',
            }}
        >
            {head && (
                <thead>
                    <tr>
                        {head}
                    </tr>
                </thead>
            )}
            <tbody>
                {children}
            </tbody>
        </table>
    );
};

export default Table;
