import React, { useState } from 'react';
import useScheduleCallForm from '../common/useScheduleCallForm.js';
import { DateInput, TimeInput, FormSubmitButton, Form } from '../common/form';

const RequestCallBackPage = ({ token }) => {
    const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
    const {
        scheduledForDate,
        setScheduledForDate,
        scheduledForFromTime,
        setScheduledForFromTime,
        scheduledForToTime,
        setScheduledForToTime,
        errorMessages,
        loading,
        confirmAndSubmitWithToken,
    } = useScheduleCallForm();

    const submit = async (event) => {
        event.preventDefault();
        const success = await confirmAndSubmitWithToken(token);
        setSubmittedSuccessfully(success);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <div style={{ maxWidth: 500 }}>
                {submittedSuccessfully && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#f9f9f9f5',
                            zIndex: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 20,
                            fontWeight: 'bold',
                        }}
                    >
                        <div style={{ fontSize: 70, color: 'green' }}>
                            <i className="check icon" />
                        </div>
                        <div>
                            Thanks for letting us know! We'll do our best to call you within the specified window.
                        </div>
                    </div>
                )}
                <Form onSubmit={submit}>
                    {errorMessages && errorMessages.length > 0 && (
                        <div style={{ backgroundColor: '#ff3030', padding: 10, borderRadius: 3, marginBottom: 10, border: '1px solid #df0000' }}>
                            {errorMessages.map((errorMessage) => (
                                <div key={errorMessage}>{errorMessage}</div>
                            ))}
                        </div>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 5, flexWrap: 'wrap' }}>
                            <div>
                                I am available on
                            </div>
                            <div>
                                <DateInput
                                    value={scheduledForDate}
                                    onChange={setScheduledForDate}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 5, flexWrap: 'wrap' }}>
                            <div>
                                between
                            </div>
                            <div>
                                <TimeInput
                                    value={scheduledForFromTime}
                                    onChange={setScheduledForFromTime}
                                />
                            </div>
                            <div>
                                and
                            </div>
                            <div>
                                <TimeInput
                                    value={scheduledForToTime}
                                    onChange={setScheduledForToTime}
                                />
                            </div>
                            <div>
                                .
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                        <FormSubmitButton
                            label="Submit"
                            disabled={loading}
                            loading={loading}
                        />
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default RequestCallBackPage;
