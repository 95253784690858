import { useState } from 'react';
import API from './api.js';

const useUserPictureUpdates = ({ userId, onPictureUpdated }) => {
    const [loading, setLoading] = useState(false);

    const replaceCurrentPicture = async ({ name, type, base64 }) => {
        setLoading(true);

        const result = await API.replaceUserPicture({ userId, picture: { name, type, base64 } });

        setLoading(false);

        if (result && onPictureUpdated) onPictureUpdated();
    };

    const deleteCurrentPicture = async () => {
        setLoading(true);

        const result = await API.deleteUserPicture({ userId });

        setLoading(false);

        if (result && onPictureUpdated) onPictureUpdated();
    };

    return {
        replaceCurrentPicture,
        deleteCurrentPicture,
        loading,
    };
};

export default useUserPictureUpdates;
