import React from 'react';

const ValidationErrors = ({ errors }) => {
    if (!errors || errors.length === 0) {
        return null;
    }

    return (
        <div
            style={{
                backgroundColor: '#ffe5e5',
                padding: 7,
                borderRadius: 3,
                fontSize: 12,
            }}
        >
            <ul
                style={{ margin: 0 }}
            >
                {errors.map((error) => (
                    <li
                        key={error.code}
                        style={{
                            color: 'red',
                        }}
                    >
                        {error.description}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ValidationErrors;
