import { useCallback, useState, useEffect } from "react";
import API from "../common/api.js";

const useNotifications = ({ archived, page, pageSize, read }) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const loadData = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        const notificationsResponse = await API.getNotifications({
            archived,
            page,
            page_size: pageSize,
            read,
        });

        if (notificationsResponse) {
            setNotifications(notificationsResponse);
        } else {
            setErrorMessage('Failed to load notifications from the server. Please try reloading the page or report this issue.')
            setNotifications([]);
        }

        setLoading(false);
        setInitialized(true);
    }, [read, archived, page, pageSize]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        notifications,
        loading,
        initialized,
        errorMessage,
        reload: loadData,
    };
};

export default useNotifications;
