import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import CaseNumber from '../components/casenumber.js';
import Select from 'react-select';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './taskcard.css';
import MatterQuickView from '../components/matterquickview.js';
import API from '../common/api.js';
import DatePickerDropdown from '../components/datepickerdropdown.js';
import {useDynamicRequestTextInputModal} from '../common/useModal.js';
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';
import {CurrentUserContext} from '../common/currentUserContext.js';
import {WorkflowButtonParentContext} from '../common/workflowButtonParentContext.js';
import FormFactory from '../components/taskforms/formfactory.js';
import { get } from 'lodash';
import {uuidv4} from '../common/uuid.js';
import {ToastContext} from '../common/toastContext.js';
import usePreventBodyScroll from '../common/usePreventBodyScroll.js';
import generateSelectOptions from '../common/generateSelectOptions.js';
import { useHistory } from "react-router-dom";
import useComments from "../common/useComments.js";
import { formatHumanDateTime, formatShortDate } from "../common/formatting.js";

const ReactMarkdown = require('react-markdown')
var classNames = require('classnames');

const taskCommentsApi = {
    getComments: API.getTaskComments,
    markMentionsRead: API.markTaskMentionsRead,
    createComment: API.createTaskComment,
    createMentionMessage: API.createTaskMentionMessage
};

function TaskCard({task, onCloseClicked, setTaskStatus, setTaskAssignee, possibleAssignees, deleteTask, setTaskDeadline, setTaskFormData, toggleTaskWatched, toggleTaskPinned, readOnly}) {
    const [showChecklists, setShowChecklists] = useState(false);
    const [checklistState, setChecklistState] = useState({checklists: [], checklistItems: []});
    const [loadingChecklists, setLoadingChecklists] = useState(false);
    const [newChecklistItemText, setNewChecklistItemText] = useState("");
    const [showAttachmentsSection, setShowAttachmentsSection] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const [newAttachment, setNewAttachment] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [openTextInputModal, textInputModal] = useDynamicRequestTextInputModal();
    usePubSub({topic: Constants.PUBSUB_TOPIC_TASK, messageHandler: handleServerMessage});
    const [errMsg, setErrMsg] = useState(null);
    const currentUser = useContext(CurrentUserContext);
    const toastIF = useContext(ToastContext);
    const workflowButtonParentRef = useRef(null);
    let history = useHistory();
    usePreventBodyScroll();

    const taskID = task && task.task_id;
    const { comments, setComments, commentsSection, toggleCommentsButton } = useComments(taskID, task, taskCommentsApi, possibleAssignees, readOnly, currentUser);

    const loadChecklists = useCallback(async (showSpinner = true) => {
        if (taskID) {
            setLoadingChecklists(showSpinner);
            const data = await API.getTaskChecklists(taskID);
            if (data) {
                let show = false;
                const itemsPerList = {};
                for (let inx = 0; inx < data.length; ++inx) {
                    const list = data[inx];
                    const itemData = await API.getTaskChecklistItems(taskID, list.list_id);
                    if (itemData) {
                        itemsPerList[list.list_id] = {list_id: list.list_id, items: itemData};
                        if (itemData.length > 0) {
                            show = true;
                        }
                    }
                }
                setChecklistState({checklists: data, checklistItems: itemsPerList});
                setShowChecklists(show);
                
            } else {
                setErrMsg("Unable to load checklists from server");
                setChecklistState({checklists: [], checklistItems: []});
                setShowChecklists(false);
            }
            setLoadingChecklists(false);
        }
    }, [taskID]);

    useEffect(() => {
        setShowChecklists(false);
        setNewChecklistItemText("");
        loadChecklists();
    }, [taskID, loadChecklists]);

    const loadAttachments = useCallback(async () => {
        if (taskID) {
            setLoadingAttachments(true);
            let data = await API.getTaskAttachments(taskID);
            if (data) {
                setAttachments(data);
                setShowAttachmentsSection(data.length > 0);
            } else {
                setErrMsg("Unable to load attachments from server");
                setAttachments([]);
                setShowAttachmentsSection(false);
            }
            setLoadingAttachments(false);
        }
    }, [taskID]);

    useEffect(() => {
        setShowAttachmentsSection(false);
        loadAttachments();
    }, [taskID, loadAttachments]);

    async function uploadNewAttachment() {
        if (newAttachment) {
            setUploading(true);
            const attachment = await API.uploadTaskAttachment(taskID, newAttachment);
            const newAttachments = [...attachments, {filename: attachment.filename, mimetype: attachment.mimetype, uri: attachment.uri}];
            setAttachments(newAttachments);
            setUploading(false);
            setNewAttachment(null);
        }
    }

    function deleteTaskAttachment(attachment) {
        // Update state locally for snappy user experience
        const newAttachments = attachments.filter(a => a.attachment_id !== attachment.attachment_id);
        setAttachments(newAttachments);

        // Update on the server in the background
        API.deleteTaskAttachment(task.task_id, attachment.attachment_id);
    }

    const markTaskDone = useCallback((msg, formData) => {
        setTaskStatus(task, Constants.TASK_STATUS_DONE);
        setTaskFormData(task, formData);
        toastIF.addToast(msg);
        onCloseClicked();
    }, [task, onCloseClicked, setTaskStatus, setTaskFormData, toastIF]);

    const markTaskDoing = useCallback(() => {
        setTaskStatus(task, Constants.TASK_STATUS_DOING);
        setTaskAssignee(task, {is_user: true, id: currentUser.userID});
    }, [task, currentUser.userID, setTaskStatus, setTaskAssignee]);

    let workflowButtonLabel = "";
    let workflowButtonDisabled = false;
    let workflowButtonTip = null;
    let workflowButtonAction = null;
    let renderWorkflowButtonThroughPortal = false;

    if ((task.status === Constants.TASK_STATUS_DOING) && task.assignee_id === currentUser.userID) {
        if ((task.form_type !== null)) {
            renderWorkflowButtonThroughPortal = true;
        } else {
            workflowButtonLabel = "Done";
            workflowButtonTip = "Mark this task as done";
            workflowButtonAction = (() => markTaskDone(`Done: ${task.title}`, null));
            workflowButtonDisabled = false;
        }
    } else if (readOnly && (task.status === Constants.TASK_STATUS_DONE)) {
        workflowButtonLabel = "Rework Task";
        workflowButtonTip = "Create a copy of this task";
        workflowButtonAction = () => history.push(`/add_task?taskID=${task.task_id}`);
        workflowButtonDisabled = false;
    } else {
        workflowButtonLabel = "I'll Take It";
        workflowButtonTip = "Assign this task to me and move it to state Doing";
        workflowButtonAction = markTaskDoing;
        workflowButtonDisabled = false;
    }
    

    function handleServerMessage(topic, data) {
        if (parseInt(get(data, 'task_id')) === get(task, 'task_id')) {
            if (topic === Constants.PUBSUB_TOPIC_TASK_COMMENT_ADDED) {
                const newComments = [data, ...comments];
                setComments(newComments);
            } else if (topic === Constants.PUBSUB_TOPIC_TASK_CHECKLIST_ADDED) {
                loadChecklists();
            } else if (topic === Constants.PUBSUB_TOPIC_TASK_CHECKLIST_ITEM_ADDED) {
                const newItems = {};
                Object.keys(checklistItems).forEach(lid => {
                    const list_id = data.list_id;
                    if (parseInt(lid) === list_id) {
                        const newChecklistItems = [...checklistItems[list_id].items, data];
                        newItems[lid] = {...checklistItems[lid], items: newChecklistItems};
                    } else {
                        newItems[lid] = checklistItems[lid];
                    }
                });
                const newChecklistState = {...checklistState, checklistItems: newItems};
                setChecklistState(newChecklistState);
            } else if (topic === Constants.PUBSUB_TOPIC_TASK_CHECKLIST_ITEM_UPDATED) {
                const newItems = {};
                Object.keys(checklistItems).forEach(list_id => {
                    const item = data;
                    if (parseInt(list_id) === item.list_id) {
                        const newChecklistItems = checklistItems[list_id].items.map(i => {
                            if (i.item_id === item.item_id) {
                                return item;
                            }
                            return i;
                        });
                        newItems[list_id] = {...checklistItems[list_id], items: newChecklistItems};
                    } else {
                        newItems[list_id] = checklistItems[list_id];
                    }
                });
                setChecklistState({...checklistState, checklistItems: newItems});
            } else if (topic === Constants.PUBSUB_TOPIC_TASK_CHECKLIST_ITEM_DELETED) {
                const newItems = {};
                Object.keys(checklistItems).forEach(list_id => {
                    const item = data;
                    if (parseInt(list_id) === parseInt(item.list_id)) {
                        const newChecklistItems = checklistItems[list_id].items.filter(i => parseInt(i.item_id) !== parseInt(item.item_id));
                        newItems[list_id] = {...checklistItems[list_id], items: newChecklistItems};
                    } else {
                        newItems[list_id] = checklistItems[list_id];
                    }
                });
                setChecklistState({...checklistState, checklistItems: newItems});
            } else if (topic === Constants.PUBSUB_TOPIC_TASK_ATTACHMENT_ADDED) {
                const newAttachments = [...attachments, data];
                setAttachments(newAttachments);
            } else if (topic === Constants.PUBSUB_TOPIC_TASK_ATTACHMENT_DELETED) {
                const newAttachments = attachments.filter(a => parseInt(a.attachment_id) !== parseInt(data.attachment_id));
                setAttachments(newAttachments);
            }
        }
    }

    async function toggleChecklistItem(item) {
        // Update state locally for snappy user experience
        const { checklistItems } = checklistState;
        const newItems = {};
        Object.keys(checklistItems).forEach(list_id => {
            if (parseInt(list_id) === item.list_id) {
                const newChecklistItems = checklistItems[list_id].items.map(i => {
                    if (i.item_id === item.item_id) {
                        return {...i, completed: !i.completed};
                    }
                    return i;
                });
                newItems[list_id] = {...checklistItems[list_id], items: newChecklistItems};
            } else {
                newItems[list_id] = checklistItems[list_id];
            }
        });
        setChecklistState({...checklistState, checklistItems: newItems});

        // Update on the server in the background
        const fieldsToUpdate = {completed: !item.completed};
        API.updateTaskChecklistItem(task.task_id, item.list_id, item.item_id, fieldsToUpdate);
    }

    async function handleNewChecklistItemKeyPress(ev) {
        if (ev.key === 'Enter') {
            if (newChecklistItemText !== "") {
                // Do we have a list already?
                let { checklists, checklistItems } = checklistState;
                let list_id = null;
                if (checklists && checklists.length > 0) {
                    list_id = checklists[0].list_id;
                } else {
                    // Create the list
                    const newList = await API.createTaskChecklist(task.task_id, "My Checklist");
                    if (newList) {
                        list_id = newList.list_id;
                        await loadChecklists();
                        checklists = checklistState.checklists;
                        checklistItems = checklistState.checklistItems;
                    } else {
                        setErrMsg("Unable to create a new checklist");
                        return;
                    }
                }

                // Update state locally for snappy user experience
                const tempItemID = uuidv4();
                const newItems = {};
                Object.keys(checklistItems).forEach(lid => {
                    if (parseInt(lid) === list_id) {
                        const newChecklistItems = [...checklistItems[list_id].items, {list_id, item_id: tempItemID, saving: true, title: newChecklistItemText, completed: false}];
                        newItems[lid] = {...checklistItems[lid], items: newChecklistItems};
                    } else {
                        newItems[lid] = checklistItems[lid];
                    }
                });
                const newChecklistState = {...checklistState, checklistItems: newItems};
                setChecklistState(newChecklistState);

                // Update on the server in the background
                const title = newChecklistItemText;
                setNewChecklistItemText("");
                await API.createTaskChecklistItem(task.task_id, list_id, title);
                loadChecklists(false);
            }
        }
    }

    async function deleteTaskChecklistItem(item) {
        // Update state locally for snappy user experience
        const { checklistItems } = checklistState;
        const newItems = {};
        Object.keys(checklistItems).forEach(list_id => {
            if (parseInt(list_id) === item.list_id) {
                const newChecklistItems = checklistItems[list_id].items.filter(i => i.item_id !== item.item_id);
                newItems[list_id] = {...checklistItems[list_id], items: newChecklistItems};
            } else {
                newItems[list_id] = checklistItems[list_id];
            }
        });
        setChecklistState({...checklistState, checklistItems: newItems});

        // Update on the server in the background
        API.deleteTaskChecklistItem(task.task_id, item.list_id, item.item_id);
    }

    async function renameTaskChecklistItem(item) {
        const save = (newTitle) => {
            // Update state locally for snappy user experience
            const { checklistItems } = checklistState;
            const newItems = {};
            Object.keys(checklistItems).forEach(list_id => {
                if (parseInt(list_id) === item.list_id) {
                    const newChecklistItems = checklistItems[list_id].items.map(i => {
                        if (i.item_id === item.item_id) {
                            return {...i, title: newTitle};
                        }
                        return i;
                    });
                    newItems[list_id] = {...checklistItems[list_id], items: newChecklistItems};
                } else {
                    newItems[list_id] = checklistItems[list_id];
                }
            });
            setChecklistState({...checklistState, checklistItems: newItems});

            // Update on the server in the background
            const fieldsToUpdate = {title: newTitle};
            API.updateTaskChecklistItem(task.task_id, item.list_id, item.item_id, fieldsToUpdate);
        };

        openTextInputModal({
            title: 'Edit Checklist Item',
            prompt: null,
            text: item.title,
            okButtonText: "Save",
            action: save
        });
    }

    if (task === null) return null;

    const possibleStatus = [
        Constants.TASK_STATUS_TODO, Constants.TASK_STATUS_DOING, Constants.TASK_STATUS_DONE, Constants.TASK_STATUS_BLOCKED
    ].map(s => ({value: s, label: Constants.TASK_STATUS_LABELS[s]}));
    const status = possibleStatus.find(s => s.value === task.status);

    const headerStyle = {backgroundColor: 'white', position: 'relative', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', flexGrow: 0, flexDirection: 'column'};
    const footerStyle = {...headerStyle, flexDirection: 'row'};
    const closeButtonStyle = {position: 'absolute', right: 10, top: 10, padding: 7};
    const headerFieldsStyle = {display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 30, alignItems: 'center'};
    const titleFieldStyle = {flexGrow: 1};
    const contentSectionParentStyle = {backgroundColor: '#F8F8F8', paddingTop: 0, paddingBottom: 0, borderTop: 'none', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'};
    const contentSectionStyle = {backgroundColor: '#F8F8F8', marginTop: '3em', marginBottom: '2em', borderTop: 'none'};
    const stretcherStyle = {...contentSectionStyle, flexGrow: 1000, height: 0, border: 'none', padding: 0};
    const newChecklistItemFormStyle = {marginTop: 10};
    const newChecklistItemInputStyle = {width: '50%', marginLeft: 25};
    const insetStyle = {paddingLeft: '3.3em'};
    const modalContentStyle = {display: 'flex', zIndex: 2, minHeight: '60vh', maxHeight: '80vh', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', maxWidth: 1500};

    const descriptionSection = (
        <div className="content" style={contentSectionStyle}>
            <h3 className="ui header"><i className="align left icon"/> Description</h3>
            <div className="taskcard-description" style={insetStyle}>
                <ReactMarkdown source={task.description}/>
            </div>
        </div>
    );

    let checklistCounter = null;
    const {checklistItems, checklists} = checklistState;
    if (loadingChecklists) {
        checklistCounter = "Loading...";
    } else if (checklists) {
        if (checklists.length === 0) {
            if (readOnly) {
                checklistCounter = <><i className="check square outline icon"/> 0 ToDo's</>;
            } else {
                checklistCounter = <><i className="check square outline icon"/> Add Checklist</>;
            }
        } else {
            const items = checklistItems[checklists[0].list_id].items;
            const numCompleted = items.filter(item => item.completed).length;
            checklistCounter = <><i className="check square outline icon"/> {numCompleted}/{items.length} ToDo's</>;
        }
    }

    let checklistItemEls = [];
    if (checklists && checklistItems && (checklists.length > 0)) {
        const itemsToAppend = checklistItems[checklists[0].list_id].items.map((item, inx) => {
            const completionInfo = (item.completed && item.completed_at && item.completed_by) ? 
                <span className="dim">{formatShortDate(item.completed_at)} by {item.completed_by}</span> : 
                null;
            return (
                <div className="inline field" key={inx}>
                    <div className="checkbox-item-wrapper">
                        <div className={`ui ${item.completed ? "checked" : ""} checkbox`} onClick={() => readOnly || toggleChecklistItem(item)}>
                            <input type="checkbox" className="hidden" {...{checked: item.completed}} onChange={() => {}}/>
                            <label>{item.title} <span className={classNames("ui mini inline loader", {active: item.saving})}/> {completionInfo}</label>
                        </div>
                        <div style={{flexGrow: 1}}/>
                        {readOnly || <i className="edit link icon checkbox-item-button" onClick={() => renameTaskChecklistItem(item)}/>}
                        {readOnly || <i className="trash link icon checkbox-item-button" onClick={() => deleteTaskChecklistItem(item)}/>}
                    </div>
                </div>
            );
        });
        checklistItemEls.push(itemsToAppend);
    }
    if (!readOnly) {
        checklistItemEls.push(
            <div className="ui form" style={newChecklistItemFormStyle} key="new_checklist_item">
                <div className="inline field">
                    <input type="text" placeholder="Add an item..." value={newChecklistItemText} onChange={(ev) => setNewChecklistItemText(ev.target.value)} style={newChecklistItemInputStyle} onKeyPress={handleNewChecklistItemKeyPress}/>
                </div>
            </div>
        );
    }

    const checklistSpinner = (
        <div>
            <div className="ui active inline loader" style={{marginTop: 20}}></div>
        </div>
    );

    const checklistSection = (
        <div className="content" style={contentSectionStyle}>
            <h3 className="ui header"><i className="square check icon"/> Checklist</h3>
            <div style={insetStyle}>
                {loadingChecklists ? checklistSpinner : checklistItemEls}
            </div>
        </div>
    );

    function iconForMimetype(type) {
        const icon = 
            type === "application/pdf" ? "file pdf outline icon" :
            type.startsWith("audio/") ? "file audio outline icon" :
            type.startsWith("image/") ? "file image outline icon" :
            type.startsWith("video/") ? "file video outline icon" :
            "file outline icon";
        return icon;
    }

    const attachmentEls = attachments && (
        <ul style={{listStyle: 'none', paddingLeft: 0}}>
            {attachments.map((attachment, inx) => (
                <li key={inx} className="mb-2">
                    <div className="attachment-item-wrapper">
                        <i className={`large ${iconForMimetype(attachment.mimetype)}`}/> <a href={attachment.uri} target="_blank" rel="noopener noreferrer">{attachment.filename}</a>
                        <div style={{flexGrow: 1}}/>
                        {readOnly || <i className="trash link icon attachment-item-button" onClick={() => deleteTaskAttachment(attachment)}/>}
                    </div>
                </li>
            ))}
        </ul>
    );

    const attachmentsSpinner = (
        <div>
            <div className="ui active inline loader" style={{marginTop: 20}}></div>
        </div>
    );

    const attachmentUploadForm = (
        <div className="ui form" style={{paddingLeft: '3.3em'}}>
            <div className="field">
                <label>Add New Attachment</label>
                <input type="file" onChange={(val) => setNewAttachment(val.target.files[0])} style={{width: '50%', marginLeft: 25}}/> <button className={classNames("ui primary button", {loading: uploading})} disabled={newAttachment === null} onClick={uploadNewAttachment} style={{marginTop: 2}}>Upload</button>
            </div>
        </div>
    );

    const attachmentsSection = (
        <div className="content" style={contentSectionStyle}>
            <h3 className="ui header"><i className="paperclip icon"/> Attachments</h3>
            <div style={insetStyle}>
                {loadingAttachments ? attachmentsSpinner : attachmentEls}
            </div>
            {readOnly || attachmentUploadForm}
        </div>
    );

    let attachmentCounter = null;
    if (loadingAttachments) {
        attachmentCounter = "Loading...";
    } else if (attachments) {
        if (attachments.length === 0) {
            if (readOnly) {
                attachmentCounter = <><i className="paperclip icon"/> 0 Attachments</>
            } else {
                attachmentCounter = <><i className="paperclip icon"/> Add Attachment</>;
            }
        } else if (attachments.length === 1) {
            attachmentCounter = <><i className="paperclip icon"/> 1 Attachment</>
        } else {
            attachmentCounter = <><i className="paperclip icon"/> {attachments.length} Attachments</>
        }
    }

    const formSection = (task.form_type !== null) && (
        <div className={classNames("content", {readOnly})} style={contentSectionStyle}>
            <h3 className="ui header"><i className="tasks icon"/> Steps To Complete</h3>
            <div style={insetStyle}>
                <FormFactory formType={task.form_type} caseNumber={task.case_number} extraData={task.extra_data} initialFormState={task.form_data} completedAt={task.completed_at} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing}/>
            </div>
        </div>
    );
    
    const statusSelect = readOnly || (
        <span style={{marginRight: 10}}>
            <Dropdown options={possibleStatus} onChange={option => setTaskStatus(task, option.value)} value={status} controlClassName="thin-dropdown-control" className="w-40"/>
        </span>
    );

    const assignee = task.assignee_id ? 
        possibleAssignees.find(a => a.is_user && (a.id === task.assignee_id)) :
        possibleAssignees.find(a => a.is_team && (a.id === task.team_id));
    const assigneeSelectStyles = {
        container: styles => ({...styles, zIndex: 100, color: 'black', flexGrow: 1}),
        control: styles => ({ ...styles, borderWidth: 0, ':hover': {cursor: 'pointer'} }),
        singleValue: styles => ({ ...styles, ':hover': {cursor: 'pointer', color: '#1e70bf'} }),
        indicatorsContainer: styles => ({ ...styles, display: 'none' }),
    };
    const assigneeIcon = task.assignee_id ? "user icon" : "users icon";
    const assigneeSelect = readOnly ?
        (
            <div style={{width: 300, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <i className={`fitted ${assigneeIcon} mr-4`} style={{lineHeight: '1.1em', paddingRight: 10}}/>
                {assignee.label}
            </div>
        )
        :
        (
            <div style={{width: 300, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}} data-tooltip="Who is this task assigned to? Click to re-assign" data-position="right center">
                <i className={`fitted ${assigneeIcon} mr-4`} style={{lineHeight: '1.1em'}}/>
                <Select options={generateSelectOptions(possibleAssignees)} styles={assigneeSelectStyles} value={assignee} onChange={option => setTaskAssignee(task, option)} menuPlacement="top"/>
            </div>
        );

    const humanDeadline = formatHumanDateTime(task.deadline);
    const deadlineEditor = readOnly ?
        (
            task.deadline ? <span style={{marginRight: 10, lineHeight: '2em'}}>Due {humanDeadline}</span> : <span style={{marginRight: 10, lineHeight: '2em'}}>No due date</span>
        )
        :
        (
            <span style={{marginRight: 10}}><DatePickerDropdown value={task.deadline} onChange={(d) => setTaskDeadline(task, d)} prefix="Due " nullText="No due date" compact/></span>
        );

    const quickView = task.case_number && task.case_number.length > 0 && (
        <div className="modal-rail">
            <div className="ui segment" style={{height: '100%', overflowY: 'scroll'}}>
                <MatterQuickView caseNumber={task.case_number}/>
            </div>
        </div>
    );

    const stretcher = <div className="content" style={stretcherStyle}></div>;

    const humanCreationDate = formatHumanDateTime(task.created_at);
    const humanCompletedAt = formatHumanDateTime(task.completed_at);
    const completedBy = task.completed_by ? ` by ${task.completed_by}` : null;

    const workflowButton = (
        <>
            <div className={classNames("ui compact primary button", {hidden: renderWorkflowButtonThroughPortal})} data-tooltip={workflowButtonTip} data-position="top right" onClick={workflowButtonAction} style={{marginRight: '2em'}} disabled={workflowButtonDisabled}>{workflowButtonLabel}</div>
            <div className={classNames({hidden: !renderWorkflowButtonThroughPortal})} ref={workflowButtonParentRef} style={{marginRight: '2em', display: 'inline-block'}}/>
        </>
    );

    const taskDetails = (
        <div className="ui raised fluid card" style={{}}>
            <div className="content" style={headerStyle}>
                <div style={{...headerFieldsStyle, marginBottom: '0.5em'}}>
                    <span style={closeButtonStyle} onClick={onCloseClicked}><i className="fitted black close link icon"/></span>
                    <div style={headerFieldsStyle}>
                        <div style={titleFieldStyle}>
                            <h2 className="mr-4">{task.title}</h2>
                        </div>
                    </div>
                </div>
                <div style={{...headerFieldsStyle, paddingRight: 0}}>
                    <div style={{opacity: 0.7}}>
                        <CaseNumber caseNumber={task.case_number} style={{marginRight: '2em'}}/>
                    </div>
                    <div className="flex">
                        {deadlineEditor}
                        {statusSelect}
                        <div className={`ui ${Constants.TASK_PRIO_COLORS[task.priority]} horizonal label`}>{Constants.TASK_PRIO_LABELS[task.priority]}</div>
                    </div>
                </div>
            </div>
            <div className="content" style={contentSectionParentStyle}>
                <div className="flex">
                    <div className="flex-grow">
                        {task.description && descriptionSection}
                        <WorkflowButtonParentContext.Provider value={workflowButtonParentRef.current}>
                            {formSection}
                        </WorkflowButtonParentContext.Provider>
                        {stretcher}
                        {commentsSection}
                        {showChecklists && checklistSection}
                        {showAttachmentsSection && attachmentsSection}
                    </div>
                    <div style={{paddingLeft: '1em', paddingRight: '1em', marginTop: '4em', width: 250, flexGrow: 0, flexShrink: 0}}>
                        <p style={{fontWeight: 'bold', color: '#5F6D83'}}>SECTIONS</p>
                        {toggleCommentsButton}
                        <button className={classNames("ui fluid labeled icon toggle button", {active: showChecklists})} style={{marginBottom: '0.5em', textAlign: 'left'}} onClick={() => setShowChecklists(!showChecklists)}>{checklistCounter}</button>
                        <button className={classNames("ui fluid labeled icon toggle button", {active: showAttachmentsSection})} style={{marginBottom: '0.5em', textAlign: 'left'}} onClick={() => setShowAttachmentsSection(!showAttachmentsSection)}>{attachmentCounter}</button>
                        {readOnly || <button className={classNames("ui fluid labeled icon toggle button", {active: task.is_watched_by_current_user})} style={{marginBottom: '0.5em', textAlign: 'left'}} onClick={() => toggleTaskWatched(task)}><i className="eye icon"/>Watch Task</button>}
                        {readOnly || <button className={classNames("ui fluid labeled icon toggle button", {active: task.is_pinned_by_current_user})} style={{marginBottom: '0.5em', textAlign: 'left'}} onClick={() => toggleTaskPinned(task)}><i className="thumbtack icon"/>Pin Task</button>}

                        <p style={{fontWeight: 'bold', color: '#5F6D83', marginTop: '2em'}}>ABOUT TASK</p>
                        {task.author_name && <div style={{marginRight: '2em', marginBottom: '0.25em'}} data-tooltip="Who created this task?" data-position="left center"><i className="address card outline icon"/> {task.author_name}</div>}
                        <div style={{marginRight: '2em', marginBottom: '0.25em'}} data-tooltip="Created at" data-position="left center"><i className="calendar plus outline icon"/> {humanCreationDate}</div>
                        {task.completed_at && <div style={{marginRight: '2em'}} data-tooltip="Completed at" data-position="left center"><i className="calendar check outline icon"/> {humanCompletedAt}{completedBy}</div>}
                        
                    </div>
                </div>
            </div>
            <div className="content" style={footerStyle}>
                <div style={{flexGrow: 1}}>{assigneeSelect}</div>
                <div>{workflowButton}</div>
                <div><span data-tooltip="Destroy this task" data-position="top right"><i className="trash link icon" style={{marginLeft: 'auto'}} onClick={() => deleteTask(task)}/></span></div>
            </div>
        </div>
    );

    return (
        <>
            <div className="modal-overlay" style={{zIndex: 1}} onClick={onCloseClicked}/>
            <div style={modalContentStyle}>
                {taskDetails}
                {quickView}
                {textInputModal}
            </div>
        </>
    );
}

export default TaskCard;
