import React from 'react';

const LoadMoreButton = ({ onClick, disabled }) => {
    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <button
                onClick={onClick}
                disabled={disabled}
                style={{
                    cursor: 'pointer',
                    border: '1px solid #ececec',
                    padding: '3px 15px',
                    borderRadius: 4,
                    fontSize: 13,
                    color: '#444',
                }}
            >
                Load more
            </button>
        </div>
    );
};

export default LoadMoreButton;
