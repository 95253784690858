/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
var classNames = require('classnames');

const FilterButton = ({ onClick, active, pressed }) => {
    return (
        <button
            className={classNames("ui basic icon button", { active })}
            onClick={onClick}
        >
            <i className={classNames("filter icon", { green: pressed })}/>
        </button>
    );
};

export default FilterButton;
