/* eslint jsx-a11y/anchor-is-valid: 0 */
import { LEAD_SCHEDULED_CALLS_STATUSES } from './webconstants.js';

export const labelForLeadScheduledCallStatusValue = (givenValue) => {
    if (givenValue === 'overwritten-by-client') {
        return 'Overwritten By Client';
    }

    const statusKey = Object.keys(LEAD_SCHEDULED_CALLS_STATUSES)
        .find((key) => LEAD_SCHEDULED_CALLS_STATUSES[key].value === givenValue);

    if (!statusKey) return givenValue;

    return LEAD_SCHEDULED_CALLS_STATUSES[statusKey].label;
};
