import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
var classNames = require('classnames');

function LimitationsReminder({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        appealFiledNotMuted: null,
        requesterName: username,
        reason: ""
    });
    
    const saveForm = useCallback(async (matterID) => {
        return await API.saveLimitationsReminder(formState.appealFiledNotMuted, formState.requesterName, formState.reason, matterID);
    }, [formState.appealFiledNotMuted, formState.requesterName, formState.reason]);

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={formState.appealFiledNotMuted === null} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.appealFiledNotMuted === true})} onClick={() => { updateFormState({appealFiledNotMuted: true}) }}>I have filed the appeal</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.appealFiledNotMuted === false})} onClick={() => { updateFormState({appealFiledNotMuted: false}) }}>Stop this alert</div>
                </div>
            </div>

            {formState.appealFiledNotMuted === true && (
                <>
                    <div className="field">
                        <p>Press the Save button below to confirm that you've filed the appeal.</p>
                    </div>
                </>
            )}

            {formState.appealFiledNotMuted === false && (
                <>
                    <div className="field">
                        <p>You wish to stop future alerts for this matter. Please make certain this alert is no longer necessary and complete the information below:</p>
                    </div>
                    <div className="field">
                        <label htmlFor="reason">Reason for request:</label>
                        <textarea type="text" name="reason" value={formState.reason} onChange={ev => updateFormState({reason: ev.target.value})} placeholder="Enter reason..." rows="10" required></textarea>
                    </div>
                </>
            )}
        </TaskForm>
    );
}

export default LimitationsReminder;
