/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useMemo, useState } from 'react';
import { FaVoicemail } from 'react-icons/fa';
import { formatDateTimeHumanDateTime, durationAsMinutesString, formatDateTimeRange } from '../../common/formatting.js';
import { LEAD_EVENT_TYPES, LEAD_FIELDS_LABELS } from '../../common/webconstants.js';
import useAllUsers from '../../common/useAllUsers.js';
import { labelForLeadScheduledCallStatusValue } from '../../common/scheduledCallsUtils.js';

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const LeadEventRow = ({ headline, lines, iconName, icon, tooltip, iconColor, datetime, bullets }) => {
    return (
        <div
            style={{ display: 'flex' }}
            data-tooltip={tooltip}
            data-position="left center"
        >
            <div style={{ marginRight: 1, color: iconColor || '#666' }}>
                {icon || <i className={`${iconName} icon`} />}
            </div>
            <div>
                <div style={{ fontWeight: 'normal', color: 'rgb(26, 26, 26)' }}>
                    {headline}
                </div>
                {lines && (
                    <ul
                        style={{
                            fontSize: '90%',
                            color: '#535353',
                            margin: 0,
                            paddingLeft: bullets ? 20 : 0,
                            listStyleType: bullets ? 'lower-roman' : 'none',
                        }}
                    >
                        {lines.map((line, index) => (
                            <li key={index}>
                                {line}
                            </li>
                        ))}
                    </ul>
                )}
                <div style={{ fontSize: '85%', color: 'rgb(128, 128, 128)' }}>
                    {formatDateTimeHumanDateTime(datetime)}
                </div>
            </div>
        </div>
    );
};

const CallLogRow = ({ callLog, allUsers }) => {
    const { getUserName } = useAllUsers({ allUsers });

    const tooltip = useMemo(() => {
        let text = '';

        if (callLog.completedAt) {
            if (callLog.answeredAt) {
                text = `Answered ${formatDateTimeHumanDateTime(callLog.answeredAt)}${callLog.wentToVoicemail ? ' (VM)' : ''} | Completed ${formatDateTimeHumanDateTime(callLog.completedAt)}`;
            } else {
                text = `Unanswered | Completed ${formatDateTimeHumanDateTime(callLog.completedAt)}`;
            }
        } else {
            text = `Call started ${formatDateTimeHumanDateTime(callLog.attemptedAt)}`;
        }

        return text;
    }, [callLog]);
    const iconColor = useMemo(() => {
        if (!callLog.completedAt) return null;

        return (callLog.answeredAt && !callLog.wentToVoicemail) ? '#00c600' : 'red';
    }, [callLog]);
    const headline = useMemo(() => {
        if (callLog.pslAgentId) {
            const pslAgentName = getUserName(callLog.pslAgentId);

            return `Call with ${pslAgentName}`;
        } else {
            return 'Waiting in queue'
        }
    }, [callLog.pslAgentId, getUserName]);
    const lines = useMemo(() => {
        const lines = [];

        lines.push(`Re: ${callLog.purpose ? capitalize(callLog.purpose) : 'Unspecified'}`);

        const statusRow = [];

        if (callLog.status) {
            const status = callLog.status.charAt(0).toUpperCase() + callLog.status.slice(1);
            statusRow.push(status);
        }

        if (callLog.answeredAt && callLog.completedAt) {
            const duration = durationAsMinutesString(callLog.answeredAt, callLog.completedAt);
            statusRow.push(`${duration} min`);
        }

        if (statusRow.length > 0) {
            lines.push(statusRow.join(' • '));
        }

        return lines
    }, [callLog]);

    return (
        <LeadEventRow
            headline={headline}
            tooltip={tooltip}
            icon={callLog.wentToVoicemail ? <span style={{ margin: '0 .25rem 0 0', paddingRight: '.1rem' }}><FaVoicemail /></span> : null}
            iconName="phone"
            iconColor={iconColor}
            datetime={callLog.attemptedAt}
            lines={lines}
        />
    );
};

const RecommendationUpdateRow = ({ recommendationUpdate, allUsers }) => {
    const { getUserName } = useAllUsers({ allUsers });
    const userName = getUserName(recommendationUpdate.userId);
    const headline = useMemo(() => {
        if (!recommendationUpdate.toValue) return 'Recommendation removed';
        return `Recommendation: ${recommendationUpdate.toValue}`;
    }, [recommendationUpdate.toValue]);
    const tooltip = useMemo(() => {
        if (recommendationUpdate.toValue) {
            return `${userName} updated the recommendation to "${recommendationUpdate.toValue}"`;
        } else {
            return `${userName} removed the recommendation`;
        }
    }, [userName, recommendationUpdate.toValue]);

    return (
        <LeadEventRow
            headline={headline}
            tooltip={tooltip}
            iconName="tag"
            datetime={recommendationUpdate.createdAt}
            lines={[
                userName,
            ]}
        />
    );
};

const TextMessageRow = ({ textMessage, allUsers }) => {
    const { getUserName } = useAllUsers({ allUsers });
    const userName = getUserName(textMessage.userId);

    return (
        <LeadEventRow
            headline="SMS sent"
            tooltip={`${userName} sent an SMS to ${textMessage.number}`}
            iconName="envelope"
            datetime={textMessage.createdAt}
            lines={[
                `"${textMessage.message}"`,
                userName,
            ]}
        />
    );
};

const OpportunityUpdateRowAttributeLine = ({ updatedFieldName, updatedFieldsOldValues, opportunityModelDescription, getUserName }) => {
    let oldValue = updatedFieldsOldValues[updatedFieldName];
    if (updatedFieldName === 'benefitsType') {
        oldValue = opportunityModelDescription
            .fields
            .benefitsType
            .options
            .find(({ value }) => value === oldValue)
            .label
    } else if (updatedFieldName === 'assigneeId') {
        oldValue = getUserName(oldValue);
    }

    const label = LEAD_FIELDS_LABELS[updatedFieldName] || updatedFieldName;
    const fromClause = oldValue === null ? '(previously unset)' : `(from "${oldValue}")`;

    return (
        <>
            {label}
            {' '}
            <span style={{ fontSize: '80%', color: '#717171' }}>
                {fromClause}
            </span>
        </>
    );
};

const OpportunityUpdateRow = ({ fieldsUpdate, allUsers, opportunityModelDescription }) => {
    const { getUserName } = useAllUsers({ allUsers });
    const userName = getUserName(fieldsUpdate.userId);

    return (
        <LeadEventRow
            headline={<><span style={{ fontStyle: 'italic' }}>{userName}</span> updated the archived Lead</>}
            iconName="pencil alternate"
            datetime={fieldsUpdate.createdAt}
            lines={
                Object.keys(fieldsUpdate.updatedFieldsOldValues).map((updatedFieldName) => {
                    return (
                        <OpportunityUpdateRowAttributeLine
                            updatedFieldName={updatedFieldName}
                            updatedFieldsOldValues={fieldsUpdate.updatedFieldsOldValues}
                            opportunityModelDescription={opportunityModelDescription}
                            getUserName={getUserName}
                        />
                    );
                })
            }
            bullets
        />
    );
};

const ScheduledCallRow = ({ scheduledCall, allUsers }) => {
    const { getUserName } = useAllUsers({ allUsers });
    const tooltip = scheduledCall.userId
        ? `Submitted by ${getUserName(scheduledCall.userId)}`
        : 'Scheduled by the client via web form';

    return (
        <LeadEventRow
            headline="Call-Back Scheduled"
            iconName="clock outline"
            datetime={scheduledCall.createdAt}
            tooltip={tooltip}
            lines={[
                `Scheduled for ${formatDateTimeRange(scheduledCall.scheduledForFrom, scheduledCall.scheduledForTo)}`,
            ]}
        />
    );
};

const ScheduledCallResolvedRow = ({ scheduledCall, allUsers }) => {
    const { getUserName } = useAllUsers({ allUsers });
    const userName = getUserName(scheduledCall.userId);
    const iconColor = scheduledCall.status === 'done' ? 'rgb(0, 208, 0)' : 'rgb(255, 75, 20)';
    const overwrittenByClient = scheduledCall.status === 'overwritten-by-client';

    return (
        <LeadEventRow
            headline={`Call-Back: ${labelForLeadScheduledCallStatusValue(scheduledCall.status)}`}
            iconName="clock outline"
            iconColor={iconColor}
            datetime={scheduledCall.createdAt}
            tooltip={`Originally scheduled for ${formatDateTimeRange(scheduledCall.scheduledForFrom, scheduledCall.scheduledForTo)}`}
            lines={overwrittenByClient ? [] : [`Resolved by ${userName}`]}
        />
    );
};

const ScheduleCallRequestedRow = ({ scheduleCallRequestedEvent, allUsers }) => {
    const { getUserName } = useAllUsers({ allUsers });
    const userName = getUserName(scheduleCallRequestedEvent.userId);

    return (
        <LeadEventRow
            headline="Asked To Schedule A Call-Back Via SMS"
            iconName="envelope"
            datetime={scheduleCallRequestedEvent.createdAt}
            lines={[
                `Sent by ${userName}`,
            ]}
        />
    );
};

const LeadHistoricalEventRow = ({ historicalEvent, allUsers, opportunityModelDescription }) => {
    switch (historicalEvent.type) {
        case LEAD_EVENT_TYPES.CALL_LOG:
            return <CallLogRow callLog={historicalEvent} allUsers={allUsers} />;
        case LEAD_EVENT_TYPES.CONSULT_RECOMMENDATION_UPDATE:
            return <RecommendationUpdateRow recommendationUpdate={historicalEvent} allUsers={allUsers} />;
        case LEAD_EVENT_TYPES.TEXT_MESSAGE:
            return <TextMessageRow textMessage={historicalEvent} allUsers={allUsers} />;
        case LEAD_EVENT_TYPES.OPPORTUNITY_UPDATE:
            return <OpportunityUpdateRow fieldsUpdate={historicalEvent} allUsers={allUsers} opportunityModelDescription={opportunityModelDescription} />;
        case LEAD_EVENT_TYPES.CALL_SCHEDULED:
            return <ScheduledCallRow scheduledCall={historicalEvent} allUsers={allUsers} />;
        case LEAD_EVENT_TYPES.SCHEDULED_CALL_RESOLVED:
            return <ScheduledCallResolvedRow scheduledCall={historicalEvent} allUsers={allUsers} />;
        case LEAD_EVENT_TYPES.SCHEDULE_CALL_REQUESTED:
            return <ScheduleCallRequestedRow scheduleCallRequestedEvent={historicalEvent} allUsers={allUsers} />;
        default:
            return null;
    }
};

const LeadHistoricalEventsRow = ({ historicalEvents, allUsers, opportunityModelDescription }) => {
    const [showAll, setShowAll] = useState(false);
    const numberOfEvents = historicalEvents.length;
    const firstEvent = historicalEvents[0];
    const backgroundColor = '#f0f0f0';
    const borderRadius = 3;

    if (numberOfEvents === 0) return null;

    return (
        <div style={{ marginBottom: '0.25em' }}>
            <LeadHistoricalEventRow
                historicalEvent={firstEvent}
                allUsers={allUsers}
                opportunityModelDescription={opportunityModelDescription}
            />
            {numberOfEvents > 1 && (
                <button
                    onClick={() => setShowAll(!showAll)}
                    style={{
                        border: 'none',
                        padding: '1px 0',
                        backgroundColor,
                        cursor: 'pointer',
                        fontSize: '90%',
                        color: 'rgb(79, 79, 79)',
                        borderTopLeftRadius: borderRadius,
                        borderTopRightRadius: borderRadius,
                        borderBottomLeftRadius: showAll ? 0 : borderRadius,
                        borderBottomRightRadius: showAll ? 0 : borderRadius,
                        width: '100%',
                    }}
                >
                    {showAll ? (
                        <>
                            <i
                                className="caret down icon"
                                style={{ margin: 0 }}
                            />
                            Hide
                        </>
                    ) : (
                        <>
                            <i
                                className="caret right icon"
                                style={{ margin: 0 }}
                            />
                            Show {historicalEvents.length - 1} more
                        </>
                    )}
                </button>
            )}
            {showAll && (
                <div
                    style={{
                        backgroundColor,
                        padding: '2px 8px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.25em',
                        borderBottomLeftRadius: borderRadius,
                        borderBottomRightRadius: borderRadius,
                    }}
                >
                    {historicalEvents.slice(1).map((historicalEvent) => (
                        <LeadHistoricalEventRow
                            key={historicalEvent.id}
                            historicalEvent={historicalEvent}
                            allUsers={allUsers}
                            opportunityModelDescription={opportunityModelDescription}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default LeadHistoricalEventsRow;
