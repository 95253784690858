/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useMemo } from 'react';
var classNames = require('classnames');

const themeStyle = (theme) => {
    const themeInlineDefault = {
        border: 'none',
        padding: 3,
        backgroundColor: '#FFFFFF',
    }

    switch (theme) {
        case 'inline':
            return themeInlineDefault;
        case 'inline-warn':
            return {
                ...themeInlineDefault,
                backgroundColor: 'rgb(254, 255, 204)',
            };
        default:
            return {};
    }
}

function HtmlInput({ type, value, onChange, id, placeholder, readOnly, theme, stretchWide }) {
    let style = {};

    if (stretchWide) style.width = '100%';
    if (theme) style = { ...themeStyle(theme), ...style };

    return (
        <input
            type={type}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            id={id}
            name={id}
            placeholder={placeholder}
            readOnly={readOnly}
            style={style}
        />
    )
}

function HtmlTextArea({ type, value, onChange, id, placeholder, readOnly, theme, rows, stretchWide }) {
    let style = {};

    if (stretchWide) style.width = '100%';
    if (theme) style = { ...themeStyle(theme), ...style };

    return (
        <textarea
            type={type}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            id={id}
            name={id}
            placeholder={placeholder}
            readOnly={readOnly}
            style={style}
            rows={rows}
        />
    )
}

function HtmlSelect({ value, onChange, id, options, readOnly, theme, stretchWide }) {
    let style = {};

    if (stretchWide) style.width = '100%';
    if (theme) style = { ...themeStyle(theme), ...style };

    return (
        <select
            name={id}
            id={id}
            onChange={(event) => onChange(event.target.value)}
            value={value}
            disabled={readOnly}
            style={style}
        >
            {options.map((option) => (
                <option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                >
                    {option.label}
                </option>
            ))}
        </select>
    )
}

export function TextInput({ value, onChange, id, placeholder, lines, readOnly, theme, stretchWide }) {
    if (lines && lines > 1) {
        return (
            <HtmlTextArea
                type="text"
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                readOnly={readOnly}
                stretchWide={stretchWide}
                theme={theme}
                rows={lines}
            />
        );
    } else {
        return (
            <HtmlInput
                type="text"
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                readOnly={readOnly}
                stretchWide={stretchWide}
                theme={theme}
            />
        );
    }
}

export function PhoneInput({ value, onChange, id, placeholder, readOnly, theme, stretchWide }) {
    return (
        <HtmlInput
            type="tel"
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            stretchWide={stretchWide}
            theme={theme}
        />
    )
}

export function SelectField({ value, onChange, id, options, readOnly, theme, stretchWide, placeholder }) {
    const augmentedOptions = useMemo(() => {
        if (!placeholder) return options;

        return [
            { label: placeholder, value: '', disabled: true },
            ...options,
        ]
    }, [options, placeholder]);

    return (
        <HtmlSelect
            id={id}
            onChange={onChange}
            value={value === null ? '' : value}
            options={augmentedOptions}
            readOnly={readOnly}
            stretchWide={stretchWide}
            theme={theme}
        />
    )
}

export function DateTimeInput({ value, onChange, id, placeholder, readOnly, theme, stretchWide }) {
    return (
        <HtmlInput
            type="datetime-local"
            id={id}
            value={value == null ? '' : value}
            onChange={(newValue) => onChange(newValue === '' ? null : newValue)}
            placeholder={placeholder}
            readOnly={readOnly}
            stretchWide={stretchWide}
            theme={theme}
        />
    )
}

export function DateInput({ value, onChange, id, placeholder, readOnly, theme, stretchWide }) {
    return (
        <HtmlInput
            type="date"
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            stretchWide={stretchWide}
            theme={theme}
        />
    )
}

export function TimeInput({ value, onChange, id, placeholder, readOnly, theme, stretchWide }) {
    return (
        <HtmlInput
            type="time"
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            stretchWide={stretchWide}
            theme={theme}
        />
    )
}

function Label({ children, id }) {
    return (
        <label htmlFor={id}>
            {children}
        </label>
    )
}

function LabeledInput({ id, label, inline, children: inputField }) {
    return (
        <div className={classNames('field', { inline })}>
            <Label id={id}>{label}</Label>
            {inputField}
        </div>
    );
}

export function LabeledTextInput({ id, label, inline, ...inputParams }) {
    return (
        <LabeledInput id={id} label={label} inline={inline}>
            <TextInput {...inputParams} id={id} />
        </LabeledInput>
    );
}

export function LabeledPhoneInput({ id, label, inline, ...inputParams }) {
    return (
        <LabeledInput id={id} label={label} inline={inline}>
            <PhoneInput {...inputParams} id={id} />
        </LabeledInput>
    );
}

export function LabeledSelectField({ id, label, inline, ...selectFieldParams }) {
    return (
        <LabeledInput id={id} label={label} inline={inline}>
            <SelectField {...selectFieldParams} id={id} />
        </LabeledInput>
    );
}

export function FormSubmitButton({ label, disabled, loading }) {
    return (
        <button
            type="submit"
            disabled={disabled}
            className="ui primary icon button"
        >
            {loading ? <span className="ui active inline loader" /> : label}
        </button>
    );
}

export function Form({ children, onSubmit }) {
    return (
        <form className="ui form segment" onSubmit={onSubmit} style={{ marginTop: 10, marginBottom: 10 }}>
            {children}
        </form>
    );
}
