import React, { useMemo, useState } from 'react';
import useConversations from '../../common/useConversations.js';
import usePubSub from '../../common/usePubSub.js';
import { PUBSUB_TOPIC_CONVERSATIONS_MESSAGES_ADDED } from '../../common/webconstants.js';
import ConversationTitle from './conversationtitle.js';
import ConversationAvatar from './conversationavatar.js';
import LoadMoreButton from './loadmorebutton.js';
import Spinner from '../../components/spinner.js';
import Center from '../../components/center.js';
import { decorateConversation } from './decorators.js';
import { formatDateTimeHumanDateTime } from '../../common/formatting.js';
import { FaFile } from 'react-icons/fa';

const ConversationRow = ({ conversation, onClick }) => {
    const conversationActivityTime = conversation.latestActivityAt || conversation.createdAt;

    return (
        <div>
            <button
                onClick={() => (onClick && onClick(conversation.id))}
                style={{
                    width: '100%',
                    border: 'none',
                    borderRadius: 4,
                    padding: 10,
                    textAlign: 'left',
                    backgroundColor: conversation.participantSelf.unreadMessagesExist ? '#c9f2ff' : '#fbfbfb',
                    cursor: 'pointer',
                }}
            >
                <div style={{ display: 'flex', gap: 7 }}>
                    <div>
                        <ConversationAvatar conversation={conversation} />
                    </div>
                    <div style={{ flex: 1, minWidth: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 5 }}>
                        <div>
                            <ConversationTitle conversation={conversation} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: 8,
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: '#797979',
                                    fontSize: '90%',
                                    fontStyle: 'italic',
                                }}
                            >
                                {conversation.latestMessageText || (conversation.latestMessageDocumentId && <FaFile />) || ''}
                            </div>
                            <div
                                style={{
                                    color: '#a0a0a0',
                                    fontSize: 10,
                                }}
                            >
                                {conversationActivityTime && formatDateTimeHumanDateTime(conversationActivityTime)}
                            </div>
                        </div>
                    </div>
                </div>
            </button>
        </div>
    );
};

const Conversations = ({ conversations, onClickConversation }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {conversations.map((conversation) => (
                <ConversationRow
                    key={conversation.id}
                    conversation={conversation}
                    onClick={onClickConversation}
                />
            ))}
        </div>
    );
};

const ConversationsPage = ({ redirectToConversation, currentUser, conversationsFilters }) => {
    const conversationsBatchSize = 10;
    const [numberOfConversations, setNumberOfConversations] = useState(conversationsBatchSize);
    const {
        conversations,
        loading: loadingConversations,
        initialized: conversationsInitialized,
        reload: reloadConversations,
    } = useConversations({
        page: 1,
        pageSize: numberOfConversations,
        filters: conversationsFilters,
    });
    const decoratedConversations = useMemo(() => (
        conversations.map((conversation) => (
            decorateConversation(conversation, { currentUser })
        ))
    ), [conversations, currentUser]);
    const moreConversationsAvailable = conversations.length === numberOfConversations;

    usePubSub({
        topic: PUBSUB_TOPIC_CONVERSATIONS_MESSAGES_ADDED,
        messageHandler: (_topic, data) => {
            const loadedConversationIds = conversations.map((conversation) => conversation.id);
            const wasAddedToALoadedConversation = loadedConversationIds.includes(data.conversationId);

            if (wasAddedToALoadedConversation) reloadConversations();;
        },
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 10, overflowY: 'scroll', height: '100%' }}>
            {conversationsInitialized ? (
                <>
                    <Conversations
                        conversations={decoratedConversations}
                        onClickConversation={(conversationId) => redirectToConversation(conversationId)}
                    />
                    {moreConversationsAvailable && (
                        <LoadMoreButton
                            onClick={() => setNumberOfConversations(numberOfConversations + conversationsBatchSize)}
                            disabled={loadingConversations}
                        />
                    )}
                </>
            ) : (
                <Center>
                    <Spinner />
                </Center>
            )}
        </div>
    );
};

export default ConversationsPage;
