import React, { useState, useRef } from 'react';
import ValidationErrors from '../../common/validationerrors.js';
import useUserPictureUpdates from '../../common/useUserPictureUpdates.js';
import UserPicture from './userpicture.js';

const UserPictureForm = ({ user, onPictureUpdated }) => {
    const fileInput = useRef();
    const [newPicture, setNewPicture] = useState(null);
    const [newPictureSelected, setNewPictureSelected] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const {
        replaceCurrentPicture,
        deleteCurrentPicture,
        loading,
    } = useUserPictureUpdates({
        userId: user.user_id,
        onPictureUpdated,
    });

    const onFileChange = (event) => {
        setValidationErrors([]);
        setNewPicture(null);

        if (!event.currentTarget.files || !event.currentTarget.files[0]) return;

        setNewPictureSelected(true);

        const newPictureFile = event.currentTarget.files[0];

        if (newPictureFile.size > 2097152) {
            setValidationErrors([{
                code: 'FILE_TOO_BIG',
                description: 'Please choose a file with no more than 2 MB in size',
            }]);

            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(newPictureFile);
        reader.onload = function () {
            setNewPicture({
                original: reader.result,
                name: newPictureFile.name,
                type: newPictureFile.type,
                base64: reader.result.split(',')[1],
            });
        };
    };

    const onClickDelete = () => {
        if (!user.picture_url) return;

        const isConfirmed = window.confirm(
            `Do you want to delete the current picture? This action can not be undone.`
        );

        if (isConfirmed) deleteCurrentPicture();
    };

    const onClickUpload = () => replaceCurrentPicture(newPicture);

    const discardNewPicture = () => {
        setNewPicture(null);
        fileInput.current.value = '';
        setNewPictureSelected(false);
        setValidationErrors([]);
    };

    return (
        <div
            style={{
                display: 'flex',
                gap: 25,
            }}
        >
            <div>
                <div>
                    <UserPicture
                        pictureSrc={(newPicture && newPicture.original) || user.picture_url}
                        name={user.name}
                        size={100}
                    />
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 5,
                    }}
                >
                    <button
                        onClick={onClickDelete}
                        disabled={loading || newPictureSelected || !user.picture_url}
                        className="ui button mini red"
                    >
                        Delete
                    </button>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    overflow: 'auto',
                }}
            >
                <div>
                    Choose a new picture
                </div>
                <div
                    style={{
                        fontSize: 12,
                        color: '#6a6a6a',
                    }}
                >
                    png, jpeg, max. 2MB
                </div>
                <div>
                    <ValidationErrors errors={validationErrors} />
                </div>
                <div
                    style={{
                        fontSize: 12,
                        color: '#6a6a6a',
                        marginTop: 5,
                        marginBottom: 10,
                    }}
                >
                    <input
                        type="file"
                        accept="image/png,image/jpeg"
                        onChange={onFileChange}
                        ref={fileInput}
                    />
                </div>
                <div>
                    <button
                        disabled={loading || !newPicture}
                        className="ui button mini blue"
                        onClick={onClickUpload}
                    >
                        Upload
                    </button>
                    <button
                        disabled={loading || !newPictureSelected}
                        className="ui button mini"
                        onClick={discardNewPicture}
                    >
                        Discard
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserPictureForm;
