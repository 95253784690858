import 'whatwg-fetch'; // for 'fetch' support on Internet Explorer

// Internet Explorer doesn't have startsWith, and we need it on the callform page for Ruby Receptionist...
if (!String.prototype.startsWith) {
    // eslint-disable-next-line
	String.prototype.startsWith = function(searchString, position) {
		position = position || 0;
		return this.substr(position, searchString.length) === searchString;
	};
}


if (!Array.prototype.find) {
    // eslint-disable-next-line
    Array.prototype.find = function(predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.find called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;
        
        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
}