import { useState, useEffect, useCallback } from 'react';
import API from '../common/api.js';

const useDynamicContentForTopic = ({ dynamicContentTopicReadableId }) => {
    const [dynamicContentBlocks, setDynamicContentBlocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [initialized, setInitialized] = useState(false);

    const loadData = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        let dynamicContentBlocksIfSuccess = await API.getDynamicContentForTopic({ dynamicContentTopicReadableId });

        if (dynamicContentBlocksIfSuccess) {
            setDynamicContentBlocks(dynamicContentBlocksIfSuccess);
            setInitialized(true);
        } else {
            setErrorMessage('Failed to load dynamic content for topic from server. Please retry.');
        }

        setLoading(false);
    }, [dynamicContentTopicReadableId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        dynamicContentBlocks,
        loading,
        errorMessage,
        initialized,
        reload: loadData,
    };
};

export default useDynamicContentForTopic;
