import { useState, useCallback, useEffect } from "react";
import API from './api.js';

const useNumberOfUnreadMessages = () => {
    const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const loadData = useCallback(async () => {
        setLoading(true);

        const response = await API.getNumberOfUnreadMessages();

        if (!isNaN(response)) {
            setNumberOfUnreadMessages(response);
        }

        setInitialized(true);
        setLoading(false);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        numberOfUnreadMessages,
        loading,
        initialized,
        reload: loadData,
    };
};

export default useNumberOfUnreadMessages;
