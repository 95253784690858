import { useEffect, useCallback, useState } from 'react';
import API from './api.js';

const useConversations = ({ page, pageSize, filters={} }) => {
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const loadData = useCallback(async () => {
        setLoading(true);

        const selectedFilters = {};
        if (typeof filters.archived === 'boolean') {
            selectedFilters.archived = filters.archived;
        }
        if (filters.caseNumberEq) {
            selectedFilters.case_number_eq = filters.caseNumberEq;
        }
        const response = await API.getConversations({ page, pageSize, filters: selectedFilters });

        setConversations(response || []);

        setInitialized(true);
        setLoading(false);
    }, [page, pageSize, filters]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        conversations,
        loading,
        initialized,
        reload: loadData,
    };
};

export default useConversations;
