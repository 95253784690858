import { useState, useEffect } from 'react';
import API from '../common/api.js';

function usePinnedLeads() {
    const [pinnedLeads, setPinnedLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            setErrorMessage(null);

            const leadsResponse = await API.getPinnedLeads()
            if (leadsResponse.leads) {
                setPinnedLeads(leadsResponse.leads);
            } else {
                setErrorMessage("Failed to load pinned leads. Please retry.");
            }

            setLoading(false);
        }

        loadData()
    }, []);

    const pinLead = async (lead, onSuccess) => {
        setErrorMessage(null);

        if (await API.pinLead(lead.id)) {
            setPinnedLeads([...pinnedLeads, lead])
            if (onSuccess) onSuccess()
        } else {
            setErrorMessage("Failed to pin lead. Please retry.");
        }
    }

    const unpinLead = async (lead, onSuccess) => {
        setErrorMessage(null);

        if (await API.unpinLead(lead.id)) {
            setPinnedLeads(pinnedLeads.filter((pinnedLead) => pinnedLead.id !== lead.id))
            if (onSuccess) onSuccess()
        } else {
            setErrorMessage("Failed to pin lead. Please retry.");
        }
    }

    const leadIsPinned = (leadId) => pinnedLeads.find((pinnedLead) => pinnedLead.id === leadId) !== undefined

    const togglePinLead = (lead, onSuccess) => {
        if (leadIsPinned(lead.id)) {
            unpinLead(lead, () => {
                if (onSuccess) onSuccess(false)
            })
        } else {
            pinLead(lead, () => {
                if (onSuccess) onSuccess(true)
            })
        }
    }

    return {
        pinnedLeads,
        loadingPinnedLeads: loading,
        pinnedLeadsErrorMessage: errorMessage,
        pinLead,
        unpinLead,
        togglePinLead,
        leadIsPinned,
    }
}

export default usePinnedLeads;
