import React, { useState, useMemo, useEffect } from "react";
import {uuidv4} from '../common/uuid.js';
import useInterval from '../common/useInterval.js';


//
// Toasts are little notification messages that can be popped up to give users feedback without getting in their way
//

function Toasts({ onMount }) {
    const [toasts, setToasts] = useState([]);

    function addToast(msg) {
        setToasts((toasts) => [...toasts, { msg, key: uuidv4(), elapsedTime: 120 }]);
    }

    const toastIF = useMemo(() => ({ addToast }), []);

    useInterval(
        () => {
            const newToasts = toasts
                .map((t) => ({ ...t, elapsedTime: t.elapsedTime - 1 }))
                .filter((t) => t.elapsedTime >= 0);
            setToasts(newToasts);
        },
        toasts.length > 0 ? 100 : null
    );

    useEffect(() => {
        onMount(toastIF);
    });

    const toastEls = toasts.map((toast) => {
        const barStyle = { minWidth: 0, width: `${(toast.elapsedTime * 100) / 120}%` };
        return (
            <React.Fragment key={toast.key}>
                <div className="ui success message" style={{ marginBottom: 0, borderRadius: 0 }}>
                    {toast.msg}
                </div>
                <div className="ui bottom attached progress">
                    <div className="bar" style={barStyle}></div>
                </div>
            </React.Fragment>
        );
    });
    return (
        <div
            style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                top: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                padding: 10,
                pointerEvents: "none",
            }}
        >
            {toastEls}
        </div>
    );
}

export default Toasts;
