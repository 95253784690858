import React, { useEffect, useMemo, useState } from 'react';
import { titleForNode } from './utils.js';
import { FaChevronLeft, FaXmark, FaTrash } from 'react-icons/fa6';
import {
    INTERVIEW_NODE_TYPES,
    CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS,
} from '../../../common/webconstants.js';

const MenuFormContainer = ({ children }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {children}
        </div>
    );
};
const MenuFormHeadline = ({ children }) => <h4>{children}</h4>;
const MenuFormLabel = ({ children }) => children;
const MenuFormInput = ({ value, onChange }) => (
    <div className="ui fluid input">
        <input
            value={value}
            onChange={(event) => onChange(event.currentTarget.value)}
        />
    </div>
);
const MenuFormButton = ({ onClick, children }) => (
    <button onClick={onClick} className="ui grey button" style={{ width: '100%' }}>
        {children}
    </button>
);
const MenuFormLabeledContainer = ({ label, children }) => (
    <div>
        <div style={{ fontSize: 13, marginBottom: 3 }}>
            <MenuFormLabel>{label}</MenuFormLabel>
        </div>
        <div>
            {children}
        </div>
    </div>
);

const ChoiceQuestionOptionEditor = ({
    choiceQuestionOption,
    updateChoiceQuestionOption,
    removeChoiceQuestionOption,
}) => {
    const [title, setTitle] = useState(choiceQuestionOption.title);

    useEffect(() => {
        updateChoiceQuestionOption(choiceQuestionOption.id, { title });
    }, [title, choiceQuestionOption.id, updateChoiceQuestionOption]);

    return (
        <div style={{ display: 'flex', gap: 5 }}>
            <div style={{ flex: 1 }}>
                <MenuFormInput
                    value={title}
                    onChange={setTitle}
                />
            </div>
            <div>
                <MenuFormButton
                    onClick={() => removeChoiceQuestionOption(choiceQuestionOption.id)}
                >
                    <FaTrash />
                </MenuFormButton>
            </div>
        </div>
    );
};

const ChoiceQuestionNodeEditor = ({
    node,
    updateChoiceQuestionOption,
    removeChoiceQuestionOption,
    getChoiceQuestionOptionsForQuestion,
    getMultipleChoiceQuestionNodeById,
    getSingleChoiceQuestionNodeById,
    updateQuestion,
    addChoiceQuestionOption,
}) => {
    const question = useMemo(() => {
        switch (node.nodeType) {
            case 'MultipleChoiceQuestion':
                return getMultipleChoiceQuestionNodeById(node.nodeId);
            case 'SingleChoiceQuestion':
                return getSingleChoiceQuestionNodeById(node.nodeId);
            default:
                console.error('ChoiceQuestionNodeEditor: can not find question for node', node.nodeType, node.nodeId);
        }
    }, [getMultipleChoiceQuestionNodeById, getSingleChoiceQuestionNodeById, node.nodeId, node.nodeType]);
    const [title, setTitle] = useState(question.title);
    const choiceQuestionOptions = useMemo(() => {
        return getChoiceQuestionOptionsForQuestion({
            questionType: node.nodeType,
            questionId: node.nodeId,
        });
    }, [getChoiceQuestionOptionsForQuestion, node.nodeId, node.nodeType]);

    useEffect(() => {
        updateQuestion({
            questionType: node.nodeType,
            questionId: node.nodeId,
        }, { title });
    }, [title, node.nodeType, node.nodeId, updateQuestion]);

    const addOption = () => {
        if (choiceQuestionOptions.length > 0 && choiceQuestionOptions[choiceQuestionOptions.length - 1].title.trim() === '') {
            return;
        }

        addChoiceQuestionOption({
            choiceQuestionType: node.nodeType,
            choiceQuestionId: node.nodeId,
            title: '',
        });
    };

    return (
        <MenuFormContainer>
            <div>
                <MenuFormHeadline>Edit {labelForInterviewNodeType(node.nodeType)}</MenuFormHeadline>
            </div>
            <div>
                <MenuFormLabeledContainer
                    label="Question:"
                >
                    <MenuFormInput
                        value={title}
                        onChange={setTitle}
                    />
                </MenuFormLabeledContainer>
            </div>
            <div>
                <MenuFormLabeledContainer
                    label="Options:"
                >
                    {choiceQuestionOptions.map((choiceQuestionOption) => (
                        <div
                            key={choiceQuestionOption.id}
                            style={{ marginTop: 5 }}
                        >
                            <ChoiceQuestionOptionEditor
                                choiceQuestionOption={choiceQuestionOption}
                                updateChoiceQuestionOption={updateChoiceQuestionOption}
                                removeChoiceQuestionOption={removeChoiceQuestionOption}
                            />
                        </div>
                    ))}
                </MenuFormLabeledContainer>
            </div>
            <div>
                <MenuFormButton onClick={addOption}>
                    Add New Option
                </MenuFormButton>
            </div>
        </MenuFormContainer>
    );
};

const labelForOperator = (operator) => {
    switch (operator) {
        case CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS.AND:
            return 'and';
        case CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS.OR:
            return 'or';
        default:
            return operator;
    }
};

const labelForInterviewNodeType = (nodeType) => {
    switch (nodeType) {
        case INTERVIEW_NODE_TYPES.CONDITIONAL:
            return 'Conditional';
        case INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION:
            return 'Single Choice Question';
        case INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION:
            return 'Multiple Choice Question';
        case INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST:
            return 'Request Document';
        default:
            return nodeType;
    }
};

const Cqo = ({ cqoId, getChoiceQuestionInfo, removeCondition }) => {
    const { option, question } = useMemo(() => getChoiceQuestionInfo(cqoId), [cqoId, getChoiceQuestionInfo]);

    return (
        <div style={{ fontSize: 12, display: 'flex' }}>
            <div style={{ flex: 1 }}>
                <div style={{ color: 'rgb(98, 98, 98)' }}>
                    {question.title}
                </div>
                <div style={{ fontStyle: 'italic' }}>
                    {option.title}
                </div>
            </div>
            <div>
                {removeCondition && (
                    <div>
                        <MenuFormButton onClick={removeCondition}>
                            <FaTrash />
                        </MenuFormButton>
                    </div>
                )}
            </div>
        </div>
    );
};

const ConditionalNodeEditor = ({
    node,
    getChoiceQuestionInfo,
    getChoiceQuestionOptionsForQuestion,
    multipleChoiceQuestionNodes,
    singleChoiceQuestionNodes,
    updateConditionalNode,
    getConditionalNodeById,
    updateNode,
}) => {
    const conditionalNode = getConditionalNodeById(node.nodeId);
    const [cqoIds, setCqoIds] = useState(conditionalNode.condition.cqoIds);
    const [operator, setOperator] = useState(conditionalNode.condition.operator);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [label, setLabel] = useState(node.label || '');
    const [selectedOption, setSelectedOption] = useState('');
    const removeCondition = (cqoId) => {
        const index = cqoIds.indexOf(cqoId);
        const newCqoIds = [...cqoIds];
        newCqoIds.splice(index, 1);
        setCqoIds(newCqoIds);
    };
    const questions = useMemo(() => {
        return [
            ...singleChoiceQuestionNodes.map((question) => ({ ...question, virtualId: `SingleChoiceQuestion-${question.id}` })),
            ...multipleChoiceQuestionNodes.map((question) => ({ ...question, virtualId: `MultipleChoiceQuestion-${question.id}` })),
        ];
    }, [multipleChoiceQuestionNodes, singleChoiceQuestionNodes]);
    const optionsForSelectedQuestion = useMemo(() => {
        if (selectedQuestion === '') return [];

        const [questionType, questionId] = selectedQuestion.split('-');

        return getChoiceQuestionOptionsForQuestion({
            questionType,
            questionId: parseInt(questionId),
        })
            .filter((option) => !cqoIds.includes(option.id));
    }, [selectedQuestion, cqoIds, getChoiceQuestionOptionsForQuestion]);
    useEffect(() => {
        if (optionsForSelectedQuestion.length === 0) {
            setSelectedOption('');
        } else {
            setSelectedOption(optionsForSelectedQuestion[0].id.toString());
        }
    }, [optionsForSelectedQuestion]);
    const onClickAddCondition = () => {
        if (selectedOption === '') return;

        const optionId = parseInt(selectedOption);

        if (cqoIds.includes(optionId)) return;

        const newCqoIds = [...cqoIds, optionId];

        setCqoIds(newCqoIds);
        setSelectedQuestion('');
    };
    useEffect(() => {
        updateConditionalNode(conditionalNode.id, { cqoIds, operator });
    }, [conditionalNode.id, cqoIds, operator, updateConditionalNode]);
    useEffect(() => {
        updateNode(node.id, { label });
    }, [node.id, label, updateNode]);

    return (
        <MenuFormContainer>
            <MenuFormLabeledContainer
                label="Label:"
            >
                <MenuFormInput
                    value={label}
                    onChange={setLabel}
                />
            </MenuFormLabeledContainer>
            <div>
                {cqoIds.map((cqoId, index) => (
                    <div key={cqoId}>
                        <Cqo
                            cqoId={cqoId}
                            getChoiceQuestionInfo={getChoiceQuestionInfo}
                            removeCondition={() => removeCondition(cqoId)}
                        />
                        {(index < (cqoIds.length - 1)) && (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <select value={operator} onChange={(event) => setOperator(event.currentTarget.value)}>
                                    <option value={CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS.AND}>
                                        {labelForOperator(CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS.AND)}
                                    </option>
                                    <option value={CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS.OR}>
                                    {labelForOperator(CONDITIONAL_INTERVIEW_NODE_OPERATOR_OPTIONS.OR)}
                                    </option>
                                </select>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div>
                <MenuFormHeadline>Add Condition</MenuFormHeadline>
            </div>
            <div>
                <MenuFormLabeledContainer label="Question:">
                    <select
                        value={selectedQuestion}
                        onChange={(event) => setSelectedQuestion(event.currentTarget.value)}
                        style={{ width: '100%' }}
                    >
                        <option value=""></option>
                        {questions.map((question) => (
                            <option key={question.virtualId} value={question.virtualId}>
                                {question.title}
                            </option>
                        ))}
                    </select>
                </MenuFormLabeledContainer>
            </div>
            <div>
                {optionsForSelectedQuestion.length > 0 && (
                    <>
                        <MenuFormLabeledContainer label="Answer:">
                            <select
                                value={selectedOption}
                                onChange={(event) => setSelectedOption(event.currentTarget.value)}
                                style={{ width: '100%' }}
                            >
                                {optionsForSelectedQuestion.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.title}
                                    </option>
                                ))}
                            </select>
                        </MenuFormLabeledContainer>
                        <div style={{ marginTop: 20 }}>
                            <MenuFormButton onClick={onClickAddCondition}>
                                Add condition
                            </MenuFormButton>
                        </div>
                    </>
                )}
            </div>
        </MenuFormContainer>
    );
};

const NodeEditor = ({
    node,
    getChoiceQuestionInfo,
    getChoiceQuestionOptionsForQuestion,
    multipleChoiceQuestionNodes,
    singleChoiceQuestionNodes,
    updateConditionalNode,
    updateChoiceQuestionOption,
    removeChoiceQuestionOption,
    getMultipleChoiceQuestionNodeById,
    getSingleChoiceQuestionNodeById,
    updateQuestion,
    getConditionalNodeById,
    addChoiceQuestionOption,
    updateNode,
}) => {
    switch (node.nodeType) {
        case INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION:
        case INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION:
            return (
                <ChoiceQuestionNodeEditor
                    node={node}
                    updateChoiceQuestionOption={updateChoiceQuestionOption}
                    removeChoiceQuestionOption={removeChoiceQuestionOption}
                    getChoiceQuestionOptionsForQuestion={getChoiceQuestionOptionsForQuestion}
                    getMultipleChoiceQuestionNodeById={getMultipleChoiceQuestionNodeById}
                    getSingleChoiceQuestionNodeById={getSingleChoiceQuestionNodeById}
                    updateQuestion={updateQuestion}
                    addChoiceQuestionOption={addChoiceQuestionOption}
                />
            );
        case INTERVIEW_NODE_TYPES.CONDITIONAL:
            return (
                <ConditionalNodeEditor
                    node={node}
                    getChoiceQuestionInfo={getChoiceQuestionInfo}
                    getChoiceQuestionOptionsForQuestion={getChoiceQuestionOptionsForQuestion}
                    multipleChoiceQuestionNodes={multipleChoiceQuestionNodes}
                    singleChoiceQuestionNodes={singleChoiceQuestionNodes}
                    updateConditionalNode={updateConditionalNode}
                    getConditionalNodeById={getConditionalNodeById}
                    updateNode={updateNode}
                />
            );
        default:
            return null;
    }
};

const getQuestionNodeById = ({
    nodeType,
    nodeId,
    getMultipleChoiceQuestionNodeById,
    getSingleChoiceQuestionNodeById,
}) => {
    switch (nodeType) {
        case INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION:
            return getMultipleChoiceQuestionNodeById(nodeId);
        case INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION:
            return getSingleChoiceQuestionNodeById(nodeId);
        default:
            console.error('getQuestionNodeById: unknown node type', nodeType, nodeId);
    }
};

const NodeHeadline = ({ children }) => (
    <div style={{ fontSize: 16, color: 'black' }}>
        {children}
    </div>
);

const NodeSubHeadline = ({ children }) => (
    <div style={{ fontSize: 11, color: '#777' }}>
        {children}
    </div>
);

const ChoiceQuestionNode = ({
    node,
    getMultipleChoiceQuestionNodeById,
    getSingleChoiceQuestionNodeById,
    getChoiceQuestionOptionsForQuestion,
}) => {
    const questionNode = getQuestionNodeById({
        nodeType: node.nodeType,
        nodeId: node.nodeId,
        getMultipleChoiceQuestionNodeById,
        getSingleChoiceQuestionNodeById,
    });
    const options = useMemo(() => {
        return getChoiceQuestionOptionsForQuestion({ questionType: node.nodeType, questionId: node.nodeId });
    }, [node.nodeType, node.nodeId, getChoiceQuestionOptionsForQuestion]);

    return (
        <div>
            <NodeSubHeadline>
                {labelForInterviewNodeType(node.nodeType)}
            </NodeSubHeadline>
            <NodeHeadline>
                {questionNode.title}
            </NodeHeadline>
            <ol>
                {options.map((option) => (
                    <li key={option.id}>
                        {option.title}
                    </li>
                ))}
            </ol>
        </div>
    );
};

const CreateDocumentRequestNode = ({ node, getCreateDocumentRequestNodeById, getDocumentTypeById }) => {
    const createDocumentRequestNode = getCreateDocumentRequestNodeById(node.nodeId);
    const documentType = getDocumentTypeById(createDocumentRequestNode.documentTypeId);

    return (
        <div>
            <NodeSubHeadline>
                Request Document:
            </NodeSubHeadline>
            <NodeHeadline>
                {documentType.name}
            </NodeHeadline>
        </div>
    )
};

const ConditionalNode = ({ node, getConditionalNodeById, getChoiceQuestionInfo }) => {
    const conditionalNode = getConditionalNodeById(node.nodeId);
    const { operator, cqoIds } = conditionalNode.condition;

    return (
        <div>
            <NodeHeadline>
                {node.label || 'Conditional'}
            </NodeHeadline>
            <div style={{ marginTop: 10 }}>
                {cqoIds.map((cqoId, index) => (
                    <div key={cqoId}>
                        <Cqo
                            cqoId={cqoId}
                            getChoiceQuestionInfo={getChoiceQuestionInfo}
                        />
                        <div style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>
                            {index < cqoIds.length - 1 ? labelForOperator(operator) : null}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const Node = ({
    node,
    getCreateDocumentRequestNodeById,
    getDocumentTypeById,
    getMultipleChoiceQuestionNodeById,
    getSingleChoiceQuestionNodeById,
    getChoiceQuestionOptionsForQuestion,
    getConditionalNodeById,
    getChoiceQuestionInfo,
}) => {
    switch (node.nodeType) {
        case INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION:
        case INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION:
            return (
                <ChoiceQuestionNode
                    node={node}
                    getMultipleChoiceQuestionNodeById={getMultipleChoiceQuestionNodeById}
                    getSingleChoiceQuestionNodeById={getSingleChoiceQuestionNodeById}
                    getChoiceQuestionOptionsForQuestion={getChoiceQuestionOptionsForQuestion}
                />
            )
        case INTERVIEW_NODE_TYPES.CONDITIONAL:
            return (
                <ConditionalNode
                    node={node}
                    getConditionalNodeById={getConditionalNodeById}
                    getChoiceQuestionInfo={getChoiceQuestionInfo}
                />
            );
        case INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST:
            return (
                <CreateDocumentRequestNode
                    node={node}
                    getCreateDocumentRequestNodeById={getCreateDocumentRequestNodeById}
                    getDocumentTypeById={getDocumentTypeById}
                />
            );
        default:
            return null;
    }
};

const DocumentTypeForm = ({ addDocumentType, onDocumentTypeAdded, documentTypes }) => {
    const [nameInput, setNameInput] = useState('');
    const onClickCreate = () => {
        const name = nameInput.trim();

        if (name === '') return;
        if (documentTypes.map((documentType) => documentType.name).includes(name)) return;

        addDocumentType({ name });

        onDocumentTypeAdded();
    };

    return (
        <MenuFormContainer>
            <div>
                <MenuFormHeadline>New Document Type</MenuFormHeadline>
            </div>
            <div>
                <MenuFormLabeledContainer
                    label="Choose a name for the new Document Type:"
                >
                    <MenuFormInput
                        value={nameInput}
                        onChange={setNameInput}
                    />
                </MenuFormLabeledContainer>
            </div>
            <div>
                <MenuFormButton onClick={onClickCreate}>
                    Create Document Type
                </MenuFormButton>
            </div>
        </MenuFormContainer>
    );
};

const NewCreateDocumentRequestNodeForm = ({ sourceNodeId, sourceNodeIdField, targetNodeId, insertNodeOnEdge, documentTypes, onClose }) => {
    const [documentTypeIdInput, setDocumentTypeIdInput] = useState(documentTypes.length > 0 ? documentTypes[0].id : '');

    const onClickSubmit = () => {
        insertNodeOnEdge(
            { sourceNodeId, sourceNodeIdField, targetNodeId },
            {
                nodeType: INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST,
                params: {
                    documentTypeId: parseInt(documentTypeIdInput),
                },
                onNodeInserted: onClose,
            },
        );

        onClose();
    };

    return (
        <MenuFormContainer>
            <div>
                <MenuFormHeadline>New Request-Document-Node</MenuFormHeadline>
            </div>
            <div>
                <MenuFormLabeledContainer
                    label="Select a Document Type:"
                >
                    <select
                        value={documentTypeIdInput}
                        onChange={(event) => setDocumentTypeIdInput(event.currentTarget.value)}
                    >
                        {documentTypes.map((documentType) => (
                            <option key={documentType.id} value={documentType.id}>
                                {documentType.name}
                            </option>
                        ))}
                    </select>
                </MenuFormLabeledContainer>
            </div>
            <div>
                <MenuFormButton onClick={onClickSubmit}>
                    Insert Node
                </MenuFormButton>
            </div>
        </MenuFormContainer>
    )
};

const TargetNodeSelect = ({
    sourceNodeId,
    sourceNodeIdField,
    targetNodeId,
    setEndNodeForEdge,
    nodes,
    getSingleChoiceQuestionNodeById,
    getMultipleChoiceQuestionNodeById,
    getCreateDocumentRequestNodeById,
    getDocumentTypeById,
    onClose,
}) => {
    const [nodeIdInput, setNodeIdInput] = useState(targetNodeId ? targetNodeId.toString() : '');

    useEffect(() => {
        const newTargetNodeId = nodeIdInput === '' ? null : parseInt(nodeIdInput);

        if (newTargetNodeId === targetNodeId) return;

        setEndNodeForEdge({
            sourceNodeId,
            sourceNodeIdField,
            targetNodeId: newTargetNodeId,
            onEdgeSet: onClose,
        });
    }, [nodeIdInput, onClose, setEndNodeForEdge, sourceNodeId, sourceNodeIdField, targetNodeId]);

    return (
        <MenuFormContainer>
            <div>
                <MenuFormHeadline>Change Target Node</MenuFormHeadline>
            </div>
            <div>
                <MenuFormLabeledContainer
                    label="Select new target node:"
                >
                    <select
                        value={nodeIdInput}
                        onChange={(event) => setNodeIdInput(event.currentTarget.value)}
                    >
                        <option value="">
                            End
                        </option>
                        {nodes.map((node) => (
                            <option key={node.id} value={node.id.toString()}>
                                {titleForNode({
                                    node,
                                    getSingleChoiceQuestionNodeById,
                                    getMultipleChoiceQuestionNodeById,
                                    getCreateDocumentRequestNodeById,
                                    getDocumentTypeById,
                                })}
                            </option>
                        ))}
                    </select>
                </MenuFormLabeledContainer>
            </div>
        </MenuFormContainer>
    );
};

const MenuContainer = ({ children }) => {
    return (
        <div
            style={{
                width: 250,
                backgroundColor: '#e6e6e6',
            }}
        >
            {children}
        </div>
    );
};

const Button = ({ onClick, children }) => {
    return (
        <button
            onClick={onClick}
            style={{
                width: '100%',
                padding: 5,
                borderRadius: 0,
                cursor: 'pointer',
                borderWidth: 1,
            }}
        >
            {children}
        </button>
    );
};

const BackButton = ({ popPageStack }) => {
    return (
        <Button onClick={popPageStack}>
            <FaChevronLeft /> Back
        </Button>
    );
};

const CloseButton = ({ onClose }) => {
    return (
        <Button onClick={onClose}>
            <FaXmark /> Close
        </Button>
    );
};

const ButtonContainer = ({ children }) => {
    return (
        <div>
            {children}
        </div>
    );
};

const Section = ({ children }) => {
    return (
        <div
            style={{
                padding: 10,
            }}
        >
            {children}
        </div>
    );
};

const ButtonGroup = ({ children, name }) => {
    return (
        <div style={{ paddingTop: 5 }}>
            <div style={{
                padding: 4,
                color: 'rgb(168, 168, 168)',
                fontSize: 12,
                fontStyle: 'italic',
                fontWeight: 'bold',
            }}>
                {name}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

export const NodeMenu = ({
    graphNodeId,
    getNodeById,
    deleteNode,
    getChoiceQuestionInfo,
    getChoiceQuestionOptionsForQuestion,
    multipleChoiceQuestionNodes,
    singleChoiceQuestionNodes,
    updateConditionalNode,
    updateChoiceQuestionOption,
    removeChoiceQuestionOption,
    getMultipleChoiceQuestionNodeById,
    getSingleChoiceQuestionNodeById,
    updateQuestion,
    getConditionalNodeById,
    addChoiceQuestionOption,
    documentTypes,
    addDocumentType,
    getCreateDocumentRequestNodeById,
    getDocumentTypeById,
    addCreateDocumentRequestNode,
    onClose,
    updateNode,
}) => {
    const [pageStack, setPageStack] = useState([]);
    const node = useMemo(() => {
        if (!graphNodeId.startsWith('node-')) return null;

        const nodeId = parseInt(graphNodeId.split('-')[1]);

        return getNodeById(nodeId);
    }, [graphNodeId, getNodeById]);
    const pushToPageStack = (page) => {
        setPageStack([...pageStack, page]);
    };
    const popPageStack = () => {
        setPageStack([...pageStack.slice(0, -1)]);
    };

    const onClickDelete = () => {
        if (node.nodeType === INTERVIEW_NODE_TYPES.CONDITIONAL) {
            pushToPageStack('delete-conditional-node');
        } else {
            confirmDeleteNode(node.id, { onDeletionComplete: onClose });
        }
    };

    const confirmDeleteNode = (nodeId, options) => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this node?'
        );

        if (confirmed) {
            deleteNode(nodeId, options);
        }
    };

    useEffect(() => {
        setPageStack([]);
    }, [graphNodeId])

    const renderOptions = () => {
        switch (pageStack[pageStack.length - 1]) {
            case 'edit-node':
                return (
                    <>
                        <ButtonContainer>
                            <BackButton popPageStack={popPageStack} />
                        </ButtonContainer>
                        <Section>
                            <NodeEditor
                                node={node}
                                getChoiceQuestionInfo={getChoiceQuestionInfo}
                                getChoiceQuestionOptionsForQuestion={getChoiceQuestionOptionsForQuestion}
                                multipleChoiceQuestionNodes={multipleChoiceQuestionNodes}
                                singleChoiceQuestionNodes={singleChoiceQuestionNodes}
                                updateConditionalNode={updateConditionalNode}
                                updateChoiceQuestionOption={updateChoiceQuestionOption}
                                removeChoiceQuestionOption={removeChoiceQuestionOption}
                                getMultipleChoiceQuestionNodeById={getMultipleChoiceQuestionNodeById}
                                getSingleChoiceQuestionNodeById={getSingleChoiceQuestionNodeById}
                                updateQuestion={updateQuestion}
                                getConditionalNodeById={getConditionalNodeById}
                                addChoiceQuestionOption={addChoiceQuestionOption}
                                documentTypes={documentTypes}
                                addDocumentType={addDocumentType}
                                getCreateDocumentRequestNodeById={getCreateDocumentRequestNodeById}
                                getDocumentTypeById={getDocumentTypeById}
                                addCreateDocumentRequestNode={addCreateDocumentRequestNode}
                                updateNode={updateNode}
                            />
                        </Section>
                    </>
                );
            case 'delete-conditional-node':
                return (
                    <ButtonContainer>
                        <BackButton popPageStack={popPageStack} />

                        <ButtonGroup name="Options">
                            <Button onClick={() => confirmDeleteNode(node.id, { keepConditionMetPath: true, onDeletionComplete: onClose })}>
                                Keep "True" path
                            </Button>
                            <Button onClick={() => confirmDeleteNode(node.id, { keepConditionMetPath: false, onDeletionComplete: onClose })}>
                                Keep "False" path
                            </Button>
                        </ButtonGroup>
                    </ButtonContainer>
                );
            default:
                return (
                    <>
                        <ButtonContainer>
                            <CloseButton onClose={onClose} />
                        </ButtonContainer>
                        {node && (
                            <Section>
                                <Node
                                    node={node}
                                    getCreateDocumentRequestNodeById={getCreateDocumentRequestNodeById}
                                    getDocumentTypeById={getDocumentTypeById}
                                    getMultipleChoiceQuestionNodeById={getMultipleChoiceQuestionNodeById}
                                    getSingleChoiceQuestionNodeById={getSingleChoiceQuestionNodeById}
                                    getChoiceQuestionOptionsForQuestion={getChoiceQuestionOptionsForQuestion}
                                    getConditionalNodeById={getConditionalNodeById}
                                    getChoiceQuestionInfo={getChoiceQuestionInfo}
                                />
                            </Section>
                        )}
                        <ButtonContainer>
                            <ButtonGroup name="Actions">
                                {(node && node.nodeType !== INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST) && (
                                    <Button onClick={() => pushToPageStack('edit-node')}>
                                        Edit
                                    </Button>
                                )}
                                {node && (
                                    <Button onClick={onClickDelete}>
                                        Delete
                                    </Button>
                                )}
                            </ButtonGroup>
                        </ButtonContainer>
                    </>
                );
        }
    };

    return (
        <MenuContainer>
            {renderOptions()}
        </MenuContainer>
    );
};

export const EdgeMenu = ({
    graphEdgeId,
    insertNodeOnEdge,
    setEndNodeForEdge,
    nodes,
    getMultipleChoiceQuestionNodeById,
    getSingleChoiceQuestionNodeById,
    getCreateDocumentRequestNodeById,
    documentTypes,
    addDocumentType,
    getDocumentTypeById,
    onClose,
}) => {
    const [pageStack, setPageStack] = useState([]);
    const pushToPageStack = (page) => {
        setPageStack([...pageStack, page]);
    };
    const popPageStack = () => {
        setPageStack([...pageStack.slice(0, -1)]);
    };
    const [sourceGraphNodeId, targetGraphNodeId] = useMemo(() => {
        return graphEdgeId.split('>');
    }, [graphEdgeId]);
    const targetNodeId = useMemo(() => {
        if (!targetGraphNodeId.startsWith('node-')) return null;
        return parseInt(targetGraphNodeId.split('-')[1]);
    }, [targetGraphNodeId]);
    const [sourceNodeId, sourceNodeIdField] = useMemo(() => {
        if (!sourceGraphNodeId.startsWith('node-')) return [null, null];

        const [sourceNodeIdString, sourceNodeIdField] = sourceGraphNodeId.split('|');

        return [parseInt(sourceNodeIdString.split('-')[1]), sourceNodeIdField];
    }, [sourceGraphNodeId]);

    const renderOptions = () => {
        switch (pageStack[pageStack.length - 1]) {
            case 'add-document-type':
                return (
                    <>
                        <ButtonContainer>
                            <BackButton popPageStack={popPageStack} />
                        </ButtonContainer>
                        <Section>
                            <DocumentTypeForm
                                addDocumentType={addDocumentType}
                                onDocumentTypeAdded={popPageStack}
                                documentTypes={documentTypes}
                            />
                        </Section>
                    </>
                );
            case 'insert-node-params':
                return (
                    <>
                        <ButtonContainer>
                            <BackButton popPageStack={popPageStack} />
                            <ButtonGroup name="Related Actions">
                                <Button onClick={() => pushToPageStack('add-document-type')}>
                                    New Document Type
                                </Button>
                            </ButtonGroup>
                        </ButtonContainer>
                        <Section>
                            {INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST && (
                                <NewCreateDocumentRequestNodeForm
                                    targetNodeId={targetNodeId}
                                    sourceNodeId={sourceNodeId}
                                    sourceNodeIdField={sourceNodeIdField}
                                    insertNodeOnEdge={insertNodeOnEdge}
                                    documentTypes={documentTypes}
                                    onClose={onClose}
                                />
                            )}
                        </Section>
                    </>
                );
            case 'insert-node':
                return (
                    <ButtonContainer>
                        <BackButton popPageStack={popPageStack} />

                        <ButtonGroup name="User-Input">
                            <Button onClick={() => insertNodeOnEdge({ sourceNodeId, sourceNodeIdField, targetNodeId }, { nodeType: INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION, params: { title: '' }, onNodeInserted: onClose })}>
                                {labelForInterviewNodeType(INTERVIEW_NODE_TYPES.SINGLE_CHOICE_QUESTION)}
                            </Button>
                            <Button onClick={() => insertNodeOnEdge({ sourceNodeId, sourceNodeIdField, targetNodeId }, { nodeType: INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION, params: { title: '' }, onNodeInserted: onClose })}>
                                {labelForInterviewNodeType(INTERVIEW_NODE_TYPES.MULTIPLE_CHOICE_QUESTION)}
                            </Button>
                        </ButtonGroup>

                        <ButtonGroup name="Auto-Input">
                            <Button onClick={() => pushToPageStack('insert-node-params')}>
                                {labelForInterviewNodeType(INTERVIEW_NODE_TYPES.CREATE_DOCUMENT_REQUEST)}
                            </Button>
                        </ButtonGroup>

                        <ButtonGroup name="Logical">
                            <Button
                                onClick={
                                    () => insertNodeOnEdge(
                                        { sourceNodeId, sourceNodeIdField, targetNodeId },
                                        {
                                            nodeType: INTERVIEW_NODE_TYPES.CONDITIONAL,
                                            onNodeInserted: onClose,
                                        },
                                    )
                                }
                            >
                                {labelForInterviewNodeType(INTERVIEW_NODE_TYPES.CONDITIONAL)}
                            </Button>
                        </ButtonGroup>
                    </ButtonContainer>
                );
            case 'select-target-node':
                return (
                    <>
                        <ButtonContainer>
                            <BackButton popPageStack={popPageStack} />
                        </ButtonContainer>
                        <Section>
                            <TargetNodeSelect
                                targetNodeId={targetNodeId}
                                sourceNodeId={sourceNodeId}
                                sourceNodeIdField={sourceNodeIdField}
                                setEndNodeForEdge={setEndNodeForEdge}
                                nodes={nodes}
                                getSingleChoiceQuestionNodeById={getSingleChoiceQuestionNodeById}
                                getMultipleChoiceQuestionNodeById={getMultipleChoiceQuestionNodeById}
                                getCreateDocumentRequestNodeById={getCreateDocumentRequestNodeById}
                                getDocumentTypeById={getDocumentTypeById}
                                onClose={onClose}
                            />
                        </Section>
                    </>
                );
            default:
                return (
                    <ButtonContainer>
                        <CloseButton onClose={onClose} />

                        <ButtonGroup name="Actions">
                            <Button onClick={() => pushToPageStack('insert-node')}>
                                Insert node
                            </Button>
                            <Button onClick={() => pushToPageStack('select-target-node')}>
                                Choose new target node
                            </Button>
                        </ButtonGroup>
                    </ButtonContainer>
                );
        }
    };

    return (
        <MenuContainer>
            {renderOptions()}
        </MenuContainer>
    );
};
