/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useState, useEffect, useRef, useCallback } from 'react';

export const prepareValue = (value, leadValue, options = { nullify: false }) => {
    if (value === leadValue) return value;

    const trimmedValue = value.trim();

    if (trimmedValue === leadValue) return trimmedValue;

    if (options.nullify && trimmedValue === '') {
        return null;
    } else {
        return trimmedValue;
    }
};

function useExecuteDelayed({ delayInMS }) {
    const timeoutId = useRef(null);
    const clear = useCallback(() => clearTimeout(timeoutId.current), []);
    const executeDelayed = useCallback((func) => {
        clear();

        timeoutId.current = setTimeout(func, delayInMS);
    }, [clear, delayInMS]);

    return {
        executeDelayed,
        clearExecuteDelayed: clear,
    };
}

const getLocalStorageKey = (leadId, fieldName) => `updateleadform.${leadId}.${fieldName}`;

const valueToInputValue = (value) => {
    return value == null ? '' : value.toString();
}

const determineInitialInputValue = (lead, fieldName) => {
    const localValue = window.localStorage.getItem(getLocalStorageKey(lead.id, fieldName));

    if (localValue) return localValue;

    return valueToInputValue(lead[fieldName]);
}

export const useLeadField = ({ lead, fieldName, remoteValue, initialInputValue }) => {
    const currentRemoteValue = remoteValue ? remoteValue : lead[fieldName];

    const [value, setValue] = useState(initialInputValue ? initialInputValue : determineInitialInputValue(lead, fieldName));
    const [valueChanged, setValueChanged] = useState(false);
    const {
        executeDelayed,
        clearExecuteDelayed,
    } = useExecuteDelayed({ delayInMS: 300 });

    useEffect(() => {
        const localStorageKey = getLocalStorageKey(lead.id, fieldName);
        const hasChanged = value !== valueToInputValue(currentRemoteValue);

        setValueChanged(hasChanged);

        if (hasChanged) {
            executeDelayed(() => {
                window.localStorage.setItem(
                    localStorageKey,
                    value,
                )
            });
        } else {
            clearExecuteDelayed();
            window.localStorage.removeItem(localStorageKey);
        }
    }, [lead, value, currentRemoteValue, fieldName, executeDelayed, clearExecuteDelayed]);

    const resetValue = () => setValue(valueToInputValue(currentRemoteValue));

    return {
        value,
        setValue,
        valueChanged,
        resetValue,
    }
};
