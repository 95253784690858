import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
var classNames = require('classnames');

function ReopenReminder({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        submitted: null,
        submitterName: username,
        reason: "",
        detail: ""
    });
    
    const saveForm = useCallback(async (matterID) => {
        return await API.saveReopenReminder(formState.submitted, formState.submitterName, formState.reason, formState.detail, matterID);
    }, [formState.submitted, formState.submitterName, formState.reason, formState.detail]);

    const possibleReasons = ["Conflict", "Client Request", "Payment Issue", "MIA", "Attorney"];

    return (
        <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={formState.submitted === null} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
            <div className="field">
                <div className="ui buttons">
                    <div className={classNames("ui toggle button", {active: formState.submitted === true})} onClick={() => { updateFormState({submitted: true}) }}>Submitted Re-Open</div>
                    <div className="or"></div>
                    <div className={classNames("ui toggle button", {active: formState.submitted === false})} onClick={() => { updateFormState({submitted: false}) }}>No Re-Open Necessary</div>
                </div>
            </div>

            {formState.submitted === true && (
                <>
                    <p>Make sure the re-open fax was received (either written confirmation or phone confirmation). Please enter the reason for the re-open and click the Save button below to confirm.</p>
                    <div className="field six wide">
                        <label htmlFor="reason">Reason for the re-open:</label>
                        <Dropdown placeholder="Please select" options={possibleReasons} onChange={option => updateFormState({reason: option.value})} value={formState.reason}/>
                    </div>
                </>
            )}

            {formState.submitted === false && (
                <>
                    <p>Please explain why the hearing is moved and no re-open is necessary.</p>
                    <div className="field">
                        <label htmlFor="detail">Explanation:</label>
                        <textarea type="text" name="detail" value={formState.detail} onChange={ev => updateFormState({detail: ev.target.value})} placeholder="Enter explanation..." rows="10" required></textarea>
                    </div>
                </>
            )}
        </TaskForm>
    );
}

export default ReopenReminder;
