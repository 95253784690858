import React, { useState, useEffect, useCallback } from "react";
import * as Constants from "../common/webconstants.js";
import { Link } from "react-router-dom";
import Assignee from "../components/assignee.js";
import MessageCard from "../components/messagecard.js";
import { useHistory } from "react-router-dom";
import Modal from "../components/modal.js";
import "../pages/inboxpage.css";
import { formatHumanDateTime } from "../common/formatting.js";
import API from "../common/api.js";
import useLeftRightSiblingLink from '../common/useLeftRightSiblingLink.js';
var classNames = require("classnames");


function placeholderRow(msg) {
    return (
        <tr key={msg} className="dim">
            <td className="border-none" colSpan={4}>
                {msg}
            </td>
        </tr>
    );
}

function InboxPageMessages({
    loading,
    errMsg,
    messages,
    setMessageAcknowledged,
    setMessageAssignee,
    allMessageTypes,
    possibleAssignees,
    initialExpandedMsgID,
    autoAck,
    setAssigneeFilter,
    setMessageTypeFilter,
    toggleWatched,
    height,
    allCaseNumbers
}) {
    const [expandedMessage, setExpandedMessage] = useState(null);
    let history = useHistory();
    const [didAutoAck, setDidAutoAck] = useState(false);

    useEffect(() => {
        if (initialExpandedMsgID) {
            const msgToExpand = messages.find((msg) => msg.msg_id === initialExpandedMsgID);
            if (msgToExpand) {
                setExpandedMessage(msgToExpand);
            } else {
                // That message was probably archived, and we got here through a link. So it won't be in the messages list.
                // Load it explicitly to ensure we don't have broken links.
                API.getInboxMessage(initialExpandedMsgID).then(msg => {
                    if (msg) {
                        setExpandedMessage(msg);
                    } else {
                        setExpandedMessage(null);            
                    }
                });
            }
        } else {
            setExpandedMessage(null);
        }
    }, [messages, initialExpandedMsgID]);

    const reworkMessage = useCallback(
        (msg) => {
            history.push(`/rework_inbox_message?messageID=${msg.msg_id}`);
        },
        [history]
    );

    useEffect(() => {
        if (autoAck === null) return;
        if (didAutoAck) return;
        if (messages && messages.find((msg) => msg.msg_id === autoAck)) {
            setMessageAcknowledged({ msg_id: autoAck });
            setDidAutoAck(true);
            history.push("/inbox/messages");
        }
    }, [autoAck, setMessageAcknowledged, messages, didAutoAck, history]);

    const [leftSiblingLink, rightSiblingLink] = useLeftRightSiblingLink(messages, expandedMessage, 'msg_id', '/inbox/messages/');

    function inboxRow(msg, inx, prefix) {
        const msgKind = (
            <span
                className="ui horizontal icon label"
                style={{ width: "100%", textAlign: "left", cursor: "pointer" }}
                onClick={() => {
                    setMessageTypeFilter(allMessageTypes.find((m) => m.value === msg.kind));
                }}
            >
                <i className={`${Constants.MESSAGE_KIND_ICONS[msg.kind]} icon`} style={{ width: 12 }} />{" "}
                {Constants.MESSAGE_KIND_LABELS[msg.kind]}
            </span>
        );
        const priorityLabel = (
            <div
                className={`ui ${Constants.MESSAGE_PRIO_COLORS[msg.priority]} horizontal label`}
                style={{ width: "100%", marginTop: 4 }}
            >
                {Constants.MESSAGE_PRIO_LABELS[msg.priority]}
            </div>
        );
        // eslint-disable-next-line
        const assignee = (
            <Assignee
                task={msg}
                onClick={(name) => {
                    setAssigneeFilter(possibleAssignees.find((a) => a.label === name));
                }}
            />
        );
        const timestamp = formatHumanDateTime(msg.created_at);
        const casenum = msg.case_number && ` - ${msg.case_number}`;
        const commentsAnnotations = msg.comment_count > 0 && (<><i className="comments icon"/>{msg.comment_count} &nbsp;</>);
        let mentionAnnotations = null;
        if (msg.read_mention_count < msg.total_mention_count) {
            mentionAnnotations = <><i className="red bell icon"/><span className="ui red header" style={{fontWeight: 'normal', fontSize: '1em'}}>{msg.total_mention_count}</span></>;
        } else if (msg.total_mention_count > 0) {
            mentionAnnotations = <><i className="bell outline icon"/>{msg.total_mention_count}</>;
        }
        const watchAnnotations = (msg.is_watched_by_current_user > 0) && <span>&nbsp;<i className="eye icon"/></span>;
        const annotations = (commentsAnnotations || mentionAnnotations || watchAnnotations) && (
            <span style={{color: "#999", marginLeft: '2em'}}>
                {commentsAnnotations}
                {mentionAnnotations}
                {watchAnnotations}
            </span>
        );
        const actionButtons = (
            <div className="ui small basic icon buttons inbox-message-action-buttons">
                <button className={classNames("ui icon button", {active: msg.is_watched_by_current_user})} data-tooltip={msg.is_watched_by_current_user ? "Unwatch message" : "Watch message"} data-position="top center" onClick={() => toggleWatched(msg)}>
                    <i className={classNames("eye icon", {green: msg.is_watched_by_current_user})}/>
                </button>
                {msg.acknowledged || (
                    <button
                        className="ui button"
                        data-tooltip="Mark messages as processed"
                        data-position="top center"
                        onClick={() => setMessageAcknowledged(msg)}
                    >
                        <i className="check icon" />
                    </button>
                )}
                {msg.acknowledged && (
                    <button
                        className="ui button"
                        data-tooltip="Rework message"
                        data-position="top center"
                        onClick={() => reworkMessage(msg)}
                    >
                        <i className="recycle icon" />
                    </button>
                )}
                <Link
                    to={`/add_task?messageID=${msg.msg_id}`}
                    className="ui button"
                    data-tooltip="Convert to task"
                    data-position="top center"
                >
                    <i className="tasks icon" />
                </Link>
            </div>
        );
        return (
            <tr key={`${prefix}${inx}`} className="inbox-message-row">
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {msgKind}
                    <br />
                    {msg.priority === Constants.MESSAGE_PRIO_URGENT && priorityLabel}
                </td>
                <td
                    className={classNames("w-full align-top", {
                        "border-none": inx === 0,
                        "font-bold": !msg.was_read && !msg.acknowledged,
                    })}
                >
                    <Link to={`/inbox/messages/${msg.msg_id}`}>{msg.subject}</Link> {annotations}
                    <br />
                    <span className="inbox-message-timestamp">
                        {timestamp}
                        {casenum}
                    </span>
                </td>
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {actionButtons}
                </td>
                <td className={classNames("whitespace-no-wrap align-top", { "border-none": inx === 0 })}>
                    {(msg.assignee || msg.team) && (
                        /* eslint-disable-next-line */
                        <a href="#" className="ui grey icon label">
                            {assignee}
                        </a>
                    )}
                </td>
            </tr>
        );
    }

    const msgRows = messages.filter((msg) => msg.acknowledged === false).map((msg, inx) => inboxRow(msg, inx, "msg_"));

    const errmsgEl = errMsg ? <div className="ui error message">{errMsg}</div> : null;

    if (loading) {
        return (
            <div style={{ paddingTop: 10, height: "100%" }}>
                <div className="ui active inline loader" style={{ marginTop: 20 }}></div>
            </div>
        );
    } else {
        return (
            <>
                {errmsgEl}

                {expandedMessage && (
                    <Modal>
                        <MessageCard
                            message={expandedMessage}
                            onCloseClicked={() => {
                                history.push("/inbox/messages");
                            }}
                            setMessageAssignee={setMessageAssignee}
                            setMessageAcknowledged={setMessageAcknowledged}
                            possibleAssignees={possibleAssignees}
                            allCaseNumbers={allCaseNumbers}
                            toggleWatched={toggleWatched}
                            leftSiblingLink={leftSiblingLink}
                            rightSiblingLink={rightSiblingLink}
                        />
                    </Modal>
                )}
                <div
                    style={{
                        flexGrow: 1,
                        overflowY: "auto",
                        paddingTop: 20,
                        paddingRight: 20,
                        height: height,
                    }}
                >
                    <table className="ui very basic table">
                        <tbody>
                            {msgRows.length > 0 ? msgRows : placeholderRow("No messages left to process...")}
                            <tr>
                                <td colSpan={2}>
                                    <Link to="/inbox/messages/archive">
                                        <div className="ui primary button">
                                            View Processed Messages in Inbox Archive
                                        </div>
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default InboxPageMessages;
