import React, { useCallback } from 'react';
import API from '../../common/api.js';
import TaskForm from './taskform.js';
import useStateObject from '../../common/useStateObject.js';
import useMatterInfo from '../../common/useMatterInfo.js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {get, uniqBy} from 'lodash';


function NonAppealReport({caseNumber, username, initialFormState, markTaskDone, completedAt, markTaskDoing}) {
    const [matterInfo, loading, errMsg] = useMatterInfo(caseNumber);
    const [formState, updateFormState] = useStateObject(JSON.parse(initialFormState) || {
        share: null,
        notes: "",
        caseType: null
    });
    
    const share = get(formState, "share.value", null);
    const caseType = get(formState, "caseType.value", null);
    const saveForm = useCallback(async (matterID) => {
        return await API.saveNonAppealReport(share, formState.notes, caseType, username, matterID);
    }, [share, formState.notes, caseType, username]);

    const yesNoOptions = ["Yes", "No"];
    const caseTypeOptions = [
        {label: `Don't change, keep it set to ${get(matterInfo, 'case_type')}`, value: get(matterInfo, 'case_type')},
        {label: "Denial of Benefits", value: "Denial of Benefits"},
        {label: "Over Payment of Benefits", value: "Over Payment of Benefits"},
        {label: "Dispute Computation", value: "Dispute Computation"},
        {label: "Second-Level Appeal", value: "Second-Level Appeal"},
        {label: "Employer Appeal EE", value: "Employer Appeal EE"},
        {label: "Employer Appeal ER", value: "Employer Appeal ER"}
    ];

    if (loading) {
        return (
            <div>
                <div className="ui active inline loader" style={{marginTop: 20}}></div>
            </div>
        );
    } else if (errMsg) {
        return (
            <div>
                <div className="ui visible error message" style={{marginTop: 20}}>{errMsg}</div>
            </div>
        );
    } else {
        return (
            <TaskForm caseNumber={caseNumber} saveButtonText="Save and Submit" disableSaveButton={formState.share === null || formState.notes === "" || formState.caseType === null} saveForm={saveForm} markTaskDone={markTaskDone} markTaskDoing={markTaskDoing} formState={formState} completedAt={completedAt}>
                <div className="field eight wide">
                    <label htmlFor="share">Share this report with the client?</label>
                    <Dropdown placeholder="Please select" options={yesNoOptions} onChange={option => updateFormState({share: option})} value={formState.share} required/>
                </div>
                <div className="field ten wide">
                    <label htmlFor="notes">Report notes:</label>
                    <textarea type="text" name="notes" value={formState.notes} onChange={ev => updateFormState({notes: ev.target.value})} placeholder="Enter notes..." rows="10" required></textarea>
                </div>
                <div className="field eight wide">
                    <label htmlFor="case_type">Change case type:</label>
                    <Dropdown placeholder="Please select" options={uniqBy(caseTypeOptions, 'value')} onChange={option => updateFormState({caseType: option})} value={formState.caseType} required/>
                </div>
            </TaskForm>
        );
    }
}

export default NonAppealReport;
